import notifications from "client/utils/notifications";
import { ipAxios } from "../ipAxios";

export const getSheetsExportLink = async () => {
  try {
    const res = await ipAxios({
      url: `/admin/transactionsPlanner/export/link`,
      method: "GET",
      responseType: "text",
    });
    if (res.status === 200) {
      return { success: true, data: res.data };
    }
    return { success: false };
  } catch (error) {
    notifications.genericError(error);
    return { success: false };
  }
};
