import { TextField, Tooltip } from "@material-ui/core";
import { createDTError } from "@wearenova/mui-data-table";
import { useUpdateDealConfigs } from "client/api/gql/deployment.gqlApi";
import notifications from "client/utils/notifications";
import _ from "lodash";
import React, { KeyboardEventHandler, useCallback, useContext, useEffect, useMemo, useState } from "react";
import DeploymentContext from "../deployment.context";
import { getFundConfig } from "../utils";

const ConfigField: React.FC<{
  path: "weighting" | "amount";
  dealStage: "seis" | "eis";
  fundId: string;
}> = ({ path, dealStage, fundId }) => {
  const { deals } = useContext(DeploymentContext);
  const [updateDealConfigs] = useUpdateDealConfigs();

  const defaultValue = useMemo<number | "">(
    () =>
      deals.length &&
      deals.every(
        (v, idx) => idx + 1 === deals.length || getFundConfig(v, fundId, dealStage)[path] === getFundConfig(deals[idx + 1], fundId, dealStage)[path],
      )
        ? getFundConfig(deals[0], fundId, dealStage)[path]!
        : "",
    [deals, dealStage, fundId, path],
  );
  const [value, setValue] = useState(defaultValue);

  const disabled = useMemo(
    () => path === "amount" && deals.some((d) => Boolean(getFundConfig(d, fundId, dealStage).weighting)),
    [dealStage, deals, fundId, path],
  );

  const handleKeyPress = useCallback<KeyboardEventHandler>(
    async (e) => {
      if (e.key !== "Enter" || value === "") return;
      e.preventDefault();
      e.stopPropagation();
      const res = await updateDealConfigs({
        variables: {
          updates: { config: { [fundId]: { [dealStage]: { [path]: value } } } as any },
          dealIds: deals.map((d) => d._id),
        },
      });
      if (res.errors) {
        notifications.gql(res.errors);
        throw createDTError("Failed to update deal configs");
      }
    },
    [dealStage, deals, fundId, path, updateDealConfigs, value],
  );

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <div>
      {dealStage.toUpperCase()}
      <br />
      {_.startCase(path)}
      <br />
      <Tooltip title={disabled ? "To set all amounts, first set all weightings to 0." : ""}>
        <div>
          <TextField
            value={value ?? ""}
            onChange={(e) => setValue(e.target.value && parseInt(e.target.value))}
            onKeyPress={handleKeyPress}
            placeholder="set all"
            size="small"
            variant="standard"
            type="number"
            disabled={disabled}
          />
        </div>
      </Tooltip>
    </div>
  );
};

export default ConfigField;
