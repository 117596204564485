import notifications from "../utils/notifications";
import { ipAxios } from "./ipAxios";

const getUsersApplication = async (applicationId?: string | null) => {
  try {
    const { status, data } = await ipAxios.get(
      applicationId ? `/applications/${applicationId}/applicationContext` : `/applications/applicationContext`,
    );
    if (status === 200) {
      return data;
    } else {
      return {};
    }
  } catch (error) {
    notifications.genericError(error);
    return false;
  }
};

const getInvestmentFee = async (applicationId?: string) => {
  try {
    const res = await ipAxios.get(applicationId ? `/applications/${applicationId}/getInvestmentFee` : `/applications/getInvestmentFee`);
    return { success: res.status === 200, data: res.data };
  } catch (error) {
    notifications.genericError(error);
    return null;
  }
};

const getApplication = async () => {
  const res = await ipAxios.get(`/applications/application`);
  return res.data;
};

const getPaymentMethod = async () => {
  try {
    const res = await ipAxios.get(`/applications/paymentMethod`);
    return res.data;
  } catch (error) {
    notifications.genericError(error);
    return false;
  }
};

const getApplications = async () => {
  try {
    const response = await ipAxios.get(`/applications`);
    return response.data;
  } catch (e) {
    notifications.genericError(e);
    return null;
  }
};

const getUsersMonthlyPayments = async () => {
  try {
    const res = await ipAxios.get("/applications/monthlyInvestments");
    return res.data;
  } catch (error) {
    notifications.genericError(error);
    return null;
  }
};

export default {
  getPaymentMethod,
  getApplications,
  getUsersApplication,
  getApplication,
  getUsersMonthlyPayments,
  getInvestmentFee,
};
