import { Link } from "@material-ui/core";
import { DateTime } from "luxon";

export const FUND_VARIABLE_REPLACER_REGEX = /\$\{([\w.]+)\}|<%=\s*fund\.([\w.]+)\s*%>/g;

const BASE_API = "/api";

export const SHARE_TRANSACTION_STATUSES = ["CREATED", "READY", "FINALISED"] as const;

export const PLANNER_OPTIONS = {
  includeNonAMLApproved: "Include applications with available funds that are awaiting AML approval",
  includeNonIMApproved: "Include applications with available funds that are awaiting IM approval",
  includeExpectedPayments: "Include applications that are awaiting funds",
  useOverAllocation: "Use any investor’s “Over Allocation” for SEIS/EIS split i.e. their secondary SEIS/EIS split preference",
  includeReadyTransactions: "Treat Share Transactions in Ready state as Finalised",
} as const;

export const IM_TAB_VALUES = {
  WELCOME: "welcome",
  HOW_TO_INVEST: "how-to-invest",
  OVERVIEW: "overview",
  DETAIL: "detail",
  RISK_FACTORS: "risk-factors",
  AGREEMENT: "agreement",
  DEFINITIONS: "definitions",
  IMPORTANT_INFORMATION: "important-information",
  REVIEWS: "reviews",
  RETURNS_CALCULATOR: "returns-calculator",
  DOCUMENTS: "documents",
};

export const USER_ROLE = {
  READ_ONLY_INVESTOR: "readOnlyInvestor",
  INVESTOR: "investor",
  READ_ONLY_ADVISOR: "readOnlyAdvisor",
  ADVISOR: "advisor",
  READ_ONLY_ADMIN: "readOnlyAdmin",
  MANAGER: "manager",
  ADMIN: "admin",
  PUBLIC_USER: "publicUser",
  API_USER: "apiUser",
};

const USER_TYPES = {
  ADMIN: "ADMIN",
  DIRECT_INVESTOR: "DIRECT_INVESTOR",
  ADVISED_INVESTOR: "ADVISED_INVESTOR",
  ADVISOR: "ADVISOR",
} as const;

const ADVISOR_TYPES = {
  ADVISED: "ADVISED",
  // EXECUTION: 'EXECUTION ONLY',
  REFERRED: "REFERRED",
} as const;

const ROUTES = {
  ADVISOR: {
    index: "/advisor",
    LANDING_PAGE: "/financialadvisors",
    OLD_LANDING_PAGE: "/advisor/landingpage",
    WELCOME: "/advisor/welcome",
    ONBOARDING: "/advisor/onboarding",
    DASHBOARD: "/advisor/dashboard",
    ADD_CLIENT: "/advisor/addclient",
    BEGIN_APPLICATION: "/advisor/client/apply",
    FEE_PAGE: "/advisor/fee",
  },
  LANDING_PAGE: "/privateinvestors",
  OLD_LANDING_PAGE: "/landingpage",
  LOGIN: "/login",
  REGISTER: "/register",
  LINK_REGISTRATION: "/registration",
  LINK_FORGOT_PASSWORD: "/forgot-password",
  CONFIRM_EMAIL: "/confirmEmail",
  WELCOME: "/welcome",
  IM: {
    index: "/information-memorandum",
    IMPORTANT_INFORMATION: "/information-memorandum/important-information",
    WELCOME: "/information-memorandum/welcome",
    HOW_TO_INVEST: "/information-memorandum/how-to-invest",
    OVERVIEW: "/information-memorandum/overview",
    DETAIL: "/information-memorandum/detail",
    RISK_FACTORS: "/information-memorandum/risk-factors",
    AGREEMENT: "/information-memorandum/agreement",
    DEFINITIONS: "/information-memorandum/definitions",
    REVIEWS: "/information-memorandum/reviews",
    RETURNS_CALCULATOR: "/information-memorandum/returns-calculator",
    DOCUMENTS: "/information-memorandum/documents",
  },
  QUALIFY: "/qualify",
  QUALIFIED: "/qualified",
  RISK_WARNING: "/risk",
  ADMIN_PANEL: "/admin/panel",
  APPLICATION_BEGIN: "/apply/begin",
  APPLICATION_ID_VERIFICATION: "/apply/id-verification",
  APPLICATION_ASSESSMENT: "/apply/assessment",
  APPLICATION_DETAILS: "/apply/personalDetails",
  APPLICATION_OBJECTIVES: "/apply/objectives",
  APPLICATION_FINANCES: "/apply/finances",
  APPLICATION_EXPERIENCE: "/apply/experience",
  APPLICATION_INVESTMENT: "/apply/investment",
  APPLICATION_AGREEMENT: "/apply/agreement",
  APPLICATION_COMPLETE: "/apply/complete",
  DASHBOARD: "/dashboard",
  DASHBOARD_OVERVIEW: "/dashboard/overview",
  PUBLIC_RETURNS_CALCULATOR: "/returns-calculator",
} as const;

const APPLICATION_STATES = {
  NOT_STARTED: "Not started",
  QUALIFIED: "Qualified for investment",
  IN_PROGRESS_DETAILS: "In progress: Personal details section completed",
  IN_PROGRESS_FINANCES: "In progress: Finances section completed",
  IN_PROGRESS_EXPERIENCE: "In progress: Experience details section completed",
  IN_PROGRESS_OBJECTIVES: "In progress: Objectives details section completed",
  IN_PROGRESS_INVESTMENT: "In progress: Investment details section completed",
  AWAITING_SIGNATURE: "Awaiting signature",
  AWAITING_FUNDS: "Signed and awaiting funds",
  FUNDS_RECEIVED: "Funds Received, Awaiting Deployment",
  FUNDS_DEPLOYED: "Funds Deployed",
  LOST_OR_NO_INTEREST: "Lost/no interest",
} as const;

const UsCitizen = {
  YES: "Yes",
  NO: "No",
  GREEN_CARD: "I am a green card holder",
} as const;

const STORAGE_KEYS = {
  APPLICATION_USER_ID: "APPLICATION_USER_ID",
  PERSONAL_DETAILS: "PERSONAL_DETAILS",
  KNOWLEDGE_EXPERIENCE: "KNOWLEDGE_EXPERIENCE",
  INVESTMENT_INFORMATION: "INVESTMENT_INFORMATION",
  INVESTMENT_OBJECTIVES: "INVESTMENT_OBJECTIVES",
  AUTH_TOKEN: "AUTH_TOKEN",
  FINANCIAL_SITUATION: "FINANCIAL_SUTUATION",
} as const;

const TRACKED_EVENTS = {
  ADVISOR: {
    BUTTON: { REGISTER: "Advisor - Register Button Clicked" },
    INPUT: {
      CATEGORIES: {
        ONBOARDING: "Advisor - Onboarding",
        ADD_CLIENT: "Advisor - Add Client",
      },
    },
  },
  ACTIONS: {
    REGISTER: "registerbuttonclick", // this is seperate to button clicks, as it tracks our Ad conversion goals
  },
  PAGE_VIEWS: {
    ADVISOR_HOMEPAGE: "advisor homepage viewed",
    INVESTOR_HOMEPAGE: "investor homepage viewed",
    REGISTER: "register page viewed",
    LOGIN: "login page viewed",
    FORGOT_PASSWORD: "forgot password viewed",
    INVESTOR_APPLICATION_QUALIFY_TO_INVEST: "investor application qualify to invest viewed",
    INVESTOR_APPLICATION_QUALIFIED: "investor application qualified viewed",
    INVESTOR_APPLICATION_BEGIN: "investor application begin viewed",
    INVESTOR_APPLICATION_ABOUT: "investor application about viewed",
    INVESTOR_APPLICATION_OBJECTIVES: "investor application objectives viewed",
    INVESTOR_APPLICATION_FINANCIAL: "investor application financial viewed",
    INVESTOR_APPLICATION_KNOWLEDGE: "investor application knowledge viewed",
    INVESTOR_APPLICATION_INVESTMENT: "investor application investment viewed",
    INVESTOR_APPLICATION_COMPLETE: "investor application complete viewed",
    INVESTOR_DASHBOARD: "investor dashboard viewed",
    INVESTOR_DASHBOARD_ACCOUNT_OVERVIEW: "investor dashboard account overview viewed",
    INVESTOR_DASHBOARD_PORTFOLIO_INVESTMENTS: "investor dashboard portfolio of investments viewed",
    INVESTOR_DASHBOARD_COMPANY_INFO: "investor dashboard company info viewed",
    INVESTOR_DASHBOARD_STATEMENT: "investor dashboard statement viewed",
    INVESTOR_DASHBOARD_INVEST_AGREEMENTS: "investor dashboard invest agreements viewed",
    IM_WELCOME: "im welcome viewed",
    IM_OVERVIEW: "im overview viewed",
    IM_HOW_TO: "im how to viewed",
    IM_DETAIL: "im detail viewed",
    IM_RISK: "im risk viewed",
    IM_AGREEMENT: "im agreement viewed",
    IM_IMPORTANT_INFORMATION: "im important information viewed",
    IM_REVIEWS: "im reviews viewed",
    IM_RETURNS_CALCULATOR: "im returns calculator viewed",
    IM_DOCUMENTS: "im documents viewed",
    RISK_WARNING: "risk warning viewed",
    ADVISOR_DASHBOARD: "advisor dashboard viewed",
    ADVISOR_VERIFICATION: "advisor verification viewed",
    ADD_CLIENT: "add client viewed",
    EDIT_CLIENT_FEE: "edit client fee viewed",
    CLIENT_APPLICATION_FORM_BEGIN: "client application form begin viewed",
    CLIENT_APPLICATION_FORM_ABOUT: "client application form about viewed",
    CLIENT_APPLICATION_FORM_OBJECTIVES: "client application form objectives viewed",
    CLIENT_APPLICATION_FORM_FINANCES: "client application form finances viewed",
    CLIENT_APPLICATION_FORM_EXPERIENCE: "client application form experience viewed",
    CLIENT_APPLICATION_FORM_INVESTMENT: "client application form investment viewed",
    CLIENT_APPLICATION_FORM_YOUR_AGREEMENT: "client application form your agreement viewed",
    CLIENT_APPLICATION_FORM_COMPLETE: "client application form complete viewed",
    CLIENT_INVESTMENTS: "client investments viewed",
  },
  PAGE_ACTIONS: {
    REGISTER_FORM_SUBMITTED_SUCCESSFULLY: "register form submitted successfully",
    REGISTER_FORM_SUBMITTED_UNSUCCESSFULLY: "register form submitted unsuccessfully",
    LOGIN_FORM_SUBMITTED_SUCCESSFULLY: "login form submitted successfully",
    LOGIN_FORM_SUBMITTED_UNSUCCESSFULLY: "login form submitted unsuccessfully",
    FORGOT_PASSWORD_SUBMITTED_SUCCESSFULLY: "forgotten password form submitted successfully",
    FORGOT_PASSWORD_SUBMITTED_UNSUCCESSFULLY: "forgotten password submitted unsuccessfully",
    RESEND_CONFIRMATION_EMAIL: "resend confirmation email",
    INVESTOR_APPLICATION_FORM_ABOUT_SUBMITTED_SUCCESSFULLY: "investor application about submitted successfully",
    INVESTOR_APPLICATION_FORM_ABOUT_SUBMITTED_UNSUCCESSFULLY: "investor applicaton about submitted unsuccessfully",
    INVESTOR_APPLICATION_FORM_OBJECTIVES_SUBMITTED_SUCCESSFULLY: "investor application objectives submitted successfully",
    INVESTOR_APPLICATION_FORM_OBJECTIVES_SUBMITTED_UNSUCCESSFULLY: "investor application objectives submitted unsuccessfully",
    INVESTOR_APPLICATION_FORM_FINANCES_SUBMITTED_SUCCESSFULLY: "investor application finaces submitted successfully",
    INVESTOR_APPLICATION_FORM_FINANCES_SUBMITTED_UNSUCCESSFULLY: "investor application finances submitted unsuccessfully",
    INVESTOR_APPLICATION_FORM_EXPERIENCE_SUBMITTED_SUCCESSFULLY: "investor application experience submitted successfully",
    INVESTOR_APPLICATION_FORM_EXPERIENCE_SUBMITTED_UNSUCCESSFULLY: "investor application experience submitted unsuccessfully",
    INVESTOR_APPLICATION_FORM_INVESTMENT_SUBMITTED_SUCCESSFULLY: "investor application investment submitted successfully",
    INVESTOR_APPLICATION_FORM_INVESTMENT_SUBMITTED_UNSUCCESSFULLY: "investor application investment submitted unsuccessfully",
    QUALIFYING_CERTIFICATE_FILE_DOWNLOADED_SUCCESSFULLY: "qualifying certificate file downloaded successfully",
    QUALIFYING_CERTIFICATE_FILE_DOWNLOADED_UNSUCCESSFULLY: "qualifying certificate file downloaded unsuccessfully",
    QUALIFYING_EVIDENCE_FILE_DOWNLOADED_SUCCESSFULLY: "qualifying evidence file downloaded successfully",
    QUALIFYING_EVIDENCE_FILE_DOWNLOADED_UNSUCCESSFULLY: "qualifying evidence file downloaded unsuccessfully",
    CONTRACT_NOTE_DOWNLOADED_SUCCESSFULLY: "contact note downloaded successfully",
    COMPANY_INFO_LINK_CLICKED: "company info link clicked",
    INVESTMENT_AGREEMENT_FILE_DOWNLOADED: "investment agreement file downloaded",
    RETURNS_CALCULATOR_FORM_SUBMITTED_SUCCESSFULLY: "returns calculator form submitted successfully",
    RETURNS_CALCULATOR_FORM_SUBMITTED_UNSUCCESSFULLY: "returns calculator form submitted unsuccessfully",
    IM_RISK_FILE_DOWNLOADED: "im risk file downloaded",
    IM_FILE_VIEWED: "im file viewed",
    IM_KID_VIEWED: "im key information document viewed",
    IM_PRODUCT_OVERVIEW_VIEWED: "im product overview viewed",
    IM_TAX_CASE_STUDY_VIEWED: "im tax case study viewed",
    IM_GROWTH_CASE_STUDY_VIEWED: "im growth case study viewed",
    IM_IFA_DOC_VIEWED: "im ifa doc viewed",
    INVESTOR_APPLICATION_FORM_INVESTMENT_FILE_DOWNLOADED: "investor application form investment file downloaded",
    ADVISOR_VERIFICATION_FORM_SUBMITTED_SUCCESSFULLY: "advisor verification form submitted successfully",
    ADVISOR_VERIFICATION_FORM_SUBMITTED_UNSUCCESSFULLY: "advisor verification form submitted unsuccessfully",
    ADD_CLIENT_FORM_SUBMITTED_SUCCESSFULLY: "add client form submitted successfully",
    ADD_CLIENT_FORM_SUBMITTED_UNSUCCESSFULLY: "add client form submitted unsuccessfully",
    EDIT_CLIENT_FEE_FORM_SUBMITTED_SUCCESSFULLY: "edit client fee form submitted successfully",
    EDIT_CLIENT_FEE_FORM_SUBMITTED_UNSUCCESSFULLY: "edit client fee form submitted unsuccessfully",
    CLIENT_APPLICATION_ABOUT_FORM_SUBMITTED_SUCCESSFULLY: "client application about form submitted successfully",
    CLIENT_APPLICATION_ABOUT_FORM_SUBMITTED_UNSUCCESSFULLY: "client application about form submitted unsuccessfully",
    CLIENT_APPLICATION_OBJECTIVES_FORM_SUBMITTED_SUCCESSFULLY: "client application objectives form submitted successfully",
    CLIENT_APPLICATION_OBJECTIVES_FORM_SUBMITTED_UNSUCCESSFULLY: "client application objectives form submitted unsuccessfully",
    CLIENT_APPLICATION_FINANCES_FORM_SUBMITTED_SUCCESSFULLY: "client application finances form submitted successfully",
    CLIENT_APPLICATION_FINANCES_FORM_SUBMITTED_UNSUCCESSFULLY: "client application finances form submitted unsuccessfully",
    CLIENT_APPLICATION_EXPERIENCE_FORM_SUBMITTED_SUCCESSFULLY: "client application experience form submitted successfully",
    CLIENT_APPLICATION_EXPERIENCE_FORM_SUBMITTED_UNSUCCESSFULLY: "client application experience form submitted unsuccessfully",
    CLIENT_APPLICATION_INVESTMENT_FORM_SUBMITTED_SUCCESSFULLY: "client application investment form submitted successfully",
    CLIENT_APPLICATION_INVESTMENT_FORM_SUBMITTED_UNSUCCESSFULLY: "client application investment form submitted unsuccessfully",
    QUALIFYING_CERTIFICATE_FILE_DOWNLOADED: "qualifying certificate file downloaded",
    PORTFOLIO_FILTER_CLICKED: "filter clicked on portfolio statement",
    PORTFOLIO_DOCUMENT_DOWNLOAD_CLICKED: "portfolio document download clicked",
    CLIENT_PORTFOLIO_PDF: "client portfolio pdf downloaded",
    PORTFOLIO_PDF: "portfolio pdf downloaded",
    CLIENT_PORTFOLIO_CSV: "client portfolio csv downloaded",
    PORTFOLIO_CSV: "portfolio csv downloaded",
  },
  BUTTON: {
    APPLY: "Apply Button Clicked",
    QUALIFY: "Qualify Button Clicked",
    REGISTER: "Register Button Clicked",
    LOGIN: "Login Button Clicked",
    INVEST_FUNDS: "Invest Funds Button Clicked",
    DASHBOARD: "Dashboard Button Clicked",
    ADVISOR_DASHBOARD: "Advisor Dashboard Button Clicked",
    ADVISOR_ONBOARD: "Advisor Onboard Button Clicked",
    EDIT_MONTHLY_INVESTMENTS: "Edit Monthly Investment Button Clicked",
    RESEND_APPLICATION: "Resend Application Button Clicked",
    SIGN_AGREEMENT: "Sign Agreement Button Clicked",
  },
  INPUT: {
    COMPLETED: "Input Completed",
    CATEGORIES: {
      PERSONAL_DETAILS: "Personal Details",
      INVESTMENT_OBJECTIVES: "Investment Objectives",
      KNOWLEDGE_EXPERIENCE: "Knowledge Experience",
      FINANCIAL_SITUATION: "Financial Situation",
      INVESTMENT_DETAILS: "Investment Details",
    }, // Currently the event labels for all text, number and radio inputs within these categories are generated from the ids
    CHECKBOX: {
      IS_READ_IM: "Is Read IM",
      UNDERSTAND_RISK: "Understand Risk",
      IS_FINANCIAL_ADVICE: "Is Financial Advice",
    },
  },
} as const; // Page events are not included here as they require no labels

const IM = {
  VERSION: 55,
  URL: "https://www.wearenova.co.uk/hubfs/Nova_seis_im_v55.pdf",
} as const;

const ADDRESS_FORM = {
  addressLineOne: {
    id: "addressLineOne",
    name: "addressLineOne",
    placeholder: "Address line 1",
    label: "Address line 1",
  },
  addressLineTwo: {
    id: "addressLineTwo",
    name: "addressLineTwo",
    placeholder: "Address line 2",
    label: "Address line 2",
  },
  city: {
    id: "city",
    name: "city",
    placeholder: "City",
    label: "City",
  },
  country: {
    id: "country",
    name: "country",
    placeholder: "Country",
    label: "Country",
  },
  postcode: {
    id: "postcode",
    name: "postcode",
    placeholder: "Postcode",
    label: "Postcode",
  },
  searchPostcode: {
    id: "searchPostcode",
    name: "searchPostcode",
    placeholder: "Enter Postcode to search for address",
    label: "Postcode",
  },
  lengthYears: {
    id: "lengthYears",
    name: "lengthYears",
    placeholder: "Years",
    label: "How many years have you lived at this address",
  },
  lengthMonths: {
    id: "lengthMonths",
    name: "lengthMonths",
    placeholder: "Months",
    label: "How many months have you lived at this address",
  },
} as const;

// make sure each field you add has a
// id (the same as the object key) and label property as they are used
// to display errors at the bottom of a page
const FORM_FIELDS_DATA = {
  ADDRESS_FORM,
  DUAL_TAX_RESIDENCY_ADDRESS_FORM: ADDRESS_FORM,
  PERSONAL_DETAILS_FORM: {
    title: {
      id: "title",
      name: "title",
      placeholder: "Title (Mr/Mrs/Miss/Ms/Other)",
      label: "Title",
    },
    forenames: {
      id: "forenames",
      name: "forenames",
      placeholder: "Forename(s)",
      label: "Forename(s)",
    },
    surname: {
      id: "surname",
      name: "surname",
      placeholder: "Surname",
      label: "Surname",
    },
    email: {
      id: "email",
      name: "email",
      placeholder: "Email",
      label: "Email",
    },
    dob: {
      id: "dob",
      name: "dob",
      placeholder: "DOB",
      label: "Date of birth",
    },
    contactNumber: {
      id: "contactNumber",
      name: "contactNumber",
      placeholder: "Contact number",
      label: "Contact number",
    },
    placeOfBirth: {
      id: "placeOfBirth",
      name: "placeOfBirth",
      placeholder: "Place of birth",
      label: "Place of birth",
    },
    nationality: {
      id: "nationality",
      name: "nationality",
      placeholder: "Select nationality",
      label: "Nationality",
    },
    taxResidency: {
      id: "taxResidency",
      name: "taxResidency",
      placeholder: "Select your tax residency",
      label: "Tax Residency",
    },
    niNumber: {
      id: "niNumber",
      name: "niNumber",
      placeholder: "National Insurance number",
      label: "National Insurance number",
    },
    tiNumber: {
      id: "tiNumber",
      name: "tiNumber",
      placeholder: "Tax identification number",
      label: "Tax identification number",
    },
    dualTaxResidency: {
      id: "dualTaxResidency",
      name: "dualTaxResidency",
      label: "Please tick here if you have dual tax residency.",
    },
    usCitizen: {
      id: "usCitizen",
      name: "usCitizen",
      label: "Are you a U.S. Citizen?",
    },
    politicallyExposed: {
      id: "politicallyExposed",
      name: "politicallyExposed",
      label: "Are you a Politically Exposed Person or a close relative or associate of one?",
    },
    politicallyExposedDetails: {
      id: "politicallyExposedDetails",
      name: "politicallyExposedDetails",
      label: "Please provide full details",
    },
    dualTaxResidencyNationality: {
      id: "dualTaxResidencyNationality",
      name: "dualTaxResidencyNationality",
      placeholder: "Select second tax residency country",
      label: "Second tax residency country",
    },
    dualTaxResidencyTINumber: {
      id: "dualTaxResidencyTINumber",
      name: "dualTaxResidencyTINumber",
      placeholder: "Tax identification number for your second residency",
      label: "Tax identification number for your second residency",
    },
    addressHistory: {
      id: "addressHistory",
      name: "addressHistory",
      title: "Current residential address",

      currentAddress: {
        placeholder: "Current Address",
        label: "Current residential address",
      },
      previousAddress: {
        placeholder: "Previous Address",
        label: "Previous residential address",
      },

      ...ADDRESS_FORM,
    },
    dualTaxResidencyAddressHistory: {
      id: "dualTaxResidencyAddressHistory",
      name: "dualTaxResidencyAddressHistory",
      title: "Current address for your second residency",

      currentAddress: {
        placeholder: "Current address for your second residency",
        label: "Current address for your second residency",
      },
      previousAddress: {
        placeholder: "Previous address for your second residency",
        label: "Previous address for your second residency",
      },

      ...ADDRESS_FORM,
    },
  },
  INVESTMENT_OBJECTIVES_FORM: {
    understandRisk: {
      id: "understandRisk",
      name: "understandRisk",
      label:
        "Please tick this to confirm that you understand the high risk investment objectives of the service (including the risk factors detailed in the product literature) and that these are consistent with your personal financial objectives.",
    },
    accessToCapital: {
      id: "accessToCapital",
      name: "accessToCapital",
      label:
        "I will not need income from or access to the capital invested during the anticipated holding period of 6 years from the date that the investment is made.",
    },
    eisRelief: {
      id: "eisRelief",
      name: "eisRelief",
      label: "I wish to take advantage of EIS taxation reliefs.",
    },
    deferGains: {
      id: "deferGains",
      title: "Are you seeking to defer a capital gain?",
      isDeferGains: {
        id: "isDeferGains",
        name: "deferGains.isDeferGains",
        label: "Are you seeking to defer a capital gain?",
      },
      deferredGains: {
        id: "deferredGains",
        name: "deferGains.deferredGains",
        label: "Please provide information about the gains, including dates & amounts",
      },
    },
    incomeTaxRelief: {
      id: "incomeTaxRelief",
      name: "incomeTaxRelief",
      label: "Are you seeking to claim full income tax relief? (You should consider whether you have sufficient income tax liability.)",
    },
    underPressure: {
      id: "underPressure",
      name: "underPressure",
      label: "Are you under any pressure to invest or secure a certain income from investing?",
    },
    willingToLoseInvestment: {
      id: "willingToLoseInvestment",
      name: "willingToLoseInvestment",
      label:
        "Investing will expose you to capital losses. Are you able to lose all of your investment and absorb the impact this would have on your lifestyle?",
    },
    healthIssues: {
      id: "healthIssues",
      name: "healthIssues",
      label:
        "Are you aware of any health issues of events in your life which would impact your ability to make decisions for financial matters or affect your day to day capability?",
    },
    inheritanceTaxRelief: {
      id: "inheritanceTaxRelief",
      name: "inheritanceTaxRelief",
      label: "Are you seeking to benefit from relief from inheritance tax? (after two years from the date of the underling investments).",
    },
    capitalCommitments: {
      id: "capitalCommitments",
      title:
        "Do you have any significant capital commitments which cannot be funded from your annual disposable income or liquid savings within the next six years? If you answered ‘yes’ to this question, please confirm how this commitment will be funded below.",

      isCapitalCommitment: {
        id: "isCapitalCommitment",
        name: "capitalCommitments.isCapitalCommitment",
        label:
          "Do you have any significant capital commitments which cannot be funded from your annual disposable income or liquid savings within the next six years? If you answered ‘yes’ to this question, please confirm how this commitment will be funded below.",
      },
      confirmation: {
        id: "confirmation",
        name: "capitalCommitments.confirmation",
        label: "How will you fund this commitment?",
      },
    },
  },
  FINANCIAL_SITUATION_FORM: {
    disposableIncome: {
      id: "disposableIncome",
      name: "disposableIncome",
      label: "Please indicate your annual net disposable income (after all regular financial commitments)",
    },
    nav: {
      id: "nav",
      name: "nav",
      label: "Please indicate the value of your assets (excluding your residence net of any loans or other liabilities",
    },
    residenceValue: {
      id: "residenceValue",
      name: "residenceValue",
      label: "Please indicate the value of your residence, net of any loans or other liabilities secured on it",
    },
    incomeSource: {
      id: "incomeSource",
      name: "incomeSource",
      placeholder: "Enter source here...",
      label: "Main sources of income (e.g. earnings, pension)",
    },
    bearLosses: {
      id: "bearLosses",
      name: "bearLosses",
      label: "I am able to lose all funds invested",
    },
  },
  KNOWLEDGE_EXPERIENCE_FORM: {
    taxProducts: {
      id: "taxProducts",
      title: "Tax Products (e.g. VCTs, EIS)",
      times: {
        id: "times",
        name: "taxProducts.times",
        placeholder: "Number of times invested",
        label: "Number of times invested",
      },
      years: {
        id: "years",
        name: "taxProducts.years",
        placeholder: "Number of years invested",
        label: "Number of years invested",
      },
      amount: {
        id: "amount",
        name: "taxProducts.amount",
        placeholder: "Amount held",
        label: "Amount held (£)",
      },
    },
    largeCompanies: {
      id: "largeCompanies",
      title: "Large quoted companies (including authorised unit trusts, OEICs, ISAs, PEPs)",
      times: {
        id: "times",
        name: "largeCompanies.times",
        placeholder: "Number of times invested",
        label: "Number of times invested",
      },
      years: {
        id: "years",
        name: "largeCompanies.years",
        placeholder: "Number of years invested",
        label: "Number of years invested",
      },
      amount: {
        id: "amount",
        name: "largeCompanies.amount",
        placeholder: "Amount held",
        label: "Amount held (£)",
      },
    },
    smallCompanies: {
      id: "smallCompanies",
      title: "Smaller quoted companies",
      times: {
        id: "times",
        name: "smallCompanies.times",
        placeholder: "Number of times invested",
        label: "Number of times invested",
      },
      years: {
        id: "years",
        name: "smallCompanies.years",
        placeholder: "Number of years invested",
        label: "Number of years invested",
      },
      amount: {
        id: "amount",
        name: "smallCompanies.amount",
        placeholder: "Amount held",
        label: "Amount held (£)",
      },
    },
    aimCompanies: {
      id: "aimCompanies",
      title: "AIM-listed companies",
      times: {
        id: "times",
        name: "aimCompanies.times",
        placeholder: "Number of times invested",
        label: "Number of times invested",
      },
      years: {
        id: "years",
        name: "aimCompanies.years",
        placeholder: "Number of years invested",
        label: "Number of years invested",
      },
      amount: {
        id: "amount",
        name: "aimCompanies.amount",
        placeholder: "Amount held",
        label: "Amount held (£)",
      },
    },
    unquotedCompanies: {
      id: "unquotedCompanies",
      title: "Unquoted companies",
      times: {
        id: "times",
        name: "unquotedCompanies.times",
        placeholder: "Number of times invested",
        label: "Number of times invested",
      },
      years: {
        id: "years",
        name: "unquotedCompanies.years",
        placeholder: "Number of years invested",
        label: "Number of years invested",
      },
      amount: {
        id: "amount",
        name: "unquotedCompanies.amount",
        placeholder: "Amount held",
        label: "Amount held (£)",
      },
    },
    realProperties: {
      id: "realProperties",
      title: "Real Properties",
      times: {
        id: "times",
        name: "realProperties.times",
        placeholder: "Number of times invested",
        label: "Number of times invested",
      },
      years: {
        id: "years",
        name: "realProperties.years",
        placeholder: "Number of years invested",
        label: "Number of years invested",
      },
      amount: {
        id: "amount",
        name: "realProperties.amount",
        placeholder: "Amount held",
        label: "Amount held (£)",
      },
    },
    otherSophisticatedInvestments: {
      id: "otherSophisticatedInvestments",
      title: "Other sophisticated investments such as unregulated collective investment schemes, foreign currencies, VCTs, commodities or futures",
      times: {
        id: "times",
        name: "otherSophisticatedInvestments.times",
        placeholder: "Number of times invested",
        label: "Number of times invested",
      },
      years: {
        id: "years",
        name: "otherSophisticatedInvestments.years",
        placeholder: "Number of years invested",
        label: "Number of years invested",
      },
      amount: {
        id: "amount",
        name: "otherSophisticatedInvestments.amount",
        placeholder: "Amount held",
        label: "Amount held (£)",
      },
    },
    lentUnquotedCompanies: {
      id: "lentUnquotedCompanies",
      title: "Lent money to an unquoted company",
      times: {
        id: "times",
        name: "lentUnquotedCompanies.times",
        placeholder: "Number of times lent",
        label: "Number of times lent",
      },
      years: {
        id: "years",
        name: "lentUnquotedCompanies.years",
        placeholder: "Number of years lent",
        label: "Number of years lent",
      },
      amount: {
        id: "amount",
        name: "lentUnquotedCompanies.amount",
        placeholder: "Amount lent",
        label: "Amount lent (£) ",
      },
    },
    companyDirector: {
      id: "companyDirector",
      title: "Have you been a director of a company with £1m+ turnover?",
      times: {
        id: "times",
        name: "companyDirector.times",
        placeholder: "Number of times",
        label: "Number of times",
      },
      years: {
        id: "years",
        name: "companyDirector.years",
        placeholder: "Number of years",
        label: "Number of years",
      },
    },
    managementTeam: {
      id: "managementTeam",
      title: "Been part of a management team",
      times: {
        id: "times",
        name: "managementTeam.times",
        placeholder: "Number of times invested",
        label: "Number of times",
      },
      years: {
        id: "years",
        name: "managementTeam.years",
        placeholder: "Number of years",
        label: "Number of years",
      },
    },
    annualInvestment: {
      id: "annualInvestment",
      name: "annualInvestment",
      label: "On average, how much do you invest in these types of investments each year?",
    },
    cashEquivalents: {
      id: "cashEquivalents",
      name: "cashEquivalents",
      label: "How much do you approximately hold in cash or cash equivalents",
    },
    educationHistory: {
      id: "educationHistory",
      name: "educationHistory",
      label: "Please indicate your education history",
    },
    occupation: {
      id: "occupation",
      name: "occupation",
      placeholder: "Please enter your occupation here",
      label: "Occupation",
    },
    relevantProfession: {
      id: "relevantProfession",
      name: "relevantProfession",
      placeholder: "Enter a profession here",
      label:
        "Please also indicate if you have previously held a position in the financial services sector or if you are a relevant professional (e.g. accountant, stockbroker, solicitor)",
    },
    taxationAdvice: {
      id: "taxationAdvice",
      name: "taxationAdvice",
      label: "Have you received any taxation advice?",
    },
    capitalCommitmentsNext10Years: {
      id: "capitalCommitmentsNext10Years",
      name: "capitalCommitmentsNext10Years",
      label: "Are you aware of any capital commitments in the next 10 years?",
    },
    levelOfRisk: {
      id: "levelOfRisk",
      name: "levelOfRisk",
      label: "What level of risk are you prepared to accept with your investments in early stage, unquoted companies?",
    },
    holdInEarlyStageCompanies: {
      id: "holdInEarlyStageCompanies",
      name: "holdInEarlyStageCompanies",
      label: "How much do you propose to hold in early-stage unquoted companies including your subscription to this fund?",
    },
    percentageNetWorth: {
      id: "percentageNetWorth",
      name: "percentageNetWorth",
      label: "What percentage does the above account for your net worth?",
    },
  },
  INVESTMENT_DETAILS_FORM: {
    investmentFrequency: {
      id: "investmentFrequency",
      name: "investmentFrequency",
      label: "How frequently would you like to invest?",
    },
    noOfMonthlyInstallments: {
      id: "noOfMonthlyInstallments",
      name: "noOfMonthlyInstallments",
      placeholder: "Number of months",
      label: "Please confirm the number of monthly instalments",
    },
    amountEachMonthlyInstallment: {
      id: "amountEachMonthlyInstallment",
      name: "amountEachMonthlyInstallment",
      placeholder: "Enter Here",
      label: "Monthly Investment",
    },
    investmentAmount: {
      id: "investmentAmount",
      name: "investmentAmount",
      placeholder: "Enter here",
      label: "Enter the amount you want to invest",
    },
    investmentDate: {
      id: "investmentDate",
      name: "investmentDate",
      label: "Enter the date you want to make this investment",
    },
    dateForMonthlyPayment: {
      id: "dateForMonthlyPayment",
      name: "dateForMonthlyPayment",
      placeholder: "Expected Date",
      label: "What day of the month do you plan to make the payment",
    },
    carryBack: {
      id: "carryBack",
      present: {
        id: "present",
        name: "carryBack.present",
        label: "Are you seeking to claim any tax relief relating to a previous tax year?",
      },
      information: {
        id: "information",
        name: "carryBack.information",
        placeholder: "Please confirm details",
        label: "Confirm details about tax relief relating to previous tax year",
      },
    },
    paymentMethod: {
      id: "paymentMethod",
      name: "paymentMethod",
      label: "Please select your payment method:",
    },
    seis: {
      id: "seis",
      name: "seis",
      placeholder: "SEIS",
      label: "SEIS split",
    },
    eis: {
      id: "eis",
      name: "eis",
      placeholder: "EIS",
      label: "EIS split",
    },
    isCustodyBound: {
      id: "isCustodyBound",
      name: "isCustodyBound",
      label: (custodyAgreementLink: string) => (
        <>
          Please check this to indicate you understand that the investor will become bound by the terms of the{" "}
          <Link href={custodyAgreementLink} target="_blank" rel="noopener noreferrer">
            Custody Agreement
          </Link>{" "}
          upon execution of the application form
        </>
      ),
    },
    isFinancialAdvice: {
      id: "isFinancialAdvice",
      name: "isFinancialAdvice",
      label: "Please tick this to indicate you have not received financial advice from ${manager.name}",
    },
    isReadIM: {
      id: "isReadIM",
      name: "isReadIM",
      label: "Please tick this box to confirm you have read and understood the Information Memorandum",
    },
    waiveCancellationPeriod: {
      id: "waiveCancellationPeriod",
      name: "waiveCancellationPeriod",
      label: "Please select one option for the declaration below:",
    },

    understandDiversifyInvestmentPortfolio: {
      id: "understandDiversifyInvestmentPortfolio",
      name: "understandDiversifyInvestmentPortfolio",
      label: "Please tick this box to confirm that you understand the need to diversify investment portfolios across asset classes",
    },
    understandNoTaxGuarantee: {
      id: "understandNoTaxGuarantee",
      name: "understandNoTaxGuarantee",
      label:
        "Please tick this box to confirm that you understand that there is no guarantee that the tax efficient status of the investment will be obtained or remain",
    },
    understandNoSecondaryMarket: {
      id: "understandNoSecondaryMarket",
      name: "understandNoSecondaryMarket",
      label:
        "Please tick this box to confirm that you understand that there is no secondary market for this investment and that the investment may not be able to be realised early, at market value, or at all",
    },
    understandHighRiskIlliquid: {
      id: "understandHighRiskIlliquid",
      name: "understandHighRiskIlliquid",
      label:
        "Please tick this box to confirm that you understand that this is a high risk, illiquid, speculative investment and that there is the potential to lose all capital invested",
    },
    understandHeldOnTrust: {
      id: "understandHeldOnTrust",
      name: "understandHeldOnTrust",
      label:
        "Please tick this box to acknowledge that your investments will be registered in the name of the nominee but will be held on trust by the nominee and you will remain the beneficial owner of the investments. ",
    },
    doNotWantToBeContacted: {
      id: "doNotWantToBeContacted",
      name: "doNotWantToBeContacted",
      label: (
        <>
          Nova Growth Capital Limited may from time to time contact investors with news updates. Please tick here if you <strong>do not</strong> want
          to receive such updates.
        </>
      ),
    },
  },
} as const;

const API_KEYS = {
  CRAFTY_CLICK: "1d0e7-3706a-abc95-03662",
} as const;

const DAYS_OF_MONTH = Array(31)
  .fill(null)
  .map((_v, i) => ({ label: (i + 1).toString(), value: i + 1 }));

export const NATIONALITY_DATA = [
  { label: "Afghanistan", value: "AF" },
  { label: "Aland Islands", value: "AX" },
  { label: "Albania", value: "AL" },
  { label: "Algeria", value: "DZ" },
  { label: "American Samoa", value: "AS" },
  { label: "Andorra", value: "AD" },
  { label: "Angola", value: "AO" },
  { label: "Anguilla", value: "AI" },
  { label: "Antarctica", value: "AQ" },
  { label: "Antigua and Barbuda", value: "AG" },
  { label: "Argentina", value: "AR" },
  { label: "Armenia", value: "AM" },
  { label: "Aruba", value: "AW" },
  { label: "Australia", value: "AU" },
  { label: "Austria", value: "AT" },
  { label: "Azerbaijan", value: "AZ" },
  { label: "Bahamas", value: "BS" },
  { label: "Bahrain", value: "BH" },
  { label: "Bangladesh", value: "BD" },
  { label: "Barbados", value: "BB" },
  { label: "Belarus", value: "BY" },
  { label: "Belgium", value: "BE" },
  { label: "Belize", value: "BZ" },
  { label: "Benin", value: "BJ" },
  { label: "Bermuda", value: "BM" },
  { label: "Bhutan", value: "BT" },
  { label: "Bolivia", value: "BO" },
  { label: "Bosnia and Herzegovina", value: "BA" },
  { label: "Botswana", value: "BW" },
  { label: "Bouvet Island", value: "BV" },
  { label: "Brazil", value: "BR" },
  { label: "British Indian Ocean Territory", value: "IO" },
  { label: "Brunei Darussalam", value: "BN" },
  { label: "Bulgaria", value: "BG" },
  { label: "Burkina Faso", value: "BF" },
  { label: "Burundi", value: "BI" },
  { label: "Cambodia", value: "KH" },
  { label: "Cameroon", value: "CM" },
  { label: "Canada", value: "CA" },
  { label: "Cape Verde", value: "CV" },
  { label: "Cayman Islands", value: "KY" },
  { label: "Central African Republic", value: "CF" },
  { label: "Chad", value: "TD" },
  { label: "Chile", value: "CL" },
  { label: "China", value: "CN" },
  { label: "Christmas Island", value: "CX" },
  { label: "Cocos (Keeling) Islands", value: "CC" },
  { label: "Colombia", value: "CO" },
  { label: "Comoros", value: "KM" },
  { label: "Congo", value: "CG" },
  { label: "Congo, The Democratic Republic of the", value: "CD" },
  { label: "Cook Islands", value: "CK" },
  { label: "Costa Rica", value: "CR" },
  { label: "Cote D'Ivoire", value: "CI" },
  { label: "Croatia", value: "HR" },
  { label: "Cuba", value: "CU" },
  { label: "Cyprus", value: "CY" },
  { label: "Czech Republic", value: "CZ" },
  { label: "Denmark", value: "DK" },
  { label: "Djibouti", value: "DJ" },
  { label: "Dominica", value: "DM" },
  { label: "Dominican Republic", value: "DO" },
  { label: "Ecuador", value: "EC" },
  { label: "Egypt", value: "EG" },
  { label: "El Salvador", value: "SV" },
  { label: "Equatorial Guinea", value: "GQ" },
  { label: "Eritrea", value: "ER" },
  { label: "Estonia", value: "EE" },
  { label: "Ethiopia", value: "ET" },
  { label: "Falkland Islands (Malvinas)", value: "FK" },
  { label: "Faroe Islands", value: "FO" },
  { label: "Fiji", value: "FJ" },
  { label: "Finland", value: "FI" },
  { label: "France", value: "FR" },
  { label: "French Guiana", value: "GF" },
  { label: "French Polynesia", value: "PF" },
  { label: "French Southern Territories", value: "TF" },
  { label: "Gabon", value: "GA" },
  { label: "Gambia", value: "GM" },
  { label: "Georgia", value: "GE" },
  { label: "Germany", value: "DE" },
  { label: "Ghana", value: "GH" },
  { label: "Gibraltar", value: "GI" },
  { label: "Greece", value: "GR" },
  { label: "Greenland", value: "GL" },
  { label: "Grenada", value: "GD" },
  { label: "Guadeloupe", value: "GP" },
  { label: "Guam", value: "GU" },
  { label: "Guatemala", value: "GT" },
  { label: "Guernsey", value: "GG" },
  { label: "Guinea", value: "GN" },
  { label: "Guinea-Bissau", value: "GW" },
  { label: "Guyana", value: "GY" },
  { label: "Haiti", value: "HT" },
  { label: "Heard Island and Mcdonald Islands", value: "HM" },
  { label: "Holy See (Vatican City State)", value: "VA" },
  { label: "Honduras", value: "HN" },
  { label: "Hong Kong", value: "HK" },
  { label: "Hungary", value: "HU" },
  { label: "Iceland", value: "IS" },
  { label: "India", value: "IN" },
  { label: "Indonesia", value: "ID" },
  { label: "Iran, Islamic Republic Of", value: "IR" },
  { label: "Iraq", value: "IQ" },
  { label: "Ireland", value: "IE" },
  { label: "Isle of Man", value: "IM" },
  { label: "Israel", value: "IL" },
  { label: "Italy", value: "IT" },
  { label: "Jamaica", value: "JM" },
  { label: "Japan", value: "JP" },
  { label: "Jersey", value: "JE" },
  { label: "Jordan", value: "JO" },
  { label: "Kazakhstan", value: "KZ" },
  { label: "Kenya", value: "KE" },
  { label: "Kiribati", value: "KI" },
  { label: "Korea, Democratic People's Republic of", value: "KP" },
  { label: "Korea, Republic of", value: "KR" },
  { label: "Kuwait", value: "KW" },
  { label: "Kyrgyzstan", value: "KG" },
  { label: "Lao People's Democratic Republic", value: "LA" },
  { label: "Latvia", value: "LV" },
  { label: "Lebanon", value: "LB" },
  { label: "Lesotho", value: "LS" },
  { label: "Liberia", value: "LR" },
  { label: "Libyan Arab Jamahiriya", value: "LY" },
  { label: "Liechtenstein", value: "LI" },
  { label: "Lithuania", value: "LT" },
  { label: "Luxembourg", value: "LU" },
  { label: "Macao", value: "MO" },
  { label: "Macedonia, The Former Yugoslav Republic of", value: "MK" },
  { label: "Madagascar", value: "MG" },
  { label: "Malawi", value: "MW" },
  { label: "Malaysia", value: "MY" },
  { label: "Maldives", value: "MV" },
  { label: "Mali", value: "ML" },
  { label: "Malta", value: "MT" },
  { label: "Marshall Islands", value: "MH" },
  { label: "Martinique", value: "MQ" },
  { label: "Mauritania", value: "MR" },
  { label: "Mauritius", value: "MU" },
  { label: "Mayotte", value: "YT" },
  { label: "Mexico", value: "MX" },
  { label: "Micronesia, Federated States of", value: "FM" },
  { label: "Moldova, Republic of", value: "MD" },
  { label: "Monaco", value: "MC" },
  { label: "Mongolia", value: "MN" },
  { label: "Montserrat", value: "MS" },
  { label: "Morocco", value: "MA" },
  { label: "Mozambique", value: "MZ" },
  { label: "Myanmar", value: "MM" },
  { label: "Namibia", value: "NA" },
  { label: "Nauru", value: "NR" },
  { label: "Nepal", value: "NP" },
  { label: "Netherlands", value: "NL" },
  { label: "Netherlands Antilles", value: "AN" },
  { label: "New Caledonia", value: "NC" },
  { label: "New Zealand", value: "NZ" },
  { label: "Nicaragua", value: "NI" },
  { label: "Niger", value: "NE" },
  { label: "Nigeria", value: "NG" },
  { label: "Niue", value: "NU" },
  { label: "Norfolk Island", value: "NF" },
  { label: "Northern Mariana Islands", value: "MP" },
  { label: "Norway", value: "NO" },
  { label: "Oman", value: "OM" },
  { label: "Pakistan", value: "PK" },
  { label: "Palau", value: "PW" },
  { label: "Palestinian Territory, Occupied", value: "PS" },
  { label: "Panama", value: "PA" },
  { label: "Papua New Guinea", value: "PG" },
  { label: "Paraguay", value: "PY" },
  { label: "Peru", value: "PE" },
  { label: "Philippines", value: "PH" },
  { label: "Pitcairn", value: "PN" },
  { label: "Poland", value: "PL" },
  { label: "Portugal", value: "PT" },
  { label: "Puerto Rico", value: "PR" },
  { label: "Qatar", value: "QA" },
  { label: "Reunion", value: "RE" },
  { label: "Romania", value: "RO" },
  { label: "Russian Federation", value: "RU" },
  { label: "Rwanda", value: "RW" },
  { label: "Saint Helena", value: "SH" },
  { label: "Saint Kitts and Nevis", value: "KN" },
  { label: "Saint Lucia", value: "LC" },
  { label: "Saint Pierre and Miquelon", value: "PM" },
  { label: "Saint Vincent and the Grenadines", value: "VC" },
  { label: "Samoa", value: "WS" },
  { label: "San Marino", value: "SM" },
  { label: "Sao Tome and Principe", value: "ST" },
  { label: "Saudi Arabia", value: "SA" },
  { label: "Senegal", value: "SN" },
  { label: "Serbia and Montenegro", value: "CS" },
  { label: "Seychelles", value: "SC" },
  { label: "Sierra Leone", value: "SL" },
  { label: "Singapore", value: "SG" },
  { label: "Slovakia", value: "SK" },
  { label: "Slovenia", value: "SI" },
  { label: "Solomon Islands", value: "SB" },
  { label: "Somalia", value: "SO" },
  { label: "South Africa", value: "ZA" },
  { label: "South Georgia and the South Sandwich Islands", value: "GS" },
  { label: "Spain", value: "ES" },
  { label: "Sri Lanka", value: "LK" },
  { label: "Sudan", value: "SD" },
  { label: "Suriname", value: "SR" },
  { label: "Svalbard and Jan Mayen", value: "SJ" },
  { label: "Swaziland", value: "SZ" },
  { label: "Sweden", value: "SE" },
  { label: "Switzerland", value: "CH" },
  { label: "Syrian Arab Republic", value: "SY" },
  { label: "Taiwan, Province of China", value: "TW" },
  { label: "Tajikistan", value: "TJ" },
  { label: "Tanzania, United Republic of", value: "TZ" },
  { label: "Thailand", value: "TH" },
  { label: "Timor-Leste", value: "TL" },
  { label: "Togo", value: "TG" },
  { label: "Tokelau", value: "TK" },
  { label: "Tonga", value: "TO" },
  { label: "Trinidad and Tobago", value: "TT" },
  { label: "Tunisia", value: "TN" },
  { label: "Turkey", value: "TR" },
  { label: "Turkmenistan", value: "TM" },
  { label: "Turks and Caicos Islands", value: "TC" },
  { label: "Tuvalu", value: "TV" },
  { label: "Uganda", value: "UG" },
  { label: "Ukraine", value: "UA" },
  { label: "United Arab Emirates", value: "AE" },
  { label: "United Kingdom", value: "GB" },
  { label: "United States", value: "US" },
  { label: "United States Minor Outlying Islands", value: "UM" },
  { label: "Uruguay", value: "UY" },
  { label: "Uzbekistan", value: "UZ" },
  { label: "Vanuatu", value: "VU" },
  { label: "Venezuela", value: "VE" },
  { label: "Viet Nam", value: "VN" },
  { label: "Virgin Islands, British", value: "VG" },
  { label: "Virgin Islands, U.S.", value: "VI" },
  { label: "Wallis and Futuna", value: "WF" },
  { label: "Western Sahara", value: "EH" },
  { label: "Yemen", value: "YE" },
  { label: "Zambia", value: "ZM" },
  { label: "Zimbabwe", value: "ZW" },
] as const;

export const CURRENCY_CODES = [
  { label: "United Kingdom Pound - (GBP)", value: "GBP" },
  { label: "United States Dollar - (USD)", value: "USD" },
  { label: "Euro - (EUR)", value: "EUR" },
  { label: "United Arab Emirates Dirham - (AED)", value: "AED" },
  { label: "Afghanistan Afghani - (AFN)", value: "AFN" },
  { label: "Albania Lek - (ALL)", value: "ALL" },
  { label: "Armenia Dram - (AMD)", value: "AMD" },
  { label: "Netherlands Antilles Guilder - (ANG)", value: "ANG" },
  { label: "Angola Kwanza - (AOA)", value: "AOA" },
  { label: "Argentina Peso - (ARS)", value: "ARS" },
  { label: "Australia Dollar - (AUD)", value: "AUD" },
  { label: "Aruba Guilder - (AWG)", value: "AWG" },
  { label: "Azerbaijan Manat - (AZN)", value: "AZN" },
  { label: "Bosnia and Herzegovina Convertible Mark - (BAM)", value: "BAM" },
  { label: "Barbados Dollar - (BBD)", value: "BBD" },
  { label: "Bangladesh Taka - (BDT)", value: "BDT" },
  { label: "Bulgaria Lev - (BGN)", value: "BGN" },
  { label: "Bahrain Dinar - (BHD)", value: "BHD" },
  { label: "Burundi Franc - (BIF)", value: "BIF" },
  { label: "Bermuda Dollar - (BMD)", value: "BMD" },
  { label: "Brunei Darussalam Dollar - (BND)", value: "BND" },
  { label: "Bolivia Bolíviano - (BOB)", value: "BOB" },
  { label: "Brazil Real - (BRL)", value: "BRL" },
  { label: "Bahamas Dollar - (BSD)", value: "BSD" },
  { label: "Bhutan Ngultrum - (BTN)", value: "BTN" },
  { label: "Botswana Pula - (BWP)", value: "BWP" },
  { label: "Belarus Ruble - (BYN)", value: "BYN" },
  { label: "Belize Dollar - (BZD)", value: "BZD" },
  { label: "Canada Dollar - (CAD)", value: "CAD" },
  { label: "Congo/Kinshasa Franc - (CDF)", value: "CDF" },
  { label: "Switzerland Franc - (CHF)", value: "CHF" },
  { label: "Chile Peso - (CLP)", value: "CLP" },
  { label: "China Yuan Renminbi - (CNY)", value: "CNY" },
  { label: "Colombia Peso - (COP)", value: "COP" },
  { label: "Costa Rica Colon - (CRC)", value: "CRC" },
  { label: "Cuba Convertible Peso - (CUC)", value: "CUC" },
  { label: "Cuba Peso - (CUP)", value: "CUP" },
  { label: "Cape Verde Escudo - (CVE)", value: "CVE" },
  { label: "Czech Republic Koruna - (CZK)", value: "CZK" },
  { label: "Djibouti Franc - (DJF)", value: "DJF" },
  { label: "Denmark Krone - (DKK)", value: "DKK" },
  { label: "Dominican Republic Peso - (DOP)", value: "DOP" },
  { label: "Algeria Dinar - (DZD)", value: "DZD" },
  { label: "Egypt Pound - (EGP)", value: "EGP" },
  { label: "Eritrea Nakfa - (ERN)", value: "ERN" },
  { label: "Ethiopia Birr - (ETB)", value: "ETB" },
  { label: "Fiji Dollar - (FJD)", value: "FJD" },
  { label: "Falkland Islands (Malvinas) Pound - (FKP)", value: "FKP" },
  { label: "Georgia Lari - (GEL)", value: "GEL" },
  { label: "Guernsey Pound - (GGP)", value: "GGP" },
  { label: "Ghana Cedi - (GHS)", value: "GHS" },
  { label: "Gibraltar Pound - (GIP)", value: "GIP" },
  { label: "Gambia Dalasi - (GMD)", value: "GMD" },
  { label: "Guinea Franc - (GNF)", value: "GNF" },
  { label: "Guatemala Quetzal - (GTQ)", value: "GTQ" },
  { label: "Guyana Dollar - (GYD)", value: "GYD" },
  { label: "Hong Kong Dollar - (HKD)", value: "HKD" },
  { label: "Honduras Lempira - (HNL)", value: "HNL" },
  { label: "Croatia Kuna - (HRK)", value: "HRK" },
  { label: "Haiti Gourde - (HTG)", value: "HTG" },
  { label: "Hungary Forint - (HUF)", value: "HUF" },
  { label: "Indonesia Rupiah - (IDR)", value: "IDR" },
  { label: "Israel Shekel - (ILS)", value: "ILS" },
  { label: "Isle of Man Pound - (IMP)", value: "IMP" },
  { label: "India Rupee - (INR)", value: "INR" },
  { label: "Iraq Dinar - (IQD)", value: "IQD" },
  { label: "Iran Rial - (IRR)", value: "IRR" },
  { label: "Iceland Krona - (ISK)", value: "ISK" },
  { label: "Jersey Pound - (JEP)", value: "JEP" },
  { label: "Jamaica Dollar - (JMD)", value: "JMD" },
  { label: "Jordan Dinar - (JOD)", value: "JOD" },
  { label: "Japan Yen - (JPY)", value: "JPY" },
  { label: "Kenya Shilling - (KES)", value: "KES" },
  { label: "Kyrgyzstan Som - (KGS)", value: "KGS" },
  { label: "Cambodia Riel - (KHR)", value: "KHR" },
  { label: "Comorian Franc - (KMF)", value: "KMF" },
  { label: "Korea (North) Won - (KPW)", value: "KPW" },
  { label: "Korea (South) Won - (KRW)", value: "KRW" },
  { label: "Kuwait Dinar - (KWD)", value: "KWD" },
  { label: "Cayman Islands Dollar - (KYD)", value: "KYD" },
  { label: "Kazakhstan Tenge - (KZT)", value: "KZT" },
  { label: "Laos Kip - (LAK)", value: "LAK" },
  { label: "Lebanon Pound - (LBP)", value: "LBP" },
  { label: "Sri Lanka Rupee - (LKR)", value: "LKR" },
  { label: "Liberia Dollar - (LRD)", value: "LRD" },
  { label: "Lesotho Loti - (LSL)", value: "LSL" },
  { label: "Libya Dinar - (LYD)", value: "LYD" },
  { label: "Morocco Dirham - (MAD)", value: "MAD" },
  { label: "Moldova Leu - (MDL)", value: "MDL" },
  { label: "Madagascar Ariary - (MGA)", value: "MGA" },
  { label: "Macedonia Denar - (MKD)", value: "MKD" },
  { label: "Myanmar (Burma) Kyat - (MMK)", value: "MMK" },
  { label: "Mongolia Tughrik - (MNT)", value: "MNT" },
  { label: "Macau Pataca - (MOP)", value: "MOP" },
  { label: "Mauritania Ouguiya - (MRU)", value: "MRU" },
  { label: "Mauritius Rupee - (MUR)", value: "MUR" },
  { label: "Maldives (Maldive Islands) Rufiyaa - (MVR)", value: "MVR" },
  { label: "Malawi Kwacha - (MWK)", value: "MWK" },
  { label: "Mexico Peso - (MXN)", value: "MXN" },
  { label: "Malaysia Ringgit - (MYR)", value: "MYR" },
  { label: "Mozambique Metical - (MZN)", value: "MZN" },
  { label: "Namibia Dollar - (NAD)", value: "NAD" },
  { label: "Nigeria Naira - (NGN)", value: "NGN" },
  { label: "Nicaragua Cordoba - (NIO)", value: "NIO" },
  { label: "Norway Krone - (NOK)", value: "NOK" },
  { label: "Nepal Rupee - (NPR)", value: "NPR" },
  { label: "New Zealand Dollar - (NZD)", value: "NZD" },
  { label: "Oman Rial - (OMR)", value: "OMR" },
  { label: "Panama Balboa - (PAB)", value: "PAB" },
  { label: "Peru Sol - (PEN)", value: "PEN" },
  { label: "Papua New Guinea Kina - (PGK)", value: "PGK" },
  { label: "Philippines Peso - (PHP)", value: "PHP" },
  { label: "Pakistan Rupee - (PKR)", value: "PKR" },
  { label: "Poland Zloty - (PLN)", value: "PLN" },
  { label: "Paraguay Guarani - (PYG)", value: "PYG" },
  { label: "Qatar Riyal - (QAR)", value: "QAR" },
  { label: "Romania Leu - (RON)", value: "RON" },
  { label: "Serbia Dinar - (RSD)", value: "RSD" },
  { label: "Russia Ruble - (RUB)", value: "RUB" },
  { label: "Rwanda Franc - (RWF)", value: "RWF" },
  { label: "Saudi Arabia Riyal - (SAR)", value: "SAR" },
  { label: "Solomon Islands Dollar - (SBD)", value: "SBD" },
  { label: "Seychelles Rupee - (SCR)", value: "SCR" },
  { label: "Sudan Pound - (SDG)", value: "SDG" },
  { label: "Sweden Krona - (SEK)", value: "SEK" },
  { label: "Singapore Dollar - (SGD)", value: "SGD" },
  { label: "Saint Helena Pound - (SHP)", value: "SHP" },
  { label: "Sierra Leone Leone - (SLL)", value: "SLL" },
  { label: "Somalia Shilling - (SOS)", value: "SOS" },
  { label: "Seborga Luigino - (SPL)", value: "SPL" },
  { label: "Suriname Dollar - (SRD)", value: "SRD" },
  { label: "São Tomé and Príncipe Dobra - (STN)", value: "STN" },
  { label: "El Salvador Colon - (SVC)", value: "SVC" },
  { label: "Syria Pound - (SYP)", value: "SYP" },
  { label: "eSwatini Lilangeni - (SZL)", value: "SZL" },
  { label: "Thailand Baht - (THB)", value: "THB" },
  { label: "Tajikistan Somoni - (TJS)", value: "TJS" },
  { label: "Turkmenistan Manat - (TMT)", value: "TMT" },
  { label: "Tunisia Dinar - (TND)", value: "TND" },
  { label: "Tonga Paanga - (TOP)", value: "TOP" },
  { label: "Turkey Lira - (TRY)", value: "TRY" },
  { label: "Trinidad and Tobago Dollar - (TTD)", value: "TTD" },
  { label: "Tuvalu Dollar - (TVD)", value: "TVD" },
  { label: "Taiwan New Dollar - (TWD)", value: "TWD" },
  { label: "Tanzania Shilling - (TZS)", value: "TZS" },
  { label: "Ukraine Hryvnia - (UAH)", value: "UAH" },
  { label: "Uganda Shilling - (UGX)", value: "UGX" },
  { label: "Uruguay Peso - (UYU)", value: "UYU" },
  { label: "Uzbekistan Som - (UZS)", value: "UZS" },
  { label: "Venezuela Bolívar - (VEF)", value: "VEF" },
  { label: "Viet Nam Dong - (VND)", value: "VND" },
  { label: "Vanuatu Vatu - (VUV)", value: "VUV" },
  { label: "Samoa Tala - (WST)", value: "WST" },
  { label: "Communauté Financière Africaine (BEAC) CFA Franc BEAC - (XAF)", value: "XAF" },
  { label: "East Caribbean Dollar - (XCD)", value: "XCD" },
  { label: "International Monetary Fund (IMF) Special Drawing Rights - (XDR)", value: "XDR" },
  { label: "Communauté Financière Africaine (BCEAO) Franc - (XOF)", value: "XOF" },
  { label: "Comptoirs Français du Pacifique (CFP) Franc - (XPF)", value: "XPF" },
  { label: "Yemen Rial - (YER)", value: "YER" },
  { label: "South Africa Rand - (ZAR)", value: "ZAR" },
  { label: "Zambia Kwacha - (ZMW)", value: "ZMW" },
  { label: "Zimbabwe Dollar - (ZWD)", value: "ZWD" },
] as const;
export const CertificationForm = {
  values: {
    id: "values",
    title: "",
    understandsRisk: {
      id: "understandsRisk",
      name: "values.understandsRisk",
      label:
        "Please tick this box to accept that the investments to which the promotions on this website relate may expose you to a significant risk of losing all of the money or other property involved.",
    },

    understandsSelfCert: {
      id: "understandsSelfCert",
      name: "values.understandsSelfCert",
      label:
        "Please tick this box to confirm that you understand that you are being asked to self-certify because the investments to which the promotions will relate are considered to be unsuitable for the majority of UK investors.",
    },
    signingConfirmation: {
      id: "signingConfirmation",
      name: "values.signingConfirmation",
      label: (
        <>
          I confirm that the above statement is true as at this date: <strong>{DateTime.now().toFormat("d MMMM yyyy")}</strong>
        </>
      ),
    },
    hasLargeIncome: {
      id: "hasLargeIncome",
      name: "values.hasLargeIncome",
      label: (
        <p>
          A) An annual <b>income of £100,000 or more?</b> Income does <u>NOT</u> include any one-off pension withdrawals.
        </p>
      ),
    },
    netIncome: {
      id: "netIncome",
      name: "values.netIncome",
      label: "If yes, please specify your income (as defined above) to the nearest £10,000 in the last financial year.",
    },
    hasLargeNetAssets: {
      id: "hasLargeNetAssets",
      name: "values.hasLargeNetAssets",
      label: (
        <p>
          B) <b>Net assets of £250,000 or more?</b> Net assets do <u>NOT</u> include: your home (primary residence), any loan secured on it or any
          equity released from it, your pension (or any pension withdrawals) or any rights under insurance contracts. Net assets are total assets
          minus any debts you owe.
        </p>
      ),
    },
    netAssets: {
      id: "netAssets",
      name: "values.netAssets",
      label: "If yes, please specify your net assets (as defined above) to the nearest £100,000 in the last financial year",
    },
    selfCertifiedCompanyName: {
      id: "selfCertifiedCompanyName",
      name: "values.selfCertifiedCompanyName",
      label: "Please provide the company name you were a director of for this period.",
      placeholder: "Company name",
    },

    financeProviderForSMEsOrPEWorker: {
      id: "financeProviderForSMEsOrPEWorker",
      name: "values.financeProviderForSMEsOrPEWorker",
      label:
        "A) Worked in a professional capacity in the private equity sector or in the provision of finance for small and medium enterprises, in the last two years?",
    },
    financeProviderForSMEsOrPEWorkerDetail: {
      id: "financeProviderForSMEsOrPEWorkerDetail",
      name: "values.financeProviderForSMEsOrPEWorkerDetail",
      label: "If yes, what is/was the name of the business or organisation?",
    },
    directorOfCompanyWithAnnualTurnoverOver1M: {
      id: "directorOfCompanyWithAnnualTurnoverOver1M",
      name: "values.directorOfCompanyWithAnnualTurnoverOver1M",
      label: "B) Been the director of a company with an annual turnover of at least £1 million, in the last two years?",
    },
    directorOfCompanyWithAnnualTurnoverOver1MDetail: {
      id: "directorOfCompanyWithAnnualTurnoverOver1MDetail",
      name: "values.directorOfCompanyWithAnnualTurnoverOver1MDetail",
      label: "If yes, what is/was the name of the company, and its Companies House number (or internation equivalent)?",
    },
    multipleInvestmentsUnlistedCompany: {
      id: "multipleInvestmentsUnlistedCompany",
      name: "values.multipleInvestmentsUnlistedCompany",
      label: "C) Made two or more investments in an unlisted company?",
    },
    multipleInvestmentsUnlistedCompanyDetail: {
      id: "multipleInvestmentsUnlistedCompanyDetail",
      name: "values.multipleInvestmentsUnlistedCompanyDetail",
      label: "If yes, how many investments in unlisted companies have you made in the last two years?",
      props: {
        disableMonetaryFormat: true,
        formatProps: {
          min: 0,
          max: 1000,
          decimalScale: 0,
        },
      },
    },
    businessAngelNetworkMemberOver6Months: {
      id: "businessAngelNetworkMemberOver6Months",
      name: "values.businessAngelNetworkMemberOver6Months",
      label: "D) Been a member of a network or syndicate of business angels for more than six months, and are still a member?",
    },
    businessAngelNetworkMemberOver6MonthsDetail: {
      id: "businessAngelNetworkMemberOver6MonthsDetail",
      name: "values.businessAngelNetworkMemberOver6MonthsDetail",
      label: "If yes, what is the name of the network or syndicate?",
    },
    investedInHighRiskInvestments: {
      id: "investedInHighRiskInvestments",
      name: "values.investedInHighRiskInvestments",
      label: "A) In the past twelve months have you invested less than 10% of your net assets in high-risk investments (as defined above)?",
      options: [
        { label: "Yes (I have invested less than 10% of my net assets)", value: true },
        { label: "No (I have invested more than 10% of my net assets)", value: false },
      ],
    },
    investedInHighRiskInvestmentsPercentage: {
      id: "investedInHighRiskInvestmentsPercentage",
      name: "values.investedInHighRiskInvestmentsPercentage",
      label: "over the last twelve months roughly what percentage of your net assets have you invested in high-risk investments (as defined above)?",
      props: {
        suffix: "%",
        disableMonetaryFormat: true,
        formatProps: {
          min: 0,
          max: 100,
          decimalScale: 2,
        },
      },
    },
    limitHighRiskInvestment: {
      id: "limitHighRiskInvestment",
      name: "values.limitHighRiskInvestment",
      label:
        "B) In the next twelve months do you intend to limit your investment in high-risk investments (as defined above) to less than 10% of your net assets?",
      options: [
        { label: "Yes (I intend to invest less than 10% of my net assets)", value: true },
        { label: "No (I intend to invest more than 10% of my net assets)", value: false },
      ],
    },
    limitHighRiskInvestmentPercentage: {
      id: "limitHighRiskInvestmentPercentage",
      name: "values.limitHighRiskInvestmentPercentage",
      label:
        "in the next twelve months roughly what percentage of your net assets do you intend to invest in high-risk investments {as defined above)? ",
      props: {
        suffix: "%",
        disableMonetaryFormat: true,
        formatProps: {
          min: 0,
          max: 100,
          decimalScale: 2,
        },
      },
    },
    notApplicable: {
      id: "notApplicable",
      name: "values.notApplicable",
      label: "This does not apply to me.",
    },
  },

  atLeastOneTrue: {
    id: "atLeastOneTrue",
    name: "atLeastOneTrue",
    label: "Please select Yes for at least one of the cases above",
  },
} as const;

enum CertificationType {
  SelfSophisticated = "sophisticated",
  HighNetWorth = "highNetWorth",
  Restricted = "restricted",
}

export const OPEN_DEALS_OPTIONS = {
  openDeals: "Open Deals",
  dealsMoreThanTenPounds: "Deals with more than £10 left in the deal",
  activeDeals: "Active Deals",
  eisDeals: "Deals which are eligible for EIS investment",
  seisDeals: "Deals which are eligible for SEIS investment",
} as const;

export {
  USER_TYPES,
  ADVISOR_TYPES,
  ROUTES,
  BASE_API,
  STORAGE_KEYS,
  TRACKED_EVENTS,
  IM,
  API_KEYS,
  DAYS_OF_MONTH,
  FORM_FIELDS_DATA,
  APPLICATION_STATES,
  CertificationType,
  UsCitizen,
};
