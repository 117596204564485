import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import React from 'react';
import ShareTransactionForm from './ShareTransactionForm.view';

const ShareTransactionDialog = ({ onClose, shareTransaction, ...props }) => (
  <Dialog onClose={onClose} {...props}>
    <DialogTitle>Add Share Transaction</DialogTitle>
    <DialogContent>
      <ShareTransactionForm shareTransaction={shareTransaction} onClose={onClose} />
    </DialogContent>
  </Dialog>
);
export default ShareTransactionDialog;
