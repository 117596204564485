import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { TRACKED_EVENTS } from "../utils/constants";

const { INPUT } = TRACKED_EVENTS;

const useStyles = makeStyles(
  () => ({
    trackInput: {
      maxWidth: "100%",
    },
  }),
  { name: "TrackInputComponent" },
);

const TrackInput = ({ children, category, label, value, ...props }) => {
  const classes = useStyles(props);

  return (
    <div
      className={clsx(classes.trackInput, "fs-exclude")}
      onBlurCapture={() => {
        const { dataLayer } = globalThis;
        if (value && category && label && dataLayer) {
          dataLayer.push(
            { event: INPUT.COMPLETED },
            {
              category,
              label,
            },
          );
        }
      }}
      {...props}
    >
      {children}
    </div>
  );
};

export default TrackInput;
