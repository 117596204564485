import { makeStyles, Typography, TypographyProps } from "@material-ui/core";
import clsx from "clsx";
import React from "react";

interface Props extends TypographyProps {
  disableTopMargin?: boolean;
}

const useStyles = makeStyles(
  (theme) => ({
    errorTypography: {
      marginTop: theme.spacing(1),
    },
  }),
  { name: "ErrorTextComponent" },
);

const ErrorText: React.FC<Props> = ({ className, disableTopMargin, ...props }) => {
  const classes = useStyles(props);
  return props.children && typeof props.children === "string" ? (
    <Typography
      variant="body2"
      color="error"
      align="right"
      className={clsx(className, { [classes.errorTypography]: !disableTopMargin })}
      {...props}
    />
  ) : null;
};

export default ErrorText;
export type ErrorTextComponentProps = Props;
