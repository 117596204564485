import { createStyles, makeStyles, Typography, TypographyProps } from "@material-ui/core";
import SectorIcon from "client/components/SectorIcon.component";
import clsx from "clsx";
import React, { FC } from "react";

interface Props extends TypographyProps {
  sector: string | null | undefined;
}

const useStyles = makeStyles(
  () =>
    createStyles({
      container: {
        display: "flex",
        alignItems: "center",
        textTransform: "none",
      },
      icon: {
        width: "auto",
        height: "1em",
        marginLeft: "0.5em",
      },
    }),
  { name: "SectorDetailsComponent" },
);

const SectorDetails: FC<Props> = ({ sector, className, ...props }) => {
  const classes = useStyles(props);

  return (
    <Typography variant="body2" {...props} className={clsx(classes.container, className)}>
      {sector ?? "Other"}
      <SectorIcon sector={sector} className={classes.icon} fontSize="inherit" />
    </Typography>
  );
};

export default SectorDetails;
