import { Container, makeStyles, Typography } from "@material-ui/core";
import Markdown from "client/components/Markdown.component";
import { ConfigContext } from "client/context/config.context";
import React, { Fragment, useContext } from "react";
import { EntryInfoPanel } from "server/services/fund/fund.types";

interface Props {
  infoPanel?: EntryInfoPanel | null;
}

const useStyles = makeStyles(
  (theme) => ({
    normalLogo: {
      width: "300px",
      height: "auto",
      [theme.breakpoints.down("sm")]: {
        width: "180px",
      },
    },
    infoContent: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      minHeight: "100vh",
    },
    bodyContent: {
      "& .MuiTypography-root": {
        color: theme.palette.primary.contrastText,
      },
      "& hr": {
        borderColor: theme.palette.primary.contrastText,
      },
    },
    header: {
      paddingBottom: theme.spacing(2),
      color: theme.palette.text.secondary,
      maxWidth: "425px",
      "& .MuiTypography-root": {
        textTransform: "uppercase",
        fontSize: "4em",
        fontWeight: "800",
      },
      [theme.breakpoints.up("xl")]: {
        maxWidth: "625px",
      },
      height: "auto",
    },
    sectionA: {
      position: "relative",
      bottom: "0",
    },
    sectionB: {
      "& .MuiTypography-root": {
        textAlign: "center",
        padding: "3.5em 6em 3.5em 6em",
        [theme.breakpoints.down("sm")]: {
          padding: "3em 1em 3em 1em",
        },
      },
    },
  }),
  { name: "EntryPageView" },
);

const InfoPane: React.FC<Props> = ({ infoPanel, ...props }) => {
  const classes = useStyles(props);
  const { logo } = useContext(ConfigContext);

  if (!infoPanel) return <div>No info page!</div>;

  return (
    <div className={classes.infoContent}>
      {/* header */}
      <Container>
        <img className={classes.normalLogo} alt="Investor Portal Logo" src={logo} />
      </Container>

      {/* body */}
      <Container className={classes.bodyContent}>
        {/* splash logo */}
        {infoPanel.header ? (
          <div className={classes.header}>
            <Typography variant="h1">{infoPanel.header}</Typography>
          </div>
        ) : (
          <Typography variant="h1">INVESTMENT FUND</Typography>
        )}

        {/* section A */}
        {infoPanel.sectionA && (
          <div className={classes.sectionA}>
            {infoPanel.sectionA.map((item, index) => (
              <Fragment key={index}>
                <hr />
                <Markdown>{item.toString()}</Markdown>
              </Fragment>
            ))}
          </div>
        )}

        {/* section B */}
        {infoPanel.sectionB && (
          <div className={classes.sectionB}>
            {infoPanel.sectionB.map((item, index) => (
              <Fragment key={index}>
                <hr />
                <Markdown>{item.toString()}</Markdown>
              </Fragment>
            ))}
          </div>
        )}
      </Container>
    </div>
  );
};

export default InfoPane;
