import { Typography } from '@material-ui/core';
import React from 'react';

const NumberedSubTitle = ({ title, number, ...props }) => (
  <Typography variant="h2" paragraph {...props}>
    {number}
    {number && (
      <Typography variant="inherit" color="primary">
        .&nbsp;
      </Typography>
    )}
    {title}
  </Typography>
);

export default NumberedSubTitle;
