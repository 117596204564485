import { FC, useEffect, useMemo, useState } from "react";
import { LeanShareTransaction, Subscription, ShareTransactionAggregates } from "server/services/shareTransaction/shareTransaction.types";
import { roundTo2DP } from "../Deployments/utils";
import AdminTable from "../AdminTable.view";
import { ColumnDefinition } from "@wearenova/mui-data-table";
import { formatThousands } from "client/utils/helpers";
import { Button, DialogContent, DialogTitle, Grid, Tooltip, Typography, makeStyles } from "@material-ui/core";
import { RoundingErrorWarning } from "./RoundingErrorWarning";
import ResponsiveDialog from "client/components/ResponsiveDialog.component";
import * as Yup from "yup";
import { RoundingErrorSummary } from "./RoundingErrorSummary";
import useRoundingError from "client/hooks/useRoundingError.hooks";
interface Props {
  shareTransaction: LeanShareTransaction;
  handleAdjustmentFactor: (subscriptionIndex: number, adjustmentFactor: number) => void;
  resetAdjustments: () => void;
  roundingErrorHandler: (roundingError: boolean) => void;
}

const useStyles = makeStyles(
  () => ({
    table: {
      maxHeight: "calc(100vh)",
      overflow: "scroll",
    },
  }),
  { name: "RoundingErrorManager" },
);

const getShareTransactionsTableDefinition = (shareTransactionAggregates: ShareTransactionAggregates): ColumnDefinition<any>[] => {
  return [
    {
      key: "TotalShares",
      title: "Total Shares",
      dataIndex: "noOfShares",
      sorter: true,
      footer: () => shareTransactionAggregates?.noOfShares,
    },
    {
      key: "Amount",
      title: "Amount",
      render: (data: Subscription) => formatThousands((data.noOfShares ?? 0) * shareTransactionAggregates.pricePerShare, undefined, true, 2, 10),
      sorter: true,
      footer: () =>
        `${formatThousands(shareTransactionAggregates?.investmentCost, undefined, true, 2, 10)} (${formatThousands(
          shareTransactionAggregates?.investmentCost2DP,
          2,
          true,
        )})`,
    },
    {
      key: "Amount2DP",
      title: "Amount (2DP)",
      render: (data: Subscription) => formatThousands(roundTo2DP((data.noOfShares ?? 0) * shareTransactionAggregates.pricePerShare), 2),
      sorter: true,
      footer: () => formatThousands(shareTransactionAggregates?.investmentCostFromSubscriptions2DP, 2),
    },
    {
      key: "AdjustmentFactor",
      title: "Adjustment Factor",
      render: (data: Subscription) => formatThousands(data.adjustmentFactor ?? 0, 2),
      sorter: true,
      footer: () => formatThousands(shareTransactionAggregates?.adjustmentFactor, 2),
      editable: {
        type: "number",
        validate: (value, d) => {
          const remainingRoundingError = roundTo2DP(
            shareTransactionAggregates.investmentCostDifference - shareTransactionAggregates.adjustmentFactor + d.data.adjustmentFactor,
          );
          return Yup.number()
            .min(remainingRoundingError, `Value should be between ${remainingRoundingError} and 0`)
            .max(0, `Value should be between ${remainingRoundingError} and 0`)
            .required("Required")
            .validate(value);
        },
      },
    },
    {
      key: "AmountAfterAdjustment",
      title: "Amount(2DP) After Adjustment",
      render: (data: Subscription) =>
        formatThousands(roundTo2DP((data.noOfShares ?? 0) * shareTransactionAggregates.pricePerShare) + (data.adjustmentFactor ?? 0), 2),
      sorter: true,
      footer: () => formatThousands(shareTransactionAggregates.investmentCostFromSubscriptionsAfterAdjustment2DP, 2),
    },
    {
      key: "PricePerShareAfterAdjustment",
      title: "Price Per Share After Adjustment",
      render: (data: Subscription) =>
        formatThousands(
          (roundTo2DP((data.noOfShares ?? 0) * shareTransactionAggregates.pricePerShare) + (data.adjustmentFactor ?? 0)) / (data.noOfShares ?? 1),
          0,
          true,
          2,
          10,
        ),
      sorter: true,
    },
  ] as ColumnDefinition<any>[];
};

const RoundingErrorManager: FC<Props> = ({ shareTransaction, handleAdjustmentFactor, resetAdjustments, roundingErrorHandler, ...props }) => {
  const classes = useStyles(props);
  //this state will be used for showing the dialog through which admin can fix the rounding error
  const [showDetails, setShowDetails] = useState<boolean>(false);

  const { autoFixRoundingError, shareTransactionAggregates, hasRoundingError, hasRoundingErrorResolved, changesConfirmed, setChangesConfirmed } =
    useRoundingError({
      shareTransaction,
      handleAdjustmentFactor,
      resetAdjustments,
      roundingErrorHandler,
    });

  useEffect(() => {
    //whenever price per share or total shares changed in share transaction form, subscriptions adjstment factor should be reset
    setShowDetails(false);

    //excluding dependencies because of formik, it always return new instance of callback function, and we would face infinite loop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shareTransaction.pricePerShare, shareTransaction.totalShares]);

  const shareTransactionsTable = useMemo<ColumnDefinition<any>[]>(
    () => getShareTransactionsTableDefinition(shareTransactionAggregates),
    [shareTransactionAggregates],
  );

  if (!hasRoundingError || changesConfirmed) {
    return null;
  } else {
    return !showDetails ? (
      <RoundingErrorWarning onClick={() => setShowDetails(true)} />
    ) : (
      <ResponsiveDialog open={showDetails} onClose={() => setShowDetails(false)} maxWidth="lg">
        <DialogTitle>Rounding Error Manager</DialogTitle>

        <DialogContent>
          {hasRoundingError ? (
            <Grid>
              <Typography variant="body1">{`The share transaction has encountered a rounding error`}</Typography>
              <br />
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <RoundingErrorSummary
                    shareTransactionAggregates={shareTransactionAggregates}
                    shareTransaction={shareTransaction}
                    hasRoundingErrorResolved={hasRoundingErrorResolved}
                  />
                </Grid>

                <Grid item xs={12} className={classes.table}>
                  <AdminTable
                    tableData={shareTransaction?.subscriptions}
                    tableStructure={shareTransactionsTable}
                    disablePagination={true}
                    onEdit={({ value }, record) => {
                      const subscriptionIndex = shareTransaction.subscriptions.findIndex((subscription) => subscription === record);
                      handleAdjustmentFactor(subscriptionIndex, (value as number) ?? 0);
                    }}
                    enableHiddenColumns={false}
                  />
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Button onClick={resetAdjustments}>Reset Changes</Button>
                  </Grid>
                  <Grid item xs={5}>
                    <Button color="primary" data-testid="applyAdjustmentsAutomaticallyButton" fullWidth onClick={autoFixRoundingError}>
                      Apply Adjustments Automatically
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Tooltip
                      title={
                        !hasRoundingErrorResolved &&
                        `Please resolve the rounding error to confirm changes, i.e. remaining rounding error should be ${0.0}`
                      }
                      placement="top"
                      disableHoverListener={hasRoundingErrorResolved}
                    >
                      <span style={{ width: "100%" }}>
                        <Button
                          fullWidth
                          disabled={!hasRoundingErrorResolved}
                          data-testid="confirmAdjustmentChangesButton"
                          onClick={() => setChangesConfirmed(true)}
                        >
                          Confirm Changes
                        </Button>
                      </span>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <p>No rounding error found</p>
          )}
        </DialogContent>
      </ResponsiveDialog>
    );
  }
};
export default RoundingErrorManager;
