import { createStyles, makeStyles, SvgIconProps } from "@material-ui/core";
import clsx from "clsx";
import { FC, useEffect, useState } from "react";

interface Props extends SvgIconProps {
  sector: string | null | undefined;
}

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      emerald: { color: theme.palette.emerald.dark },
    }),
  { name: "SectorIconComponent" },
);

const SectorIcon: FC<Props> = ({ sector, className, ...props }) => {
  const classes = useStyles(props);
  const [iconColor, setIconColor] = useState<"primary" | "error" | "emerald">("primary");
  const [SectorIcon, setSectorIcon] = useState<FC<SvgIconProps> | null>(null);

  useEffect(() => {
    (async () => {
      if (!sector) return;

      let iconSrc: string | null = null,
        color: typeof iconColor = "primary";

      switch (sector.toLocaleLowerCase()) {
        case "commerce":
          iconSrc = "Commerce";
          break;
        case "edtech":
          iconSrc = "EdTech";
          color = "emerald";
          break;
        case "fintech":
          iconSrc = "FinTech";
          break;
        case "gaming":
          iconSrc = "Gaming";
          color = "emerald";
          break;
        case "healthtech":
          iconSrc = "HealthTech";
          color = "error";
          break;
        case "hospitality":
          iconSrc = "Hospitality";
          color = "error";
          break;
        case "hrtech":
          iconSrc = "HRTech";
          color = "emerald";
          break;
        case "legaltech":
          iconSrc = "LegalTech";
          color = "emerald";
          break;
        case "marketplace":
          iconSrc = "Marketplace";
          break;
        case "retailtech":
          iconSrc = "RetailTech";
          break;
        case "wellbeing":
          iconSrc = "Wellbeing";
          color = "error";
          break;
        default:
          iconSrc = null;
          break;
      }
      if (!iconSrc) return;
      const icon = await import(`client/components/Icons/${iconSrc}Sector.icon.tsx`);
      setIconColor(color);
      setSectorIcon(() => icon.default);
    })();
  }, [sector]);

  return SectorIcon ? (
    <SectorIcon
      color={iconColor === "emerald" ? "primary" : iconColor}
      {...props}
      className={clsx(className, {
        [classes.emerald]: iconColor === "emerald",
      })}
    />
  ) : null;
};

export default SectorIcon;
