import { createStyles, Divider as MUIDivider, DividerProps, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React, { FC } from "react";

interface Props extends DividerProps {
  color?: "primary" | "secondary" | "default";
  size?: "small" | "medium" | "large";
  gutterBottom?: boolean;
  bottomMargin?: boolean;
}

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      gutterBottom: {
        marginBottom: "0.35em",
      },
      bottomMargin: {
        marginBottom: theme.spacing(1.5),
      },
      small: {
        height: theme.spacing(0.125),
      },
      medium: {
        height: theme.spacing(0.375),
      },
      large: {
        height: theme.spacing(0.75),
      },
      default: {
        backgroundColor: theme.palette.common.black,
      },
      primary: {
        backgroundColor: theme.palette.primary.main,
      },
      secondary: {
        backgroundColor: theme.palette.secondary.main,
      },
    }),
  { name: "DividerComponent" },
);

const Divider: FC<Props> = ({ size = "small", color = "default", gutterBottom = false, bottomMargin = false, className, ...props }) => {
  const classes = useStyles(props);

  return (
    <MUIDivider
      className={clsx(classes[size], classes[color], className, { [classes.gutterBottom]: gutterBottom, [classes.bottomMargin]: bottomMargin })}
      {...props}
    />
  );
};

export default Divider;
