import { Button, Container, FormGroup, Typography } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { PersonalDetails } from "server/services/application/application.types";
import { LeanUser } from "server/services/user/user.types";
import * as Yup from "yup";
import AdvisorApi from "../../api/advisor/onboarding.advisorApi";
import PublicAuthenticationApi from "../../api/public/authentication.publicApi";
import RadioQuestion from "../../components/FormControls/RadioQuestion.component";
import TextQuestion from "../../components/FormControls/TextQuestion.component";
import { AuthContext, AUTH_TYPES } from "../../context/auth.context";
import { ROUTES, TRACKED_EVENTS } from "../../utils/constants";

const { PAGE_VIEWS, PAGE_ACTIONS } = TRACKED_EVENTS;

const {
  ADVISOR: {
    INPUT: {
      CATEGORIES: { ONBOARDING },
    },
  },
} = TRACKED_EVENTS;

const SCHEMA = Yup.object().shape({
  title: Yup.string().required("This field is required"),
  forenames: Yup.string().required("This field is required"),
  surname: Yup.string().required("This field is required"),
  contactNumber: Yup.string().required("This field is required"),
  company: Yup.string().required("This field is required"),
  fcaNumber: Yup.string().required("This field is required"),
  isNetworkService: Yup.boolean().required("This field is required"),
  networkService: Yup.string().when("isNetworkService", {
    is: true,
    then: Yup.string().required("This field is required"),
    otherwise: Yup.string(),
  }),
});

const DEFAULT_VALUES = {
  title: "",
  forenames: "",
  surname: "",
  contactNumber: "",
  company: "",
  fcaNumber: "",
  isNetworkService: "",
  networkService: "",
};

const AdvisorOnboarding = () => {
  const history = useHistory();
  const { dispatch } = useContext(AuthContext);
  const [savedFormData, setSavedFormData] = useState<Partial<PersonalDetails> | null>(null);
  const initialValues = useMemo<Record<keyof typeof DEFAULT_VALUES, any> & Pick<LeanUser, "title" | "forenames" | "surname">>(
    () =>
      savedFormData
        ? {
            ...DEFAULT_VALUES,
            title: savedFormData.title ?? "",
            forenames: savedFormData.forenames ?? "",
            surname: savedFormData.surname ?? "",
            contactNumber: savedFormData.contactNumber ?? "",
          }
        : DEFAULT_VALUES,
    [savedFormData],
  );

  const handleSubmit = useCallback(
    async (values) => {
      const { success: onboardSuccess } = await AdvisorApi.onboard(values);
      if (!onboardSuccess) return;
      const { success, data } = await PublicAuthenticationApi.verifyToken();
      if (!success) {
        globalThis.dataLayer.push({ event: PAGE_ACTIONS.ADVISOR_VERIFICATION_FORM_SUBMITTED_UNSUCCESSFULLY });
        return;
      }
      globalThis.dataLayer.push({ event: PAGE_ACTIONS.ADVISOR_VERIFICATION_FORM_SUBMITTED_SUCCESSFULLY });
      dispatch({ type: AUTH_TYPES.SET_USER, payload: data });
      history.push(ROUTES.ADVISOR.DASHBOARD);
    },
    [dispatch, history],
  );

  useEffect(() => {
    globalThis.dataLayer.push({ event: PAGE_VIEWS.ADVISOR_VERIFICATION });
  }, []);

  useEffect(() => {
    (async () => {
      const personalDetailsSavedData = await AdvisorApi.getAdvisorsPersonalDetails();
      if (personalDetailsSavedData) {
        setSavedFormData({ ...DEFAULT_VALUES, ...personalDetailsSavedData });
      }
    })();
  }, []);

  return (
    <Container maxWidth="md">
      <Typography variant="h1" align="center" paragraph>
        Please answer the questions below to verify your IFA status
      </Typography>
      <Formik initialValues={initialValues} validationSchema={SCHEMA} enableReinitialize onSubmit={handleSubmit}>
        {({ values }) => (
          <Form>
            <FormGroup>
              <Field component={TextQuestion} name="title" label="Title:" placeholder="Title (Mr/Mrs/Miss/Ms/Other)" trackedCategory={ONBOARDING} />
            </FormGroup>
            <FormGroup>
              <Field component={TextQuestion} name="forenames" label="Forenames:" placeholder="Forenames" trackedCategory={ONBOARDING} />
            </FormGroup>
            <FormGroup>
              <Field component={TextQuestion} name="surname" label="Surname:" placeholder="Surname" trackedCategory={ONBOARDING} />
            </FormGroup>
            <FormGroup>
              <Field
                component={TextQuestion}
                name="contactNumber"
                label="Contact number:"
                placeholder="Contact number"
                trackedCategory={ONBOARDING}
              />
            </FormGroup>
            <FormGroup>
              <Field
                component={TextQuestion}
                name="company"
                label="What is the name of the company you work for?"
                placeholder="Type your answer here..."
                trackedCategory={ONBOARDING}
              />
            </FormGroup>
            <FormGroup>
              <Field component={TextQuestion} name="fcaNumber" label="FCA Number:" placeholder="FCA Number" trackedCategory={ONBOARDING} />
            </FormGroup>
            <FormGroup>
              <Field
                component={RadioQuestion}
                name="isNetworkService"
                label="Are you part of a network/service provider?"
                trackedCategory={ONBOARDING}
              />
            </FormGroup>
            {values.isNetworkService && (
              <FormGroup>
                <Field
                  component={TextQuestion}
                  name="networkService"
                  label="Please provide the name of your network/service provider:"
                  placeholder="Type your answer here..."
                  trackedCategory={ONBOARDING}
                />
              </FormGroup>
            )}
            <FormGroup row>
              <Button variant="outlined" size="large" onClick={() => history.push(ROUTES.ADVISOR.DASHBOARD)}>
                Back
              </Button>
              <Button data-testid="verifySubmit" size="large" type="submit">
                Submit
              </Button>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default AdvisorOnboarding;
