import { Button, CircularProgress } from "@material-ui/core";
import { ColumnDefinition, OnChangeObject, TableCellEditHandler } from "@wearenova/mui-data-table";
import DropDownButton from "client/components/DropDownButton.component";
import { ApplicationContext } from "client/context/application.context";
import { useAllPortfolios } from "client/hooks/adminContext.hooks";
import { APPLICATION_STATES, USER_ROLE } from "client/utils/constants";
import { AuthContext } from "../../../context/auth.context";
import AdminTable from "client/views/AdminPanel/AdminTable.view";
import _ from "lodash";
import moment from "moment";
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { AdminApplication } from "server/services/application/application.types";
import * as Yup from "yup";
import {
  addApplication,
  downloadBlankApplication,
  downloadFullApplication,
  downloadPaymentsReceivedData,
  generateApplication,
  getApplications,
  getAppsVSFundsReceivedLink,
  getIntroducers,
  sendNewFundsReceivedEmail,
} from "../../../api/admin/application.adminApi";
import { AdminContext, ADMIN_TYPES } from "../../../context/admin.context";
import { formatThousands } from "../../../utils/helpers";
import DisplayLink from "../ShareTransactions/DisplayLink.view";
import { useStyles } from "../useStyles.hook";
import ApplicationForm from "./ApplicationForm.view";

interface Props {
  readOnly: boolean;
}

const ApplicationsTab: FC<Props> = ({ readOnly, ...props }) => {
  const { applications, allUsers, dispatch: adminDispatch } = useContext(AdminContext);
  const { dispatch: applicationDispatch } = useContext(ApplicationContext);
  const {
    state: { user },
  } = useContext(AuthContext);
  const [newApplication, setNewApplication] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState<AdminApplication | null>(null);
  const [downloading, setDownloading] = useState(false);
  const [count, setCount] = useState(-1);
  const [sheetsLink, setSheetsLink] = useState<string | null>(null);
  const classes = useStyles(props);
  const history = useHistory();
  const allPortfolios = useAllPortfolios();
  const [introducers, setIntroducers] = useState([]);

  useEffect(() => {
    (async () => {
      const res = await getIntroducers();
      if (res) setIntroducers(res);
    })();
  }, []);

  const setApplications = useCallback(
    async (onChangeObject: OnChangeObject, isExport: boolean) => {
      const res = await getApplications(onChangeObject);
      if (!res) return [];
      if (isExport) return res.data;
      adminDispatch({ type: "SET_APPLICATIONS", payload: res.data });
      setCount(res.count);
    },
    [adminDispatch],
  );

  const [investors, advisors] = useMemo(() => {
    const formattedUsers = allUsers.map((user) => ({
      value: user._id,
      label: `${user.forenames} ${user.surname} - ${user.email}`,
      isAdvisor: Boolean(user.advisorDetails),
    }));
    const formattedAdvisors = [{ label: "none", value: null }, ...formattedUsers.filter((user) => user.isAdvisor)];
    return [formattedUsers, formattedAdvisors];
  }, [allUsers]);

  const handleSheetsLink = useCallback(async () => {
    const link = await getAppsVSFundsReceivedLink();
    setSheetsLink(link);
  }, []);

  const applicationTable = useMemo<ColumnDefinition<AdminApplication>[]>(
    () => [
      {
        key: "investorName",
        title: "Investor Name",
        sorter: "investor.user.fullName",
        dataIndex: "investor.user.fullName",
        filterColumn: true,
        pinnable: true,
        editable: {
          path: "investor.user",
          type: "select",
          selectOptions: investors,
          defaultValue: "",
        },
      },
      {
        key: "investorEmail",
        title: "Investor Email",
        sorter: "investor.user.email",
        dataIndex: "investor.user.email",
        filterColumn: true,
        pinnable: true,
        editable: {
          path: "investor.user",
          type: "select",
          selectOptions: investors,
          defaultValue: "",
        },
      },
      {
        key: "portfolio",
        title: "Portfolio",
        dataIndex: "portfolio.name",
        sorter: true,
        filterColumn: true,
        pinnable: true,
        editable: {
          path: "portfolio",
          type: "select",
          selectOptions: allPortfolios.map((portfolio) => ({ label: portfolio.name, value: portfolio._id })),
          defaultValue: "",
        },
      },
      {
        key: "introducer",
        title: "Introducer",
        dataIndex: "introducer",
        sorter: true,
        filterColumn: true,
        pinnable: true,
        editable: {
          path: "introducer",
          type: "select",
          selectOptions: introducers,
          defaultValue: "",
        },
      },
      {
        key: "coinvestorId",
        title: "Coinvestor Id",
        dataIndex: "coinvestorId",
        sorter: true,
        filterColumn: true,
        pinnable: true,
        editable: true,
      },
      {
        key: "advisorName",
        title: "Adviser Name",
        sorter: "advisor.user.fullName",
        dataIndex: "advisor.user.fullName",
        filterColumn: true,
        pinnable: true,
        editable: {
          path: "advisor.user",
          type: "select",
          selectOptions: advisors,
          defaultValue: "",
          validate: (value) =>
            Yup.string()
              .oneOf(advisors.map((a) => a.value))
              .nullable()
              .validate(value),
        },
      },
      {
        key: "advisorEmail",
        title: "Adviser Email",
        dataIndex: "advisor.user.email",
        sorter: true,
        filterColumn: true,
        pinnable: true,
        editable: {
          path: "advisor.user",
          type: "select",
          selectOptions: advisors,
          defaultValue: "",
        },
      },
      {
        key: "registrationDate",
        title: "Registration Date",
        dataIndex: "investor.user.registrationDateFormatted",
        sorter: "investor.user.registrationDate",
        filterColumn: { path: "investor.user.registrationDate", type: "date" },
        pinnable: true,
      },
      {
        key: "startDate",
        title: "Start Date",
        render: (record: any) => (record.startDate ? moment(record.startDate).format("DD/MM/YYYY") : null),
        sorter: "startDate",
        filterColumn: { path: "startDate", type: "date" },
        pinnable: true,
      },
      {
        key: "date",
        title: "Signature Date",
        dataType: "date",
        dataIndex: "completedTimestampFormatted",
        sorter: "completedTimestamp",
        filterColumn: { path: "completedTimestamp", type: "date" },
        pinnable: true,
        editable: "completedTimestamp",
      },
      {
        key: "lastActivityDate",
        title: "Last Activity",
        dataIndex: "data.updatedAtFormatted",
        sorter: "data.updatedAt",
        filterColumn: { path: "data.updatedAt", type: "date" },
        pinnable: true,
      },
      {
        key: "usersLastActivityDate",
        title: "User's Last Activity",
        dataIndex: "data.userUpdatedAtFormatted",
        sorter: "data.userUpdatedAt",
        filterColumn: { path: "data.userUpdatedAt", type: "date" },
        pinnable: true,
      },
      {
        key: "amount",
        title: "Amount",
        dataIndex: "data.investmentInformation.investmentAmount",
        numerical: { path: true, currency: true },
        sorter: true,
        filterColumn: { path: true, type: "number" },
        pinnable: true,
        editable: true,
      },
      {
        key: "frequency",
        title: "Investment Frequency",
        dataIndex: "data.investmentInformation.investmentFrequency",
        filterColumn: true,
        pinnable: true,
      },
      {
        key: "percentageSplit",
        title: "SEIS/EIS Split",
        pinnable: true,
        filterColumn: true,
        colGroup: [
          {
            key: "data.investmentInformation.seis",
            title: "SEIS",
            render: (record) => typeof record.data.investmentInformation.seis === "number" && `${record.data.investmentInformation.seis}%`,
            sorter: "data.investmentInformation.seis",
          },
          {
            key: "data.investmentInformation.eis",
            title: "EIS",
            render: (record) => typeof record.data.investmentInformation.eis === "number" && `${record.data.investmentInformation.eis}%`,
            sorter: "data.investmentInformation.eis",
          },
        ],
      },
      {
        key: "likelihood",
        title: "Likelihood of Funds",
        dataType: "number",
        dataIndex: "likelihood",
        render: (record) => typeof record.likelihood === "number" && `${record.likelihood}%`,
        sorter: true,
        pinnable: true,
        filterColumn: true,
        editable: true,
      },
      {
        key: "carryBack",
        title: "Carry Back",
        pinnable: true,
        filterColumn: true,
        colGroup: [
          // {
          //   key: "hasCarryBack",
          //   title: "Has Carry Back?",
          //   render: (record) => String(_.get(record, "data.investmentInformation.carryBack.present", false)).toUpperCase(),
          //   sorter: "data.investmentInformation.carryBack.present",
          //   filterColumn: { path: "data.investmentInformation.carryBack.present", type: "boolean" },
          // },
          {
            key: "carryBack.seis",
            title: "SEIS Amount",
            dataIndex: "data.investmentInformation.carryBack.seis",
            numerical: { path: "data.investmentInformation.carryBack.seis", currency: true },
            sorter: true,
            editable: "data.investmentInformation.carryBack.seis",
          },
          {
            key: "carryBack.eis",
            title: "EIS Amount",
            dataIndex: "data.investmentInformation.carryBack.eis",
            numerical: { path: "data.investmentInformation.carryBack.eis", currency: true },
            sorter: true,
            editable: "data.investmentInformation.carryBack.eis",
          },
          {
            key: "carryBackInfo",
            title: "Information",
            dataIndex: "data.investmentInformation.carryBack.information",
            filterColumn: true,
            limitWidth: "lg",
          },
        ],
      },
      {
        key: "fundsReceived",
        title: "Funds Received",
        numerical: { path: "balances.fundsReceived.total", currency: true },
        sorter: "balances.fundsReceived.total",
        filterColumn: { path: "balances.fundsReceived.total", type: "number" },
        pinnable: true,
      },
      {
        key: "newFundsReceived",
        title: "Send Funds Received Email",
        render: (record) => {
          const someNewFundsReceived = record.fundsReceived && record.fundsReceived.some((fundReceived) => fundReceived.newFundReceived);
          if (!someNewFundsReceived) {
            return null;
          }
          return (
            <Button
              variant="text"
              size="small"
              onClick={async (e) => {
                e.stopPropagation();
                const application = await sendNewFundsReceivedEmail(record._id);
                adminDispatch({ type: "UPDATE_APPLICATION", payload: application });
              }}
            >
              Send Funds Received Email
            </Button>
          );
        },
        pinnable: true,
      },
      {
        key: "fundsHeld",
        title: "Funds Held Elsewhere",
        dataType: "number",
        render: (record) => (record.amountHeldElsewhere ? formatThousands(record.amountHeldElsewhere, 2) : "N/A"),
        sorter: "balances.fundsHeld.total",
        filterColumn: { path: "balances.fundsHeld.total", type: "number" },
        pinnable: true,
        editable: "amountHeldElsewhere",
      },
      {
        key: "fundsDeployed",
        title: "Funds Deployed",
        numerical: { path: "balances.fundsDeployed.total", currency: true },
        sorter: "balances.fundsDeployed.total",
        filterColumn: { path: "balances.fundsDeployed.total", type: "number" },
        pinnable: true,
      },
      {
        key: "seisDeployed",
        title: "SEIS Deployed",
        numerical: { path: "balances.fundsDeployed.seis", currency: true },
        sorter: "balances.fundsDeployed.seis",
        filterColumn: { path: "balances.fundsDeployed.seis", type: "number" },
        pinnable: true,
      },
      {
        key: "eisDeployed",
        title: "EIS Deployed",
        numerical: { path: "balances.fundsDeployed.eis", currency: true },
        sorter: "balances.fundsDeployed.eis",
        filterColumn: { path: "balances.fundsDeployed.eis", type: "number" },
        pinnable: true,
      },
      {
        key: "fundsRemaining",
        title: "Funds Remaining",
        numerical: { path: "balances.fundsRemaining.total", currency: true },
        sorter: "balances.fundsRemaining.total",
        filterColumn: { path: "balances.fundsRemaining.total", type: "number" },
        pinnable: true,
      },
      {
        key: "seisRemaining",
        title: "SEIS Remaining",
        numerical: { path: "balances.fundsRemaining.seis", currency: true },
        sorter: "balances.fundsRemaining.seis",
        filterColumn: { path: "balances.fundsRemaining.seis", type: "number" },
        pinnable: true,
      },
      {
        key: "eisRemaining",
        title: "EIS Remaining",
        numerical: { path: "balances.fundsRemaining.eis", currency: true },
        sorter: "balances.fundsRemaining.eis",
        filterColumn: { path: "balances.fundsRemaining.eis", type: "number" },
        pinnable: true,
      },
      {
        key: "status",
        title: "Status",
        dataIndex: "status",
        sorter: true,
        filterColumn: true,
        pinnable: true,
        editable: {
          path: "status",
          type: "select",
          selectOptions: Object.entries(APPLICATION_STATES).map(([key, value]) => ({ label: key, value: value })),
        },
      },
      {
        key: "updateApplication",
        title: "Update Application",
        sorter: true,
        filterColumn: true,
        pinnable: true,
        render: (record) => (
          <Button
            variant="text"
            size="small"
            onClick={async (e) => {
              e.stopPropagation();
              const application = await generateApplication(record._id);
              applicationDispatch({ type: "SET_APPLICATION", payload: application });
              history.push(`/admin/application/${application._id}/apply/personalDetails`);
            }}
          >
            Update
          </Button>
        ),
      },
      {
        key: "application",
        title: "Application",
        sorter: "fileName",
        filterColumn: true,
        pinnable: true,
        render: (record) => (
          <Button
            variant="text"
            size="small"
            disabled={!record.fileName}
            onClick={(e) => {
              e.stopPropagation();
              downloadFullApplication(record._id, _.get(record, "investor.user.surname"));
            }}
          >
            Download Full PDF
          </Button>
        ),
      },
      {
        key: "amlCompleted",
        title: "AML Completed",
        dataIndex: "amlCompleted",
        sorter: true,
        pinnable: true,
        filterColumn: true,
        editable: {
          path: "amlCompleted",
          type: "select",
          selectOptions: ["Pending", "Refer", "Signed off"],
        },
      },
      {
        key: "investmentManager",
        title: "Investment Manager",
        render: (record) => (record.investmentManager ? _.startCase(record.investmentManager) : "N/A"),
        filterColumn: "investmentManager",
        sorter: true,
        pinnable: true,
        editable: {
          path: "investmentManager",
          type: "select",
          selectOptions: ["Sapia", "Sapphire", "Stellar"],
        },
      },
      {
        key: "investmentManagerApproved",
        title: "Investment Manager Approved",
        dataType: "boolean",
        render: (record) => (record.investmentManagerApproved === true ? "Yes" : "No"),
        sorter: true,
        filterColumn: true,
        pinnable: true,
        editable: {
          path: "investmentManagerApproved",
          type: "select",
          selectOptions: [
            { label: "Yes", value: true },
            { label: "No", value: false },
          ],
        },
      },
      {
        key: "mainspringId",
        title: "Mainspring ID",
        dataIndex: "mainspringId",
        filterColumn: true,
        sorter: true,
        pinnable: true,
        editable: true,
      },
      {
        key: "notes",
        title: "Notes",
        dataIndex: "notes",
        sorter: true,
        filterColumn: true,
        pinnable: true,
        editable: true,
      },
    ],
    [adminDispatch, applicationDispatch, introducers, advisors, investors, allPortfolios, history],
  );

  const handleTableEdit = useCallback<TableCellEditHandler<AdminApplication>>(
    async ({ path, value }, record) => {
      const res = await addApplication({ [path]: value === "null" ? null : value, disableApprovalEmail: true }, record._id);
      if (res) adminDispatch({ type: ADMIN_TYPES.UPDATE_APPLICATION, payload: res });
    },
    [adminDispatch],
  );

  return (
    <>
      <AdminTable
        tableData={applications}
        onChange={setApplications}
        count={count}
        rowClick={(application) => setSelectedApplication(application)}
        tableStructure={applicationTable}
        tableProps={{ size: "small" }}
        exportToCSVOption
        csvFilename="Applications"
        defaultSort={{ key: "completedTimestamp", direction: "desc" }}
        onEdit={handleTableEdit}
      />
      <div className={classes.tableFooter}>
        <Button
          disabled={user?.role === USER_ROLE.ADMIN || user?.role === USER_ROLE.MANAGER ? false : readOnly}
          onClick={() => setNewApplication(true)}
        >
          Add Application
        </Button>
        <Button
          disabled={downloading}
          onClick={async () => {
            setDownloading(true);
            await downloadBlankApplication();
            setDownloading(false);
          }}
        >
          Download Blank Application
          {downloading && <CircularProgress color="secondary" className={classes.circularProgress} />}
        </Button>
        <DropDownButton
          primaryText="Export"
          dropDownItems={[
            {
              text: "Payments Received to CSV",
              onClick: downloadPaymentsReceivedData,
            },
            {
              text: "Google Sheets Link",
              onClick: handleSheetsLink,
            },
          ]}
        />
      </div>
      <ApplicationForm
        fullWidth
        application={selectedApplication}
        open={Boolean(newApplication || selectedApplication)}
        onClose={() => {
          setNewApplication(false);
          setSelectedApplication(null);
        }}
      />
      <DisplayLink fullWidth open={Boolean(sheetsLink)} link={sheetsLink} onClose={() => setSheetsLink(null)} />
    </>
  );
};

export default ApplicationsTab;
