import fileDownload from "js-file-download";
import notifications from "../../utils/notifications";
import { ipAxios } from "../ipAxios";

export const downloadStatisticsData = async () => {
  try {
    const res = await ipAxios({
      url: `/admin/statistics/download`,
      method: "GET",
      responseType: "blob",
    });
    if (res.status === 200) {
      fileDownload(new Blob([res.data]), `statistics-data-${Date.now()}.csv`);
      return { success: true, data: res.data };
    }
    return { success: false };
  } catch (error) {
    notifications.error(error.message, error);
    return { success: false };
  }
};

export const getStats = async () => {
  try {
    const res = await ipAxios(`/admin/statistics/weekly`);
    return res.data;
  } catch (error) {
    notifications.error(error.message, error);
    return null;
  }
};
