import { makeStyles, Step, StepContent, StepLabel, Stepper, Typography } from "@material-ui/core";
import qualifyIcon from "client/assets/1_qualify.png";
import applicationIcon from "client/assets/2_application.png";
import depositIcon from "client/assets/3_deposit.png";
import welcomeIcon from "client/assets/4_welcome.png";
import InvestFunds from "client/components/CTAs/InvestFunds.component";
import QualifyCTA from "client/components/CTAs/QualifyToInvest.component";
import { AuthContext } from "client/context/auth.context";
import { TRACKED_EVENTS } from "client/utils/constants";
import React, { useMemo } from "react";
import { useStyles as externalStyles } from "../useStyles";

const { PAGE_VIEWS } = TRACKED_EVENTS;

const useStyles = makeStyles(
  (theme) => ({
    image: {
      height: 100,
      width: "auto",
      marginBottom: theme.spacing(4),
    },
  }),
  { name: "HowToInvestComponent" },
);

const HowToInvest: React.FC = (props) => {
  const {
    state: { user },
  } = React.useContext(AuthContext);
  React.useEffect(() => {
    globalThis.dataLayer.push({ event: PAGE_VIEWS.IM_HOW_TO });
  }, []);

  const classes = useStyles(props);
  const externalClasses = externalStyles(props);

  const steps = useMemo(
    () => [
      {
        title: "Step 1 - Certify",
        image: <img src={qualifyIcon} className={classes.image} alt="qualify icon" />,
        description:
          "Our investment fund is regulated by the FCA and is only suitable for individuals of certain levels of income, wealth and experience. The first requirement is to ensure you are certified to invest in our fund. If you haven’t completed step 1 and certified to invest you will see a button below allowing you to commence the process.",
        button: !user?.isQualified && <QualifyCTA {...props} />,
      },
      {
        title: "Step 2 - Complete an Application",
        image: <img src={applicationIcon} className={classes.image} alt="qualify icon" />,
        description:
          "To make the process simpler, we ask you a few questions online and then generate a pre-filled agreement for you to electronically sign. This agreement will then be emailed to you for your records. If you have completed step 1 and certified to invest you will see a button below allowing you to begin the application process.",
        button: user?.isQualified && <InvestFunds {...props} />,
      },
      {
        title: "Step 3 - Deposit Funds",
        image: <img src={depositIcon} className={classes.image} alt="qualify icon" />,
        description:
          "Once you have received your application form, you can then bank transfer the required funds using the payment information we provide.",
      },
      {
        title: "Step 4 - Welcome",
        image: <img src={welcomeIcon} className={classes.image} alt="qualify icon" />,
        description:
          "Once our custodian is in receipt of funds we will notify you by email and you will be able to see your cash balance on your dashboard.",
      },
    ],
    [classes.image, props, user?.isQualified],
  );

  return (
    <>
      <Typography variant="h1">
        How to Invest
        <Typography variant="inherit" color="primary">
          .
        </Typography>
      </Typography>
      <Stepper orientation="vertical">
        {steps.map(({ title, image, description, button }) => (
          <Step key={title} active>
            <StepLabel>
              <Typography variant="h2">{title}</Typography>
            </StepLabel>
            <StepContent>
              <div className={externalClasses.stepContentContainer}>
                <div className={externalClasses.stepImageContainer}>{image}</div>
                <div className={externalClasses.stepParagraphContainer}>
                  <Typography variant="body2" paragraph>
                    {description}
                  </Typography>
                  {button}
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </>
  );
};

export default HowToInvest;
