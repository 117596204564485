import { Button, Dialog, DialogContent, DialogProps, DialogTitle, Divider, FormGroup, IconButton, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { addApplication, deleteAdminApplication, getIntroducers } from "client/api/admin/application.adminApi";
import { getPortfolios } from "client/api/admin/portfolios.adminApi";
import { getUsers } from "client/api/admin/users.adminApi";
import FieldArrayRemoveButton from "client/components/FieldArrayRemoveButton.component";
import CheckboxQuestion from "client/components/FormControls/CheckboxQuestion.component";
import CrudFormButtons from "client/components/FormControls/CrudFormButtons.component";
import DatePickerField from "client/components/FormControls/DatePickerField.component";
import NumberQuestion from "client/components/FormControls/NumberQuestion.component";
import RadioQuestion from "client/components/FormControls/RadioQuestion.component";
import SeisEisSplit from "client/components/FormControls/SeisEisSplit.component";
import SelectField from "client/components/FormControls/SelectField.component";
import TextQuestion from "client/components/FormControls/TextQuestion.component";
import FormikErrorsTouched from "client/components/FormikErrorsTouched.component";
import { AdminContext } from "client/context/admin.context";
import { APPLICATION_STATES, DAYS_OF_MONTH } from "client/utils/constants";
import { Field, FieldArray, Form, Formik } from "formik";
import _ from "lodash";
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { AdminApplication, LeanApplication } from "server/services/application/application.types";
import * as Yup from "yup";

interface Props extends DialogProps {
  application?: AdminApplication | null;
  onClose(): any;
}

const AML_STATUSES = ["Pending", "Refer", "Signed off"];

const SCHEMA = Yup.object({
  investor: Yup.object({
    user: Yup.string().required("Please select an investor"),
  }),
  introducer: Yup.string().nullable(),
  coinvestorId: Yup.string().nullable(),
  advisor: Yup.object({
    user: Yup.string(),
  }),
  fileName: Yup.mixed().notRequired(),
  completedTimestamp: Yup.date().nullable(),
  status: Yup.string().required("Please select an application status"),
  data: Yup.object({
    investmentInformation: Yup.object({
      investmentAmount: Yup.number().positive().required("Please enter an investment amount"),
      carryBack: Yup.object({
        eis: Yup.number().min(0, "Carry back EIS must be at least ${min}"),
        seis: Yup.number().min(0, "Carry back SEIS must be at least ${min}"),
      }),
      seis: Yup.number().min(0, "Minimum value is ${min}%").max(100, "Maximum value is ${max}%").required("Please enter a value"),
      eis: Yup.number().min(0, "Minimum value is ${min}%").max(100, "Maximum value is ${max}%").required("Please enter a value"),
      investmentFrequency: Yup.string(),
      investmentDate: Yup.string()
        .when("investmentFrequency", {
          is: "One off payment",
          then: Yup.string().required("Please enter a payment date"),
          otherwise: Yup.string().nullable().notRequired(),
        })
        .typeError("Please enter an Investment Date"),
      dateForMonthlyPayment: Yup.number()
        .when("investmentFrequency", {
          is: "Monthly installments",
          then: Yup.number()
            .oneOf(
              DAYS_OF_MONTH.map(({ value }) => value),
              "Please select one of the days",
            )
            .required("Please enter a date for each monthly payment"),
          otherwise: Yup.number().nullable().notRequired(),
        })
        .typeError("Please enter a date for each monthly payment"),
      noOfMonthlyInstallments: Yup.number().when("investmentFrequency", {
        is: "Monthly installments",
        then: Yup.number()
          .typeError("Please enter a number")
          .integer("Please enter an integer")
          .min(1, "Minimum number of instalments is ${min}")
          .required("Please enter the number of monthly instalments"),
        otherwise: Yup.number().nullable(),
      }),
      installments: Yup.array().when("investmentFrequency", {
        is: "Multiple installments",
        then: Yup.array(
          Yup.object({
            amountEachInstallment: Yup.number().required("Please enter an amount per instalment"),
            date: Yup.date().required("Please enter a date"),
          }),
        ),
        otherwise: Yup.array(
          Yup.object({
            amountEachInstallment: Yup.number().nullable().notRequired(),
            date: Yup.date().nullable().notRequired(),
          }),
        ),
      }),
      waiveCancellationPeriod: Yup.boolean().nullable(),
    }),
  }),
  overAllocation: Yup.object({
    seis: Yup.number().min(0, "Minimum value is ${min}%").max(100, "Maximum value is ${max}%"),
    eis: Yup.number().min(0, "Minimum value is ${min}%").max(100, "Maximum value is ${max}%"),
  }),
  amlCompleted: Yup.string().oneOf(AML_STATUSES).required("Please indicate the AML Status"),
  investmentManager: Yup.string().nullable(),
  investmentManagerApproved: Yup.boolean().nullable(),
  mainspringId: Yup.string(),
  notes: Yup.string(),
  disableFundsReceivedEmail: Yup.boolean(),
  disableApprovalEmail: Yup.boolean(),
  heldElsewhere: Yup.boolean().nullable(),
  amountHeldElsewhere: Yup.number().nullable(),
  fundsReceived: Yup.array(
    Yup.object({
      amount: Yup.number().typeError("Must be a number").positive("Must be a positive number").required("Please the amount received"),
      date: Yup.date().typeError("Must be a date").required("Please enter the date this fund was received"),
      reference: Yup.string().required("Please enter a payment reference"),
      status: Yup.string().required("Please indicate a status for this payment"),
    }),
  ),
  likelihood: Yup.number().min(0, "Likelihood must be at least ${min}%").max(100, "Likelihood must be maximum ${max}%").nullable(),
  portfolio: Yup.string().nullable(),
});

const APPLICATION_STATUSES = Object.entries(APPLICATION_STATES).map(([key, value]) => ({
  label: key,
  value: value,
}));

const INITIAL_VALUES = {
  investor: {
    user: "",
  },
  introducer: "",
  coinvestorId: "",
  advisor: {
    user: "",
  },
  fileName: "",
  status: "",
  completedTimestamp: null,
  disableFundsReceivedEmail: false,
  disableApprovalEmail: false,
  heldElsewhere: false,
  amountHeldElsewhere: "",
  amlCompleted: "Pending",
  investmentManager: "",
  investmentManagerApproved: null,
  mainspringId: "",
  notes: "",
  data: {
    investmentInformation: {
      investmentAmount: "",
      carryBack: {
        eis: "",
        seis: "",
      },
      investmentFrequency: "",
      investmentDate: "",
      seis: "",
      eis: "",
      dateForMonthlyPayment: "",
      installments: [
        {
          amountEachInstallment: "",
          date: null,
        },
      ],
      waiveCancellationPeriod: false,
    },
  },
  overAllocation: {
    seis: "",
    eis: "",
  },
  fundsReceived: [],
  likelihood: "",
};

const ApplicationForm: FC<Props> = ({ onClose, application, ...props }) => {
  const { allUsers, portfolios, dispatch } = useContext(AdminContext);
  const [introducers, setIntroducers] = useState([]);

  const fetchIntroducers = useCallback(async () => {
    const is = await getIntroducers();
    if (is) {
      setIntroducers(is);
    }
  }, []);

  useEffect(() => {
    fetchIntroducers();
  }, [fetchIntroducers]);

  useEffect(() => {
    (async () => {
      const res = await getUsers();
      if (res) {
        dispatch({ type: "SET_ALL_USERS", payload: res.data });
      }
    })();
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      const data = await getPortfolios();
      if (!data) return;
      dispatch({ type: "SET_PORTFOLIOS", payload: data });
    })();
  }, [dispatch]);

  const [investors, advisors] = useMemo(() => {
    const formattedUsers = allUsers.map((user) => ({
      value: user._id,
      label: `${user.forenames} ${user.surname} - ${user.email}`,
      isAdvisor: Boolean(user.advisorDetails),
    }));
    const formattedAdvisors = formattedUsers.filter((user) => user.isAdvisor);

    return [formattedUsers, formattedAdvisors];
  }, [allUsers]);

  const portfolioOptions = useMemo(() => portfolios.map((portfolio) => ({ value: portfolio._id, label: portfolio.name })), [portfolios]);

  const handleSubmit = useCallback(
    async (values) => {
      if (typeof values.overAllocation.seis !== "number" || typeof values.overAllocation.eis !== "number") {
        values.overAllocation = {};
      }
      const newApplication = await (application ? addApplication(values, application._id) : addApplication(values));
      if (newApplication) {
        dispatch({ type: "UPDATE_APPLICATION", payload: newApplication });
        onClose();
      }
    },
    [application, dispatch, onClose],
  );

  const handleDelete = useCallback(async () => {
    if (application) {
      const applicationId = await deleteAdminApplication(application._id);
      if (applicationId) {
        dispatch({ type: "DELETE_APPLICATION", payload: applicationId });
        onClose();
      }
    }
  }, [application, dispatch, onClose]);

  const initialValues = useMemo<Partial<LeanApplication>>(
    () =>
      (application
        ? {
            ...INITIAL_VALUES,
            ...application,
            portfolio: _.get(application, "portfolio._id", null),
            investor: {
              ...INITIAL_VALUES.investor,
              ...(application.investor || {}),
              user: _.get(application, "investor.user._id", ""),
            },
            advisor: {
              ...INITIAL_VALUES.advisor,
              ...(application.advisor || {}),
              user: _.get(application, "advisor.user._id", ""),
            },
            // make sure these are always false by default
            disableApprovalEmail: false,
            disableFundsReceivedEmail: false,
          }
        : INITIAL_VALUES) as any,
    [application],
  );

  return (
    <Dialog onClose={onClose} onEnter={fetchIntroducers} {...props}>
      <DialogTitle>{application ? "Edit Application" : "Add Appication"}</DialogTitle>
      <DialogContent>
        <Formik onSubmit={handleSubmit} initialValues={initialValues} enableReinitialize={true} validationSchema={SCHEMA}>
          {({ setFieldValue, values }) => (
            <Form data-testid="applicationForm">
              <FormikErrorsTouched />
              <FormGroup>
                <Field component={SelectField} name="investor.user" label="Investor" options={investors} />
              </FormGroup>
              <FormGroup>
                <Field component={SelectField} name="portfolio" label="Portfolio" options={portfolioOptions} />
              </FormGroup>
              <FormGroup>
                <Field
                  component={SelectField}
                  name="introducer"
                  label="Introducer"
                  options={introducers}
                  freeSolo
                  placeholder="Create or select an introducer"
                />
              </FormGroup>
              <FormGroup>
                <Field component={TextQuestion} name="coinvestorId" label="Coinvestor ID" placeholder="Coinvestor ID" />
              </FormGroup>
              <FormGroup>
                <Field component={SelectField} name="advisor.user" label="Adviser" options={advisors} placeholder="No Adviser" />
              </FormGroup>
              <FormGroup>
                <hr />
              </FormGroup>
              <FormGroup row>
                <Field
                  component={NumberQuestion}
                  moneyFormat
                  name="data.investmentInformation.investmentAmount"
                  label="Amount"
                  step={0.01}
                  prefix="£"
                />
                <Field component={NumberQuestion} name="likelihood" label="Likelihood of Funds" step={1} suffix="%" />
              </FormGroup>
              <FormGroup>
                <hr />
              </FormGroup>
              <FormGroup>
                <Typography variant="body1">(S)EIS Carry Back</Typography>
              </FormGroup>
              <FormGroup row>
                <Field component={NumberQuestion} name="data.investmentInformation.carryBack.seis" label="SEIS" moneyFormat />
                <Field component={NumberQuestion} name="data.investmentInformation.carryBack.eis" label="EIS" moneyFormat />
              </FormGroup>
              <FormGroup>
                <hr />
              </FormGroup>
              <FormGroup>
                <Field
                  component={SelectField}
                  name="data.investmentInformation.investmentFrequency"
                  label="Investment Frequency"
                  placeholder="Investment Frequency"
                  options={[
                    { label: "One off payment", value: "One off payment" },
                    { label: "Monthly instalments", value: "Monthly installments" },
                    { label: "Multiple instalments", value: "Multiple installments" },
                  ]}
                />
              </FormGroup>
              {values.data!.investmentInformation.investmentFrequency === "One off payment" && (
                <FormGroup>
                  <Field
                    component={DatePickerField}
                    name="data.investmentInformation.investmentDate"
                    label="Investment Date"
                    placeholder="Investment Date"
                  />
                </FormGroup>
              )}
              {values.data!.investmentInformation.investmentFrequency === "Monthly installments" && (
                <>
                  <FormGroup>
                    <Field
                      component={SelectField}
                      name="data.investmentInformation.dateForMonthlyPayment"
                      placeholder="Expected payment date each month"
                      label="Expected Payment Date Each Month"
                      options={DAYS_OF_MONTH}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Field
                      component={NumberQuestion}
                      name="data.investmentInformation.noOfMonthlyInstallments"
                      placeholder="Number of monthly instalments"
                      label="Number of monthly instalments"
                      min={1}
                      step={1}
                    />
                  </FormGroup>
                </>
              )}
              {values.data!.investmentInformation.investmentFrequency === "Multiple installments" && (
                <FieldArray name="data.investmentInformation.installments">
                  {({ push, remove }) => (
                    <FormGroup>
                      <hr />
                      <FormGroup>
                        <Typography variant="body1">
                          <strong>Instalments</strong>
                        </Typography>
                      </FormGroup>
                      {values.data!.investmentInformation.installments.map((_installment, index, installments) => (
                        <FormGroup key={index}>
                          <FieldArrayRemoveButton handleClick={() => remove(index)} label={`Instalment ${index + 1}`} disableMargin />
                          <FormGroup>
                            <Field
                              component={NumberQuestion}
                              name={`data.investmentInformation.installments[${index}].amountEachInstallment`}
                              placeholder="Amount Per Instalment"
                              label="Amount Per Instalment"
                              moneyFormat
                            />
                          </FormGroup>
                          <FormGroup>
                            <Field
                              component={DatePickerField}
                              name={`data.investmentInformation.installments[${index}].date`}
                              label="Date of Instalment"
                              placeholder="Date of Instalment"
                            />
                          </FormGroup>
                          {index !== installments.length - 1 && (
                            <FormGroup>
                              <hr />
                            </FormGroup>
                          )}
                        </FormGroup>
                      ))}
                      <FormGroup>
                        <Button onClick={() => push({ date: null, amountEachInstallment: "" })} variant="contained" size="small">
                          Add Instalment
                        </Button>
                      </FormGroup>
                      <FormGroup>
                        <hr />
                      </FormGroup>
                    </FormGroup>
                  )}
                </FieldArray>
              )}
              <FormGroup>
                <Field
                  component={TextQuestion}
                  name="fileName"
                  type="file"
                  placeholder="Agreement File"
                  label="Agreement File"
                  onChange={(e: any) => setFieldValue(e.target.name, e.target.files[0])}
                  value={undefined}
                  InputProps={{
                    inputProps: {
                      accept: "application/pdf",
                    },
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Field
                  component={SelectField}
                  name="status"
                  label="Application Status"
                  placeholder="Application Status"
                  options={APPLICATION_STATUSES}
                />
              </FormGroup>
              <FormGroup>
                <Field component={TextQuestion} name="mainspringId" label="Mainspring ID" placeholder="Mainspring ID" />
              </FormGroup>
              <FormGroup>
                <Field
                  component={DatePickerField}
                  name="completedTimestamp"
                  data-testid="signatureDate"
                  label="Signature Date"
                  placeholder="Signature Date"
                />
              </FormGroup>
              <FormGroup>
                <Field
                  component={CheckboxQuestion}
                  name="data.investmentInformation.waiveCancellationPeriod"
                  data-testid="waiveCancellationPeriod"
                  placeholder="Waive cancellation Period?"
                  label="I confirm that I do not wish to waive my 14-day cancellation period and the Investment Manager may not make an investment on my behalf during my 14-day cancellation period."
                />
              </FormGroup>
              <FormGroup>
                <Field component={SeisEisSplit} namePrefix="data.investmentInformation" label="SEIS / EIS Split" seisEisProps={{ step: 0.01 }} />
                <FormGroup>
                  <Field component={RadioQuestion} name="heldElsewhere" label="Funds Held Elsewhere?" />
                </FormGroup>
                {values.heldElsewhere && (
                  <FormGroup>
                    <Field component={NumberQuestion} moneyFormat name="amountHeldElsewhere" label="Amount Held Elsewhere" />
                  </FormGroup>
                )}
                <FormGroup>
                  <Field component={RadioQuestion} name="amlCompleted" label="AML Completed" radioValues={AML_STATUSES} />
                </FormGroup>
                <FormGroup>
                  <Field
                    component={RadioQuestion}
                    name="investmentManager"
                    label="Investment Manager"
                    radioValues={["Sapia", "Sapphire", "Stellar"]}
                  />
                </FormGroup>
                <FormGroup>
                  <Field component={RadioQuestion} name="investmentManagerApproved" label="Investment Manager Approved" />
                </FormGroup>
                <FormGroup>
                  <Field
                    component={CheckboxQuestion}
                    name="disableApprovalEmail"
                    placeholder="Do not send 'Application Approved' email notification"
                    label="Do not send 'Application Approved' email notification"
                  />
                </FormGroup>
                <FormGroup>
                  <Field component={TextQuestion} name="notes" label="Notes:" />
                </FormGroup>
              </FormGroup>
              <FormGroup>
                <Divider />
                <FormGroup>
                  <Typography variant="body1">Over Allocation</Typography>
                  <Typography variant="body2">This is where an investor may have a secondary preference for their SEIS/EIS split</Typography>
                </FormGroup>
                <Field component={SeisEisSplit} namePrefix="overAllocation" seisEisProps={{ step: 0.01 }} />
                <FormGroup>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      setFieldValue("overAllocation.seis", "");
                      setFieldValue("overAllocation.eis", "");
                    }}
                  >
                    Reset
                  </Button>
                </FormGroup>
              </FormGroup>
              <FormGroup>
                <FieldArray name="fundsReceived">
                  {({ push, remove }) => (
                    <>
                      {values.fundsReceived!.map((_fund, index) => (
                        <FormGroup key={index}>
                          <FormGroup style={{ alignItems: "flex-end", margin: 0 }}>
                            <hr />
                            <IconButton size="small" edge="end" style={{ flex: 0 }} onClick={() => remove(index)}>
                              <CloseIcon />
                            </IconButton>
                          </FormGroup>
                          <FormGroup>
                            <Field
                              component={NumberQuestion}
                              moneyFormat
                              name={`fundsReceived[${index}].amount`}
                              data-testid={`fundsReceived[${index}].amount`}
                              label="Amount Received"
                              prefix="£"
                            />
                          </FormGroup>
                          <FormGroup>
                            <Field
                              component={DatePickerField}
                              name={`fundsReceived[${index}].date`}
                              data-testid={`fundsReceived[${index}].date`}
                              label="Date Received"
                            />
                          </FormGroup>
                          <FormGroup>
                            <Field
                              component={TextQuestion}
                              name={`fundsReceived[${index}].reference`}
                              data-testid={`fundsReceived[${index}].reference`}
                              label="Reference"
                            />
                          </FormGroup>
                          <FormGroup>
                            <Field
                              component={TextQuestion}
                              name={`fundsReceived[${index}].status`}
                              data-testid={`fundsReceived[${index}].status`}
                              label="Status"
                            />
                          </FormGroup>
                        </FormGroup>
                      ))}
                      <FormGroup>
                        <Button data-testid="addPaymentReceived" onClick={() => push({ amount: "", date: null, reference: "", status: "" })}>
                          Add Payment Received
                        </Button>
                      </FormGroup>
                    </>
                  )}
                </FieldArray>
                {values.fundsReceived!.length !== 0 && (
                  <FormGroup>
                    <Field
                      component={CheckboxQuestion}
                      name="disableFundsReceivedEmail"
                      placeholder="Do not send 'Fund Received' email notification"
                      label="Do not send 'Fund Received' email notification"
                    />
                  </FormGroup>
                )}
              </FormGroup>
              <CrudFormButtons isEdit={Boolean(application)} handleDelete={handleDelete} onClose={onClose} />
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default ApplicationForm;
