import { makeStyles } from "@material-ui/core";
import { FC } from "react";

interface Props {
  onClick: ({ checked }: { checked: boolean }) => void;
}

const useStyles = makeStyles(
  (theme) => ({
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    eye: {
      "& input": {
        display: "none",
        "&:checked + $eyeOuter": {
          borderColor: theme.palette.error.main,
          "& $eyeInner": {
            borderColor: theme.palette.error.main,
            background: theme.palette.error.main,
            width: "0.5em",
            height: "0.5em",
          },
        },
      },
    },
    eyeOuter: {
      fontSize: "20px",
      border: `0.1em solid ${theme.palette.emerald.main}`,
      width: "1em",
      height: "1em",
      borderRadius: "50% 10% 50% 10%",
      transform: "rotate(45deg)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "border-color 50ms linear",
    },
    eyeInner: {
      position: "relative",
      width: "0.3em",
      height: "0.3em",
      border: `0.05em solid ${theme.palette.emerald.main}`,
      borderRadius: "50%",
      transition: "width 100ms linear, background-color 50ms linear",
    },
  }),
  { name: "TextHideAdornmentComponent" },
);

const TextHideAdornment: FC<Props> = ({ onClick, ...props }) => {
  const classes = useStyles(props);

  return (
    <div className={classes.container}>
      <label className={classes.eye}>
        <input type="checkbox" onChange={(e) => onClick({ checked: e.target.checked })} />
        <div className={classes.eyeOuter}>
          <div className={classes.eyeInner}></div>
        </div>
      </label>
    </div>
  );
};

export default TextHideAdornment;
