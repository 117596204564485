import React, { useCallback, useEffect, useMemo, useState, useRef, useContext } from "react";
import {
  Container,
  FormGroup,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  makeStyles,
  createStyles,
} from "@material-ui/core";
import useApplication from "./useApplication";
import { useHistory, useParams } from "react-router-dom";
import { AuthContext } from "../../context/auth.context";
import { ROUTES } from "../../utils/constants";
import { getAssessmentById, setUserAssessmentAnswer, submitUserAssessment } from "../../api/application.api";
import { getAdvisedAssessment } from "../../api/advisor/client/application.clientApi";
import { LeanAssessment, Question } from "server/services/assessment/assessment.types";
import { AssessmentStatus } from "server/services/assessment/assessment.consts";
import CoolDown from "./components/CoolDown.component";

interface Props {
  isAdmin?: boolean;
}
const useStyles = makeStyles(
  (theme) =>
    createStyles({
      container: {
        backgroundColor: "#F8F8F8",
        borderTop: "solid 1px",
        borderBottom: "solid 1px",
      },
      subTitle: {
        fontWeight: "bold",
        fontSize: "24px",
      },

      hr: {
        borderTop: "1px solid",
      },
      formGroup: {
        paddingBottom: theme.spacing(5),
        [theme.breakpoints.down("md")]: {
          paddingBottom: theme.spacing(3),
        },
      },
      sectionTitle: {
        fontWeight: 600,
      },
      secondTitle: {
        fontSize: "16px",
        fontWeight: "bold",
        paddingTop: "10px",
      },
      understandRisk: {
        marginBottom: theme.spacing(12.5),
      },
    }),
  { name: "InvestmentAssessmentView" },
);

const Assessment: React.FC<Props> = ({ isAdmin, ...props }) => {
  const classes = useStyles(props);
  const navigate = useHistory();

  const {
    state: { user },
  } = useContext(AuthContext);
  const { clientID: clientIdParam } = useParams<{ applicationId?: string; clientID?: string }>();
  const clientId = useMemo(() => user?.isAdvisor && clientIdParam, [clientIdParam, user?.isAdvisor]);
  const isAdvisor = useMemo(() => Boolean(clientId), [clientId]);
  const assessId = useMemo(() => (isAdvisor ? null : user?.assessmentId), [isAdvisor, user?.assessmentId]);
  const { application } = useApplication(isAdmin);
  const [assessment, setAssessment] = useState<LeanAssessment>();
  const topReference = useRef<HTMLParagraphElement>(null);
  useEffect(() => {
    if (isAdmin) navigate.push(`..${ROUTES.APPLICATION_DETAILS}`, { replace: true });
    if (!assessId && !isAdvisor) return;
    (async () => {
      if (isAdvisor && clientId != null) {
        const res = await getAdvisedAssessment(clientId as string);
        if (res) setAssessment(res);
      } else {
        const res = await getAssessmentById(assessId as string);
        if (res) setAssessment(res);
      }

      topReference.current?.focus();
    })();
  }, [assessId, clientId, isAdvisor]);

  const goDetails = useCallback(() => navigate.push(`..${ROUTES.APPLICATION_DETAILS}`), [navigate]);

  const getQuestionsSubject = useMemo(() => {
    const investorName =
      application?.data.personalDetails?.forenames && application?.data.personalDetails?.surname
        ? `${application?.data.personalDetails?.forenames} ${application?.data.personalDetails?.surname}`
        : `${application?.data.personalDetails?.forenames}`;
    const questions = assessment?.currentAttempt.questions;
    const noOfAttempt = assessment?.totalAttempts ? assessment?.totalAttempts + 1 : 0;
    let message = `${investorName}, please answer the following questions to assess your investment knowledge:`;
    if (noOfAttempt == 2)
      message = `It looks like you have failed your first assessment. Please answer the following newly generated  assessment carefully. If you fail again, you will be required to wait for 24h.`;

    return (
      <>
        {questions && (
          <div className={classes.container}>
            <Container maxWidth="md">
              <FormGroup>
                <Typography>{message}</Typography>
              </FormGroup>
            </Container>
          </div>
        )}
      </>
    );
  }, [
    application?.data.personalDetails?.forenames,
    application?.data.personalDetails?.surname,
    assessment?.currentAttempt.questions,
    assessment?.totalAttempts,
  ]);

  const handleQuestionSelection = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>, questionIndex: number) => {
      const res = await setUserAssessmentAnswer(assessment?.id, questionIndex, event.target.value);
      if (res) {
        if (res.currentAttempt.questions.every((q: Question) => q.answer.selectedOption != null)) {
          const submitResponse = await submitUserAssessment(assessment?.id);
          if (submitResponse) {
            setAssessment(submitResponse);
            topReference.current?.focus();
          }
        } else setAssessment(res);
      }
    },
    [assessment?.id],
  );

  const getQuestions = useMemo(() => {
    const questions = assessment?.currentAttempt.questions as Question[];
    return (
      questions && (
        <form>
          {questions?.map((question, index) => {
            const isAnswered = question.answer?.selectedOption != null ? true : false;
            const questionOptions = question.options;
            delete questionOptions["_id"];
            const optionsKeys = Object.keys(questionOptions);
            return (
              <>
                <FormControl>
                  <FormLabel id={index.toString()} disabled={isAnswered} focused={false} color="primary">
                    {index + 1}. {question.question}
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby={index.toString()}
                    name={question.question}
                    value={isAnswered ? question.answer?.selectedOption : null}
                    onChange={(event) => handleQuestionSelection(event, index)}
                    color="default"
                  >
                    <FormControlLabel value={optionsKeys[0]} control={<Radio />} label={question.options?.a} disabled={isAnswered} />
                    <FormControlLabel value={optionsKeys[1]} control={<Radio />} label={question.options?.b} disabled={isAnswered} />
                    <FormControlLabel value={optionsKeys[2]} control={<Radio />} label={question.options?.c} disabled={isAnswered} />
                    <FormControlLabel value={optionsKeys[3]} control={<Radio />} label={question.options?.d} disabled={isAnswered} />
                  </RadioGroup>
                  {question.answer.selectedOption && (
                    <p>
                      {question.answer.selectedOption == question.answer.correctOption ? (
                        <span style={{ color: "green" }}>You are correct!</span>
                      ) : (
                        <span style={{ color: "red" }}>Incorrect</span>
                      )}
                    </p>
                  )}
                </FormControl>
                <br />
                <br />
              </>
            );
          })}
        </form>
      )
    );
  }, [assessment?.currentAttempt.questions, handleQuestionSelection]);

  const config = useMemo(() => {
    if (
      assessment?.status == AssessmentStatus.COOLDOWN_SUCCESS ||
      assessment?.status == AssessmentStatus.COOLDOWN_FAILED ||
      assessment?.status == AssessmentStatus.COMPLETED_SUCCESSFULLY
    )
      return (
        <CoolDown
          assessmentId={assessment.id}
          investorDetail={application?.data.personalDetails}
          assessmentStatus={assessment.status}
          coolDownTime={assessment.cooldown}
          clientId={clientId}
          isAdvisor={isAdvisor}
        />
      );
    else if (assessment?.status == AssessmentStatus.CONTINUE_TO_THE_APPLICATION) return goDetails();
    else if (assessment?.status == AssessmentStatus.PENDING)
      return (
        <>
          <div ref={topReference} tabIndex={-1} />
          {getQuestionsSubject}
          <Container maxWidth="md">
            <>{getQuestions}</>
          </Container>
        </>
      );
    else return null;
  }, [application?.data.personalDetails, assessment?.cooldown, assessment?.id, assessment?.status, getQuestions, getQuestionsSubject, goDetails]);

  return <>{config}</>;
};

export default Assessment;
