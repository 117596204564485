import { Container, makeStyles, Typography } from "@material-ui/core";
import React, { FC, useEffect } from "react";
import { useHistory } from "react-router-dom";
import AddClientForm from "../../components/AddClientForm.component";
import { ROUTES, TRACKED_EVENTS } from "../../utils/constants";
import InitialRiskWarning from "client/components/InitialRiskWarning.component";

const { PAGE_VIEWS } = TRACKED_EVENTS;

const useStyles = makeStyles(
  () => ({
    hr: {
      borderTop: "solid 1px",
    },
    container: {
      paddingRight: 0,
      paddingLeft: 0,
    },
  }),
  { name: "AddClientView" },
);

const AddClient: FC = (props) => {
  const classes = useStyles(props);
  useEffect(() => {
    globalThis.dataLayer.push({ event: PAGE_VIEWS.ADD_CLIENT });
  }, []);

  const history = useHistory();

  return (
    <div>
      <Container maxWidth="md">
        <Typography variant="h1" align="center" gutterBottom>
          Add a Client
        </Typography>
        <Typography variant="h4" align="center" gutterBottom>
          Enter your client&apos;s information to begin their application
        </Typography>
      </Container>
      <hr className={classes.hr} />
      <Container maxWidth="md">
        <AddClientForm goForward={() => history.push(ROUTES.ADVISOR.DASHBOARD)} goBack={() => history.push(ROUTES.ADVISOR.DASHBOARD)} />
      </Container>
      <InitialRiskWarning isPersistent={false} />
    </div>
  );
};

export default AddClient;
