import { Container } from "@material-ui/core";
import { ColumnDefinition, OnChangeObject, TableCellEditHandler } from "@wearenova/mui-data-table";
import { getFunds, updateFund } from "client/api/admin/funds.adminApi";
import { AdminContext, ADMIN_TYPES } from "client/context/admin.context";
import moment from "moment";
import { FC, useCallback, useContext, useMemo, useState } from "react";
import { LeanFund } from "server/services/fund/fund.types";
import AdminTable from "../AdminTable.view";
import FundForm from "./FundForm.view";

/**
 * Funds tab functional component.
 *
 * @returns FundsTab component
 */

const FundsTab: FC = () => {
  const { funds, dispatch } = useContext(AdminContext);
  const [selectedFund, setSelectedFund] = useState(null);

  // action handlers

  const handleChange = useCallback(
    async (changeObject: OnChangeObject, isExport: boolean) => {
      const data = await getFunds(changeObject);
      if (isExport) return data;
      dispatch({ type: ADMIN_TYPES.SET_FUNDS, payload: data || [] });
    },
    [dispatch],
  );

  const handleRowClick = useCallback((fund) => setSelectedFund(fund), []);

  const handleClose = useCallback(() => {
    setSelectedFund(null);
  }, []);

  const handleTableEdit = useCallback<TableCellEditHandler<LeanFund>>(
    async ({ path, value }, record) => {
      const res = await updateFund({ [path]: value === "null" ? null : value }, record._id);
      if (res) dispatch({ type: ADMIN_TYPES.UPDATE_FUND, payload: res });
    },
    [dispatch],
  );

  // create the structure of the table

  const tableStructure = useMemo<ColumnDefinition<LeanFund>[]>(
    () => [
      {
        key: "name",
        title: "Fund Name",
        sorter: "name",
        dataIndex: "name",
        filterColumn: true,
        editable: true,
      },
      {
        key: "meta.deploymentDate",
        title: "Deployment Date",
        render: (record) => (record.meta.deploymentDate ? moment(record.meta.deploymentDate).format("MM/YYYY") : "TBD"),
        pinnable: true,
        dataType: "date",
        sorter: "meta.deploymentDate",
        filterColumn: "meta.deploymentDate",
        editable: "meta.deploymentDate",
      },
    ],
    [],
  );

  // render the page
  return (
    <Container maxWidth={false} disableGutters>
      <AdminTable tableStructure={tableStructure} tableData={funds} onChange={handleChange} rowClick={handleRowClick} onEdit={handleTableEdit} />
      <FundForm open={Boolean(selectedFund)} onClose={handleClose} fund={selectedFund} />
    </Container>
  );
};

export default FundsTab;
