import { makeStyles, useTheme } from "@material-ui/core";
import { KeyboardDatePicker, KeyboardDatePickerProps } from "@material-ui/pickers";
import clsx from "clsx";
import { ErrorMessage, FieldProps } from "formik";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { SetOptional } from "type-fest";
import { fromCamelCase } from "../../utils/helpers";
import QuestionLabel from "../QuestionLabel.component";
import TrackInput from "../TrackInput.component";
import ErrorText from "../UIKit/ErrorText.component";

interface Props extends FieldProps, SetOptional<KeyboardDatePickerProps, "value" | "onChange"> {
  trackedCategory?: string;
  nullable?: boolean;
}

const gbDateRegex = /[\d]{1,2}\/[\d]{1,2}\/[\d]{4}/;

const useStyles = makeStyles(
  () => ({
    typographys: {
      "& p.MuiTypography-body1": {
        lineHeight: 1,
      },
    },
  }),
  { name: "DatePickerFieldComponent" },
);

const DatePickerField: React.FC<Props> = ({
  field,
  form: { touched, errors, setFieldTouched, setFieldValue },
  label,
  trackedCategory,
  nullable,
  className,
  ...props
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <TrackInput category={trackedCategory} label={fromCamelCase(field.name)} value={field.value}>
      {label && <QuestionLabel>{label}</QuestionLabel>}
      <KeyboardDatePicker
        {...(theme.props?.MuiKeyboardDatePicker ?? {})}
        name={field.name}
        value={gbDateRegex.test(field.value) ? moment(field.value, "DD/MM/YYYY") : field.value}
        onChange={(date) => setFieldValue(field.name, nullable && !date ? null : moment(date).format("YYYY-MM-DD"))}
        onBlur={() => setFieldTouched(field.name)}
        error={Boolean(_.get(touched, field.name) && _.get(errors, field.name))}
        PopoverProps={{ className: clsx(classes.typographys, className, "fs-exclude") }}
        {...props}
        className="fs-exclude"
      />
      <ErrorMessage name={field.name} component={ErrorText} />
    </TrackInput>
  );
};

export default DatePickerField;
