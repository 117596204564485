import { FilteredFund } from "client/api/gql/fund.gqlApi";
import _ from "lodash";
import { useMemo } from "react";
import { BaseDeploymentApplication } from "server/services/application/application.types";
import { AdminCompany, AllDeploymentDeals, BaseDeploymentDeal } from "server/services/company/company.types";
import { getRelevantApps, getRelevantDeals, getToDeployAmount, getWeightingValues, roundTo2DP } from "../utils";

interface Totals {
  weighting: number;
  amount: number;
  available: number;
}

const getInitialTotals = (funds: FilteredFund[]) => {
  return {
    config: funds.reduce<{ [fundId: string]: { [key in "seis" | "eis"]: Totals } }>(
      (totals, fund) => ({
        ...totals,
        [String(fund._id)]: {
          seis: { weighting: 0, amount: 0, available: 0 },
          eis: { weighting: 0, amount: 0, available: 0 },
        },
      }),
      {},
    ),
  };
};

const useDealData = ({
  applications,
  companiesObject,
  deals,
  funds,
}: {
  applications: BaseDeploymentApplication[];
  companiesObject: GenericObject<AdminCompany>;
  deals: BaseDeploymentDeal[];
  funds: FilteredFund[];
}) =>
  useMemo(() => {
    const dealData = [...deals] as AllDeploymentDeals;
    dealData.totals = getInitialTotals(funds);

    funds.forEach((fund) => {
      (["seis", "eis"] as const).forEach((dealStage) => {
        const filteredDeals = getRelevantDeals(dealData, fund._id, dealStage);
        if (!filteredDeals.length) return;
        const rowsToDeploy = getRelevantApps({ applications, fundId: fund._id, dealStage });
        if (!rowsToDeploy.length) return;

        const totalToDeploy = _.sumBy(rowsToDeploy, (row) =>
          roundTo2DP((row.balances.fundsRemaining[dealStage] * (row.config.percentToUse[dealStage] ?? 100)) / 100),
        );

        const { weightedToDeploy, totalWeighting } = getWeightingValues({ totalToDeploy, deals: filteredDeals, fundId: fund._id, dealStage });
        dealData.totals.config[String(fund._id)][dealStage].weighting = totalWeighting;
        dealData.totals.config[String(fund._id)][dealStage].available = totalToDeploy;

        filteredDeals.forEach((deal) => {
          const { toDeploy, fundConfig } = getToDeployAmount({
            deal,
            fundId: fund._id,
            dealStage,
            weightedToDeploy,
            totalWeighting,
            company: companiesObject[deal.company._id],
          });
          fundConfig.amount = toDeploy;
          dealData.totals.config[String(fund._id)][dealStage].amount += toDeploy;
        });
      });
    });

    return dealData;
  }, [applications, companiesObject, deals, funds]);

export default useDealData;
