import { FormControl, makeStyles, MenuItem, Select, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(
  (theme) => ({
    container: {
      display: "flex",
      flexDirection: "row",
    },
    formControl: {
      width: "100%",
    },
    reset: {
      padding: theme.spacing(0, 1.25),
      borderLeft: "2px solid gray",
      marginLeft: 10,
      borderRadius: 0,
    },
  }),
  { name: "FilterComponent" },
);

interface FilterProps {
  filterOptions: { value: string; label: string }[];
  filter: string;
  filterLabel: string;
  onChange: (value: any) => void;
}

const Filter: React.FC<FilterProps> = ({ filterOptions = [], filter = "", filterLabel = "Filter", onChange, ...props }) => {
  const classes = useStyles(props);
  return (
    <div>
      <Typography variant="body1">{filterLabel}</Typography>
      <FormControl variant="outlined" className={classes.formControl}>
        <Select data-testid="filterOptions" onChange={(e) => onChange(e.target.value)} value={filter} inputProps={{ "aria-label": filterLabel }}>
          <MenuItem key="none" value="">
            <em>None</em>
          </MenuItem>
          {filterOptions?.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default Filter;
