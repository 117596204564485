import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { downloadDocument } from "../../../api/document.api";
import DocumentCardBackground from "../../../assets/document_card.png";
import { formatDateString } from "client/utils/helpers";

const useStyles = makeStyles(
  (theme) => ({
    documentContainer: {
      backgroundImage: `url(${DocumentCardBackground})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      cursor: "pointer",
      width: 350,
      height: 350,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "start",
      margin: "1vw",
      boxSizing: "content-box",
      backgroundColor: "transparent",
      border: "none",
      padding: 0,
      [theme.breakpoints.down("xs")]: {
        width: 325,
        height: 325,
      },
    },
    documentShortCode: {
      flex: 1,
      width: "100%",
      boxSizing: "border-box",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "6.25em",
      lineHeight: "60px",
      fontWeight: 900,
      letterSpacing: "10px",
    },
    documentNameDateContainer: {
      width: "100%",
      boxSizing: "border-box",
      padding: 15,
      borderTop: "2px solid black",
      textAlign: "start",
    },
    documentName: {
      fontSize: "1rem",
      fontWeight: 600,
      lineHeight: "18px",
      letterSpacing: "0.8px",
    },
    documentDate: {
      fontSize: "0.75rem",
      fontWeight: 600,
      lineHeight: "18px",
      letterSpacing: "0.6px",
    },
  }),
  { name: "DocumentCardComponent" },
);

interface DocumentCardProps {
  documentId: string;
  documentInitialKey: string;
  documentName: string;
  documentVersion: string;
  documentDate: string;
}

const DocumentCard: React.FC<DocumentCardProps> = ({ documentId, documentInitialKey, documentName, documentVersion, documentDate, ...props }) => {
  const classes = useStyles(props);

  return (
    <button
      className={clsx(classes.documentContainer)}
      {...props}
      onClick={() => downloadDocument(documentId, `${documentName}_v${documentVersion}.pdf`)}
    >
      <Typography className={clsx(classes.documentShortCode)}>{documentInitialKey}</Typography>
      <div className={clsx(classes.documentNameDateContainer)}>
        <Typography className={clsx(classes.documentName)}>{documentName}</Typography>
        <Typography className={clsx(classes.documentDate)}>{formatDateString(documentDate, "MMM YY")}</Typography>
      </div>
    </button>
  );
};

export default DocumentCard;
