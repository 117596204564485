import React, { useEffect } from "react";
import { TRACKED_EVENTS } from "../../../utils/constants";
import SectionA from "./SectionA.component";
import SectionD from "./SectionD.component";

interface Props {
  isAdvisor?: boolean;
}

const { PAGE_VIEWS } = TRACKED_EVENTS;

const Welcome: React.FC<Props> = (props) => {
  useEffect(() => {
    globalThis.dataLayer.push({ event: PAGE_VIEWS.IM_WELCOME });
  }, []);
  return (
    <>
      <SectionA {...props} />
      <br />
      <SectionD {...props} />
    </>
  );
};

export default Welcome;
