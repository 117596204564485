import { FC } from "react";
import { Typography, makeStyles } from "@material-ui/core";

interface Props {}

const useStyles = makeStyles(
  (theme) => ({
    banner: {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      padding: theme.spacing(2),
    },
  }),
  { name: "InfoBannerComponent" },
);

/**
 * General InfoBanner component
 *
 * Creates a simple banner (stretching the full width of its parent container)
 * to provide information to the user.
 *
 * @example
 * <InfoBanner>
 *  <Typography>I love banners!</Typography>
 * </InfoBanner>
 */

const InfoBanner: FC<Props> = (props) => {
  const classes = useStyles(props);

  return (
    <Typography variant="body2" className={classes.banner} align="center" {...props}>
      {props.children}
    </Typography>
  );
};

export default InfoBanner;
