import { useMemo } from 'react';
import { useLocation } from 'react-router';

/**
 * @returns {{ path: string; search: string; location: null | { pathname: string; search: string; } }}
 */
const useLocationReferrer = () => {
  const location = useLocation();
  const [referrerPath, referrerSearch = '?'] = useMemo(
    () =>
      !location.state?.referrer
        ? ['']
        : typeof location.state.referrer === 'string'
        ? location.state.referrer.split('?')
        : [location.state.referrer.path, location.state.referrer.search],
    [location.state?.referrer],
  );
  const referrerLocation = useMemo(
    () =>
      !referrerPath ? null : { pathname: referrerPath, search: `${referrerSearch}${location.search && `&${location.search.replace('?', '')}`}` },
    [location.search, referrerPath, referrerSearch],
  );

  return { path: referrerPath, search: referrerSearch, location: referrerLocation };
};

export default useLocationReferrer;
