import { MutableRefObject, useDebugValue, useEffect, useRef } from "react";

const titles: MutableRefObject<string | undefined>[] = [];

/**
 * This hook will set the window title, when a component gets mounted.
 * When the component gets unmounted, the previously used title will be restored.
 * @param {string} title
 */
export const useTitle = (title: string) => {
  const ref = useRef<string>();
  useDebugValue(title);

  if (ref.current === undefined) {
    titles.push(ref);
  }

  ref.current = title;

  useEffect(
    () => () => {
      titles.splice(titles.indexOf(ref), 1);
      document.title = getTitle();
    },
    [],
  );

  useEffect(() => {
    document.title = getTitle();
  }, [title]);
};

/**
 * Returns the current window title to be used in a SSR context
 * @returns {string}
 */
export const getTitle = (): string => titles[titles.length - 1]?.current ?? "";

export default useTitle;
