import fileDownload from "js-file-download";
import {
  Experience,
  FinancialSituation,
  InvestmentInformation,
  LeanApplication,
  Objectives,
  PersonalDetails,
} from "server/services/application/application.types";
import { LeanAssessment } from "server/services/assessment/assessment.types";
import notifications from "../utils/notifications";
import { ipAxios } from "./ipAxios";

const postPersonalDetails = async (applicationId: string, values: PersonalDetails) => {
  try {
    const res = await ipAxios.post<LeanApplication>(`/applications/${applicationId}/personalDetails/`, values);
    return res.data;
  } catch (error: any) {
    notifications.genericError(error);
    return null;
  }
};

const postObjectives = async (applicationId: string, values: Objectives) => {
  try {
    const res = await ipAxios.post<LeanApplication>(`/applications/${applicationId}/objectives/`, values);
    return res.data;
  } catch (error: any) {
    notifications.genericError(error);
    return null;
  }
};

const postExperience = async (applicationId: string, values: Experience) => {
  try {
    const res = await ipAxios.post<LeanApplication>(`/applications/${applicationId}/experience/`, values);
    return res.data;
  } catch (error: any) {
    notifications.genericError(error);
    return null;
  }
};

const postFinances = async (applicationId: string, values: FinancialSituation) => {
  try {
    const res = await ipAxios.post<LeanApplication>(`/applications/${applicationId}/finances/`, values);
    return res.data;
  } catch (error: any) {
    notifications.genericError(error);
    return null;
  }
};

const postInvestment = async (applicationId: string, values: InvestmentInformation) => {
  try {
    const res = await ipAxios.post<LeanApplication>(`/applications/${applicationId}/investment/`, values);
    return res.data;
  } catch (error: any) {
    if (error.response.status >= 500) {
      notifications.genericError(error);
    } else {
      notifications.error(error.response.data, error);
    }
    return null;
  }
};

const downloadApplication = async (applicationId: string) => {
  try {
    const response = await ipAxios({
      url: `/applications/${applicationId}/download`,
      method: "GET",
      responseType: "blob",
      headers: {
        "Content-Type": "applications/json",
      },
    });
    if (response.status === 200) {
      const blob = new Blob([response.data]);
      fileDownload(blob, `${response.headers["x-file-name"]}.pdf`);
      return { success: true, data: response.data };
    }
    return { success: false };
  } catch (error: any) {
    notifications.genericError();
    return { success: false };
  }
};

const requestSignatureUrl = async (applicationId: string, clientId?: string, sigId?: string | null) => {
  const isClientId = clientId ? `/advisor/clients/${clientId}` : "";
  const isSignId = sigId ? `/${sigId}` : "";
  try {
    const res = await ipAxios.get(`${isClientId}/applications/${applicationId}/requestSignatureUrl${isSignId}`);
    return { success: true, data: res.data };
  } catch (error: any) {
    notifications.genericError(error);
    return { success: false };
  }
};

const getAMLChecksURL = async (applicationId: string) => {
  try {
    const res = await ipAxios.get<string>(`/applications/${applicationId}/aml/url`);
    return res.data;
  } catch (error: any) {
    notifications.error("Failed initialising identity check. Please try again later.", error);
    return null;
  }
};

const setFlowCompleted = async (applicationId: string) => {
  try {
    const res = await ipAxios.put<undefined>(`applications/${applicationId}/aml/flow-completed`);
    return res.data;
  } catch (error: any) {
    notifications.genericError(error);
    return null;
  }
};

const getInvestorDetails = async (applicationId: string) => {
  try {
    const res = await ipAxios.get<undefined | LeanApplication>(`/applications/${applicationId}/aml/extracted-details`);
    return res.data;
  } catch (error: any) {
    notifications.genericError(error);
    return null;
  }
};

export async function getAssessmentById(assessmentId: string) {
  try {
    const res = await ipAxios.get<LeanAssessment>(`/assessments/${assessmentId}`);
    return res.data;
  } catch (error: any) {
    notifications.genericError(error);
    return null;
  }
}

export async function setUserAssessmentAnswer(assessmentId: string, question: number, selectedOption: string) {
  try {
    const res = await ipAxios.put<LeanAssessment>(`/assessments/${assessmentId}/attempts/current/questions/${question}/answer`, { selectedOption });
    return res.data;
  } catch (error: any) {
    notifications.genericError(error);
    return null;
  }
}

export async function submitUserAssessment(assessmentId: string) {
  try {
    const res = await ipAxios.put<LeanAssessment>(`/assessments/${assessmentId}/submit`);
    return res.data;
  } catch (error: any) {
    notifications.genericError(error);
    return null;
  }
}

export async function completeUserAssessment(assessmentId: string) {
  try {
    const res = await ipAxios.put<LeanAssessment>(`/assessments/${assessmentId}/continue`);
    return res.data;
  } catch (error: any) {
    notifications.genericError(error);
    return null;
  }
}

export default {
  downloadApplication,
  getAMLChecksURL,
  getInvestorDetails,
  postPersonalDetails,
  postObjectives,
  postExperience,
  postFinances,
  postInvestment,
  requestSignatureUrl,
  setFlowCompleted,
};
