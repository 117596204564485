import { setToken } from "../../utils/auth";
import { clearLocalStorage } from "../../utils/helpers";
import notifications from "../../utils/notifications";
import { ipAxios } from "../ipAxios";

const register = async (values) => {
  try {
    const res = await ipAxios.post(`/public/authentication/register`, values);
    if (res.status === 200) {
      clearLocalStorage();
      setToken(res.data.token);
      return { success: true, data: res.data.user };
    }
    return { success: false };
  } catch (error) {
    if (error.response.status >= 500) {
      notifications.genericError(error);
    } else {
      notifications.error(error.response.data, error);
    }
    return { success: false };
  }
};

const registerAdvisor = async (values) => {
  try {
    const res = await ipAxios.post(`/public/authentication/advisor`, values);
    if (res.status === 200) {
      clearLocalStorage();
      setToken(res.data.token);
      return { success: true, data: res.data.user };
    }
    return { success: false };
  } catch (error) {
    notifications.genericError(error);
    return { success: false };
  }
};

const login = async (values) => {
  try {
    const res = await ipAxios.post(`/public/authentication/login`, values);
    if (res.status === 200) {
      setToken(res.data.token);
      return { success: true, data: res.data.user };
    }
    return { success: false };
  } catch (error) {
    notifications.error("Incorrect email or password", error);
    return { success: false };
  }
};

const verifyToken = async () => {
  try {
    const res = await ipAxios.post(`/public/authentication/token`);
    if (res.status === 200) {
      return { success: true, data: res.data };
    }
    return { success: false };
  } catch (error) {
    if (error.response.status === 401) {
      return { success: false, data: error.response.data };
    }
    return { success: false };
  }
};

const confirmEmail = async (values) => {
  try {
    await ipAxios.post(`/public/authentication/confirmEmail`, values);
    return true;
  } catch (error) {
    if (error.response.status === 409) return false;
    notifications.genericError(error);
  }
};
const forgotPassword = async (emailAddress) => {
  try {
    await ipAxios.post(`/public/authentication/forgot-password`, {
      email: emailAddress,
    });
    return true;
  } catch (e) {
    notifications.genericError(e);
    return false;
  }
};

const resendForgotPasswordLink = async (userId) => {
  try {
    const res = await ipAxios.post(`/public/authentication/${userId}/forgot-password`);
    if (res.status === 200) {
      notifications.success("A new link has been sent to your email", 10);
      return res;
    }
    return null;
  } catch (e) {
    notifications.genericError(e);
    return null;
  }
};

const publicResend = async (userId) => {
  try {
    const res = await ipAxios.post(`/public/authentication/${userId}/resend`);
    if (res.status === 200) {
      notifications.success("A new link has been sent to your email", 10);
      return res;
    }
    return null;
  } catch (error) {
    notifications.genericError();
    return null;
  }
};

export default {
  register,
  registerAdvisor,
  login,
  verifyToken,
  confirmEmail,
  forgotPassword,
  resendForgotPasswordLink,
  publicResend,
};
