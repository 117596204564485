import { Button, Collapse, FormGroup, Link, makeStyles } from "@material-ui/core";
import PasswordQuestion from "client/components/FormControls/PasswordQuestion.component";
import useLocationReferrer from "client/hooks/useLocationReferrer";
import clsx from "clsx";
import { Field, Form, Formik } from "formik";
import React, { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import PublicAuthenticationApi from "../../../api/public/authentication.publicApi";
import TextQuestion from "../../../components/FormControls/TextQuestion.component";
import { AuthContext, AUTH_TYPES } from "../../../context/auth.context";
import { ROUTES, TRACKED_EVENTS } from "../../../utils/constants";
import notifications from "../../../utils/notifications";

const { PAGE_ACTIONS, PAGE_VIEWS } = TRACKED_EVENTS;

const useStyles = makeStyles(
  (theme) => ({
    errorMessage: {
      margin: "10px 0",
    },
    forgotPassword: {
      alignSelf: "flex-end",
    },
    formContainer: {
      "& > form": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        transition: "height 0.5s",
        height: 220,
        "& > .MuiFormGroup-root": {
          marginTop: theme.spacing(2),
        },
      },
    },
    formContainerSmall: {
      "& > form": {
        height: 170,
      },
    },
    password: {
      transition: "height 0.5s",
    },
    passwordFormGroup: {
      margin: 0,
    },
    passwordInvisible: {
      margin: 0,
      overflow: "hidden",
      height: 0,
    },
  }),
  { name: "LoginFormComponent" },
);

const INITIAL_VALUES = {
  email: "",
  password: "",
};

export default (props) => {
  const { dispatch } = React.useContext(AuthContext);
  const classes = useStyles(props);
  const history = useHistory();
  const referrer = useLocationReferrer();
  const [successfulSent, setSuccessfulSent] = useState(null);
  const [forgotClicked, setForgotClicked] = useState(false);

  const SCHEMA = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string().email("Not a valid email").required("Please enter your email"),
        password: forgotClicked ? Yup.string().notRequired() : Yup.string().required("Please enter your password"),
      }),
    [forgotClicked],
  );

  const handleSubmit = useCallback(
    async (values) => {
      if (forgotClicked) {
        globalThis.dataLayer.push({ event: PAGE_VIEWS.FORGOT_PASSWORD });
        const success = await PublicAuthenticationApi.forgotPassword(values.email.toLowerCase());
        if (success) {
          notifications.success(
            <>
              If an account with this email exists, you will be sent a link to reset your password.
              <br />
              <br />
              <strong>Please check your inbox.</strong>
            </>,
            10,
          );
        }
        setSuccessfulSent(success);
        globalThis.dataLayer.push({ event: PAGE_ACTIONS.FORGOT_PASSWORD_SUBMITTED_SUCCESSFULLY });
      } else {
        const { success, data } = await PublicAuthenticationApi.login(values);
        if (success) {
          globalThis.dataLayer.push({ event: PAGE_ACTIONS.LOGIN_FORM_SUBMITTED_SUCCESSFULLY });
          dispatch({ type: AUTH_TYPES.SET_USER, payload: data });

          if (referrer.location) {
            history.push(referrer.location);
          } else if (data.isAdvisor) {
            history.push(ROUTES.ADVISOR.DASHBOARD);
          } else {
            history.push(ROUTES.DASHBOARD_OVERVIEW);
          }
        }
      }
    },
    [dispatch, forgotClicked, history, referrer],
  );

  return (
    <div className={clsx(classes.formContainer, { [classes.formContainerSmall]: forgotClicked })}>
      <Formik initialValues={INITIAL_VALUES} validationSchema={SCHEMA} onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <Form id="login-form">
            <FormGroup>
              <Field component={TextQuestion} name="email" data-testid="loginEmail" placeholder="Email" size="small" autoComplete="username" />
            </FormGroup>
            <FormGroup className={clsx({ [classes.passwordFormGroup]: forgotClicked })}>
              <Collapse in={!forgotClicked}>
                <Field
                  component={PasswordQuestion}
                  showControls
                  name="password"
                  data-testid="loginPassword"
                  placeholder="Password"
                  size="small"
                  autoComplete="current-password"
                />
              </Collapse>
            </FormGroup>
            <Link
              variant="body2"
              data-testid="forgottenPasswordButton"
              className={classes.forgotPassword}
              onClick={() => setForgotClicked(!forgotClicked)}
            >
              {!forgotClicked ? "Forgot your password?" : "Cancel"}
            </Link>
            <FormGroup>
              <Button
                disabled={isSubmitting || Boolean(forgotClicked && successfulSent)}
                data-testid="loginButton"
                size="small"
                fullWidth
                type="submit"
                color="primary"
              >
                {forgotClicked ? "Send Email" : "Login"}
              </Button>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </div>
  );
};
