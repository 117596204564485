import { Button, FormGroup, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useFormikContext } from "formik";
import React, { useContext, useMemo, useState } from "react";
import { AuthContext } from "../../context/auth.context";

interface Props {
  isEdit?: boolean;
  handleDelete?: () => void;
  onClose: () => any;
  isSubmitAll?: boolean;
  submitText?: string;
  cancelText?: string;
  sticky?: boolean;
}

const useStyles = makeStyles(
  (theme) => ({
    deleteButton: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.text.secondary,
      "&:hover": {
        backgroundColor: theme.palette.error.dark,
      },
    },
    sticky: {
      position: "sticky",
      bottom: 0,
    },
  }),
  { name: "CrudFormButtons" },
);

const CrudFormButtons: React.FC<Props> = ({ isEdit, handleDelete, onClose, isSubmitAll = false, submitText, cancelText, sticky = false }) => {
  const classes = useStyles();
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const { isSubmitting, isValidating } = useFormikContext();
  const {
    state: { user },
  } = useContext<any>(AuthContext);

  // This is in a useMemo in case we want to add other options that would mean the buttons are disabled
  const disabled = useMemo(() => user.role === "readOnlyAdmin" || isSubmitting || isValidating, [isSubmitting, isValidating, user.role]);

  return (
    <FormGroup row className={clsx({ [classes.sticky]: sticky })}>
      {deleteConfirmation && (
        <Typography align="center" style={{ width: "100%" }}>
          Are you sure?
        </Typography>
      )}
      {deleteConfirmation && isEdit ? (
        <Button type="button" fullWidth variant="outlined" disabled={disabled} onClick={() => setDeleteConfirmation(false)}>
          No
        </Button>
      ) : isEdit && !isSubmitAll ? (
        <Button
          type="button"
          data-testid="deleteUserButton"
          fullWidth
          className={classes.deleteButton}
          disabled={disabled}
          onClick={() => setDeleteConfirmation(true)}
        >
          Delete
        </Button>
      ) : (
        <Button type="reset" fullWidth onClick={() => onClose()}>
          {cancelText || "Cancel"}
        </Button>
      )}
      {deleteConfirmation && isEdit ? (
        <Button
          type="reset"
          data-testid="confirmDelete"
          disabled={disabled}
          fullWidth
          onClick={(e) => {
            handleDelete && handleDelete();
            e.preventDefault();
          }}
        >
          Yes
        </Button>
      ) : (
        <Button type="submit" fullWidth disabled={disabled} data-testid="submit">
          {isEdit ? "Update" : isSubmitAll ? "Submit All" : submitText || "Submit"}
        </Button>
      )}
    </FormGroup>
  );
};

export default CrudFormButtons;
