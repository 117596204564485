import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import React, { useState } from 'react';

const DisplayLink = ({ link, onClose, ...props }) => {
  const [copiedToken, setCopiedToken] = useState(false);
  return (
    <>
      <Dialog onClose={onClose} {...props}>
        <DialogTitle>CSV Export Link</DialogTitle>
        <DialogContent>
          <p style={{ wordBreak: 'break-all' }}>{link}</p>
          {copiedToken && <p style={{ color: 'green' }}>Link copied!</p>}
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              navigator.clipboard.writeText(`${link}`);
              setCopiedToken(true);
            }}
          >
            <Typography color="textPrimary">Copy to Clipboard</Typography>
          </Button>
          <Button
            variant="text"
            onClick={() => {
              setCopiedToken(false);
              onClose();
            }}
          >
            <Typography color="textPrimary">Close</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DisplayLink;
