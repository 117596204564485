import fileDownload from "js-file-download";
import { UpdateQuery } from "mongoose";
import { LeanApplication } from "server/services/application/application.types";
import notifications from "../../utils/notifications";
import { ipAxios } from "../ipAxios";

export const addApplication = async (
  values: Omit<UpdateQuery<LeanApplication>, "fileName"> & { fileName?: string | File },
  applicationId?: string,
) => {
  try {
    const formData = new FormData();
    if (values.fileName && typeof values.fileName !== "string") {
      formData.append("fileName", values.fileName, values.fileName.name);
    }
    formData.append("body", JSON.stringify(values));
    const res = await ipAxios.post(`/admin/application/${applicationId ?? ""}`, formData);
    return res.data;
  } catch (error: any) {
    notifications.error(error.message, error);
    return null;
  }
};

export const downloadBlankApplication = async () => {
  try {
    const res = await ipAxios({
      url: `/admin/application/blank-application`,
      method: "GET",
      responseType: "blob",
    });
    if (res.status === 200) {
      fileDownload(new Blob([res.data]), "blank-application.pdf");
      return { success: true, data: res.data };
    }
    return { success: false };
  } catch (error: any) {
    notifications.error(error.message, error);
    return { success: false };
  }
};

export const downloadApplicationsData = async () => {
  try {
    const res = await ipAxios({
      url: `/admin/application/download`,
      method: "GET",
      responseType: "blob",
    });
    if (res.status === 200) {
      fileDownload(new Blob([res.data]), `application-data-${Date.now()}.csv`);
      return { success: true, data: res.data };
    }
    return { success: false };
  } catch (error: any) {
    notifications.error(error.message, error);
    return { success: false };
  }
};

export const getApplications = async (onChangeObject: GenericObject) => {
  try {
    const res = await ipAxios(`/admin/application/`, { params: onChangeObject });
    return { data: res.data, count: res.headers["x-count"] };
  } catch (error: any) {
    if (error.message !== "duplicate request cancelled") {
      notifications.error(error.message, error);
    }
    return null;
  }
};

export const linkInvestmentToApplication = async (applicationId: string, investment: any) => {
  try {
    const res = await ipAxios.post(`/admin/application/${applicationId}/link`, investment);
    if (res.status === 200) {
      return { success: true, data: res.data };
    }
    return { success: false };
  } catch (error: any) {
    notifications.error(error.message, error);
    return { success: false };
  }
};

export const downloadFullApplication = async (applicationId: string, surname: string) => {
  try {
    const res = await ipAxios({
      url: `/admin/application/${applicationId}/download`,
      method: "GET",
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (res.status === 200) {
      const blob = new Blob([res.data]);
      fileDownload(blob, `${surname}-${applicationId}.pdf`);
      return { success: true, data: res.data };
    }
    return { success: false };
  } catch (error: any) {
    notifications.error(error.message, error);
    return { success: false };
  }
};

export const deleteAdminApplication = async (applicationId: string) => {
  try {
    const res = await ipAxios.delete(`/admin/application/${applicationId}`);

    return res.data;
  } catch (error: any) {
    notifications.error(error.message, error);

    return { success: false };
  }
};

export const generateApplication = async (applicationId: string) => {
  try {
    const res = await ipAxios.get(`/admin/application/${applicationId}/generate`);
    return res.data;
  } catch (error: any) {
    notifications.error(error.message, error);
    return null;
  }
};

export const getApplication = async (applicationId: string) => {
  try {
    const res = await ipAxios.get(`/admin/application/${applicationId}`);
    return res.data;
  } catch (error: any) {
    notifications.error(error.message, error);
    return null;
  }
};
export const submitApplication = async (applicationId: string) => {
  try {
    await ipAxios.post(`/admin/application/${applicationId}/submit`);

    notifications.success("Application Submitted!");
    return true;
  } catch (error: any) {
    notifications.error(error.message, error);
    return null;
  }
};

export const getPreview = async (applicationId: string) => {
  try {
    const res = await ipAxios.get(`/admin/application/${applicationId}/preview`, {
      responseType: "arraybuffer",
      headers: {
        Accept: "application/pdf",
      },
    });

    const blob = new Blob([res.data], { type: "application/pdf" });
    return window.URL.createObjectURL(blob);
  } catch (error: any) {
    notifications.error(error.message, error);
    return null;
  }
};

export const genApplication = async (applicationId: string, values: any) => {
  try {
    const res = await ipAxios.post(`/admin/applications/${applicationId}`, values);
    return { success: res.status === 200, data: res.data };
  } catch (error: any) {
    notifications.genericError(error);
    return false;
  }
};

export const getExpectedPayments = async () => {
  try {
    const res = await ipAxios.get("/admin/application/expectedPayments");
    return res.data;
  } catch (error: any) {
    notifications.error(error.message, error);
    return null;
  }
};

export const downloadPaymentsReceivedData = async () => {
  try {
    const res = await ipAxios({
      url: "/admin/application/payments",
      method: "GET",
      responseType: "blob",
    });
    if (res.status === 200) {
      fileDownload(new Blob([res.data]), `payments-received-${Date.now()}.csv`);
      return { success: true, data: res.data };
    }
    return { success: false };
  } catch (error: any) {
    notifications.error(error.message, error);
    return { success: false };
  }
};

export const sendNewFundsReceivedEmail = async (applicationId: string) => {
  try {
    const res = await ipAxios.put(`/admin/application/${applicationId}/newFundsReceivedEmail`);
    return res.data;
  } catch (error: any) {
    notifications.error(error.message, error);
    return null;
  }
};

export const getIntroducers = async () => {
  try {
    const res = await ipAxios.get("/admin/application/introducers");
    return res.data;
  } catch (error: any) {
    notifications.error(error.message, error);
    return null;
  }
};

export const getAppsVSFundsReceivedLink = async () => {
  try {
    const res = await ipAxios.get<string>("/admin/application/export/link");
    return res.data;
  } catch (error: any) {
    notifications.error(error.message, error);
    return null;
  }
};

export const postPersonalDetails = async (applicationId: string, values: any) => {
  try {
    const res = await ipAxios.post<LeanApplication>(`/admin/application/${applicationId}/personalDetails`, values);
    return res.data;
  } catch (error: any) {
    notifications.genericError(error);
    return null;
  }
};

export const postObjectives = async (applicationId: string, values: any) => {
  try {
    const res = await ipAxios.post<LeanApplication>(`/admin/application/${applicationId}/objectives`, values);
    return res.data;
  } catch (error: any) {
    notifications.genericError(error);
    return null;
  }
};

export const postFinances = async (applicationId: string, values: any) => {
  try {
    const res = await ipAxios.post<LeanApplication>(`/admin/application/${applicationId}/finances`, values);
    return res.data;
  } catch (error: any) {
    notifications.genericError(error);
    return null;
  }
};

export const postExperience = async (applicationId: string, values: any) => {
  try {
    const res = await ipAxios.post<LeanApplication>(`/admin/application/${applicationId}/experience`, values);
    return res.data;
  } catch (error: any) {
    notifications.genericError(error);
    return null;
  }
};

export const postInvestment = async (applicationId: string, values: any) => {
  try {
    const res = await ipAxios.post<LeanApplication>(`/admin/application/${applicationId}/investment`, values);
    return res.data;
  } catch (error: any) {
    notifications.genericError(error);
    return null;
  }
};
