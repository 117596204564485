import React, { createContext, Dispatch, FC, Reducer, useContext, useEffect, useReducer } from "react";
import { UserState, UserStateClient } from "server/services/user/user.types";
import { AuthContext } from "./auth.context";

interface State extends UserState {
  dispatch: Dispatch<UserAction>;
}

interface Props {
  userData: UserState;
}

interface UserAction {
  type: typeof UserContextTypes[keyof typeof UserContextTypes];
  payload?: UserState[keyof Omit<UserState, "clients">] | UserStateClient[];
  clientId?: string;
}

type UserReducer = Reducer<UserState, UserAction>;

export const DEFAULT_USER_DATA: State = {
  balances: {
    fundsRemaining: { eis: 0, seis: 0, total: 0, amountHeldElsewhere: 0 },
    fundsReceived: { total: 0, seis: 0, eis: 0 },
  },
  holdings: null,
  historicValues: [],
  statement: [],
  applications: [],
  documents: [],
  advisor: {},
  clients: {},
  dispatch: () => {},
};

const UserContext = createContext(DEFAULT_USER_DATA);
UserContext.displayName = "UserContext";

export const UserContextTypes = {
  SET_DATA: "SET_DATA",
  CLEAR_DATA: "CLEAR_DATA",

  SET_BALANCES: "SET_BALANCES",
  SET_HOLDINGS: "SET_HOLDINGS",
  SET_HISTORIC_VALUES: "SET_HISTORIC_VALUES",

  SET_STATEMENT: "SET_STATEMENT",

  SET_APPLICATIONS: "SET_APPLICATIONS",

  SET_DOCUMENTS: "SET_DOCUMENTS",

  SET_ADVISOR: "SET_ADVISOR",

  SET_CLIENTS: "SET_CLIENTS",
  SET_CLIENT: "SET_CLIENT",
} as const;

const { clients: _clients, ...DEFAULT_CLIENT_DATA } = {
  ...DEFAULT_USER_DATA,
  userId: "",
  clientName: "",
  email: "",
  applicationStatus: "",
  adviceType: "",
  fee: "",
  onInvite: () => null,
  onEdit: () => null,
};

const updateState = (state: UserState, key: keyof UserState, payload: UserState[keyof UserState], clientId?: string) => {
  return {
    ...state,
    ...(!clientId
      ? { [key]: payload }
      : { clients: { ...state.clients, [clientId]: { ...DEFAULT_CLIENT_DATA, ...state.clients[clientId], [key]: payload } } }),
  };
};

const reducer: UserReducer = (state, { clientId, ...action }) => {
  switch (action.type) {
    case UserContextTypes.SET_DATA:
      return { ...state, ...action.payload };
    case UserContextTypes.CLEAR_DATA:
      return { ...DEFAULT_USER_DATA };

    case UserContextTypes.SET_BALANCES:
      return updateState(state, "balances", action.payload!, clientId);
    case UserContextTypes.SET_HOLDINGS:
      return updateState(state, "holdings", action.payload!, clientId);
    case UserContextTypes.SET_HISTORIC_VALUES:
      return updateState(state, "historicValues", action.payload!, clientId);

    case UserContextTypes.SET_STATEMENT:
      return updateState(state, "statement", action.payload!, clientId);

    case UserContextTypes.SET_APPLICATIONS:
      return updateState(state, "applications", action.payload!, clientId);

    case UserContextTypes.SET_DOCUMENTS:
      return updateState(state, "documents", action.payload!, clientId);

    case UserContextTypes.SET_ADVISOR:
      return updateState(state, "advisor", action.payload!, clientId);

    case UserContextTypes.SET_CLIENTS:
      return {
        ...state,
        clients: (action.payload! as UserStateClient[]).reduce(
          (clients, client) => ({
            ...clients,
            [client.userId]: {
              ...DEFAULT_CLIENT_DATA,
              ...state.clients[client.userId],
              ...client,
            },
          }),
          { ...state.clients },
        ),
      };
    case UserContextTypes.SET_CLIENT:
      return {
        ...state,
        clients: {
          ...state.clients,
          [clientId!]: {
            ...DEFAULT_CLIENT_DATA,
            ...state.clients[clientId!],
            ...action.payload,
          },
        },
      };

    default:
      return { ...state };
  }
};

export const UserProvider: FC<Props> = ({ userData, ...props }) => {
  const [state, dispatch] = useReducer<UserReducer>(reducer, userData);
  const {
    state: { user },
  } = useContext(AuthContext);

  useEffect(() => {
    if (userData) {
      dispatch({ type: UserContextTypes.SET_DATA, payload: userData as any });
    }
  }, [userData]);

  useEffect(() => {
    if (!user) {
      dispatch({ type: UserContextTypes.CLEAR_DATA });
    }
  }, [user]);

  return <UserContext.Provider value={{ ...state, dispatch }}>{props.children}</UserContext.Provider>;
};

export default UserContext;
