import { setToken } from "../../utils/auth";
import { clearLocalStorage } from "../../utils/helpers";
import notifications from "../../utils/notifications";
import { ipAxios } from "../ipAxios";

const getUser = async (uid) => {
  try {
    const res = await ipAxios.get(`/public/user/`, {
      params: { uid },
    });
    return res.data;
  } catch (error) {
    return null;
  }
};

const updateUserPassword = async (values) => {
  try {
    const res = await ipAxios.post(`/public/user/updatePassword`, values);

    if (res.status === 200) {
      clearLocalStorage();
      setToken(res.data.token);
      return { success: true, data: res.data.user };
    }
    return { success: false };
  } catch (error) {
    notifications.genericError(error);
    return { success: false };
  }
};

const clientAgreeToCharges = async (values) => {
  try {
    const res = await ipAxios.post(`/public/user/agreeToCharges`, values);
    if (res.status === 200) {
      return { success: true };
    }
    return { success: false };
  } catch (error) {
    notifications.genericError(error);
    return { success: false };
  }
};

export default {
  getUser,
  updateUserPassword,
  clientAgreeToCharges,
};
