import { Button, ButtonProps, Link, LinkProps, makeStyles } from "@material-ui/core";
import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../utils/constants";

type ButtonLinkProps<T extends boolean | undefined> = T extends true ? LinkProps : ButtonProps;

type Props<T extends boolean | undefined> = ButtonLinkProps<T> & {
  className?: string;
  link?: boolean;
};

const useStyles = makeStyles(
  (theme) => ({
    button: {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  }),
  { name: "QualifyToInvestComponent" },
);

const QualifyToInvest = <T extends boolean | undefined>({ link = false, ...props }: Props<T>) => {
  const classes = useStyles(props);
  const history = useHistory();

  const qualifyToInvestProps = useMemo(
    () => ({
      className: classes.button,
      "data-testid": "qualifyToInvestButton",
      onClick: () => {
        history.push(ROUTES.QUALIFY);
      },
      ...props,
    }),
    [classes.button, history, props],
  );

  return (
    <span>
      {link ? (
        <Link {...(qualifyToInvestProps as LinkProps)}>Qualify to invest</Link>
      ) : (
        <Button {...(qualifyToInvestProps as ButtonProps)}>Qualify to invest</Button>
      )}
    </span>
  );
};

export default QualifyToInvest;
