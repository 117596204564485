import { Container, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import AdvisorApi from "../../api/advisor/clients.advisorApi";
import AddClientForm from "../../components/AddClientForm.component";
import { ROUTES, TRACKED_EVENTS } from "../../utils/constants";

const { PAGE_VIEWS } = TRACKED_EVENTS;

const useStyles = makeStyles(() => ({
  hr: {
    borderTop: "solid 1px",
  },
  container: {
    paddingRight: 0,
    paddingLeft: 0,
  },
}));

const EditClient: React.FC = (props) => {
  const classes = useStyles(props);
  const [client, setClient] = useState(null);
  const history = useHistory();
  const match = useRouteMatch<{ clientID?: string }>();
  const clientId = useMemo(() => match.params.clientID, [match.params.clientID]);

  useEffect(() => {
    globalThis.dataLayer.push({ event: PAGE_VIEWS.EDIT_CLIENT_FEE });
  }, []);

  useEffect(() => {
    (async () => {
      if (!clientId) return;
      const tempClient = await AdvisorApi.getClient(clientId);
      if (tempClient) setClient(tempClient);
    })();
  }, [clientId]);

  return (
    <Container maxWidth={false} className={classes.container}>
      <Container>
        <Typography variant="h1" gutterBottom>
          Edit your client&apos;s fee below
        </Typography>
      </Container>
      <hr className={classes.hr} />
      <Container>
        <AddClientForm
          goForward={() => history.push(ROUTES.ADVISOR.DASHBOARD)}
          goBack={() => history.push(ROUTES.ADVISOR.DASHBOARD)}
          client={client}
        />
      </Container>
    </Container>
  );
};

export default EditClient;
