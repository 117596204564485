import { createStyles, makeStyles, useTheme } from "@material-ui/core";
import { Chart } from "chart.js";
import React, { forwardRef, ForwardRefRenderFunction, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";

interface Props {
  values: Array<{ x: string; y: number }>;
}

const useStyles = makeStyles(
  () =>
    createStyles({
      chart: {
        maxHeight: "100%",
      },
    }),
  { name: "MiniValueChart" },
);

const MiniValueChart: ForwardRefRenderFunction<number, Props> = ({ values, ...props }, ref) => {
  const theme = useTheme();
  const classes = useStyles(props);
  const canvas = useRef<HTMLCanvasElement>(null);
  const [chartHeight, setChartHeight] = useState(0);

  useImperativeHandle(ref, () => chartHeight, [chartHeight]);

  const createMiniChart = useCallback(() => {
    const ctx = canvas.current?.getContext("2d");
    if (!ctx) return;
    const maxY = Math.max(...values.map(({ y }) => y));
    const chart = new Chart<"line", typeof values, string>(ctx, {
      type: "line",
      data: {
        labels: [],
        datasets: [
          {
            data: values,
            borderColor: theme.palette.primary.main,
          },
        ],
      },
      options: {
        animation: false,
        responsive: typeof ResizeObserver !== "undefined",
        elements: {
          point: {
            radius: 0,
            hoverRadius: 0,
            hitRadius: 0,
          },
        },
        scales: {
          x: {
            display: false,
          },
          y: {
            max: maxY + maxY * 0.1,
            display: false,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
          title: {
            display: false,
          },
        },
      },
    });
    if (chart.canvas) setChartHeight(chart.canvas.scrollHeight);
    return () => chart.destroy();
  }, [theme.palette.primary.main, values]);

  useEffect(() => {
    return createMiniChart();
  }, [createMiniChart]);

  return <canvas ref={canvas} className={classes.chart} />;
};

export default forwardRef(MiniValueChart);
