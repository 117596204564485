import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormGroup,
  FormHelperText,
  Typography,
} from "@material-ui/core";
import { bulkUpdateShareTransactions } from "client/api/admin/shareTransactions.adminApi";
import CheckboxQuestion from "client/components/FormControls/CheckboxQuestion.component";
import DatePickerField from "client/components/FormControls/DatePickerField.component";
import SelectField from "client/components/FormControls/SelectField.component";
import { AdminContext, ADMIN_TYPES } from "client/context/admin.context";
import { SHARE_TRANSACTION_STATUSES } from "client/utils/constants";
import notifications from "client/utils/notifications";
import { Field, Form, Formik } from "formik";
import React, { FC, useCallback, useContext, useState } from "react";
import { AdminShareTransaction } from "server/services/shareTransaction/shareTransaction.types";
import * as Yup from "yup";

interface Props extends DialogProps {
  shareTransactions: AdminShareTransaction[];
  onClose(): void;
}

const SCHEMA = Yup.object({
  status: Yup.string()
    .nullable()
    .oneOf([null, ...SHARE_TRANSACTION_STATUSES], "Please select one of these statuses, ${values}"),
  settleDate: Yup.date().nullable(),
  tradeDate: Yup.date().nullable(),
  doNotSendEmails: Yup.boolean(),
}).test("atLeastOneUpdate", "Please update at least one of the values above or press cancel", function (this, vs) {
  if (!vs) return false;
  const { doNotSendEmails, ...values } = vs;
  const isValid = Object.values(values).some((value) => Boolean(value));
  return isValid || this.createError({ path: "atLeastOneUpdate", message: "Please update at least one of the values above or press cancel" });
});

const INITIAL_VALUES = {
  status: null,
  settleDate: null,
  tradeDate: null,
  doNotSendEmails: false,
};

const BulkUpdateForm: FC<Props> = ({ open, onClose, shareTransactions }) => {
  const { dispatch } = useContext(AdminContext);
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);

  const handleSubmit = useCallback(
    async (vals) => {
      const values = { ...vals };
      Object.entries(values).forEach(([key, value]) => {
        if (typeof value !== "boolean" && !value) {
          delete values[key];
        }
      });

      setUpdateLoading(true);
      const data = await bulkUpdateShareTransactions(
        values,
        shareTransactions.map((st) => st._id),
      );
      setUpdateLoading(false);

      if (!data) {
        notifications.error("Could not update share transactions!");
        return;
      } else notifications.success("Share transactions updated!");

      dispatch({ type: ADMIN_TYPES.BULK_UPDATE_HOLDINGS, payload: data });
      onClose();
    },
    [dispatch, onClose, shareTransactions],
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle disableTypography>
        <Typography variant="h3">Bulk Update Selected</Typography>
      </DialogTitle>
      <Formik validationSchema={SCHEMA} initialValues={INITIAL_VALUES} onSubmit={handleSubmit}>
        {({ errors }) => (
          <Form data-testid="bulkUpdateForm">
            <DialogContent>
              <Field name="status" component={SelectField} options={SHARE_TRANSACTION_STATUSES} label="Status" placeholder="No update" />
              <FormGroup row>
                <Field component={DatePickerField} name="settleDate" label="Settle Date" placeholder="No update" />
                <Field component={DatePickerField} name="tradeDate" label="Trade Date" placeholder="No update" />
              </FormGroup>
              <FormGroup>
                <FormHelperText error>
                  <strong>{(errors as GenericObject).atLeastOneUpdate}</strong>
                </FormHelperText>
              </FormGroup>
              <FormGroup>
                <Field name="doNotSendEmails" component={CheckboxQuestion} label="Do not send emails to finance or investors?" />
              </FormGroup>
              {updateLoading && (
                <>
                  <br />
                  <Typography variant="caption">
                    Please wait while we update the transactions and create records for S/EIS1 submissions.{" "}
                    <strong>Please also note that this may take a while depending on the number of transactions selected.</strong>
                  </Typography>
                  <br />
                </>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} variant="outlined" size="small">
                Cancel
              </Button>
              <Button type="submit" variant="contained" size="small" disabled={updateLoading}>
                {!updateLoading ? "Submit" : <CircularProgress size={20} />}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default BulkUpdateForm;
