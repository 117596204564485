import { Button, IconButton, makeStyles, Snackbar, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import MicapReview from "client/assets/documents/micap-review.png";
import { ConfigContext } from "client/context/config.context";
import useToggle from "client/hooks/useToggle.hook";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { getAllDocuments } from "../../../api/document.api";
import { TRACKED_EVENTS } from "../../../utils/constants";
import ReviewDocumentCard from "../components/Document.component";
import { useStyles as externalStyles } from "../useStyles";

const { PAGE_VIEWS } = TRACKED_EVENTS;

const useStyles = makeStyles(() => ({
  close: {
    position: "absolute",
    top: 10,
    right: 0,
    marginLeft: 10,
  },
  documentContainer: {
    cursor: "pointer",
    width: 265,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "left",
    margin: "1vw",
    boxSizing: "content-box",
  },
  documentImg: {
    width: 265,
    height: 375,
  },
}));

const Reviews: React.FC = (props) => {
  const classes = externalStyles(props);
  const internalClasses = useStyles(props);
  const { fund } = useContext(ConfigContext);
  const [open, , openSnackbar, closeSnackbar] = useToggle(false);
  const [documentsData, setDocumentsData] = useState<Array<{ _id: string; title: string; versionNumber: string }>>([]);

  useEffect(() => {
    globalThis.dataLayer.push({ event: PAGE_VIEWS.IM_REVIEWS });
  }, []);

  const fetchDocuments = useCallback(async () => {
    const { data } = await getAllDocuments("reviews");
    setDocumentsData(data);
  }, [setDocumentsData]);

  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments]);

  return (
    <>
      <Typography variant="h1" gutterBottom>
        Reviews
      </Typography>
      <div className={classes.documentWrapper}>
        {!documentsData.length && !fund.informationMemorandum.reviews?.hasMICAP && <Typography variant="h3">No reviews available</Typography>}
        {fund.informationMemorandum.reviews?.hasMICAP && (
          /* eslint-disable */
          <div className={internalClasses.documentContainer}>
            <button
              type="button"
              style={{ border: "none", padding: 0, margin: 0, backgroundColor: "transparent" }}
              onClick={openSnackbar}
              tabIndex={0}
              onKeyDown={(event) => event.key === "Enter" && openSnackbar()}
            >
              <img alt="MicapReview" className={internalClasses.documentImg} src={MicapReview} />
            </button>
          </div>
        )}
        {documentsData.map((document) => (
          <ReviewDocumentCard key={document._id} documentId={document._id} fileName={`${document.title}_v${document.versionNumber}.pdf`} />
        ))}
      </div>
      <Snackbar
        open={open}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={10000}
        message="To view this review you will have to create an account on MICAP"
        action={
          <>
            <Button onClick={() => window.open("https://micap.com/")} variant="outlined" color="inherit">
              Go to MICAP
            </Button>
            <IconButton onClick={closeSnackbar} color="inherit" className={internalClasses.close} size="small">
              <CloseIcon />
            </IconButton>
          </>
        }
      />
    </>
  );
};

export default Reviews;
