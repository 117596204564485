import { DialogContentText, Link, Typography } from "@material-ui/core";
import ResponsiveDialog from "./ResponsiveDialog.component";
import { AuthContext } from "../context/auth.context";
import { ConfigContext } from "../context/config.context";
import useToggle from "../hooks/useToggle.hook";
import React, { useContext, useEffect, useRef, useState } from "react";
import RiskWarningPopup from "./RiskWarningPopup.component";

const RiskWarningStatus = {
  READ: "risk warning read",
  ACKNOWLEDGED: "risk warning acknowledged",
};
interface Props {
  isPersistent?: boolean;
}

const InitialRiskWarning: React.FC<Props> = ({ isPersistent = true }) => {
  const [open, , toggleOn, toggleOff] = useToggle(false);
  const riskWarningRef = useRef<() => void>(null);
  const [riskWarningLocalStateKey, setRiskWarningLocalStateKey] = useState<string>("");

  const [stayButtonDisabled, setStayButtonDisable] = useState<boolean>(true);

  const {
    state: { user },
  } = useContext(AuthContext);

  const { fund } = useContext(ConfigContext);

  useEffect(() => {
    const handlePersistency = () => {
      if (user && user.userId) {
        setRiskWarningLocalStateKey(user.userId);

        const riskWarningStatus = localStorage.getItem(riskWarningLocalStateKey);
        if (riskWarningStatus) {
          if (riskWarningStatus === RiskWarningStatus.READ) {
            toggleOn();
            setStayButtonDisable(false);
          } else if (riskWarningStatus === RiskWarningStatus.ACKNOWLEDGED) {
            toggleOff();
          }
        } else {
          toggleOn();
        }
      }
    };

    if (isPersistent) {
      handlePersistency();
    } else {
      toggleOn();
    }
  }, [isPersistent, riskWarningLocalStateKey, toggleOff, toggleOn, user]);

  const redirectToLandingPage = () => {
    window.location.href = fund.links.alternateSite;
  };

  const onWarningRead = () => {
    if (user?.userId && isPersistent) {
      localStorage.setItem(riskWarningLocalStateKey, RiskWarningStatus.READ);
    }
    setStayButtonDisable(false);
  };
  const handleStayButtonClicked = () => {
    if (user?.userId && isPersistent) {
      localStorage.setItem(riskWarningLocalStateKey, RiskWarningStatus.ACKNOWLEDGED);
    }
    toggleOff();
  };
  return (
    <RiskWarningPopup ref={riskWarningRef} onWarningRead={onWarningRead}>
      <ResponsiveDialog
        open={open}
        onClose={() => {}}
        actionButtons={[
          {
            label: "Stay",
            disabled: stayButtonDisabled,
            tooltip: stayButtonDisabled ? "Please select the “Take 2 mins to learn more” link above before continuing" : "",
            onClick: handleStayButtonClicked,
            attributes: { "data-testid": "stay" },
          },
          { label: "Leave", onClick: redirectToLandingPage },
        ]}
      >
        <DialogContentText component="div" align="justify" style={{ color: "inherit" }}>
          <Typography paragraph>
            {`${user?.forenames} ${user?.surname}`}, this is a high-risk investment. How would you feel if you lost the money you’re about to invest?{" "}
            <Link
              onClick={() => {
                riskWarningRef.current?.();
              }}
              data-testid="take-2-mins-to-learn"
            >
              Take 2 mins to learn more
            </Link>
          </Typography>
        </DialogContentText>
      </ResponsiveDialog>
    </RiskWarningPopup>
  );
};

export default InitialRiskWarning;
