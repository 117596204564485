import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getMainDefinition } from "@apollo/client/utilities";
import { Kind, OperationTypeNode } from "graphql";
import { getToken } from "./auth";
import WebSocketLink from "./WebSocketLink";

const isSSR = () => typeof window === "undefined";

const getHeaders = (token?: string) => ({ Authorization: `Bearer ${token || getToken()}` });

const authLink = setContext(() => ({
  headers: getHeaders(),
}));

const httpLink = () => authLink.concat(new HttpLink({ uri: window.__CONFIG__.GQL_ENDPOINT }));

const wsLink = (token?: string) =>
  authLink.concat(
    new WebSocketLink({
      url: globalThis.__CONFIG__.GQL_ENDPOINT_WS,
      webSocketImpl: isSSR() ? require("ws") : undefined,
      connectionParams: () => ({ ...getHeaders(token), Origin: globalThis.__CONFIG__.fund.origin }),
    }),
  );

const createApolloClient = (token?: string) => {
  const link = isSSR()
    ? wsLink(token!)
    : ApolloLink.split(
        (operation) => {
          const definition = getMainDefinition(operation.query);
          return definition.kind === Kind.OPERATION_DEFINITION && definition.operation === OperationTypeNode.SUBSCRIPTION;
        },
        wsLink(),
        httpLink(),
      );

  return new ApolloClient({
    link,
    ssrMode: isSSR(),
    connectToDevTools: !isSSR() && !globalThis.__CONFIG__.IS_PRODUCTION,
    cache: isSSR() ? new InMemoryCache() : new InMemoryCache().restore(globalThis.__INITIAL_STATE__.apollo),
  });
};

export default createApolloClient;
