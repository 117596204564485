import { createTheme, ThemeOptions } from "@material-ui/core/styles";
import RadioIcon from "@material-ui/icons/Brightness1";
import CheckIcon from "@material-ui/icons/Check";
import CenterLineIcon from "@material-ui/icons/Remove";
import Color from "color";

const theme = createTheme({
  palette: {
    primary: {
      light: "#FFE15E",
      main: "#FFAF28",
      dark: "#C78000",
    },
    secondary: {
      light: "#2C2C2C",
      main: "#000000",
      dark: "#000000",
    },
    white: {
      light: "#FFFFFF",
      main: "#FFFFFF",
      dark: "#CCCCCC",
    },
    emerald: {
      light: "#5BCABB",
      main: "#1B998B",
      dark: "#006A5E",
    },
    pk: {
      light: "#3F6A52",
      main: "#143F29",
      dark: "#001A00",
    },
    stone: {
      light: "#B7B4A1",
      main: "#878472",
      dark: "#5A5747",
    },
    violet: {
      light: "#59517A",
      main: "#2E294E",
      dark: "#080026",
    },
    action: {
      hover: "rgba(60, 60, 59, 0.2)",
    },
    error: {
      light: "#FF6168",
      main: "#D7263D",
      dark: "#9E0017",
    },
    grey: {
      light: "#FAFAFA",
      main: "#F3F3F3",
      dark: "#DADADA",
      darker: "#3C3C3B",
      border: "#D9D9D9",
    },
    background: {
      default: "#FFFFFF",
    },
    bullet: {
      main: "#83DFC8",
    },
    text: {
      primary: "#000000",
      secondary: "#FFFFFF",
      link: "#1B998B",
      hint: "#878472",
      usedLink: "#A44C97",
      disabled: "rgba(60, 60, 59, 0.3)",
      hover: "rgba(60, 60, 59, 0.1)",
    },
    info: {
      main: "#878472",
    },
    common: {
      transparent: "rgba(0, 0, 0, 0)",
    },
  },
  typography: {
    fontFamily: "Montserrat",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightSemiBold: 600,
    fontWeightBold: 700,
    fontWeightBlack: 800,
  },
  breakpoints: {
    values: {
      xs: 350, // mobile - small
      sm: 640, // mobile - large / tablet - small
      md: 768, // tablet
      lg: 1024, // laptop
      xl: 1232, // laptop - medium
    },
  },
  props: {
    MuiAlert: {
      variant: "filled",
    },
    MuiButton: {
      variant: "contained",
      size: "medium",
      disableElevation: true,
    },
    MuiButtonGroup: {
      variant: "contained",
      color: "primary",
      size: "medium",
    },
    MuiCheckbox: {
      disableRipple: true,
      checkedIcon: <CheckIcon />,
      indeterminateIcon: <CenterLineIcon />,
    },
    MuiContainer: {
      maxWidth: "xl",
    },
    MuiKeyboardDatePicker: {
      format: "DD/MM/YYYY",
      placeholder: "DD/MM/YYYY",
      variant: "inline",
      autoOk: true,
      invalidDateMessage: "",
      minDateMessage: "",
      maxDateMessage: "",
    },
    MuiLink: {
      underline: "always",
      color: "initial",
    },
    MuiPaper: {
      square: true,
    },
    MuiRadio: {
      checkedIcon: <RadioIcon />,
    },
    MuiSelect: {
      variant: "outlined",
    },
    MuiTextField: {
      variant: "outlined",
      fullWidth: true,
    },
    MuiTableCell: {
      align: "center",
    },
    MuiTabs: {
      variant: "scrollable",
      indicatorColor: "secondary",
    },
    MuiTooltip: {
      arrow: true,
    },
    MuiTypography: {
      color: "textPrimary",
    },
  },
});
export default function mergeThemes(fundTheme?: ThemeOptions) {
  const updatedTheme = createTheme(theme, fundTheme ?? {});
  updatedTheme.typography.h1 = {
    fontSize: "2.625rem", // 42px
    fontWeight: updatedTheme.typography.fontWeightBold,
    lineHeight: 1.43,
    letterSpacing: "0.10em",
    [updatedTheme.breakpoints.down("md")]: {
      fontSize: "2rem",
    },
  };
  updatedTheme.typography.h2 = {
    fontSize: "2.25rem", // 36px
    fontWeight: updatedTheme.typography.fontWeightBold,
    lineHeight: 1.33,
    letterSpacing: "0.075em",
    [updatedTheme.breakpoints.down("md")]: {
      fontSize: "1.375rem",
    },
  };
  updatedTheme.typography.h3 = {
    fontSize: "1.5rem", // 24px
    fontWeight: updatedTheme.typography.fontWeightBold,
    lineHeight: 1.25,
    letterSpacing: "0.075em",
    [updatedTheme.breakpoints.down("md")]: {
      fontSize: "0.875rem",
    },
  };
  updatedTheme.typography.h4 = {
    fontSize: "1.25rem", // 20px
    fontWeight: updatedTheme.typography.fontWeightMedium,
    lineHeight: 1.5,
    letterSpacing: "0.05em",
    [updatedTheme.breakpoints.down("md")]: {
      fontSize: "1rem",
    },
  };
  updatedTheme.typography.h5 = {
    fontSize: "0.875rem", // 14px
    fontWeight: updatedTheme.typography.fontWeightSemiBold,
    lineHeight: 1.71,
    letterSpacing: "0.05em",
    [updatedTheme.breakpoints.down("md")]: {
      fontSize: "0.75rem",
    },
  };
  updatedTheme.typography.h6 = {
    fontSize: "0.75rem", // 12px
    fontWeight: updatedTheme.typography.fontWeightMedium,
    lineHeight: 1.18,
    letterSpacing: "0.02em",
    [updatedTheme.breakpoints.down("md")]: {
      fontSize: "0.5625rem",
    },
  };
  updatedTheme.typography.body1 = {
    fontSize: "1.25rem", // 20px
    fontWeight: updatedTheme.typography.fontWeightMedium,
    lineHeight: 1.5,
    letterSpacing: "0.05em",
    [updatedTheme.breakpoints.down("md")]: {
      fontSize: "0.875rem",
    },
  };
  updatedTheme.typography.body2 = {
    fontSize: "1rem", // 16px
    fontWeight: updatedTheme.typography.fontWeightMedium,
    lineHeight: 1.5,
    letterSpacing: "0.05em",
    [updatedTheme.breakpoints.down("md")]: {
      fontSize: "0.75rem",
    },
  };
  updatedTheme.typography.body3 = {
    fontSize: "0.75rem", // 12px
    fontWeight: updatedTheme.typography.fontWeightMedium,
    lineHeight: 1.5,
    letterSpacing: "0.02em",
    [updatedTheme.breakpoints.down("md")]: {
      fontSize: "0.625rem",
    },
  };
  updatedTheme.typography.caption = {
    ...updatedTheme.typography.body3,
  };

  updatedTheme.overrides = {
    MuiCssBaseline: {
      "@global": {
        "*": {
          fontFamily: "Montserrat",
        },
        body: {
          "--primary-main": updatedTheme.palette.primary.main,
          "--primary-contrast-text": updatedTheme.palette.primary.contrastText,
          "--secondary-light": updatedTheme.palette.secondary.light,
          "--secondary-main": updatedTheme.palette.secondary.main,
          "--secondary-contrast-text": updatedTheme.palette.secondary.contrastText,
          "--action-hover": updatedTheme.palette.action.hover,
        },
        hr: {
          width: "100%",
          borderWidth: 0,
          borderBottomWidth: 1,
          borderStyle: "solid",
          borderColor: updatedTheme.palette.grey.border,
        },
      },
    },
    MuiAlert: {
      root: {
        maxWidth: updatedTheme.breakpoints.values.lg,
      },
    },
    MuiButton: {
      root: {
        flex: 1,
        borderRadius: 0,
        padding: updatedTheme.spacing(2, 3.75),
      },
      label: {
        ...updatedTheme.typography.h5,
        fontWeight: updatedTheme.typography.fontWeightMedium,
      },
      contained: {
        backgroundColor: updatedTheme.palette.secondary.main,
        color: updatedTheme.palette.secondary.contrastText,
      },
      containedSizeLarge: {
        padding: updatedTheme.spacing(0, 6.25),
        minHeight: 72,
        flex: "unset",
        "& .MuiButton-label": {
          ...updatedTheme.typography.h3,
        },
        [updatedTheme.breakpoints.down("md")]: {
          padding: updatedTheme.spacing(0, 4.75),
          minHeight: 56,
        },
      },
      containedSizeSmall: {
        padding: updatedTheme.spacing(0, 3.75),
        minHeight: 48,
        flex: "unset",
        "& .MuiButton-label": {
          ...updatedTheme.typography.body2,
          fontWeight: updatedTheme.typography.fontWeightMedium,
        },
        [updatedTheme.breakpoints.down("md")]: {
          padding: updatedTheme.spacing(0, 2.85),
          minHeight: 36,
        },
      },
      outlined: {
        border: `3px solid ${updatedTheme.palette.secondary.main}`,
      },
      outlinedSizeLarge: {
        padding: updatedTheme.spacing(0, 6.25),
        minHeight: 72,
        flex: "unset",
        "& .MuiButton-label": {
          ...updatedTheme.typography.h3,
        },
        [updatedTheme.breakpoints.down("md")]: {
          padding: updatedTheme.spacing(0, 4.75),
          minHeight: 56,
        },
      },
      outlinedSizeSmall: {
        padding: updatedTheme.spacing(0, 3.75),
        minHeight: 48,
        flex: "unset",
        "& .MuiButton-label": {
          ...updatedTheme.typography.body2,
          fontWeight: updatedTheme.typography.fontWeightMedium,
        },
      },
      text: {
        ...updatedTheme.typography.body1,
        fontWeight: updatedTheme.typography.fontWeightSemiBold,
        textTransform: "none",
        minWidth: "unset",
      },
    },
    MuiButtonGroup: {
      grouped: {
        flex: "unset",
        minHeight: 40,
        "&:not(:first-child)": {
          padding: 0,
        },
      },
    },
    MuiCard: {
      root: {
        padding: updatedTheme.spacing(5),
        [updatedTheme.breakpoints.down("sm")]: {
          padding: updatedTheme.spacing(3),
        },
      },
    },
    MuiCardActions: {
      root: {
        padding: updatedTheme.spacing(2),
      },
    },
    MuiCheckbox: {
      root: {
        "& .MuiIconButton-label": {
          width: "1.375rem",
          height: "1.375rem",
          border: `2px solid ${updatedTheme.palette.secondary.main}`,
          borderRadius: 0,
          "&  > .MuiSvgIcon-root": {
            display: "none",
          },
        },
        "&$checked": {
          "& .MuiIconButton-label": {
            background: updatedTheme.palette.emerald.main,
            borderColor: updatedTheme.palette.emerald.main,
            "& > .MuiSvgIcon-root": {
              display: "unset",
              color: updatedTheme.palette.text.secondary,
            },
          },
        },
      },
    },
    MuiContainer: {
      root: {
        paddingTop: updatedTheme.spacing(5),
        paddingBottom: updatedTheme.spacing(5),
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: 0,
      },
    },
    MuiFormControl: {
      root: {
        "& > div > div.MuiFormGroup-root": {
          margin: 0,
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        ...updatedTheme.typography.h5,
        marginLeft: updatedTheme.spacing(1.375),
      },
    },
    MuiFormGroup: {
      root: {
        marginTop: updatedTheme.spacing(3),
        [updatedTheme.breakpoints.down("md")]: {
          marginTop: updatedTheme.spacing(2),
        },
      },
      row: {
        [updatedTheme.breakpoints.up("lg")]: {
          justifyContent: "center",
          "& > *": {
            flex: 1,
            "&:not(:last-child)": {
              marginRight: updatedTheme.spacing(2.5),
            },
          },
        },
        [updatedTheme.breakpoints.down("md")]: {
          flexDirection: "column",
          "& > *:not(:last-child)": {
            marginBottom: updatedTheme.spacing(2.5),
          },
        },
      },
    },
    MuiFormHelperText: {
      root: {
        color: updatedTheme.palette.text.hint,
      },
    },
    MuiFormLabel: {
      root: {
        color: updatedTheme.palette.text.main,
        lineHeight: "unset",
      },
    },
    MuiGrid: {
      item: {
        display: "flex",
      },
    },
    MuiInputBase: {
      input: {
        letterSpacing: 1,
        "&::placeholder": {
          opacity: 1,
          color: updatedTheme.palette.text.hint,
          ...updatedTheme.typography.body2,
          fontSize: "inherit",
        },
        "&:-ms-input-placeholder": {
          opacity: 1,
          color: updatedTheme.palette.text.hint,
          ...updatedTheme.typography.body2,
          fontSize: "inherit",
        },
        "&::-ms-input-placeholder": {
          opacity: 1,
          color: updatedTheme.palette.text.hint,
          ...updatedTheme.typography.body2,
          fontSize: "inherit",
        },
      },
    },
    MuiLink: {
      root: {
        cursor: "pointer",
        "&:not(.MuiTypography-colorPrimary):not(.MuiTypography-colorSecondary):not(.MuiTypography-colorError):not(.MuiTypography-colorTextPrimary):not(.MuiTypography-colorTextSecondary):not(.MuiTypography-colorInherit)":
          {
            color: updatedTheme.palette.text.link,
          },
      },
      underlineAlways: {
        textDecoration: "underline !important",
        fontWeight: updatedTheme.typography.fontWeightBold,
      },
    },
    MuiListItemText: {
      secondary: {
        color: updatedTheme.palette.text.hint,
      },
    },
    MuiOutlinedInput: {
      root: {
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderWidth: 4,
        },
      },
      input: {
        padding: updatedTheme.spacing(2.3125, 3),
      },
      notchedOutline: {
        borderRadius: 0,
        borderWidth: 4,
        borderColor: updatedTheme.palette.secondary.main,
      },
    },
    MuiPaper: {
      root: {
        borderRadius: 0,
      },
      outlined: {
        border: `12px solid ${updatedTheme.palette.secondary.main}`,
      },
    },
    MuiPickersDay: {
      daySelected: {
        color: updatedTheme.palette.text.secondary,
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: updatedTheme.palette.text.secondary,
        opacity: 0.88,
      },
      toolbarBtnSelected: {
        color: updatedTheme.palette.text.secondary,
        opacity: 1,
      },
    },
    MuiRadio: {
      root: {
        padding: updatedTheme.spacing(1.25),
        alignSelf: "flex-start",
        "&:hover": {
          backgroundColor: Color(updatedTheme.palette.emerald.main).fade(0.9).toString(),
        },
        "& .MuiIconButton-label": {
          background: "unset",
        },
        "& .MuiSvgIcon-root": {
          fontSize: "2.25rem",
          fill: updatedTheme.palette.secondary.main,
        },
        "&.Mui-checked .MuiSvgIcon-root": {
          fill: updatedTheme.palette.emerald.main,
        },
        "& .MuiTouchRipple-root": {
          color: updatedTheme.palette.emerald.main,
        },
      },
    },
    MuiStepContent: {
      root: {
        borderLeft: `4px solid ${updatedTheme.palette.primary.main}`,
        paddingBottom: 12,
      },
    },
    MuiStepConnector: {
      lineVertical: {
        borderLeftStyle: "solid",
        borderLeftWidth: 4,
        borderLeftColor: updatedTheme.palette.primary.main,
      },
    },
    MuiStepIcon: {
      root: {
        width: 30,
        height: 30,
      },
      text: {
        fill: updatedTheme.palette.text.secondary,
      },
    },
    MuiStepLabel: {
      labelContainer: {
        marginLeft: 20,
      },
    },
    MuiTab: {
      root: {
        ...updatedTheme.typography.caption,
        fontWeight: updatedTheme.typography.fontWeightRegular,
        maxWidth: "unset",
        paddingLeft: 0,
        paddingRight: 0,
        flexShrink: "unset",
        "&:not(:last-child)": {
          marginRight: updatedTheme.spacing(2),
        },
        "&$selected": {
          fontWeight: updatedTheme.typography.fontWeightBold,
        },
      },
      textColorInherit: {
        opacity: 1,
      },
    },
    MuiTable: {
      root: {
        "& th:not(:last-child)": {
          borderRight: `1px solid ${updatedTheme.palette.divider}`,
          borderLeft: `1px solid ${updatedTheme.palette.divider}`,
        },
        tableLayout: "auto",
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: `1px solid ${updatedTheme.palette.divider}`,
      },
      sizeSmall: {
        padding: updatedTheme.spacing(0.75, 2),
      },
      footer: {
        fontWeight: updatedTheme.typography.fontWeightBold,
        color: updatedTheme.palette.text.primary,
      },
    },
    MuiTablePagination: {
      root: {
        borderBottom: `1px solid ${updatedTheme.palette.divider}`,
        overflow: "unset",
      },
      toolbar: {
        overflow: "auto",
      },
    },
    MuiTableSortLabel: {
      root: {
        "&:hover, &:focus": {
          color: updatedTheme.palette.text.primary,
        },
        "&$active": {
          color: updatedTheme.palette.text.primary,
          // && instead of & is a workaround for https://github.com/cssinjs/jss/issues/1045
          "&& $icon": {
            color: "inherit",
          },
        },
      },
      icon: {
        opacity: "0.2",
      },
    },
    MuiTableRow: {
      hover: {
        cursor: "pointer",
      },
    },
    MuiTableFooter: {
      root: {
        borderTop: `2px solid ${updatedTheme.palette.divider}`,
      },
    },
    MuiTabs: {
      root: {
        width: "100%",
        borderTop: `1px solid ${updatedTheme.palette.secondary.main}`,
        borderBottom: `1px solid ${updatedTheme.palette.secondary.main}`,
        backgroundColor: updatedTheme.palette.grey.main,
        justifyContent: "center",
      },
      flexContainer: {
        justifyContent: "space-between",
      },
      scrollButtons: {
        "&:first-child": {
          marginLeft: "auto",
        },
        "&:last-child": {
          marginRight: "auto",
        },
      },
      scroller: {
        width: "100%",
        maxWidth: updatedTheme.breakpoints.values.xl,
        paddingLeft: updatedTheme.spacing(3),
        paddingRight: updatedTheme.spacing(3),
      },
      indicator: {
        height: 4, // * For above
      },
    },
    MuiTextField: {
      root: {
        "&:hover": {
          borderColor: updatedTheme.palette.common.black,
        },
      },
    },
  };
  return createTheme(updatedTheme);
}
