import { createStyles, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React, { FC } from "react";

interface Props {
  growth: number;
  className?: string;
}

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      growthIndicator: {
        height: 0,
        width: 0,
        fontSize: "inherit",
        borderStyle: "solid",
        borderWidth: "0px 0.375em 0.63em 0.375em",
        borderColor: "transparent",
        borderBottomColor: theme.palette.action.disabled,
        marginRight: "0.5em",
        alignSelf: "center",
        transform: "rotate(0deg)",
        transition: theme.transitions.create("transform", {
          duration: theme.transitions.duration.shortest,
          easing: theme.transitions.easing.easeInOut,
        }),
      },
      growthIndicatorNegative: {
        borderBottomColor: theme.palette.error.main,
        transform: "rotate(180deg)",
      },
      growthIndicatorPositive: {
        borderBottomColor: theme.palette.emerald.main,
      },
    }),
  { name: "ChangeHistoryIconComponent" },
);

const ChangeHistoryIcon: FC<Props> = ({ growth, className, ...props }) => {
  const classes = useStyles(props);

  return (
    <div
      {...props}
      className={clsx(classes.growthIndicator, className, {
        [classes.growthIndicatorNegative]: growth < 0,
        [classes.growthIndicatorPositive]: growth > 0,
      })}
    />
  );
};

export default ChangeHistoryIcon;
