import { InputAdornment, StandardTextFieldProps, TextField, Typography } from "@material-ui/core";
import clsx from "clsx";
import { ErrorMessage, FieldProps } from "formik";
import _ from "lodash";
import React, { ChangeEvent, ChangeEventHandler, useCallback, useEffect, useMemo, useState } from "react";
import NumberFormat, { NumberFormatProps } from "react-number-format";
import { fromCamelCase } from "../../utils/helpers";
import QuestionLabel from "../QuestionLabel.component";
import TrackInput from "../TrackInput.component";
import ErrorText from "../UIKit/ErrorText.component";

type Props<Monetary extends boolean = false> = (Monetary extends true ? Partial<NumberFormatProps> : Partial<StandardTextFieldProps>) &
  FieldProps & {
    inputLabel?: string;
    trackedCategory?: string;
    suffix?: string;
    min?: number;
    max?: number;
    moneyFormat?: boolean;
    step?: number;
    onChange?: (e: ChangeEvent<HTMLInputElement>, value: number | "") => any;
    debounce?: number;
  };

const NumberQuestion: React.FC<Props> = <Monetary extends boolean>({
  field,
  form: { touched, errors, setFieldValue },
  label,
  inputLabel,
  trackedCategory,
  prefix,
  suffix,
  min,
  max,
  moneyFormat = false,
  step,
  onChange,
  debounce = 0,
  ...props
}: Props<Monetary>) => {
  const [value, setValue] = useState<number | "">(props.value ?? field.value);

  const keyPressHandler = useCallback((event) => {
    if (!String.fromCharCode(event.which).match(/[\d.]/)) {
      event.preventDefault();
    }
  }, []);

  const debouncedChangeHandler = useMemo(
    () =>
      _.debounce<(e: ChangeEvent<HTMLInputElement>, v: number | "") => void>((e, v) => {
        if (onChange) {
          onChange(e, v);
        } else {
          setFieldValue(field.name, v);
        }
      }, debounce),
    [debounce, field.name, onChange, setFieldValue],
  );

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      const num = parseFloat(e.target.value.replace(/,/g, ""));
      const updatedValue = isNaN(num) ? "" : num;
      setValue(updatedValue);
      debouncedChangeHandler(e, updatedValue);
    },
    [debouncedChangeHandler],
  );

  useEffect(() => {
    setValue(props.value ?? field.value);
  }, [field.value, props.value]);

  useEffect(() => () => debouncedChangeHandler.cancel(), [debouncedChangeHandler]);

  if (moneyFormat) {
    return (
      <TrackInput category={trackedCategory} label={fromCamelCase(field.name)} value={field.value}>
        {label && <QuestionLabel>{label}</QuestionLabel>}
        <NumberFormat
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Typography color="textPrimary">£</Typography>
              </InputAdornment>
            ),
          }}
          customInput={TextField}
          label={inputLabel}
          thousandSeparator={true}
          decimalSeparator="."
          error={Boolean(_.get(touched, field.name) && _.get(errors, field.name))}
          decimalScale={2}
          fixedDecimalScale
          {...field}
          {...(props as Partial<NumberFormatProps>)}
          value={value}
          className={clsx(props.className, "fs-exclude")}
          onChange={handleChange}
        />
        <ErrorMessage name={field.name} component={ErrorText} />
      </TrackInput>
    );
  } else {
    return (
      <TrackInput category={trackedCategory} label={fromCamelCase(field.name)} value={field.value}>
        {label && <QuestionLabel>{label}</QuestionLabel>}
        <TextField
          type="number"
          label={inputLabel}
          onKeyPress={keyPressHandler}
          error={Boolean(_.get(touched, field.name) && _.get(errors, field.name))}
          InputProps={{
            startAdornment: prefix && (
              <InputAdornment position="start">
                <Typography color="textPrimary">{prefix}</Typography>
              </InputAdornment>
            ),
            endAdornment: suffix && (
              <InputAdornment position="end">
                <Typography color="textPrimary">{suffix}</Typography>
              </InputAdornment>
            ),
            inputProps: { min, max, step },
          }}
          {...field}
          {...(props as Partial<StandardTextFieldProps>)}
          value={value}
          className={clsx(props.className, "fs-exclude")}
          onChange={handleChange}
        />
        <ErrorMessage name={field.name} component={ErrorText} />
      </TrackInput>
    );
  }
};

export default NumberQuestion;
