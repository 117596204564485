import fileDownload from "js-file-download";
import notifications from "../../utils/notifications";
import { ipAxios } from "../ipAxios";

export const downloadTaxCertificate = async <D extends true | false | undefined>(holdingId: string, disableDownload?: D) => {
  try {
    const res = await ipAxios.get<D extends true ? ArrayBuffer : Blob>(`/admin/holdings/${holdingId}/certificate`, {
      responseType: disableDownload ? "arraybuffer" : "blob",
    });
    if (!disableDownload) fileDownload(res.data, `certificate-${holdingId}.pdf`);
    return res.data;
  } catch (error: any) {
    notifications.genericError(error);
    return null;
  }
};

export const getAllHoldings = async (onChangeObject: GenericObject) => {
  try {
    const res = await ipAxios.get(`/admin/holdings/`, { params: onChangeObject });
    return { data: res.data, count: res.headers["x-count"] };
  } catch (error: any) {
    if (error.message !== "duplicate request cancelled") {
      notifications.error(error.message, error);
    }
    return null;
  }
};

export const getSheetsExportLink = async () => {
  try {
    const res = await ipAxios.get("/admin/holdings/export/link");
    return res.data;
  } catch (error: any) {
    notifications.genericError(error);
    return null;
  }
};
