import { Container } from "@material-ui/core";
import React from "react";
import TheTeam from "../../../components/TheTeam.component";
import SectionLetterTypography from "../components/SectionLetterTypography.component";

const SectionD: React.FC = () => (
  <Container>
    <SectionLetterTypography letter="D" orangeFullstop>
      The Team
    </SectionLetterTypography>
    <TheTeam />
  </Container>
);

export default SectionD;
