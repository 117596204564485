import { Avatar, createStyles, Link, makeStyles, Paper, PaperProps, Tooltip, Typography } from "@material-ui/core";
import Divider from "client/components/Divider.component";
import { formatThousands } from "client/utils/helpers";
import clsx from "clsx";
import moment from "moment";
import { MouseEvent, useCallback, useMemo } from "react";
import { CompanyWithHoldings } from "server/services/company/company.types";
import { FormattedHolding } from "server/services/shareTransaction/shareTransaction.types";
import ChangeHistoryIcon from "./ChangeHistoryIcon.component";
import SectorDetails from "./SectorDetails.component";
import { ReactComponent as SlashCircleSVG } from "client/assets/slash_circle.svg"; // Importing the SVG file as a React component
import DissolvedWarning from "client/components/DissolvedWarning.component";

interface Props<T extends CompanyWithHoldings | FormattedHolding> extends Omit<PaperProps, "onClick"> {
  data: T;
  onClick?: (data: T) => any;
  onDocumentsClick?: (data: T) => any;
}

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      container: {
        position: "relative",
        padding: theme.spacing(2.5),
        paddingBottom: theme.spacing(1.5),
        width: "100%",
        "&:hover": {
          boxShadow: theme.shadows[7],
        },
      },
      clickable: {
        "&:hover": {
          cursor: "pointer",
        },
      },
      dataDetails: {
        display: "flex",
        alignItems: "center",
        overflow: "hidden",
        width: "100%",
        "& > *": {
          marginRight: theme.spacing(2.5),
        },
      },
      companyDetails: {
        overflow: "hidden",
        width: "60%",
        textTransform: "uppercase",
      },
      companyLogo: {
        width: theme.spacing(7.5),
        height: theme.spacing(7.5),
        [theme.breakpoints.down("sm")]: {
          width: theme.spacing(6),
          height: theme.spacing(6),
        },
      },
      notificationDetails: {
        display: "flex",
      },

      dissolvedCompanyLogoOverlay: {
        position: "absolute",
        top: 0,
        left: 0,
        backgroundColor: "rgba(255, 255, 255, 0.5)",
      },
      costValue: {
        display: "flex",
        "& > *": {
          flex: 1,
          "&:first-child": {
            paddingRight: theme.spacing(1.5),
          },
          "&:last-child": {
            paddingLeft: theme.spacing(1.5),
          },
        },
      },
      dealStage: {
        position: "absolute",
        right: 0,
        padding: theme.spacing(0.375, 1.25),
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.primary,
      },
      dealStageEIS: {
        backgroundColor: theme.palette.emerald.main,
        color: theme.palette.text.secondary,
      },
      growth: {
        display: "flex",
        alignItems: "flex-end",
        flexFlow: "row wrap",
      },
      growthPositive: {
        color: theme.palette.emerald.main,
      },
      growthNegative: {
        color: theme.palette.error.main,
      },
      header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        marginBottom: theme.spacing(3.75),
      },
      newDocument: {
        boxShadow: `5px 5px 0 ${theme.palette.emerald.main}, ${theme.shadows[5]}`,
        "&:hover": {
          boxShadow: `7px 7px 0 ${theme.palette.emerald.main}, ${theme.shadows[7]}`,
        },
      },
      noHoldings: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: theme.palette.secondary.main,
        width: theme.spacing(2.5),
        height: theme.spacing(2.5),
        borderRadius: "50%",
        lineHeight: 0,
        letterSpacing: 0,
      },
      noHoldingsMany: {
        boxShadow: [
          `0px 1px ${theme.palette.common.white}`,
          `0px 3px ${theme.palette.secondary.main}`,
          `0px 4px ${theme.palette.common.white}`,
          `0px 6px ${theme.palette.secondary.main}`,
        ].join(),
      },
      noHoldingsTwo: {
        boxShadow: [`0px 1px ${theme.palette.common.white}`, `0px 3px ${theme.palette.secondary.main}`].join(),
      },
      value: {
        display: "flex",
        flexFlow: "row wrap",
        justifyContent: "space-between",
      },
      docsSector: {
        display: "flex",
        flexFlow: "row wrap",
        justifyContent: "space-between",
      },
      noSharePrice: {
        visibility: "hidden",
      },
    }),
  { name: "CompanyCardComponent" },
);

const CompanyHoldingCard = <T extends CompanyWithHoldings | FormattedHolding>({ data, onClick, onDocumentsClick, className, ...props }: Props<T>) => {
  const classes = useStyles(props);
  const isASA = data.transactionType === "ASA";

  const getNoOfSharesText = (holding: FormattedHolding): string => {
    if (!holding.noOfShares || !holding.pricePerShare) return `ASA`;
    return `${formatThousands(holding.noOfShares, 0, false)} units @ ${formatThousands(holding.pricePerShare, 2)} each`;
  };

  const documentsText = useMemo(() => {
    if (isASA) return "";
    if (!data.noDocuments) return "no documents";
    if (data.hasNewDocument) return "New document!";
    return `${data.noDocuments} document${data.noDocuments > 1 ? "s" : ""}`;
  }, [isASA, data.hasNewDocument, data.noDocuments]);

  const handleClick = useCallback(() => {
    if (onClick) onClick(data);
  }, [data, onClick]);

  const handleDocumentsClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      if (!onDocumentsClick) return;
      onDocumentsClick(data);
    },
    [data, onDocumentsClick],
  );

  const renderCostValueSection = (holding: CompanyWithHoldings | FormattedHolding) => {
    return (
      <>
        <div className={classes.costValue}>
          <div>
            {holding.type && !isASA && (
              <Typography variant="body2" gutterBottom>
                {getNoOfSharesText(holding)}
              </Typography>
            )}
            <Typography variant="body2">
              <strong>{formatThousands(holding.totalCost, 2)}</strong>
            </Typography>
          </div>
          <div>
            {holding.type && !isASA && (
              <Typography variant="body2" gutterBottom className={holding.noOfShares ? "" : classes.noSharePrice}>
                {formatThousands(holding.currentPricePerShare, 2)} each
              </Typography>
            )}
            <div className={classes.value}>
              <Typography variant="body2">
                <strong>{formatThousands(holding.totalValue, 2)}</strong>
              </Typography>
              <Typography
                variant="h5"
                className={clsx(classes.growth, {
                  [classes.growthPositive]: holding.growth > 0,
                  [classes.growthNegative]: holding.growth < 0,
                })}
              >
                <ChangeHistoryIcon growth={holding.growth} />
                <strong>{formatThousands(holding.growth, 2)}</strong>
                &nbsp;({holding.growth && holding.totalCost ? Math.round((holding.growth / holding.totalCost) * 100 * 100) / 100 : 0}%)
              </Typography>
            </div>
          </div>
        </div>
        <br />
      </>
    );
  };

  return (
    <Paper
      elevation={4}
      {...props}
      className={clsx(classes.container, className, {
        [classes.newDocument]: data.hasNewDocument,
        [classes.clickable]: Boolean(onClick),
      })}
      onClick={handleClick}
      data-testid={!data.type && data.companyName}
    >
      <div className={classes.header}>
        <div className={classes.dataDetails}>
          {!data.type && (
            <>
              {data.companyLogo && (
                <div style={{ position: "relative" }}>
                  <Avatar src={data.companyLogo!} alt={`${data.projectName} Logo`} className={classes.companyLogo} />
                  {data.isDissolved && <SlashCircleSVG className={classes.dissolvedCompanyLogoOverlay} />}
                </div>
              )}
              <div className={classes.companyDetails}>
                <Tooltip title={data.projectName.length > 25 ? data.projectName : ""}>
                  <Typography variant="h4" noWrap gutterBottom>
                    <strong>{data.projectName || data.companyName}</strong>
                  </Typography>
                </Tooltip>
                <Typography variant="h6">{data.projectName && data.companyName}</Typography>
              </div>
            </>
          )}
          {data.type && (
            <Typography variant="h4">
              <strong>{moment(data.settleDate).format("L")}</strong>
            </Typography>
          )}
        </div>
        <div className={classes.notificationDetails}>
          {!data.type && data.isDissolved && <DissolvedWarning companyName={data.companyName || data.projectName} />}
          {!data.type ? (
            <Typography
              variant="h5"
              align="center"
              color="textSecondary"
              component="div"
              className={clsx(classes.noHoldings, {
                [classes.noHoldingsTwo]: data.noHoldings === 2,
                [classes.noHoldingsMany]: data.noHoldings > 2,
              })}
            >
              {data.noHoldings}
            </Typography>
          ) : (
            <Typography
              variant="body2"
              align="center"
              component="div"
              className={clsx(classes.dealStage, {
                [classes.dealStageEIS]: data.dealStage === "EIS",
              })}
            >
              {data.dealStage}
            </Typography>
          )}
        </div>
      </div>

      <div>
        {isASA && data.longstopDate && (
          <Typography>
            <p>Advanced Subscription Agreement with a targeted conversion of {moment(data.longstopDate).format("L")}.</p>
          </Typography>
        )}
      </div>

      <div className={classes.costValue}>
        <div>
          <Typography variant="h5">COST</Typography>
          <Divider />
        </div>
        <div>
          <Typography variant="h5">VALUE</Typography>
          <Divider />
        </div>
      </div>
      <br />
      {renderCostValueSection(data)}
      {data.otherHoldings?.map((otherHolding: FormattedHolding) => renderCostValueSection(otherHolding))}
      <Divider bottomMargin />
      <div className={classes.docsSector}>
        <div>
          {!data.noDocuments || !onDocumentsClick ? (
            <Typography variant="body2">{documentsText}</Typography>
          ) : (
            <Link variant="body2" onClick={handleDocumentsClick}>
              {documentsText}
            </Link>
          )}
        </div>
        <div>{!data.type && <SectorDetails sector={data.sector} />}</div>
      </div>
    </Paper>
  );
};

export default CompanyHoldingCard;
