import { Button, Container, createStyles, makeStyles, Tooltip, Typography } from "@material-ui/core";
import { downloadTaxCertificate } from "client/api/admin/holdings.adminApi";
import ConfirmationDialog from "client/components/ConfirmationDialog.component";
import useQuery from "client/hooks/useQuery.hook";
import useToggle from "client/hooks/useToggle.hook";
import moment from "moment";
import { LeanDocument } from "mongoose";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import type { DocumentProps } from "react-pdf";
import { Document, Page, pdfjs } from "react-pdf";
import { useHistory, useRouteMatch } from "react-router-dom";
import { LeanCompany } from "server/services/company/company.types";
import { PopulatedShareTransaction } from "server/services/shareTransaction/shareTransaction.types";
import { Address } from "server/services/user/user.types";
import { getShareTransaction, sendRegenTaxCertificateEmail, sendTaxCertificateEmails } from "../api/admin/shareTransactions.adminApi";
import { formatThousands } from "../utils/helpers";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface Props {}

const useStyles = makeStyles((theme) =>
  createStyles({
    detailsContainer: {
      display: "flex",
      justifyContent: "space-between",
      "& > div": {
        flex: 1,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
      },
      "& > div:not(:first-child)": {
        marginLeft: theme.spacing(2),
      },
    },
    pdfContainer: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
    },
  }),
);

const AdminViewTaxCertificate: React.FC<Props> = (props) => {
  const classes = useStyles(props);
  const history = useHistory();
  const match = useRouteMatch<{ shareTransactionId: string; subscriptionId: string }>();
  const regen = useQuery("regen");
  const [pdfData, setPDFData] = useState<ArrayBuffer | null>(null);
  const [shareTransactionDetails, setShareTransactionDetails] = useState<LeanDocument<PopulatedShareTransaction> | null>(null);
  const [confirmationOpen, , toggleOn, toggleOff] = useToggle(false);

  const isRegen = useMemo(() => regen === "1", [regen]);

  const sendDisabled = useMemo(
    () => !shareTransactionDetails?.subscriptions || shareTransactionDetails.subscriptions.every((sub) => Boolean(sub.seis3?.sent)),
    [shareTransactionDetails?.subscriptions],
  );
  const { shareTransactionId, subscriptionId } = useMemo(() => match.params, [match.params]);

  const currentSubscription = useMemo(() => {
    if (!shareTransactionDetails) return null;
    return shareTransactionDetails.subscriptions.find((sub) => sub._id === subscriptionId);
  }, [shareTransactionDetails, subscriptionId]);

  const fileProp = useMemo<DocumentProps["file"]>(() => ({ data: pdfData }), [pdfData]);

  useEffect(() => {
    if (currentSubscription) {
      (async () => {
        const data = await downloadTaxCertificate(subscriptionId, true);
        if (!data) return;
        setPDFData(data);
      })();
    }
  }, [currentSubscription, subscriptionId]);

  const handleShareTransactionFetch = useCallback(async () => {
    const shareTransaction = await getShareTransaction(shareTransactionId);
    setShareTransactionDetails(shareTransaction);
    return shareTransaction;
  }, [shareTransactionId]);

  useEffect(() => {
    handleShareTransactionFetch();
  }, [handleShareTransactionFetch]);

  const renderAddress = useCallback(
    (address: Address | LeanCompany["registeredAddress"] | null | undefined) =>
      address ? (
        <strong>
          <address>
            {address.addressLineOne}
            {address.addressLineTwo && (
              <>
                <br />
                {address.addressLineTwo}
              </>
            )}
            <br />
            {address.city}
            <br />
            {address.country}
            <br />
            {address.postcode}
          </address>
        </strong>
      ) : null,
    [],
  );

  const sendEmails = useCallback(async () => {
    let success: boolean | null = false;
    if (isRegen) {
      success = await sendRegenTaxCertificateEmail(shareTransactionId, subscriptionId);
    } else {
      success = await sendTaxCertificateEmails(shareTransactionId);
    }
    if (!success) return;
    toggleOff();
    const data = await handleShareTransactionFetch();
    if (data?.subscriptions.every((sub) => sub.seis3?.sent)) history.replace("/admin/panel/shareTransactions");
  }, [handleShareTransactionFetch, history, isRegen, shareTransactionId, subscriptionId, toggleOff]);

  return (
    <Container maxWidth="lg">
      <Typography variant="h3" align="center" gutterBottom>
        {shareTransactionDetails?.dealStage}3{isRegen && " Regenerated"} Tax Certificate
      </Typography>
      <div className={classes.detailsContainer}>
        <div>
          <Typography variant="h4" align="center" gutterBottom>
            Deployment Details
          </Typography>
          {shareTransactionDetails && (
            <Typography variant="body1" align="center" component="div" paragraph>
              Settle Date
              <br /> <strong>{moment(shareTransactionDetails.settleDate).format("L")}</strong>
              <br />
              Nominal Value Per Share
              <br /> <strong>{formatThousands(shareTransactionDetails.nominalValuePerShare, 2, true, 2, 7)}</strong>
              <br />
              Nominee
              <br /> <strong>{shareTransactionDetails.nominee}</strong>
              <br />
              UIR
              <br /> <strong>{shareTransactionDetails.uir}</strong>
              <br />
              Company
              <br />
              <strong>{shareTransactionDetails.company.companyName}</strong>
              <br />
              Company Address
              <br />
              <strong>{renderAddress(shareTransactionDetails.company.registeredAddress)}</strong>
            </Typography>
          )}
        </div>
        <div>
          <Typography variant="h4" align="center" gutterBottom>
            Subscription Details
          </Typography>
          {currentSubscription && (
            <Typography variant="body1" align="center" component="div" paragraph>
              Investor Name
              <br />
              <strong>
                {currentSubscription.investor.forenames} {currentSubscription.investor.surname}
              </strong>
              <br />
              Investor Email
              <br /> <strong>{currentSubscription.investor.email}</strong>
              <br />
              Number of shares
              <br /> <strong>{currentSubscription.noOfShares}</strong>
              <br />
              Address
              <br />
              {currentSubscription.investor.personalDetails &&
                currentSubscription.investor.personalDetails.addressHistory &&
                renderAddress(currentSubscription.investor.personalDetails.addressHistory[0])}
            </Typography>
          )}
        </div>
      </div>
      {pdfData && (
        <Document file={fileProp} className={classes.pdfContainer}>
          <Page pageNumber={1} />
        </Document>
      )}
      <br />
      <Tooltip title={sendDisabled ? `All ${shareTransactionDetails?.dealStage}3s Sent or Share Transaction Not Available` : ""}>
        <div>
          <Button onClick={toggleOn} disabled={sendDisabled} variant="outlined" size="small" fullWidth>
            Send Email{!isRegen && "s"}
          </Button>
        </div>
      </Tooltip>
      <ConfirmationDialog open={confirmationOpen} onClose={toggleOff} onPositiveButtonClick={sendEmails}>
        Are you sure you want to do this?
        <br />
        This will send an email to{" "}
        {isRegen
          ? "this underlying investor (or their adviser) for this deployment"
          : "all underlying investors (or their advisers) in this deployment"}{" "}
        indicating that their {shareTransactionDetails?.dealStage}3 Tax Certificates are available.
      </ConfirmationDialog>
    </Container>
  );
};

export default AdminViewTaxCertificate;
