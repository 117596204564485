import { Link, makeStyles, Typography } from "@material-ui/core";
import { FiberManualRecord as Circle } from "@material-ui/icons";
import clsx from "clsx";
import React, { FC, useCallback, useMemo, useState } from "react";
import { HistoricValue } from "server/services/shareTransaction/shareTransaction.types";
import { formatThousands } from "server/utils/helpers";
import PortfolioValueChart from "./PortfolioValueChart.component";
import SummaryItem from "./SummaryItem.component";

interface Props {
  historicValues: HistoricValue[];
  cost: number;
  value: number;
}

const useStyles = makeStyles(
  (theme) => ({
    container: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "flex-start",
      transition: theme.transitions.create("height", {
        duration: theme.transitions.duration.complex,
        easing: theme.transitions.easing.easeInOut,
      }),
      "& > *": {
        flex: 1,
      },
    },
    chartContainer: {
      width: "100%",
      height: "100%",
      overflow: "hidden",
      padding: theme.spacing(0, 2),
    },
    costValueKey: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& > svg": {
        marginRight: "0.3em",
      },
    },
    expandedContainer: {
      position: "relative",
    },
    fullChartContainer: {
      overflow: "unset",
      padding: theme.spacing(0),
      height: "unset",
      position: "absolute",
      bottom: theme.spacing(-1.25),
    },
    summaryItem: {
      flex: 2,
      overflow: "hidden",
    },
  }),
  { name: "PortfolioSummaryItem" },
);

const PortfolioSummaryItem: FC<Props> = ({ historicValues, cost, value, ...props }) => {
  const classes = useStyles(props);
  const [mini, setMini] = useState(true);
  const [chartHeight, setChartHeight] = useState(0);
  const [minHeight, setMinHeight] = useState(0);

  const hasHoldings = useMemo(() => Boolean(historicValues.length), [historicValues.length]);
  const containerHeight = useMemo(() => (mini ? minHeight : minHeight + chartHeight), [chartHeight, minHeight, mini]);

  const titleActionClick = useCallback(() => setMini((s) => !s), []);
  const handleContainerHeight = useCallback((ref: HTMLDivElement | null) => ref && setMinHeight(ref.scrollHeight), []);

  return (
    <SummaryItem
      title="YOUR PORTFOLIO"
      titleAction={hasHoldings && <Link onClick={titleActionClick}>{mini ? "More" : "Less"} detail</Link>}
      className={clsx(classes.summaryItem)}
    >
      <div
        ref={handleContainerHeight}
        className={clsx(classes.container, { [classes.expandedContainer]: !mini })}
        style={{ height: containerHeight }}
      >
        <div>
          <Typography variant="body2" align="center" paragraph className={clsx({ [classes.costValueKey]: !mini })}>
            {!mini && <Circle color="secondary" fontSize="inherit" />}
            <span>COST</span>
          </Typography>
          <Typography variant="h3" align="center">
            {formatThousands(cost, 2)}
          </Typography>
        </div>
        <div className={clsx(classes.chartContainer, { [classes.fullChartContainer]: !mini })}>
          <PortfolioValueChart historicValues={historicValues} mini={mini} onChartChange={setChartHeight} />
        </div>
        <div>
          <Typography variant="body2" align="center" paragraph className={clsx({ [classes.costValueKey]: !mini })}>
            {!mini && <Circle color="primary" fontSize="inherit" />}
            <span>VALUE</span>
          </Typography>
          <Typography variant="h3" align="center">
            {formatThousands(value, 2)}
          </Typography>
        </div>
      </div>
    </SummaryItem>
  );
};

export default PortfolioSummaryItem;
