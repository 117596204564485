import { Container, FormGroup, Link, makeStyles, Typography } from "@material-ui/core";
import ApplicationApi from "client/api/application.api";
import FieldArrayRemoveButton from "client/components/FieldArrayRemoveButton.component";
import FormikErrorsTouched from "client/components/FormikErrorsTouched.component";
import { ConfigContext } from "client/context/config.context";
import clsx from "clsx";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import _ from "lodash";
import moment from "moment";
import React, { ChangeEvent, FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { InvestmentInformation, LeanApplication } from "server/services/application/application.types";
import * as Yup from "yup";
import * as AdminApplicationApi from "../../api/admin/application.adminApi";
import ClientApplicationApi from "../../api/advisor/client/application.clientApi";
import AdvisorClientsApi from "../../api/advisor/clients.advisorApi";
import { downloadIM, getIMVersion } from "../../api/document.api";
import ErrorsList from "../../components/ErrorsList.component";
import Checkbox from "../../components/FormControls/CheckboxQuestion.component";
import DatePickerField from "../../components/FormControls/DatePickerField.component";
import NumberQuestion from "../../components/FormControls/NumberQuestion.component";
import RadioQuestion from "../../components/FormControls/RadioQuestion.component";
import SeisEisSplit from "../../components/FormControls/SeisEisSplit.component";
import SelectField from "../../components/FormControls/SelectField.component";
import TextQuestion from "../../components/FormControls/TextQuestion.component";
import ErrorText from "../../components/UIKit/ErrorText.component";
import { APPLICATION_TYPES } from "../../context/application.context";
import { AuthContext } from "../../context/auth.context";
import useAnalytics from "../../hooks/useAnalytics";
import { DAYS_OF_MONTH, FORM_FIELDS_DATA, ROUTES, TRACKED_EVENTS } from "../../utils/constants";
import { formatThousands, fundVariableReplacer, InitialValues } from "../../utils/helpers";
import StageButtonGroup from "./StageButtonGroup.component";
import useApplication from "./useApplication";

interface Props {
  isAdmin?: boolean;
}

const { INVESTMENT_DETAILS_FORM } = FORM_FIELDS_DATA;

const {
  INPUT: {
    CATEGORIES: { INVESTMENT_DETAILS },
  },
  PAGE_VIEWS,
  PAGE_ACTIONS,
} = TRACKED_EVENTS;

const useStyles = makeStyles(
  (theme) => ({
    addButton: {
      width: "268px",
      backgroundColor: "white",
      boxShadow: "0px 0px 0px",
    },
    boldUnderline: {
      fontWeight: theme.typography.fontWeightBold,
      textDecoration: "underline",
    },
    hr: {
      borderTop: "solid 1px",
    },
    instruction: {
      fontWeight: theme.typography.fontWeightSemiBold,
    },
    sectionTitle: {
      fontWeight: theme.typography.fontWeightSemiBold,
    },
    split: {
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
        flexDirection: "column",
        "& > *": {
          width: "100%",
        },
        "& > *:not(:last-child)": {
          marginRight: 0,
        },
      },
    },
    formGroup: {
      width: "100%",
      paddingBottom: theme.spacing(5),
      [theme.breakpoints.down("md")]: {
        paddingBottom: theme.spacing(3),
      },
    },
    marginTop: {
      marginTop: "40px",
    },
    addText: {
      fontWeight: theme.typography.fontWeightSemiBold,
      color: theme.palette.primary.main,
      textDecoration: "underline",
    },
    topAndBottom: {
      paddingBottom: "30px",
      paddingTop: "40px",
    },
    or: {
      flex: 0,
      alignSelf: "center",
    },
    paymentContainer: {
      marginTop: 10,
      marginBottom: 50,
    },
    installmentsContainer: {
      backgroundColor: "#F8F8F8",
      borderRadius: "6px",
      marginTop: "40px",
      paddingTop: "0px",
      boxShadow: "3px 3px 10px #00000029",
    },
    container: {
      backgroundColor: "#F8F8F8",
      borderTop: "solid 1px",
      borderBottom: "solid 1px",
    },
    orTerm: {
      paddingTop: "20px",
      paddingBottom: "20px",
    },
  }),
  { name: "InvestmentDetailsView" },
);

const getSchema = ({
  isAdvisor,
  pastDateEnabled,
  enableCancellationPeriod,
  maximumInvestment,
}: {
  isAdvisor: boolean;
  pastDateEnabled: boolean;
  enableCancellationPeriod: boolean;
  maximumInvestment?: number | null;
}) => {
  const investmentDateSchema = Yup.date()
    .typeError("Please enter a valid date")
    .required("Please provide a date for this payment")
    .test("date-not-past", "Date cannot be in the past", (value) => pastDateEnabled || moment(value).isSameOrAfter(moment().startOf("day")));

  return Yup.object().shape({
    [INVESTMENT_DETAILS_FORM.investmentFrequency.id]: Yup.string().required("This field is required"),
    [INVESTMENT_DETAILS_FORM.noOfMonthlyInstallments.id]: Yup.number().when(INVESTMENT_DETAILS_FORM.investmentFrequency.id, {
      is: "Monthly installments",
      then: Yup.number()
        .positive("Please enter a positive value")
        .min(12, "Minimum number of months is 12 months")
        .required("Please enter the number of monthly instalments"),
      otherwise: Yup.number().nullable().notRequired(),
    }),
    [INVESTMENT_DETAILS_FORM.amountEachMonthlyInstallment.id]: Yup.number().when(INVESTMENT_DETAILS_FORM.investmentFrequency.id, {
      is: "Monthly installments",
      then: Yup.number()
        .typeError("Monthly investment amount must be a number")
        .positive("Please enter a positive value")
        .required("Please enter the amount to be invested each month"),
      otherwise: Yup.number().nullable().notRequired(),
    }),
    [INVESTMENT_DETAILS_FORM.dateForMonthlyPayment.id]: Yup.number().when(INVESTMENT_DETAILS_FORM.investmentFrequency.id, {
      is: "Monthly installments",
      then: Yup.number().required("Please select a day of the month"),
      otherwise: Yup.number().nullable().notRequired(),
    }),

    [INVESTMENT_DETAILS_FORM.investmentAmount.id]: maximumInvestment
      ? Yup.number()
          .typeError("Investment amount must be a number")
          .positive("Please enter a positive value")
          .max(maximumInvestment, `Maximum investment is ${formatThousands(maximumInvestment, 2)}`)
          .required("Please enter an amount")
      : Yup.number().typeError("Investment amount must be a number").positive("Please enter a positive value").required("Please enter an amount"),

    [INVESTMENT_DETAILS_FORM.investmentDate.id]: Yup.date().when(INVESTMENT_DETAILS_FORM.investmentFrequency.id, {
      is: "One off payment",
      then: investmentDateSchema,
      otherwise: Yup.date().nullable().notRequired(),
    }),
    [INVESTMENT_DETAILS_FORM.carryBack.id]: Yup.object({
      [INVESTMENT_DETAILS_FORM.carryBack.present.id]: Yup.boolean().required("This field is required"),
      [INVESTMENT_DETAILS_FORM.carryBack.information.id]: Yup.string().when(INVESTMENT_DETAILS_FORM.carryBack.present.id, {
        is: true,
        then: Yup.string().required("Please confirm details"),
        otherwise: Yup.string().nullable(),
      }),
    }),
    [INVESTMENT_DETAILS_FORM.seis.id]: Yup.number()
      .min(0, "Minimum value is 0%")
      .max(100, "Maximum value is 100%")
      .integer("Value must be a whole number")
      .required("Please enter a percentage"),
    [INVESTMENT_DETAILS_FORM.eis.id]: Yup.number()
      .min(0, "Minimum value is 0%")
      .max(100, "Maximum value is 100%")
      .integer("Value must be a whole number")
      .required("Please enter a percentage"),
    [INVESTMENT_DETAILS_FORM.waiveCancellationPeriod.id]: enableCancellationPeriod
      ? Yup.boolean().nullable().required("This field is required")
      : Yup.boolean().nullable(),
    [INVESTMENT_DETAILS_FORM.paymentMethod.id]: Yup.string().required("This field is required"),
    [INVESTMENT_DETAILS_FORM.isCustodyBound.id]: Yup.boolean().oneOf([true], "Please check to agree").required("Please check to agree"),
    [INVESTMENT_DETAILS_FORM.isFinancialAdvice.id]: !isAdvisor
      ? Yup.boolean().oneOf([true], "Please check to agree").required("Please check to agree")
      : Yup.boolean(),
    [INVESTMENT_DETAILS_FORM.isReadIM.id]: Yup.boolean().oneOf([true], "Please check to agree").required("Please check to agree"),
    [INVESTMENT_DETAILS_FORM.understandDiversifyInvestmentPortfolio.id]: Yup.boolean()
      .oneOf([true], "Please check to agree")
      .required("Please check to agree"),
    [INVESTMENT_DETAILS_FORM.understandNoTaxGuarantee.id]: Yup.boolean().oneOf([true], "Please check to agree").required("Please check to agree"),
    [INVESTMENT_DETAILS_FORM.understandNoSecondaryMarket.id]: Yup.boolean().oneOf([true], "Please check to agree").required("Please check to agree"),
    [INVESTMENT_DETAILS_FORM.understandHighRiskIlliquid.id]: Yup.boolean().oneOf([true], "Please check to agree").required("Please check to agree"),
    [INVESTMENT_DETAILS_FORM.understandHeldOnTrust.id]: Yup.boolean().oneOf([true], "Please check to agree").required("Please check to agree"),
    [INVESTMENT_DETAILS_FORM.doNotWantToBeContacted.id]: Yup.boolean(),

    installments: Yup.array().when(INVESTMENT_DETAILS_FORM.investmentFrequency.id, {
      is: "Multiple installments",
      then: Yup.array(
        Yup.object({
          amountEachInstallment: Yup.number()
            .typeError("Please enter an instalment amount")
            .positive("Please enter a positive value")
            .required("Please enter an amount"),
          date: investmentDateSchema,
        }),
      ).min(1, "Please include at least one instalment"),
      otherwise: Yup.array().nullable(),
    }),
  });
};

const DEFAULT_VALUES = {
  [INVESTMENT_DETAILS_FORM.investmentFrequency.id]: "",
  [INVESTMENT_DETAILS_FORM.noOfMonthlyInstallments.id]: "",
  [INVESTMENT_DETAILS_FORM.amountEachMonthlyInstallment.id]: "",
  [INVESTMENT_DETAILS_FORM.dateForMonthlyPayment.id]: "",
  [INVESTMENT_DETAILS_FORM.investmentAmount.id]: "",
  [INVESTMENT_DETAILS_FORM.investmentDate.id]: null,
  [INVESTMENT_DETAILS_FORM.carryBack.id]: {
    [INVESTMENT_DETAILS_FORM.carryBack.present.id]: null,
    [INVESTMENT_DETAILS_FORM.carryBack.information.id]: "",
  },
  [INVESTMENT_DETAILS_FORM.seis.id]: 67,
  [INVESTMENT_DETAILS_FORM.eis.id]: 33,
  [INVESTMENT_DETAILS_FORM.waiveCancellationPeriod.id]: null,
  [INVESTMENT_DETAILS_FORM.paymentMethod.id]: "",
  [INVESTMENT_DETAILS_FORM.isCustodyBound.id]: false,
  [INVESTMENT_DETAILS_FORM.isFinancialAdvice.id]: false,
  [INVESTMENT_DETAILS_FORM.isReadIM.id]: false,
  [INVESTMENT_DETAILS_FORM.understandDiversifyInvestmentPortfolio.id]: false,
  [INVESTMENT_DETAILS_FORM.understandNoTaxGuarantee.id]: false,
  [INVESTMENT_DETAILS_FORM.understandNoSecondaryMarket.id]: false,
  [INVESTMENT_DETAILS_FORM.understandHighRiskIlliquid.id]: false,
  [INVESTMENT_DETAILS_FORM.understandHeldOnTrust.id]: false,
  [INVESTMENT_DETAILS_FORM.doNotWantToBeContacted.id]: false,
  installments: [
    {
      amountEachInstallment: "",
      date: null,
    },
  ],
};

const InvestmentDetails: FC<Props> = ({ isAdmin, ...props }) => {
  const classes = useStyles(props);
  const history = useHistory();
  const params = useParams<{ applicationId?: string; clientID?: string }>();
  const { fund } = useContext(ConfigContext);
  const {
    state: { user },
  } = useContext(AuthContext);
  const [clientMaxInvestment, setClientMaxInvestment] = useState(null);
  const [imVersionNumber, setIMVersionNumber] = useState("");

  const applicationId = useMemo(() => params.applicationId, [params.applicationId]);
  const clientId = useMemo(() => user?.isAdvisor && params.clientID, [params.clientID, user?.isAdvisor]);
  const { application, dispatch } = useApplication(isAdmin);

  useAnalytics();

  const minDate = useMemo(() => (applicationId ? undefined : moment().startOf("day").toDate()), [applicationId]);

  const fetchClientById = useCallback(async () => {
    if (clientId) {
      const tempClient = await AdvisorClientsApi.getClient(clientId);
      if (tempClient?.maxInvestment) {
        setClientMaxInvestment(tempClient.maxInvestment);
      }
    }
  }, [clientId]);

  useEffect(() => {
    (async () => {
      const { data } = await getIMVersion();
      setIMVersionNumber(data);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await fetchClientById();
    })();
  }, [fetchClientById]);

  const isAgreeingFee = useMemo(() => {
    if (!applicationId && user?.advisor?.adviceType === "REFERRED") {
      return !user?.advisor.isFeeAgreed;
    } else {
      return false;
    }
  }, [applicationId, user?.advisor]);

  const initialValues = useMemo<InitialValues<InvestmentInformation>>(
    () =>
      ({
        ...DEFAULT_VALUES,
        [INVESTMENT_DETAILS_FORM.seis.id]: fund.applicationForm.seisDefault,
        [INVESTMENT_DETAILS_FORM.eis.id]: 100 - fund.applicationForm.seisDefault,
        ...(applicationId && application?.data ? application.data.investmentInformation : {}),
      } as any),
    [application?.data, applicationId, fund.applicationForm.seisDefault],
  );

  useEffect(() => {
    globalThis.dataLayer.push({
      event: clientId ? PAGE_VIEWS.CLIENT_APPLICATION_FORM_INVESTMENT : PAGE_VIEWS.INVESTOR_APPLICATION_INVESTMENT,
    });
  }, [clientId]);

  const schema = useMemo(
    () =>
      getSchema({
        isAdvisor: Boolean(clientId),
        pastDateEnabled: !minDate,
        enableCancellationPeriod: fund.applicationForm.enableCancellationPeriod,
        maximumInvestment: clientId ? clientMaxInvestment : user?.maxInvestment,
      }),
    [clientId, clientMaxInvestment, fund.applicationForm.enableCancellationPeriod, minDate, user?.maxInvestment],
  );

  const isInitialValid = useMemo(() => schema.isValidSync(initialValues), [initialValues, schema]);

  const goForward = useCallback(() => {
    if (isAgreeingFee) {
      history.push(ROUTES.ADVISOR.FEE_PAGE);
    } else if (clientId) {
      history.push(`/advisor/client/${clientId}${ROUTES.APPLICATION_AGREEMENT}`);
    } else if (isAdmin) {
      history.push(`/admin/application/${applicationId}/preview`);
    } else {
      history.push(ROUTES.APPLICATION_AGREEMENT);
    }
  }, [applicationId, clientId, history, isAdmin, isAgreeingFee]);

  const handleFormSubmit = useCallback(
    async (values) => {
      let submittedApplication: LeanApplication | null = null;
      if (!fund.applicationForm.enableCancellationPeriod) values[INVESTMENT_DETAILS_FORM.waiveCancellationPeriod.id] = null;
      if (clientId) {
        const res = await ClientApplicationApi.postInvestment(clientId, application!._id, values);
        if (res) {
          submittedApplication = res;
          globalThis.dataLayer.push({ event: PAGE_ACTIONS.CLIENT_APPLICATION_ABOUT_FORM_SUBMITTED_SUCCESSFULLY });
        } else {
          globalThis.dataLayer.push({ event: PAGE_ACTIONS.CLIENT_APPLICATION_ABOUT_FORM_SUBMITTED_UNSUCCESSFULLY });
        }
      } else if (isAdmin) {
        const res = await AdminApplicationApi.postInvestment(applicationId!, values);
        if (res) submittedApplication = res;
      } else {
        const res = await ApplicationApi.postInvestment(application!._id, values);
        if (res) {
          submittedApplication = res;
          globalThis.dataLayer.push({ event: PAGE_ACTIONS.INVESTOR_APPLICATION_FORM_ABOUT_SUBMITTED_SUCCESSFULLY });
        } else {
          globalThis.dataLayer.push({
            event: PAGE_ACTIONS.INVESTOR_APPLICATION_FORM_ABOUT_SUBMITTED_UNSUCCESSFULLY,
          });
        }
      }
      if (submittedApplication) {
        dispatch({ type: APPLICATION_TYPES.SET_APPLICATION, payload: submittedApplication });
        goForward();
      }
    },
    [application, applicationId, clientId, dispatch, fund.applicationForm.enableCancellationPeriod, goForward, isAdmin],
  );

  return (
    <>
      <Container>
        <FormGroup>
          <Typography variant="h2" align="center">
            Appropriateness questionnaire
          </Typography>
        </FormGroup>
      </Container>
      <hr className={classes.hr} />
      <Formik
        validationSchema={schema}
        initialValues={initialValues}
        isInitialValid={isInitialValid}
        enableReinitialize={true}
        onSubmit={handleFormSubmit}
      >
        {({ values, errors, submitCount, setFieldValue }) => (
          <Form autoComplete="off" noValidate>
            <FormikErrorsTouched />
            <Container maxWidth="md">
              <FormGroup>
                <Typography variant="h4" className={classes.sectionTitle}>
                  Section 5:
                </Typography>
                <Typography variant="h4" paragraph>
                  About the investment
                </Typography>
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <Field
                  component={RadioQuestion}
                  name={INVESTMENT_DETAILS_FORM.investmentFrequency.name}
                  label={INVESTMENT_DETAILS_FORM.investmentFrequency.label}
                  trackedCategory={INVESTMENT_DETAILS}
                  radioValues={[
                    "One off payment",
                    { label: "Multiple instalments", value: "Multiple installments" },
                    { label: "Monthly instalments", value: "Monthly installments" },
                  ]}
                  onChange={(_e: any, value: string) => {
                    if (value === "One off payment") {
                      setFieldValue(INVESTMENT_DETAILS_FORM.investmentFrequency.name, value);
                      setFieldValue(INVESTMENT_DETAILS_FORM.noOfMonthlyInstallments.name, "");
                      setFieldValue(INVESTMENT_DETAILS_FORM.amountEachMonthlyInstallment.name, "");
                      setFieldValue(INVESTMENT_DETAILS_FORM.dateForMonthlyPayment.name, "");
                      setFieldValue("installments", []);
                    } else if (value === "Multiple installments") {
                      setFieldValue(INVESTMENT_DETAILS_FORM.investmentFrequency.name, value);
                      setFieldValue(INVESTMENT_DETAILS_FORM.investmentDate.name, null);
                      setFieldValue(INVESTMENT_DETAILS_FORM.noOfMonthlyInstallments.name, "");
                      setFieldValue(INVESTMENT_DETAILS_FORM.amountEachMonthlyInstallment.name, "");
                      setFieldValue(INVESTMENT_DETAILS_FORM.dateForMonthlyPayment.name, "");
                      if (values.installments!.length === 0) values.installments!.push({ amountEachInstallment: "", date: null });
                    } else {
                      setFieldValue(INVESTMENT_DETAILS_FORM.investmentFrequency.name, value);
                      setFieldValue(INVESTMENT_DETAILS_FORM.investmentDate.name, null);
                      setFieldValue("installments", []);
                    }
                  }}
                />
                {values.investmentFrequency === "Multiple installments" && (
                  <>
                    <FieldArray name="installments">
                      {({ push, remove }) => (
                        <>
                          {values.installments!.map((_installment, index) => (
                            <Container maxWidth="md" className={classes.installmentsContainer} key={index}>
                              <FieldArrayRemoveButton
                                label={`Instalment ${index + 1}`}
                                hideIconButton={index === 0}
                                handleClick={() => {
                                  const vals = _.cloneDeep(values.installments!);
                                  vals.splice(index, 1);
                                  setFieldValue(
                                    "investmentAmount",
                                    _.sumBy(vals, (i) => Number(i.amountEachInstallment)),
                                  );
                                  remove(index);
                                }}
                              />
                              <FormGroup row className={classes.marginTop}>
                                <Field component={DatePickerField} name={`installments[${index}].date`} minDate={minDate} minDateMessage={null} />
                                <Field
                                  component={NumberQuestion}
                                  moneyFormat
                                  name={`installments[${index}].amountEachInstallment`}
                                  placeholder="Instalment Amount"
                                  prefix="£"
                                  onBlur={() =>
                                    setFieldValue(
                                      "investmentAmount",
                                      _.sumBy(values.installments, (i) => Number(i.amountEachInstallment)),
                                    )
                                  }
                                  onChange={(e: ChangeEvent<HTMLInputElement>, value: number) => setFieldValue(e.target.name, value)}
                                />
                              </FormGroup>
                              <ErrorMessage name="installments" component={ErrorText} className="fs-exclude" />
                            </Container>
                          ))}
                          {values.installments!.length !== 4 && (
                            <FormGroup>
                              <Link className={classes.addButton} onClick={() => push({ amountEachInstallment: "", date: null })}>
                                Add another instalment
                              </Link>
                            </FormGroup>
                          )}
                        </>
                      )}
                    </FieldArray>
                    <br />
                  </>
                )}
                {values.investmentFrequency === "Monthly installments" && (
                  <>
                    <FormGroup className={classes.topAndBottom}>
                      <Field
                        component={NumberQuestion}
                        name={INVESTMENT_DETAILS_FORM.noOfMonthlyInstallments.name}
                        label={INVESTMENT_DETAILS_FORM.noOfMonthlyInstallments.label}
                        placeholder={INVESTMENT_DETAILS_FORM.noOfMonthlyInstallments.placeholder}
                        trackedCategory={INVESTMENT_DETAILS}
                      />
                    </FormGroup>
                    <br />
                    <Typography className={classes.instruction}>
                      Please provide&nbsp;
                      <Typography component="span" className={classes.boldUnderline}>
                        one
                      </Typography>
                      &nbsp;of the following two values:
                    </Typography>
                    <FormGroup row>
                      <Field
                        component={NumberQuestion}
                        moneyFormat
                        name={INVESTMENT_DETAILS_FORM.amountEachMonthlyInstallment.name}
                        label={INVESTMENT_DETAILS_FORM.amountEachMonthlyInstallment.label}
                        placeholder={INVESTMENT_DETAILS_FORM.amountEachMonthlyInstallment.placeholder}
                        trackedCategory={INVESTMENT_DETAILS}
                        onChange={(_e: any, value: number) => {
                          if (values.investmentFrequency === "Monthly installments") {
                            setFieldValue("amountEachMonthlyInstallment", value);
                            setFieldValue("investmentAmount", value * (values.noOfMonthlyInstallments || 0));
                          }
                        }}
                      />
                      <Typography className={clsx(classes.or, classes.boldUnderline)} align="center">
                        or
                      </Typography>
                      <Field
                        component={NumberQuestion}
                        moneyFormat
                        name={INVESTMENT_DETAILS_FORM.investmentAmount.name}
                        label="Total Investment"
                        placeholder={INVESTMENT_DETAILS_FORM.investmentAmount.placeholder}
                        trackedCategory={INVESTMENT_DETAILS}
                        onChange={(_e: any, value: number) => {
                          if (values.investmentFrequency === "Monthly installments") {
                            setFieldValue("investmentAmount", value);
                            setFieldValue("amountEachMonthlyInstallment", value / (values.noOfMonthlyInstallments || 0));
                          } else if (values.investmentFrequency === "One off payment") {
                            setFieldValue("investmentAmount", value);
                          } else {
                            setFieldValue("investmentAmount", value);
                          }
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Field
                        component={SelectField}
                        name={INVESTMENT_DETAILS_FORM.dateForMonthlyPayment.name}
                        label={INVESTMENT_DETAILS_FORM.dateForMonthlyPayment.label}
                        placeholder={INVESTMENT_DETAILS_FORM.dateForMonthlyPayment.placeholder}
                        trackedCategory={INVESTMENT_DETAILS}
                        options={DAYS_OF_MONTH}
                      />
                    </FormGroup>
                  </>
                )}
              </FormGroup>
              {values.investmentFrequency === "One off payment" && (
                <>
                  <FormGroup className={classes.formGroup}>
                    <Field
                      component={DatePickerField}
                      name={INVESTMENT_DETAILS_FORM.investmentDate.name}
                      label={INVESTMENT_DETAILS_FORM.investmentDate.label}
                      minDate={minDate}
                      minDateMessage={null}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Field
                      component={NumberQuestion}
                      moneyFormat
                      name={INVESTMENT_DETAILS_FORM.investmentAmount.name}
                      label={INVESTMENT_DETAILS_FORM.investmentAmount.label}
                      placeholder={INVESTMENT_DETAILS_FORM.investmentAmount.placeholder}
                      trackedCategory={INVESTMENT_DETAILS}
                      onChange={(_e: any, value: number) => {
                        setFieldValue("investmentAmount", value);
                      }}
                    />
                  </FormGroup>
                </>
              )}
              {values.investmentFrequency === "Multiple installments" && (
                <FormGroup className={classes.formGroup}>
                  <Field
                    component={NumberQuestion}
                    moneyFormat
                    name={INVESTMENT_DETAILS_FORM.investmentAmount.name}
                    label="Total Investment"
                    placeholder="Multiple instalments"
                    trackedCategory={INVESTMENT_DETAILS}
                    disabled
                  />
                </FormGroup>
              )}
              <FormGroup>
                <FormGroup className={classes.formGroup}>
                  <Field
                    component={SeisEisSplit}
                    label="How do you want to split the investment (SEIS / EIS)"
                    trackedCategory={INVESTMENT_DETAILS}
                    row
                  />
                </FormGroup>
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <Field
                  component={RadioQuestion}
                  name={INVESTMENT_DETAILS_FORM.carryBack.present.name}
                  label={INVESTMENT_DETAILS_FORM.carryBack.present.label}
                  trackedCategory={INVESTMENT_DETAILS}
                />
                {values.carryBack!.present && (
                  <Field
                    component={TextQuestion}
                    name={INVESTMENT_DETAILS_FORM.carryBack.information.name}
                    placeholder={INVESTMENT_DETAILS_FORM.carryBack.information.placeholder}
                    trackedCategory={INVESTMENT_DETAILS}
                  />
                )}
              </FormGroup>
              {fund.applicationForm.enableCancellationPeriod && (
                <FormGroup className={classes.formGroup}>
                  <Field
                    component={RadioQuestion}
                    name={INVESTMENT_DETAILS_FORM.waiveCancellationPeriod.name}
                    label={INVESTMENT_DETAILS_FORM.waiveCancellationPeriod.label}
                    radioStyle={{ paddingTop: "20px" }}
                    trackedCategory={INVESTMENT_DETAILS}
                    radioValues={[
                      {
                        label:
                          "I confirm that I wish to waive my 14-day cancellation period and the Investment Manager may make an investment on my behalf during my 14-day cancellation period",
                        value: false,
                      },
                      {
                        label:
                          "I confirm that I do not wish to waive my 14-day cancellation period and the Investment Manager may not make an investment on my behalf during my 14-day cancellation period.",
                        value: true,
                      },
                    ]}
                  />
                </FormGroup>
              )}
            </Container>

            <Container maxWidth={false} className={classes.container}>
              <Container maxWidth="md">
                <Field
                  component={RadioQuestion}
                  name={INVESTMENT_DETAILS_FORM.paymentMethod.name}
                  label={INVESTMENT_DETAILS_FORM.paymentMethod.label}
                  trackedCategory={INVESTMENT_DETAILS}
                  radioValues={["Bank transfer", "Cheque/bankers' draft"]}
                />
              </Container>
            </Container>

            <Container maxWidth="md">
              {!clientId && (
                <FormGroup>
                  <Field
                    component={Checkbox}
                    name={INVESTMENT_DETAILS_FORM.isFinancialAdvice.name}
                    label={INVESTMENT_DETAILS_FORM.isFinancialAdvice.label.replace("${manager.name}", fund.manager.name)}
                    trackedCategory={INVESTMENT_DETAILS}
                  />
                </FormGroup>
              )}
              <FormGroup>
                <Field
                  component={Checkbox}
                  name={INVESTMENT_DETAILS_FORM.isReadIM.name}
                  label={
                    <>
                      Please tick this to indicate that you have read and understood the{" "}
                      <Link
                        onClick={async (e: any) => {
                          e.preventDefault();
                          await downloadIM();
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {fund.name} Information Memorandum {imVersionNumber}
                      </Link>
                    </>
                  }
                  trackedCategory={INVESTMENT_DETAILS}
                />
              </FormGroup>
              <FormGroup>
                <Field
                  component={Checkbox}
                  name={INVESTMENT_DETAILS_FORM.isCustodyBound.name}
                  label={INVESTMENT_DETAILS_FORM.isCustodyBound.label(fund.custodian.custodyAgreement)}
                  trackedCategory={INVESTMENT_DETAILS}
                />
              </FormGroup>
              <FormGroup>
                <Field
                  component={Checkbox}
                  name={INVESTMENT_DETAILS_FORM.understandDiversifyInvestmentPortfolio.name}
                  label={INVESTMENT_DETAILS_FORM.understandDiversifyInvestmentPortfolio.label}
                  trackedCategory={INVESTMENT_DETAILS}
                />
              </FormGroup>
              <FormGroup>
                <Field
                  component={Checkbox}
                  name={INVESTMENT_DETAILS_FORM.understandNoTaxGuarantee.name}
                  label={INVESTMENT_DETAILS_FORM.understandNoTaxGuarantee.label}
                  trackedCategory={INVESTMENT_DETAILS}
                />
              </FormGroup>
              <FormGroup>
                <Field
                  component={Checkbox}
                  name={INVESTMENT_DETAILS_FORM.understandNoSecondaryMarket.name}
                  label={INVESTMENT_DETAILS_FORM.understandNoSecondaryMarket.label}
                  trackedCategory={INVESTMENT_DETAILS}
                />
              </FormGroup>
              <FormGroup>
                <Field
                  component={Checkbox}
                  name={INVESTMENT_DETAILS_FORM.understandHighRiskIlliquid.name}
                  label={INVESTMENT_DETAILS_FORM.understandHighRiskIlliquid.label}
                  trackedCategory={INVESTMENT_DETAILS}
                />
              </FormGroup>
              <FormGroup>
                <Field
                  component={Checkbox}
                  name={INVESTMENT_DETAILS_FORM.understandHeldOnTrust.name}
                  label={INVESTMENT_DETAILS_FORM.understandHeldOnTrust.label}
                  trackedCategory={INVESTMENT_DETAILS}
                />
              </FormGroup>
            </Container>
            <Container maxWidth={false} className={classes.container}>
              <Container maxWidth="md">
                <Field
                  component={Checkbox}
                  name={INVESTMENT_DETAILS_FORM.doNotWantToBeContacted.name}
                  label={
                    <>
                      {fundVariableReplacer(fund.applicationForm.section7.signature.updatesContact, fund)} may from time to time contact investors
                      with news updates. Please tick here if you <strong>do not</strong> want to receive such updates.
                    </>
                  }
                  trackedCategory={INVESTMENT_DETAILS}
                />
              </Container>
            </Container>
            <Container maxWidth="md">
              {submitCount > 0 && Object.keys(errors).length !== 0 && errors.constructor === Object && (
                <FormGroup>
                  <Typography variant="body2" color="error" paragraph>
                    Please complete the following fields:
                  </Typography>
                  <ErrorsList errors={errors} constant={INVESTMENT_DETAILS_FORM} />
                </FormGroup>
              )}
              <FormGroup>
                <StageButtonGroup handleFormSubmit={handleFormSubmit} isAdmin={isAdmin} />
              </FormGroup>
            </Container>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default InvestmentDetails;
