import { Dialog, DialogContent, DialogProps, DialogTitle, FormGroup } from "@material-ui/core";
import { useUpdateAppConfigs } from "client/api/gql/deployment.gqlApi";
import Divider from "client/components/Divider.component";
import CrudFormButtons from "client/components/FormControls/CrudFormButtons.component";
import NumberQuestion from "client/components/FormControls/NumberQuestion.component";
import TextQuestion from "client/components/FormControls/TextQuestion.component";
import QuestionLabel from "client/components/QuestionLabel.component";
import TransferList from "client/components/TransferList.component";
import { AdminContext } from "client/context/admin.context";
import { InitialValues } from "client/utils/helpers";
import notifications from "client/utils/notifications";
import { Field, Form, Formik, FormikConfig } from "formik";
import _ from "lodash";
import React, { useCallback, useContext, useMemo } from "react";
import { LeanApplicationConfig } from "server/services/deployment/deployment.types";
import * as Yup from "yup";
import DeploymentContext from "../deployment.context";

type Props = DialogProps & {
  applicationId?: string | null;
  onClose: () => void;
};

const AllocationOptions: React.FC<Props> = ({ onClose, applicationId, ...props }) => {
  const { allCompanies } = useContext(AdminContext);
  const { deals, applications } = useContext(DeploymentContext);
  const [updateAppConfigs] = useUpdateAppConfigs();

  const application = useMemo(() => applications.find((a) => a._id === applicationId), [applicationId, applications]);

  const listItems = useMemo(
    () =>
      allCompanies
        .filter((company) => deals.some((deal) => deal.company._id === company._id))
        .map((company) => ({
          key: company._id,
          label: `${company.companyName} - (${company.projectName})`,
          value: company._id,
        })),
    [allCompanies, deals],
  );

  const listItemsIds = useMemo(() => listItems.map((item) => item.value), [listItems]);

  const handleSubmit = useCallback<
    FormikConfig<
      Pick<LeanApplicationConfig, "excludedCompanies"> & {
        searchTerm: string;
        spread: { [key in keyof LeanApplicationConfig["spread"]]: LeanApplicationConfig["spread"][key] | string };
      }
    >["onSubmit"]
  >(
    async ({ searchTerm, spread, ...values }) => {
      if (!applicationId) return;
      const res = await updateAppConfigs({
        variables: {
          updates: {
            ...values,
            spread: { seis: typeof spread.seis === "string" ? null : spread.seis, eis: typeof spread.eis === "string" ? null : spread.eis },
          },
          appIds: [applicationId],
        },
      });
      if (res.errors) return notifications.gql(res.errors, "failed to update application config");
      onClose();
    },
    [applicationId, updateAppConfigs, onClose],
  );

  const initialValues = useMemo<InitialValues<Pick<LeanApplicationConfig, "spread" | "excludedCompanies"> & { searchTerm: string }>>(
    () => ({
      searchTerm: "",
      spread: {
        seis: application?.config.spread?.seis ?? "",
        eis: application?.config.spread?.eis ?? "",
      },
      excludedCompanies: application?.config.excludedCompanies ?? [],
    }),
    [application?.config.excludedCompanies, application?.config.spread?.eis, application?.config.spread?.seis],
  );

  const schema = useMemo(
    () =>
      Yup.lazy((v) =>
        Yup.object({
          spread: Yup.object({
            seis: Yup.number()
              .min(0, "Company spread must be at least ${min}")
              .max(listItemsIds.length - v.excludedCompanies.length, "Company spread cannot be more than ${max}")
              .integer("Company spread cannot be a decimal number")
              .nullable(),
            eis: Yup.number()
              .min(0, "Company spread must be at least ${min}")
              .max(listItemsIds.length - v.excludedCompanies.length, "Company spread cannot be more than ${max}")
              .integer("Company spread cannot be a decimal number")
              .nullable(),
          }),
          excludedCompanies: Yup.array(Yup.string()),
        }),
      ),
    [listItemsIds.length],
  );

  return (
    <Dialog onClose={onClose} {...props}>
      <DialogTitle>Edit Row Config</DialogTitle>
      <DialogContent>
        <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={schema} enableReinitialize>
          {({ values, setFieldValue }) => (
            <Form>
              <QuestionLabel>Companies</QuestionLabel>
              <Field
                name="searchTerm"
                component={TextQuestion}
                size="small"
                placeholder="Search Companies"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue(e.target.name, e.target.value, false)}
              />
              <br />
              <TransferList
                items={listItems}
                searchTerm={values.searchTerm}
                value={_.difference(listItemsIds, values.excludedCompanies)}
                sortKey="label"
                onChange={(_rightList, leftList) => setFieldValue("excludedCompanies", leftList)}
              />
              <FormGroup>
                <Divider />
              </FormGroup>
              <FormGroup row>
                {["seis", "eis"].map((key) => (
                  <Field
                    key={key}
                    name={`spread.${key}`}
                    component={NumberQuestion}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFieldValue(e.target.name, isNaN(e.target.valueAsNumber) ? null : e.target.valueAsNumber)
                    }
                    placeholder="e.g. 1"
                    size="small"
                    label={
                      <>
                        No. companies to spread <strong>{key.toUpperCase()}</strong> funds over
                      </>
                    }
                  />
                ))}
              </FormGroup>
              <CrudFormButtons onClose={onClose} />
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AllocationOptions;
