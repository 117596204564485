import notifications from "../utils/notifications";
import { ipAxios } from "./ipAxios";

const certifyInvestor = async (investorType, values) => {
  try {
    const { status, data } = await ipAxios.post(`/qualification/`, {
      investorType,
      values,
    });
    if (status === 200) {
      return { success: true, data };
    }
    return {
      success: false,
    };
  } catch (error) {
    notifications.genericError(error);
    return { success: false };
  }
};

const confirmInvestor = async () => {
  try {
    const { status, data } = await ipAxios.post(`/qualification/confirmInvestor`, null);
    if (status === 200) {
      return { success: true, data };
    }
    return {
      success: false,
    };
  } catch (error) {
    notifications.genericError(error);
    return { success: false };
  }
};

export default {
  certifyInvestor,
  confirmInvestor,
};
