import fileDownload from "js-file-download";
import notifications from "../utils/notifications";
import { ipAxios } from "./ipAxios";

export const getIMVersion = async () => {
  try {
    const res = await ipAxios.get("/documents/im/versionNumber");
    if (res.status === 200) {
      return { success: true, data: res.data };
    }
    return { success: false };
  } catch (error: any) {
    notifications.genericError(error);
    return { success: false };
  }
};

export const downloadIM = async () => {
  try {
    const response = await ipAxios.get("/documents/im/download", {
      responseType: "blob",
    });
    if (response.status === 200) {
      const blob = new Blob([response.data]);
      fileDownload(blob, "InformationMemorandum.pdf");
      return { success: true, data: response.data };
    }
    return { success: false };
  } catch (error: any) {
    notifications.genericError(error);
    return { success: false };
  }
};

export const getAllDocuments = async (tag?: string) => {
  try {
    const res = await ipAxios.get("/documents", { params: tag && { tag } });
    if (res.status === 200) {
      return { data: res.data, success: true };
    }
    return { success: false };
  } catch (error: any) {
    notifications.genericError(error);
    return { success: false };
  }
};

export const downloadDocument = async (documentId: string, fileName: string) => {
  try {
    const response = await ipAxios.get(`/documents/${documentId}`, {
      responseType: "blob",
    });

    if (response.status === 200) {
      const blob = new Blob([response.data]);
      fileDownload(blob, fileName);
      return { success: true, data: response.data };
    }
    return { success: false };
  } catch (error: any) {
    notifications.genericError(error);
    return { success: false };
  }
};

export const getCoverImage = async (documentId: string) => {
  try {
    const response = await ipAxios.get(`/documents/${documentId}/coverImage`, {
      responseType: "blob",
    });
    if (response.status === 200) {
      const blob = new Blob([response.data]);
      if ((window.navigator as any).msSaveBlob) {
        (window.navigator as any).msSaveBlob(blob, `${response.headers["x-file-name"]}.pdf`);
      } else {
        return window.URL.createObjectURL(blob);
      }
      return { success: true, data: response.data };
    }
    return { success: false };
  } catch (error: any) {
    notifications.genericError(error);
    return { success: false };
  }
};
