import { FC } from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { formatThousands } from "client/utils/helpers";
import { ShareTransactionAggregates, LeanShareTransaction } from "server/services/shareTransaction/shareTransaction.types";

interface Props {
  shareTransactionAggregates: ShareTransactionAggregates;
  shareTransaction: LeanShareTransaction;
  hasRoundingErrorResolved: boolean;
}

const useStyles = makeStyles(
  (theme) => ({
    success: {
      color: theme.palette.bullet.main,
    },
    error: {
      color: theme.palette.error.main,
    },
  }),
  { name: "RoundingErrorSummary" },
);

export const RoundingErrorSummary: FC<Props> = ({ shareTransactionAggregates, shareTransaction, hasRoundingErrorResolved, ...props }) => {
  const classes = useStyles(props);

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} spacing={2} justifyContent="center">
        <Typography variant="subtitle1" gutterBottom={true}>
          <strong>Summary</strong>
        </Typography>
      </Grid>

      <Grid item xs={12} spacing={2}>
        <Grid item xs={6}>
          <Typography variant="body2">
            {`Total Shares(${shareTransactionAggregates.noOfShares}) * Price Per Share(${shareTransaction.pricePerShare})`}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <strong>{`${formatThousands(shareTransactionAggregates.investmentCost2DP, 2)}`}</strong>
        </Grid>
      </Grid>

      <Grid item xs={12} spacing={2}>
        <Grid item xs={6}>
          <Typography variant="body2">{`Sum of subscriptions investment amount(2DP)`}</Typography>
        </Grid>
        <Grid item xs={6}>
          <strong>{`${formatThousands(shareTransactionAggregates.investmentCostFromSubscriptions2DP, 2)} `}</strong>
          {shareTransactionAggregates.adjustmentFactor !== 0 && (
            <>
              <span>&nbsp; {"=>"} &nbsp;</span>
              <strong className={hasRoundingErrorResolved ? classes.success : ""}>
                {`${formatThousands(shareTransactionAggregates.investmentCostFromSubscriptionsAfterAdjustment2DP, 2)}`}
              </strong>
            </>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12} spacing={2}>
        <Grid item xs={6}>
          <Typography variant="body2">{`Rounding Error`}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography display="inline" variant="body2" color="error">
            <strong>{`${formatThousands(shareTransactionAggregates.investmentCostDifference * -1, 2)}`}</strong>
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={12} spacing={2}>
        <Grid item xs={6}>
          <Typography variant="body2">{`Rounding Error Adjusted`}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography display="inline" variant="body2">
            <strong data-testid="adjustmentFactorValue">{`${formatThousands(shareTransactionAggregates.adjustmentFactor, 2)}`}</strong>
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={12} spacing={2}>
        <Grid item xs={6}>
          <Typography variant="body2">{`Rounding Error Remaining`}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography display="inline" variant="body2" className={hasRoundingErrorResolved ? classes.success : classes.error}>
            <strong>{`${formatThousands(
              shareTransactionAggregates.investmentCostDifference + shareTransactionAggregates.adjustmentFactor * -1,
              2,
            )}`}</strong>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
