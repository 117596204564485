import { gql, useQuery } from "@apollo/client";
import _ from "lodash";
import { useEffect, useMemo } from "react";
import { BaseDeploymentDeal } from "server/services/company/company.types";
import { DealConfig } from "server/services/deployment/deployment.types";
import { DealConfigsQueries } from "./deployment.gqlApi";

export const DeploymentDealsQueries = {
  query: gql`
    query GetDeals {
      deals {
        _id
        company {
          _id
          companyName
          seisAllowanceLimit
        }
        config
        shareClassData {
          name
          pricePerShare
          nominalValue
        }
        investmentRemaining {
          total
          seis
          eis
        }
        investmentAsk
        investmentRequired
        investmentStatus
        cashInBank
        cashBackedServices
        preOrPostFees
        cashForBusiness
        status
        type
        longstopDate
        investmentStage
        investmentRound
        startDate
        deploymentShareClass
        externalFunding {
          founders
          additionalInvestors
          total
        }
        fees {
          annualManagement
          investment
        }
        historicInvoice
        service {
          type
          amount
          cashAmount
          cashPercentage
          noOfShare
          pricePerShare
          dayRate
        }
        historicFees {
          annualManagement
          investment
        }
        historicInvestment {
          seis
          eis
          total
        }
      }
    }
  `,
};

export const useGetDeals = () => {
  const res = useQuery<{ deals: BaseDeploymentDeal[] }>(DeploymentDealsQueries.query);
  const data = useMemo(() => res.data && _.cloneDeep(res.data), [res.data]);
  return { ...res, data };
};

export const useDeploymentDeals = () => {
  const { subscribeToMore, ...query } = useGetDeals();

  useEffect(() => {
    subscribeToMore<{ dealConfigs: DealConfig[] }>({
      document: DealConfigsQueries.subscribe,
      updateQuery(prev, { subscriptionData }) {
        const deals = [...prev.deals];
        subscriptionData.data.dealConfigs.forEach((updatedDealConfig) => {
          const dealIdx = deals.findIndex((d) => d._id === updatedDealConfig.deal);
          if (dealIdx === -1) return;
          deals[dealIdx] = _.mergeWith({}, deals[dealIdx], updatedDealConfig, (value1, value2) => {
            if (value2 === null) return value1;
          });
        });
        return { deals };
      },
    });
  }, [subscribeToMore]);

  return query;
};
