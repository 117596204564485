import { Grid, Link, makeStyles, Theme, Typography, useMediaQuery } from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { ConfigContext } from "client/context/config.context";
import clsx from "clsx";
import _ from "lodash";
import React, { ReactNode, useCallback, useContext, useMemo } from "react";
import { TeamMember } from "server/services/fund/fund.types";
import Image from "./Image.component";

interface Props {}

const useStyles = makeStyles(
  (theme) => ({
    0: {
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
    },
    1: {
      color: theme.palette.emerald.main,
      borderColor: theme.palette.emerald.main,
    },
    2: {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
    3: {
      color: theme.palette.emerald.dark,
      borderColor: theme.palette.emerald.dark,
    },
    companyName: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    companyNameMargin: {
      marginLeft: theme.spacing(2),
      [theme.breakpoints.down("md")]: {
        marginLeft: 0,
      },
    },
    description: {
      whiteSpace: "pre-line",
    },
    gridContainer: {
      margin: "unset",
    },
    gridItemContainer: {
      flexDirection: "column",
      // justifyContent: "center",
      "&:not(:first-child) > div:first-child": {
        marginTop: `calc((${theme.typography.body2.lineHeight} * ${theme.typography.body2.fontSize}) + (${theme.typography.body2.fontSize} * 0.3))`,
        [theme.breakpoints.down("md")]: {
          marginTop: `calc((${theme.typography.body2.lineHeight} * ${
            (theme.typography.body2[theme.breakpoints.down("md")] as CSSProperties).fontSize
          }) + (${(theme.typography.body2[theme.breakpoints.down("md")] as CSSProperties).fontSize} * 0.3))`,
        },
      },
    },
    gridItemContent: {
      borderTop: `2px solid`,
      padding: theme.spacing(2),
      overflow: "hidden",
      [theme.breakpoints.down("sm")]: {
        padding: 0,
      },
    },
    gridItemSpacing: {
      "&:not(:first-child)": {
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down("md")]: {
          marginLeft: 0,
        },
      },
    },
    hideText: {
      opacity: 0,
    },
    image: {
      [theme.breakpoints.up("sm")]: {
        maxWidth: "100%",
        width: "auto",
        height: 240,
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        height: "auto",
      },
    },
    placeholderImage: {
      opacity: "30%",
    },
  }),
  { name: "TheTeamComponent" },
);

const TheTeam: React.FC<Props> = (props) => {
  const classes = useStyles(props);
  const { fund, ...config } = useContext(ConfigContext);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const teams = useMemo(() => {
    const theTeam: Array<Omit<TeamMember, "description"> & { description: ReactNode }> = [...fund.informationMemorandum.welcome.team];
    const investmentManagerTeam = [
      {
        company: `Investment Manager - ${fund.manager.companyName}`,
        imgSrc: fund.manager.logo,
        name: fund.manager.name,
        description: (
          <>
            {fund.manager.description}
            <br />
            Further details on {fund.manager.name} can be found on its website:{" "}
            <Link component="a" variant="inherit" href={fund.manager.homepage} rel="noopener noreferrer" target="_blank">
              {fund.manager.homepage.replace(/https:|\/|www\./g, "")}
            </Link>
          </>
        ),
      },
      ...fund.manager.team.map((teamMember) => ({
        company: `Investment Manager - ${fund.manager.companyName}`,
        role: "",
        ...teamMember,
      })),
    ];

    if (fund._id === null) {
      theTeam.push(...investmentManagerTeam);
    } else {
      theTeam.unshift(...investmentManagerTeam);
    }

    return _.groupBy(theTeam, "company");
  }, [fund]);

  const getImgSrc = useCallback(
    (imgSrc?: string | null) => {
      if (imgSrc) return imgSrc;
      if (fund.meta.darkLogo) return config[fund.meta.darkLogo];
      return config.logo;
    },
    [config, fund.meta.darkLogo],
  );

  return (
    <Grid container className={classes.gridContainer}>
      {Object.entries(teams).map(([companyName, teamMembers], companyIndex) =>
        teamMembers.map(({ imgSrc, name, role, description }, index) => (
          <Grid key={index} item xs={isMobile ? 12 : 6} className={classes.gridItemContainer}>
            <Typography
              variant="body2"
              className={clsx(classes.companyName, classes[companyIndex], {
                [classes.companyNameMargin]: companyIndex !== 0,
                [classes.hideText]: index !== 0,
              })}
              gutterBottom
            >
              <strong>{companyName}</strong>
            </Typography>
            <div className={clsx(classes.gridItemContent, classes[companyIndex], { [classes.gridItemSpacing]: index === 0 && companyIndex !== 0 })}>
              <Image
                src={getImgSrc(imgSrc)}
                className={clsx(classes.image, { [classes.placeholderImage]: !imgSrc })}
                style={{ objectFit: `${fund?._id === null ? "cover" : "unset"}` }}
                alt={`${name} - ${role || companyName}`}
              />
              <Typography variant="h2">{name}</Typography>
              {role && (
                <Typography variant="body1" gutterBottom>
                  {role}
                </Typography>
              )}
              <Typography variant="body2" align="justify" className={classes.description} paragraph>
                {description}
              </Typography>
            </div>
          </Grid>
        )),
      )}
    </Grid>
  );
};

export default TheTeam;
