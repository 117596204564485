import { Button, Dialog, DialogActions, DialogContent, LinearProgress, TextField } from "@material-ui/core";
import { ColumnDefinition, OnChangeObject, TableCellEditHandler } from "@wearenova/mui-data-table";
import { downloadAdvancedAssurance, getCompanyValuationAtDate } from "client/api/admin/companies.adminApi";
import {
  downloadShareCertificate,
  downloadTaxCertificateEvidence,
  getAllShareTransactions,
  getCSVExport,
  getCSVExportLink,
  getShareTransaction,
  saveShareTransaction,
} from "client/api/admin/shareTransactions.adminApi";
import DropDownButton from "client/components/DropDownButton.component";
import { AdminContext, ADMIN_TYPES } from "client/context/admin.context";
import { ConfigContext } from "client/context/config.context";
import { useAllFunds, useAllPortfolios } from "client/hooks/adminContext.hooks";
import { SHARE_TRANSACTION_STATUSES, USER_ROLE } from "client/utils/constants";
import { formatThousands, xlsxAutofitColumns } from "client/utils/helpers";
import notifications from "client/utils/notifications";
import _ from "lodash";
import moment from "moment";
import { LeanDocument } from "mongoose";
import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { CompanyValuation } from "server/services/company/company.types";
import { AdminShareTransaction, FullyPopulatedShareTransaction } from "server/services/shareTransaction/shareTransaction.types";
import { titleCase } from "title-case";
import AdminTable from "../AdminTable.view";
import { useStyles } from "../useStyles.hook";
import { AuthContext } from "../../../context/auth.context";
import BulkUpdateForm from "./BulkUpdateForm.view";
import DisplayLink from "./DisplayLink.view";
import ShareTransactionDialog from "./ShareTransactionDialog.view";
import { INVESTEE_CATEGORIES } from "./utils";
import ValuationReportForm, { ValuationReportSection, ValuationReportValues } from "./ValuationReportForm.view";

interface Props {
  readOnly?: boolean;
}

const EMPTY_VALUATION_DATA: CompanyValuation = {
  _id: "",
  shareClasses: [],
  valuation: "N/A",
};

const MISSING_PORTFOLIO_NAME = "(Missing Portfolio Name)";
const VALUATION_ENDPOINT_DATE_FORMAT = "YYYY-MM-DD";

const ShareTransactionTab: FC<Props> = ({ readOnly, ...props }) => {
  const classes = useStyles(props);
  const { shareTransactions, dispatch } = useContext(AdminContext);
  const {
    state: { user },
  } = useContext(AuthContext);
  const { fund } = useContext(ConfigContext);
  const allPortfolios = useAllPortfolios();
  const allFunds = useAllFunds();
  const [addShareTransaction, setAddShareTransaction] = useState(false);
  const [selectedShareTransaction, setSelectedShareTransaction] = useState<LeanDocument<FullyPopulatedShareTransaction> | null>(null);
  const [csvExportLink, setCSVExportLink] = useState<string | null>(null);
  const [count, setCount] = useState(-1);
  const [selectedRows, setSelectedRows] = useState<AdminShareTransaction[]>([]);
  const [selectedPortfolios, setSelectedPortfolios] = useState<string[]>([]);
  const [bulkAction, setBulkAction] = useState("");
  const [valuationReportLoading, setValuationReportLoading] = useState<boolean>(false);
  const [emailDialogOpen, setEmailDialogOpen] = useState<boolean>(false);
  const [emailDialogLoading, setEmailDialogLoading] = useState<boolean>(false);
  const [tableEditData, setTableEditData] = useState();

  // Set the portfolios selected (used in Valuation Report Forn)
  useEffect(() => {
    setSelectedPortfolios(
      _.uniq(selectedRows.map((row) => row.portfolio?.name || MISSING_PORTFOLIO_NAME)).filter((portfolio) => portfolio !== MISSING_PORTFOLIO_NAME),
    );
  }, [selectedRows]);

  const setHoldings = useCallback(
    async (onChangeObject: OnChangeObject, isExport: boolean) => {
      const res = await getAllShareTransactions(onChangeObject);
      if (!res) return [];
      if (isExport) return res.data;
      dispatch({ type: ADMIN_TYPES.SET_SHARE_TRANSACTIONS, payload: res.data });
      setCount(res.count);
      return res.data;
    },
    [dispatch],
  );

  const setShareTransaction = useCallback(async (st: AdminShareTransaction) => {
    const shareTransaction = await getShareTransaction(st._id);
    setSelectedShareTransaction(shareTransaction);
  }, []);

  const portfolioOptions = useMemo(
    () => allPortfolios.map((portfolio) => ({ label: portfolio.name, value: String(portfolio._id) })),
    [allPortfolios],
  );
  const fundOptions = useMemo(() => [...allFunds.map((f) => f.manager.companyName), "Sapia Partners LLP"], [allFunds]);

  const shareTransactionsTable = useMemo<ColumnDefinition<AdminShareTransaction>[]>(
    () =>
      [
        {
          key: "project",
          title: "Project",
          dataIndex: "company.projectName",
          sorter: true,
          filterColumn: true,
          pinnable: true,
        },
        {
          key: "companyName",
          title: "Company Name",
          dataIndex: "company.companyName",
          sorter: true,
          filterColumn: true,
          pinnable: true,
        },
        {
          key: "companyNumber",
          title: "Company Number",
          dataIndex: "company.companyNumber",
          sorter: true,
          filterColumn: true,
          pinnable: true,
        },
        {
          key: "portfolio",
          title: "Portfolio",
          dataIndex: "portfolio.name",
          sorter: true,
          filterColumn: true,
          pinnable: true,
          editable: {
            path: "portfolio",
            type: "select",
            selectOptions: portfolioOptions,
            defaultValue: "",
          },
        },
        {
          key: "deal",
          title: "Deal",
          dataIndex: "company.dealDetails.dealName",
          sorter: true,
          filterColumn: true,
          pinnable: true,
          editable: {
            path: "deal",
            type: "select",
            selectOptions: (row) =>
              row.company.deals
                ?.filter((deal) => deal.investmentRequired)
                .map((deal) => ({ label: `${deal.investmentStage} - ${deal.dealNumber}`, value: deal._id })) || [],
            defaultValue: "",
          },
        },
        {
          key: "investor",
          title: "Investor",
          dataIndex: "topLevelInvestor",
          sorter: true,
          filterColumn: true,
          pinnable: true,
          editable: true,
        },
        {
          key: "settleDate",
          title: "Settle Date",
          dataType: "date",
          dataIndex: "settleDateFormatted",
          sorter: "settleDate",
          filterColumn: "settleDate",
          editable: "settleDate",
          pinnable: true,
        },
        {
          key: "tradeDate",
          title: "Trade Date",
          dataType: "date",
          dataIndex: "tradeDateFormatted",
          sorter: "tradeDate",
          filterColumn: "tradeDate",
          editable: "tradeDate",
          pinnable: true,
        },
        {
          key: "nominee",
          title: "Nominee",
          dataIndex: "nominee",
          sorter: true,
          filterColumn: true,
          pinnable: true,
          editable: true,
        },
        {
          key: "buySell",
          title: "Buy/Sell",
          dataIndex: "buySell",
          editable: {
            path: true,
            type: "select",
            selectOptions: ["Buy", "Sell"],
          },
        },
        {
          key: "shareClass",
          title: "Share Class",
          dataIndex: "shareClass",
          sorter: true,
          filterColumn: true,
          pinnable: true,
          editable: {
            path: true,
            type: "select",
            selectOptions: (row) => row.company.shareClasses.map((shareClass) => shareClass.name),
          },
        },
        {
          key: "pricePerShare",
          title: "Price Per Share",
          dataType: "number",
          numerical: { path: "pricePerShare", currency: true, minDecimalPlaces: 2, maxDecimalPlaces: 10 },
          sorter: "pricePerShare",
          filterColumn: "pricePerShare",
          editable: "pricePerShare",
          pinnable: true,
        },
        {
          key: "nominalPrice",
          title: "Nominal Price",
          dataType: "number",
          numerical: { path: "nominalValuePerShare", currency: true, minDecimalPlaces: 2, maxDecimalPlaces: 10 },
          sorter: "nominalValuePerShare",
          filterColumn: "nominalValuePerShare",
          editable: "nominalValuePerShare",
          pinnable: true,
        },
        {
          key: "totalInvestment",
          title: "Total Investment",
          numerical: { path: "totalInvestment", currency: true },
          filterColumn: { path: "totalInvestment", type: "number" },
        },
        {
          key: "dealStage",
          title: "Deal Stage",
          dataIndex: "dealStage",
          sorter: true,
          filterColumn: true,
          editable: {
            path: true,
            type: "select",
            selectOptions: ["SEIS", "EIS", "NONE", "INITIAL"],
          },
          pinnable: true,
        },
        {
          key: "company.advancedAssurance",
          title: "(S)EIS Advanced Assurance",
          colGroup: [
            {
              key: "advancedAssurance.date",
              title: "Date",
              render: (record) => _.get(record, `company.advancedAssurance.${record.dealStage?.toLowerCase()}.dateFormatted`),
            },
            {
              key: "advancedAssurance.download",
              title: "Download",
              render: (record, isCSVExport) => {
                if (isCSVExport || !_.get(record, `company.advancedAssurance.${record.dealStage?.toLowerCase()}.key`)) return;
                return (
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      downloadAdvancedAssurance(record.company._id, record.dealStage.toLowerCase());
                    }}
                    variant="text"
                  >
                    Download
                  </Button>
                );
              },
            },
          ],
        },
        {
          key: "investmentRound",
          title: "Investment Round",
          dataType: "number",
          dataIndex: "investmentRound",
          filterColumn: true,
          editable: true,
        },
        {
          key: "totalShares",
          title: "Total Shares",
          render: (record) => (typeof record.totalShares === "undefined" ? null : formatThousands(record.totalShares, 0, false)),
          filterColumn: { path: "totalShares", type: "number" },
        },
        {
          key: "services",
          title: "Services",
          dataType: "number",
          numerical: { path: "services", currency: true },
          filterColumn: "services",
          editable: "services",
        },
        {
          key: "equivalentShares",
          title: "Equivalent Shares",
          dataType: "number",
          render: (record) => (typeof record.equivalentShares === "undefined" ? null : formatThousands(record.equivalentShares, 0, false)),
          filterColumn: "equivalentShares",
          editable: "equivalentShares",
        },
        {
          key: "transactionType",
          title: "Transaction Type",
          dataIndex: "transactionType",
          filterColumn: true,
          editable: {
            path: true,
            type: "select",
            selectOptions: ["Primary", "Secondary", "Share Split", "Transfer", "Reserve", "ASA"],
          },
        },
        {
          key: "longstopDate",
          title: "Long Stop Date",
          dataType: "date",
          dataIndex: "longStopDateFormatted",
          sorter: "longstopDate",
          filterColumn: "longstopDate",
          editable: "longstopDate",
        },
        {
          key: "currency",
          title: "Currency",
          dataIndex: "currency",
          filterColumn: true,
          editable: true,
        },
        {
          key: "investmentManager",
          title: "Investment Manager",
          dataIndex: "investmentManager",
          sorter: true,
          filterColumn: true,
          pinnable: true,
          editable: {
            path: true,
            type: "select",
            selectOptions: fundOptions,
          },
        },
        {
          key: "uir",
          title: "UIR",
          dataIndex: "uir",
          sorter: true,
          filterColumn: true,
          editable: true,
        },
        {
          key: "investeeCategory",
          title: "Investee Category",
          dataIndex: "investeeCategory",
          sorter: true,
          filterColumn: true,
          editable: {
            path: true,
            type: "select",
            selectOptions: INVESTEE_CATEGORIES,
          },
        },
        {
          key: "numberOfInvestors",
          title: "No. Investors",
          dataIndex: "numberOfInvestors",
          sorter: true,
          filterColumn: { path: "numberOfInvestors", type: "number" },
        },
        {
          key: "status",
          title: "Status",
          dataIndex: "status",
          sorter: true,
          filterColumn: true,
          pinnable: true,
          editable: {
            path: true,
            type: "select",
            selectOptions: SHARE_TRANSACTION_STATUSES.map((status) => ({ value: status, label: status })),
          },
        },
        {
          key: "shareCertificate",
          title: "Share Certificate",
          colGroup: [
            {
              key: "shareCertificate.issueDate",
              title: "Date of Issue",
              dataType: "date",
              dataIndex: "shareCertificate.issueDateFormatted",
              sorter: "shareCertificate.issueDate",
              filterColumn: "shareCertificate.issueDate",
              editable: "shareCertificate.issueDate",
            },
            {
              key: "shareCertificate.download",
              title: "Download",
              render: (record, isCSVExport) => {
                if (isCSVExport || !record.shareCertificate?.key) return;
                return (
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      downloadShareCertificate(record._id);
                    }}
                    variant="text"
                  >
                    Download
                  </Button>
                );
              },
            },
          ],
        },
        {
          key: "seis1.sent",
          title: "(S)EIS1 Sent Date",
          dataType: "date",
          dataIndex: "seis1.sentFormatted",
          sorter: "seis1.sent",
          filterColumn: "seis1.sent",
          editable: "seis1.sent",
        },
        {
          key: "seis2",
          title: "(S)EIS2",
          colGroup: [
            {
              key: "seis2.received",
              title: "Received Date",
              dataType: "date",
              dataIndex: "seis2.receivedFormatted",
              sorter: "seis2.received",
              filterColumn: "seis2.received",
              editable: "seis2.received",
            },
            {
              key: "seis2.expected",
              title: "Expected Date",
              dataType: "date",
              dataIndex: "seis2.expectedFormatted",
              sorter: "seis2.expected",
              filterColumn: "seis2.expected",
              editable: "seis2.expected",
            },
            {
              key: "seis2.download",
              title: "Download",
              render: (record, isCSVExport) => {
                if (isCSVExport || !record.seis2?.key) {
                  return;
                }
                return (
                  <Button
                    variant="text"
                    size="small"
                    disabled={!_.get(record, "seis2.key")}
                    onClick={(e) => {
                      e.stopPropagation();
                      downloadTaxCertificateEvidence(_.get(record, "shareTransactionId"));
                    }}
                  >
                    View
                  </Button>
                );
              },
            },
          ],
        },
        {
          key: "notes",
          title: "Notes",
          dataIndex: "notes",
          sorter: true,
          filterColumn: true,
          editable: {
            path: true,
            component: ({ onChange, ...editProps }) => (
              <TextField {...editProps} onChange={(e) => onChange(e.target.value)} variant="standard" multiline />
            ),
          },
        },
      ] as ColumnDefinition<AdminShareTransaction>[],
    [fundOptions, portfolioOptions],
  );

  const getCompanyValuationData = useCallback(async (companyId: string, date: string, valuationType: string, sts: AdminShareTransaction[]) => {
    const response = await getCompanyValuationAtDate(companyId, date);

    /**
     * If we're handling 'new valuation' data, as a safety measure
     * we should return "N/A" if no data is found (see EMPTY_VALUATION_DATA).
     * This should never happen on real/live data, but if it does then we can
     * clearly see where the issue is in the report.
     *
     * If we're handling 'existing valuation' data, we should return
     * the price of the first share transaction for that company that
     * is linked to the fund. As another safety measure, N/A could be returned
     * if all else fails but this should also never happen.
     *
     * See for more details:
     * https://wearenova.jira.com/browse/INV-1463?atlOrigin=eyJpIjoiMzAyMjkzN2YxZmUyNGIzYTg5YjBhOGE4MjM5ZmRiOTEiLCJwIjoiaiJ9
     */
    if (response?.[0]) return response[0];
    if (valuationType.toLowerCase() === "existing") {
      const firstTransaction = _.minBy(sts, "settleDate");
      if (!firstTransaction) return EMPTY_VALUATION_DATA;

      const resolvedResponse = await getCompanyValuationAtDate(companyId, moment(firstTransaction.settleDate).format(VALUATION_ENDPOINT_DATE_FORMAT));

      if (resolvedResponse?.[0]) return resolvedResponse[0];
    }

    return EMPTY_VALUATION_DATA;
  }, []);

  /**
   * Function to handle the generation of XLSX Valuation Reports
   * for Kuber & Mainspring.
   */
  const handleValuationReportExport = useCallback(
    async (values: ValuationReportValues) => {
      try {
        const XLSX = await import("xlsx");
        const workbook = XLSX.utils.book_new();

        const FILENAME = `Valuation_Report.xlsx`;
        const VALUE_FORMAT = { t: "n", z: "£#,##0.00" };

        let errorRetrievingPrices = false;
        setValuationReportLoading(true);

        // Group holdings by their portfolio (if they belong to one)
        const separatedHoldings = Object.values(
          _.groupBy(
            selectedRows.filter((transaction) => _.has(transaction, "portfolio.name")),
            (transaction) => _.get(transaction, "portfolio.name", null),
          ),
        );

        // used to check if a portfolio has a custom set of dates
        const providedPortfolioNames = _.flatMap(_.map(values.valuations, "portfolioNames"));

        // used for subtotal/total prices
        const existingPrices: GenericObject<CompanyValuation> = {};
        const newPrices: GenericObject<CompanyValuation> = {};

        // Iterate over each portfolio, creating a worksheet for each one.
        const sheets = await Promise.all(
          separatedHoldings.map(async (group) => {
            // seperate transactions into arrays for each different company (sorted alphabetically)
            const companies = Object.values(_.groupBy(group, "company.companyName")).sort((a, b) => {
              return a[0].company.companyName.toLowerCase().localeCompare(b[0].company.companyName.toLowerCase());
            });

            const allCompanyPrices: number[] = [];

            let existingDate: string = moment(values.valuations[0].existingValuationDate).format(VALUATION_ENDPOINT_DATE_FORMAT);
            let newDate: string = moment(values.valuations[0].newValuationDate).format(VALUATION_ENDPOINT_DATE_FORMAT);

            const portfolioEntries = await Promise.all(
              companies.map(async (companyTransactionsGroup) => {
                const company = companyTransactionsGroup[0].company;
                const companyName = titleCase(company.companyName.toLowerCase());

                // keep a running total of company prices
                let currentCompanyPrices: {
                  currentPrices: number[];
                  newPrices: number[];
                } = { currentPrices: [], newPrices: [] };

                // check if the current portfolio has a specified set of dates.
                // if not (or if it's the default portfolio), use the default
                // dates specified above,
                const portfolioName = companyTransactionsGroup[0].portfolio?.name || null;
                if (portfolioName && providedPortfolioNames.includes(portfolioName)) {
                  values.valuations.forEach((valuation: ValuationReportSection) => {
                    if (valuation.portfolioNames && valuation.portfolioNames.includes(portfolioName)) {
                      existingDate = moment(valuation.existingValuationDate).format(VALUATION_ENDPOINT_DATE_FORMAT);
                      newDate = moment(valuation.newValuationDate).format(VALUATION_ENDPOINT_DATE_FORMAT);
                    }
                  });
                }

                // retrieve existing & new valuation data for this company
                if (!existingPrices[company._id])
                  existingPrices[company._id] = await getCompanyValuationData(company._id, existingDate, "existing", companyTransactionsGroup);
                const existingValuationData = existingPrices[company._id];
                if (!newPrices[company._id])
                  newPrices[company._id] = await getCompanyValuationData(company._id, newDate, "new", companyTransactionsGroup);
                const newValuationData = newPrices[company._id];

                const thisCompanyData = [...companyTransactionsGroup]
                  .sort(
                    (a, b) =>
                      // sort share transactions by their date (oldest first), then map
                      new Date(a.settleDate).getTime() - new Date(b.settleDate).getTime(),
                  )
                  .map((transaction) => {
                    const securityName = `${transaction.shareClass || "N/A"} Shares NV ${moment(transaction.settleDate).format("DDMMYYYY")}`;

                    let currentPrice = 0;
                    let newPrice = 0;

                    try {
                      currentPrice = existingValuationData.shareClasses.find((sc) => sc.name === transaction.shareClass)!.pricePerShare;
                      newPrice = newValuationData.shareClasses.find((sc) => sc.name === transaction.shareClass)!.pricePerShare;

                      // only include share transactions with valid valuation data in the 'subtotal' / 'grand total' calculations
                      allCompanyPrices.push(currentPrice);
                      currentCompanyPrices.currentPrices.push(currentPrice);
                      currentCompanyPrices.newPrices.push(newPrice);
                    } catch (error: any) {
                      errorRetrievingPrices = true;
                    }

                    return {
                      // future reference: get rid of hardcoded special case for Nova
                      "Fund Manager": fund._id === null ? "Nova (Sapphire Capital Partners LLP)" : fund.manager.companyName,
                      "Issuer Name": companyName,
                      "Security Name (Short Description)": securityName,
                      "Current Price": { v: currentPrice, ...VALUE_FORMAT },
                      "New Price": { v: newPrice, ...VALUE_FORMAT },
                    };
                  });

                // add the subtotal to the company data & return the company data object
                return [
                  ...thisCompanyData,
                  {
                    "Issuer Name": `Subtotal: ${companyName}`,
                    "Current Price": { v: _.mean(currentCompanyPrices.currentPrices) || 0, ...VALUE_FORMAT },
                    "New Price": { v: _.mean(currentCompanyPrices.newPrices) || 0, ...VALUE_FORMAT },
                  },
                ];
              }),
            );

            const portfolioData = [
              ...portfolioEntries.filter(Boolean),
              {
                "Fund Manager": "Grand Total(s):",
                "Current Price": { v: _.mean(allCompanyPrices), ...VALUE_FORMAT },
              },
            ].flat();

            const sheet = xlsxAutofitColumns(portfolioData, XLSX.utils.json_to_sheet(portfolioData), 2);
            const sheetTitle = group[0].portfolio ? group[0].portfolio?.name.trim().slice(0, 30) : MISSING_PORTFOLIO_NAME;

            return { portfolioData, sheet, sheetTitle };
          }),
        );

        // create the individual portfolio sheets
        sheets.forEach(({ sheet, sheetTitle }) => {
          XLSX.utils.book_append_sheet(workbook, sheet, sheetTitle);
        });

        // create the 'All Portfolios' sheet
        const allData = sheets.flatMap(({ portfolioData }) => portfolioData);
        const allPortfoliosSheet = xlsxAutofitColumns(allData, XLSX.utils.json_to_sheet(allData), 2);
        XLSX.utils.book_append_sheet(workbook, allPortfoliosSheet, "All Portfolios");

        XLSX.writeFile(workbook, FILENAME);

        if (errorRetrievingPrices)
          notifications.warning(
            `Couldn't retrieve share prices for some company(s). Valuation data may not exist at the specified dates for these, so their current & new prices will appear as '0' in the report. Their empty values will not be included in the subtotals/grand total.`,
            16,
          );
        notifications.success("Successfully generated valuation report - please check your downloads!", 10);
      } catch (error: any) {
        notifications.error(`Failed to generate valuation report: ${error.message}`, error, 10);
      }

      setValuationReportLoading(false);
    },
    [selectedRows, getCompanyValuationData, fund._id, fund.manager.companyName],
  );

  const handleTableEdit = useCallback<TableCellEditHandler<AdminShareTransaction>>(
    async ({ path, value }, record) => {
      if (path.includes("status") && value === "FINALISED") {
        setTableEditData({ path, value, record });
        setEmailDialogOpen(true);
        return;
      }
      const res = await saveShareTransaction({ [path]: value === "null" ? null : value, doNotSendEmails: true }, record._id);
      if (!res) return;
      dispatch({ type: ADMIN_TYPES.UPDATE_SHARE_TRANSACTIONS, payload: res });
    },
    [dispatch],
  );

  const handleEmailDialog = useCallback(
    async (doNotSendEmails) => {
      if (!tableEditData) return;
      setEmailDialogLoading(true);
      const res = await saveShareTransaction(
        { [tableEditData!.path]: tableEditData.value === "null" ? null : tableEditData.value, doNotSendEmails },
        tableEditData.record._id,
      );

      if (!res) return;
      dispatch({ type: ADMIN_TYPES.UPDATE_SHARE_TRANSACTIONS, payload: res });
      setEmailDialogLoading(false);
      setEmailDialogOpen(false);
    },
    [dispatch, tableEditData],
  );

  return (
    <>
      <AdminTable
        onChange={setHoldings}
        count={count}
        tableData={shareTransactions}
        tableStructure={shareTransactionsTable}
        rowClick={setShareTransaction}
        exportToCSVOption
        csvFilename="ShareTransactions"
        rowsSelectable
        onSelectedRowsChange={setSelectedRows}
        defaultSort={{ key: "settleDate", direction: "desc" }}
        onEdit={handleTableEdit}
      />
      <div className={classes.tableFooter}>
        <Button
          data-testid="addShareTransaction"
          disabled={readOnly || user?.role === USER_ROLE.MANAGER}
          onClick={() => setAddShareTransaction(true)}
        >
          Add Share Transaction
        </Button>

        <DropDownButton
          primaryText="Bulk Actions"
          primaryButtonProps={{ disabled: !selectedRows.length || readOnly || user?.role === USER_ROLE.MANAGER }}
          dropDownItems={[
            { text: "Update Selected", onClick: () => setBulkAction("selected") },
            { text: "Generate Valuation Report", onClick: () => setBulkAction("generateValuationReport") },
          ]}
        />
        <DropDownButton
          primaryText="Export as"
          dropDownItems={[
            {
              text: "CSV Download",
              onClick: () => getCSVExport(),
            },
            {
              text: "Google Sheets Link",
              onClick: async () => {
                const { data } = await getCSVExportLink();
                setCSVExportLink(data);
              },
            },
          ]}
          primaryButtonProps={{ "data-testid": "export-dropdown-button" }}
        />
        <ShareTransactionDialog
          fullWidth
          open={Boolean(addShareTransaction || selectedShareTransaction) && user?.role === USER_ROLE.ADMIN}
          shareTransaction={selectedShareTransaction}
          onClose={() => {
            setAddShareTransaction(false);
            setSelectedShareTransaction(null);
          }}
        />
        <ValuationReportForm
          fullWidth
          open={bulkAction === "generateValuationReport"}
          selectedPortfolios={selectedPortfolios}
          loading={valuationReportLoading}
          onClose={() => {
            setBulkAction("");
          }}
          onSubmit={(values: ValuationReportValues) => {
            handleValuationReportExport(values);
          }}
        />
        <DisplayLink
          fullWidth
          open={Boolean(csvExportLink)}
          link={csvExportLink}
          onClose={() => {
            setCSVExportLink(null);
          }}
        />
        <BulkUpdateForm open={bulkAction === "selected"} shareTransactions={selectedRows} onClose={() => setBulkAction("")} />
      </div>
      <Dialog open={emailDialogOpen}>
        <DialogContent>
          Changing the status to finalised will send an email to investors and advisors notifying them of a completed deployment. Do you want to send
          this email?
          {emailDialogLoading && (
            <>
              <br />
              <br /> Updating transaction... <br /> <br />
              <LinearProgress />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={async () => handleEmailDialog(false)} disabled={emailDialogLoading}>
            Yes
          </Button>
          <Button onClick={async () => handleEmailDialog(true)} disabled={emailDialogLoading}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ShareTransactionTab;
