import { Container, createStyles, makeStyles, Typography } from "@material-ui/core";
import Markdown from "client/components/Markdown.component";
import { ConfigContext } from "client/context/config.context";
import React, { useContext } from "react";
import SectionLetterTypography from "../components/SectionLetterTypography.component";

interface Props {
  isAdvisor?: boolean;
}

const useInternalStyles = makeStyles(
  () =>
    createStyles({
      bannerImage: {
        maxWidth: "100%",
        width: "auto",
        maxHeight: 500,
        height: "auto",
        marginBottom: 50,
      },
    }),
  { name: "SectionAComponent" },
);

const SectionA: React.FC<Props> = ({ isAdvisor, ...props }) => {
  const { fund } = useContext(ConfigContext);
  const classes = useInternalStyles({ ...props, bannerImage: fund.informationMemorandum.welcome.banner });

  return (
    <Container>
      <SectionLetterTypography letter="A">Welcome</SectionLetterTypography>
      <img className={classes.bannerImage} alt="Alistair Welcome" src={fund.informationMemorandum.welcome.banner} width="100%" />
      {!isAdvisor && (
        <Typography variant="h1" gutterBottom>
          <strong>Dear Investor,</strong>
        </Typography>
      )}
      <Markdown>{fund.informationMemorandum.welcome.intro}</Markdown>
    </Container>
  );
};

export default SectionA;
