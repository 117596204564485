import { FC, useState } from "react";
import { Collapse, makeStyles, Typography } from "@material-ui/core";

interface Props {
  title: string;
}

const useStyles = makeStyles(
  (theme) => ({
    itemContainer: {
      display: "block",
      height: "100%",
      color: theme.palette.secondary.contrastText,
    },
    itemHeader: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      cursor: "pointer",
    },
    expandCol: {
      width: "4%",
      [theme.breakpoints.down("xs")]: {
        width: "8%",
      },
    },
    itemContent: {
      display: "block",
      marginLeft: "4%",
      [theme.breakpoints.down("xs")]: {
        marginLeft: "8%",
      },
    },
    itemSeperator: {
      borderColor: theme.palette.secondary.contrastText,
      opacity: ".5",
    },
  }),
  { name: "AccordionItemComponent" },
);

const AccordionItem: FC<Props> = ({ title, children, ...props }) => {
  const classes = useStyles(props);
  const [active, setActive] = useState<boolean>(false);

  return (
    <div className={classes.itemContainer} aria-hidden="true">
      <div className={classes.itemHeader} onClick={() => setActive(!active)} aria-hidden="true">
        <Typography variant="h1" className={classes.expandCol} color="inherit">
          {active ? "-" : "+"}
        </Typography>
        <Typography variant="h3" color="inherit">
          {title}
        </Typography>
      </div>

      <Collapse in={active}>
        <div className={classes.itemContent}>{children}</div>
        <br />
      </Collapse>
      <hr className={classes.itemSeperator} />
    </div>
  );
};

export default AccordionItem;
