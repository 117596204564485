import { ceil } from "client/utils/helpers";
import { BaseDeploymentApplication, FullDeploymentApplication } from "server/services/application/application.types";
import { AdminCompany, BaseDeploymentDeal } from "server/services/company/company.types";
import { FundConfig } from "server/services/deployment/deployment.types";

export const getFundConfig = <T extends "seis" | "eis">(deal: BaseDeploymentDeal, fundId: string | null, dealStage: T): FundConfig[T] => {
  return deal.config[String(fundId)][dealStage];
};

export const getRelevantDeals = (deals: BaseDeploymentDeal[], fundId: string | null, dealStage: "seis" | "eis") =>
  deals.filter((deal) => {
    const fundConfig = getFundConfig(deal, fundId, dealStage);
    return Boolean(deal.shareClassData.pricePerShare) && (fundConfig.weighting > 0 || (fundConfig.amount ?? 0) > 0);
  });

export const getToDeployAmount = ({
  deal,
  fundId,
  dealStage,
  weightedToDeploy,
  totalWeighting,
  company,
}: {
  deal: BaseDeploymentDeal;
  fundId: string | null;
  dealStage: "seis" | "eis";
  weightedToDeploy: number;
  totalWeighting: number;
  company?: AdminCompany;
}) => {
  const fundConfig = getFundConfig(deal, fundId, dealStage);
  const weighting = fundConfig.weighting;
  let amount =
    weighting > 0
      ? ceil(roundTo2DP((weighting * weightedToDeploy) / totalWeighting), deal.shareClassData.pricePerShare || 1)
      : fundConfig.amount ?? 0;
  if (deal.type === "ASA") {
    amount = weighting > 0 ? roundTo2DP((weighting * weightedToDeploy) / totalWeighting) : fundConfig.amount ?? 0;
  }
  return {
    toDeploy: Math.min(amount, deal.investmentRemaining[dealStage], company?.capacityRemaining?.[dealStage] ?? amount),
    weighting,
    fundConfig,
  };
};

export const getRelevantApps = <T extends BaseDeploymentApplication | FullDeploymentApplication>({
  applications,
  fundId,
  dealStage,
}: {
  applications: T[];
  fundId: String | null;
  dealStage: "seis" | "eis";
}) => applications.filter((row) => String(row.fund ?? null) === String(fundId) && row.config.percentToUse[dealStage] > 0);

export const getWeightingValues = ({
  totalToDeploy,
  deals,
  fundId,
  dealStage,
}: {
  totalToDeploy: number;
  deals: BaseDeploymentDeal[];
  fundId: string | null;
  dealStage: "seis" | "eis";
}) => {
  let weightedToDeploy = totalToDeploy;
  let totalWeighting = 0;
  deals.forEach((deal) => {
    const fundConfig = getFundConfig(deal, fundId, dealStage);
    if (!fundConfig.weighting) weightedToDeploy -= fundConfig.amount ?? 0;
    if (fundConfig.weighting) totalWeighting += fundConfig.weighting;
  });

  return { weightedToDeploy: roundTo2DP(weightedToDeploy), totalWeighting };
};

export const roundTo2DP = (value: number) => Math.round(value * 100) / 100;

export const getAmountLeftToDeploy = (row: FullDeploymentApplication, dealStage: "seis" | "eis") => {
  const value = row.toDeploy[dealStage] - row.balances.totalInvested[dealStage];
  return roundTo2DP(value);
};

export const hasMoreThanTwoDecimalPlaces = (number: number) => {
  // Convert the number to a string
  const numberStr = number.toString();

  // Use a regular expression to match decimal places
  const regex = /\.\d{3,}/;
  return regex.test(numberStr);
};
