import { useEffect } from "react";

const useAnalytics = () => {
  const { analytics } = globalThis;
  useEffect(() => {
    if (analytics) {
      analytics.page();
    }
  }, [analytics]);
};

export default useAnalytics;
