import { InputAdornment, StandardTextFieldProps, TextField, TypographyProps } from "@material-ui/core";
import { FieldProps } from "formik";
import _ from "lodash";
import React, { useState } from "react";
import { fromCamelCase } from "../../utils/helpers";
import ErrorMessage from "../ErrorMessage.component";
import QuestionLabel from "../QuestionLabel.component";
import TrackInput from "../TrackInput.component";
import TextHideAdornment from "./TextHideAdornment.component";

interface Props extends FieldProps, StandardTextFieldProps {
  showControls?: boolean;
  inputLabel?: string;
  trackedCategory?: string;
  errorMessageProps?: TypographyProps;
}

const PasswordField: React.FC<Props> = ({
  field,
  form: { touched, errors },
  label,
  inputLabel,
  trackedCategory,
  showControls,
  errorMessageProps = {},
  ...props
}) => {
  const [hidden, setHidden] = useState<boolean>(false);

  return (
    <TrackInput category={trackedCategory} label={fromCamelCase(field.name)} value={field.value}>
      {label && <QuestionLabel>{label}</QuestionLabel>}
      <TextField
        label={inputLabel}
        type={`${hidden && showControls ? "text" : "password"}`}
        error={typeof (_.get(touched, field.name) && _.get(errors, field.name)) === "string"}
        {...field}
        {...props}
        InputProps={{
          ...props.InputProps,
          endAdornment: showControls && (
            <InputAdornment position="end">
              <TextHideAdornment onClick={(e) => setHidden(e.checked)} />
            </InputAdornment>
          ),
        }}
      />
      <ErrorMessage name={field.name} {...errorMessageProps} />
    </TrackInput>
  );
};

export default PasswordField;
