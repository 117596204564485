import { SvgIcon, SvgIconProps } from "@material-ui/core";
import { ReactComponent as CloseIcon } from "client/assets/cancel.svg";
import React, { FC } from "react";

interface Props extends SvgIconProps {}

const Close: FC<Props> = ({ ...props }) => {
  return <SvgIcon {...props} component={CloseIcon} viewBox="0 0 64 64" />;
};

export default Close;
