import { Container, Link, makeStyles, Typography } from "@material-ui/core";
import { Facebook, LinkedIn, Twitter, YouTube } from "@material-ui/icons";
import { ConfigContext } from "client/context/config.context";
import React, { useContext } from "react";

interface Props {}

const useStyles = makeStyles(
  (theme) => ({
    border: {
      borderColor: theme.palette.secondary.light,
      margin: 0,
    },
    footer: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
    footerContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: "column",
      "@media print": {
        display: "none",
      },
    },
    footerText: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    logo: {
      height: "auto",
      width: "20%",
      maxWidth: 250,
      marginBottom: theme.spacing(4),
      [theme.breakpoints.down("sm")]: {
        width: "50%",
      },
    },
    linksContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& > a": {
        lineHeight: 1,
        "&:not(:last-child)": {
          marginRight: theme.spacing(2),
        },
      },
    },
    row: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
      padding: theme.spacing(2.5, 4),
      "& *": {
        ...theme.typography.body3,
      },
    },
    termsAndPrivacyContainer: {
      [theme.breakpoints.up("lg")]: {
        "& > *": {
          padding: theme.spacing(0, 1),
        },
        "& > *:not(:first-child)": {
          borderLeft: `1px solid ${theme.palette.secondary.light}`,
        },
        "& > *:last-child": {
          paddingRight: 0,
        },
      },
      [theme.breakpoints.down("md")]: {
        display: "flex",
        flexDirection: "column",
        "& > *:first-child": {
          marginBottom: theme.spacing(1),
        },
      },
    },
  }),
  { name: "FooterComponent" },
);

const Footer: React.FC<Props> = (props) => {
  const classes = useStyles(props);
  const { logo, logoSecondary, fund } = useContext(ConfigContext);

  return (
    <div className={classes.footer}>
      <hr className={classes.border} />
      <Container className={classes.footerContainer}>
        <img className={classes.logo} src={logoSecondary ?? logo} alt="Logo" />
        <div className={classes.linksContainer}>
          {fund.fundSocialLinks &&
            Object.entries(fund.fundSocialLinks).map(([social, link]) => (
              <Link key={social} href={link} color="inherit" target="blank">
                {social === "facebook" ? (
                  <Facebook />
                ) : social === "twitter" ? (
                  <Twitter />
                ) : social === "linkedIn" ? (
                  <LinkedIn />
                ) : social === "youtube" ? (
                  <YouTube />
                ) : (
                  social
                )}
              </Link>
            ))}
        </div>
      </Container>
      <hr className={classes.border} />

      <div className={classes.row}>
        <Typography color="inherit" className={classes.footerText}>
          © {fund.copyright} {new Date().getFullYear()}
        </Typography>
        <div className={classes.termsAndPrivacyContainer}>
          <Link color="inherit" align="center" href={fund.links.tsAndCs}>
            Terms and Conditions
          </Link>
          <Link color="inherit" align="center" href={fund.links.privacyPolicy}>
            Privacy Policy
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
