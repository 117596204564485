import { Button, makeStyles } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../utils/constants';

const useStyles = makeStyles(theme => ({
  button: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

const AdvisorOnboard = props => {
  const classes = useStyles(props);
  const history = useHistory();

  return (
    <Button
      className={classes.button}
      data-testid="continueToVerification"
      onClick={() => {
        history.push(ROUTES.ADVISOR.ONBOARDING);
      }}
      {...props}
    >
      Continue to verification
    </Button>
  );
};

export default AdvisorOnboard;
