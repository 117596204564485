import { Typography } from "@material-ui/core";
import _ from "lodash";
import React, { Fragment } from "react";

interface ErrorsListProps {
  constant: any;
  errors?: any;
  nested?: string;
}

const ErrorsList: React.FC<ErrorsListProps> = ({ constant, errors = {}, nested = "" }) => {
  const nestedKey = nested ? `${nested}.` : "";

  const renderErrorField = (fieldId: string, value: any) => {
    const label = _.get(constant, `${nestedKey}${fieldId}.label`, fieldId);
    const title = _.get(constant, `${nestedKey}${fieldId}.title`, fieldId);

    if (typeof value === "string" || typeof value === "function") {
      return (
        <>
          {nested ? "- " : ""}
          {label}
        </>
      );
    } else if (Array.isArray(value)) {
      return (
        <Fragment>
          {value.map((arrayValue: any, index: number) => (
            <Fragment key={`${fieldId}-${index}`}>
              <Typography component="div" variant="inherit" color="inherit" gutterBottom>
                {title}&nbsp;{index + 1}
              </Typography>
              <ErrorsList constant={constant} errors={arrayValue} nested={`${nestedKey}${fieldId}`} />
            </Fragment>
          ))}
        </Fragment>
      );
    } else {
      return (
        <>
          <Typography component="div" variant="inherit" color="inherit" gutterBottom>
            {title}
          </Typography>
          <ErrorsList constant={constant} errors={value} nested={`${nestedKey}${fieldId}`} />
        </>
      );
    }
  };

  return (
    <>
      {Object.entries(errors).map(([fieldId, value]) => (
        <Typography
          key={fieldId}
          component="div"
          variant="body2"
          color="error"
          paragraph={!nested}
          gutterBottom={Boolean(nested)}
          className="fs-exclude"
        >
          {renderErrorField(fieldId, value)}
        </Typography>
      ))}
    </>
  );
};

export default ErrorsList;
