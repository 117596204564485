import { Avatar, Button, Container, IconButton, Link, makeStyles, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import FaceIcon from "@material-ui/icons/Face";
import Divider from "client/components/Divider.component";
import CompanyLinkList from "client/components/Lists/CompanyLinkList.component";
import clsx from "clsx";
import moment from "moment";
import { FC, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import Linkify, { Props as LinkifyProps } from "react-linkify";
import ReactPlayer from "react-player/lazy";
import { animateScroll } from "react-scroll";
import { CompanyWithHoldings } from "server/services/company/company.types";
import { FormattedHolding, Holding } from "server/services/shareTransaction/shareTransaction.types";
import CompanyHoldingCard from "./CompanyHoldingCard.component";
import DocumentsForDownload from "./DocumentsForDownload.view";
import SectorDetails from "./SectorDetails.component";
import { roundTo2DP } from "client/views/AdminPanel/Deployments/utils";
import DissolvedWarning from "client/components/DissolvedWarning.component";

interface Props {
  company: CompanyWithHoldings;
  onBackClick: () => any;
}

const useStyles = makeStyles(
  (theme) => ({
    container: {
      display: "flex",
      flexFlow: "column wrap",
    },
    aboutSection: {
      display: "flex",
      justifyContent: "space-between",
    },
    buttonContainer: {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "flex",
      },
    },
    row: {
      display: "flex",
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        paddingBottom: theme.spacing(1),
      },
    },
    mediaContainer: {
      display: "flex",
      flex: "1",
      minHeight: "150px",
      [theme.breakpoints.up("sm")]: {
        minHeight: "241px",
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: theme.spacing(4),
      },
    },
    iconContainer: {
      position: "absolute",
    },
    icon: {
      alignSelf: "flex-start",
      position: "relative",
      left: -theme.spacing(6),
      [theme.breakpoints.down("lg")]: {
        left: -theme.spacing(3),
      },
    },
    logoBackground: {
      display: "flex",
      flex: "1",
      alignItems: "center",
      justifyContent: "center",
    },
    logo: {
      maxWidth: "200px",
      maxHeight: "300px",
      [theme.breakpoints.only("sm")]: {
        maxWidth: "150px",
      },
      [theme.breakpoints.up("lg")]: {
        maxWidth: "300px",
      },
    },
    videoContainer: {
      display: "flex",
      width: "100%",
      maxHeight: "241px",
      [theme.breakpoints.down("lg")]: {
        marginLeft: theme.spacing(3),
      },
      [theme.breakpoints.up("md")]: {
        minWidth: "300px",
      },
      [theme.breakpoints.up("sm")]: {
        minWidth: "200px",
      },
    },
    toUpperCase: {
      textTransform: "uppercase",
    },
    companyOverview: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
      },
    },
    companyName: {
      display: "flex",
      justifyContent: "space-between",
    },
    companySector: {
      backgroundColor: theme.palette.primary.main,
      padding: theme.spacing(1, 2),
      width: "fit-content",
    },
    socialContainer: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
    },
    valuationContainer: {
      marginBottom: theme.spacing(4),
      // background: theme.palette.grey.main,
      borderBottom: `${theme.spacing(0.375)}px solid ${theme.palette.common.black}`,
      padding: theme.spacing(3),
      [theme.breakpoints.down("sm")]: {
        borderBottom: "none",
      },
    },
    founderContainer: {
      marginBottom: theme.spacing(4),
      [theme.breakpoints.down("sm")]: {
        marginBottom: theme.spacing(2),
      },
    },
    founderImage: {
      height: "28px",
      width: "28px",
      "&:hover": {
        cursor: "zoom-in",
      },
    },
    founderImageLarge: {
      height: "48px",
      width: "48px",
      "&:hover": {
        cursor: "zoom-out",
      },
    },
    founderDetails: {
      display: "flex",
      alignItems: "center",
    },
    founderPic: {
      marginRight: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    nameContainer: {
      marginBottom: theme.spacing(4),
      [theme.breakpoints.down("sm")]: {
        marginBottom: theme.spacing(2),
      },
    },
    holdingsContainer: {
      background: theme.palette.grey.main,
    },
    holdingCard: {
      "&:not(:last-child)": {
        marginBottom: theme.spacing(3),
      },
    },
    linkContainer: {
      [theme.breakpoints.down("sm")]: {
        marginBottom: theme.spacing(2),
      },
    },
    descriptionTitle: {
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    companyDetail: {
      flex: "1",
      marginLeft: theme.spacing(3),
      whiteSpace: "pre-wrap",
      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(0, 0, 4, 0),
      },
      [theme.breakpoints.only("sm")]: {
        flex: "1.5",
      },
    },
    updateContainer: {
      "& > *": {
        margin: theme.spacing(3, 0),
      },
    },
    updateText: {
      whiteSpace: "pre-line",
    },
  }),
  { name: "CompanyInfoView" },
);

const CompanyInfo: FC<Props> = ({ company, onBackClick, ...props }) => {
  const classes = useStyles(props);
  const [selectedHolding, setSelectedHolding] = useState<CompanyWithHoldings | null>(null);
  const [founderImageExpanded, setFounderImageExpanded] = useState<boolean>(false);

  useEffect(() => {
    animateScroll.scrollToTop({
      duration: 0,
    });
  }, []);

  const handleMultipleHoldingsOnSameDate = (existingObject: FormattedHolding, currentHolding: Omit<Holding, "company">) => {
    //if both have same price per share then sum the data of both holdings into single holding
    if (roundTo2DP(existingObject.pricePerShare || 0) === roundTo2DP(currentHolding.pricePerShare || 0)) {
      // If an object with the same settle date exists, add the current item as otherHoldings
      existingObject.noOfShares = (existingObject.noOfShares || 0) + (currentHolding.noOfShares || 0);
      existingObject.totalCost += currentHolding.cost;
      existingObject.totalValue += currentHolding.value;
      existingObject.growth = existingObject.totalValue - existingObject.totalCost;
    } else {
      //if price per share is different but holding belongs to same date then add the subsequent holdings as the sub array of the existing holding
      if (!existingObject.otherHoldings) {
        existingObject.otherHoldings = [];
      }
      existingObject.otherHoldings.push({
        ...currentHolding,
        type: "holding",
        totalCost: currentHolding.cost,
        totalValue: currentHolding.value,
        growth: currentHolding.value - currentHolding.cost,
      });
    }
  };

  const formattedHoldingsGroupByDate = useMemo<FormattedHolding[]>(() => {
    const groupedHoldings = company.holdings.reduce<FormattedHolding[]>((holdings: FormattedHolding[], currentHolding: Omit<Holding, "company">) => {
      const settleDate = currentHolding.settleDate;
      const dealStage = currentHolding.dealStage;

      const existingObject = holdings.find((obj) => obj.settleDate === settleDate && obj.dealStage === dealStage);

      if (existingObject && existingObject?.seis3?.key === currentHolding?.seis3?.key) {
        handleMultipleHoldingsOnSameDate(existingObject, currentHolding);
      } else {
        // If no holding with the same settle date exists, create a new holding object
        holdings.push({
          ...currentHolding,
          type: "holding",
          totalCost: currentHolding.cost,
          totalValue: currentHolding.value,
          growth: currentHolding.value - currentHolding.cost,
          otherHoldings: [],
        });
      }

      return holdings;
    }, []);

    // Sort by settleDate in descending order
    const sortedGroupedHoldings = groupedHoldings.sort((a, b) => new Date(b.settleDate).getTime() - new Date(a.settleDate).getTime());

    return sortedGroupedHoldings;
  }, [company.holdings]);

  const companyUpdates = useMemo(
    () => company.updates?.map((update) => ({ ...update, date: moment(update.date) })).sort((a, b) => a.date.valueOf() - b.date.valueOf()),
    [company.updates],
  );

  const filteredTeam = useMemo(() => company.team.filter((member) => Boolean(member.name)), [company.team]);

  const getLink = useCallback<Required<LinkifyProps>["componentDecorator"]>(
    (href, text) => (
      <Link href={href} target="_blank" rel="noopener noreferrer">
        {text}
      </Link>
    ),
    [],
  );

  const handleDocumentsClick = useCallback((holding: FormattedHolding) => setSelectedHolding({ ...company, holdings: [holding] }), [company]);

  const handleDocumentsForDownloadClose = useCallback(() => setSelectedHolding(null), []);

  return (
    <>
      <Container maxWidth="lg" className={classes.container}>
        <div className={classes.row}>
          {(company.video?.length || company.companyLogo) && (
            <div className={classes.mediaContainer}>
              <div className={classes.iconContainer}>
                <IconButton onClick={onBackClick} color="secondary" className={classes.icon}>
                  <ArrowBackIcon />
                </IconButton>
              </div>
              {!company.video?.length ? (
                <div className={classes.logoBackground}>
                  <img src={company.companyLogo || undefined} alt="company logo" className={classes.logo} />
                </div>
              ) : (
                <div className={classes.videoContainer}>
                  <ReactPlayer
                    width="100%"
                    height="100%"
                    url={company.video[0].url}
                    onStart={() =>
                      globalThis.dataLayer.push({ event: "company video link clicked", eventProps: { companyName: company.companyName } })
                    }
                  />
                </div>
              )}
            </div>
          )}

          <div className={classes.companyOverview}>
            <div>
              {!company.video?.length && !company.companyLogo && (
                <IconButton onClick={onBackClick} color="secondary" className={classes.icon}>
                  <ArrowBackIcon />
                </IconButton>
              )}
              <div className={classes.companyName}>
                <Typography gutterBottom variant="h1" className={classes.toUpperCase}>
                  {company.projectName || company.companyName}
                </Typography>
                {company.isDissolved && <DissolvedWarning companyName={company.companyName || company.projectName} />}
              </div>

              <Typography paragraph variant="body1">
                {company.headline}
              </Typography>
            </div>
          </div>
        </div>
        <div className={classes.valuationContainer}>
          {/* <Typography variant="body1" component="div" align="center">
            Valuation:&nbsp;
            <Typography variant="h3" component="span">
              {company.shareClasses[0].pricePerShare != null ? formatThousands(company.companyValuation, 2) : "Not Available"}
            </Typography>
          </Typography> */}
        </div>
        <div className={classes.row}>
          <div className={classes.socialContainer}>
            {company.projectName && (
              <div className={classes.nameContainer}>
                <Typography variant="h3" paragraph>
                  COMPANY NAME
                </Typography>
                <Typography variant="h4" paragraph>
                  {company.companyName}
                </Typography>
              </div>
            )}
            <div className={classes.founderContainer}>
              <Typography variant="h3" paragraph>
                FOUNDER{filteredTeam?.length > 1 ? "S" : ""}
              </Typography>
              {filteredTeam.map((teamObj, index) => (
                <Typography key={index} paragraph className={classes.founderDetails} variant="h4">
                  {teamObj.image ? (
                    <Avatar
                      src={teamObj.image}
                      alt={teamObj.name}
                      onClick={() => setFounderImageExpanded(!founderImageExpanded)}
                      className={clsx(classes.founderImage, { [classes.founderImageLarge]: founderImageExpanded })}
                    />
                  ) : (
                    <FaceIcon className={classes.founderPic} />
                  )}
                  &nbsp;
                  {teamObj.name}
                </Typography>
              ))}
            </div>

            {!filteredTeam?.some((teamMember) => teamMember.profileUrl) &&
            !company.websiteLink &&
            !Object.values(company.socials || {}).length ? null : (
              <div className={classes.linkContainer}>
                <Typography variant="h3" paragraph>
                  LINKS
                </Typography>
                {filteredTeam.map((teamObj, index) => (
                  <Fragment key={index}>
                    {teamObj.profileUrl && (
                      <Link
                        variant="body2"
                        paragraph
                        onClick={() =>
                          globalThis.dataLayer.push({
                            event: "company founder profile link clicked",
                            eventProps: { companyName: company.companyName, founderName: teamObj.name },
                          })
                        }
                        href={teamObj.profileUrl}
                        display="block"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {teamObj.name}&apos;s profile
                      </Link>
                    )}
                  </Fragment>
                ))}
                <CompanyLinkList company={company} exclude={["video"]} paragraph variant="body2" renderWebsitesTruthfully />
              </div>
            )}
          </div>
          <div className={classes.companyDetail}>
            <Typography variant="h3" paragraph className={classes.aboutSection}>
              ABOUT
              <SectorDetails sector={company.sector} />
            </Typography>
            <Typography variant="body2">{company.detailedInfo}</Typography>
          </div>
        </div>
        <Divider size="medium" />
        {!companyUpdates?.length ? null : (
          <div>
            <br />
            <Typography variant="h3" gutterBottom>
              <strong>UPDATES</strong>
            </Typography>
            {companyUpdates.map((update) => (
              <div key={update._id} className={classes.updateContainer}>
                <Typography variant="body2" paragraph>
                  <strong>{update.date.format("MMMM YYYY")}</strong>
                </Typography>
                <Typography variant="body2" className={classes.updateText} paragraph>
                  <Linkify componentDecorator={getLink}>{update.text}</Linkify>
                </Typography>
                <Divider />
              </div>
            ))}
            <Divider size="medium" />
          </div>
        )}
      </Container>
      <div className={classes.holdingsContainer}>
        <Container maxWidth="lg">
          <Typography variant="h3" paragraph>
            YOUR HOLDINGS
          </Typography>
          <br />
          {formattedHoldingsGroupByDate.map((holding) => (
            <CompanyHoldingCard key={holding._id} data={holding} onDocumentsClick={handleDocumentsClick} className={classes.holdingCard} />
          ))}
        </Container>
      </div>
      <Container maxWidth="lg" className={classes.buttonContainer}>
        <Button onClick={onBackClick}>BACK TO PORTFOLIO</Button>
      </Container>
      <DocumentsForDownload company={selectedHolding} onClose={handleDocumentsForDownloadClose} />
    </>
  );
};

export default CompanyInfo;
