import { ConfigContext } from "client/context/config.context";
import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import BasicPageTemplate from "../../components/BasicPageTemplate.component";
import { TRACKED_EVENTS } from "../../utils/constants";

const { PAGE_VIEWS } = TRACKED_EVENTS;

const QualificationComplete: React.FC = () => {
  const history = useHistory();
  const { imDefaultPath } = useContext(ConfigContext);

  useEffect(() => {
    globalThis.dataLayer.push({ event: PAGE_VIEWS.INVESTOR_APPLICATION_QUALIFIED });
  }, []);

  return (
    <BasicPageTemplate
      title="Thank you"
      body={`
        You meet the guidelines set by the FCA for this type of security,
        and you are now qualified to view the rest of our content
      `}
      onClick={() => history.push(imDefaultPath)}
      buttonText="Continue"
    />
  );
};

export default QualificationComplete;
