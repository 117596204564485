import { Button, CircularProgress, Container, createStyles, makeStyles, Typography } from "@material-ui/core";
import useToggle from "client/hooks/useToggle.hook";
import jwt from "jsonwebtoken";
import React, { useEffect, useMemo, useState } from "react";
import { LeanUser } from "server/services/user/user.types";
import PublicAuthenticationApi from "../api/public/authentication.publicApi";
import PublicUserApi from "../api/public/user.publicApi";
import useAnalytics from "../hooks/useAnalytics";
import useQuery from "../hooks/useQuery.hook";
import { USER_TYPES } from "../utils/constants";
import RegisterForm from "./LoginRegister/Forms/RegisterForm.component";

interface Props {
  forgottenPassword?: boolean;
}

const useStyles = makeStyles(
  () =>
    createStyles({
      spinner: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
    }),
  { name: "LinkRegistrationView" },
);

const LinkRegistration: React.FC<Props> = (props) => {
  const classes = useStyles(props);
  const [uid, aId, advised, applicationId] = useQuery(["uid", "aId", "advised", "applicationId"]);
  const [loading, , , doneLoading] = useToggle(true);
  const [advisedClient, setAdvisedClient] = useState<string | null>(null);
  const [user, setUser] = useState<LeanUser | null>(null);

  const [resendClicked, setResendClicked] = useState(false);
  const userId = useMemo(() => {
    if (!uid) return;
    const decodedToken = jwt.decode(uid);
    if (!decodedToken || typeof decodedToken === "string") return;
    return decodedToken?.data as string;
  }, [uid]);

  useAnalytics();

  useEffect(() => {
    (async () => {
      const res = await PublicUserApi.getUser(uid);
      if (res) {
        setUser(res);
        setAdvisedClient(advised);
      }
      doneLoading();
    })();
  }, [advised, doneLoading, uid]);

  return (
    <Container maxWidth={user ? "xs" : "md"}>
      {loading ? (
        <div className={classes.spinner}>
          <CircularProgress size="3rem" />
          <br />
          <Typography>Please wait....</Typography>
        </div>
      ) : user ? (
        <RegisterForm
          forgottenPassword={props.forgottenPassword}
          advisedClient={advisedClient}
          applicationId={aId || applicationId}
          userId={user._id}
          userType={user.advisor ? USER_TYPES.ADVISED_INVESTOR : user.advisorDetails ? USER_TYPES.ADVISOR : USER_TYPES.DIRECT_INVESTOR}
          email={user.email}
          forenames={user.forenames}
          surname={user.surname}
        />
      ) : (
        <>
          <Typography variant="h1" align="center" gutterBottom>
            Your link has expired
          </Typography>
          <Typography variant="body1" align="center" gutterBottom>
            For security reasons our forgot password links expire after 24 hours, please select the button below for a new link to be sent to your
            email.
          </Typography>
          <Button
            onClick={async () => {
              setResendClicked(true);
              const res = await PublicAuthenticationApi.resendForgotPasswordLink(userId);
              if (!res) {
                setResendClicked(false);
              }
            }}
            disabled={resendClicked}
            fullWidth
          >
            Resend Link
          </Button>
        </>
      )}
    </Container>
  );
};

export default LinkRegistration;
