import { Container, FormGroup, makeStyles, Typography } from "@material-ui/core";
import ApplicationApi from "client/api/application.api";
import FormikErrorsTouched from "client/components/FormikErrorsTouched.component";
import clsx from "clsx";
import { Field, FieldArray, Form, Formik } from "formik";
import _ from "lodash";
import React, { FC, useCallback, useContext, useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import type { Address, LeanApplication, PersonalDetails as IPersonalDetails } from "server/services/application/application.types";
import { UserStateClient } from "server/services/user/user.types";
import * as Yup from "yup";
import * as AdminApplicationApi from "../../api/admin/application.adminApi";
import ClientApplicationApi from "../../api/advisor/client/application.clientApi";
import ErrorsList from "../../components/ErrorsList.component";
import CheckboxQuestion from "../../components/FormControls/CheckboxQuestion.component";
import DatePickerField from "../../components/FormControls/DatePickerField.component";
import NumberQuestion from "../../components/FormControls/NumberQuestion.component";
import PostcodeLookupField from "../../components/FormControls/PostcodeLookupField.component";
import RadioQuestion from "../../components/FormControls/RadioQuestion.component";
import TrackedSelectField from "../../components/FormControls/SelectField.component";
import TrackedTextQuestion from "../../components/FormControls/TextQuestion.component";
import QuestionLabel from "../../components/QuestionLabel.component";
import { APPLICATION_TYPES } from "../../context/application.context";
import { AuthContext } from "../../context/auth.context";
import UserContext from "../../context/user.context";
import useAnalytics from "../../hooks/useAnalytics";
import { FORM_FIELDS_DATA, NATIONALITY_DATA, ROUTES, TRACKED_EVENTS } from "../../utils/constants";
import { InitialValues, NAT_INSUR_REGEX } from "../../utils/helpers";
import StageButtonGroup from "./StageButtonGroup.component";
import useApplication from "./useApplication";

interface Props {
  isAdmin: boolean;
}

const {
  INPUT: {
    CATEGORIES: { PERSONAL_DETAILS },
  },
  PAGE_VIEWS,
  PAGE_ACTIONS,
} = TRACKED_EVENTS;

const { PERSONAL_DETAILS_FORM, ADDRESS_FORM, DUAL_TAX_RESIDENCY_ADDRESS_FORM } = FORM_FIELDS_DATA;

const SCHEMA = Yup.object().shape({
  [PERSONAL_DETAILS_FORM.title.id]: Yup.string().required("Please enter a title"),
  [PERSONAL_DETAILS_FORM.forenames.id]: Yup.string().required("Please enter a forename"),
  [PERSONAL_DETAILS_FORM.surname.id]: Yup.string().required("Please enter a surname"),
  [PERSONAL_DETAILS_FORM.email.id]: Yup.string().email("Please enter a valid email address").required("Please enter your email"),
  [PERSONAL_DETAILS_FORM.dob.id]: Yup.string().typeError("Please enter a date of birth").required("Please enter a date of birth"),
  [PERSONAL_DETAILS_FORM.placeOfBirth.id]: Yup.string().required("Please enter a place of birth"),
  [PERSONAL_DETAILS_FORM.nationality.id]: Yup.string().required("Please enter a nationality"),
  [PERSONAL_DETAILS_FORM.taxResidency.id]: Yup.string().required("Please enter a tax residency"),
  [PERSONAL_DETAILS_FORM.tiNumber.id]: Yup.string().when("taxResidency", {
    is: (v: string) => v === "GB",
    then: Yup.string()
      .matches(NAT_INSUR_REGEX, "Please enter a valid National Insurance number")
      .required("Please enter a National Insurance number"),
    otherwise: Yup.string(),
  }),
  [PERSONAL_DETAILS_FORM.addressHistory.id]: Yup.array(
    Yup.object({
      [PERSONAL_DETAILS_FORM.addressHistory.addressLineOne.id]: Yup.string().required("Please enter an address line"),
      [PERSONAL_DETAILS_FORM.addressHistory.addressLineTwo.id]: Yup.string().notRequired(),
      [PERSONAL_DETAILS_FORM.addressHistory.city.id]: Yup.string().required("Please enter a city"),
      [PERSONAL_DETAILS_FORM.addressHistory.country.id]: Yup.string().required("Please enter a country"),
      [PERSONAL_DETAILS_FORM.addressHistory.postcode.id]: Yup.string().required("Please enter a postcode"),
      [PERSONAL_DETAILS_FORM.addressHistory.lengthYears.id]: Yup.number()
        .typeError("Please enter a number of years")
        .min(0, "Invalid number of years")
        .required("Please enter a number of years"),
      [PERSONAL_DETAILS_FORM.addressHistory.lengthMonths.id]: Yup.number()
        .typeError("Please enter a number of months")
        .min(0, "Invalid number of months")
        .max(11, "Invalid number of months")
        .required("Please enter a number of months"),
    }),
  )
    .min(1)
    .max(3),
  [PERSONAL_DETAILS_FORM.dualTaxResidencyNationality.id]: Yup.string().when("dualTaxResidency", {
    is: true,
    then: Yup.string().required("Please select your second tax residency country"),
    otherwise: Yup.string().notRequired(),
  }),
  [PERSONAL_DETAILS_FORM.dualTaxResidencyTINumber.id]: Yup.string().when("dualTaxResidency", {
    is: true,
    then: Yup.string().required("Please enter a tax identification number"),
    otherwise: Yup.string().notRequired(),
  }),
  [PERSONAL_DETAILS_FORM.dualTaxResidencyAddressHistory.id]: Yup.array().when("dualTaxResidency", {
    is: true,
    then: Yup.array(
      Yup.object({
        [PERSONAL_DETAILS_FORM.dualTaxResidencyAddressHistory.addressLineOne.id]: Yup.string().required("Please enter an address line"),
        [PERSONAL_DETAILS_FORM.dualTaxResidencyAddressHistory.addressLineTwo.id]: Yup.string().notRequired(),
        [PERSONAL_DETAILS_FORM.dualTaxResidencyAddressHistory.city.id]: Yup.string().required("Please enter a city"),
        [PERSONAL_DETAILS_FORM.dualTaxResidencyAddressHistory.country.id]: Yup.string().required("Please enter a country"),
        [PERSONAL_DETAILS_FORM.dualTaxResidencyAddressHistory.postcode.id]: Yup.string().required("Please enter a postcode"),
        [PERSONAL_DETAILS_FORM.dualTaxResidencyAddressHistory.lengthYears.id]: Yup.number()
          .typeError("Please enter a number of years")
          .min(0, "Invalid number of years")
          .required("Please enter a number of years"),
        [PERSONAL_DETAILS_FORM.dualTaxResidencyAddressHistory.lengthMonths.id]: Yup.number()
          .typeError("Please enter a number of months")
          .min(0, "Invalid number of months")
          .max(11, "Invalid number of months")
          .required("Please enter a number of months"),
      }),
    )
      .min(1)
      .max(3)
      .required("Please enter your secondary residence address"),
    otherwise: Yup.array(),
  }),
  [PERSONAL_DETAILS_FORM.usCitizen.id]: Yup.string().required("Please select one option"),
  [PERSONAL_DETAILS_FORM.politicallyExposed.id]: Yup.boolean().required("Please select yes or no"),
  [PERSONAL_DETAILS_FORM.politicallyExposedDetails.id]: Yup.string().when("politicallyExposed", {
    is: true,
    then: Yup.string().required("Please provide full details"),
    otherwise: Yup.string().notRequired(),
  }),
  [PERSONAL_DETAILS_FORM.contactNumber.id]: Yup.string().required("Please enter a contact number"),
});

const BLANK_ADDRESS = {
  [ADDRESS_FORM.addressLineOne.id]: "",
  [ADDRESS_FORM.addressLineTwo.id]: "",
  [ADDRESS_FORM.city.id]: "",
  [ADDRESS_FORM.country.id]: "",
  [ADDRESS_FORM.postcode.id]: "",
  [ADDRESS_FORM.lengthYears.id]: "",
  [ADDRESS_FORM.lengthMonths.id]: "",
} as const;

const DEFAULT_VALUES = {
  [PERSONAL_DETAILS_FORM.title.id]: "",
  [PERSONAL_DETAILS_FORM.forenames.id]: "",
  [PERSONAL_DETAILS_FORM.surname.id]: "",
  [PERSONAL_DETAILS_FORM.email.id]: "",
  [PERSONAL_DETAILS_FORM.dob.id]: null,
  [PERSONAL_DETAILS_FORM.placeOfBirth.id]: "",
  [PERSONAL_DETAILS_FORM.nationality.id]: "GB",
  [PERSONAL_DETAILS_FORM.taxResidency.id]: "GB",
  [PERSONAL_DETAILS_FORM.tiNumber.id]: "",
  [PERSONAL_DETAILS_FORM.dualTaxResidencyTINumber.id]: "",
  [PERSONAL_DETAILS_FORM.dualTaxResidency.id]: false,
  [PERSONAL_DETAILS_FORM.dualTaxResidencyNationality.id]: "",
  [PERSONAL_DETAILS_FORM.tiNumber.id]: "",
  [PERSONAL_DETAILS_FORM.contactNumber.id]: "",
  [PERSONAL_DETAILS_FORM.addressHistory.id]: [BLANK_ADDRESS],
  [PERSONAL_DETAILS_FORM.dualTaxResidencyAddressHistory.id]: [],
  [PERSONAL_DETAILS_FORM.usCitizen.id]: "",
  [PERSONAL_DETAILS_FORM.politicallyExposed.id]: null,
  [PERSONAL_DETAILS_FORM.politicallyExposedDetails.id]: "",
};

const useStyles = makeStyles(
  (theme) => ({
    alignRight: {
      textAlign: "right",
    },
    noMargin: {
      margin: 0,
    },
    noTop: {
      marginTop: 0,
    },
    topMargin: {
      marginTop: "25px",
    },
    topAndBottom: {
      marginTop: "25px",
      paddingBottom: "40px",
    },
    top: {
      marginTop: "25px",
      textAlign: "center",
    },
    formGroup: {
      paddingBottom: theme.spacing(5),
      [theme.breakpoints.down("md")]: {
        paddingBottom: theme.spacing(3),
      },
    },
    formGroupSmall: {
      paddingBottom: "20px",
    },
    sectionTitle: {
      fontWeight: 600,
    },
    hr: {
      borderTop: "solid 1px",
    },
  }),
  { name: "PersonalDetailsView" },
);

const PersonalDetails: FC<Props> = ({ isAdmin, ...props }) => {
  const {
    state: { user },
  } = useContext(AuthContext);
  const { clients } = useContext(UserContext);
  const history = useHistory();
  const { applicationId, clientID } = useParams<{ applicationId?: string; clientID?: string }>();
  const clientId = useMemo(() => user!.isAdvisor && clientID, [clientID, user]);
  const isAdvisor = useMemo(() => Boolean(clientId), [clientId]);
  const classes = useStyles(props);
  const client = useMemo<Partial<UserStateClient>>(() => (clientId && clients[clientId]) || {}, [clientId, clients]);
  const { application, dispatch } = useApplication(isAdmin);

  useAnalytics();

  useEffect(() => {
    globalThis.dataLayer.push({
      event: clientId ? PAGE_VIEWS.CLIENT_APPLICATION_FORM_ABOUT : PAGE_VIEWS.INVESTOR_APPLICATION_ABOUT,
    });
  }, [clientId]);

  const initialValues = useMemo<InitialValues<IPersonalDetails>>(() => {
    const values = application?.data ? { ...DEFAULT_VALUES, ...application.data.personalDetails } : DEFAULT_VALUES;
    if (!clientId && applicationId) return values;
    return {
      ...values,
      email: (clientId ? client.email : user?.email) ?? "",
    };
  }, [application?.data, applicationId, client.email, clientId, user?.email]);

  const isInitialValid = useMemo(() => SCHEMA.isValidSync(initialValues), [initialValues]);

  const goForward = useCallback(() => {
    history.push(`..${ROUTES.APPLICATION_OBJECTIVES}`);
  }, [history]);

  const handleFormSubmit = useCallback(
    async (values) => {
      let submittedApplication: LeanApplication | null = null;
      if (clientId) {
        const res = await ClientApplicationApi.postPersonalDetails(clientId, application!._id, values);
        if (res) {
          submittedApplication = res;
          globalThis.dataLayer.push({ event: PAGE_ACTIONS.CLIENT_APPLICATION_ABOUT_FORM_SUBMITTED_SUCCESSFULLY });
        } else {
          globalThis.dataLayer.push({ event: PAGE_ACTIONS.CLIENT_APPLICATION_ABOUT_FORM_SUBMITTED_UNSUCCESSFULLY });
        }
      } else if (isAdmin) {
        const res = await AdminApplicationApi.postPersonalDetails(applicationId!, values);
        if (res) submittedApplication = res;
      } else {
        const res = await ApplicationApi.postPersonalDetails(application!._id, values);
        if (res) {
          submittedApplication = res;
          globalThis.dataLayer.push({ event: PAGE_ACTIONS.INVESTOR_APPLICATION_FORM_ABOUT_SUBMITTED_SUCCESSFULLY });
        } else {
          globalThis.dataLayer.push({
            event: PAGE_ACTIONS.INVESTOR_APPLICATION_FORM_ABOUT_SUBMITTED_UNSUCCESSFULLY,
          });
        }
      }
      if (submittedApplication) {
        dispatch({ type: APPLICATION_TYPES.SET_APPLICATION, payload: submittedApplication });
        goForward();
      }
    },
    [application, applicationId, clientId, dispatch, goForward, isAdmin],
  );

  return (
    <>
      <Container>
        <FormGroup>
          <Typography variant="h2" align="center">
            Please fill out these questions
          </Typography>
        </FormGroup>
        <FormGroup>
          <Typography className={classes.top} align="center">
            By filling out these questions, it enables us to generate an application form which you can sign and send back to us.
          </Typography>
        </FormGroup>
      </Container>
      <hr className={classes.hr} />
      <Formik
        initialValues={initialValues}
        isInitialValid={isInitialValid}
        enableReinitialize={true}
        validationSchema={SCHEMA}
        onSubmit={handleFormSubmit}
      >
        {({ values, errors, submitCount, setFieldValue }) => (
          <Form autoComplete="off" noValidate className={classes.topMargin}>
            <FormikErrorsTouched />
            <Container maxWidth="md">
              <FormGroup>
                <Typography variant="h4" className={classes.sectionTitle}>
                  Section 1:
                </Typography>
                <Typography variant="h4">About the investor</Typography>
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <Field
                  component={TrackedTextQuestion}
                  name={PERSONAL_DETAILS_FORM.title.name}
                  label={PERSONAL_DETAILS_FORM.title.label}
                  placeholder={PERSONAL_DETAILS_FORM.title.placeholder}
                  trackedCategory={PERSONAL_DETAILS}
                />
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <Field
                  component={TrackedTextQuestion}
                  name={PERSONAL_DETAILS_FORM.forenames.name}
                  label={PERSONAL_DETAILS_FORM.forenames.label}
                  placeholder={PERSONAL_DETAILS_FORM.forenames.placeholder}
                  trackedCategory={PERSONAL_DETAILS}
                />
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <Field
                  component={TrackedTextQuestion}
                  name={PERSONAL_DETAILS_FORM.surname.name}
                  label={PERSONAL_DETAILS_FORM.surname.label}
                  placeholder={PERSONAL_DETAILS_FORM.surname.placeholder}
                  trackedCategory={PERSONAL_DETAILS}
                />
              </FormGroup>
              {initialValues.email ? null : (
                <FormGroup className={classes.formGroup}>
                  <Field
                    component={TrackedTextQuestion}
                    name={PERSONAL_DETAILS_FORM.email.name}
                    label={PERSONAL_DETAILS_FORM.email.label}
                    placeholder={PERSONAL_DETAILS_FORM.email.placeholder}
                    trackedCategory={PERSONAL_DETAILS}
                  />
                </FormGroup>
              )}
              <FormGroup className={classes.formGroup}>
                <Field
                  component={DatePickerField}
                  name={PERSONAL_DETAILS_FORM.dob.name}
                  label={PERSONAL_DETAILS_FORM.dob.label}
                  trackedCategory={PERSONAL_DETAILS}
                />
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <Field
                  component={TrackedTextQuestion}
                  name={PERSONAL_DETAILS_FORM.contactNumber.name}
                  label={PERSONAL_DETAILS_FORM.contactNumber.label}
                  placeholder={PERSONAL_DETAILS_FORM.contactNumber.placeholder}
                  trackedCategory={PERSONAL_DETAILS}
                />
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <Field
                  component={TrackedTextQuestion}
                  name={PERSONAL_DETAILS_FORM.placeOfBirth.name}
                  label={PERSONAL_DETAILS_FORM.placeOfBirth.label}
                  placeholder={PERSONAL_DETAILS_FORM.placeOfBirth.placeholder}
                  trackedCategory={PERSONAL_DETAILS}
                />
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <Field
                  component={TrackedSelectField}
                  name={PERSONAL_DETAILS_FORM.nationality.name}
                  label={PERSONAL_DETAILS_FORM.nationality.label}
                  placeholder={PERSONAL_DETAILS_FORM.nationality.placeholder}
                  options={NATIONALITY_DATA}
                  trackedCategory={PERSONAL_DETAILS}
                />
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <Field
                  component={TrackedSelectField}
                  name={PERSONAL_DETAILS_FORM.taxResidency.name}
                  label={PERSONAL_DETAILS_FORM.taxResidency.label}
                  placeholder={PERSONAL_DETAILS_FORM.taxResidency.placeholder}
                  options={NATIONALITY_DATA}
                  trackedCategory={PERSONAL_DETAILS}
                />
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                {values.taxResidency === "GB" ? (
                  <Field
                    component={TrackedTextQuestion}
                    name={PERSONAL_DETAILS_FORM.tiNumber.name}
                    label={PERSONAL_DETAILS_FORM.niNumber.label}
                    placeholder={PERSONAL_DETAILS_FORM.niNumber.placeholder}
                    trackedCategory={PERSONAL_DETAILS}
                  />
                ) : (
                  <Field
                    component={TrackedTextQuestion}
                    name={PERSONAL_DETAILS_FORM.tiNumber.name}
                    label={PERSONAL_DETAILS_FORM.tiNumber.label}
                    placeholder={PERSONAL_DETAILS_FORM.tiNumber.placeholder}
                    trackedCategory={PERSONAL_DETAILS}
                  />
                )}
              </FormGroup>
              <FieldArray name={PERSONAL_DETAILS_FORM.addressHistory.name}>
                {({ push, pop }) => {
                  const addressHistory: InitialValues<Address[]> = _.get(values, PERSONAL_DETAILS_FORM.addressHistory.name)!;
                  const totalLengthMonths = addressHistory.reduce(
                    (months, address) => months + Number(address.lengthYears) * 12 + Number(address.lengthMonths),
                    0,
                  );

                  const lastLengthMonths =
                    addressHistory.length > 0
                      ? Number(addressHistory[addressHistory.length - 1].lengthYears) * 12 +
                        Number(addressHistory[addressHistory.length - 1].lengthMonths)
                      : 0;

                  if (addressHistory.length === 0) {
                    push(BLANK_ADDRESS);
                  } else if (addressHistory.length < 3 && totalLengthMonths < 60 && lastLengthMonths > 0) {
                    push(BLANK_ADDRESS);
                  } else if (addressHistory.length > 1 && totalLengthMonths - lastLengthMonths >= 60) {
                    pop();
                  }
                  return values.addressHistory!.map((address, index) => {
                    const key = index === 0 ? "currentAddress" : "previousAddress";
                    const { label } = PERSONAL_DETAILS_FORM.addressHistory[key];
                    const name = `${PERSONAL_DETAILS_FORM.addressHistory.name}[${index}]`;
                    const isExtractedAddress = Boolean(
                      _.get(initialValues, `${name}.${PERSONAL_DETAILS_FORM.addressHistory.addressLineOne.name}`) &&
                        typeof _.get(initialValues, `${name}.${PERSONAL_DETAILS_FORM.addressHistory.lengthYears.name}`) !== "number",
                    );
                    return (
                      <React.Fragment key={index}>
                        <FormGroup className={clsx(classes.formGroup, "fs-exclude")}>
                          {index === 1 && (
                            <Typography variant="body1" gutterBottom>
                              As you have lived at your current address for less than 5 years, please also add your previous residential address:
                            </Typography>
                          )}
                          {index > 1 && (
                            <Typography variant="body1" gutterBottom>
                              As you have lived at your previous addresses for less than 5 years, please also add another previous residential
                              address:
                            </Typography>
                          )}
                          <Field
                            component={PostcodeLookupField}
                            name={name}
                            label={label}
                            trackedCategory={PERSONAL_DETAILS}
                            onChange={(selectedAddress: Address) => setFieldValue(name, { ...address, ...selectedAddress })}
                            initialIsManual={isExtractedAddress}
                          />
                        </FormGroup>
                        <FormGroup className={classes.formGroup}>
                          <QuestionLabel>How long have you lived here</QuestionLabel>
                          <FormGroup row className={classes.noMargin}>
                            <Field
                              component={NumberQuestion}
                              name={`${name}.${ADDRESS_FORM.lengthYears.name}`}
                              suffix="Years"
                              trackedCategory={PERSONAL_DETAILS}
                              className={classes.alignRight}
                              min="0"
                              max="99"
                              step="1"
                            />
                            <Field
                              component={NumberQuestion}
                              name={`${name}.${ADDRESS_FORM.lengthMonths.name}`}
                              suffix="Months"
                              trackedCategory={PERSONAL_DETAILS}
                              className={classes.alignRight}
                              min="0"
                              max="11"
                              step="1"
                            />
                          </FormGroup>
                        </FormGroup>
                      </React.Fragment>
                    );
                  });
                }}
              </FieldArray>
              <FormGroup className={classes.formGroup}>
                <Field
                  component={CheckboxQuestion}
                  name={PERSONAL_DETAILS_FORM.dualTaxResidency.name}
                  label={PERSONAL_DETAILS_FORM.dualTaxResidency.label}
                  trackedCategory={PERSONAL_DETAILS}
                />
              </FormGroup>

              {values.dualTaxResidency ? (
                <FormGroup className={classes.formGroup}>
                  <FormGroup className={classes.formGroup}>
                    <Field
                      component={TrackedSelectField}
                      name={PERSONAL_DETAILS_FORM.dualTaxResidencyNationality.name}
                      label={PERSONAL_DETAILS_FORM.dualTaxResidencyNationality.label}
                      placeholder={PERSONAL_DETAILS_FORM.dualTaxResidencyNationality.placeholder}
                      options={NATIONALITY_DATA}
                      trackedCategory={PERSONAL_DETAILS}
                    />
                  </FormGroup>
                  <FormGroup className={classes.formGroup}>
                    <Field
                      component={TrackedTextQuestion}
                      name={PERSONAL_DETAILS_FORM.dualTaxResidencyTINumber.name}
                      label={PERSONAL_DETAILS_FORM.dualTaxResidencyTINumber.label}
                      placeholder={PERSONAL_DETAILS_FORM.dualTaxResidencyTINumber.placeholder}
                      trackedCategory={PERSONAL_DETAILS}
                    />
                  </FormGroup>
                  <FieldArray name={PERSONAL_DETAILS_FORM.dualTaxResidencyAddressHistory.name}>
                    {({ push, pop }) => {
                      const dualTaxResidencyAddressHistory: InitialValues<Address[]> = _.get(
                        values,
                        PERSONAL_DETAILS_FORM.dualTaxResidencyAddressHistory.name,
                      )!;
                      const totalLengthMonths = dualTaxResidencyAddressHistory.reduce(
                        (months, address) => months + Number(address.lengthYears) * 12 + Number(address.lengthMonths),
                        0,
                      );

                      const lastLengthMonths =
                        dualTaxResidencyAddressHistory.length > 0
                          ? Number(dualTaxResidencyAddressHistory[dualTaxResidencyAddressHistory.length - 1].lengthYears) * 12 +
                            Number(dualTaxResidencyAddressHistory[dualTaxResidencyAddressHistory.length - 1].lengthMonths)
                          : 0;

                      if (dualTaxResidencyAddressHistory.length === 0) {
                        push(BLANK_ADDRESS);
                      } else if (dualTaxResidencyAddressHistory.length < 3 && totalLengthMonths < 60 && lastLengthMonths > 0) {
                        push(BLANK_ADDRESS);
                      } else if (dualTaxResidencyAddressHistory.length > 1 && totalLengthMonths - lastLengthMonths >= 60) {
                        pop();
                      }
                      return values.dualTaxResidencyAddressHistory!.map((address, index) => {
                        const key = index === 0 ? "currentAddress" : "previousAddress";
                        const { label } = PERSONAL_DETAILS_FORM.dualTaxResidencyAddressHistory[key];
                        const name = `${PERSONAL_DETAILS_FORM.dualTaxResidencyAddressHistory.name}[${index}]`;
                        return (
                          <React.Fragment key={index}>
                            <FormGroup className={clsx(classes.formGroup, "fs-exclude")}>
                              {index === 1 && (
                                <Typography variant="body1" gutterBottom>
                                  As you have lived at your second address for less than 5 years, please also add your previous residential address:
                                </Typography>
                              )}
                              {index > 1 && (
                                <Typography variant="body1" gutterBottom>
                                  As you have lived at your previous second addresses for less than 5 years, please also add another previous second
                                  residential address:
                                </Typography>
                              )}
                              <Field
                                component={PostcodeLookupField}
                                name={name}
                                label={label}
                                trackedCategory={PERSONAL_DETAILS}
                                onChange={(selectedAddress: Address) => setFieldValue(name, { ...address, ...selectedAddress })}
                              />
                            </FormGroup>
                            <FormGroup className={classes.formGroup}>
                              <QuestionLabel>How long have you lived here?</QuestionLabel>
                              <FormGroup row className={classes.noMargin}>
                                <Field
                                  component={NumberQuestion}
                                  name={`${name}.${DUAL_TAX_RESIDENCY_ADDRESS_FORM.lengthYears.name}`}
                                  suffix="Years"
                                  trackedCategory={PERSONAL_DETAILS}
                                  className={classes.alignRight}
                                  min="0"
                                  max="99"
                                  step="1"
                                />
                                <Field
                                  component={NumberQuestion}
                                  name={`${name}.${DUAL_TAX_RESIDENCY_ADDRESS_FORM.lengthMonths.name}`}
                                  suffix="Months"
                                  trackedCategory={PERSONAL_DETAILS}
                                  className={classes.alignRight}
                                  min="0"
                                  max="11"
                                  step="1"
                                />
                              </FormGroup>
                            </FormGroup>
                          </React.Fragment>
                        );
                      });
                    }}
                  </FieldArray>
                </FormGroup>
              ) : null}
              <FormGroup className={classes.formGroupSmall}>
                <QuestionLabel>
                  If you are a U.S. citizen, or hold a U.S. passport or green card, you will also be considered tax resident in the U.S., even if you
                  live outside the U.S. If you have any concerns regarding your tax residency, please seek advice from a suitably qualified person.
                </QuestionLabel>
              </FormGroup>
              <FormGroup className={classes.formGroupSmall}>
                <Field
                  component={RadioQuestion}
                  name={PERSONAL_DETAILS_FORM.usCitizen.name}
                  label={PERSONAL_DETAILS_FORM.usCitizen.label}
                  trackedCategory={PERSONAL_DETAILS}
                  radioValues={["No", "Yes", "I am a green card holder"]}
                />
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <QuestionLabel>If yes above, please send a copy of your completed Form W-9 with this application.</QuestionLabel>
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <Field
                  component={RadioQuestion}
                  name={PERSONAL_DETAILS_FORM.politicallyExposed.name}
                  label={PERSONAL_DETAILS_FORM.politicallyExposed.label}
                  trackedCategory={PERSONAL_DETAILS}
                />
                {values.politicallyExposed && (
                  <FormGroup className={classes.formGroup}>
                    <Field
                      component={TrackedTextQuestion}
                      multiline
                      name={PERSONAL_DETAILS_FORM.politicallyExposedDetails.name}
                      label={PERSONAL_DETAILS_FORM.politicallyExposedDetails.label}
                      placeholder={PERSONAL_DETAILS_FORM.politicallyExposedDetails.label}
                    />
                  </FormGroup>
                )}
              </FormGroup>

              {submitCount > 0 && Object.keys(errors).length !== 0 && errors.constructor === Object && (
                <FormGroup>
                  <Typography variant="body2" color="error" paragraph>
                    Please complete the following fields:
                  </Typography>
                  <ErrorsList errors={errors} constant={PERSONAL_DETAILS_FORM} />
                </FormGroup>
              )}
              <FormGroup>
                <StageButtonGroup
                  handleFormSubmit={handleFormSubmit}
                  isAdmin={isAdmin}
                  backLink={isAdvisor ? `../apply` : `..${ROUTES.APPLICATION_ID_VERIFICATION}`}
                />
              </FormGroup>
            </Container>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default PersonalDetails;
