import fileDownload from "js-file-download";
import moment from "moment";
import { LeanDocument, UpdateQuery } from "mongoose";
import {
  AdminHolding,
  AdminShareTransaction,
  DbShareTransaction,
  PopulatedShareTransaction,
} from "server/services/shareTransaction/shareTransaction.types";
import notifications from "../../utils/notifications";
import { ipAxios } from "../ipAxios";

export const saveShareTransaction = async (values: UpdateQuery<DbShareTransaction> & { doNotSendEmails?: boolean }, shareTransactionId?: string) => {
  try {
    const data = new FormData();
    data.append("body", JSON.stringify(values));
    if (values.shareCertificate?.key?.constructor === File) {
      const file = values.shareCertificate.key as File;
      data.append("shareCertificate.key", file, file.name);
    }
    if (values.seis2?.key?.constructor === File) {
      const file = values.seis2.key as File;
      data.append("seis2.key", file, file.name);
    }
    values.subscriptions?.forEach((subscription: GenericObject, index: number) => {
      if (subscription.contractNote && subscription.contractNote.constructor === File) {
        data.append(`subscriptions[${index}].contractNote`, subscription.contractNote, subscription.contractNote.name);
      }
      if (subscription.seis3?.key?.constructor === File) {
        data.append(`subscriptions[${index}].seis3.key`, subscription.seis3.key, subscription.seis3.key.name);
      }
    });

    const res = await ipAxios.post<AdminShareTransaction>(`/admin/shareTransactions/${shareTransactionId ?? ""}`, data);
    return res.data;
  } catch (error: any) {
    notifications.error(error.message, error);
    return false;
  }
};

export const getAllShareTransactions = async (onChangeObject: GenericObject) => {
  try {
    const res = await ipAxios.get<AdminShareTransaction[]>(`/admin/shareTransactions/`, { params: onChangeObject });
    return { data: res.data, count: res.headers["x-count"] };
  } catch (error: any) {
    if (error.message !== "duplicate request cancelled") {
      notifications.error(error.message, error);
    }
    return null;
  }
};

export const downloadShareTransactionsData = async () => {
  try {
    const res = await ipAxios({
      url: `/admin/shareTransactions/download`,
      method: "GET",
      responseType: "blob",
    });
    if (res.status === 200) {
      fileDownload(new Blob([res.data]), `shareTransactions-data-${Date.now()}.csv`);
      return { success: true, data: res.data };
    }
    return { success: false };
  } catch (error: any) {
    notifications.genericError(error);
    return { success: false };
  }
};

export const deleteShareTransaction = async (shareTransactionId: string) => {
  try {
    const res = await ipAxios.delete(`/admin/shareTransactions/${shareTransactionId}`);
    return res.data;
  } catch (error: any) {
    notifications.genericError(error);
    return;
  }
};

export const downloadTaxCertificateEvidence = async (shareTransactionId: string) => {
  try {
    const res = await ipAxios({
      url: `/admin/shareTransactions/${shareTransactionId}/certificateEvidence`,
      method: "GET",
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (res.status === 200) {
      fileDownload(new Blob([res.data]), `evidence-${shareTransactionId}.pdf`);
      return { success: true, data: res.data };
    }
    return { success: false };
  } catch (error: any) {
    notifications.genericError(error);
    return null;
  }
};

export const bulkAddShareTransactions = async (values: any[]) => {
  try {
    const res = await ipAxios.post(`/admin/shareTransactions/bulk`, values);
    return res.data;
  } catch (error: any) {
    notifications.error(error.message, error);
    return;
  }
};

export const getShareTransaction = async (shareTransactionId: string) => {
  try {
    const res = await ipAxios.get<LeanDocument<PopulatedShareTransaction>>(`/admin/shareTransactions/${shareTransactionId}`);
    return res.data;
  } catch (error: any) {
    notifications.error(error.message, error);
    return null;
  }
};

export const taxCertificateSigned = async (shareTransactionId: string, subscriptionId: string) => {
  try {
    const res = await ipAxios.post(`/admin/shareTransactions/${shareTransactionId}/subscriptions/${subscriptionId}/pending`);
    return res.data;
  } catch (error: any) {
    notifications.error(error.message, error);
    return null;
  }
};

export const requestTaxCertificateSignatureUrl = async (shareTransactionId: string, sigId: string) => {
  try {
    const res = await ipAxios.get(`/admin/shareTransactions/${shareTransactionId}/signatures/${sigId}/sign`);
    return res.data;
  } catch (error: any) {
    notifications.genericError(error);
    return null;
  }
};

export const getTransactionsPlanner = async (options: GenericObject) => {
  try {
    const res = await ipAxios(`/admin/shareTransactions/planner`, { params: options });
    return res.data;
  } catch (error: any) {
    if (error.message !== "duplicate request cancelled") {
      notifications.error(error.message, error);
    }
    return null;
  }
};

export const createDeploymentDocs = async (shareTransactions: string[]) => {
  try {
    const res = await ipAxios.post<{ link: string } | string>("/admin/shareTransactions/msSheet", shareTransactions);
    if (typeof res.data === "string") {
      fileDownload(new Blob([res.data]), `Actual ${moment().format("MMMM DD YYYY")} Allocation Sheets.zip`);
      return true;
    }
    return res.data.link;
  } catch (error: any) {
    notifications.error(error.message, error);
    return null;
  }
};

export const downloadContractNote = async ({ shareTransactionId, subscriptionId, ...holding }: AdminHolding) => {
  try {
    const res = await ipAxios.get(`/admin/shareTransactions/${shareTransactionId}/${subscriptionId}/contractNote`);
    fileDownload(new Blob([res.data]), `contract-note-${holding.subscription.investor.surname}-${holding.tradeDate}.pdf`);
    return res.data;
  } catch (error: any) {
    notifications.error(error.message, error);
    return null;
  }
};

export const getCSVExport = async () => {
  try {
    const res = await ipAxios({
      url: `/admin/shareTransactions/export`,
      method: "GET",
      responseType: "blob",
    });
    if (res.status === 200) {
      fileDownload(new Blob([res.data]), `share-transactions-data-${Date.now()}.csv`);
      return { success: true, data: res.data };
    }
    return { success: false };
  } catch (error: any) {
    notifications.genericError(error);
    return { success: false };
  }
};

export const getCSVExportLink = async () => {
  try {
    const res = await ipAxios({
      url: `/admin/shareTransactions/export/link`,
      method: "GET",
      responseType: "text",
    });
    if (res.status === 200) {
      return { success: true, data: res.data };
    }
    return { success: false };
  } catch (error: any) {
    notifications.genericError(error);
    return { success: false };
  }
};

/**
 * @param {string} shareTransactionId
 */
export const downloadShareCertificate = async (shareTransactionId: string) => {
  try {
    const res = await ipAxios.get(`/admin/shareTransactions/${shareTransactionId}/shareCertificate`, { responseType: "blob" });
    fileDownload(res.data, `share-certificate-${shareTransactionId}.pdf`);
    return true;
  } catch (error: any) {
    notifications.error(error.message, error);
    return null;
  }
};

export const bulkUpdateShareTransactions = async (updates: GenericObject, shareTransactionIds: string[]) => {
  try {
    const res = await ipAxios.put("/admin/shareTransactions/bulk", { updates, shareTransactionIds });
    return res.data;
  } catch (error: any) {
    notifications.error(error.message);
    return null;
  }
};

export const sendTaxCertificateEmails = async (shareTransactionId: string) => {
  try {
    const res = await ipAxios.put<boolean>(`/admin/shareTransactions/${shareTransactionId}/tax-certificates/send`);
    return res.data;
  } catch (error: any) {
    notifications.error(error.message, error);
    return null;
  }
};

export const regenerateTaxCertificate = async (shareTransactionId: string, subscriptionId: string) => {
  try {
    const res = await ipAxios.put<AdminHolding[]>(
      `/admin/shareTransactions/${shareTransactionId}/subscriptions/${subscriptionId}/tax-certificate/regenerate`,
    );
    return res.data;
  } catch (error: any) {
    notifications.error(error.message, error);
    return null;
  }
};

export const sendRegenTaxCertificateEmail = async (shareTransactionId: string, subscriptionId: string) => {
  try {
    const res = await ipAxios.put<boolean>(`/admin/shareTransactions/${shareTransactionId}/subscriptions/${subscriptionId}/tax-certificate/send`);
    return res.data;
  } catch (error: any) {
    notifications.error(error.message, error);
    return null;
  }
};
