import { FC } from "react";
import { makeStyles } from "@material-ui/core";
import notFound from "../assets/404.svg";

const useStyles = makeStyles((theme) => ({
  image: {
    width: "100%",
    padding: "20px 20%",
    marginBottom: -theme.spacing(2.5),
    backgroundColor: theme.palette.grey.darker,
  },
}),
  { name: "PageNotFoundView" },
);

const PageNotFound: FC = ({ ...props }) => {
  const classes = useStyles(props);
  return <img className={classes.image} alt="404NotFound" src={notFound} />;
};

export default PageNotFound;
