import { gql, useQuery } from "@apollo/client";
import _ from "lodash";
import { useEffect } from "react";
import { BaseDeploymentApplication } from "server/services/application/application.types";
import { AllocationsOptions, LeanApplicationConfig } from "server/services/deployment/deployment.types";
import { AppConfigsQueries } from "./deployment.gqlApi";

export const DeploymentApplicationsQueries = {
  query: gql`
    query GetDeploymentApplications($options: IAllocationsOptions) {
      deploymentApplications(options: $options) {
        _id
        allocations {
          eis
          seis
        }
        amountHeldElsewhere
        balances {
          fundsReceived {
            eis
            received
            seis
            total
          }
          fundsDeployed {
            total
            seis
            eis
            companies
            pendingCompanies
          }
          fundsRemaining {
            eis
            seis
            total
          }
        }
        amlCompleted
        config {
          percentToUse {
            eis
            seis
          }
          excludedCompanies
          spread {
            eis
            seis
          }
        }
        data {
          investmentInformation {
            endOfCancellationPeriod
            carryBack {
              eis
              information
              seis
            }
          }
        }
        fund
        introducer
        investmentManagerApproved
        investor {
          user {
            ... on DirectInvestor {
              _id
              email
              fullName
            }
            ... on FAInvestor {
              _id
              email
              fullName
            }
          }
        }
        mainspringId
        notes
        portfolio {
          _id
          name
          nominee
        }
      }
    }
  `,
};

export const useDeploymentApplications = () => {
  const { subscribeToMore, ...query } = useQuery<{ deploymentApplications: BaseDeploymentApplication[] }, { options?: AllocationsOptions }>(
    DeploymentApplicationsQueries.query,
  );

  useEffect(() => {
    subscribeToMore<{ appConfigs: LeanApplicationConfig[] }>({
      document: AppConfigsQueries.subscribe,
      updateQuery(prev, { subscriptionData }) {
        const applications = [...prev.deploymentApplications];
        subscriptionData.data.appConfigs.forEach((updatedAppConfig) => {
          const appIdx = applications.findIndex((a) => a._id === updatedAppConfig.application);
          if (appIdx === -1) return;
          applications[appIdx] = _.mergeWith({}, applications[appIdx], { config: updatedAppConfig }, (value1, value2) => {
            if (Array.isArray(value1)) return value2;
          });
        });
        return { deploymentApplications: applications };
      },
    });
  }, [subscribeToMore]);

  return query;
};
