import { Button, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(
  theme => ({
    buttonWrapper: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      '& > button': {
        flex: 1,
        margin: theme.spacing(1.25),
      },
    },
  }),
  { name: 'ButtonGroupComponent' },
);

export default ({ isFinish, goBack, onClick, ...props }) => {
  const classes = useStyles(props);
  return (
    <div className={classes.buttonWrapper}>
      <Button variant="outlined" size="large" onClick={goBack}>
        Previous
      </Button>
      <Button data-testid="blueButtonNextFinish" size="large" type="submit" onClick={onClick}>
        {isFinish ? 'Finish' : 'Next'}
      </Button>
    </div>
  );
};
