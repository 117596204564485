import notifications from "client/utils/notifications";
import { UpdateQuery } from "mongoose";
import { LeanFund } from "server/services/fund/fund.types";
import { ipAxios } from "../ipAxios";

/**
 * Retrieve all fund objects from the database.
 *
 * @param query
 * @returns AxiosResponse data
 */

export const getFunds = async (onChangeObject: GenericObject) => {
  try {
    const res = await ipAxios.get<LeanFund[]>("/admin/funds/", { params: onChangeObject });
    return res.data;
  } catch (error: any) {
    notifications.error(error.message, error);
    return null;
  }
};

/**
 * Update the specified fund with the specified values.
 *
 * @param id
 * @param values
 * @returns AxiosResponse data
 */

export const updateFund = async (values: UpdateQuery<LeanFund>, id: string | null) => {
  try {
    const res = await ipAxios.put<LeanFund>(`/admin/funds/${id}`, values);
    return res.data;
  } catch (error: any) {
    notifications.error(error.message);
    return null;
  }
};

/**
 * Delete the specified fund
 *
 * @param id
 * @returns AxiosResponse data
 */

export const deleteFund = async (id: string | null) => {
  try {
    const res = await ipAxios.delete<LeanFund>(`/admin/funds/${id}`);
    return res.data;
  } catch (error: any) {
    notifications.error(error.message);
    return null;
  }
};
