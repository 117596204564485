import { ReturnsCalculatorValues } from "server/services/accounting/accounting.types";
import notifications from "../../utils/notifications";
import { ipAxios } from "../ipAxios";

const getReturns = async (values: any) => {
  try {
    const res = await ipAxios.post<ReturnsCalculatorValues>(`/public/calculator`, values);
    if (res.status === 200) {
      return res.data;
    }
    return null;
  } catch (error) {
    notifications.genericError(error);
    return null;
  }
};

export default {
  getReturns,
};
