import React, { useContext, useEffect, useMemo } from "react";
import { getExpectedPayments } from "../../../api/admin/application.adminApi";
import { AdminContext, ADMIN_TYPES } from "../../../context/admin.context";
import AdminTable from "../AdminTable.view";

const ExpectedPaymentsTab = () => {
  const { expectedPayments, dispatch } = useContext(AdminContext);

  useEffect(() => {
    (async () => {
      const data = await getExpectedPayments();
      if (data) {
        dispatch({ type: ADMIN_TYPES.SET_EXPECTED_PAYMENTS, payload: data });
        return data;
      }
    })();
  }, [dispatch]);

  const expectedPaymentsTable = useMemo(
    () => [
      {
        key: "investorName",
        groupBy: "investor._id",
        title: "Investor Name",
        dataIndex: "investor.fullName",
        sorter: (a, b) => a.investor.fullName.localeCompare(b.investor.fullName),
        filterColumn: true,
      },
      {
        key: "email",
        groupBy: "investor._id",
        title: "Email",
        dataIndex: "investor.email",
        sorter: (a, b) => a.investor.email.localeCompare(b.investor.email),
        filterColumn: true,
      },
      {
        key: "payPeriod",
        title: "Pay Period",
        dataIndex: "payPeriod",
        sorter: (a, b) => a.date - b.date,
        filterColumn: true,
      },
      {
        key: "amount",
        title: "Expected Amount",
        numerical: { path: "amount", currency: true },
        sorter: (a, b) => a.amount - b.amount,
        filterColumn: { path: "amount", type: "number" },
      },
      {
        key: "paid",
        title: "Paid?",
        render: (record) => String(record.paid),
        sorter: (a, b) => Number(a.paid) - Number(b.paid),
        filterColumn: { path: "paid", type: "boolean" },
      },
    ],
    [],
  );

  return <AdminTable tableStructure={expectedPaymentsTable} tableData={expectedPayments} exportToCSVOption csvFilename="ExpectedPayments" />;
};
export default ExpectedPaymentsTab;
