import { Chip, TextField, Typography } from "@material-ui/core";
import { Value } from "@material-ui/lab";
import AutoComplete, { AutocompleteProps, createFilterOptions } from "@material-ui/lab/Autocomplete";
import clsx from "clsx";
import { ErrorMessage, FieldProps } from "formik";
import _ from "lodash";
import React, { PropsWithChildren, ReactNode, useCallback, useMemo } from "react";
import { fromCamelCase } from "../../utils/helpers";
import QuestionLabel from "../QuestionLabel.component";
import TrackInput from "../TrackInput.component";
import ErrorText from "../UIKit/ErrorText.component";

export type SelectOption = string | { label: string; value: string };

interface Props<
  T extends SelectOption,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> extends FieldProps<Value<T, Multiple, DisableClearable, FreeSolo>>,
    Partial<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>> {
  options: T[];
  label?: ReactNode;
  trackedCategory?: string;
  helperText?: ReactNode;
}

const filterOptions = createFilterOptions<any>({
  matchFrom: "any",
  ignoreCase: true,
  stringify: (option) => (typeof option === "object" ? option.label : option),
});

const getLabel = <T extends string | { label: string }>(option: T): string => (option !== null && typeof option === "object" ? option.label : option);
export const getValue = <T extends string | null | { value: string | null }>(option: T) =>
  option !== null && typeof option === "object" ? option.value : option;
const getOptionSelected = <T extends string | { value: string }>(option: T, selected: T) => {
  const optionValue = getValue(option);
  const selectedValue = getValue(selected);
  return optionValue === selectedValue;
};

const SelectField = <
  T extends string | { label: string; value: string },
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>({
  field,
  form: { touched, errors, setFieldTouched, setFieldValue },
  label,
  options,
  placeholder,
  trackedCategory,
  ...props
}: PropsWithChildren<Props<T, Multiple, DisableClearable, FreeSolo>>) => {
  const getMatchingOption = useCallback(
    (val) => _.find(options, (option) => (typeof option === "object" ? option.value === val : option === val)),
    [options],
  );

  const value = useMemo(() => {
    if (Array.isArray(field.value)) {
      return field.value.map((val) => getMatchingOption(val));
    }
    const matchingOption = getMatchingOption(field.value);
    return matchingOption || field.value;
  }, [field.value, getMatchingOption]);

  return (
    <TrackInput category={trackedCategory} label={fromCamelCase(field.name)} value={field.value}>
      {label && <QuestionLabel>{label}</QuestionLabel>}
      <AutoComplete<T, Multiple, DisableClearable, FreeSolo>
        options={options}
        filterOptions={filterOptions}
        value={value as Value<T, Multiple, DisableClearable, FreeSolo>}
        renderInput={(params) => (
          <TextField
            name={field.name}
            placeholder={placeholder}
            error={Boolean(_.get(touched, field.name) && _.get(errors, field.name))}
            helperText={props.helperText}
            {...params}
          />
        )}
        getOptionLabel={getLabel}
        getOptionSelected={getOptionSelected}
        renderOption={(option) => (
          <Typography variant="body2" className="fs-exclude">
            {getLabel(option)}
          </Typography>
        )}
        renderTags={(allSelected, getTagProps) =>
          allSelected.map((selected, index) => {
            const fullOption = options.find((o) => getOptionSelected(selected, o));
            return <Chip key={index} label={fullOption ? getLabel(fullOption) : ""} {...getTagProps({ index })} />;
          })
        }
        onBlur={(e) => {
          setFieldTouched(field.name);
          if (props.freeSolo && field.value !== e.target.value) {
            setFieldValue(field.name, e.target.value);
          }
        }}
        onChange={(_event, selected) => {
          if (selected == null) return setFieldValue(field.name, "");
          if (Array.isArray(selected)) return setFieldValue(field.name, selected.map(getValue));
          setFieldValue(field.name, getValue(selected));
        }}
        {...props}
        className={clsx(props.className, "fs-exclude")}
      />
      <ErrorMessage name={field.name} component={ErrorText} />
    </TrackInput>
  );
};

export default SelectField;
