import React, { FC, useCallback, useContext, useEffect, useMemo } from "react";
import { Container, makeStyles, Tab, Tabs, Typography } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import ClientStatementApi from "../../../api/advisor/client/statement.clientApi";
import AdvisorClientsApi from "../../../api/advisor/clients.advisorApi";
import StatementApi from "../../../api/statement.api";
import UserContext, { UserContextTypes } from "../../../context/user.context";
import useAnalytics from "../../../hooks/useAnalytics";
import { TRACKED_EVENTS } from "../../../utils/constants";
import Applications from "./Applications/Applications.view";
import InvestmentPortfolio from "./Portfolio/InvestmentPortfolio.view";
import Statement from "./Statement/Statement.view";
import useIsAdmin from "client/hooks/useIsAdmin";
import { AuthContext } from "client/context/auth.context";

const useStyles = makeStyles(
  (theme) => ({
    tabsContainer: {
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(4),
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: theme.spacing(7),
      },
    },
    tabsContainerAdmin: {
      justifyContent: "center",
    },
  }),
  { name: "InvestorDashboardView" },
);

const InvestorDashboard: FC = (props) => {
  const classes = useStyles(props);
  const { clients, dispatch } = useContext(UserContext);
  const { clientId, tabName } = useParams<{ clientId: string; tabName?: string }>();
  const {
    state: { user },
  } = useContext(AuthContext);
  const isAdmin = useIsAdmin(user);
  const history = useHistory();
  const clientName = useMemo(() => clients[clientId] && clients[clientId].clientName, [clientId, clients]);
  useAnalytics();

  useEffect(() => {
    globalThis.dataLayer.push({ event: TRACKED_EVENTS.PAGE_VIEWS.INVESTOR_DASHBOARD });
  }, []);

  useEffect(() => {
    if (clientId) {
      (async () => {
        const res = await AdvisorClientsApi.getClient(clientId);
        if (res) {
          dispatch({ type: UserContextTypes.SET_CLIENT, payload: { clientName: `${res.forenames} ${res.surname}` }, clientId });
        }
      })();
    }
  }, [clientId, dispatch]);

  const TabPanel = useCallback(({ children, value }) => tabName === value && children, [tabName]);

  useEffect(() => {
    (async () => {
      const data = clientId ? await ClientStatementApi.getClientBalance(clientId) : await StatementApi.getBalance();
      if (data) {
        dispatch({ type: UserContextTypes.SET_BALANCES, payload: data, clientId });
      }
    })();
  }, [clientId, dispatch]);

  return (
    <>
      <Tabs
        value={tabName}
        onChange={(_e, newValue) => history.push(newValue)}
        scrollButtons="auto"
        variant="scrollable"
        classes={{ flexContainer: !isAdmin ? classes.tabsContainer : classes.tabsContainerAdmin }}
      >
        <Tab label="Portfolio of Investments" value="portfolio" data-testid="portfolio" />
        <Tab label="Statement" value="statement" data-testid="statement" />
        <Tab label="Investment Applications" value="applications" data-testid="applications" />
        {/* <Tab label="My Documents" value="documents" data-testid="documents" /> */}
      </Tabs>
      {clientId && (
        <Container>
          <Typography variant="h2">{clientName}</Typography>
        </Container>
      )}
      <TabPanel value="portfolio">
        <InvestmentPortfolio clientId={clientId} />
      </TabPanel>
      <TabPanel value="statement">
        <Container>
          <Statement clientId={clientId} />
        </Container>
      </TabPanel>
      <TabPanel value="applications">
        <Container>
          <Applications clientId={clientId} />
        </Container>
      </TabPanel>
      {/* <TabPanel value="documents">
          <Documents clientId={clientId} />
        </TabPanel> */}
    </>
  );
};

export default InvestorDashboard;
