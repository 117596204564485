import React, { Dispatch, FC, Reducer, useEffect } from "react";
import { AuthContextUser } from "server/services/user/user.types";

interface PayloadMap {
  ADMIN_SET_USER: AuthContextUser;
  SET_USER: AuthContextUser;
  SET_CONFIRMED: boolean;
  CLEAR_USER: undefined;
}
type AuthAction<T extends keyof PayloadMap> = {
  type: T;
  payload: PayloadMap[T];
};

type Actions = AuthAction<"ADMIN_SET_USER"> | AuthAction<"CLEAR_USER"> | AuthAction<"SET_CONFIRMED"> | AuthAction<"SET_USER"> | { type: undefined };

interface IAuthContext {
  state: { user?: AuthContextUser };
  dispatch: Dispatch<Actions>;
}

const AuthContext = React.createContext<IAuthContext>({ state: { user: undefined }, dispatch: () => null });
AuthContext.displayName = "AuthContext";

export const AUTH_TYPES = {
  ADMIN_SET_USER: "ADMIN_SET_USER",
  SET_USER: "SET_USER",
  SET_CONFIRMED: "SET_CONFIRMED",
  CLEAR_USER: "CLEAR_USER",
} as const;

const reducer: Reducer<IAuthContext["state"], Actions> = (state, action) => {
  switch (action.type) {
    case AUTH_TYPES.ADMIN_SET_USER:
      return { ...state, user: action.payload };

    case AUTH_TYPES.SET_USER:
      const user = action.payload!.adminUser || action.payload!;
      const userIdentify: GenericObject = {
        userId: user.userId,
        email: user.email,
        qualified: user.isQualified,
        isInvestor: user.isInvestor,
        isConfirmed: user.isConfirmed,
        isAdvisor: user.isAdvisor,
        isOnboardedAdvisor: user.isOnboardedAdvisor,
        investorType: user.investorType,
        role: user.role,
      };
      if (user.isAdvisor) {
        userIdentify.isVerified = user.isVerified;
      }
      globalThis.dataLayer.push({ user: userIdentify, event: "identify" });
      return {
        ...state,
        user: {
          ...action.payload!,
        },
      };

    case AUTH_TYPES.SET_CONFIRMED:
      return { ...state, user: state.user && { ...state.user, isConfirmed: action.payload } };

    case AUTH_TYPES.CLEAR_USER:
      return { ...state, user: undefined };

    default:
      throw new Error(`Unhandled action: ${action.type}`);
  }
};

const AuthProvider: FC<{ user?: AuthContextUser }> = ({ user, children }) => {
  const [state, dispatch] = React.useReducer(reducer, { user });

  useEffect(() => {
    if (user) {
      dispatch({
        type: AUTH_TYPES.SET_USER,
        payload: user,
      });
    }
  }, [user, dispatch]);

  return <AuthContext.Provider value={{ state, dispatch }}>{children}</AuthContext.Provider>;
};

const AuthConsumer = AuthContext.Consumer;
export { AuthContext, AuthProvider, AuthConsumer };
