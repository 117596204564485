import { MuiThemeProvider } from "@material-ui/core";
import { Alert, AlertProps, Color as Severity } from "@material-ui/lab";
import * as Sentry from "@sentry/react";
import { GraphQLError } from "graphql";
import { uniqueId } from "lodash";
import Notification from "rc-notification";
import "rc-notification/assets/index.css";
import { NotificationInstance } from "rc-notification/lib/Notification";
import React, { FC, ReactNode } from "react";
import theme from "../theme";

let notification: NotificationInstance;
if (typeof window !== "undefined") {
  Notification.newInstance(
    {
      style: { padding: 0, zIndex: 10000, top: 10, width: "100%", display: "flex", justifyContent: "center" },
    },
    (n) => {
      notification = n;
    },
  );
}

const style = { right: "unset", padding: 0 };

const Content: FC<AlertProps> = ({ children, ...props }) => (
  <MuiThemeProvider theme={theme()}>
    <Alert {...props} variant="standard">
      {children}
    </Alert>
  </MuiThemeProvider>
);

const process = (err?: Error) => {
  // do something with this error
  Sentry.withScope(() => {
    Sentry.captureException(err);
  });
};

const quiet = (err: Error) => {
  process(err);
};

const notice = ({ message, severity, duration = 1.5 }: { message: ReactNode; severity: Severity; duration?: number }) => {
  const key = uniqueId();
  return notification.notice({
    key,
    content: (
      <Content severity={severity} onClose={() => notification.removeNotice(key)}>
        {message}
      </Content>
    ),
    style,
    duration,
  });
};

const genericError = (err?: Error, duration: number = 1.5) => {
  process(err);
  return notice({
    message: "Oops. Something went wrong. Please try again.",
    severity: "error",
    duration,
  });
};

const error = (errorMessage: ReactNode, err?: Error, duration: number = 1.5) => {
  if (err) process(err);
  return notice({
    message: errorMessage,
    severity: "error",
    duration,
  });
};

const warning = (errorMessage: ReactNode, duration: number = 1.5) => {
  return notice({
    message: errorMessage,
    severity: "warning",
    duration,
  });
};

const success = (message: ReactNode, duration: number = 1.5) => {
  return notice({
    message,
    severity: "success",
    duration,
  });
};

const info = (message: ReactNode, duration: number = 1.5) => {
  return notice({
    message,
    severity: "info",
    duration,
  });
};

/**
 * Notification function for handling GraphQL errors.
 *
 * @param errors graphql errors
 * @param message optional notification message
 * @param duration
 * @returns
 */
const gql = (errors: GraphQLError[] | readonly GraphQLError[], message?: string, duration: number = 1.5) => {
  if (message) error(message, new Error(message), duration);
  errors.forEach(quiet);
};

export default { genericError, quiet, error, warning, success, info, gql };
