import { Dialog, DialogContent, IconButton, makeStyles } from "@material-ui/core";
import useToggle from "client/hooks/useToggle.hook";
import React from "react";
import Close from "../Icons/Close.icon";
import Menu from "../Icons/Menu.icon";

interface Props {}

const useStyles = makeStyles(
  (theme) => ({
    menuButton: {
      marginLeft: "auto",
      display: "none",
      [theme.breakpoints.down("md")]: {
        display: "unset",
      },
    },
    menuIcon: {
      width: 30,
    },
    closeButton: {
      position: "absolute",
      top: 28,
      right: 22,
    },
    closeIcon: {
      width: 30,
    },
    touchNavContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  }),
  { name: "TouchNavbarComponent" },
);

const TouchNavbar: React.FC<Props> = (props) => {
  const [open, , openDialog, closeDialog] = useToggle(false);
  const classes = useStyles(props);
  return (
    <>
      <IconButton onClick={openDialog} data-testid="navbarMenuButton" color="inherit" className={classes.menuButton}>
        <Menu />
      </IconButton>
      <Dialog open={open} onClick={closeDialog} onClose={closeDialog} fullScreen>
        <DialogContent className={classes.touchNavContainer}>
          <IconButton onClick={closeDialog} color="inherit" className={classes.closeButton}>
            <Close />
          </IconButton>
          {props.children}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TouchNavbar;
