import { createStyles, makeStyles, Typography, useTheme } from "@material-ui/core";
import { ErrorBoundary } from "@sentry/react";
import { Chart } from "chart.js";
import "chart.js/auto";
import clsx from "clsx";
import { extendMoment } from "moment-range";
import * as Moment from "moment-timezone";
import { FC, useEffect, useMemo, useState } from "react";
import { HistoricValue } from "server/services/shareTransaction/shareTransaction.types";
import FullValueChart from "./FullValueChart.component";
import MiniValueChart from "./MiniValueChart.component";

interface Props {
  historicValues: HistoricValue[];
  mini?: boolean;
  onChartChange?: (height: number) => any;
}

const moment = extendMoment(Moment);

const DATE_FORMAT = "MMM 'YY";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      fullChart: {
        opacity: 0,
      },
      fullChartVisible: {
        opacity: 1,
        transition: theme.transitions.create("opacity", {
          duration: theme.transitions.duration.standard,
          easing: theme.transitions.easing.easeInOut,
          delay: theme.transitions.duration.standard,
        }),
      },
    }),
  { name: "PortfolioValueChartComponent" },
);

const getChartData = <T extends "totalCost" | "totalValue">(data: HistoricValue[], key: T) =>
  data.reduce<Array<{ x: string; y: number; type: T }>>(
    (prev, { date, ...values }) => [...prev, { x: moment.tz(date, "UTC").format(DATE_FORMAT), y: Math.floor(values[key]), type: key }],
    [],
  );

const PortfolioValueChart: FC<Props> = ({ historicValues, onChartChange, mini = false, ...props }) => {
  const theme = useTheme();
  const classes = useStyles(props);
  const [height, setHeight] = useState<number | null>(null);

  useEffect(() => {
    Chart.defaults.font = {
      ...Chart.defaults.font,
      family: theme.typography.fontFamily!,
      size: 12,
      weight: theme.typography.fontWeightMedium! as any,
    };
  }, [theme.typography.fontFamily, theme.typography.fontWeightMedium]);

  useEffect(() => {
    if (onChartChange && typeof height === "number") onChartChange(height + theme.spacing(1.25));
  }, [height, onChartChange, theme]);

  const [amounts, values] = useMemo(() => [getChartData(historicValues, "totalCost"), getChartData(historicValues, "totalValue")], [historicValues]);

  const xLabels = useMemo(() => {
    if (!historicValues.length) return [];
    return Array.from(moment.range(moment.tz(historicValues[0]!.date, "UTC"), moment()).by("month")).map((month) =>
      moment(month).format(DATE_FORMAT),
    );
  }, [historicValues]);

  return (
    <ErrorBoundary
      fallback={
        <Typography variant="caption" align="center">
          Unable to load chart
        </Typography>
      }
    >
      {mini && <MiniValueChart values={values} ref={setHeight} />}
      <div className={clsx(classes.fullChart, { [classes.fullChartVisible]: !mini })}>
        {!mini && <FullValueChart amounts={amounts} values={values} xLabels={xLabels} ref={setHeight} />}
      </div>
    </ErrorBoundary>
  );
};

export default PortfolioValueChart;
