import fileDownload from "js-file-download";
import notifications from "../../utils/notifications";
import { ipAxios } from "../ipAxios";

export const uploadPublicDocument = async (values: GenericObject, documentId: string = "") => {
  try {
    const data = new FormData();
    data.append("body", JSON.stringify(values));

    if (values.file && values.file.constructor === File) {
      data.append("file", values.file, values.file.name);
    }

    if (values.coverImage && values.coverImage.constructor === File) {
      data.append("coverImage", values.coverImage, values.coverImage.name);
    }
    const res = await ipAxios.post(`/admin/documents/${documentId}`, data);
    return res.data;
  } catch (error: any) {
    notifications.error(error.message, error);
    return null;
  }
};

export const removePublicDocument = async (documentId: string) => {
  try {
    const res = await ipAxios.delete(`/admin/documents/${documentId}`);
    return res.data;
  } catch (error: any) {
    notifications.error(error.message, error);
    return null;
  }
};

export const getAllPublicDocuments = async (onChangeObject: GenericObject) => {
  try {
    const res = await ipAxios.get(`/admin/documents/`, { params: onChangeObject });
    return { data: res.data, count: res.headers["x-count"] };
  } catch (error: any) {
    if (error.message !== "duplicate request cancelled") {
      notifications.error(error.message, error);
    }
  }
};

export const downloadPublicDocument = async (documentId: string, fileName: string) => {
  try {
    const res = await ipAxios.get<Blob>(`/admin/documents/${documentId}`, {
      responseType: "blob",
    });
    if (res.status === 200) {
      const blob = new Blob([res.data]);
      fileDownload(blob, fileName);
      return { data: res.data, success: true };
    }
    return { success: false };
  } catch (error: any) {
    notifications.genericError(error);
    return { success: false };
  }
};

export const getAllDocuments = async (queryParams: GenericObject) => {
  try {
    const res = await ipAxios.get("/admin/documents/all", { params: queryParams });
    return { data: res.data, count: res.headers["x-count"] };
  } catch (error: any) {
    if (error.message !== "duplicate request cancelled") {
      notifications.error(error.message, error);
    }
    return null;
  }
};
