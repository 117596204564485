import { makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { useCallback } from 'react';

const useStyles = makeStyles(theme => ({
  disabledText: {
    color: theme.palette.text.disabled,
  },
  progressBarLine: {
    height: 10,
    backgroundColor: theme.palette.grey.main,

    marginTop: 4,
  },
  progressBarLineYellow: {
    backgroundColor: theme.palette.primary.main,
  },
  progressBarsWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    margin: theme.spacing(8.75, 0),
  },
  progressTitleAndBar: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginRight: 4,
    '&:first-child > div': {
      borderRadius: '50px 0px 0px 50px',
    },
    '&:last-child > div': {
      borderRadius: '0px 50px 50px 0px',
    },
  },
}));

const ProgressBar = ({ activeBarLine, ...props }) => {
  const classes = useStyles(props);

  const ProgressText = useCallback(
    ({ children, progressValue }) => (
      <Typography className={activeBarLine > progressValue ? classes.disabledText : ''} variant="body2" align="center" color="textPrimary">
        {children}
      </Typography>
    ),
    [activeBarLine, classes.disabledText],
  );

  return (
    <div className={classes.progressBarsWrapper}>
      <div className={classes.progressTitleAndBar}>
        <ProgressText progressValue={1}>About</ProgressText>
        <div className={clsx(classes.progressBarLine, { [classes.progressBarLineYellow]: activeBarLine >= 1 })} />
      </div>
      <div className={classes.progressTitleAndBar}>
        <ProgressText progressValue={2}>Objectives</ProgressText>
        <div className={clsx(classes.progressBarLine, { [classes.progressBarLineYellow]: activeBarLine >= 2 })} />
      </div>
      <div className={classes.progressTitleAndBar}>
        <ProgressText progressValue={3}>Finances</ProgressText>
        <div className={clsx(classes.progressBarLine, { [classes.progressBarLineYellow]: activeBarLine >= 3 })} />
      </div>
      <div className={classes.progressTitleAndBar}>
        <ProgressText progressValue={4}>Experience</ProgressText>
        <div className={clsx(classes.progressBarLine, { [classes.progressBarLineYellow]: activeBarLine >= 4 })} />
      </div>
      <div className={classes.progressTitleAndBar}>
        <ProgressText progressValue={5}>Investment</ProgressText>
        <div className={clsx(classes.progressBarLine, { [classes.progressBarLineYellow]: activeBarLine >= 5 })} />
      </div>
    </div>
  );
};

export default ProgressBar;
