import { IconButton, makeStyles, Tooltip, Typography } from "@material-ui/core";
import { Settings } from "@material-ui/icons";
import { ColGroupDefinition, ColumnDefinition, numberFormatter } from "@wearenova/mui-data-table";
import { useUpdateAppConfigs } from "client/api/gql/deployment.gqlApi";
import Divider from "client/components/Divider.component";
import NumberQuestion from "client/components/FormControls/NumberQuestion.component";
import { arrayToObject, formatThousands } from "client/utils/helpers";
import notifications from "client/utils/notifications";
import { Field, Form, Formik } from "formik";
import moment from "moment";
import React, { useCallback, useContext, useMemo } from "react";
import { AllDeploymentApplications, FullDeploymentApplication } from "server/services/application/application.types";
import { AdminCompany, BaseDeploymentDeal } from "server/services/company/company.types";
import { FundConfig } from "server/services/deployment/deployment.types";
import * as Yup from "yup";
import DeploymentContext from "../deployment.context";

const useStyles = makeStyles(
  () => ({
    includeForm: {
      width: "100%",
      display: "flex",
      justifyContent: "space-evenly",
      minWidth: 280,
    },
    includeInput: {
      width: 115,
    },
    preWrap: {
      whiteSpace: "pre-line",
    },
  }),
  { name: "UsePlannerTableStructureHook" },
);

export interface FlatDeal extends Omit<BaseDeploymentDeal, "config" | "id"> {
  uniqId: string;
  config: FundConfig;
  dealStage: "seis" | "eis";
}

const ConfigField: React.FC<{ path: "seis" | "eis" }> = <T extends "seis" | "eis">({ path }: { path: T }) => {
  const { applications } = useContext(DeploymentContext);
  const [updateAppConfigs] = useUpdateAppConfigs();

  const initialValues = useMemo(
    () =>
      ({
        [path]:
          (applications.every(
            (a, idx) => idx + 1 === applications.length || a.config.percentToUse[path] === applications[idx + 1].config.percentToUse[path],
          ) &&
            applications[0]?.config.percentToUse[path]) ||
          "",
      } as Record<T, number>),
    [applications, path],
  );
  const validationSchema = useMemo(() => Yup.object({ [path]: Yup.number().min(0).max(100) }), [path]);

  const handleSubmit = useCallback(
    async (values: Record<T, number>) => {
      const res = await updateAppConfigs({ variables: { updates: { percentToUse: values }, appIds: applications.map((a) => a._id) } });
      if (res.errors) notifications.gql(res.errors, "failed to update application configs");
    },
    [applications, updateAppConfigs],
  );

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} enableReinitialize>
      {({ submitForm }) => (
        <Form>
          <Field
            name={path}
            component={NumberQuestion}
            onKeyPress={(e: KeyboardEvent) => e.key === "Enter" && submitForm()}
            placeholder={path.toUpperCase()}
            suffix="%"
            variant="standard"
            size="small"
          />
        </Form>
      )}
    </Formik>
  );
};

const useAllocationsStructure = ({
  deals,
  allCompaniesObject,
  toggleChangeOptsOn,
  setChangeRowOptionsId,
}: {
  deals: FlatDeal[];
  allCompaniesObject: GenericObject<AdminCompany>;
  toggleChangeOptsOn: () => void;
  setChangeRowOptionsId: React.Dispatch<React.SetStateAction<string | null>>;
}): ColumnDefinition<FullDeploymentApplication, AllDeploymentApplications>[] => {
  const classes = useStyles();
  const { options, funds } = useContext(DeploymentContext);

  const fundsObject = useMemo(() => arrayToObject(funds), [funds]);

  return useMemo<ColumnDefinition<FullDeploymentApplication, AllDeploymentApplications>[]>(
    () =>
      (
        [
          {
            key: "config",
            title: "Config",
            align: "center",
            actionButtons: [
              {
                key: "changeOptions",
                icon: <Settings />,
                onClick: toggleChangeOptsOn,
                color: Object.values(options.allocations || {}).some((o) => o === true) ? "primary" : undefined,
              },
            ],
            render: (record) => {
              return (
                <IconButton
                  onClick={() => setChangeRowOptionsId(record._id)}
                  color={
                    record.config.excludedCompanies?.length > 0 ||
                    typeof record.config.spread.seis === "number" ||
                    typeof record.config.spread.eis === "number"
                      ? "primary"
                      : undefined
                  }
                >
                  <Settings />
                </IconButton>
              );
            },
          },
          {
            key: "percentToUse",
            title: "Percent of available funds to use",
            colGroup: (["seis", "eis"] as const).map((key) => ({
              key: `percentToUse.${key}`,
              title: (
                <div>
                  {key.toUpperCase()}
                  <br />
                  <ConfigField key={key} path={key} />
                </div>
              ),
              dataType: "number",
              dataIndex: `config.percentToUse.${key}`,
              editable: { path: true, validate: (value) => Yup.number().min(0).max(100).validate(value) },
              render: (record) => numberFormatter((record.config.percentToUse[key] ?? 0) / 100, { currency: false, style: "percent" }),
            })),
          },
          {
            key: "investorName",
            title: "Investor Name",
            sorter: (a, b) => a.investor.user.fullName.localeCompare(b.investor.user.fullName),
            dataIndex: "investor.user.fullName",
            filterColumn: true,
          },
          {
            key: "investorEmail",
            title: "Investor Email",
            sorter: (a, b) => a.investor.user.email.localeCompare(b.investor.user.email),
            dataIndex: "investor.user.email",
            filterColumn: true,
          },
          {
            key: "portfolio",
            title: "Portfolio",
            sorter: (a, b) => a.portfolio.name.localeCompare(b.portfolio.name),
            dataIndex: "portfolio.name",
            filterColumn: true,
          },
          {
            key: "cancellationPeriod",
            title: "End of Cancellation Period",
            sorter: (a, b) =>
              a.data.investmentInformation.endOfCancellationPeriod!.localeCompare(b.data.investmentInformation.endOfCancellationPeriod!),
            render: (record, isCSVExport) => {
              const date = moment(parseInt(record.data.investmentInformation.endOfCancellationPeriod!)).format("DD/MM/YYYY");
              const stringedReceived = String(date);
              if (isCSVExport) {
                return stringedReceived;
              }
              return date ? stringedReceived : <strong>{stringedReceived}</strong>;
            },
            filterColumn: { path: "data.investmentInformation.endOfCancellationPeriod", type: "date" },
          },
          {
            key: "mainspringId",
            title: "Mainspring ID",
            sorter: (a, b) => (a.mainspringId || "").localeCompare(b.mainspringId || ""),
            dataIndex: "mainspringId",
            filterColumn: true,
          },
          {
            key: "fundsReceived",
            title: "Funds Received?",
            sorter: (a, b) => Number(Boolean(a.balances.fundsReceived.total)) - Number(Boolean(b.balances.fundsReceived.total)),
            render: (record, isCSVExport) => {
              const received = record.balances.fundsReceived.received;
              const stringedReceived = String(received).toUpperCase();
              if (isCSVExport) {
                return stringedReceived;
              }
              return received ? stringedReceived : <strong>{stringedReceived}</strong>;
            },
            filterColumn: { path: "balances.fundsReceived.received", type: "boolean" },
          },
          {
            key: "amlApproved",
            title: "AML Approved",
            sorter: (a, b) => a.amlCompleted.localeCompare(b.amlCompleted),
            render: (record, isCSVExport) => {
              if (isCSVExport) {
                return record.amlCompleted;
              }
              return record.amlCompleted === "Signed off" ? record.amlCompleted : <strong>{record.amlCompleted}</strong>;
            },
            filterColumn: "amlCompleted",
          },
          {
            key: "investmentManagerApproved",
            title: "Investment Manager Approved",
            sorter: (a, b) => Number(a.investmentManagerApproved) - Number(b.investmentManagerApproved),
            render: (record, isCSVExport) => {
              const stringedIMApproved = String(record.investmentManagerApproved).toUpperCase();
              if (isCSVExport) {
                return stringedIMApproved;
              }
              return record.investmentManagerApproved ? stringedIMApproved : <strong>{stringedIMApproved}</strong>;
            },
            filterColumn: { path: "investmentManagerApproved", type: "boolean" },
          },
          {
            key: "introducer",
            title: "Introducer",
            dataIndex: "introducer",
            filterColumn: true,
          },
          {
            key: "notes",
            title: "Notes",
            dataIndex: "notes",
            filterColumn: true,
          },
          {
            key: "carryBack",
            title: "Carryback Amount",
            filterColumn: "data.investmentInformation.carryBack.information",
            colGroup: [
              {
                key: "carryBack.total",
                title: "Total",
                numerical: { path: true, currency: true },
                sorter: (a, b) => {
                  const { seis: aSEIS = 0, eis: aEIS = 0 } = a.data.investmentInformation.carryBack ?? {};
                  const { seis: bSEIS = 0, eis: bEIS = 0 } = b.data.investmentInformation.carryBack ?? {};
                  return aSEIS + aEIS - (bSEIS + bEIS);
                },
                render: (record) => {
                  const seis = record.data.investmentInformation.carryBack?.seis || 0;
                  const eis = record.data.investmentInformation.carryBack?.eis || 0;
                  return formatThousands(seis + eis, 2);
                },
                footer: (data) => formatThousands((data.totals?.carryBack.seis ?? 0) + (data.totals?.carryBack.eis ?? 0), 2),
              },
              {
                key: "carryBack.seis",
                title: "SEIS",
                dataIndex: "data.investmentInformation.carryBack.seis",
                numerical: { path: true, currency: true },
                sorter: (a, b) => (a.data.investmentInformation.carryBack.seis ?? 0) - (b.data.investmentInformation.carryBack.seis ?? 0),
                footer: (data) => formatThousands(data.totals?.carryBack.seis ?? 0, 2),
              },
              {
                key: "carryBack.eis",
                title: "EIS",
                dataIndex: "data.investmentInformation.carryBack.eis",
                numerical: { path: true, currency: true },
                sorter: (a, b) => (a.data.investmentInformation.carryBack.eis ?? 0) - (b.data.investmentInformation.carryBack.eis ?? 0),
                footer: (data) => formatThousands(data.totals?.carryBack.eis ?? 0, 2),
              },
              {
                key: "carryBackInfo",
                title: "Info",
                dataIndex: "data.investmentInformation.carryBack.information",
                limitWidth: "sm",
              },
            ],
          },
          {
            key: "receivedAmount",
            title: "Received Amount",
            colGroup: [
              {
                key: "totalReceived",
                title: "Total",
                numerical: { path: "balances.fundsReceived.total", currency: true },
                sorter: (a, b) => a.balances.fundsReceived.total - b.balances.fundsReceived.total,
                footer: (data) => formatThousands(data.totals?.fundsReceived.total ?? 0, 2),
              },
              {
                key: "seisReceived",
                title: "SEIS",
                numerical: { path: "balances.fundsReceived.seis", currency: true },
                sorter: (a, b) => a.balances.fundsReceived.seis - b.balances.fundsReceived.seis,
                footer: (data) => formatThousands(data.totals?.fundsReceived.seis ?? 0, 2),
              },
              {
                key: "eisReceived",
                title: "EIS",
                numerical: { path: "balances.fundsReceived.eis", currency: true },
                sorter: (a, b) => a.balances.fundsReceived.eis - b.balances.fundsReceived.eis,
                footer: (data) => formatThousands(data.totals?.fundsReceived.eis ?? 0, 2),
              },
            ],
          },
          {
            key: "deployedAmount",
            title: "Deployed Amount",
            colGroup: [
              {
                key: "totalDeployed",
                title: "Total",
                numerical: { path: "balances.fundsDeployed.total", currency: true },
                sorter: (a, b) => a.balances.fundsDeployed.total - b.balances.fundsDeployed.total,
                footer: (data) => formatThousands(data.totals?.fundsDeployed.total ?? 0, 2),
              },
              {
                key: "seisDeployed",
                title: "SEIS",
                numerical: { path: "balances.fundsDeployed.seis", currency: true },
                sorter: (a, b) => a.balances.fundsDeployed.seis - b.balances.fundsDeployed.seis,
                footer: (data) => formatThousands(data.totals?.fundsDeployed.seis ?? 0, 2),
              },
              {
                key: "eisDeployed",
                title: "EIS",
                numerical: { path: "balances.fundsDeployed.eis", currency: true },
                sorter: (a, b) => a.balances.fundsDeployed.eis - b.balances.fundsDeployed.eis,
                footer: (data) => formatThousands(data.totals?.fundsDeployed.eis ?? 0, 2),
              },
            ],
          },
          {
            key: "availableAmount",
            title: "Available Amount",
            colGroup: [
              {
                key: "totalBalance",
                title: "Total",
                numerical: { path: "balances.fundsRemaining.total", currency: true },
                sorter: (a, b) => a.balances.fundsRemaining.total - b.balances.fundsRemaining.total,
                footer: (data) => formatThousands(data.totals?.availableAmount.total ?? 0, 2),
              },
              {
                key: "seisBalance",
                title: "SEIS",
                numerical: { path: "balances.fundsRemaining.seis", currency: true },
                sorter: (a, b) => a.balances.fundsRemaining.seis - b.balances.fundsRemaining.seis,
                footer: (data) => formatThousands(data.totals?.availableAmount.seis ?? 0, 2),
              },
              {
                key: "eisBalance",
                title: "EIS",
                numerical: { path: "balances.fundsRemaining.eis", currency: true },
                sorter: (a, b) => a.balances.fundsRemaining.eis - b.balances.fundsRemaining.eis,
                footer: (data) => formatThousands(data.totals?.availableAmount.eis ?? 0, 2),
              },
            ],
          },
          {
            key: "toDeploy",
            title: "To Deploy Amount",
            colGroup: [
              {
                key: "totalToDeploy",
                title: "Total",
                numerical: { path: "toDeploy.total", currency: true },
                sorter: (a, b) => a.toDeploy.total - b.toDeploy.total,
                footer: (data) => formatThousands(data.totals?.toDeploy.total ?? 0, 2),
              },
              {
                key: "seisToDeploy",
                title: "SEIS",
                numerical: { path: "toDeploy.seis", currency: true },
                sorter: (a, b) => a.toDeploy.seis - b.toDeploy.seis,
                footer: (data) => formatThousands(data.totals?.toDeploy.seis ?? 0, 2),
              },
              {
                key: "eisToDeploy",
                title: "EIS",
                numerical: { path: "toDeploy.eis", currency: true },
                sorter: (a, b) => a.toDeploy.eis - b.toDeploy.eis,
                footer: (data) => formatThousands(data.totals?.toDeploy.eis ?? 0, 2),
              },
            ],
          },
          ...(deals.length !== 0
            ? ([
                {
                  key: "investmentAmount",
                  title: "Investment Amount",
                  colGroup: [
                    {
                      key: "totalInvested",
                      title: "Total",
                      numerical: { path: "balances.totalInvested.total", currency: true },
                      sorter: (a, b) => a.balances.totalInvested.total - b.balances.totalInvested.total,
                      footer: (data) => formatThousands(data.totals?.investmentAmount.total ?? 0, 2),
                    },
                    {
                      key: "totalSEISInvested",
                      title: "SEIS",
                      numerical: { path: "balances.totalInvested.seis", currency: true },
                      sorter: (a, b) => a.balances.totalInvested.seis - b.balances.totalInvested.seis,
                      footer: (data) => formatThousands(data.totals?.investmentAmount.seis ?? 0, 2),
                    },
                    {
                      key: "totalEISInvested",
                      title: "EIS",
                      numerical: { path: "balances.totalInvested.eis", currency: true },
                      sorter: (a, b) => a.balances.totalInvested.eis - b.balances.totalInvested.eis,
                      footer: (data) => formatThousands(data.totals?.investmentAmount.eis ?? 0, 2),
                    },
                  ],
                },
              ] as ColumnDefinition<FullDeploymentApplication, AllDeploymentApplications>[])
            : []),
          ...(deals.length !== 0
            ? ([
                {
                  key: "remainingAmount",
                  title: "Remaining Amount",
                  colGroup: [
                    {
                      key: "totalRemaining",
                      title: "Total",
                      numerical: { path: "balances.afterShareTransaction.total", currency: true },
                      sorter: (a, b) => a.balances.afterShareTransaction.total - b.balances.afterShareTransaction.total,
                      footer: (data) => formatThousands(data.totals?.remainingAmount.total ?? 0, 2),
                    },
                    {
                      key: "seisRemaining",
                      title: "SEIS",
                      numerical: { path: "balances.afterShareTransaction.seis", currency: true },
                      sorter: (a, b) => a.balances.afterShareTransaction.seis - b.balances.afterShareTransaction.seis,
                      footer: (data) => formatThousands(data.totals?.remainingAmount.seis ?? 0, 2),
                    },
                    {
                      key: "eisRemaining",
                      title: "EIS",
                      numerical: { path: "balances.afterShareTransaction.eis", currency: true },
                      sorter: (a, b) => a.balances.afterShareTransaction.eis - b.balances.afterShareTransaction.eis,
                      footer: (data) => formatThousands(data.totals?.remainingAmount.eis ?? 0, 2),
                    },
                  ],
                },
              ] as ColumnDefinition<FullDeploymentApplication, AllDeploymentApplications>[])
            : []),
          {
            key: "companiesInvestedIn",
            title: "Nova Companies Invested In",
            colGroup: [
              {
                key: "novaCompaniesInvestedInBefore",
                title: "Before",
                render: (record) => (
                  <Tooltip
                    interactive
                    title={
                      !record.balances.fundsDeployed.companies.length && !record.balances.fundsDeployed.pendingCompanies.length ? (
                        ""
                      ) : (
                        <>
                          {record.balances.fundsDeployed.companies.map((company) => (
                            <div key={company}>
                              {allCompaniesObject[company] ? `${allCompaniesObject[company].companyName} (Deployed)` : "Unknown"}
                            </div>
                          ))}
                          {Boolean(record.balances.fundsDeployed.pendingCompanies.length) && <hr />}
                          {record.balances.fundsDeployed.pendingCompanies.map((company) => (
                            <div key={company}>
                              {allCompaniesObject[company] ? `${allCompaniesObject[company].companyName} (Pending deployment)` : "Unknown"}
                            </div>
                          ))}
                        </>
                      )
                    }
                  >
                    <div>{record.balances.fundsDeployed.companies.length}</div>
                  </Tooltip>
                ),
              },
              ...(deals.length !== 0
                ? ([
                    {
                      key: "novaCompaniesInvestedInAfter",
                      title: "After",
                      render: (record) => (
                        <Tooltip
                          title={
                            <Typography variant="inherit" color="inherit" className={classes.preWrap}>
                              {record.noSpreadReasons && (
                                <>
                                  Not enough eligible companies to create the desired spread.
                                  <br />
                                  {record.noSpreadReasons.seis}
                                  {record.noSpreadReasons.eis}
                                  <br />
                                  <br />
                                  <Divider color="primary" />
                                  <br />
                                </>
                              )}
                              {record.balances.afterShareTransaction.companies.map((company: string) => (
                                <div key={company}> {allCompaniesObject[company] ? allCompaniesObject[company].companyName : "Unknown"} </div>
                              ))}
                            </Typography>
                          }
                          interactive
                        >
                          <Typography variant="inherit" color={record.noSpreadReasons ? "error" : "inherit"}>
                            {record.balances.afterShareTransaction.companies.length}
                          </Typography>
                        </Tooltip>
                      ),
                    },
                  ] as ColGroupDefinition<FullDeploymentApplication, AllDeploymentApplications>[])
                : []),
            ],
          },
          ...deals.map<ColumnDefinition<FullDeploymentApplication, AllDeploymentApplications>>((deal) => {
            const company = allCompaniesObject[deal.company._id];
            const fundId = String(deal.config.fund);
            return {
              key: deal.uniqId,
              title: (data) => (
                <div>
                  {fundsObject[String(deal.config.fund)]?.name}
                  <br />
                  {company?.companyName}
                  <br />
                  <strong>
                    {deal.dealStage.toUpperCase()}
                    {deal.type === "ASA" ? " - ASA" : ""}
                  </strong>
                  <br />
                  Share Class: <strong>{deal.deploymentShareClass}</strong>&nbsp;/&nbsp;Price Per Share:{" "}
                  <strong>{formatThousands(deal.shareClassData.pricePerShare as number, 2, true, 2, 7)}</strong>
                  <br />
                  Weighting:{" "}
                  <strong>{formatThousands(data.totals?.deals[deal._id]?.[fundId]?.[deal.dealStage].weighting ?? 0, 0, false, 0, 5)}</strong>
                  &nbsp;/&nbsp;To Deploy:{" "}
                  <strong>{formatThousands(data.totals?.deals[deal._id]?.[fundId]?.[deal.dealStage].toDeploy ?? 0, 2)}</strong>
                  <br />
                  Balance:{" "}
                  <strong>{typeof company?.resourceBalance === "number" ? formatThousands(company.resourceBalance, 2) : "Not Defined"}</strong>
                  &nbsp;/&nbsp; Remaining {deal.dealStage.toUpperCase()}:{" "}
                  <strong>{formatThousands(company?.capacityRemaining?.[deal.dealStage], 2)}</strong>
                </div>
              ),
              footer: (data) => {
                const remaining = company?.capacityRemaining?.[deal.dealStage] ?? 0;
                const toDeploy = data.totals?.deals[deal._id]?.[fundId]?.[deal.dealStage].toDeploy ?? 0;
                const total = data.totals?.deals[deal._id]?.[fundId]?.[deal.dealStage].amount ?? 0;
                return (
                  <>
                    Remaining To Deploy: {formatThousands(toDeploy - total, 2)}
                    <br />
                    Remaining {deal.dealStage.toUpperCase()}: {formatThousands(remaining - total, 2)}
                  </>
                );
              },
              colGroup: [
                {
                  key: `${deal.uniqId}.shares`,
                  title: "Shares",
                  footer: (data) =>
                    deal.type === "ASA" ? "N/A" : formatThousands(data.totals?.deals[deal._id]?.[fundId]?.[deal.dealStage]?.shares ?? 0, 0, false),
                  render: (data) => {
                    return deal.type === "ASA" ? "N/A" : formatThousands(data.deals[deal._id]?.[fundId]?.[deal.dealStage].shares ?? 0, 0, false);
                  },
                },
                {
                  key: `${deal.uniqId}.amount`,
                  title: "Amount",
                  numerical: { path: `deals.${deal._id}.${fundId}.${deal.dealStage}.amount`, currency: true },
                  footer: (data) => formatThousands(data.totals?.deals[deal._id]?.[fundId]?.[deal.dealStage]?.amount ?? 0, 2),
                },
              ],
            };
          }),
        ] as ColumnDefinition<FullDeploymentApplication, AllDeploymentApplications>[]
      ).map((c) => ({ pinnable: true, ...c } as ColumnDefinition<FullDeploymentApplication, AllDeploymentApplications>)),
    [allCompaniesObject, classes.preWrap, deals, fundsObject, options.allocations, setChangeRowOptionsId, toggleChangeOptsOn],
  );
};

export default useAllocationsStructure;
