import { Card, CardContent, CardHeader, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles(
  theme => ({
    card: {
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: 0,
      flex: 1,
      cursor: 'pointer',
    },
    cardContent: {
      color: theme.palette.text.hint,
    },
    cardHeader: {
      paddingBottom: 0,
    },
    cardHeaderText: {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.text.hint,
    },
    selected: {
      boxShadow: `0px 12px 24px ${theme.palette.primary.dark}4D`,
      '& .MuiTypography-root': {
        color: theme.palette.text.primary,
      },
    },
  }),
  { name: 'qualificationCard' },
);

const QualificationCard = ({ title, selected, onClick, registerRef, ...props }) => {
  const classes = useStyles(props);

  return (
    <Card elevation={0} className={clsx(classes.card, { [classes.selected]: selected })} onClick={onClick} ref={registerRef} {...props}>
      <CardHeader className={classes.cardHeader} title={title} titleTypographyProps={{ variant: 'body1', className: classes.cardHeaderText }} />
      <CardContent>
        <Typography variant="h5" className={classes.cardContent}>
          {props.children}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default QualificationCard;
