import _ from "lodash";
import { LeanFund } from "server/services/fund/fund.types";
import { LiteralUnion } from "type-fest";
import { FUND_VARIABLE_REPLACER_REGEX, STORAGE_KEYS } from "./constants";
import { LeanApplication } from "server/services/application/application.types";
import moment from "moment";

export type InitialValues<T extends GenericObject> = { [key in keyof T]: GetInitialType<T[key]> };

type GetInitialType<T> = T extends (infer U)[]
  ? GetInitialType<U>[]
  : T extends object
  ? InitialValues<T>
  : T extends number
  ? T | ""
  : T extends boolean
  ? boolean | "" | null
  : string | null;

export const PHONE_REGEX = /(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s.]?[(]?[0-9]{1,3}[)]?([-\s.]?[0-9]{3})([-\s.]?[0-9]{3,4})/;
export const NAT_INSUR_REGEX = /^^[A-CEGHJ-PR-TW-Za-ceghj-pr-tw-z]{1}[A-CEGHJ-NPR-TW-Za-ceghj-npr-tw-z]{1}[0-9]{6}[A-DFMa-dfm]{1}$/;

export const UK_POSTCODE_REGEX =
  /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/;

export const fundVariableReplacer = (text: string, fund: LeanFund) =>
  text.replace(FUND_VARIABLE_REPLACER_REGEX, (_fullMatch, path1, path2) => _.get(fund, path1 || path2, ""));

const getCookies = () => {
  return document.cookie.split(";").reduce<{ [key: string]: string }>((cookies, cookie) => {
    const [name, value] = cookie.split("=").map((c) => c.trim());
    return { ...cookies, [name]: value };
  }, {});
};

export const getCookie = (cookieName: string) => {
  const cookies = getCookies();
  return cookies[cookieName];
};

export const clearLocalStorage = () => Object.values(STORAGE_KEYS).forEach((i) => window.localStorage.removeItem(i));

export const clearApplicationData = () => {
  const { AUTH_TOKEN, ...APPLICATION_KEYS } = STORAGE_KEYS;
  Object.values(APPLICATION_KEYS).forEach((i) => window.localStorage.removeItem(i));
};

export const fromCamelCase = (str: string) => {
  return str.replace(/([A-Z])/g, " $1").replace(/^./, (l) => {
    return l.toUpperCase();
  });
};

export const toCamelCase = (str: string) =>
  str.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => (index === 0 ? word.toLowerCase() : word.toUpperCase())).replace(/\s+/g, "");

export const toStringDate = (date: string | number | Date) => {
  return new Date(date)
    .toLocaleString("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
    })
    .toUpperCase();
};

export const formatThousands = (
  num: number,
  decimalPlaces: number = 0,
  currency: boolean = true,
  minDecimalPlaces: number = 0,
  maxDecimalPlaces: number = 0,
) =>
  new Intl.NumberFormat("en-GB", {
    style: currency ? "currency" : "decimal",
    currency: "GBP",
    minimumFractionDigits: minDecimalPlaces || decimalPlaces,
    maximumFractionDigits: maxDecimalPlaces || decimalPlaces,
  }).format(num);

export const getNoDecimalPlaces = (value: number | string) => {
  const stringValue = value.toString();
  if (stringValue.indexOf("e-") > -1) {
    const [, trail] = stringValue.split("e-");
    return parseInt(trail, 10);
  }
  const [, decimal] = stringValue.split(".");
  if (!decimal) {
    return 0;
  }
  return decimal.length;
};

export const subtractDecimals = (value: number, initialValue: number = 100) => {
  const noDecimalPlaces = getNoDecimalPlaces(value);
  return Number((initialValue - value).toFixed(noDecimalPlaces));
};

export const arrayToObject = <A extends GenericObject[], Keys extends LiteralUnion<A[number], string> = LiteralUnion<A[number], string>>(
  payload: A | A[number],
  key: Keys | Keys[] = "_id" as Keys,
  joiner: string = "_",
) => {
  const keyArr = Array.isArray(key) ? key : [key];
  const vals = Array.isArray(payload) ? payload : ([payload] as A);
  return vals
    ? vals.reduce<{ [key: string]: A[number] }>((prev, curr) => {
        const key = keyArr.map((key) => _.get(curr, key)).join(joiner);
        return {
          ...prev,
          [key || "null"]: curr,
        };
      }, {})
    : {};
};

// Function to autofit columns when creating XLSX sheets with SheetJS
// https://github.com/SheetJS/sheetjs/issues/1473#issuecomment-580648494
export const xlsxAutofitColumns = (sheetJsonData: any[], worksheet: any, padding?: number) => {
  const jsonKeys = Object.keys(sheetJsonData[0]);
  const objectMaxLength = calculateObjectMaxLength(sheetJsonData, jsonKeys, padding);

  const wscols = objectMaxLength.map((width: any) => ({ width }));

  worksheet["!cols"] = wscols;
  return worksheet;
};

const calculateObjectMaxLength = (sheetJsonData: any[], jsonKeys: string[], padding?: number) => {
  const objectMaxLength: any = [];

  for (let i = 0; i < sheetJsonData.length; i++) {
    const value = sheetJsonData[i];

    for (let j = 0; j < jsonKeys.length; j++) {
      objectMaxLength[j] = Math.max(objectMaxLength[j] || 0, getStringLength(value[jsonKeys[j]]) || 0, 10);
    }

    objectMaxLength.forEach((maxLength: any, j: number) => {
      objectMaxLength[j] = Math.max(maxLength, getStringLength(jsonKeys[j]) + (padding ?? 0));
    });
  }

  return objectMaxLength;
};

const getStringLength = (str: string | undefined): number => {
  return str ? str.length : 0;
};

export const getLinkTextByApplicationStatus = (application: LeanApplication | null, short: boolean = true) => {
  const status = application?.status ?? "";

  if (short) {
    return status.includes("In progress") ? "Continue" : "Start";
  }
  return status.includes("In progress") ? "Continue with Application" : "Begin an Application";
};

export const formatDateString = (inputDateString: string, dateFormat: string) => {
  const formattedDate = moment(inputDateString).format(dateFormat).toUpperCase();
  return formattedDate;
};

export const fixedPrecision = (value: number, precision: number = 2) => Number(value.toFixed(precision));
export const round = (value: number, multiple: number = 1) => Math.round(value / multiple) * multiple;
export const ceil = (value: number, multiple: number = 1) => Math.ceil(value / multiple) * multiple;
export const floor = (value: number, multiple: number = 1) => Math.floor(value / multiple) * multiple;
