import { ConfigContext } from "client/context/config.context";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import AdvisorClientsApi from "../../api/advisor/clients.advisorApi";
import BasicPageTemplate from "../../components/BasicPageTemplate.component";
import useAnalytics from "../../hooks/useAnalytics";
import { ROUTES, TRACKED_EVENTS } from "../../utils/constants";
import useApplication from "./useApplication";
import { getLinkTextByApplicationStatus } from "client/utils/helpers";
import { Typography, makeStyles } from "@material-ui/core";

const { PAGE_VIEWS } = TRACKED_EVENTS;

const useStyles = makeStyles((theme) => ({
  warning: {
    marginTop: theme.spacing(-4),
    marginBottom: theme.spacing(4),
    color: theme.palette.error.main,
  },
}));

const ApplicationStart: React.FC = (props) => {
  const classes = useStyles(props);
  const history = useHistory();
  const match = useRouteMatch<{ clientID?: string }>();

  const { fund } = useContext(ConfigContext);
  const [clientName, setClientName] = useState("");

  const clientId = useMemo(() => match.params.clientID, [match.params.clientID]);

  const { application } = useApplication(false);
  const { IS_PRODUCTION } = useContext(ConfigContext);

  const disableNewApplication = IS_PRODUCTION;

  useEffect(() => {
    if (clientId) {
      (async () => {
        const tempClient = await AdvisorClientsApi.getClient(clientId);
        if (!tempClient) return;
        const { forenames, surname } = tempClient;
        setClientName(`${forenames} ${surname}`);
      })();
    }
  }, [clientId, clientName, setClientName]);

  useEffect(() => {
    if (clientId) {
      globalThis.dataLayer.push({ event: PAGE_VIEWS.CLIENT_APPLICATION_FORM_BEGIN });
    } else {
      globalThis.dataLayer.push({ event: PAGE_VIEWS.INVESTOR_APPLICATION_BEGIN });
    }
  }, [clientId]);

  useAnalytics();

  return (
    <>
      {clientId ? (
        <BasicPageTemplate
          title={`Apply on behalf of \n${clientName}`}
          body={`
      Once you have completed the application form you will be asked to electronically sign the adviser section. Your client will then be informed that an application has been made on their behalf and they will be instructed to electronically sign the investor section of the application form.
      `}
          data-testid="beginApplication"
          onClick={() => history.push(!clientId ? ROUTES.APPLICATION_DETAILS : `/advisor/client/${clientId}${ROUTES.APPLICATION_ASSESSMENT}`)}
          buttonText={getLinkTextByApplicationStatus(application, false)}
          isBtnDisabled={disableNewApplication}
        />
      ) : (
        <BasicPageTemplate
          title={`${fund.name} Application Form`}
          body="Thank you for your interest in our fund. The application process is completely digital, and will take about 10 minutes to complete. You will then be prompted to electronically sign the investment agreement."
          data-testid="beginApplication"
          onClick={() => history.push(fund.aml.enabled ? ROUTES.APPLICATION_ID_VERIFICATION : ROUTES.APPLICATION_ASSESSMENT)}
          buttonText={getLinkTextByApplicationStatus(application, false)}
          isBtnDisabled={disableNewApplication}
        />
      )}
      {disableNewApplication && (
        <Typography variant="body2" align="center" className={classes.warning}>
          New applications are now closed for 2023/24
        </Typography>
      )}
    </>
  );
};

export default ApplicationStart;
