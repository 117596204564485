import { FormGroup, Typography } from "@material-ui/core";
import { Field, useFormikContext } from "formik";
import React, { useEffect } from "react";
import Statement from "./Statement.component";
import { CertificationForm } from "client/utils/constants";
import RadioQuestion from "../FormControls/RadioQuestion.component";
import NumberQuestion from "../FormControls/NumberQuestion.component";
import CheckboxQuestion from "../FormControls/CheckboxQuestion.component";

type QuestionIds = (typeof CertificationForm.values)["hasLargeIncome" | "hasLargeNetAssets" | "notApplicable" | "netIncome" | "netAssets"]["id"];

const HighNetWorthQualification: React.FC = () => {
  const { errors, touched, values, setFieldValue } = useFormikContext<{
    atLeastOneTrue: boolean;
    values: {
      [key in QuestionIds]: boolean;
    };
  }>();

  useEffect(() => {
    if (values.values.notApplicable) {
      setFieldValue(CertificationForm.values.hasLargeIncome.name, null);
      setFieldValue(CertificationForm.values.hasLargeNetAssets.name, null);
    }
  }, [setFieldValue, values.values.notApplicable]);

  const handleRadioChange = () => {
    if (values.values.notApplicable) {
      setFieldValue(CertificationForm.values.notApplicable.name, false);
    }
  };

  return (
    <FormGroup>
      <Statement title="High Net Worth Investor Statement">
        If you meet <b>condition A or B below,</b> you may choose to be classified as high net worth individual for the purposes of the Financial
        Services and Markets Act 2000 (Financial Promotion) Order 2005. <br />
        <br />
        In the last financial year, did you have:
        <FormGroup>
          <Field
            name={CertificationForm.values.hasLargeIncome.name}
            component={RadioQuestion}
            onClick={handleRadioChange}
            label={<Typography variant="body2">{CertificationForm.values.hasLargeIncome.label}</Typography>}
          />
        </FormGroup>
        <FormGroup>
          {values.values.hasLargeIncome && (
            <Field
              name={CertificationForm.values.netIncome.name}
              component={NumberQuestion}
              label={<Typography variant="body2">{CertificationForm.values.netIncome.label}</Typography>}
            />
          )}
        </FormGroup>
        <Typography variant="h3">AND/OR</Typography>
        <FormGroup>
          <Field
            name={CertificationForm.values.hasLargeNetAssets.name}
            component={RadioQuestion}
            onClick={handleRadioChange}
            label={<Typography variant="body2">{CertificationForm.values.hasLargeNetAssets.label}</Typography>}
          />
        </FormGroup>
        {values.values.hasLargeNetAssets && (
          <FormGroup>
            <Field
              name={CertificationForm.values.netAssets.name}
              component={NumberQuestion}
              label={<Typography variant="body2">{CertificationForm.values.netAssets.label}</Typography>}
            />
          </FormGroup>
        )}
        <br />
        <Typography variant="h3">OR</Typography>
        <FormGroup>
          <Typography variant="body2">C) None of these apply to me.</Typography>
          <Field name={CertificationForm.values.notApplicable.name} component={CheckboxQuestion} label={"Yes"} />
        </FormGroup>
        <FormGroup>
          {(touched.values?.[CertificationForm.values.hasLargeIncome.id] || touched.values?.[CertificationForm.values.hasLargeNetAssets.id]) &&
            errors.atLeastOneTrue && (
              <Typography variant="body2" color="error">
                {errors.atLeastOneTrue}
              </Typography>
            )}
        </FormGroup>
        <br />
        <Typography variant="body2">
          I declare that I have answered yes to A and/or B, and wish to be treated as a high net worth individual.
        </Typography>
        <br />
        <Typography variant="body2">
          I understand that this means:
          <ol type="a">
            <li>I can receive financial promotions where the contents may not comply with rules made by the Financial Conduct Authority (FCA).</li>
            <li>
              I can expect <strong>no protection</strong> from the FCA, the Financial Ombudsman Service, or the Financial Services Compensation
              Scheme.
            </li>
          </ol>
        </Typography>
        <Typography variant="body2">
          I am aware that it is open to me to seek advice from someone who specialises in advising on investments.
        </Typography>
        <br />
        <Typography variant="body2">
          <strong>I accept that I could lose all the money I invest.</strong>
        </Typography>
      </Statement>
    </FormGroup>
  );
};

export default HighNetWorthQualification;
