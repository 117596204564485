import { FC, useCallback, useEffect, useState } from "react";
import { Dialog, DialogProps, DialogContent, DialogTitle, Button, FormGroup, Typography, CircularProgress, Tooltip } from "@material-ui/core";
import * as yup from "yup";
import { Formik, Field, Form, FieldArray } from "formik";
import DatePickerField from "client/components/FormControls/DatePickerField.component";
import SelectField from "../../../components/FormControls/SelectField.component";
import _ from "lodash";
import FieldArrayRemoveButton from "client/components/FieldArrayRemoveButton.component";

export interface ValuationReportSection {
  existingValuationDate: string | null;
  newValuationDate: string | null;
  portfolioNames: string[] | null;
}

export interface ValuationReportValues {
  valuations: ValuationReportSection[];
}

interface Props extends DialogProps {
  selectedPortfolios: string[];
  loading: boolean;
  onSubmit: (values: any) => void;
  onClose: () => void;
}

const EMPTY_VALUATION: ValuationReportSection = {
  existingValuationDate: null,
  newValuationDate: null,
  portfolioNames: [],
};

const VALIDATION_SCHEMA = yup.object().shape({
  valuations: yup.array(
    yup.object({
      existingValuationDate: yup.date().required("Please enter the Existing Valuation Date").nullable().typeError("Invalid Date"),
      newValuationDate: yup
        .date()
        .required("Please enter the New Valuation Date")
        .min(yup.ref("existingValuationDate"), "New valuation date cannot be before the existing valuation date. ")
        .nullable()
        .typeError("Invalid Date"),
      portfolioNames: yup.array().min(1, "You must select at least 1 portfolio"),
    }),
  ),
});

const INITIAL_VALUES = {
  valuations: [{ ...EMPTY_VALUATION, portfolioNames: [""] }] as ValuationReportSection[],
};

const ValuationReportForm: FC<Props> = ({ selectedPortfolios, loading, onClose, onSubmit, ...props }) => {
  const [availablePortfolios, setAvailablePortfolios] = useState<string[]>([]);

  useEffect(() => {
    setAvailablePortfolios(selectedPortfolios);
  }, [selectedPortfolios]);

  const onDialogClose = useCallback(() => {
    onClose();
    setAvailablePortfolios(selectedPortfolios);
  }, [onClose, selectedPortfolios]);

  /**
   * Handle changes on portfolio select inputs.

   * Builds the list of 'available' portfolios to use in the selection dropdowns,
   * taking into account ones that haven't been selected yet and ones that are currently
   * being used by the provided valuation selection (valuations[index].portfolioNames).
   *
   * @param {number} index Current index in the valuations array
   * @param {ValuationReportValues} values Current list of values provided by the form
   * @param {string[]} selectedOptions The currently selected option(s) (portfolios) in the input
   */
  const handleSelectChange = useCallback(
    async (index: number, values: ValuationReportValues, selectedOptions: string[]) => {
      const usedPortfolios: string[] = values.valuations.flatMap((valuation: ValuationReportSection) => valuation.portfolioNames) as string[];

      const currentValuationPortfolios = values.valuations[index].portfolioNames;

      let newPortfolios = availablePortfolios
        .filter((portfolio) => !usedPortfolios.includes(portfolio))
        .filter((availablePortfolio) => !selectedOptions.includes(availablePortfolio));

      setAvailablePortfolios([...newPortfolios, ..._.difference(currentValuationPortfolios, selectedOptions)] as string[]);
    },
    [availablePortfolios, setAvailablePortfolios],
  );

  return (
    <Dialog onClose={onDialogClose} {...props}>
      <DialogTitle>Generate Valuation Report</DialogTitle>
      <DialogContent>
        <Formik initialValues={INITIAL_VALUES} validationSchema={VALIDATION_SCHEMA} onSubmit={onSubmit}>
          {({ values, setFieldValue }) => (
            <Form>
              <Typography variant="body2" paragraph>
                An XLSX report will be generated, pulling valuation data from between the existing and new valuation dates provided. Portfolio data
                will only be extracted from the selected share transactions.
              </Typography>
              <FieldArray name="valuations">
                {({ push, remove }) => (
                  <>
                    {values.valuations.map((valuation, index) => (
                      <FormGroup key={index}>
                        <hr />
                        {index !== 0 && (
                          <>
                            <FieldArrayRemoveButton
                              handleClick={() => {
                                remove(index);
                                handleSelectChange(index, values, []);
                              }}
                            />
                            <Field
                              component={SelectField}
                              multiple
                              name={`valuations[${index}].portfolioNames`}
                              label="Portfolios"
                              options={[...availablePortfolios, ...(valuation.portfolioNames as string[])].sort(function (a, b) {
                                return a.localeCompare(b);
                              })}
                              onChange={(e: any, selectedOptions: string[]) => {
                                setFieldValue(`valuations[${index}].portfolioNames`, selectedOptions);
                                handleSelectChange(index, values, selectedOptions);
                              }}
                            />
                          </>
                        )}
                        <FormGroup row>
                          <Field
                            component={DatePickerField}
                            name={`valuations[${index}].existingValuationDate`}
                            label="Existing Valuation Date"
                            format="DD/MM/YYYY"
                          />
                          <Field
                            component={DatePickerField}
                            name={`valuations[${index}].newValuationDate`}
                            label="New Valuation Date"
                            format="DD/MM/YYYY"
                          />
                        </FormGroup>
                        <br />
                        {index === 0 && (
                          <Typography variant="body2" paragraph>
                            By default, all portfolios will use the above dates. If you want to apply different date ranges to different portfolios,
                            you can add new date ranges below.
                          </Typography>
                        )}
                      </FormGroup>
                    ))}
                    <hr />
                    <FormGroup>
                      <Button data-testid="addValuation" onClick={() => push(EMPTY_VALUATION)}>
                        Add New Date Range
                      </Button>
                    </FormGroup>
                  </>
                )}
              </FieldArray>
              <FormGroup>
                <Tooltip title="This may take a little while...">
                  <Button type="submit" fullWidth disabled={loading}>
                    {!loading ? "Generate Report" : <CircularProgress size={20} />}
                  </Button>
                </Tooltip>
              </FormGroup>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default ValuationReportForm;
