import { useMemo } from "react";
import { useLocation } from "react-router-dom";

/**
 * Enter search term as the input value and it will return the value for that search term
 **/
const useQuery = <Q extends string | string[]>(query: Q) => {
  const location = useLocation();

  const queryValue = useMemo(() => {
    if (typeof query === "string") {
      return new URLSearchParams(location.search).get(query);
    }
    return (query as string[]).map((queryKey) => new URLSearchParams(location.search).get(queryKey));
  }, [location.search, query]);

  return queryValue as Q extends string ? string | null : Array<string | null>;
};
export default useQuery;
