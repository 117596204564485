import { Container, FormGroup, makeStyles, Typography } from "@material-ui/core";
import ApplicationApi from "client/api/application.api";
import FormikErrorsTouched from "client/components/FormikErrorsTouched.component";
import { InitialValues } from "client/utils/helpers";
import { Field, Form, Formik } from "formik";
import React, { FC, useCallback, useContext, useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Experience, LeanApplication } from "server/services/application/application.types";
import * as Yup from "yup";
import * as AdminApplicationApi from "../../api/admin/application.adminApi";
import ClientApplicationApi from "../../api/advisor/client/application.clientApi";
import ErrorsList from "../../components/ErrorsList.component";
import NumberQuestion from "../../components/FormControls/NumberQuestion.component";
import RadioQuestion from "../../components/FormControls/RadioQuestion.component";
import TextQuestion from "../../components/FormControls/TextQuestion.component";
import { APPLICATION_TYPES } from "../../context/application.context";
import { AuthContext } from "../../context/auth.context";
import useAnalytics from "../../hooks/useAnalytics";
import { FORM_FIELDS_DATA, ROUTES, TRACKED_EVENTS } from "../../utils/constants";
import StageButtonGroup from "./StageButtonGroup.component";
import useApplication from "./useApplication";

interface Props {
  isAdmin?: boolean;
}

const { KNOWLEDGE_EXPERIENCE_FORM } = FORM_FIELDS_DATA;

const useStyles = makeStyles(
  (theme) => ({
    sectionTitle: {
      fontWeight: 600,
    },
    formGroup: {
      paddingBottom: theme.spacing(5),
      [theme.breakpoints.down("md")]: {
        paddingBottom: theme.spacing(3),
      },
    },
    formItem: {
      marginTop: "20px",
      marginLeft: "40px",
    },
    hr: {
      borderTop: "solid 1px",
    },
    infoText: {
      fontWeight: 600,
    },
  }),
  { name: "KnowledgeExperienceView" },
);

const {
  INPUT: {
    CATEGORIES: { KNOWLEDGE_EXPERIENCE },
  },
  PAGE_VIEWS,
  PAGE_ACTIONS,
} = TRACKED_EVENTS;

const REQUIRED_MESSAGES = {
  TIMES_INVESTED: "Please enter no. of times invested",
  NO_YEARS: "Please enter no. of years",
  AMOUNT_HELD: "Please enter the amount held or lent",
};

const SCHEMA = Yup.object().shape({
  [KNOWLEDGE_EXPERIENCE_FORM.taxProducts.id]: Yup.object().shape({
    [KNOWLEDGE_EXPERIENCE_FORM.taxProducts.times.id]: Yup.number().required(REQUIRED_MESSAGES.TIMES_INVESTED),
    [KNOWLEDGE_EXPERIENCE_FORM.taxProducts.years.id]: Yup.number().required(REQUIRED_MESSAGES.NO_YEARS),
    [KNOWLEDGE_EXPERIENCE_FORM.taxProducts.amount.id]: Yup.number().required(REQUIRED_MESSAGES.AMOUNT_HELD),
  }),
  [KNOWLEDGE_EXPERIENCE_FORM.largeCompanies.id]: Yup.object().shape({
    [KNOWLEDGE_EXPERIENCE_FORM.largeCompanies.times.id]: Yup.number().required(REQUIRED_MESSAGES.TIMES_INVESTED),
    [KNOWLEDGE_EXPERIENCE_FORM.largeCompanies.years.id]: Yup.number().required(REQUIRED_MESSAGES.NO_YEARS),
    [KNOWLEDGE_EXPERIENCE_FORM.largeCompanies.amount.id]: Yup.number().required(REQUIRED_MESSAGES.AMOUNT_HELD),
  }),
  [KNOWLEDGE_EXPERIENCE_FORM.smallCompanies.id]: Yup.object().shape({
    [KNOWLEDGE_EXPERIENCE_FORM.smallCompanies.times.id]: Yup.number().required(REQUIRED_MESSAGES.TIMES_INVESTED),
    [KNOWLEDGE_EXPERIENCE_FORM.smallCompanies.years.id]: Yup.number().required(REQUIRED_MESSAGES.NO_YEARS),
    [KNOWLEDGE_EXPERIENCE_FORM.smallCompanies.amount.id]: Yup.number().required(REQUIRED_MESSAGES.AMOUNT_HELD),
  }),
  [KNOWLEDGE_EXPERIENCE_FORM.aimCompanies.id]: Yup.object().shape({
    [KNOWLEDGE_EXPERIENCE_FORM.aimCompanies.times.id]: Yup.number().required(REQUIRED_MESSAGES.TIMES_INVESTED),
    [KNOWLEDGE_EXPERIENCE_FORM.aimCompanies.years.id]: Yup.number().required(REQUIRED_MESSAGES.NO_YEARS),
    [KNOWLEDGE_EXPERIENCE_FORM.aimCompanies.amount.id]: Yup.number().required(REQUIRED_MESSAGES.AMOUNT_HELD),
  }),
  [KNOWLEDGE_EXPERIENCE_FORM.unquotedCompanies.id]: Yup.object().shape({
    [KNOWLEDGE_EXPERIENCE_FORM.unquotedCompanies.times.id]: Yup.number().required(REQUIRED_MESSAGES.TIMES_INVESTED),
    [KNOWLEDGE_EXPERIENCE_FORM.unquotedCompanies.years.id]: Yup.number().required(REQUIRED_MESSAGES.NO_YEARS),
    [KNOWLEDGE_EXPERIENCE_FORM.unquotedCompanies.amount.id]: Yup.number().required(REQUIRED_MESSAGES.AMOUNT_HELD),
  }),
  [KNOWLEDGE_EXPERIENCE_FORM.realProperties.id]: Yup.object().shape({
    [KNOWLEDGE_EXPERIENCE_FORM.realProperties.times.id]: Yup.number().required(REQUIRED_MESSAGES.TIMES_INVESTED),
    [KNOWLEDGE_EXPERIENCE_FORM.realProperties.years.id]: Yup.number().required(REQUIRED_MESSAGES.NO_YEARS),
    [KNOWLEDGE_EXPERIENCE_FORM.realProperties.amount.id]: Yup.number().required(REQUIRED_MESSAGES.AMOUNT_HELD),
  }),
  [KNOWLEDGE_EXPERIENCE_FORM.otherSophisticatedInvestments.id]: Yup.object().shape({
    [KNOWLEDGE_EXPERIENCE_FORM.otherSophisticatedInvestments.times.id]: Yup.number().required(REQUIRED_MESSAGES.TIMES_INVESTED),
    [KNOWLEDGE_EXPERIENCE_FORM.otherSophisticatedInvestments.years.id]: Yup.number().required(REQUIRED_MESSAGES.NO_YEARS),
    [KNOWLEDGE_EXPERIENCE_FORM.otherSophisticatedInvestments.amount.id]: Yup.number().required(REQUIRED_MESSAGES.AMOUNT_HELD),
  }),
  [KNOWLEDGE_EXPERIENCE_FORM.lentUnquotedCompanies.id]: Yup.object().shape({
    [KNOWLEDGE_EXPERIENCE_FORM.lentUnquotedCompanies.times.id]: Yup.number().required(REQUIRED_MESSAGES.TIMES_INVESTED),
    [KNOWLEDGE_EXPERIENCE_FORM.lentUnquotedCompanies.years.id]: Yup.number().required(REQUIRED_MESSAGES.NO_YEARS),
    [KNOWLEDGE_EXPERIENCE_FORM.lentUnquotedCompanies.amount.id]: Yup.number().required(REQUIRED_MESSAGES.AMOUNT_HELD),
  }),
  [KNOWLEDGE_EXPERIENCE_FORM.companyDirector.id]: Yup.object().shape({
    [KNOWLEDGE_EXPERIENCE_FORM.companyDirector.times.id]: Yup.number().required(REQUIRED_MESSAGES.TIMES_INVESTED),
    [KNOWLEDGE_EXPERIENCE_FORM.companyDirector.years.id]: Yup.number().required(REQUIRED_MESSAGES.NO_YEARS),
  }),
  [KNOWLEDGE_EXPERIENCE_FORM.managementTeam.id]: Yup.object().shape({
    [KNOWLEDGE_EXPERIENCE_FORM.managementTeam.times.id]: Yup.number().required(REQUIRED_MESSAGES.TIMES_INVESTED),
    [KNOWLEDGE_EXPERIENCE_FORM.managementTeam.years.id]: Yup.number().required(REQUIRED_MESSAGES.NO_YEARS),
  }),
  [KNOWLEDGE_EXPERIENCE_FORM.annualInvestment.id]: Yup.string().required(
    "Please indicate how much do you invest in these types of investments each year",
  ),
  [KNOWLEDGE_EXPERIENCE_FORM.cashEquivalents.id]: Yup.string().required(
    "Please indicate how much do you approximately hold in cash or cash equivalents",
  ),
  [KNOWLEDGE_EXPERIENCE_FORM.educationHistory.id]: Yup.string().required("Please indicate your education history").nullable(),
  [KNOWLEDGE_EXPERIENCE_FORM.occupation.id]: Yup.string().required("Please enter occupation"),
  [KNOWLEDGE_EXPERIENCE_FORM.relevantProfession.id]: Yup.string(),
  [KNOWLEDGE_EXPERIENCE_FORM.taxationAdvice.id]: Yup.boolean().required("Please select either Yes or No").nullable(),
  [KNOWLEDGE_EXPERIENCE_FORM.capitalCommitmentsNext10Years.id]: Yup.boolean().required("Please select either Yes or No").nullable(),
  [KNOWLEDGE_EXPERIENCE_FORM.levelOfRisk.id]: Yup.string().required("Please select a level of risk"),
  [KNOWLEDGE_EXPERIENCE_FORM.holdInEarlyStageCompanies.id]: Yup.number().required(
    "Please enter the amount you propose to hold in early-stage unquoted companies",
  ),
  [KNOWLEDGE_EXPERIENCE_FORM.percentageNetWorth.id]: Yup.number().required("Please enter the percentage of your net worth"),
});

const DEFAULT_VALUES = {
  [KNOWLEDGE_EXPERIENCE_FORM.taxProducts.id]: {
    [KNOWLEDGE_EXPERIENCE_FORM.taxProducts.times.id]: "",
    [KNOWLEDGE_EXPERIENCE_FORM.taxProducts.years.id]: "",
    [KNOWLEDGE_EXPERIENCE_FORM.taxProducts.amount.id]: "",
  },
  [KNOWLEDGE_EXPERIENCE_FORM.largeCompanies.id]: {
    [KNOWLEDGE_EXPERIENCE_FORM.largeCompanies.times.id]: "",
    [KNOWLEDGE_EXPERIENCE_FORM.largeCompanies.years.id]: "",
    [KNOWLEDGE_EXPERIENCE_FORM.largeCompanies.amount.id]: "",
  },
  [KNOWLEDGE_EXPERIENCE_FORM.smallCompanies.id]: {
    [KNOWLEDGE_EXPERIENCE_FORM.smallCompanies.times.id]: "",
    [KNOWLEDGE_EXPERIENCE_FORM.smallCompanies.years.id]: "",
    [KNOWLEDGE_EXPERIENCE_FORM.smallCompanies.amount.id]: "",
  },
  [KNOWLEDGE_EXPERIENCE_FORM.aimCompanies.id]: {
    [KNOWLEDGE_EXPERIENCE_FORM.aimCompanies.times.id]: "",
    [KNOWLEDGE_EXPERIENCE_FORM.aimCompanies.years.id]: "",
    [KNOWLEDGE_EXPERIENCE_FORM.aimCompanies.amount.id]: "",
  },
  [KNOWLEDGE_EXPERIENCE_FORM.unquotedCompanies.id]: {
    [KNOWLEDGE_EXPERIENCE_FORM.unquotedCompanies.times.id]: "",
    [KNOWLEDGE_EXPERIENCE_FORM.unquotedCompanies.years.id]: "",
    [KNOWLEDGE_EXPERIENCE_FORM.unquotedCompanies.amount.id]: "",
  },
  [KNOWLEDGE_EXPERIENCE_FORM.realProperties.id]: {
    [KNOWLEDGE_EXPERIENCE_FORM.realProperties.times.id]: "",
    [KNOWLEDGE_EXPERIENCE_FORM.realProperties.years.id]: "",
    [KNOWLEDGE_EXPERIENCE_FORM.realProperties.amount.id]: "",
  },
  [KNOWLEDGE_EXPERIENCE_FORM.otherSophisticatedInvestments.id]: {
    [KNOWLEDGE_EXPERIENCE_FORM.otherSophisticatedInvestments.times.id]: "",
    [KNOWLEDGE_EXPERIENCE_FORM.otherSophisticatedInvestments.years.id]: "",
    [KNOWLEDGE_EXPERIENCE_FORM.otherSophisticatedInvestments.amount.id]: "",
  },
  [KNOWLEDGE_EXPERIENCE_FORM.lentUnquotedCompanies.id]: {
    [KNOWLEDGE_EXPERIENCE_FORM.lentUnquotedCompanies.times.id]: "",
    [KNOWLEDGE_EXPERIENCE_FORM.lentUnquotedCompanies.years.id]: "",
    [KNOWLEDGE_EXPERIENCE_FORM.lentUnquotedCompanies.amount.id]: "",
  },
  [KNOWLEDGE_EXPERIENCE_FORM.companyDirector.id]: {
    [KNOWLEDGE_EXPERIENCE_FORM.companyDirector.times.id]: "",
    [KNOWLEDGE_EXPERIENCE_FORM.companyDirector.years.id]: "",
  },
  [KNOWLEDGE_EXPERIENCE_FORM.managementTeam.id]: {
    [KNOWLEDGE_EXPERIENCE_FORM.managementTeam.times.id]: "",
    [KNOWLEDGE_EXPERIENCE_FORM.managementTeam.years.id]: "",
  },
  [KNOWLEDGE_EXPERIENCE_FORM.annualInvestment.id]: "",
  [KNOWLEDGE_EXPERIENCE_FORM.cashEquivalents.id]: "",
  [KNOWLEDGE_EXPERIENCE_FORM.educationHistory.id]: null,
  [KNOWLEDGE_EXPERIENCE_FORM.occupation.id]: "",
  [KNOWLEDGE_EXPERIENCE_FORM.relevantProfession.id]: "",
  [KNOWLEDGE_EXPERIENCE_FORM.taxationAdvice.id]: null,
  [KNOWLEDGE_EXPERIENCE_FORM.capitalCommitmentsNext10Years.id]: null,
  [KNOWLEDGE_EXPERIENCE_FORM.levelOfRisk.id]: "",
  [KNOWLEDGE_EXPERIENCE_FORM.holdInEarlyStageCompanies.id]: "",
  [KNOWLEDGE_EXPERIENCE_FORM.percentageNetWorth.id]: "",
} as const;

const KnowledgeExperience: FC<Props> = ({ isAdmin, ...props }) => {
  const classes = useStyles(props);
  const {
    state: { user },
  } = useContext(AuthContext);
  const history = useHistory();
  const params = useParams<{ applicationId?: string; clientID?: string }>();
  const applicationId = useMemo(() => params.applicationId, [params.applicationId]);
  const clientId = useMemo(() => user?.isAdvisor && params.clientID, [params.clientID, user?.isAdvisor]);
  const { application, dispatch } = useApplication(isAdmin);

  useAnalytics();

  useEffect(() => {
    globalThis.dataLayer.push({
      event: clientId ? PAGE_VIEWS.CLIENT_APPLICATION_FORM_EXPERIENCE : PAGE_VIEWS.INVESTOR_APPLICATION_KNOWLEDGE,
    });
  }, [clientId]);

  const goForward = useCallback(() => {
    history.push(`..${ROUTES.APPLICATION_INVESTMENT}`);
  }, [history]);

  const handleFormSubmit = useCallback(
    async (values) => {
      let submittedApplication: LeanApplication | null = null;
      if (clientId) {
        const res = await ClientApplicationApi.postExperience(clientId, application!._id, values);
        if (res) {
          submittedApplication = res;
          globalThis.dataLayer.push({ event: PAGE_ACTIONS.CLIENT_APPLICATION_ABOUT_FORM_SUBMITTED_SUCCESSFULLY });
        } else {
          globalThis.dataLayer.push({ event: PAGE_ACTIONS.CLIENT_APPLICATION_ABOUT_FORM_SUBMITTED_UNSUCCESSFULLY });
        }
      } else if (isAdmin) {
        const res = await AdminApplicationApi.postExperience(applicationId!, values);
        if (res) submittedApplication = res;
      } else {
        const res = await ApplicationApi.postExperience(application!._id, values);
        if (res) {
          submittedApplication = res;
          globalThis.dataLayer.push({ event: PAGE_ACTIONS.INVESTOR_APPLICATION_FORM_ABOUT_SUBMITTED_SUCCESSFULLY });
        } else {
          globalThis.dataLayer.push({
            event: PAGE_ACTIONS.INVESTOR_APPLICATION_FORM_ABOUT_SUBMITTED_UNSUCCESSFULLY,
          });
        }
      }
      if (submittedApplication) {
        dispatch({ type: APPLICATION_TYPES.SET_APPLICATION, payload: submittedApplication });
        goForward();
      }
    },
    [application, applicationId, clientId, dispatch, goForward, isAdmin],
  );

  const INITIAL_VALUES = useMemo<InitialValues<Experience>>(
    () =>
      application?.data?.experience
        ? ({
            ...DEFAULT_VALUES,
            ...application.data.experience,
            [KNOWLEDGE_EXPERIENCE_FORM.taxProducts.id]:
              typeof application.data.experience[KNOWLEDGE_EXPERIENCE_FORM.taxProducts.id] === "number"
                ? {
                    ...DEFAULT_VALUES[KNOWLEDGE_EXPERIENCE_FORM.taxProducts.id],
                    [KNOWLEDGE_EXPERIENCE_FORM.taxProducts.times.id]: application.data.experience[KNOWLEDGE_EXPERIENCE_FORM.taxProducts.id],
                  }
                : typeof application.data.experience[KNOWLEDGE_EXPERIENCE_FORM.taxProducts.id] === "object"
                ? {
                    ...DEFAULT_VALUES[KNOWLEDGE_EXPERIENCE_FORM.taxProducts.id],
                    ...application.data.experience[KNOWLEDGE_EXPERIENCE_FORM.taxProducts.id],
                  }
                : DEFAULT_VALUES[KNOWLEDGE_EXPERIENCE_FORM.taxProducts.id],
            [KNOWLEDGE_EXPERIENCE_FORM.largeCompanies.id]:
              typeof application.data.experience[KNOWLEDGE_EXPERIENCE_FORM.largeCompanies.id] === "number"
                ? {
                    ...DEFAULT_VALUES[KNOWLEDGE_EXPERIENCE_FORM.largeCompanies.id],
                    [KNOWLEDGE_EXPERIENCE_FORM.largeCompanies.times.id]: application.data.experience[KNOWLEDGE_EXPERIENCE_FORM.largeCompanies.id],
                  }
                : typeof application.data.experience[KNOWLEDGE_EXPERIENCE_FORM.largeCompanies.id] === "object"
                ? {
                    ...DEFAULT_VALUES[KNOWLEDGE_EXPERIENCE_FORM.largeCompanies.id],
                    ...application.data.experience[KNOWLEDGE_EXPERIENCE_FORM.largeCompanies.id],
                  }
                : DEFAULT_VALUES[KNOWLEDGE_EXPERIENCE_FORM.largeCompanies.id],
            [KNOWLEDGE_EXPERIENCE_FORM.smallCompanies.id]:
              typeof application.data.experience[KNOWLEDGE_EXPERIENCE_FORM.smallCompanies.id] === "number"
                ? {
                    ...DEFAULT_VALUES[KNOWLEDGE_EXPERIENCE_FORM.smallCompanies.id],
                    [KNOWLEDGE_EXPERIENCE_FORM.smallCompanies.times.id]: application.data.experience[KNOWLEDGE_EXPERIENCE_FORM.smallCompanies.id],
                  }
                : typeof application.data.experience[KNOWLEDGE_EXPERIENCE_FORM.smallCompanies.id] === "object"
                ? {
                    ...DEFAULT_VALUES[KNOWLEDGE_EXPERIENCE_FORM.smallCompanies.id],
                    ...application.data.experience[KNOWLEDGE_EXPERIENCE_FORM.smallCompanies.id],
                  }
                : DEFAULT_VALUES[KNOWLEDGE_EXPERIENCE_FORM.smallCompanies.id],
            [KNOWLEDGE_EXPERIENCE_FORM.aimCompanies.id]:
              typeof application.data.experience[KNOWLEDGE_EXPERIENCE_FORM.aimCompanies.id] === "number"
                ? {
                    ...DEFAULT_VALUES[KNOWLEDGE_EXPERIENCE_FORM.aimCompanies.id],
                    [KNOWLEDGE_EXPERIENCE_FORM.aimCompanies.times.id]: application.data.experience[KNOWLEDGE_EXPERIENCE_FORM.aimCompanies.id],
                  }
                : typeof application.data.experience[KNOWLEDGE_EXPERIENCE_FORM.aimCompanies.id] === "object"
                ? {
                    ...DEFAULT_VALUES[KNOWLEDGE_EXPERIENCE_FORM.aimCompanies.id],
                    ...application.data.experience[KNOWLEDGE_EXPERIENCE_FORM.aimCompanies.id],
                  }
                : DEFAULT_VALUES[KNOWLEDGE_EXPERIENCE_FORM.aimCompanies.id],
            [KNOWLEDGE_EXPERIENCE_FORM.unquotedCompanies.id]:
              typeof application.data.experience[KNOWLEDGE_EXPERIENCE_FORM.unquotedCompanies.id] === "number"
                ? {
                    ...DEFAULT_VALUES[KNOWLEDGE_EXPERIENCE_FORM.unquotedCompanies.id],
                    [KNOWLEDGE_EXPERIENCE_FORM.unquotedCompanies.times.id]:
                      application.data.experience[KNOWLEDGE_EXPERIENCE_FORM.unquotedCompanies.id],
                  }
                : typeof application.data.experience[KNOWLEDGE_EXPERIENCE_FORM.unquotedCompanies.id] === "object"
                ? {
                    ...DEFAULT_VALUES[KNOWLEDGE_EXPERIENCE_FORM.unquotedCompanies.id],
                    ...application.data.experience[KNOWLEDGE_EXPERIENCE_FORM.unquotedCompanies.id],
                  }
                : DEFAULT_VALUES[KNOWLEDGE_EXPERIENCE_FORM.unquotedCompanies.id],
            [KNOWLEDGE_EXPERIENCE_FORM.otherSophisticatedInvestments.id]:
              typeof application.data.experience[KNOWLEDGE_EXPERIENCE_FORM.otherSophisticatedInvestments.id] === "number"
                ? {
                    ...DEFAULT_VALUES[KNOWLEDGE_EXPERIENCE_FORM.otherSophisticatedInvestments.id],
                    [KNOWLEDGE_EXPERIENCE_FORM.otherSophisticatedInvestments.times.id]:
                      application.data.experience[KNOWLEDGE_EXPERIENCE_FORM.otherSophisticatedInvestments.id],
                  }
                : typeof application.data.experience[KNOWLEDGE_EXPERIENCE_FORM.otherSophisticatedInvestments.id] === "object"
                ? {
                    ...DEFAULT_VALUES[KNOWLEDGE_EXPERIENCE_FORM.otherSophisticatedInvestments.id],
                    ...application.data.experience[KNOWLEDGE_EXPERIENCE_FORM.otherSophisticatedInvestments.id],
                  }
                : DEFAULT_VALUES[KNOWLEDGE_EXPERIENCE_FORM.otherSophisticatedInvestments.id],
          } as InitialValues<Experience>)
        : { ...DEFAULT_VALUES },
    [application],
  );

  return (
    <>
      <Container>
        <FormGroup>
          <Typography variant="h2" align="center">
            Appropriateness questionnaire
          </Typography>
        </FormGroup>
      </Container>
      <hr className={classes.hr} />
      <Formik initialValues={INITIAL_VALUES} validateOnMount={true} enableReinitialize={true} validationSchema={SCHEMA} onSubmit={handleFormSubmit}>
        {({ errors, submitCount }) => (
          <Form autoComplete="off" noValidate>
            <FormikErrorsTouched />
            <Container maxWidth="md">
              <FormGroup>
                <Typography variant="h4" className={classes.sectionTitle}>
                  Section 4:
                </Typography>
                <Typography variant="h4" paragraph>
                  Knowledge & Experience
                </Typography>
                <Typography variant="h5" paragraph className={classes.infoText}>
                  For each of the following scenarios, please confirm the number of times and how many years you have done so as well as how much you
                  hold (if applicable):
                </Typography>
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <Typography variant="h4" className="fs-exclude">
                  {KNOWLEDGE_EXPERIENCE_FORM.taxProducts.title}
                </Typography>
                <div className={classes.formItem}>
                  <Field
                    component={NumberQuestion}
                    name={KNOWLEDGE_EXPERIENCE_FORM.taxProducts.times.name}
                    label={KNOWLEDGE_EXPERIENCE_FORM.taxProducts.times.label}
                    placeholder={KNOWLEDGE_EXPERIENCE_FORM.taxProducts.times.placeholder}
                    trackedCategory={KNOWLEDGE_EXPERIENCE}
                    min="0"
                    max="99"
                  />
                </div>
                <div className={classes.formItem}>
                  <Field
                    component={NumberQuestion}
                    name={KNOWLEDGE_EXPERIENCE_FORM.taxProducts.years.name}
                    label={KNOWLEDGE_EXPERIENCE_FORM.taxProducts.years.label}
                    placeholder={KNOWLEDGE_EXPERIENCE_FORM.taxProducts.years.placeholder}
                    trackedCategory={KNOWLEDGE_EXPERIENCE}
                    min="0"
                    max="99"
                  />
                </div>
                <div className={classes.formItem}>
                  <Field
                    component={NumberQuestion}
                    name={KNOWLEDGE_EXPERIENCE_FORM.taxProducts.amount.name}
                    label={KNOWLEDGE_EXPERIENCE_FORM.taxProducts.amount.label}
                    placeholder={KNOWLEDGE_EXPERIENCE_FORM.taxProducts.amount.placeholder}
                    trackedCategory={KNOWLEDGE_EXPERIENCE}
                    moneyFormat
                    min="0"
                    max="99"
                  />
                </div>
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <Typography variant="h4" className="fs-exclude">
                  {KNOWLEDGE_EXPERIENCE_FORM.largeCompanies.title}
                </Typography>
                <div className={classes.formItem}>
                  <Field
                    component={NumberQuestion}
                    name={KNOWLEDGE_EXPERIENCE_FORM.largeCompanies.times.name}
                    label={KNOWLEDGE_EXPERIENCE_FORM.largeCompanies.times.label}
                    placeholder={KNOWLEDGE_EXPERIENCE_FORM.largeCompanies.times.placeholder}
                    trackedCategory={KNOWLEDGE_EXPERIENCE}
                    min="0"
                    max="99"
                  />
                </div>
                <div className={classes.formItem}>
                  <Field
                    component={NumberQuestion}
                    name={KNOWLEDGE_EXPERIENCE_FORM.largeCompanies.years.name}
                    label={KNOWLEDGE_EXPERIENCE_FORM.largeCompanies.years.label}
                    placeholder={KNOWLEDGE_EXPERIENCE_FORM.largeCompanies.years.placeholder}
                    trackedCategory={KNOWLEDGE_EXPERIENCE}
                    min="0"
                    max="99"
                  />
                </div>
                <div className={classes.formItem}>
                  <Field
                    component={NumberQuestion}
                    name={KNOWLEDGE_EXPERIENCE_FORM.largeCompanies.amount.name}
                    label={KNOWLEDGE_EXPERIENCE_FORM.largeCompanies.amount.label}
                    placeholder={KNOWLEDGE_EXPERIENCE_FORM.largeCompanies.amount.placeholder}
                    trackedCategory={KNOWLEDGE_EXPERIENCE}
                    moneyFormat
                    min="0"
                    max="99"
                  />
                </div>
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <Typography variant="h4" className="fs-exclude">
                  {KNOWLEDGE_EXPERIENCE_FORM.smallCompanies.title}
                </Typography>
                <div className={classes.formItem}>
                  <Field
                    component={NumberQuestion}
                    name={KNOWLEDGE_EXPERIENCE_FORM.smallCompanies.times.name}
                    label={KNOWLEDGE_EXPERIENCE_FORM.smallCompanies.times.label}
                    placeholder={KNOWLEDGE_EXPERIENCE_FORM.smallCompanies.times.placeholder}
                    trackedCategory={KNOWLEDGE_EXPERIENCE}
                    min="0"
                    max="99"
                  />
                </div>
                <div className={classes.formItem}>
                  <Field
                    component={NumberQuestion}
                    name={KNOWLEDGE_EXPERIENCE_FORM.smallCompanies.years.name}
                    label={KNOWLEDGE_EXPERIENCE_FORM.smallCompanies.years.label}
                    placeholder={KNOWLEDGE_EXPERIENCE_FORM.smallCompanies.years.placeholder}
                    trackedCategory={KNOWLEDGE_EXPERIENCE}
                    min="0"
                    max="99"
                  />
                </div>
                <div className={classes.formItem}>
                  <Field
                    component={NumberQuestion}
                    name={KNOWLEDGE_EXPERIENCE_FORM.smallCompanies.amount.name}
                    label={KNOWLEDGE_EXPERIENCE_FORM.smallCompanies.amount.label}
                    placeholder={KNOWLEDGE_EXPERIENCE_FORM.smallCompanies.amount.placeholder}
                    trackedCategory={KNOWLEDGE_EXPERIENCE}
                    moneyFormat
                    min="0"
                    max="99"
                  />
                </div>
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <Typography variant="h4" className="fs-exclude">
                  {KNOWLEDGE_EXPERIENCE_FORM.aimCompanies.title}
                </Typography>
                <div className={classes.formItem}>
                  <Field
                    component={NumberQuestion}
                    name={KNOWLEDGE_EXPERIENCE_FORM.aimCompanies.times.name}
                    label={KNOWLEDGE_EXPERIENCE_FORM.aimCompanies.times.label}
                    placeholder={KNOWLEDGE_EXPERIENCE_FORM.aimCompanies.times.placeholder}
                    trackedCategory={KNOWLEDGE_EXPERIENCE}
                    min="0"
                    max="99"
                  />
                </div>
                <div className={classes.formItem}>
                  <Field
                    component={NumberQuestion}
                    name={KNOWLEDGE_EXPERIENCE_FORM.aimCompanies.years.name}
                    label={KNOWLEDGE_EXPERIENCE_FORM.aimCompanies.years.label}
                    placeholder={KNOWLEDGE_EXPERIENCE_FORM.aimCompanies.years.placeholder}
                    trackedCategory={KNOWLEDGE_EXPERIENCE}
                    min="0"
                    max="99"
                  />
                </div>
                <div className={classes.formItem}>
                  <Field
                    component={NumberQuestion}
                    name={KNOWLEDGE_EXPERIENCE_FORM.aimCompanies.amount.name}
                    label={KNOWLEDGE_EXPERIENCE_FORM.aimCompanies.amount.label}
                    placeholder={KNOWLEDGE_EXPERIENCE_FORM.aimCompanies.amount.placeholder}
                    trackedCategory={KNOWLEDGE_EXPERIENCE}
                    moneyFormat
                    min="0"
                    max="99"
                  />
                </div>
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <Typography variant="h4" className="fs-exclude">
                  {KNOWLEDGE_EXPERIENCE_FORM.unquotedCompanies.title}
                </Typography>
                <div className={classes.formItem}>
                  <Field
                    component={NumberQuestion}
                    name={KNOWLEDGE_EXPERIENCE_FORM.unquotedCompanies.times.name}
                    label={KNOWLEDGE_EXPERIENCE_FORM.unquotedCompanies.times.label}
                    placeholder={KNOWLEDGE_EXPERIENCE_FORM.unquotedCompanies.times.placeholder}
                    trackedCategory={KNOWLEDGE_EXPERIENCE}
                    min="0"
                    max="99"
                  />
                </div>
                <div className={classes.formItem}>
                  <Field
                    component={NumberQuestion}
                    name={KNOWLEDGE_EXPERIENCE_FORM.unquotedCompanies.years.name}
                    label={KNOWLEDGE_EXPERIENCE_FORM.unquotedCompanies.years.label}
                    placeholder={KNOWLEDGE_EXPERIENCE_FORM.unquotedCompanies.years.placeholder}
                    trackedCategory={KNOWLEDGE_EXPERIENCE}
                    min="0"
                    max="99"
                  />
                </div>
                <div className={classes.formItem}>
                  <Field
                    component={NumberQuestion}
                    name={KNOWLEDGE_EXPERIENCE_FORM.unquotedCompanies.amount.name}
                    label={KNOWLEDGE_EXPERIENCE_FORM.unquotedCompanies.amount.label}
                    placeholder={KNOWLEDGE_EXPERIENCE_FORM.unquotedCompanies.amount.placeholder}
                    trackedCategory={KNOWLEDGE_EXPERIENCE}
                    moneyFormat
                    min="0"
                    max="99"
                  />
                </div>
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <Typography variant="h4" className="fs-exclude">
                  {KNOWLEDGE_EXPERIENCE_FORM.realProperties.title}
                </Typography>
                <div className={classes.formItem}>
                  <Field
                    component={NumberQuestion}
                    name={KNOWLEDGE_EXPERIENCE_FORM.realProperties.times.name}
                    label={KNOWLEDGE_EXPERIENCE_FORM.realProperties.times.label}
                    placeholder={KNOWLEDGE_EXPERIENCE_FORM.realProperties.times.placeholder}
                    trackedCategory={KNOWLEDGE_EXPERIENCE}
                    min="0"
                    max="99"
                  />
                </div>
                <div className={classes.formItem}>
                  <Field
                    component={NumberQuestion}
                    name={KNOWLEDGE_EXPERIENCE_FORM.realProperties.years.name}
                    label={KNOWLEDGE_EXPERIENCE_FORM.realProperties.years.label}
                    placeholder={KNOWLEDGE_EXPERIENCE_FORM.realProperties.years.placeholder}
                    trackedCategory={KNOWLEDGE_EXPERIENCE}
                    min="0"
                    max="99"
                  />
                </div>
                <div className={classes.formItem}>
                  <Field
                    component={NumberQuestion}
                    name={KNOWLEDGE_EXPERIENCE_FORM.realProperties.amount.name}
                    label={KNOWLEDGE_EXPERIENCE_FORM.realProperties.amount.label}
                    placeholder={KNOWLEDGE_EXPERIENCE_FORM.realProperties.amount.placeholder}
                    trackedCategory={KNOWLEDGE_EXPERIENCE}
                    moneyFormat
                    min="0"
                    max="99"
                  />
                </div>
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <Typography variant="h4" className="fs-exclude">
                  {KNOWLEDGE_EXPERIENCE_FORM.otherSophisticatedInvestments.title}
                </Typography>
                <div className={classes.formItem}>
                  <Field
                    component={NumberQuestion}
                    name={KNOWLEDGE_EXPERIENCE_FORM.otherSophisticatedInvestments.times.name}
                    label={KNOWLEDGE_EXPERIENCE_FORM.otherSophisticatedInvestments.times.label}
                    placeholder={KNOWLEDGE_EXPERIENCE_FORM.otherSophisticatedInvestments.times.placeholder}
                    trackedCategory={KNOWLEDGE_EXPERIENCE}
                    min="0"
                    max="99"
                  />
                </div>
                <div className={classes.formItem}>
                  <Field
                    component={NumberQuestion}
                    name={KNOWLEDGE_EXPERIENCE_FORM.otherSophisticatedInvestments.years.name}
                    label={KNOWLEDGE_EXPERIENCE_FORM.otherSophisticatedInvestments.years.label}
                    placeholder={KNOWLEDGE_EXPERIENCE_FORM.otherSophisticatedInvestments.years.placeholder}
                    trackedCategory={KNOWLEDGE_EXPERIENCE}
                    min="0"
                    max="99"
                  />
                </div>
                <div className={classes.formItem}>
                  <Field
                    component={NumberQuestion}
                    name={KNOWLEDGE_EXPERIENCE_FORM.otherSophisticatedInvestments.amount.name}
                    label={KNOWLEDGE_EXPERIENCE_FORM.otherSophisticatedInvestments.amount.label}
                    placeholder={KNOWLEDGE_EXPERIENCE_FORM.otherSophisticatedInvestments.amount.placeholder}
                    trackedCategory={KNOWLEDGE_EXPERIENCE}
                    moneyFormat
                    min="0"
                    max="99"
                  />
                </div>
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <Typography variant="h4" className="fs-exclude">
                  {KNOWLEDGE_EXPERIENCE_FORM.lentUnquotedCompanies.title}
                </Typography>
                <div className={classes.formItem}>
                  <Field
                    component={NumberQuestion}
                    name={KNOWLEDGE_EXPERIENCE_FORM.lentUnquotedCompanies.times.name}
                    label={KNOWLEDGE_EXPERIENCE_FORM.lentUnquotedCompanies.times.label}
                    placeholder={KNOWLEDGE_EXPERIENCE_FORM.lentUnquotedCompanies.times.placeholder}
                    trackedCategory={KNOWLEDGE_EXPERIENCE}
                    min="0"
                    max="99"
                  />
                </div>
                <div className={classes.formItem}>
                  <Field
                    component={NumberQuestion}
                    name={KNOWLEDGE_EXPERIENCE_FORM.lentUnquotedCompanies.years.name}
                    label={KNOWLEDGE_EXPERIENCE_FORM.lentUnquotedCompanies.years.label}
                    placeholder={KNOWLEDGE_EXPERIENCE_FORM.lentUnquotedCompanies.years.placeholder}
                    trackedCategory={KNOWLEDGE_EXPERIENCE}
                    min="0"
                    max="99"
                  />
                </div>
                <div className={classes.formItem}>
                  <Field
                    component={NumberQuestion}
                    name={KNOWLEDGE_EXPERIENCE_FORM.lentUnquotedCompanies.amount.name}
                    label={KNOWLEDGE_EXPERIENCE_FORM.lentUnquotedCompanies.amount.label}
                    placeholder={KNOWLEDGE_EXPERIENCE_FORM.lentUnquotedCompanies.amount.placeholder}
                    trackedCategory={KNOWLEDGE_EXPERIENCE}
                    moneyFormat
                    min="0"
                    max="99"
                  />
                </div>
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <Typography variant="h4" className="fs-exclude">
                  {KNOWLEDGE_EXPERIENCE_FORM.companyDirector.title}
                </Typography>
                <div className={classes.formItem}>
                  <Field
                    component={NumberQuestion}
                    name={KNOWLEDGE_EXPERIENCE_FORM.companyDirector.times.name}
                    label={KNOWLEDGE_EXPERIENCE_FORM.companyDirector.times.label}
                    placeholder={KNOWLEDGE_EXPERIENCE_FORM.companyDirector.times.placeholder}
                    trackedCategory={KNOWLEDGE_EXPERIENCE}
                    min="0"
                    max="99"
                  />
                </div>
                <div className={classes.formItem}>
                  <Field
                    component={NumberQuestion}
                    name={KNOWLEDGE_EXPERIENCE_FORM.companyDirector.years.name}
                    label={KNOWLEDGE_EXPERIENCE_FORM.companyDirector.years.label}
                    placeholder={KNOWLEDGE_EXPERIENCE_FORM.companyDirector.years.placeholder}
                    trackedCategory={KNOWLEDGE_EXPERIENCE}
                    min="0"
                    max="99"
                  />
                </div>
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <Typography variant="h4" className="fs-exclude">
                  {KNOWLEDGE_EXPERIENCE_FORM.managementTeam.title}
                </Typography>
                <div className={classes.formItem}>
                  <Field
                    component={NumberQuestion}
                    name={KNOWLEDGE_EXPERIENCE_FORM.managementTeam.times.name}
                    label={KNOWLEDGE_EXPERIENCE_FORM.managementTeam.times.label}
                    placeholder={KNOWLEDGE_EXPERIENCE_FORM.managementTeam.times.placeholder}
                    trackedCategory={KNOWLEDGE_EXPERIENCE}
                    min="0"
                    max="99"
                  />
                </div>
                <div className={classes.formItem}>
                  <Field
                    component={NumberQuestion}
                    name={KNOWLEDGE_EXPERIENCE_FORM.managementTeam.years.name}
                    label={KNOWLEDGE_EXPERIENCE_FORM.managementTeam.years.label}
                    placeholder={KNOWLEDGE_EXPERIENCE_FORM.managementTeam.years.placeholder}
                    trackedCategory={KNOWLEDGE_EXPERIENCE}
                    min="0"
                    max="99"
                  />
                </div>
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <Field
                  component={RadioQuestion}
                  name={KNOWLEDGE_EXPERIENCE_FORM.annualInvestment.name}
                  label={KNOWLEDGE_EXPERIENCE_FORM.annualInvestment.label}
                  trackedCategory={KNOWLEDGE_EXPERIENCE}
                  radioValues={[
                    "Above £100,000",
                    "£50,000-100,000",
                    "£25,000-50,000",
                    "£5,000-25,000",
                    "Less than £5,000",
                    "I don't invest in these types of investments",
                  ]}
                />
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <Field
                  component={RadioQuestion}
                  name={KNOWLEDGE_EXPERIENCE_FORM.educationHistory.name}
                  label={KNOWLEDGE_EXPERIENCE_FORM.educationHistory.label}
                  trackedCategory={KNOWLEDGE_EXPERIENCE}
                  radioValues={["Professional/Post-Graduate Qualification", "Higher Education/Degree", "Secondary Education"]}
                />
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <Field
                  component={TextQuestion}
                  name={KNOWLEDGE_EXPERIENCE_FORM.occupation.name}
                  label={KNOWLEDGE_EXPERIENCE_FORM.occupation.label}
                  placeholder={KNOWLEDGE_EXPERIENCE_FORM.occupation.placeholder}
                  trackedCategory={KNOWLEDGE_EXPERIENCE}
                />
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <Field
                  component={TextQuestion}
                  name={KNOWLEDGE_EXPERIENCE_FORM.relevantProfession.name}
                  label={KNOWLEDGE_EXPERIENCE_FORM.relevantProfession.label}
                  placeholder={KNOWLEDGE_EXPERIENCE_FORM.relevantProfession.placeholder}
                  trackedCategory={KNOWLEDGE_EXPERIENCE}
                />
              </FormGroup>
              <FormGroup>
                <Field
                  component={RadioQuestion}
                  name={KNOWLEDGE_EXPERIENCE_FORM.taxationAdvice.name}
                  label={KNOWLEDGE_EXPERIENCE_FORM.taxationAdvice.label}
                  trackedCategory={KNOWLEDGE_EXPERIENCE}
                />
              </FormGroup>
              <FormGroup>
                <Field
                  component={RadioQuestion}
                  name={KNOWLEDGE_EXPERIENCE_FORM.capitalCommitmentsNext10Years.name}
                  label={KNOWLEDGE_EXPERIENCE_FORM.capitalCommitmentsNext10Years.label}
                  trackedCategory={KNOWLEDGE_EXPERIENCE}
                />
              </FormGroup>
              <FormGroup>
                <Field
                  component={RadioQuestion}
                  name={KNOWLEDGE_EXPERIENCE_FORM.levelOfRisk.name}
                  label={KNOWLEDGE_EXPERIENCE_FORM.levelOfRisk.label}
                  trackedCategory={KNOWLEDGE_EXPERIENCE}
                  radioValues={[
                    "Conservative - Wishes to preserve capital or receive a moderate return while avoiding exposure to significant risk.",
                    "Moderately Conservative - Tolerant of a little risk to the capital in order to gain some returns but overall adverse to any significant loss of capital.",
                    "Moderate - Wishes to receive good returns over the long term and willing to take some risk to capital to achieve this.",
                    "Moderately Aggressive - Wishes to receive above average returns with aim of accumulating a significant amount of wealth and does not mind putting capital at risk or waiting for a significant amount of time to receive return on investment.",
                    "Aggressive - Seeks returns that are substantially higher than average and willing to expose capital to significant risk in order to achieve this objective.",
                  ]}
                />
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <Field
                  component={NumberQuestion}
                  name={KNOWLEDGE_EXPERIENCE_FORM.holdInEarlyStageCompanies.name}
                  label={KNOWLEDGE_EXPERIENCE_FORM.holdInEarlyStageCompanies.label}
                  trackedCategory={KNOWLEDGE_EXPERIENCE}
                  prefix="£"
                  moneyFormat
                />
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <Field
                  component={NumberQuestion}
                  name={KNOWLEDGE_EXPERIENCE_FORM.percentageNetWorth.name}
                  label={KNOWLEDGE_EXPERIENCE_FORM.percentageNetWorth.label}
                  trackedCategory={KNOWLEDGE_EXPERIENCE}
                  prefix="%"
                  min="0"
                  max="100"
                />
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <Field
                  component={RadioQuestion}
                  name={KNOWLEDGE_EXPERIENCE_FORM.cashEquivalents.name}
                  label={KNOWLEDGE_EXPERIENCE_FORM.cashEquivalents.label}
                  trackedCategory={KNOWLEDGE_EXPERIENCE}
                  radioValues={[
                    "Above £1,000,000",
                    "£500,000-1,000,000",
                    "£250,000-500,000",
                    "£100,000-250,000",
                    "£50,000-100,000",
                    "Less than £50,000",
                  ]}
                />
              </FormGroup>
              {submitCount > 0 && Object.keys(errors).length !== 0 && errors.constructor === Object && (
                <FormGroup>
                  <Typography variant="body2" color="error" paragraph>
                    Please complete the following fields:
                  </Typography>
                  <ErrorsList errors={errors} constant={KNOWLEDGE_EXPERIENCE_FORM} />
                </FormGroup>
              )}
              <FormGroup>
                <StageButtonGroup handleFormSubmit={handleFormSubmit} isAdmin={isAdmin} />
              </FormGroup>
            </Container>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default KnowledgeExperience;
