import fileDownload from "js-file-download";
import notifications from "../utils/notifications";
import { ipAxios } from "./ipAxios";

const getUserDocuments = async () => {
  try {
    const res = await ipAxios.get(`/user/documents`);
    return res.data;
  } catch (error) {
    notifications.genericError(error);
    return null;
  }
};

const downloadDocument = async (documentId, fileName) => {
  try {
    const res = await ipAxios({
      url: `/user/documents/${documentId}/download`,
      method: "GET",
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const blob = new Blob([res.data]);
    fileDownload(blob, fileName);
    return res.data;
  } catch (error) {
    notifications.genericError(error);
    return null;
  }
};

const getAdvisorDetails = async () => {
  try {
    const res = await ipAxios.get(`/user/advisor`);
    return res.data;
  } catch (error) {
    notifications.genericError(error);
    return null;
  }
};

export default {
  getUserDocuments,
  downloadDocument,
  getAdvisorDetails,
};
