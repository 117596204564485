import { Container, Link, makeStyles, Typography } from "@material-ui/core";
import DataTable, { ColumnDefinition } from "@wearenova/mui-data-table";
import ClientApplicationApi from "client/api/advisor/client/application.clientApi";
import ApplicationApi from "client/api/application.api";
import ApplicationsApi from "client/api/applications.api";
import UserContext, { UserContextTypes } from "client/context/user.context";
import { APPLICATION_STATES, ROUTES, TRACKED_EVENTS } from "client/utils/constants";
import clsx from "clsx";
import moment from "moment";
import { FC, useContext, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { LeanApplication } from "server/services/application/application.types";

interface Props {
  clientId: string;
}

const { PAGE_VIEWS, PAGE_ACTIONS } = TRACKED_EVENTS;

const useStyles = makeStyles(
  (theme) => ({
    usedLink: {
      color: theme.palette.text.usedLink,
    },
  }),
  { name: "ApplicationsView" },
);

const Applications: FC<Props> = ({ clientId, ...props }) => {
  const { applications, clients, dispatch } = useContext(UserContext);
  const classes = useStyles(props);
  const history = useHistory();
  const applicationsData: LeanApplication[] = useMemo(
    () => (!clientId ? applications : clients[clientId]?.applications ?? []),
    [applications, clientId, clients],
  );

  useEffect(() => {
    globalThis.dataLayer.push({ event: PAGE_VIEWS.INVESTOR_DASHBOARD_INVEST_AGREEMENTS });
  }, []);

  useEffect(() => {
    (async () => {
      const apps = clientId ? await ClientApplicationApi.getClientApplications(clientId) : await ApplicationsApi.getApplications();
      dispatch({ type: UserContextTypes.SET_APPLICATIONS, payload: apps, clientId });
    })();
  }, [clientId, dispatch]);

  const TABLE_COLUMNS = useMemo<ColumnDefinition<LeanApplication>[]>(
    () => [
      {
        title: clientId ? "Investor Signed Date" : "Signed Date",
        key: "signedDate",
        render: (application) => (application.completedTimestamp ? moment(new Date(application.completedTimestamp)).format("L") : "Not Signed"),
        sorter: (a, b) => Date.parse(a.completedTimestamp as string) - Date.parse(b.completedTimestamp as string),
      },
      {
        title: "Download Application",
        key: "downloadApplication",
        render: (application) =>
          application.fileName ? (
            <Link
              variant="body2"
              onClick={() => {
                ApplicationApi.downloadApplication(application._id);
                globalThis.dataLayer.push({
                  event: PAGE_ACTIONS.INVESTMENT_AGREEMENT_FILE_DOWNLOADED,
                  eventProps: application.fileName,
                });
              }}
              className={clsx({ [classes.usedLink]: clientId ? application.advisor?.downloaded : application.investor?.downloaded })}
            >
              {(clientId && application.advisor?.downloaded && "Download again") ||
                (application.investor?.downloaded && "Download again") ||
                "Ready to download"}
            </Link>
          ) : (
            <Typography variant="body2">Unavailable</Typography>
          ),
        sorter: (a, b) => {
          if (clientId) {
            return Number(a.advisor?.downloaded) - Number(b.advisor?.downloaded);
          } else {
            return Number(a.investor?.downloaded) - Number(b.investor?.downloaded);
          }
        },
      },
      ...([
        {
          title: "Sign Application",
          key: "signApplication",
          render: (application) => {
            if (
              application.status === APPLICATION_STATES.AWAITING_FUNDS ||
              application.status === APPLICATION_STATES.FUNDS_DEPLOYED ||
              application.status === APPLICATION_STATES.FUNDS_RECEIVED ||
              application.completedTimestamp
            ) {
              //Application has already been signed
              return <Typography variant="body2">Signed</Typography>;
            } else if (
              application.status !== APPLICATION_STATES.AWAITING_FUNDS &&
              application.status !== APPLICATION_STATES.FUNDS_DEPLOYED &&
              application.status !== APPLICATION_STATES.FUNDS_RECEIVED &&
              application.status !== APPLICATION_STATES.AWAITING_SIGNATURE
            ) {
              //application status is before await signature, show nothing
              return <Typography variant="body2">Application not completed</Typography>;
            } else if (application.status === APPLICATION_STATES.AWAITING_SIGNATURE && !clientId && !application.advisor?.user) {
              //Direct Investor: Awaiting signature, allow them to sign
              return (
                <Link
                  variant="body2"
                  onClick={() =>
                    history.push({
                      pathname: ROUTES.APPLICATION_AGREEMENT,
                      search: `sigId=${application.investor!.signatureId}`,
                    })
                  }
                >
                  Sign
                </Link>
              );
            } else if (application.advisor?.user && !application.advisor?.signatureTimestamp) {
              //Advisor hasn't signed. Don't show for anyone
              return <Typography variant="body2">Awaiting Adviser signature</Typography>;
            } else if (!clientId && application.advisor?.signatureTimestamp) {
              //Advised client: Show "Sign" and allow them to sign
              return (
                <Link
                  variant="body2"
                  onClick={() =>
                    history.push({
                      pathname: `${ROUTES.APPLICATION_AGREEMENT}`,
                      search: `sigId=${application.investor!.signatureId}`,
                    })
                  }
                >
                  Sign
                </Link>
              );
            } else if (clientId && application.advisor?.signatureTimestamp) {
              //Advisor viewing client dashboard: Show "Awaiting signature" instead of "Sign"
              return <Typography variant="body2">Awaiting Signature</Typography>;
            }
          },
          sorter: (a, b) => {
            if (clientId) {
              return Number(a.advisor?.signatureTimestamp ?? 0) - Number(b.advisor?.signatureTimestamp ?? 0);
            } else {
              return Number(a.investor?.signatureTimestamp ?? 0) - Number(b.investor?.signatureTimestamp ?? 0);
            }
          },
        },
      ] as ColumnDefinition<LeanApplication>[]),
    ],
    [classes.usedLink, clientId, history],
  );

  if (applicationsData.length > 0) {
    return <DataTable tableData={applicationsData} tableStructure={TABLE_COLUMNS} />;
  } else {
    return (
      <Container>
        <Typography variant="h4" align="center" color="textPrimary">
          No investment applications
        </Typography>
      </Container>
    );
  }
};

export default Applications;
