import { gql, useQuery } from "@apollo/client";
import { LeanFund } from "server/services/fund/fund.types";

const FILTERED_FUND_FIELDS = ["_id", "name"] as const;

export interface FilteredFund extends Pick<LeanFund, typeof FILTERED_FUND_FIELDS[number]> {}

const GetFilteredFunds = gql`
  query GetFilteredFunds {
    filteredFunds {
      ${FILTERED_FUND_FIELDS.join("\n")}
    }
  }
`;

export const useGetFilteredFunds = () => {
  return useQuery<{ filteredFunds: FilteredFund[] }>(GetFilteredFunds);
};
