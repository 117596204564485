import { FC } from "react";
import { Container } from "@material-ui/core";
import ReturnsCalculator from "../components/ReturnsCalculator.component";

const PublicReturnsCalculator: FC = () => (
  <Container>
    <ReturnsCalculator />
  </Container>
);

export default PublicReturnsCalculator;
