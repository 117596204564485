import { Button, makeStyles, Tooltip } from "@material-ui/core";
import { TableCellEditHandler } from "@wearenova/mui-data-table";
import { getSheetsExportLink } from "client/api/admin/transactionsPlanner.adminApi";
import { useUpdateAppConfigs } from "client/api/gql/deployment.gqlApi";
import { AdminContext } from "client/context/admin.context";
import { ConfigContext } from "client/context/config.context";
import { arrayToObject } from "client/utils/helpers";
import notifications from "client/utils/notifications";
import _ from "lodash";
import moment from "moment";
import { LeanDocument } from "mongoose";
import React, { FC, useCallback, useContext, useMemo, useState } from "react";
import { FullDeploymentApplication } from "server/services/application/application.types";
import { FundConfig } from "server/services/deployment/deployment.types";
import { LeanPortfolio } from "server/services/portfolio/portfolio.types";
import { LeanShareTransaction, Subscription, TransactionType } from "server/services/shareTransaction/shareTransaction.types";
import AdminTable from "../../AdminTable.view";
import DisplayLink from "../../ShareTransactions/DisplayLink.view";
import DeploymentContext from "../deployment.context";
import useAllocationsStructure, { FlatDeal } from "../hooks/useAllocationsStructure.hook";
import AllocationOptions from "./AllocationOptions";
import AllocationsTableOptions from "./AllocationsTableOptions";
import BulkShareTransactionsForm from "./BulkShareTransactionsForm.view";
import { useAllFunds } from "client/hooks/adminContext.hooks";
import { LeanFund } from "server/services/fund/fund.types";
interface Props {
  searchTerm?: string;
  readOnly: boolean;
}

type InvestmentManagerInfo = {
  topLevelInvestor: string | undefined;
  investmentManager: string | undefined;
};

const useStyles = makeStyles(
  () => ({
    tableFooter: {
      textAlign: "left",
      marginTop: 10,
      marginLeft: 10,
      "& .MuiButton-root:not(:last-child)": {
        marginRight: 20,
      },
    },
    filterBox: {
      marginTop: 10,
      marginBottom: 10,
      marginLeft: 10,
    },
    toggleSwitch: {
      "& .Mui-checked .MuiIconButton-label": {
        background: "unset",
      },
    },
    checkBox: {
      width: 30,
      height: 30,
      padding: 0,
      "& .MuiCheckbox-root": {
        width: 30,
        height: 30,
      },
      "& .MuiIconButton-label": {
        height: 30,
        width: 30,
      },
    },
    includeForm: {
      width: "100%",
      display: "flex",
      justifyContent: "space-evenly",
      minWidth: 280,
    },
    includeInput: {
      width: 115,
    },
    provisionAddTooltip: {
      whiteSpace: "pre-line",
    },
  }),
  { name: "TransactionsPlannerTabView" },
);

const TransactionsPlannerTab: FC<Props> = ({ ...props }) => {
  const classes = useStyles(props);
  useContext(ConfigContext);
  const allFunds = useAllFunds();

  const { allCompanies } = useContext(AdminContext);
  const { applications, deals } = useContext(DeploymentContext);
  const [updateAppConfigs] = useUpdateAppConfigs();

  const [newShareTransactions, setNewShareTransactions] = useState<LeanShareTransaction[] | null>(null);
  const [changeOptions, setChangeOptions] = useState<boolean>(false);
  const [sheetsExportLink, setSheetsExportLink] = useState<string | null>(null);
  const [changeRowOptionsId, setChangeRowOptionsId] = useState<string | null>(null);

  const formattedDeals = useMemo(() => {
    return deals.flatMap<FlatDeal>((deal) => {
      const fundConfigs = Object.values(deal.config).filter<FundConfig>((config: FundConfig): config is FundConfig =>
        Boolean(config.seis.amount || config.eis.amount),
      );
      return fundConfigs.flatMap<FlatDeal>((config) => {
        return (["seis", "eis"] as const)
          .filter((dealStage) => Boolean(config[dealStage].amount))
          .map((dealStage) => {
            return {
              ...deal,
              uniqId: `${deal._id}_${config.fund}_${dealStage}`,
              config,
              dealStage,
            };
          });
      });
    });
  }, [deals]);

  const allCompaniesObject = useMemo(() => arrayToObject(allCompanies), [allCompanies]);
  const plannerTableStructure = useAllocationsStructure({
    deals: formattedDeals,
    allCompaniesObject,
    toggleChangeOptsOn: () => setChangeOptions(true),
    setChangeRowOptionsId,
  });

  const getNotPassedWaiverPeriod = useCallback((record) => moment(record.data.investmentInformation.endOfCancellationPeriod).isAfter(moment()), []);

  const hasCompany = useMemo(() => applications.some((row) => Boolean(Object.values(row.deals).length)), [applications]);

  const companiesMissingInvestmentFolder = useMemo(() => {
    const missing = deals.filter((d) => !allCompaniesObject[d.company._id]?.investmentFolder);
    return missing.length !== 0 && missing.map((d) => allCompaniesObject[d.company._id]?.companyName);
  }, [allCompaniesObject, deals]);

  const usersNotPassedWaiverPeriod = useMemo(() => {
    const notPassed = applications.filter((row) => getNotPassedWaiverPeriod(row));
    return (
      notPassed.length !== 0 && notPassed.map((row) => `${row.investor.user.forenames} ${row.investor.user.surname} (${row.investor.user.email})`)
    );
  }, [getNotPassedWaiverPeriod, applications]);

  const getInvestmentManagerInfo = useCallback((fundId: string | null, funds: LeanFund[]): InvestmentManagerInfo => {
    fundId = fundId === "null" ? null : fundId;
    const transactionFund = funds.find((f) => f._id === fundId);
    const investmentManager = transactionFund?.manager?.companyName;
    const topLevelInvestor = transactionFund?.deployment?.investor ?? investmentManager;
    return {
      investmentManager,
      topLevelInvestor,
    };
  }, []);

  const generateShareTransactions = useCallback(
    (
      dataByPortfolio: {
        [portfolio: string]: LeanPortfolio & {
          applications: FullDeploymentApplication[];
        };
      },
      date,
    ) => {
      return Object.values(dataByPortfolio)
        .filter((portfolio) => portfolio.applications.length > 0)
        .flatMap((portfolio) =>
          formattedDeals.flatMap<LeanShareTransaction>((deal) => {
            let type: TransactionType = deal.type as TransactionType;
            let pricePerShare: number | null = deal.shareClassData.pricePerShare;
            let nominalValue: number | null = deal.shareClassData.nominalValue as number;
            let longstopDate = deal.longstopDate;
            if (type === "ASA") {
              pricePerShare = null;
              nominalValue = null;
            } else {
              longstopDate = null;
              type = "Primary";
            }
            const fundId = String(deal.config.fund);
            const investmentManagerInfo = getInvestmentManagerInfo(fundId, allFunds);
            return {
              status: "READY",
              company: deal.company._id,
              deal: deal._id,
              dealStage: deal.dealStage.toUpperCase(),
              nominee: portfolio.nominee,
              topLevelInvestor: investmentManagerInfo.topLevelInvestor,
              investmentManager: investmentManagerInfo.investmentManager,
              settleDate: date,
              tradeDate: date,
              buySell: "Buy",
              transactionType: type,
              longstopDate,
              currency: "GBP",
              knowledgeIntensiveCompany: true,
              shareClass: deal.deploymentShareClass,
              // updateAllShareClasses: t.updateAll ?? false,
              pricePerShare,
              nominalValuePerShare: nominalValue,
              investmentRound: deal.investmentRound!,
              portfolio: portfolio._id,
              subscriptions: portfolio.applications
                .filter((row) => row.deals[deal._id][fundId][deal.dealStage].shares > 0)
                .map<LeanDocument<Subscription>>((row) => ({
                  investor: row.investor.user._id,
                  noOfShares: deal.type === "ASA" ? undefined : row.deals[deal._id][fundId][deal.dealStage].shares,
                  investmentAmount:
                    deal.type === "ASA" ? row.deals[deal._id][fundId][deal.dealStage].shares * deal.shareClassData.pricePerShare : undefined,
                  application: row._id,
                })),
              totalShares: portfolio.applications
                .filter((row) => row.deals[deal._id][fundId][deal.dealStage].shares > 0)
                .reduce((totalShares, row) => totalShares + row.deals[deal._id][fundId][deal.dealStage].shares, 0),
              fund: fundId === "null" ? null : fundId,
            };
          }),
        )
        .filter((shareTransaction) => shareTransaction.subscriptions.length > 0);
    },
    [allFunds, formattedDeals, getInvestmentManagerInfo],
  );
  const handleMSExport = useCallback(() => {
    const date = moment().toDate();
    const dataByPortfolio = applications.reduce((prev, curr) => {
      const prevValue = prev[curr.portfolio._id]?.applications || [];
      return {
        ...prev,
        [curr.portfolio._id]: {
          ...curr.portfolio,
          applications: [...prevValue, curr],
        },
      };
    }, {} as { [portfolio: string]: LeanPortfolio & { applications: FullDeploymentApplication[] } });

    const asShareTransactions = generateShareTransactions(dataByPortfolio, date);
    setNewShareTransactions(asShareTransactions);
  }, [applications, generateShareTransactions]);

  const provisionAddButtonOptions = useMemo<{ disabled: boolean; tooltip: string }>(() => {
    let tooltip: string = "";
    let disabled = false;
    if (!hasCompany) {
      tooltip += "At least one company needs to be added to the planner to be able to export to Mainspring’s format.\n\n";
      disabled = true;
    } else if (companiesMissingInvestmentFolder) {
      tooltip += `The companies: ${companiesMissingInvestmentFolder.join(", ")} do not have investment folder links.\n\n`;
      disabled = true;
    }
    if (usersNotPassedWaiverPeriod) {
      tooltip += `Application(s) from ${usersNotPassedWaiverPeriod.join(", ")} have not passed their waiver period yet.`;
      disabled = true;
    }
    return { disabled, tooltip };
  }, [companiesMissingInvestmentFolder, hasCompany, usersNotPassedWaiverPeriod]);

  const handleTableEdit = useCallback<TableCellEditHandler<FullDeploymentApplication>>(
    async (update, record) => {
      const parsed = typeof update.value === "string" && parseFloat(update.value);
      const res = await updateAppConfigs({
        variables: {
          updates: _.set({}, update.path.replace("config.", ""), parsed === false || isNaN(parsed) ? update.value : parsed),
          appIds: [record._id],
        },
      });
      if (res.errors) notifications.gql(res.errors);
    },
    [updateAppConfigs],
  );

  return (
    <>
      <AdminTable
        tableData={applications}
        tableStructure={plannerTableStructure}
        tableProps={{ size: "small" }}
        rowOptions={{
          alternateRowColour: (record) =>
            !record.balances.fundsReceived.total ||
            record.amlCompleted !== "Signed off" ||
            !record.investmentManagerApproved ||
            getNotPassedWaiverPeriod(record),
          rowDisabled: (record) => Boolean(record.excluded),
        }}
        exportToCSVOption
        csvFilename="TransactionsPlanner"
        disablePagination
        enableHiddenColumns={false}
        onEdit={handleTableEdit}
      />
      <div className={classes.tableFooter}>
        <Button
          onClick={async () => {
            const { data } = await getSheetsExportLink();
            setSheetsExportLink(data);
          }}
        >
          Export as Google Sheets Link
        </Button>
        <Tooltip title={provisionAddButtonOptions.tooltip} PopperProps={{ className: classes.provisionAddTooltip }} interactive>
          <span>
            <Button onClick={handleMSExport} disabled={provisionAddButtonOptions.disabled}>
              Provisionally add share transactions
            </Button>
          </span>
        </Tooltip>
      </div>
      <AllocationsTableOptions fullWidth open={changeOptions} onClose={() => setChangeOptions(false)} />
      <AllocationOptions
        fullWidth
        open={Boolean(changeRowOptionsId)}
        applicationId={changeRowOptionsId}
        onClose={() => setChangeRowOptionsId(null)}
      />
      <BulkShareTransactionsForm
        open={Boolean(newShareTransactions?.length)}
        onClose={() => setNewShareTransactions(null)}
        shareTransactions={newShareTransactions}
        maxWidth="md"
      />
      <DisplayLink
        fullWidth
        open={Boolean(sheetsExportLink)}
        link={sheetsExportLink}
        onClose={() => {
          setSheetsExportLink(null);
        }}
      />
    </>
  );
};

export default TransactionsPlannerTab;
