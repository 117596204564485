import { Button, Container, makeStyles } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { ConfigContext } from "client/context/config.context";
import React, { useContext, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import useAnalytics from "../hooks/useAnalytics";
import { TRACKED_EVENTS } from "../utils/constants";
import IMPage from "./InformationMemorandum/IMPage.view";

interface Props {
  hideButton?: boolean;
}

const { PAGE_VIEWS } = TRACKED_EVENTS;

const useStyles = makeStyles(
  () => ({
    content: {
      "@media print": {
        margin: 0,
      },
    },
  }),
  { name: "RiskWarningView" },
);

const RiskWarning: React.FC<Props> = (props) => {
  const classes = useStyles(props);
  const history = useHistory();
  const { fund } = useContext(ConfigContext);
  useAnalytics();

  const riskWarningSection = useMemo(
    () => fund.informationMemorandum.contents.find((content) => Boolean(content.riskWarning))!,
    [fund.informationMemorandum.contents],
  );

  useEffect(() => {
    globalThis.dataLayer.push({ event: PAGE_VIEWS.RISK_WARNING });
  }, []);

  return (
    <>
      <Container>
        <div className={classes.content}>
          {!props.hideButton && (
            <Button onClick={() => history.goBack()} variant="text" startIcon={<ArrowBackIosIcon />}>
              Back
            </Button>
          )}
        </div>
      </Container>
      <IMPage config={riskWarningSection} />
    </>
  );
};

export default RiskWarning;
