import { FormGroup, Typography } from "@material-ui/core";
import { Field, useFormikContext } from "formik";
import React, { useEffect } from "react";
import RadioQuestion from "../FormControls/RadioQuestion.component";
import Statement from "./Statement.component";
import TextQuestion from "../FormControls/TextQuestion.component";
import { CertificationForm } from "client/utils/constants";
import NumberQuestion from "../FormControls/NumberQuestion.component";
import CheckboxQuestion from "../FormControls/CheckboxQuestion.component";

type QuestionIds = (typeof CertificationForm.values)[
  | "financeProviderForSMEsOrPEWorker"
  | "financeProviderForSMEsOrPEWorkerDetail"
  | "directorOfCompanyWithAnnualTurnoverOver1M"
  | "directorOfCompanyWithAnnualTurnoverOver1MDetail"
  | "multipleInvestmentsUnlistedCompany"
  | "multipleInvestmentsUnlistedCompanyDetail"
  | "businessAngelNetworkMemberOver6Months"
  | "businessAngelNetworkMemberOver6MonthsDetail"
  | "notApplicable"]["id"];

const SophisticatedQualification: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<{
    atLeastOneTrue: boolean;
    values: {
      [key in QuestionIds]: boolean;
    };
  }>();

  useEffect(() => {
    if (values.values.notApplicable) {
      setFieldValue(CertificationForm.values.financeProviderForSMEsOrPEWorker.name, null);
      setFieldValue(CertificationForm.values.directorOfCompanyWithAnnualTurnoverOver1M.name, null);
      setFieldValue(CertificationForm.values.multipleInvestmentsUnlistedCompany.name, null);
      setFieldValue(CertificationForm.values.businessAngelNetworkMemberOver6Months.name, null);
    }
  }, [setFieldValue, values.values.notApplicable]);

  const handleRadioChange = () => {
    if (values.values.notApplicable) {
      setFieldValue(CertificationForm.values.notApplicable.name, false);
    }
  };
  return (
    <FormGroup>
      <Statement title="Self-Certified Sophisticated Investor Statement">
        If you meet <b> condition A,B,C, or D below, </b> you may choose to be classified as a self-certified sophisticated investor for the purposes
        of the Financial Services and Markets Act 2000 (Financial Promotion) Order 2005.
        <br />
        <br />
        Have you:
        <FormGroup key={CertificationForm.values.financeProviderForSMEsOrPEWorker.id}>
          <Field
            name={CertificationForm.values.financeProviderForSMEsOrPEWorker.name}
            component={RadioQuestion}
            onClick={handleRadioChange}
            label={<Typography variant="body2">{CertificationForm.values.financeProviderForSMEsOrPEWorker.label}</Typography>}
          />
        </FormGroup>
        {values.values.financeProviderForSMEsOrPEWorker && (
          <FormGroup key={CertificationForm.values.financeProviderForSMEsOrPEWorkerDetail.id}>
            <Field
              name={CertificationForm.values.financeProviderForSMEsOrPEWorkerDetail.name}
              component={TextQuestion}
              label={<Typography variant="body2">{CertificationForm.values.financeProviderForSMEsOrPEWorkerDetail.label}</Typography>}
            />
          </FormGroup>
        )}
        <FormGroup key={CertificationForm.values.directorOfCompanyWithAnnualTurnoverOver1M.id}>
          <Field
            name={CertificationForm.values.directorOfCompanyWithAnnualTurnoverOver1M.name}
            component={RadioQuestion}
            onClick={handleRadioChange}
            label={<Typography variant="body2">{CertificationForm.values.directorOfCompanyWithAnnualTurnoverOver1M.label}</Typography>}
          />
        </FormGroup>
        {values.values.directorOfCompanyWithAnnualTurnoverOver1M && (
          <FormGroup key={CertificationForm.values.directorOfCompanyWithAnnualTurnoverOver1MDetail.id}>
            <Field
              name={CertificationForm.values.directorOfCompanyWithAnnualTurnoverOver1MDetail.name}
              component={TextQuestion}
              label={<Typography variant="body2">{CertificationForm.values.directorOfCompanyWithAnnualTurnoverOver1MDetail.label}</Typography>}
            />
          </FormGroup>
        )}
        <br />
        <FormGroup key={CertificationForm.values.multipleInvestmentsUnlistedCompany.id}>
          <Field
            name={CertificationForm.values.multipleInvestmentsUnlistedCompany.name}
            component={RadioQuestion}
            onClick={handleRadioChange}
            label={<Typography variant="body2">{CertificationForm.values.multipleInvestmentsUnlistedCompany.label}</Typography>}
          />
        </FormGroup>
        {values.values.multipleInvestmentsUnlistedCompany && (
          <FormGroup key={CertificationForm.values.multipleInvestmentsUnlistedCompanyDetail.id}>
            <Field
              name={CertificationForm.values.multipleInvestmentsUnlistedCompanyDetail.name}
              component={NumberQuestion}
              label={<Typography variant="body2">{CertificationForm.values.multipleInvestmentsUnlistedCompanyDetail.label}</Typography>}
              {...CertificationForm.values.multipleInvestmentsUnlistedCompanyDetail.props}
            />
          </FormGroup>
        )}
        <br />
        <FormGroup key={CertificationForm.values.businessAngelNetworkMemberOver6Months.id}>
          <Field
            name={CertificationForm.values.businessAngelNetworkMemberOver6Months.name}
            component={RadioQuestion}
            onClick={handleRadioChange}
            label={<Typography variant="body2">{CertificationForm.values.businessAngelNetworkMemberOver6Months.label}</Typography>}
          />
        </FormGroup>
        {values.values.businessAngelNetworkMemberOver6Months && (
          <FormGroup key={CertificationForm.values.businessAngelNetworkMemberOver6MonthsDetail.id}>
            <Field
              name={CertificationForm.values.businessAngelNetworkMemberOver6MonthsDetail.name}
              component={TextQuestion}
              label={<Typography variant="body2">{CertificationForm.values.businessAngelNetworkMemberOver6MonthsDetail.label}</Typography>}
            />
          </FormGroup>
        )}
        <br />
        <Typography variant="h3">OR</Typography>
        <FormGroup key={CertificationForm.values.notApplicable.id}>
          <Typography variant="body2">E) None of these apply to me.</Typography>
          <Field
            name={CertificationForm.values.notApplicable.name}
            component={CheckboxQuestion}
            label={<Typography variant="body2">Yes</Typography>}
          />
        </FormGroup>
        <br />
        <Typography variant="body2">
          I declare that I have answered yes to A and/or B and/or C and/or D, and wish to be treated as a self-certified sophisticated investor.
        </Typography>
        <br />
        <Typography variant="body2">
          I understand that this means:
          <ol type="a">
            <li>I can receive financial promotions where the contents may not comply with rules made by the Financial Conduct Authority (FCA).</li>
            <li>
              I can expect <strong>no protection</strong> from the FCA, the Financial Ombudsman Service, or the Financial Services Compensation
              Scheme.
            </li>
          </ol>
        </Typography>
        <Typography variant="body2">
          I am aware that it is open to me to seek advice from someone who specialises in advising on investments.
        </Typography>
        <br />
        <Typography variant="body2">
          <strong>I accept that I could lose all the money I invest.</strong>
        </Typography>
      </Statement>
    </FormGroup>
  );
};

export default SophisticatedQualification;
