import { Button, Container, makeStyles } from "@material-ui/core";
import { AuthContext } from "client/context/auth.context";
import { ConfigContext } from "client/context/config.context";
import useLocationReferrer from "client/hooks/useLocationReferrer";
import React, { useCallback, useContext, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ROUTES } from "../../utils/constants";
import useIsAdmin from "client/hooks/useIsAdmin";

interface Props {}

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  headerPadding: {
    paddingTop: theme.spacing(20),
  },
  logo: {
    width: 224,
    [theme.breakpoints.down("sm")]: {
      width: 144,
    },
  },
  yellowBackground: {
    background: theme.palette.primary.main,
  },
}));

const PublicNavbar: React.FC<Props> = (props) => {
  const {
    state: { user },
  } = React.useContext(AuthContext);
  const isAdmin = useIsAdmin(user);

  const classes = useStyles(props);
  const history = useHistory();
  const location = useLocation<{ fromRegister?: boolean } | undefined>();
  const referrer = useLocationReferrer();
  const { logo } = useContext(ConfigContext);
  const { fund } = useContext(ConfigContext);

  const isFromRegister = useMemo(() => Boolean(location.state?.fromRegister), [location.state]);

  const handleClick = useCallback(() => {
    if (isFromRegister) {
      history.push(referrer.location || ROUTES.REGISTER);
    } else {
      window.location.href = fund.links.alternateSite;
    }
  }, [fund.links.alternateSite, history, isFromRegister, referrer.location]);

  return (
    <div className={classes.yellowBackground}>
      <Container maxWidth={false} className={`${classes.header} ${isAdmin ? "" : classes.headerPadding}`}>
        <img className={classes.logo} src={logo} alt="Logo" />
        <Button color="secondary" size="small" onClick={handleClick}>
          {isFromRegister ? "Register" : "Go to website"}
        </Button>
      </Container>
    </div>
  );
};

export default PublicNavbar;
