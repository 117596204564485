import { FC } from "react";
import { Box, Container, makeStyles, Typography } from "@material-ui/core";
import Markdown from "client/components/Markdown.component";
import AccordionItem from "./AccordionItem.component";

const useStyles = makeStyles(
  (theme) => ({
    accordionContainer: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
    header: {
      paddingBottom: "20px",
    },
  }),
  { name: "FAQAccordionComponent" },
);

interface Props {
  items: { title: string; content: string }[];
}

const FAQAccordion: FC<Props> = ({ items, ...props }) => {
  const classes = useStyles(props);

  return (
    <Box className={classes.accordionContainer}>
      <Container>
        <div className={classes.header}>
          <Typography variant="h2" color="inherit">
            FAQs
          </Typography>
        </div>

        {/* render the accordion items */}
        {items?.length &&
          items.map((item) => {
            return (
              <AccordionItem key={item.title} title={item.title}>
                <Markdown>{item.content}</Markdown>
              </AccordionItem>
            );
          })}
      </Container>
    </Box>
  );
};

export default FAQAccordion;
