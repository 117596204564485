import { Button, FormGroup, makeStyles, Typography } from "@material-ui/core";
import * as Sentry from "@sentry/react";
import notifications from "client/utils/notifications";
import { ErrorMessage, FieldProps } from "formik";
import _ from "lodash";
import React, { FC, useCallback, useEffect, useMemo } from "react";
import coerce from "semver/functions/coerce";
import semverLessThan from "semver/functions/lt";
import { UAParser } from "ua-parser-js";

interface Props extends FieldProps<{ completed: null | boolean }> {
  amlChecksURL?: string;
  onComplete?: () => any;
  onSkip?: () => any;
  skipDisabled?: boolean;
}

const uaParser = new UAParser();

const useStyles = makeStyles(
  (theme) => ({
    yotiContainer: {
      height: "84vh",
      maxHeight: 786,
      width: "100%",
      maxWidth: 629,
      alignSelf: "center",
      overflow: "auto",
      display: "flex",
      border: `1px solid ${theme.palette.primary.main}`,
    },
    yoti: {
      border: "none",
      width: "100%",
    },
  }),
  { name: "IdentityCheckView" },
);

const IdentityCheck: FC<Props> = ({ field, form, onComplete, onSkip, skipDisabled, amlChecksURL, ...props }) => {
  const classes = useStyles(props);

  const completedName = useMemo(() => `${field.name ? `${field.name}.` : ""}completed`, [field.name]);

  const handleEvent = useCallback(
    (event) => {
      if (event.data.eventType === "SUCCESS") {
        if (onComplete) onComplete();
        return form.setFieldValue(completedName, true);
      }
      if (event.data.eventType === "ERROR") {
        notifications.error(
          "Sorry there was an error during the process. Either press DO IT LATER to continue with the flow, or refresh the page to start again.",
          event,
          10,
        );
        form.setFieldError(
          completedName,
          "Sorry there was an error during the process. Either press DO IT LATER to continue with the flow, or refresh the page to start again.",
        );
        return form.setFieldValue(completedName, false);
      }
    },
    [completedName, form, onComplete],
  );

  const handleCompleteLater = useCallback(() => {
    form.setFieldValue(completedName, false);
    form.setSubmitting(true);
    if (onSkip) onSkip();
  }, [completedName, form, onSkip]);

  useEffect(() => {
    window.addEventListener("message", handleEvent);
    return () => window.removeEventListener("message", handleEvent);
  }, [handleEvent]);

  useEffect(() => {
    try {
      const osInfo = uaParser.getOS();
      const parsedVersion = coerce(osInfo.version, { loose: true });
      if (osInfo.name?.toLowerCase() === "ios" && osInfo.version && parsedVersion && semverLessThan(parsedVersion, "14.0.0", { loose: true })) {
        notifications.warning(
          <>
            There may be issues with the identity verification process on this version of iOS. We recommend you do one of the following: update your
            device to the latest version of iOS, change devices or press &ldquo;DO IT LATER&rdquo; to skip the process for now.
          </>,
          30,
        );
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }, [completedName]);

  return (
    <Sentry.ErrorBoundary onError={onSkip}>
      <FormGroup>
        <Typography variant="h2" align="center">
          We need to verify your identity
        </Typography>
        <FormGroup>
          <Typography variant="body2" paragraph>
            For Anti-Money Laundering (AML) Regulation purposes, you are required to provide a valid form of identification and your most recent proof
            of address. Please proceed by selecting the button below to upload your documents. If you cannot provide these now, you will be contacted
            after completing your online application to verify your identity. Failure to provide the required documents may delay the application
            process. We therefore encourage you to complete the AML now so that your application is complete and can be processed.
          </Typography>
        </FormGroup>
      </FormGroup>
      <FormGroup>
        <div className={classes.yotiContainer}>
          {amlChecksURL ? <iframe title="ID Verification" src={amlChecksURL} className={classes.yoti} allow="camera" onError={onSkip} /> : null}
        </div>
      </FormGroup>
      <FormGroup>
        <Typography variant="caption">
          <strong>
            Once you have completed the steps, please wait while we attempt to prefill some answers for you. When completed, we will move you onto the
            next stage.
          </strong>
        </Typography>
        <FormGroup>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={handleCompleteLater}
            disabled={Boolean(field.value.completed) || skipDisabled}
            data-testid="skip-id-verification"
          >
            Do it later
          </Button>
        </FormGroup>
      </FormGroup>
      {!_.get(form.errors, completedName, false) ? null : (
        <FormGroup>
          <Typography variant="body2" color="error">
            <ErrorMessage name={completedName} />
          </Typography>
        </FormGroup>
      )}
    </Sentry.ErrorBoundary>
  );
};

export default IdentityCheck;
