import { createStyles, Link, List, ListItem, ListItemIcon, ListItemText, makeStyles, Typography } from "@material-ui/core";
import BulletIcon from "@material-ui/icons/FiberManualRecord";
import { ConfigContext } from "client/context/config.context";
import { FUND_VARIABLE_REPLACER_REGEX } from "client/utils/constants";
import clsx from "clsx";
import { intToExcelCol } from "excel-column-name";
import _ from "lodash";
import React, { Fragment, useCallback, useContext, useMemo } from "react";
import ReactMarkdown, { ReactMarkdownOptions } from "react-markdown";
import { TableCellComponent } from "react-markdown/src/ast-to-react";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import Image from "./Image.component";

interface Props extends ReactMarkdownOptions {
  enableHTML?: boolean | null;
}

type Components = ReactMarkdownOptions["components"];

const REACT_MARKDOWN_PLUGINS = [remarkGfm];

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      image: {
        maxWidth: "100%",
        maxHeight: 450,
      },
      listItemIcon: {
        alignSelf: "flex-start",
      },
      noMargin: {
        margin: 0,
        "& *": {
          margin: 0,
        },
      },
      orderedListItemIcon: {
        lineHeight: 1,
      },
      preLineWrap: {
        whiteSpace: "pre-line",
      },
      table: {
        width: "100%",
        margin: theme.spacing(2, 0),
      },
      tableCell: {
        verticalAlign: "top",
        textAlign: "left",
        "&:not(:last-child)": {
          paddingRight: theme.spacing(4),
        },
        [theme.breakpoints.down("sm")]: {
          "&:not(:last-child)": {
            paddingRight: theme.spacing(2),
          },
        },
      },
    }),
  { name: "MarkdownComponent" },
);

const Markdown: React.FC<Props> = ({ enableHTML, ...props }) => {
  const classes = useStyles(props);
  const { fund } = useContext(ConfigContext);

  const text = useMemo(() => {
    return props.children.replace(FUND_VARIABLE_REPLACER_REGEX, (_fullMatch, match) => _.get(fund, match, ""));
  }, [fund, props.children]);

  const tableCell = useCallback<TableCellComponent>(
    ({ node, isHeader, siblingCount, sourcePosition, index, ...ps }) => {
      const Component = (props: any) => (isHeader ? <th {...props} /> : <td {...props} />);
      return (
        <Component {...ps} className={clsx(classes.preLineWrap, classes.tableCell)}>
          {ps.children?.map((node, index) => (
            <Fragment key={index}>
              {typeof node === "string"
                ? node.split(/<br[\s]?\/?>/).map(
                    (str, nestedIndex) =>
                      str && (
                        <Fragment key={nestedIndex}>
                          <br />
                          {str}
                        </Fragment>
                      ),
                  )
                : node}
            </Fragment>
          ))}
        </Component>
      );
    },
    [classes],
  );

  const components = useMemo<Components>(
    () => ({
      h1: ({ node, siblingCount, sourcePosition, index, ref, ...ps }) => <Typography {...ps} variant="h1" color="inherit" paragraph />,
      h2: ({ node, siblingCount, sourcePosition, index, ref, ...ps }) => <Typography {...ps} variant="h2" color="inherit" paragraph />,
      h3: ({ node, siblingCount, sourcePosition, index, ref, ...ps }) => <Typography {...ps} variant="h3" color="inherit" paragraph />,
      h4: ({ node, siblingCount, sourcePosition, index, ref, ...ps }) => <Typography {...ps} variant="h4" color="inherit" paragraph />,
      h5: ({ node, siblingCount, sourcePosition, index, ref, ...ps }) => <Typography {...ps} variant="h5" color="inherit" paragraph />,
      h6: ({ node, siblingCount, sourcePosition, index, ref, ...ps }) => <Typography {...ps} variant="h6" color="inherit" paragraph />,
      a: ({ node, siblingCount, sourcePosition, index, color, ref, ...ps }) => <Link {...ps} component="a" rel="noopener noreferrer" />,
      p: ({ node, siblingCount, sourcePosition, index, ref, ...ps }) => (
        <Typography {...ps} variant="body2" color="inherit" paragraph className={classes.preLineWrap} />
      ),
      ol: ({ node, siblingCount, sourcePosition, index, ordered, ref, ...ps }) => <List {...ps} component="ol" />,
      ul: ({ node, siblingCount, sourcePosition, index, ordered, ref, ...ps }) => <List {...ps} component="ul" />,
      li: ({ node, siblingCount, sourcePosition, index, ordered, ref, ...ps }) => (
        <ListItem {...ps}>
          <ListItemIcon className={classes.listItemIcon}>
            {ordered ? (
              <Typography variant="body1" color="inherit" className={classes.orderedListItemIcon}>
                <strong>{intToExcelCol(index + 1).toLowerCase()}</strong>
              </Typography>
            ) : (
              <BulletIcon color="primary" />
            )}
          </ListItemIcon>
          <ListItemText disableTypography className={classes.noMargin}>
            {ps.children}
          </ListItemText>
        </ListItem>
      ),
      img: ({ node, siblingCount, sourcePosition, index, ...ps }) => <Image {...ps} alt={(ps.alt as string) ?? ""} className={classes.image} />,
      table: ({ node, siblingCount, sourcePosition, index, ...ps }) => <table {...ps} className={classes.table} />,
      th: tableCell,
      td: tableCell,
    }),
    [classes, tableCell],
  );

  return (
    <ReactMarkdown
      includeElementIndex
      linkTarget="_blank"
      remarkPlugins={REACT_MARKDOWN_PLUGINS}
      components={components}
      rehypePlugins={enableHTML ? [rehypeRaw] : []}
    >
      {text}
    </ReactMarkdown>
  );
};

export default Markdown;
