import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogContentTextProps,
  DialogProps,
  DialogTitle,
} from "@material-ui/core";
import { FC, MouseEvent, ReactNode, useCallback } from "react";

type OnButtonClick = Required<ButtonProps>["onClick"];

interface Props extends DialogProps {
  dialogTitle?: ReactNode;
  dialogTextProps?: DialogContentTextProps;
  onClose: (e: MouseEvent, reason: "backdropClick" | "escapeKeyDown" | "negativeButtonClick") => any;
  onNegativeButtonClick?: OnButtonClick;
  negativeButtonText?: ReactNode;
  negativeButtonProps?: ButtonProps;
  onPositiveButtonClick: OnButtonClick;
  positiveButtonText?: ReactNode;
  positiveButtonProps?: ButtonProps;
}

const ConfirmationDialog: FC<Props> = ({
  dialogTitle,
  dialogTextProps,
  onNegativeButtonClick,
  negativeButtonText = "CANCEL",
  negativeButtonProps,
  onPositiveButtonClick,
  positiveButtonText = "OK",
  positiveButtonProps,
  ...props
}) => {
  const handleNegativeButtonClick = useCallback<OnButtonClick>(
    (e) => {
      if (onNegativeButtonClick) return onNegativeButtonClick(e);
      return props.onClose(e, "negativeButtonClick");
    },
    [onNegativeButtonClick, props],
  );

  return (
    <Dialog {...props}>
      {dialogTitle && <DialogTitle>{dialogTitle}</DialogTitle>}
      <DialogContent>
        <DialogContentText variant="body2" color="textPrimary" {...dialogTextProps}>
          {props.children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleNegativeButtonClick} size="small" variant="text" {...negativeButtonProps}>
          {negativeButtonText}
        </Button>
        <Button onClick={onPositiveButtonClick} size="small" variant="text" color="primary" {...positiveButtonProps}>
          {positiveButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
