import { Button, Dialog, DialogContent, DialogProps, DialogTitle, FormGroup, FormLabel, IconButton, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { getFunds } from "client/api/admin/funds.adminApi";
import CrudLinkArray from "client/components/FormControls/CrudLinkArray";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import moment from "moment";
import React, { FC, useCallback, useContext, useEffect, useMemo } from "react";
import { AdminCompany, LeanCompany } from "server/services/company/company.types";
import * as Yup from "yup";
import { removeCompany, saveCompany } from "../../../api/admin/companies.adminApi";
import FieldArrayRemoveButton from "../../../components/FieldArrayRemoveButton.component";
import CrudFormButtons from "../../../components/FormControls/CrudFormButtons.component";
import DateQuestion from "../../../components/FormControls/DatePickerField.component";
import NumberQuestion from "../../../components/FormControls/NumberQuestion.component";
import PostcodeLookup from "../../../components/FormControls/PostcodeLookupField.component";
import SelectField, { getValue, SelectOption } from "../../../components/FormControls/SelectField.component";
import TextQuestion from "../../../components/FormControls/TextQuestion.component";
import FormikErrorsTouched from "../../../components/FormikErrorsTouched.component";
import ErrorText from "../../../components/UIKit/ErrorText.component";
import { AdminContext, ADMIN_TYPES } from "../../../context/admin.context";
import { SEIS_ALLOWANCE_MINIMUM_LIMIT } from "shared/constants";
import CheckboxQuestion from "client/components/FormControls/CheckboxQuestion.component";

interface Props extends DialogProps {
  onClose(): any;
  company: AdminCompany | null;
}

interface InitialValues extends Omit<LeanCompany, "advancedAssurance" | "companyNumber"> {
  advancedAssurance: {
    seis: {
      date: null | Date;
      key: string;
    };
    eis: {
      date: null | Date;
      key: string;
    };
  };
}

const LINK_TYPES = [
  {
    label: "Website",
    path: "websiteLink",
  },
  {
    label: "Video",
    path: "video",
  },
  {
    label: "Facebook",
    path: "socials.facebook",
  },
  {
    label: "Twitter",
    path: "socials.twitter",
  },
  {
    label: "Instagram",
    path: "socials.instagram",
  },
  {
    label: "LinkedIn",
    path: "socials.linkedIn",
  },
];

const SHARE_PRICE_SOURCES = ["LAST_TRADE", "EXTERNAL_VALUATION", "REVALUATION"];

type TeamMemberFormProps = {
  index: number;
  remove: (index: number) => void;
  setFieldValue: any;
};

type TeamFormProps = {
  push: (teamMember: { name: string; role: string }) => void;
};

const INITIAL_VALUES: InitialValues = {
  companyName: "",
  projectName: "",
  isDissolved: false,
  p2Id: "",
  companyLogo: "",
  websiteLink: [],
  headline: "",
  video: [],
  detailedInfo: "",
  sector: "",
  team: [
    {
      name: "",
      role: "",
      image: "",
    },
  ],
  registeredAddress: {
    addressLineOne: "",
    addressLineTwo: "",
    city: "",
    country: "",
    postcode: "",
  },
  sharePriceHistory: [],
  shareClasses: [],
  icRounds: [],
  resourceBalance: null,
  socials: {
    facebook: [],
    twitter: [],
    linkedIn: [],
    instagram: [],
  },
  advancedAssurance: {
    seis: {
      date: null,
      key: "",
    },
    eis: {
      date: null,
      key: "",
    },
  },
  updates: [],
  investmentFolder: "",
  seisAllowanceLimit: SEIS_ALLOWANCE_MINIMUM_LIMIT,
};

const linkSchema = Yup.array(
  Yup.object({
    url: Yup.string().required("Please enter the Link URL").url("Link must be a valid URL"),
    name: Yup.string().required("Please enter the Link Name"),
    category: Yup.string().required("Please enter the Link Category"),
  }),
).nullable();

const SCHEMA = Yup.object().shape({
  companyName: Yup.string().required("Please enter the Company Name"),
  projectName: Yup.string().required("Please enter the Project Name"),
  isDissolved: Yup.boolean().nullable(),
  companyLogo: Yup.mixed(),
  advancedAssurance: Yup.object({
    seis: Yup.object({
      date: Yup.date().nullable(),
      key: Yup.mixed(),
    }),
    eis: Yup.object({
      date: Yup.date().nullable(),
      key: Yup.mixed(),
    }),
  }),
  p2Id: Yup.string(),
  websiteLink: linkSchema,
  sector: Yup.string().nullable(),
  headline: Yup.string().nullable(),
  video: linkSchema,
  socials: Yup.object({
    facebook: linkSchema,
    twitter: linkSchema,
    instagram: linkSchema,
    linkedIn: linkSchema,
  }).nullable(),
  detailedInfo: Yup.string(),
  team: Yup.array(
    Yup.object({
      name: Yup.string().required("Please enter the Team Member Name"),
      role: Yup.string().required("Please enter the Team Member Role"),
    }),
  ),
  registeredAddress: Yup.object({
    addressLineOne: Yup.string().required("This field is required"),
    addressLineTwo: Yup.string().nullable().notRequired(),
    city: Yup.string().required("This field is required"),
    country: Yup.string().nullable(),
    postcode: Yup.string().required("This field is required"),
  }).required("Please enter company registered address"),
  icRounds: Yup.array(
    Yup.object({
      date: Yup.date().required("Please enter a date that this price was used"),
      resourceAmount: Yup.number().positive("Resource amount must be a positive number").required("Please enter an amount"),
      investmentAmount: Yup.number().positive("Resource amount must be a positive number").required("Please enter an amount"),
    }),
  ),
  sharePriceHistory: Yup.array(
    Yup.object({
      date: Yup.date().required("Please enter a date that this price was used"),
      price: Yup.number().min(0, "Price per share must be a positive number").required("Please enter a price"),
      source: Yup.string().oneOf(SHARE_PRICE_SOURCES).required("Please provide a source of this share price change"),
      nominalValue: Yup.number().nullable(),
    }),
  ),
  shareClasses: Yup.array(
    Yup.object({
      name: Yup.string().required("Please enter a name for this share class"),
      description: Yup.string(),
      nominalValue: Yup.number().nullable(),
      pricePerShare: Yup.number().nullable(),
    }),
  ),
  investmentFolder: Yup.string().url("Link must be a valid URL"),
  seisAllowanceLimit: Yup.number()
    .required("Please enter SEIS Allowance Limit")
    .min(SEIS_ALLOWANCE_MINIMUM_LIMIT, `Minimum value is ${SEIS_ALLOWANCE_MINIMUM_LIMIT}`)
    .max(250000, "Maximum value is 250,000"),
});

const TeamMemberForm = ({ index, remove, setFieldValue }: TeamMemberFormProps) => (
  <FormGroup key={index}>
    <FormGroup style={{ alignItems: "flex-end", margin: 0 }}>
      <hr />
      <IconButton size="small" edge="end" style={{ flex: 0 }} onClick={() => remove(index)}>
        <CloseIcon />
      </IconButton>
    </FormGroup>
    <FormGroup>
      <Field component={TextQuestion} name={`team[${index}].name`} placeholder="Team member name" label="Team Member Name" />
    </FormGroup>
    <FormGroup>
      <Field component={TextQuestion} name={`team[${index}].role`} placeholder="Team member role" label="Team Member Role" />
    </FormGroup>
    <FormGroup>
      <Field
        component={TextQuestion}
        name={`team[${index}].image`}
        type="file"
        placeholder="Team member image"
        label="Team Image"
        onChange={(e: any) => setFieldValue(e.target.name, e.target.files[0])}
        value={undefined}
        InputProps={{
          inputProps: {
            accept: "image/x-png,image/gif,image/jpeg",
          },
        }}
      />
    </FormGroup>
  </FormGroup>
);

const TeamForm = ({ push }: TeamFormProps) => (
  <>
    <hr />
    <FormGroup row>
      <Button size="small" onClick={() => push({ name: "", role: "" })}>
        Add Team member
      </Button>
    </FormGroup>
    <hr />
  </>
);

const CompanyForm: FC<Props> = ({ onClose, company, ...props }) => {
  const { dispatch, funds } = useContext(AdminContext);

  useEffect(() => {
    (async () => {
      const data = await getFunds();
      if (!data) return;
      dispatch({ type: "SET_FUNDS", payload: data });
    })();
  }, [dispatch]);

  const handleSubmit = useCallback(
    async (values) => {
      const newCompany = await (company ? saveCompany(values, company._id) : saveCompany(values));
      if (newCompany) {
        dispatch({ type: ADMIN_TYPES.UPDATE_COMPANY, payload: newCompany });
        onClose();
      }
    },
    [company, dispatch, onClose],
  );

  const handleDelete = useCallback(async () => {
    if (company) {
      const companyId = await removeCompany(company._id);
      if (companyId) {
        dispatch({ type: ADMIN_TYPES.DELETE_COMPANY, payload: companyId });
        onClose();
      }
    }
  }, [company, dispatch, onClose]);

  const initialValues = useMemo<InitialValues>(() => {
    return company
      ? {
          ...INITIAL_VALUES,
          ...company,
        }
      : { ...INITIAL_VALUES };
  }, [company]);

  const fundOptions = useMemo(() => funds.map((fund) => ({ label: fund.name, value: fund._id })), [funds]);

  return (
    <Dialog onClose={onClose} maxWidth="lg" {...props}>
      <DialogTitle>{company ? "Edit Company" : "Add Company"}</DialogTitle>

      <DialogContent>
        <Formik onSubmit={handleSubmit} validationSchema={SCHEMA} initialValues={initialValues} enableReinitialize={true}>
          {({ values, setFieldValue }) => (
            <Form data-testid="companyForm">
              <FormikErrorsTouched />
              <FormGroup>
                <Field component={TextQuestion} name="companyName" placeholder="Company Name" label="Company Name" />
              </FormGroup>
              <FormGroup>
                <Field component={TextQuestion} name="projectName" placeholder="Project Name" label="Project Name" />
                <FormGroup>
                  <Field
                    component={CheckboxQuestion}
                    name="isDissolved"
                    data-testid="isDissolvedCheckBox"
                    placeholder="Company Dissolved"
                    label="Company Dissolved"
                    value={values.isDissolved}
                  />
                </FormGroup>
              </FormGroup>
              <FormGroup>
                <hr />
              </FormGroup>
              <FormGroup>
                <Typography variant="body1" paragraph>
                  <strong>Advanced Assurance (AA)</strong>
                </Typography>
                <Typography variant="body1">SEIS AA</Typography>
              </FormGroup>
              <FormGroup row>
                <Field component={DateQuestion} name="advancedAssurance.seis.date" label="SEIS Date" />
                <Field
                  component={TextQuestion}
                  name="advancedAssurance.seis.key"
                  type="file"
                  label="SEIS File"
                  onChange={(e: any) => setFieldValue(e.target.name, e.target.files[0])}
                  value={undefined}
                  accept="application/pdf"
                />
              </FormGroup>
              <FormGroup row>
                <Field
                  component={NumberQuestion}
                  moneyFormat
                  prefix="£"
                  name={`seisAllowanceLimit`}
                  placeholder="SEIS Allowance Limit"
                  label="SEIS Allowance Limit"
                  decimalScale={0}
                  fixedDecimalScale={false}
                />
              </FormGroup>
              <FormGroup>
                <Typography variant="body1">EIS AA</Typography>
              </FormGroup>
              <FormGroup row>
                <Field component={DateQuestion} name="advancedAssurance.eis.date" label="EIS Date" />
                <Field
                  component={TextQuestion}
                  name="advancedAssurance.eis.key"
                  type="file"
                  label="EIS File"
                  onChange={(e: any) => setFieldValue(e.target.name, e.target.files[0])}
                  value={undefined}
                  accept="application/pdf"
                />
              </FormGroup>
              <FormGroup>
                <hr />
              </FormGroup>
              <FormGroup>
                <Field component={TextQuestion} name="p2Id" placeholder="P2 ID" label="P2 ID" />
              </FormGroup>
              <FormGroup>
                <Field component={TextQuestion} name="investmentFolder" placeholder="Investment Folder" label="Investment Folder" />
              </FormGroup>

              <FormGroup>
                <hr />
                <br />
                <Typography variant="body1" paragraph>
                  <strong>Company Links:</strong>
                </Typography>
                <FormGroup>
                  {LINK_TYPES.map(({ path, label }) => (
                    <Field key={path} component={CrudLinkArray} label={label} name={path} placeholder={label} />
                  ))}
                </FormGroup>
                <br />
                <hr />
              </FormGroup>

              <FormGroup>
                <Field
                  component={TextQuestion}
                  name="companyLogo"
                  type="file"
                  placeholder="Company Logo"
                  label="Company Logo"
                  onChange={(e: any) => setFieldValue(e.target.name, e.target.files[0])}
                  value={undefined}
                  accept="image/x-png,image/gif,image/jpeg"
                />
              </FormGroup>
              <FormGroup>
                <Field component={TextQuestion} name="headline" placeholder="Headline" label="Headline" />
              </FormGroup>
              <FormGroup>
                <Field component={TextQuestion} name="detailedInfo" placeholder="Information" label="Information" multiline />
              </FormGroup>
              <FormGroup>
                <Field component={TextQuestion} name="sector" placeholder="Sector" label="Sector" />
              </FormGroup>
              <FieldArray name="team">
                {({ push, remove }) => (
                  <>
                    {values.team.map((_teamMember, index) => (
                      <TeamMemberForm key={_teamMember.name} index={index} remove={remove} setFieldValue={setFieldValue} />
                    ))}
                    <TeamForm push={push} />
                    <ErrorMessage name="team" component={ErrorText} />
                  </>
                )}
              </FieldArray>
              <FormGroup>
                <Field component={PostcodeLookup} name="registeredAddress" label="Company Registered Address" />
              </FormGroup>
              <FormGroup>
                <FieldArray name="shareClasses">
                  {({ push, remove }) => (
                    <>
                      <FormGroup>
                        <Typography variant="h3">Share Classes</Typography>
                      </FormGroup>
                      {values.shareClasses.map((_share, index) => (
                        <FormGroup key={index}>
                          <hr />
                          <FieldArrayRemoveButton handleClick={() => remove(index)} disableMargin />

                          <FormGroup>
                            <Field component={TextQuestion} name={`shareClasses[${index}].name`} label="Name" placeholder="Name" />
                          </FormGroup>
                          <FormGroup>
                            <Field
                              component={TextQuestion}
                              name={`shareClasses[${index}].description`}
                              label="Description"
                              placeholder="Description"
                            />
                          </FormGroup>
                          <FormGroup>
                            <Field
                              component={NumberQuestion}
                              moneyFormat
                              prefix="£"
                              name={`shareClasses[${index}].nominalValue`}
                              placeholder="Nominal Value"
                              label="Nominal Value"
                              decimalScale={7}
                              fixedDecimalScale={false}
                              disabled
                            />
                          </FormGroup>
                          <FormGroup>
                            <Field
                              component={NumberQuestion}
                              moneyFormat
                              prefix="£"
                              name={`shareClasses[${index}].pricePerShare`}
                              placeholder="Price Per Share"
                              label="Price Per Share"
                              decimalScale={10}
                              fixedDecimalScale={false}
                              disabled
                            />
                          </FormGroup>
                          <hr />
                        </FormGroup>
                      ))}
                      <FormGroup>
                        <Button size="small" onClick={() => push({ name: "", description: "", pricePerShare: "", nominalValue: "" })}>
                          Add Share Class
                        </Button>
                      </FormGroup>
                    </>
                  )}
                </FieldArray>
              </FormGroup>
              <FormGroup>
                <FieldArray name="sharePriceHistory">
                  {({ push, remove }) => (
                    <>
                      <FormGroup>
                        <Typography variant="h3">Share Price History:</Typography>
                      </FormGroup>
                      {values.sharePriceHistory.map((sharePrice, index) => (
                        <FormGroup key={index}>
                          <hr />
                          <FieldArrayRemoveButton handleClick={() => remove(index)} disableMargin />
                          <FormLabel>{sharePrice.date ? `Price as of ${moment(sharePrice.date).format("L")}` : "New Price"}</FormLabel>
                          <FormGroup>
                            <Field component={DateQuestion} name={`sharePriceHistory[${index}].date`} label="Date of change" />
                          </FormGroup>
                          <FormGroup>
                            <Field
                              component={SelectField}
                              name={`sharePriceHistory[${index}].shareClass`}
                              placeholder="Share Class"
                              label="Share Class"
                              options={values.shareClasses.map((share) => ({
                                label: `${share.name} - ${share.description}`,
                                value: share.name,
                              }))}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Field
                              component={NumberQuestion}
                              moneyFormat
                              prefix="£"
                              name={`sharePriceHistory[${index}].price`}
                              placeholder="Price Per Share"
                              label="Price Per Share"
                              decimalScale={10}
                              fixedDecimalScale={false}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Field
                              component={NumberQuestion}
                              name={`sharePriceHistory[${index}].nominalValue`}
                              placeholder="Nominal Value"
                              label="Nominal Value"
                              decimalScale={7}
                              fixedDecimalScale={false}
                              moneyFormat
                              prefix="£"
                            />
                          </FormGroup>
                          <FormGroup>
                            <Field
                              component={SelectField}
                              name={`sharePriceHistory[${index}].source`}
                              placeholder="Source"
                              label="Source of share price change"
                              options={SHARE_PRICE_SOURCES}
                            />
                          </FormGroup>
                          {values.sharePriceHistory[index].source === "REVALUATION" && (
                            <>
                              <FormGroup>
                                <Field
                                  component={SelectField}
                                  multiple
                                  name={`sharePriceHistory[${index}].fundIds`}
                                  label="Revaluation Applies To"
                                  options={fundOptions}
                                  value={
                                    !values.sharePriceHistory[index]?.fundIds?.length
                                      ? fundOptions.map(getValue)
                                      : values.sharePriceHistory[index].fundIds
                                  }
                                  onChange={(_e: React.ChangeEvent<{}>, selectedOptions: SelectOption[]) =>
                                    setFieldValue(
                                      `sharePriceHistory[${index}].fundIds`,
                                      selectedOptions?.length === fundOptions.length ? [] : selectedOptions.map(getValue),
                                    )
                                  }
                                  helperText={
                                    <>
                                      To include this price per share and nominal value to all current and future funds, press clear (&ldquo;{" "}
                                      <kbd>x</kbd>&rdquo;) to the right of the dropdown. Else it will apply only to the selected funds.
                                    </>
                                  }
                                />
                              </FormGroup>
                              <FormGroup>
                                <Field component={TextQuestion} name={`sharePriceHistory[${index}].reason`} placeholder="Reason" label="Reason" />
                              </FormGroup>
                            </>
                          )}
                        </FormGroup>
                      ))}
                      <FormGroup>
                        <Button
                          size="small"
                          onClick={() =>
                            push({
                              date: null,
                              price: "",
                              source: "",
                              nominalValue: "",
                              fundIds: [],
                              reason: null,
                            })
                          }
                        >
                          Add Historic/Current Share Price
                        </Button>
                      </FormGroup>
                    </>
                  )}
                </FieldArray>
              </FormGroup>

              <FormGroup>
                <FieldArray name="icRounds">
                  {({ push, remove }) => (
                    <>
                      <FormGroup>
                        <Typography variant="h3">Investment Committee (IC) Rounds:</Typography>
                      </FormGroup>
                      {values.icRounds.map((icRound, index) => (
                        <FormGroup key={index}>
                          <hr />
                          <FieldArrayRemoveButton handleClick={() => remove(index)} disableMargin />
                          <FormLabel>{icRound.date ? `IC Round for ${moment(icRound.date).format("L")}` : "New IC Round"}</FormLabel>
                          <FormGroup>
                            <Field component={DateQuestion} name={`icRounds[${index}].date`} label="Date of IC Round" />
                          </FormGroup>
                          <FormGroup>
                            <Field
                              component={NumberQuestion}
                              moneyFormat
                              prefix="£"
                              name={`icRounds[${index}].resourceAmount`}
                              placeholder="Resource Amount"
                              label="Resource Amount"
                            />
                          </FormGroup>
                          <FormGroup>
                            <Field
                              component={NumberQuestion}
                              moneyFormat
                              prefix="£"
                              name={`icRounds[${index}].investmentAmount`}
                              placeholder="Investment Amount"
                              label="Investment Amount"
                            />
                          </FormGroup>
                          <hr />
                        </FormGroup>
                      ))}
                      <FormGroup>
                        <Button size="small" onClick={() => push({ date: null, resourceAmount: "", investmentAmount: "" })}>
                          Add IC Round
                        </Button>
                      </FormGroup>
                    </>
                  )}
                </FieldArray>
              </FormGroup>
              <FormGroup>
                <FieldArray name="updates">
                  {({ push, remove }) => (
                    <>
                      <FormGroup>
                        <Typography variant="h3">Founder Updates:</Typography>
                      </FormGroup>
                      {values.updates.map((update, index) => (
                        <FormGroup key={index}>
                          <hr />
                          <FieldArrayRemoveButton handleClick={() => remove(index)} disableMargin />
                          <FormLabel>{update.date ? `Update for ${moment(update.date).format("L")}` : "New Update"}</FormLabel>
                          <FormGroup>
                            <Field component={DateQuestion} name={`updates[${index}].date`} label="Date of update" />
                          </FormGroup>
                          <FormGroup>
                            <Field
                              component={TextQuestion}
                              name={`updates[${index}].text`}
                              placeholder="Update"
                              label="What is the update?"
                              multiline
                            />
                          </FormGroup>
                          <hr />
                        </FormGroup>
                      ))}
                      <FormGroup>
                        <Button size="small" onClick={() => push({ date: null, text: "" })}>
                          Add Founder Update
                        </Button>
                      </FormGroup>
                    </>
                  )}
                </FieldArray>
                <FormGroup>
                  <Field
                    component={NumberQuestion}
                    moneyFormat
                    prefix="£"
                    name={`resourceBalance`}
                    placeholder="Resource Balance"
                    label="Resource Balance"
                    decimalScale={2}
                    fixedDecimalScale={false}
                  />
                </FormGroup>
              </FormGroup>
              <CrudFormButtons isEdit={Boolean(company)} handleDelete={handleDelete} onClose={onClose} />
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default CompanyForm;
