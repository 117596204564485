import { Container, Tab, Tabs, Tooltip } from "@material-ui/core";
import { ConfigContext } from "client/context/config.context";
import React, { ReactElement, useCallback, useContext, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import InfoBanner from "../../components/CTAs/InfoBanner.component";
import { AuthContext } from "../../context/auth.context";
import TabPanel from "./TabPanel.view";

const InformationMemorandum: React.FC = () => {
  const { tabName } = useParams<{ tabName?: string }>();
  const history = useHistory();
  const { fund } = useContext(ConfigContext);
  const {
    state: { user },
  } = useContext(AuthContext);

  const restrictTabs = useMemo(() => !user?.isQualified && !user?.isVerified, [user?.isQualified, user?.isVerified]);

  const DisabledTooltip = useCallback(
    ({ children }) => (
      <Tooltip
        placement="bottom"
        arrow
        enterDelay={0}
        enterTouchDelay={0}
        title={
          restrictTabs
            ? user?.isAdvisor
              ? "Before you can start sharing our investment opportunities with your clients, we need to verify your IFA status."
              : "Before you can access our fund documentation and further information, you must complete our qualification process."
            : ""
        }
      >
        <span>{children}</span>
      </Tooltip>
    ),
    [user?.isAdvisor, restrictTabs],
  );

  const bannerProps = useMemo(
    () => ({
      isQualified: user?.isQualified,
      isInvestor: user?.isInvestor,
      isAdvisor: user?.isAdvisor,
    }),
    [user?.isAdvisor, user?.isInvestor, user?.isQualified],
  );

  const tabs = useMemo(
    () =>
      fund.informationMemorandum.contents
        .map((content) => {
          if (content.howToInvest && user?.applicationStatus === "Funds Deployed") return;
          if (content.returnsCalculator && !fund.returnsCalculator.enabled) return;
          return (
            <Tab
              key={content.tabValue}
              label={content.unrestricted ? content.tabLabel : <DisabledTooltip>{content.tabLabel}</DisabledTooltip>}
              value={content.tabValue}
              disabled={!content.unrestricted && restrictTabs}
            />
          );
        })
        .filter((tab): tab is ReactElement => Boolean(tab)),
    [DisabledTooltip, fund.informationMemorandum.contents, fund.returnsCalculator.enabled, restrictTabs, user?.applicationStatus],
  );

  const tabPanels = useMemo(
    () => fund.informationMemorandum.contents.map((content) => <TabPanel key={content.tabValue} config={content} />),
    [fund.informationMemorandum.contents],
  );

  return (
    <>
      <Tabs value={tabName} onChange={(_e, newValue) => history.push(newValue)} scrollButtons="auto">
        {tabs}
      </Tabs>
      <InfoBanner {...bannerProps} />
      <Container>{tabPanels}</Container>
    </>
  );
};

export default InformationMemorandum;
