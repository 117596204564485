import { Button, FormGroup, Typography } from "@material-ui/core";
import Divider from "client/components/Divider.component";
import FieldArrayRemoveButton from "client/components/FieldArrayRemoveButton.component";
import { ConfigContext } from "client/context/config.context";
import { useAllFunds, useAllPortfolios } from "client/hooks/adminContext.hooks";
import { Field, FieldArray, Form, Formik } from "formik";
import _ from "lodash";
import moment from "moment";
import { ChangeEvent, FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ICRoundWithRemaining, LeanCompany } from "server/services/company/company.types";
import { LeanFund } from "server/services/fund/fund.types";
import { LeanShareTransaction, PopulatedShareTransaction } from "server/services/shareTransaction/shareTransaction.types";
import * as Yup from "yup";
import { getApplications } from "../../../api/admin/application.adminApi";
import { getCompanies, getIcRounds } from "../../../api/admin/companies.adminApi";
import { deleteShareTransaction, saveShareTransaction } from "../../../api/admin/shareTransactions.adminApi";
import { getUsers } from "../../../api/admin/users.adminApi";
import CheckboxQuestion from "../../../components/FormControls/CheckboxQuestion.component";
import CrudFormButtons from "../../../components/FormControls/CrudFormButtons.component";
import DatePickerField from "../../../components/FormControls/DatePickerField.component";
import NumberQuestion from "../../../components/FormControls/NumberQuestion.component";
import RadioQuestion from "../../../components/FormControls/RadioQuestion.component";
import SelectField from "../../../components/FormControls/SelectField.component";
import TextQuestion from "../../../components/FormControls/TextQuestion.component";
import FormikErrorsTouched from "../../../components/FormikErrorsTouched.component";
import ErrorText from "../../../components/UIKit/ErrorText.component";
import { AdminContext } from "../../../context/admin.context";
import { CURRENCY_CODES, SHARE_TRANSACTION_STATUSES } from "../../../utils/constants";
import { formatThousands } from "../../../utils/helpers";
import { INVESTEE_CATEGORIES } from "./utils";
import RoundingErrorManager from "./RoundingErrorManager.view";
import { hasMoreThanTwoDecimalPlaces } from "../Deployments/utils";

interface Props {
  shareTransaction?: LeanShareTransaction | PopulatedShareTransaction;
  isBulkAdd?: boolean;
  isFinalShareTransaction?: boolean;
  onClose?(): any;
  onSubmit?(values: any): any;
  onPrevious?(): any;
}

interface LeanShareTransactionWithRoundingError extends LeanShareTransaction {
  roundingError: boolean;
}

interface FormattedICRound {
  value: string;
  label: string;
  remainingInvestmentAmount?: number;
}

const SCHEMA = (icRounds: GenericObject[]) =>
  Yup.object()
    .shape({
      company: Yup.string().required("Please select a company from the dropdown list"),
      deal: Yup.string().nullable(),
      portfolio: Yup.string().nullable(),
      shareCertificate: Yup.object({
        key: Yup.mixed().notRequired(),
        issueDate: Yup.date().nullable(),
      }),
      longstopDate: Yup.date().nullable(),
      topLevelInvestor: Yup.string().nullable(),
      nominee: Yup.string().nullable(),
      investmentManager: Yup.string().nullable(),
      project: Yup.string(),
      buySell: Yup.string().required("Please select either Buy/Sell"),
      currency: Yup.string(),
      transactionType: Yup.string().required("Please select a transaction type"),
      investmentRound: Yup.number().nullable(),
      services: Yup.number().nullable(),
      equivalentShares: Yup.number().nullable(),
      totalShares: Yup.number().nullable(),
      settleDate: Yup.date().required("Please enter the settle date of this share transaction"),
      tradeDate: Yup.date().required("Please enter the trade date of this share transaction"),
      icRound: Yup.string(),
      investeeCategory: Yup.string(),
      dealStage: Yup.string().required("Deal stage required"),
      shareClass: Yup.string().required('Please enter a description of the shares (typically "ORDINARY")'),
      knowledgeIntensiveCompany: Yup.boolean()
        .nullable()
        .notRequired()
        .when("dealStage", {
          is: (dealStage: string) => dealStage === "EIS",
          then: Yup.boolean().required("Please indicate if the company was knowledge-intensive on issue date"),
        }),
      pricePerShare: Yup.number()
        .notRequired()
        .nullable()
        .when("transactionType", {
          is: (transactionType: string) => transactionType !== "ASA",
          then: Yup.number()
            .typeError("Price per share must be a number")
            .required("Please enter the price per share")
            .min(0, "Price per share must be a positive number"),
        }),
      nominalValuePerShare: Yup.number()
        .notRequired()
        .nullable()
        .when("transactionType", {
          is: (transactionType: string) => transactionType !== "ASA",
          then: Yup.number()
            .typeError("Nominal value per share must be a number")
            .min(0, "Value must be positive")
            .required("Please enter a nominal value per share (typically 0.0001)"),
        }),
      uir: Yup.string().notRequired(),
      seis1: Yup.object({
        sent: Yup.date().nullable(),
      }),
      seis2: Yup.object({
        expected: Yup.date().nullable(),
        received: Yup.date().nullable(),
        key: Yup.mixed().notRequired(),
      }),
      notes: Yup.string(),
      subscriptions: Yup.array(
        Yup.object({
          investor: Yup.string().required("Please select an investor"),
          application: Yup.string(),
          noOfShares: Yup.number().typeError("Must be a number").required("Please enter the number of shares").min(0, "Must be a positive number"),
          investmentAmount: Yup.number().nullable().notRequired(),
          contractNote: Yup.mixed().notRequired(),
          seis3: Yup.object({
            key: Yup.mixed().notRequired(),
            generated: Yup.date().nullable(),
            signed: Yup.date().nullable(),
          }),
        }),
      ).when("transactionType", {
        is: (transactionType: string) => transactionType === "ASA",
        then: (schema) =>
          schema.clone().of(
            Yup.reach(schema, "0")
              .clone()
              .omit(["noOfShares", "investmentAmount"])
              .concat(
                Yup.object({
                  noOfShares: Yup.number().nullable().notRequired(),
                  investmentAmount: Yup.number()
                    .typeError("Must be a number")
                    .required("Please enter the investment amount")
                    .min(0, "Must be a positive number"),
                }),
              ),
          ),
      }),
      status: Yup.string()
        .oneOf([...SHARE_TRANSACTION_STATUSES])
        .required("Please indicate what status this share transaction is in"),
      doNotSendEmails: Yup.boolean(),
      roundingError: Yup.boolean().required("Please fix the rounding error").oneOf([false], "Please fix the rounding error"),
    })
    .test("total-amount-less-than-equal-to-IcRound", "Share Transaction amount cannot exceed IC Round", function (value) {
      if (!value) return true;
      const totalNoOfShares = value.subscriptions!.reduce((prevAmount, currAmount) => {
        return prevAmount + (currAmount?.noOfShares || 0);
      }, 0);
      const totalShareTransactionInvested = totalNoOfShares * value.pricePerShare!;
      const icRound = icRounds.find((icRoundId) => icRoundId.value === value.icRound);

      return !icRound || totalShareTransactionInvested <= icRound.remainingInvestmentAmount
        ? true
        : this.createError({
            path: "icRoundAndTotalShareTransactionAmount",
            message: `Share Transaction amount cannot exceed IC Round amount of ${icRound.remainingInvestmentAmount}`,
            params: {},
          });
    });

const EMPTY_SUBSCRIPTION = {
  investor: "",
  application: "",
  noOfShares: "",
  investmentAmount: "",
  seis3: {
    key: "",
    generated: null,
    signed: null,
  },
};

const DEFAULT_VALUES = {
  company: "",
  portfolio: "",
  dateOfMonies: null,
  shareCertificate: {
    issueDate: null,
    key: "",
  },
  longstopDate: null,
  nominee: null,
  topLevelInvestor: "",
  investmentManager: "",
  settleDate: null,
  tradeDate: null,
  project: "",
  buySell: "",
  currency: "GBP",
  transactionType: "",
  equivalentShares: "",
  services: "",
  investmentRound: "",
  icRound: "",
  dealStage: "",
  shareClass: "",
  knowledgeIntensiveCompany: null,
  pricePerShare: null,
  nominalValuePerShare: "",
  uir: "",
  totalShares: null,
  seis1: {
    sent: null,
  },
  seis2: {
    expected: null,
    received: null,
    key: "",
  },
  notes: "",
  subscriptions: [],
  status: "",
  doNotSendEmails: false,
  investeeCategory: INVESTEE_CATEGORIES[3],
  roundingError: false,
} as unknown as LeanShareTransactionWithRoundingError;

const ShareTransactionForm: FC<Props> = ({ shareTransaction, isBulkAdd, onClose, isFinalShareTransaction, ...props }) => {
  const { allApplications, allCompanies, allUsers, dispatch } = useContext(AdminContext);
  const allPortfolios = useAllPortfolios();
  const allFunds = useAllFunds();
  const { fund } = useContext(ConfigContext);
  const transactionFund = useMemo(
    () => (!shareTransaction ? fund : allFunds.find((f) => f._id === (shareTransaction.fund ?? null))),
    [shareTransaction, fund, allFunds],
  );

  const [companyIcRounds, setCompanyIcRounds] = useState<FormattedICRound[]>([]);

  useEffect(() => {
    (async () => {
      const res = await getApplications({});
      if (res) {
        dispatch({ type: "SET_ALL_APPLICATIONS", payload: res.data });
      }
    })();
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      const res = await getCompanies();
      if (res) {
        dispatch({ type: "SET_ALL_COMPANIES", payload: res.data });
      }
    })();
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      const res = await getUsers();
      if (res) {
        dispatch({ type: "SET_ALL_USERS", payload: res.data });
      }
    })();
  }, [dispatch]);

  const companyOptions = useMemo(
    () =>
      allCompanies
        .map((company) => ({
          value: company._id,
          label: company.companyName || `N/A (Project: ${company.projectName})`,
          projectName: company.projectName,
          shareClasses: company.shareClasses,
        }))
        // sort alphabetically (on companyName)
        .sort((a, b) => {
          return a.label.localeCompare(b.label);
        }),
    [allCompanies],
  );

  const getDealOptions = useCallback(
    (company?: LeanCompany | string) => {
      if (!company) return [];
      const companyObject = typeof company === "string" ? allCompanies.find((c) => c._id === company) : company;
      if (!companyObject) return [];
      return (
        companyObject.deals
          ?.filter((deal) => deal.investmentRequired)
          .map((deal) => ({
            label: `${moment(deal.startDate).format("L")} - ${deal.investmentStage}`,
            value: deal._id,
          })) || []
      );
    },
    [allCompanies],
  );

  const shareClassesOptions = useMemo(
    () =>
      companyOptions.reduce(
        (prev, current) => ({
          ...prev,
          [current.value]:
            current.shareClasses &&
            current.shareClasses.map((share) => ({
              value: share.name,
              label: `${share.name} - ${share.description}`,
              nominalValue: share.nominalValue,
              pricePerShare: share.pricePerShare,
            })),
        }),
        {} as GenericObject<{ value: string; label: string; nominalValue?: number; pricePerShare: number }[]>,
      ),
    [companyOptions],
  );

  const investorOptions = useMemo(
    () =>
      allUsers.map((user) => ({
        value: user._id,
        label: `${user.forenames} ${user.surname} - ${user.email}`,
      })),
    [allUsers],
  );

  const applicationOptions = useMemo(
    () =>
      allApplications
        .filter((application) => Boolean(application.completedTimestamp))
        .map((application) => ({
          value: application._id,
          investorId: application.investor.user._id,
          label: `${moment(application.completedTimestamp).format("L")} - ${
            _.get(application, "data.investmentInformation.investmentAmount")
              ? formatThousands(_.get(application, "data.investmentInformation.investmentAmount"), 2)
              : "Unknown Amount"
          }`,
        })),
    [allApplications],
  );

  const portfolioOptions = useMemo(() => allPortfolios.map((portfolio) => ({ value: portfolio._id, label: portfolio.name })), [allPortfolios]);

  const transactionTypeOptions = [
    { label: "Primary", value: "Primary" },
    { label: "Secondary", value: "Secondary" },
    { label: "Share Split", value: "Share Split" },
    { label: "Transfer", value: "Transfer" },
    { label: "Reserve", value: "Reserve" },
    { label: "ASA", value: "ASA" },
  ];

  const getCompanyIcRounds = useCallback(async (companyId) => {
    const icRounds: ICRoundWithRemaining[] = await getIcRounds(companyId);
    const formattedIcRounds = icRounds.map<FormattedICRound>((icRound) => ({
      value: icRound._id,
      label: `IC Round on ${moment(icRound.date).format("L")}`,
      remainingInvestmentAmount: icRound.remainingInvestmentAmount,
    }));

    setCompanyIcRounds(formattedIcRounds);
  }, []);

  useEffect(() => {
    if (shareTransaction?.company)
      getCompanyIcRounds(typeof shareTransaction.company === "string" ? shareTransaction.company : shareTransaction.company._id);
  }, [shareTransaction, getCompanyIcRounds]);

  const handleSubmit = useCallback(
    async ({ knowledgeIntensiveCompany, ...vs }) => {
      if (vs.transactionType === "ASA") {
        vs.totalShares = null;
        vs.pricePerShare = null;
        vs.nominalValuePerShare = null;
      } else {
        vs.longstopDate = null;
      }

      if (!vs.deal && vs.deal !== null) vs.deal = null;
      if (vs.subscriptions?.length) {
        vs.totalShares = null;
      }
      if (isBulkAdd && props.onSubmit) return props.onSubmit({ ...vs, knowledgeIntensiveCompany });
      const values =
        typeof knowledgeIntensiveCompany === "string"
          ? vs
          : {
              ...vs,
              knowledgeIntensiveCompany,
            };

      const newShareTransaction = await (shareTransaction ? saveShareTransaction(values, shareTransaction._id) : saveShareTransaction(values));
      if (newShareTransaction) {
        dispatch({ type: "UPDATE_SHARE_TRANSACTIONS", payload: newShareTransaction });
        onClose?.();
      }
    },
    [isBulkAdd, props, shareTransaction, dispatch, onClose],
  );

  const handleDelete = useCallback(async () => {
    if (shareTransaction) {
      const shareTransactionId = await deleteShareTransaction(shareTransaction._id);
      if (shareTransactionId) {
        dispatch({ type: "DELETE_SHARE_TRANSACTIONS", payload: shareTransactionId });
        onClose?.();
      }
    }
  }, [shareTransaction, dispatch, onClose]);

  const initialValues = useMemo(() => {
    const investmentManager = transactionFund?.manager.companyName ?? fund?.manager.companyName;
    const topLevelInvestor = transactionFund?.deployment?.investor ?? transactionFund?.manager.companyName;

    if (!shareTransaction) {
      return {
        ...DEFAULT_VALUES,
        investmentManager,
        topLevelInvestor,
      };
    }

    return {
      ...DEFAULT_VALUES,
      ...shareTransaction,
      totalShares:
        shareTransaction.totalShares || _.sumBy<(typeof shareTransaction.subscriptions)[number]>(shareTransaction.subscriptions, "noOfShares"),
      company: typeof shareTransaction.company === "string" ? shareTransaction.company : shareTransaction.company._id,
      project:
        typeof shareTransaction.company === "string"
          ? allCompanies.find((c) => c._id === shareTransaction.company)?.projectName ?? ""
          : shareTransaction.company.projectName,
      shareCertificate: {
        ...DEFAULT_VALUES.shareCertificate,
        ...shareTransaction.shareCertificate,
      },
      seis1: {
        ...DEFAULT_VALUES.seis1,
        ...shareTransaction.seis1,
      },
      seis2: {
        ...DEFAULT_VALUES.seis2,
        ...shareTransaction.seis2,
      },
      subscriptions: shareTransaction.subscriptions.map((subscription) => ({
        ...EMPTY_SUBSCRIPTION,
        ...subscription,
        seis3: {
          ...EMPTY_SUBSCRIPTION.seis3,
          ...subscription.seis3,
        },
        investor: typeof subscription.investor === "string" ? subscription.investor : subscription.investor._id,
        adjustmentFactor: subscription.adjustmentFactor || 0,
      })),
      roundingError: false,
    } as unknown as LeanShareTransactionWithRoundingError;
  }, [allCompanies, fund?.manager.companyName, shareTransaction, transactionFund?.deployment?.investor, transactionFund?.manager.companyName]);

  const schema = useMemo(() => SCHEMA(companyIcRounds), [companyIcRounds]);
  const resetAdjustments = useCallback((values: LeanShareTransactionWithRoundingError, setFieldValue) => {
    const updatedSubscriptions = values.subscriptions.map((subscription) => ({
      ...subscription,
      adjustmentFactor: 0,
    }));
    setFieldValue("subscriptions", updatedSubscriptions);
  }, []);

  return (
    <Formik onSubmit={handleSubmit} validationSchema={schema} initialValues={initialValues} enableReinitialize={true}>
      {({ values, setFieldValue, errors }) => {
        const isASA = values.transactionType === "ASA";
        return (
          <Form autoComplete="off" data-testid="shareTransactionForm">
            <FormikErrorsTouched />
            <FormGroup>
              <Field
                component={SelectField}
                name="company"
                label="Company"
                placeholder="Company Name"
                options={companyOptions}
                onChange={(_event: any, selectedOption: (typeof companyOptions)[0]) => {
                  const companyId = selectedOption?.value;
                  if (companyId) {
                    setFieldValue("company", companyId);
                    getCompanyIcRounds(companyId);
                    setFieldValue("project", selectedOption.projectName);
                  } else {
                    setFieldValue("company", "");
                    setCompanyIcRounds([]);
                  }
                }}
              />
            </FormGroup>
            <FormGroup row>
              <Field component={SelectField} name="deal" label="Deal" placeholder="Deal" options={getDealOptions(values.company)} />
              <Field component={SelectField} name="portfolio" label="Portfolio" placeholder="Portfolio" options={portfolioOptions} />
            </FormGroup>
            <FormGroup>
              <hr />
            </FormGroup>
            <FormGroup>
              <Typography variant="body1">
                <strong>Share Certificate</strong>
              </Typography>
            </FormGroup>
            <FormGroup row>
              <Field component={DatePickerField} name="shareCertificate.issueDate" label="Date of Issue" autoComplete="off" />
              <Field
                component={TextQuestion}
                name="shareCertificate.key"
                label="Share Certificate File"
                type="file"
                accept="application/pdf"
                value={undefined}
                autoComplete="off"
                onChange={(e: any) => setFieldValue(e.target.name, e.target.files[0])}
              />
            </FormGroup>
            <FormGroup>
              <hr />
            </FormGroup>
            <FormGroup>
              <Field
                component={SelectField}
                name="nominee"
                placeholder="Nominee"
                label="Nominee (Company Name or Investor)"
                options={investorOptions.map((option) => option.label)}
                freeSolo
              />
            </FormGroup>
            <FormGroup>
              <Field component={TextQuestion} name="topLevelInvestor" placeholder="Investor" label="Investor" />
            </FormGroup>
            <FormGroup>
              <Field component={TextQuestion} name="project" placeholder="Project" label="Project" disabled />
            </FormGroup>
            <FormGroup>
              <Field
                component={SelectField}
                name="buySell"
                placeholder="Buy/Sell"
                label="Buy/Sell"
                options={[
                  { label: "Buy", value: "Buy" },
                  { label: "Sell", value: "Sell" },
                ]}
              />
            </FormGroup>
            <FormGroup>
              <Field component={SelectField} name="currency" placeholder="Currency" label="Currency" options={CURRENCY_CODES} />
            </FormGroup>
            <FormGroup>
              <Field
                component={SelectField}
                name="transactionType"
                placeholder="Transaction Type"
                label="Transaction Type"
                options={transactionTypeOptions}
              />
            </FormGroup>

            {isASA && (
              <FormGroup>
                <Field component={DatePickerField} name="longstopDate" label="Long Stop Date" autoComplete="off" nullable />
              </FormGroup>
            )}
            <FormGroup>
              <Field component={NumberQuestion} name="investmentRound" placeholder="Investment Round" label="Investment Round" />
            </FormGroup>
            <FormGroup>
              <Field component={NumberQuestion} name="services" placeholder="Services" label="Services" />
            </FormGroup>
            <FormGroup>
              <Field component={NumberQuestion} name="equivalentShares" placeholder="Equivalent Shares" label="Equivalent Shares" />
            </FormGroup>
            <FormGroup>
              <Field
                component={SelectField}
                name="investmentManager"
                label="Investment Manager"
                placeholder="Investment Manager"
                options={[...allFunds.map((fund: LeanFund) => fund.manager.companyName), "Sapia Partners LLP"]} // Sapia Partners LLP still available to support old transactions
              />
            </FormGroup>
            <FormGroup row>
              <Field component={DatePickerField} data-testid="settleDate" name="settleDate" label="Settle Date" autoComplete="off" />
              <Field component={DatePickerField} data-testid="tradeDate" name="tradeDate" label="Trade Date" autoComplete="off" />
            </FormGroup>
            <FormGroup>
              <Field
                component={SelectField}
                name="icRound"
                label="Investor Committee (IC) Round"
                placeholder={companyIcRounds.length === 0 ? "No IC Rounds available for this company" : "IC Round"}
                disabled={companyIcRounds.length === 0}
                options={companyIcRounds}
              />
            </FormGroup>
            <FormGroup>
              <Field component={SelectField} name="status" options={SHARE_TRANSACTION_STATUSES} label="Status" placeholder="Status" />
            </FormGroup>
            <FormGroup>
              <Field component={CheckboxQuestion} name="doNotSendEmails" label="Do not send emails to finance or investors?" />
            </FormGroup>
            <FormGroup>
              <Field
                component={SelectField}
                name="investeeCategory"
                options={INVESTEE_CATEGORIES}
                label="Investee Category"
                placeholder="Open investment"
              />
            </FormGroup>
            <FormGroup>
              <Field
                component={SelectField}
                name="dealStage"
                label="Deal Stage"
                placeholder="Deal stage"
                options={["SEIS", "EIS", "NONE", "INITIAL"]}
              />
            </FormGroup>
            {values.dealStage === "EIS" && (
              <FormGroup>
                <Field component={RadioQuestion} name="knowledgeIntensiveCompany" label="Knowledge-intensive company at the time of share issue" />
              </FormGroup>
            )}
            <FormGroup>
              <Divider />
            </FormGroup>
            <FormGroup>
              <Field
                component={SelectField}
                name="shareClass"
                placeholder="Share Class"
                data-testid="shareClass"
                label="Share Class"
                options={shareClassesOptions[values.company as unknown as string] || []}
                onChange={(_event: ChangeEvent, selectedOption: (typeof shareClassesOptions)[string][0]) => {
                  setFieldValue("shareClass", selectedOption.value);
                  setFieldValue("pricePerShare", selectedOption.pricePerShare);
                  setFieldValue("nominalValuePerShare", selectedOption.nominalValue);
                }}
              />
            </FormGroup>

            {!isASA && (
              <>
                <FormGroup>
                  <Field
                    component={NumberQuestion}
                    prefix="£"
                    moneyFormat
                    data-testid="pricePerShare"
                    name="pricePerShare"
                    label="Price Per Share"
                    placeholder="Price per share"
                    decimalScale={10}
                    fixedDecimalScale={false}
                    disabled={isASA}
                  />
                </FormGroup>
                <FormGroup>
                  <Field
                    component={NumberQuestion}
                    data-testid="nominalValuePerShare"
                    name="nominalValuePerShare"
                    label="Nominal value per share"
                    placeholder="Nominal value per share"
                    decimalScale={7}
                    fixedDecimalScale={false}
                    step="0.0001"
                    min="0"
                    moneyFormat
                    prefix="£"
                    disabled={isASA}
                  />
                </FormGroup>
              </>
            )}
            <FormGroup>
              <Field
                component={CheckboxQuestion}
                name="updateAllShareClasses"
                label="Update all other share class prices for this company to this price when the transaction is finalised"
              />
            </FormGroup>
            <FormGroup>
              <Divider />
            </FormGroup>
            {!isBulkAdd && (
              <>
                <FormGroup>
                  <Field
                    component={TextQuestion}
                    name="uir"
                    label="Unique Investment Reference (UIR)"
                    placeholder="Unique Investment Reference (UIR)"
                  />
                </FormGroup>
                <FormGroup>
                  <Field component={DatePickerField} name="seis1.sent" label="(S)EIS1 sent on" autoComplete="off" />
                </FormGroup>
                <FormGroup row>
                  <Field component={DatePickerField} name="seis2.expected" label="(S)EIS2 expected on" autoComplete="off" />
                  <Field component={DatePickerField} name="seis2.received" label="(S)EIS2 received on" autoComplete="off" />
                </FormGroup>
                <FormGroup>
                  <Field
                    component={TextQuestion}
                    name="seis2.key"
                    label="(S)EIS2 File"
                    type="file"
                    accept="application/pdf"
                    value={undefined}
                    autoComplete="off"
                    onChange={(e: any) => setFieldValue(e.target.name, e.target.files[0])}
                  />
                </FormGroup>
              </>
            )}
            <FormGroup>
              <Field component={TextQuestion} name="notes" label="Notes" placeholder="Notes...." multiline rows={4} />
            </FormGroup>
            <FieldArray name="subscriptions">
              {({ push, remove }) => (
                <>
                  {values.subscriptions.map((subscription, index) => (
                    <FormGroup key={index}>
                      <hr />
                      <FieldArrayRemoveButton label={`Subscription ${index + 1}`} handleClick={() => remove(index)} disableMargin />
                      <FormGroup>
                        <Field
                          component={SelectField}
                          name={`subscriptions[${index}].investor`}
                          placeholder="Investor"
                          label="Investor"
                          options={investorOptions}
                          onChange={(_e: any, selectedOption: (typeof investorOptions)[0]) => {
                            setFieldValue(`subscriptions[${index}].investor`, selectedOption.value);
                            const filteredApplications = applicationOptions.filter((application) => application.investorId === selectedOption.value);
                            if (filteredApplications.length === 1) {
                              setFieldValue(`subscriptions[${index}].application`, filteredApplications[0].value);
                            } else {
                              setFieldValue(`subscriptions[${index}].application`, "");
                            }
                            if (values.transactionType === "ASA") {
                              setFieldValue(`subscriptions[${index}].noOfShares`, 0);
                            }
                          }}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Field
                          component={SelectField}
                          name={`subscriptions[${index}].application`}
                          placeholder="Application"
                          label="Application"
                          options={applicationOptions.filter((application) => application.investorId === subscription.investor)}
                        />
                      </FormGroup>
                      <FormGroup row>
                        {!isASA && (
                          <Field
                            component={NumberQuestion}
                            name={`subscriptions[${index}].noOfShares`}
                            placeholder="Num shares"
                            label="Num shares"
                            onChange={(_e: any, value: number) => {
                              setFieldValue(`subscriptions[${index}].noOfShares`, value);
                              setFieldValue(`subscriptions[${index}].investmentAmount`, value * values.pricePerShare!);
                              const total = values.subscriptions.reduce((prev, current, currIndex) => {
                                if (currIndex === index) return prev + value;
                                return prev + (current.noOfShares ?? 0);
                              }, 0);
                              setFieldValue("totalShares", total);
                            }}
                            min="0"
                            disabled={isASA}
                          />
                        )}
                        <Field
                          component={NumberQuestion}
                          moneyFormat
                          name={`subscriptions[${index}].investmentAmount`}
                          placeholder="Amount"
                          label="Amount"
                          disabled={values.transactionType !== "ASA"}
                          onChange={(_e: any, value: number) => {
                            setFieldValue(_e.target.name, value);
                            setFieldValue(`subscriptions[${index}].noOfShares`, 0);
                          }}
                        />

                        <Field
                          component={NumberQuestion}
                          moneyFormat
                          name={`subscriptions[${index}].adjustmentFactor`}
                          placeholder="Adjustment Factor"
                          label="Adjustment Factor"
                          disabled={true}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Field
                          component={TextQuestion}
                          name={`subscriptions[${index}].contractNote`}
                          label="Contract Note"
                          type="file"
                          accept="application/pdf"
                          value={undefined}
                          onChange={(e: any) => setFieldValue(e.target.name, e.target.files[0])}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Typography variant="body1">
                          <strong>(S)EIS3 Tax Certificate</strong>
                        </Typography>
                      </FormGroup>
                      <FormGroup row>
                        <Field component={DatePickerField} name={`subscriptions[${index}].seis3.generated`} label="Generated on" autoComplete="off" />
                        <Field component={DatePickerField} name={`subscriptions[${index}].seis3.signed`} label="Signed on" autoComplete="off" />
                      </FormGroup>
                      <FormGroup>
                        <Field
                          component={TextQuestion}
                          name={`subscriptions[${index}].seis3.key`}
                          label="Tax Certificate File"
                          type="file"
                          accept="application/pdf"
                          value={undefined}
                          autoComplete="off"
                          onChange={(e: any) => setFieldValue(e.target.name, e.target.files[0])}
                        />
                      </FormGroup>
                      <FormGroup>
                        <hr />
                      </FormGroup>
                    </FormGroup>
                  ))}
                  <FormGroup>
                    <Button data-testid="addSubscription" onClick={() => push(EMPTY_SUBSCRIPTION)}>
                      Add Subscription
                    </Button>
                  </FormGroup>
                  <hr />
                </>
              )}
            </FieldArray>
            {!isASA && (
              <FormGroup>
                <Field
                  component={NumberQuestion}
                  name="totalShares"
                  placeholder="Total Shares"
                  label="Total Shares"
                  disabled={values.subscriptions.length > 0}
                />
              </FormGroup>
            )}

            {!isASA && values.pricePerShare && hasMoreThanTwoDecimalPlaces(values.pricePerShare) && (
              //rounding error is only possible if transaction is non ASA and has price per share value in more than 2 decimals
              <RoundingErrorManager
                shareTransaction={values}
                handleAdjustmentFactor={(subscriptionIndex: number, adjustmentFactor: number) => {
                  setFieldValue(`subscriptions[${subscriptionIndex}].adjustmentFactor`, adjustmentFactor);
                }}
                resetAdjustments={() => {
                  resetAdjustments(values, setFieldValue);
                }}
                roundingErrorHandler={(roundingError: boolean) => {
                  setFieldValue("roundingError", roundingError);
                }}
              />
            )}

            {(errors as GenericObject).icRoundAndTotalShareTransactionAmount && (values.subscriptions[0].noOfShares as any) !== "" && (
              <ErrorText>{(errors as GenericObject).icRoundAndTotalShareTransactionAmount}</ErrorText>
            )}
            <CrudFormButtons
              isEdit={!isBulkAdd && Boolean(shareTransaction)}
              handleDelete={handleDelete}
              onClose={isBulkAdd ? props.onPrevious! : onClose!}
              isSubmitAll={isFinalShareTransaction}
              submitText="Submit"
              cancelText="Previous Share Transaction"
              sticky={isBulkAdd}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
export default ShareTransactionForm;
