import { Container, FormGroup, makeStyles, Typography } from "@material-ui/core";
import ApplicationApi from "client/api/application.api";
import FormikErrorsTouched from "client/components/FormikErrorsTouched.component";
import { InitialValues } from "client/utils/helpers";
import { Field, Form, Formik } from "formik";
import React, { FC, useCallback, useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { FinancialSituation as IFinancialSituation, LeanApplication } from "server/services/application/application.types";
import * as Yup from "yup";
import * as AdminApplicationApi from "../../api/admin/application.adminApi";
import ClientApplicationApi from "../../api/advisor/client/application.clientApi";
import ErrorsList from "../../components/ErrorsList.component";
import RadioQuestion from "../../components/FormControls/RadioQuestion.component";
import TextQuestion from "../../components/FormControls/TextQuestion.component";
import { APPLICATION_TYPES } from "../../context/application.context";
import { AuthContext } from "../../context/auth.context";
import useAnalytics from "../../hooks/useAnalytics";
import { FORM_FIELDS_DATA, ROUTES, TRACKED_EVENTS } from "../../utils/constants";
import StageButtonGroup from "./StageButtonGroup.component";
import useApplication from "./useApplication";

interface Props {
  isAdmin?: boolean;
}
const { FINANCIAL_SITUATION_FORM } = FORM_FIELDS_DATA;

const {
  INPUT: {
    CATEGORIES: { FINANCIAL_SITUATION },
  },
  PAGE_VIEWS,
  PAGE_ACTIONS,
} = TRACKED_EVENTS;

const SCHEMA = Yup.object().shape({
  [FINANCIAL_SITUATION_FORM.disposableIncome.name]: Yup.string().required("Please indicate your annual net disposable income"),
  [FINANCIAL_SITUATION_FORM.nav.name]: Yup.string().required("Please indicate the value of your assets"),
  [FINANCIAL_SITUATION_FORM.residenceValue.name]: Yup.string().required("Please indicate the value of your residence"),
  [FINANCIAL_SITUATION_FORM.incomeSource.name]: Yup.string().required("Please enter source of income"),
  [FINANCIAL_SITUATION_FORM.bearLosses.name]: Yup.boolean().required("Please indicate whether you are able to lose all funds invested"),
});

const DEFAULT_VALUES = {
  [FINANCIAL_SITUATION_FORM.disposableIncome.name]: "",
  [FINANCIAL_SITUATION_FORM.nav.name]: "",
  [FINANCIAL_SITUATION_FORM.residenceValue.name]: "",
  [FINANCIAL_SITUATION_FORM.incomeSource.name]: "",
  [FINANCIAL_SITUATION_FORM.bearLosses.name]: null,
};

const useStyles = makeStyles(
  (theme) => ({
    sectionTitle: {
      fontWeight: 600,
    },
    formGroup: {
      paddingBottom: theme.spacing(5),
      [theme.breakpoints.down("md")]: {
        paddingBottom: theme.spacing(3),
      },
    },
    hr: {
      borderTop: "solid 1px",
    },
    infoText: {
      fontWeight: 600,
    },
  }),
  { name: "FinancialSituationView" },
);

const FinancialSituation: FC<Props> = ({ isAdmin, ...props }) => {
  const classes = useStyles(props);
  const {
    state: { user },
  } = React.useContext(AuthContext);
  const history = useHistory();
  const params = useParams<{ applicationId?: string; clientID?: string }>();
  const applicationId = useMemo(() => params.applicationId, [params.applicationId]);
  const clientId = useMemo(() => user?.isAdvisor && params.clientID, [params.clientID, user?.isAdvisor]);
  const { application, dispatch } = useApplication(isAdmin);

  useAnalytics();

  useEffect(() => {
    globalThis.dataLayer.push({
      event: clientId ? PAGE_VIEWS.CLIENT_APPLICATION_FORM_FINANCES : PAGE_VIEWS.INVESTOR_APPLICATION_FINANCIAL,
    });
  }, [clientId]);

  const initialValues = useMemo<InitialValues<IFinancialSituation>>(
    () => (application && application.data ? { ...DEFAULT_VALUES, ...application.data.financialSituation } : DEFAULT_VALUES),
    [application],
  );
  const isInitialValid = useMemo(() => SCHEMA.isValidSync(initialValues), [initialValues]);

  const goForward = useCallback(() => {
    history.push(`..${ROUTES.APPLICATION_EXPERIENCE}`);
  }, [history]);

  const handleFormSubmit = useCallback(
    async (values) => {
      let submittedApplication: LeanApplication | null = null;
      if (clientId) {
        const res = await ClientApplicationApi.postFinances(clientId, application!._id, values);
        if (res) {
          submittedApplication = res;
          globalThis.dataLayer.push({ event: PAGE_ACTIONS.CLIENT_APPLICATION_ABOUT_FORM_SUBMITTED_SUCCESSFULLY });
        } else {
          globalThis.dataLayer.push({ event: PAGE_ACTIONS.CLIENT_APPLICATION_ABOUT_FORM_SUBMITTED_UNSUCCESSFULLY });
        }
      } else if (isAdmin) {
        const res = await AdminApplicationApi.postFinances(applicationId!, values);
        if (res) submittedApplication = res;
      } else {
        const res = await ApplicationApi.postFinances(application!._id, values);
        if (res) {
          submittedApplication = res;
          globalThis.dataLayer.push({ event: PAGE_ACTIONS.INVESTOR_APPLICATION_FORM_ABOUT_SUBMITTED_SUCCESSFULLY });
        } else {
          globalThis.dataLayer.push({
            event: PAGE_ACTIONS.INVESTOR_APPLICATION_FORM_ABOUT_SUBMITTED_UNSUCCESSFULLY,
          });
        }
      }
      if (submittedApplication) {
        dispatch({ type: APPLICATION_TYPES.SET_APPLICATION, payload: submittedApplication });
        goForward();
      }
    },
    [application, applicationId, clientId, dispatch, goForward, isAdmin],
  );

  return (
    <>
      <Container maxWidth="lg">
        <FormGroup>
          <Typography variant="h2" align="center">
            Appropriateness questionnaire
          </Typography>
        </FormGroup>
      </Container>
      <hr className={classes.hr} />
      <Formik
        initialValues={initialValues}
        isInitialValid={isInitialValid}
        validationSchema={SCHEMA}
        enableReinitialize={true}
        onSubmit={handleFormSubmit}
      >
        {({ errors, submitCount }) => (
          <Form autoComplete="off" noValidate>
            <FormikErrorsTouched />
            <Container maxWidth="md">
              <FormGroup>
                <Typography variant="h4" className={classes.sectionTitle}>
                  Section 3:
                </Typography>
                <Typography variant="h4" paragraph>
                  Financial Situation
                </Typography>
                <Typography variant="h5" paragraph className={classes.infoText}>
                  The figures below should include your spouse or civil partner, where applicable.
                </Typography>
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <Field
                  component={RadioQuestion}
                  name={FINANCIAL_SITUATION_FORM.disposableIncome.name}
                  label={FINANCIAL_SITUATION_FORM.disposableIncome.label}
                  trackedCategory={FINANCIAL_SITUATION}
                  radioValues={["Above £100,000", "£50,000-100,000", "£25,000-50,000", "£5,000-25,000", "Less than £5,000"]}
                />
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <Field
                  component={RadioQuestion}
                  name={FINANCIAL_SITUATION_FORM.nav.name}
                  label={FINANCIAL_SITUATION_FORM.nav.label}
                  trackedCategory={FINANCIAL_SITUATION}
                  radioValues={[
                    "Above £1,000,000",
                    "£500,000-1,000,000",
                    "£250,000-500,000",
                    "£100,000-250,000",
                    "£50,000-100,000",
                    "Less than £50,000",
                  ]}
                />
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <Field
                  component={RadioQuestion}
                  name={FINANCIAL_SITUATION_FORM.residenceValue.name}
                  label={FINANCIAL_SITUATION_FORM.residenceValue.label}
                  trackedCategory={FINANCIAL_SITUATION}
                  radioValues={[
                    "Above £1,000,000",
                    "£500,000-1,000,000",
                    "£250,000-500,000",
                    "£100,000-250,000",
                    "£50,000-100,000",
                    "Less than £50,000",
                  ]}
                />
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <Field
                  component={TextQuestion}
                  name={FINANCIAL_SITUATION_FORM.incomeSource.name}
                  label={FINANCIAL_SITUATION_FORM.incomeSource.label}
                  placeholder={FINANCIAL_SITUATION_FORM.incomeSource.placeholder}
                  trackedCategory={FINANCIAL_SITUATION}
                />
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <Field
                  component={RadioQuestion}
                  name={FINANCIAL_SITUATION_FORM.bearLosses.name}
                  label={FINANCIAL_SITUATION_FORM.bearLosses.label}
                  trackedCategory={FINANCIAL_SITUATION}
                />
              </FormGroup>
              {submitCount > 0 && Object.keys(errors).length !== 0 && errors.constructor === Object && (
                <FormGroup>
                  <Typography variant="body2" color="error" paragraph>
                    Please complete the following fields:
                  </Typography>
                  <ErrorsList errors={errors} constant={FINANCIAL_SITUATION_FORM} />
                </FormGroup>
              )}
              <FormGroup>
                <StageButtonGroup handleFormSubmit={handleFormSubmit} isAdmin={isAdmin} />
              </FormGroup>
            </Container>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FinancialSituation;
