import { Button, Dialog, IconButton, makeStyles, Theme, useMediaQuery } from "@material-ui/core";
import CloseIcon from "client/components/Icons/Close.icon";
import { ConfigContext } from "client/context/config.context";
import useDescription from "client/hooks/useDescription.hook";
import useTitle from "client/hooks/useTitle.hook";
import React, { FC, useContext, useEffect, useState } from "react";
import { TRACKED_EVENTS } from "../../utils/constants";
import FAQAccordion from "./components/FAQAccordion.component";
import FormPane from "./FormPane.view";
import InfoPane from "./InfoPane.view";

const { PAGE_VIEWS } = TRACKED_EVENTS;

const useStyles = makeStyles(
  (theme) => ({
    mainContent: {
      display: "flex",
      "& > div:first-of-type": {
        flex: 2,
      },
      "& > div:not(:first-of-type)": {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        overflow: "visible",
        height: "100vh",
        position: "sticky",
        top: 0,
        [theme.breakpoints.down("lg")]: {
          display: "none",
        },
      },
    },
    mobileButtonContainer: {
      alignItems: "center",
      justifyContent: "center",
      display: "none",
      position: "fixed",
      bottom: 10,
      left: 10,
      right: 10,
      [theme.breakpoints.down("lg")]: {
        display: "flex",
      },
      [theme.breakpoints.up("md")]: {
        left: "25%",
        right: "25%",
      },
    },
    mobileButton: {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.text.secondary,
    },
    dialogBar: {
      margin: "20px",
    },
  }),
  { name: "EntryPageView" },
);

interface Props {
  entryType?: "login" | "register";
}

const LoginRegister: FC<Props> = ({ entryType = "register", ...props }) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const { fund } = useContext(ConfigContext);
  const register = entryType === "register";
  const isNotLarge = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));

  useTitle(fund.meta.loginRegisterTitle || fund.meta.title);
  useDescription("Register for tax-efficient SEIS EIS investment fund and qualify to invest in our UK SEIS & EIS startups.");

  useEffect(() => {
    window.dataLayer.push({ event: register ? PAGE_VIEWS.REGISTER : PAGE_VIEWS.LOGIN });
  }, [register]);

  const classes = useStyles(props);

  return (
    <>
      {/* left/right panes */}
      <div className={classes.mainContent}>
        <div>
          <InfoPane infoPanel={fund.entryPage.infoPanel} />
        </div>
        {!isNotLarge && (
          <div>
            <FormPane register={register} />
          </div>
        )}
      </div>

      {/* accordion */}
      {fund.entryPage?.faqs?.length ? <FAQAccordion items={fund.entryPage.faqs} /> : <></>}

      {/* floating register button (mobile) */}
      <div className={classes.mobileButtonContainer}>
        <Button className={classes.mobileButton} size="large" variant="outlined" fullWidth onClick={() => setDialogOpen(true)}>
          {register ? `REGISTER` : `LOGIN`}
        </Button>
      </div>

      {/* mobile reg/login dialog form */}
      {isNotLarge && (
        <Dialog fullScreen open={dialogOpen} onClose={() => setDialogOpen(false)}>
          <div className={classes.dialogBar}>
            <IconButton edge="start" color="inherit" onClick={() => setDialogOpen(false)} aria-label="close">
              <CloseIcon />
            </IconButton>
            <br />
            <br />
            <FormPane register={register} />
          </div>
        </Dialog>
      )}
    </>
  );
};
LoginRegister.whyDidYouRender = true;

export default LoginRegister;
