import { FormGroup, Typography } from "@material-ui/core";
import { Field, useFormikContext } from "formik";
import React from "react";
import Statement from "./Statement.component";
import { CertificationForm } from "client/utils/constants";
import RadioQuestion from "../FormControls/RadioQuestion.component";
import NumberQuestion from "../FormControls/NumberQuestion.component";

type QuestionIds = (typeof CertificationForm.values)[
  | "investedInHighRiskInvestments"
  | "investedInHighRiskInvestmentsPercentage"
  | "limitHighRiskInvestment"
  | "limitHighRiskInvestmentPercentage"]["id"];

const RestrictedQualification: React.FC = () => {
  const { errors, touched, values } = useFormikContext<{
    atLeastOneTrue: boolean;
    values: {
      [key in QuestionIds]: boolean;
    };
  }>();

  return (
    <Statement title="RESTRICTED INVESTOR STATEMENT">
      Putting all your money into a single business or type of investment is risky. Spreading your money across different investments makes you less
      dependent on any one to do well.
      <br />
      <br />
      You should not invest more than 10% of your net assets in high-risk investments. Doing so could expose you to significant losses.
      <br />
      <br />
      For the purposes of this statement, net assets do NOT include: your home (primary residence), your pension (or any pension withdrawals) or any
      rights under qualifying contracts of insurance.
      <br />
      <br />
      For the purposes of this statement high-risk investments are; peer-to-peer (P2P) loans; investment based crowdfunding; and unlisted debt and
      equity (such as in companies not listed on an exchange like the London Stock Exchange).
      <br />
      <br />
      Please confirm whether vou qualify as a restricted investor on the basis that A and B apply to you.
      <br />
      <br />
      <FormGroup>
        <Field
          name={CertificationForm.values.investedInHighRiskInvestments.name}
          component={RadioQuestion}
          label={<Typography variant="body2">{CertificationForm.values.investedInHighRiskInvestments.label}</Typography>}
          options={CertificationForm.values.investedInHighRiskInvestments.options}
        />
      </FormGroup>
      {values.values.investedInHighRiskInvestments && (
        <FormGroup>
          <Field
            name={CertificationForm.values.investedInHighRiskInvestmentsPercentage.name}
            component={NumberQuestion}
            {...CertificationForm.values.investedInHighRiskInvestmentsPercentage.props}
            label={<Typography variant="body2">{CertificationForm.values.investedInHighRiskInvestmentsPercentage.label}</Typography>}
          />
        </FormGroup>
      )}
      <FormGroup>
        <Field
          name={CertificationForm.values.limitHighRiskInvestment.name}
          component={RadioQuestion}
          label={<Typography variant="body2">{CertificationForm.values.limitHighRiskInvestment.label}</Typography>}
          options={CertificationForm.values.limitHighRiskInvestment.options}
        />
      </FormGroup>
      {values.values.limitHighRiskInvestment && (
        <FormGroup>
          <Field
            name={CertificationForm.values.limitHighRiskInvestmentPercentage.name}
            component={NumberQuestion}
            {...CertificationForm.values.limitHighRiskInvestmentPercentage.props}
            label={<Typography variant="body2">{CertificationForm.values.limitHighRiskInvestmentPercentage.label}</Typography>}
          />
        </FormGroup>
      )}
      <FormGroup>
        {(touched.values?.[CertificationForm.values.investedInHighRiskInvestments.id] ||
          touched.values?.[CertificationForm.values.limitHighRiskInvestment.id]) &&
          errors.atLeastOneTrue && (
            <Typography variant="body2" color="error">
              {errors.atLeastOneTrue}
            </Typography>
          )}
      </FormGroup>
      <br />I accept that being a restricted investor will expose me to promotions for investment where there is a risk of losing all the money I
      invest. I am aware that it is open to me seek professional advice before making any investment in a high-risk investment.
    </Statement>
  );
};

export default RestrictedQualification;
