import { makeStyles, Typography } from "@material-ui/core";
import { ROUTES } from "client/utils/constants";
import React, { useContext, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../context/auth.context";
import ResendConfirmationButton from "./CTAs/ResendConfirmationButton.component";
import useIsAdmin from "client/hooks/useIsAdmin";

const useStyles = makeStyles((theme) => ({
  confirmEmailBanner: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2.5, "20%"),
    backgroundColor: theme.palette.stone.light,
  },
  paddingTop: {
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(7)
    },
  }
}));

const ConfirmEmailBanner: React.FC = (props) => {
  const classes = useStyles(props);
  const location = useLocation();
  const {
    state: { user },
  } = useContext(AuthContext);
  const isAdmin = useIsAdmin(user);

  const isHidden = useMemo(
    () => location.pathname.toLowerCase() === ROUTES.CONFIRM_EMAIL.toLowerCase() || !user || user.isConfirmed,
    [location.pathname, user],
  );

  return isHidden ? null : (
    <div className={`${classes.confirmEmailBanner} ${!isAdmin && classes.paddingTop}`}>
      <Typography variant="body2" align="center" paragraph>
        Before you start, please confirm your email address. Please check your inbox for an email containing a verification link.
      </Typography>
      <ResendConfirmationButton size="small">Resend Confirmation Email</ResendConfirmationButton>
    </div>
  );
};

export default ConfirmEmailBanner;
