import { Container, createStyles, makeStyles } from "@material-ui/core";
import React, { FC, useCallback, useContext, useMemo } from "react";
import { Redirect as RrdRedirect, Route as RrdRoute, Switch } from "react-router-dom";
import ConfirmEmailBanner from "./components/ConfirmEmailBanner.component";
import Footer from "./components/Footer.component";
import InvestorNavBar from "./components/navbar/InvestorNavbar.component";
import PublicNavbar from "./components/navbar/PublicNavbar.component";
import RiskBanner from "./components/RiskBanner.component";
import RiskFooter from "./components/RiskWarning.component";
import ScrollToTop from "./components/utils/ScrollToTop";
import { AuthContext } from "./context/auth.context";
import { ConfigContext } from "./context/config.context";
import { ROUTES } from "./utils/constants";
import AdminPanel from "./views/AdminPanel";
import AdminViewTaxCertificate from "./views/AdminViewTaxCertificate.view";
import AdvisorAddClient from "./views/Advisor/AddClient.view";
import AdvisorDashboard from "./views/Advisor/AdvisorDashboard.view";
import AdvisorOnboarding from "./views/Advisor/AdvisorOnboarding.view";
import AdvisorEditClient from "./views/Advisor/EditClient.view";
import APIDocumentation from "./views/APIDocumentation.view";
import AdminPreview from "./views/ApplicationForm/AdminPreview";
import ApplicationComplete from "./views/ApplicationForm/ApplicationComplete.view";
import ApplicationForm from "./views/ApplicationForm/ApplicationForm.view";
import ApplicationStart from "./views/ApplicationForm/ApplicationStart.view";
import SignAgreement from "./views/ApplicationForm/SignAgreement.view";
import ConfirmEmail from "./views/ConfirmEmail.view";
import InformationMemorandum from "./views/InformationMemorandum/InformationMemorandum.view";
import AgreeFee from "./views/Investor/AgreeFee.view";
import InvestorDashboard from "./views/Investor/Dashboard";
import QualificationFlow from "./views/Investor/Qualification.view";
import Qualified from "./views/Investor/QualificationComplete.view";
import LinkRegistration from "./views/LinkRegistration.view";
import LoginRegister from "./views/LoginRegister";
import PageNotFound from "./views/PageNotFound.view";
import PublicReturnsCalculator from "./views/PublicReturnsCalculator.view";
import RiskWarning from "./views/RiskWarning.view";
// eslint-disable-next-line import/namespace
import useIsAdmin from "./hooks/useIsAdmin";

interface Props { }

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      outerContainer: {
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        padding: 0,
      },
      innerContainer: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        padding: 0,
      },
      pageTopPadding: {
        paddingTop: theme.spacing(15),
        [theme.breakpoints.down("md")]: {
          paddingTop: theme.spacing(11),
        },
        [theme.breakpoints.down("sm")]: {
          paddingTop: theme.spacing(14),
        },
      },
    }),
  { name: "Router" },
);

const Router: FC<Props> = (props) => {
  const classes = useStyles(props);
  const { fund, imDefaultPath } = useContext(ConfigContext);
  const {
    state: { user },
  } = useContext(AuthContext);
  const isAuthed = useMemo(() => Boolean(user), [user]);
  const isQualified = useMemo(() => Boolean(isAuthed && user!.isQualified), [isAuthed, user]);
  const isAdmin = useIsAdmin(user);
  const isAdvisor = useMemo(() => Boolean(isAuthed && user!.isAdvisor), [isAuthed, user]);
  const isReadOnly = useMemo(() => Boolean(isAuthed) && user!.isReadOnly, [isAuthed, user]);
  const isAdvised = useMemo(() => Boolean(isAuthed && user!.advisor && user!.advisor.adviceType === "ADVISED"), [isAuthed, user]);

  const marketingRedirect = useCallback(() => {
    if (typeof window !== "undefined") window.location.href = fund.links.alternateSite;
    return null;
  }, [fund.links.alternateSite]);

  // * Custom redirect that redirects to specified path (or /login if not specified) and redirects them to referrer location on login
  // * Redirects with the referrer is for stuff like email links when the user is not logged in
  const Redirect = useCallback(({ to = {} }) => {
    if (typeof document === "undefined") return null;
    const toObj = typeof to === "string" ? { pathname: to } : to;
    return (
      <RrdRedirect
        to={{
          pathname: "/login",
          ...toObj,
          state: { referrer: { path: document.location.pathname, search: document.location.search }, ...toObj.state },
        }}
      />
    );
  }, []);

  // * This Route component now works out the Navbar it needs on it's own by specifying routes for the different navbars
  // * and checks if the user is logged in (isAuthed) or is a public route and then redirects them to the landing page if not,
  // * or displays the provided component(s) if they are.
  const Route = useCallback(
    ({ publicRoute, children, ...routeProps }) => {
      const getNavbar = () => {
        switch (routeProps.path) {
          case "/":
          case ROUTES.OLD_LANDING_PAGE:
          case ROUTES.ADVISOR.OLD_LANDING_PAGE:
          case ROUTES.LANDING_PAGE:
          case ROUTES.ADVISOR.LANDING_PAGE:
          case ROUTES.RISK_WARNING:
          case ROUTES.PUBLIC_RETURNS_CALCULATOR:
          case ROUTES.LINK_REGISTRATION:
          case ROUTES.LINK_FORGOT_PASSWORD:
          case ROUTES.CONFIRM_EMAIL:
            return <PublicNavbar />;

          case ROUTES.LOGIN:
          case ROUTES.REGISTER:
            return null;
          default:
            return <InvestorNavBar />;
        }
      };

      const NavBar = getNavbar();

      return (
        <ScrollToTop>
          {!isAdmin && <RiskBanner />}
          {NavBar}
          <Container
            maxWidth={false}
            className={`${classes.outerContainer} ${(NavBar?.type === InvestorNavBar || !NavBar) && !isAdmin ? classes.pageTopPadding : ""}`}
          >
            <Container maxWidth={false} className={classes.innerContainer}>
              <ConfirmEmailBanner />
              {publicRoute || isAuthed ? <RrdRoute {...routeProps}>{children}</RrdRoute> : <Redirect />}
            </Container>
            <footer>
              <RiskFooter />
              <Footer />
            </footer>
          </Container>
        </ScrollToTop>
      );
    },
    [isAdmin, classes.outerContainer, classes.pageTopPadding, classes.innerContainer, isAuthed, Redirect],
  );

  const AdvisorRoute = useCallback(
    ({ children, readOnly = isReadOnly, ...advisorRouteProps }) => (
      <Route {...advisorRouteProps}>{isAdvisor && !readOnly ? children : <RrdRedirect to="/dashboard/overview" />}</Route>
    ),
    [Route, isAdvisor, isReadOnly],
  );

  const AdminRoute = useCallback(
    ({ children, ...adminRouteProps }) => <Route {...adminRouteProps}>{isAdmin ? children : <Redirect to={ROUTES.DASHBOARD_OVERVIEW} />}</Route>,
    [Redirect, Route, isAdmin],
  );

  const ApplicationRoute = useCallback(
    ({ children, ...applicationRouteProps }) => {
      const advised = typeof applicationRouteProps.isAdvised === "boolean" ? applicationRouteProps.isAdvised : isAdvised;
      return (
        <Route {...applicationRouteProps}>
          {isQualified && !advised ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: ROUTES.DASHBOARD_OVERVIEW,
              }}
            />
          )}
        </Route>
      );
    },
    [Redirect, Route, isAdvised, isQualified],
  );

  return (
    <Switch>
      {/* public routes */}
      <Route path="/login" publicRoute>
        {isAuthed ? <Redirect to={ROUTES.DASHBOARD_OVERVIEW} /> : <LoginRegister entryType="login" />}
      </Route>
      <Route path="/register" publicRoute>
        {isAuthed ? <Redirect to={ROUTES.DASHBOARD_OVERVIEW} /> : <LoginRegister entryType="register" />}
      </Route>
      <Route path={ROUTES.LINK_REGISTRATION} publicRoute>
        <LinkRegistration forgottenPassword={false} />
      </Route>
      <Route path={ROUTES.LINK_FORGOT_PASSWORD} publicRoute>
        <LinkRegistration forgottenPassword />
      </Route>
      <Route path={ROUTES.CONFIRM_EMAIL} publicRoute>
        <ConfirmEmail />
      </Route>
      <Route path={ROUTES.RISK_WARNING} publicRoute>
        <RiskWarning />
      </Route>
      <Route path={ROUTES.PUBLIC_RETURNS_CALCULATOR} publicRoute>
        <PublicReturnsCalculator />
      </Route>

      {/* auth routes */}
      <Route path="/information-memorandum/:tabName">
        <InformationMemorandum />
      </Route>
      <Route path={ROUTES.ADVISOR.FEE_PAGE}>
        <AgreeFee />
      </Route>
      <Route path={ROUTES.QUALIFY}>
        {isAuthed ? !isQualified ? <QualificationFlow /> : <Redirect to={{ pathname: ROUTES.DASHBOARD_OVERVIEW }} /> : <Redirect />}
      </Route>
      <Route path={ROUTES.QUALIFIED}>
        {isAuthed ? isQualified ? <Qualified /> : <Redirect to={{ pathname: ROUTES.QUALIFIED }} /> : <Redirect />}
      </Route>
      <Route path={`/applications/:applicationId/apply/:pageName`}>
        <ApplicationForm />
      </Route>

      <Route exact path="/dashboard/overview">
        <Redirect to="/dashboard/portfolio" />
      </Route>
      <Route path="/dashboard/:tabName">{isAdvisor ? <Redirect to={{ pathname: ROUTES.ADVISOR.DASHBOARD }} /> : <InvestorDashboard />}</Route>

      <AdminRoute exact path="/docs">
        <APIDocumentation />
      </AdminRoute>
      <AdminRoute path={`${ROUTES.ADMIN_PANEL}/:tabValue`}>
        <AdminPanel />
      </AdminRoute>
      <AdminRoute exact path={`/admin/application/:applicationId/preview`}>
        <AdminPreview />
      </AdminRoute>
      <AdminRoute path={`/admin/application/:applicationId/apply/:pageName`}>
        <ApplicationForm />
      </AdminRoute>
      <AdminRoute path={`/admin/shareTransactions/:shareTransactionId/tax-certificates/:subscriptionId/view`}>
        <AdminViewTaxCertificate />
      </AdminRoute>

      <ApplicationRoute isConfirmed exact path={ROUTES.APPLICATION_BEGIN}>
        <ApplicationStart />
      </ApplicationRoute>
      <ApplicationRoute isAdvised={false} exact path={ROUTES.APPLICATION_AGREEMENT}>
        <SignAgreement />
      </ApplicationRoute>
      <ApplicationRoute exact path={ROUTES.APPLICATION_COMPLETE}>
        <ApplicationComplete />
      </ApplicationRoute>
      <ApplicationRoute path="/apply/:pageName">
        <ApplicationForm />
      </ApplicationRoute>

      {/* advisor routes */}
      <Route exact path="/advisor/client/:clientId/dashboard/overview">
        <Redirect to="/advisor/client/:clientId/dashboard/portfolio" />
      </Route>
      <Route path={`/advisor/client/:clientId/dashboard/:tabName`}>
        <InvestorDashboard />
      </Route>
      <Route path={`/advisor/client/:clientID/applications/:applicationId/apply/:pageName`}>
        <ApplicationForm />
      </Route>

      <AdvisorRoute path={ROUTES.ADVISOR.ONBOARDING} readOnly={false}>
        <AdvisorOnboarding />
      </AdvisorRoute>
      <AdvisorRoute path={ROUTES.ADVISOR.DASHBOARD} readOnly={false}>
        <AdvisorDashboard />
      </AdvisorRoute>
      <AdvisorRoute path={ROUTES.ADVISOR.ADD_CLIENT}>
        <AdvisorAddClient />
      </AdvisorRoute>
      <AdvisorRoute path={`/advisor/client/:clientID/edit`} exact>
        <AdvisorEditClient />
      </AdvisorRoute>
      <AdvisorRoute path={`/advisor/client/:clientID/apply`} exact>
        <ApplicationStart />
      </AdvisorRoute>
      <AdvisorRoute exact path={`/advisor/client/:clientID${ROUTES.APPLICATION_AGREEMENT}`}>
        <SignAgreement />
      </AdvisorRoute>
      <AdvisorRoute exact path={`/advisor/client/:clientID${ROUTES.APPLICATION_COMPLETE}`}>
        <ApplicationComplete />
      </AdvisorRoute>
      <AdvisorRoute exact path={`/advisor/client/:clientID${ROUTES.APPLICATION_ID_VERIFICATION}`}>
        <Redirect to={ROUTES.ADVISOR.DASHBOARD} />
      </AdvisorRoute>
      <AdvisorRoute path={`/advisor/client/:clientID/apply/:pageName`}>
        <ApplicationForm />
      </AdvisorRoute>

      {/* Redirects */}
      <Route exact path="/" publicRoute>
        <Redirect to={ROUTES.LOGIN} />
      </Route>
      <Route exact path={ROUTES.WELCOME}>
        <Redirect to={imDefaultPath} />
      </Route>
      <Route exact path="/information-memorandum">
        <Redirect to={imDefaultPath} />
      </Route>
      <Route exact path="/dashboard">
        <Redirect to="/dashboard/portfolio" />
      </Route>
      <Route exact path={ROUTES.ADMIN_PANEL}>
        <Redirect to={`${ROUTES.ADMIN_PANEL}/users`} />
      </Route>
      <RrdRoute exact path={ROUTES.OLD_LANDING_PAGE} render={marketingRedirect} />
      <RrdRoute exact path={ROUTES.ADVISOR.OLD_LANDING_PAGE} render={marketingRedirect} />
      <RrdRoute path={ROUTES.LANDING_PAGE} render={marketingRedirect} />
      <RrdRoute path={ROUTES.ADVISOR.LANDING_PAGE} render={marketingRedirect} />
      <Route path="/" publicRoute>
        <PageNotFound />
      </Route>
    </Switch>
  );
};

export default Router;
