import Markdown from "client/components/Markdown.component";
import ReturnsCalculator from "client/components/ReturnsCalculator.component";
import { AuthContext } from "client/context/auth.context";
import { ConfigContext } from "client/context/config.context";
import _ from "lodash";
import React, { Fragment, useContext } from "react";
import { ContentsEntry, SectionType } from "server/services/fund/fund.types";
import MainSection from "./components/MainSection.component";
import Documents from "./Documents/Documents.view";
import HowToInvest from "./HowToInvest/HowToInvest.view";
import Reviews from "./Reviews/Reviews.view";
import Welcome from "./Welcome/Welcome.view";

interface Props {
  config: ContentsEntry;
}

const renderSection = <Plain extends boolean | undefined>({
  section,
  prefix,
  plain,
}: {
  section: string | SectionType;
  prefix?: string;
  plain?: Plain;
}) => (plain ? <Markdown>{section as string}</Markdown> : <MainSection prefix={prefix as string} section={section as SectionType} />);

const IMPage: React.FC<Props> = ({ config }) => {
  const { fund } = useContext(ConfigContext);
  const {
    state: { user },
  } = useContext(AuthContext);

  if (config.welcome) return <Welcome isAdvisor={user?.isAdvisor} />;
  if (config.reviews) return <Reviews />;
  if (config.documents) return <Documents />;
  if (config.howToInvest && user?.applicationStatus !== "Funds Deployed") return <HowToInvest />;
  if (config.returnsCalculator && fund.returnsCalculator.enabled) return <ReturnsCalculator />;
  if (!config.content) return null;

  return Array.isArray(config.content) ? (
    <>
      {config.content.map((section) => (
        <Fragment key={section.path}>
          {renderSection({ ...section, section: _.get(fund.informationMemorandum, section.path) })}
          <br />
          <br />
        </Fragment>
      ))}
    </>
  ) : (
    renderSection({ ...config.content, section: _.get(fund.informationMemorandum, config.content.path) })
  );
};

export default IMPage;
