import { Button, Collapse, Container, FormGroup, makeStyles, Theme, Typography, useMediaQuery } from "@material-ui/core";
import { ConfigContext } from "client/context/config.context";
import useLocationReferrer from "client/hooks/useLocationReferrer";
import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { animateScroll } from "react-scroll";
import QualificationApi from "../../api/qualification.api";
import QualificationCard from "../../components/QualificationCard.component";
import HighNetWorthQualification from "../../components/Qualifications/HighNetWorthQualification.component";
import RestrictedQualification from "../../components/Qualifications/RestrictedQualification.component";
import SophisticatedQualification from "client/components/Qualifications/SophisticatedQualification.component";
import { AuthContext, AUTH_TYPES } from "../../context/auth.context";
import useAnalytics from "../../hooks/useAnalytics";
import { CertificationForm, CertificationType, TRACKED_EVENTS } from "../../utils/constants";
import InitialRiskWarning from "client/components/InitialRiskWarning.component";
import * as Yup from "yup";
import { RequiredStringSchema } from "yup/lib/string";
import { Qualification as QualificationType } from "server/services/user/user.types";
import { Formik, Form, Field } from "formik";
import CheckboxQuestion from "client/components/FormControls/CheckboxQuestion.component";
import ErrorsList from "client/components/ErrorsList.component";

let XArrow: typeof import("react-xarrows").default;
if (typeof window !== "undefined") {
  import("react-xarrows").then((module) => (XArrow = module.default));
}

interface Props {}

const { PAGE_VIEWS } = TRACKED_EVENTS;

const OPTIONS = [
  // {
  //   value: 'professional',
  //   title: 'Professional',
  //   description: 'Our highest category enabling you to see every deal.',
  // },
  {
    value: CertificationType.SelfSophisticated,
    title: "Sophisticated",
    description: "Likely to be you if you have current or recent investment experience.",
  },
  {
    value: CertificationType.HighNetWorth,
    title: "High Net Worth",
    description: "Could be you if your income or net assets exceed certain minimum thresholds.",
  },
  {
    value: CertificationType.Restricted,
    title: "Restricted",
    description: "If you don’t fit the other types and are happy to limit your investments.",
  },
];

const useStyles = makeStyles(
  (theme) => ({
    arrow: {
      stroke: theme.palette.primary.main,
    },
    investorTypes: {
      display: "flex",
      padding: theme.spacing(0, 2),
      "& > div:not(:last-child)": {
        marginRight: theme.spacing(3),
      },
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        "& > div:not(:last-child)": {
          marginRight: 0,
          marginBottom: theme.spacing(3),
        },
      },
    },
  }),
  { name: "qualificationView" },
);

const ValuesSchema = Yup.object({
  [CertificationForm.values.signingConfirmation.id]: Yup.boolean()
    .oneOf([true], "Please confirm that the above statement is true")
    .required("Please confirm the statement above"),
})
  .default({
    [CertificationForm.values.signingConfirmation.id]: false,
    [CertificationForm.values.notApplicable.id]: false,
    [CertificationForm.values.hasLargeIncome.id]: null,
    [CertificationForm.values.netIncome.id]: null,
    [CertificationForm.values.hasLargeNetAssets.id]: null,
    [CertificationForm.values.netAssets.id]: null,
    [CertificationForm.values.financeProviderForSMEsOrPEWorker.id]: null,
    [CertificationForm.values.financeProviderForSMEsOrPEWorkerDetail.id]: "",
    [CertificationForm.values.directorOfCompanyWithAnnualTurnoverOver1M.id]: null,
    [CertificationForm.values.directorOfCompanyWithAnnualTurnoverOver1MDetail.id]: "",
    [CertificationForm.values.multipleInvestmentsUnlistedCompany.id]: null,
    [CertificationForm.values.multipleInvestmentsUnlistedCompanyDetail.id]: null,
    [CertificationForm.values.businessAngelNetworkMemberOver6Months.id]: null,
    [CertificationForm.values.businessAngelNetworkMemberOver6MonthsDetail.id]: "",
    [CertificationForm.values.investedInHighRiskInvestments.id]: null,
    [CertificationForm.values.investedInHighRiskInvestmentsPercentage.id]: null,
    [CertificationForm.values.limitHighRiskInvestment.id]: null,
    [CertificationForm.values.limitHighRiskInvestmentPercentage.id]: null,
  })
  .when("qualification", {
    is: CertificationType.HighNetWorth,
    then: (schema) =>
      schema.concat(
        Yup.object({
          [CertificationForm.values.hasLargeIncome.id]: Yup.boolean().nullable(),
          [CertificationForm.values.netIncome.id]: Yup.number()
            .nullable()
            .when(CertificationForm.values.hasLargeIncome.id, {
              is: true,
              then: Yup.number().nullable().required("Please enter your net income"),
            }),
          [CertificationForm.values.hasLargeNetAssets.id]: Yup.boolean().nullable(),
          [CertificationForm.values.netAssets.id]: Yup.number()
            .nullable()
            .when(CertificationForm.values.hasLargeNetAssets.id, {
              is: true,
              then: Yup.number().nullable().required("Please enter your net assets"),
            }),
          [CertificationForm.values.notApplicable.id]: Yup.boolean(),
        })
          .test("atLeastOneTrue", "Please select Yes for at least one of the questions above", function (values) {
            return (
              values?.[CertificationForm.values.hasLargeIncome.id] ||
              values?.[CertificationForm.values.hasLargeNetAssets.id] ||
              values?.[CertificationForm.values.notApplicable.id] ||
              this.createError({
                path: "atLeastOneTrue",
                message: "Please select Yes for at least one of the cases above",
              })
            );
          })
          .required(),
      ),
  })
  .when("qualification", {
    is: CertificationType.SelfSophisticated,
    then: (schema) =>
      schema
        .concat(
          Yup.object({
            [CertificationForm.values.financeProviderForSMEsOrPEWorker.id]: Yup.boolean()
              .nullable()
              .when(CertificationForm.values.notApplicable.id, {
                is: false,
                then: Yup.boolean().nullable().required("Please select either Yes or No"),
              }),
            [CertificationForm.values.financeProviderForSMEsOrPEWorkerDetail.id]: Yup.string()
              .nullable()
              .when(CertificationForm.values.financeProviderForSMEsOrPEWorker.id, {
                is: true,
                then: Yup.string().nullable().required("Please enter name of the business or organisation"),
              }),
            [CertificationForm.values.directorOfCompanyWithAnnualTurnoverOver1M.id]: Yup.boolean()
              .nullable()
              .when(CertificationForm.values.notApplicable.id, {
                is: false,
                then: Yup.boolean().nullable().required("Please select either Yes or No"),
              }),
            [CertificationForm.values.directorOfCompanyWithAnnualTurnoverOver1MDetail.id]: Yup.string()
              .nullable()
              .when(CertificationForm.values.directorOfCompanyWithAnnualTurnoverOver1M.id, {
                is: true,
                then: Yup.string().nullable().required("Please enter the name of the company"),
              }),
            [CertificationForm.values.multipleInvestmentsUnlistedCompany.id]: Yup.boolean()
              .nullable()
              .when(CertificationForm.values.notApplicable.id, {
                is: false,
                then: Yup.boolean().nullable().required("Please select either Yes or No"),
              }),
            [CertificationForm.values.multipleInvestmentsUnlistedCompanyDetail.id]: Yup.string()
              .nullable()
              .when(CertificationForm.values.multipleInvestmentsUnlistedCompany.id, {
                is: true,
                then: Yup.string().nullable().required("Please enter investments in unlisted companies you have made in the last two years"),
              }),

            [CertificationForm.values.businessAngelNetworkMemberOver6Months.id]: Yup.boolean()
              .nullable()
              .when(CertificationForm.values.notApplicable.id, {
                is: false,
                then: Yup.boolean().nullable().required("Please select either Yes or No"),
              }),
            [CertificationForm.values.businessAngelNetworkMemberOver6MonthsDetail.id]: Yup.string()
              .nullable()
              .when(CertificationForm.values.businessAngelNetworkMemberOver6Months.id, {
                is: true,
                then: Yup.string().nullable().required("Please enter the name of the network or syndicate?"),
              }),
            [CertificationForm.values.notApplicable.id]: Yup.boolean(),
          }),
        )
        .required(),
  })
  .when("qualification", {
    is: CertificationType.Restricted,
    then: (schema) =>
      schema.concat(
        Yup.object({
          [CertificationForm.values.investedInHighRiskInvestments.id]: Yup.boolean().nullable().required("Please select either Yes or No"),
          [CertificationForm.values.investedInHighRiskInvestmentsPercentage.id]: Yup.number()
            .nullable()
            .min(0, "Must be at least ${min}%")
            .max(100, "Must be at most ${max}%")
            .when(CertificationForm.values.investedInHighRiskInvestments.id, {
              is: true,
              then: Yup.number().nullable().required("Field is required"),
            }),
          [CertificationForm.values.limitHighRiskInvestment.id]: Yup.boolean().nullable().required("Please select either Yes or No"),
          [CertificationForm.values.limitHighRiskInvestmentPercentage.id]: Yup.number()
            .nullable()
            .min(0, "Must be at least ${min}%")
            .max(100, "Must be at most ${max}%")
            .when(CertificationForm.values.limitHighRiskInvestment.id, {
              is: true,
              then: Yup.number().nullable().required("Field is required"),
            }),
        })
          .test("atLeastOneTrue", "Please select Yes for at least one of the questions above", function (values) {
            return (
              values?.[CertificationForm.values.investedInHighRiskInvestments.id] ||
              values?.[CertificationForm.values.limitHighRiskInvestment.id] ||
              this.createError({
                path: "atLeastOneTrue",
                message: "Please select Yes for at least one of the cases above",
              })
            );
          })
          .required(),
      ),
  });

export const QualificationFormSchema = Yup.object({
  qualification: Yup.string()
    .default("")
    .oneOf(OPTIONS.map((option) => option.value))
    .required("Please select a certification type") as RequiredStringSchema<CertificationType>,
  values: ValuesSchema,
}).required();

export type QualificationFormValues = Pick<QualificationType, "values" | "qualification">;

const InitialValues = QualificationFormSchema.getDefault() as QualificationFormValues;

const Qualification: FC<Props> = (props) => {
  const classes = useStyles(props);
  const history = useHistory();
  const referrer = useLocationReferrer();
  const ArrowEndRef = useRef<HTMLDivElement>(null);
  const { imDefaultPath } = useContext(ConfigContext);

  const {
    state: { user },
    dispatch,
  } = useContext(AuthContext);
  const [selectedCard, setSelectedCard] = useState("");

  useAnalytics();

  const isTabletMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  useEffect(() => {
    if (selectedCard && isTabletMobile) {
      animateScroll.scrollTo(ArrowEndRef.current!.offsetTop);
    }
  }, [isTabletMobile, selectedCard]);

  const isUnqualifiedAdvisor = useMemo(() => referrer.path.includes("/dashboard"), [referrer]);

  useEffect(() => {
    if (user && user.isQualified) {
      history.push(imDefaultPath);
    }
  }, [user, history, imDefaultPath]);

  useEffect(() => {
    globalThis.dataLayer.push({ event: PAGE_VIEWS.INVESTOR_APPLICATION_QUALIFY_TO_INVEST });
  }, []);

  const handleSimple = useCallback(
    async (values) => {
      const { success, data } = await QualificationApi.certifyInvestor(selectedCard, values.values);
      if (success) {
        dispatch({
          type: AUTH_TYPES.SET_USER,
          payload: { ...user, ...data },
        });
        history.push(isUnqualifiedAdvisor ? referrer.location! : imDefaultPath);
      }
    },
    [dispatch, history, imDefaultPath, isUnqualifiedAdvisor, referrer.location, selectedCard, user],
  );

  return (
    <Formik initialValues={InitialValues} validationSchema={QualificationFormSchema} onSubmit={handleSimple}>
      {({ values, setFieldValue, submitCount, errors, resetForm }) => (
        <Form>
          <Container disableGutters>
            <Container maxWidth="lg">
              <Typography variant="h2" align="center" gutterBottom>
                What type of investor are you?
              </Typography>
              {isUnqualifiedAdvisor && (
                <Typography variant="body1" align="center" paragraph>
                  Before viewing your investor dashboard, you must specify the type of investor you are classed as.
                </Typography>
              )}
              <Typography variant="body1" align="center">
                Please take your time to consider this carefully.
              </Typography>
            </Container>
            <div className={classes.investorTypes}>
              {OPTIONS.map(({ value, title, description }) => (
                <QualificationCard
                  key={value}
                  id={value}
                  data-testid={value}
                  title={title}
                  onClick={() => {
                    setSelectedCard(value);
                    resetForm();
                    setFieldValue("qualification", value);
                  }}
                  selected={selectedCard === value}
                >
                  {description}
                </QualificationCard>
              ))}
            </div>
            {XArrow && (
              <XArrow
                start={selectedCard}
                startAnchor={["middle", "bottom"]}
                end={ArrowEndRef}
                endAnchor={["middle", "top"]}
                path="grid"
                strokeWidth={2}
                headSize={2}
                showXarrow={Boolean(!isTabletMobile && selectedCard)}
                arrowBodyProps={{ className: classes.arrow }}
                arrowHeadProps={{ className: classes.arrow }}
              />
            )}
            <Collapse in={Boolean(selectedCard)} timeout="auto">
              <Container maxWidth="md" style={{ display: !values.qualification ? "none" : "", paddingBottom: 0 }}>
                <div ref={ArrowEndRef}>
                  {/* {selectedCard === 'professional' && <ProfessionalQualification onSubmit={handleProfessional} />} */}
                  {selectedCard === CertificationType.SelfSophisticated && <SophisticatedQualification onSubmit={handleSimple} />}
                  {selectedCard === CertificationType.HighNetWorth && <HighNetWorthQualification onSubmit={handleSimple} />}
                  {selectedCard === CertificationType.Restricted && <RestrictedQualification onSubmit={handleSimple} />}
                </div>
              </Container>
            </Collapse>
            <Container maxWidth="md" style={{ display: !values.qualification ? "none" : "", paddingTop: 0 }}>
              <Typography variant="h4" style={{ textDecoration: "underline" }}>
                Electronic Signature Notice
              </Typography>
              <Typography variant="body2">
                By submitting the button below labelled &quot;Click to Sign&quot; you are confirming your assent that this represents an electronic
                signature confirming your above High Net Worth Investor Statement. You acknowledge and agree that you will not contest the validity or
                enforceability of this electronically signed statement on the basis that it lacks an original handwritten signature. Your submission
                of the &quot;Click to Sign&quot; button shall be considered a valid signature as of the date of your above statement.
                Computer-maintained records, when produced in hard copy form, shall constitute business records and shall have the same validity as
                any other generally recognised business records.
              </Typography>

              <FormGroup>
                <Typography variant="h5">Click to Sign</Typography>
                <Field
                  name={CertificationForm.values.signingConfirmation.name}
                  component={CheckboxQuestion}
                  label={CertificationForm.values.signingConfirmation.label}
                />
              </FormGroup>

              {submitCount > 0 && Object.keys(errors).length !== 0 && errors.constructor === Object && (
                <FormGroup>
                  <br />
                  <Typography variant="body2" color="error" paragraph>
                    Please complete the following fields:
                  </Typography>
                  <ErrorsList errors={errors} constant={CertificationForm} />
                </FormGroup>
              )}

              <FormGroup row>
                <Button size="large" type="submit">
                  Continue
                </Button>
              </FormGroup>
            </Container>
            <InitialRiskWarning />
          </Container>
        </Form>
      )}
    </Formik>
  );
};

export default Qualification;
