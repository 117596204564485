import { Button } from "@material-ui/core";
import { ColumnDefinition, OnChangeObject } from "@wearenova/mui-data-table";
import { downloadTaxCertificate, getAllHoldings, getSheetsExportLink } from "client/api/admin/holdings.adminApi";
import DropDownButton from "client/components/DropDownButton.component";
import _ from "lodash";
import moment from "moment";
import { FC, useCallback, useContext, useMemo, useState } from "react";
import { AdminHolding } from "server/services/shareTransaction/shareTransaction.types";
import { downloadContractNote, regenerateTaxCertificate } from "../../../api/admin/shareTransactions.adminApi";
import { AdminContext, ADMIN_TYPES } from "../../../context/admin.context";
import AdminTable from "../AdminTable.view";
import { useStyles } from "../useStyles.hook";
import DisplayLink from "../ShareTransactions/DisplayLink.view";

const SubscriptionsTab: FC = ({ ...props }) => {
  const classes = useStyles(props);
  const { holdings, dispatch } = useContext(AdminContext);
  const [count, setCount] = useState(-1);
  const [selectedRows, setSelectedRows] = useState<AdminHolding[]>([]);
  const [sheetsExportLink, setSheetsExportLink] = useState<string | null>(null);

  const subscriptionsTable = useMemo<ColumnDefinition<AdminHolding>[]>(
    () => [
      {
        key: "project",
        title: "Project",
        dataIndex: "company.projectName",
        sorter: true,
        filterColumn: true,
        pinnable: true,
      },
      {
        key: "companyName",
        title: "Company Name",
        dataIndex: "company.companyName",
        sorter: true,
        filterColumn: true,
        pinnable: true,
      },
      {
        key: "companyNumber",
        title: "Company Number",
        dataIndex: "company.companyNumber",
        sorter: true,
        filterColumn: true,
        pinnable: true,
      },
      {
        key: "deal",
        title: "Deal Name",
        render: (record) => {
          const deal = record.company.deals?.find((x) => x._id === record.deal);
          return record.deal && deal ? `${moment(deal.startDate).format("L")} - ${deal.investmentStage}` : null;
        },
        sorter: true,
        filterColumn: true,
      },
      {
        key: "portfolio",
        title: "Portfolio",
        dataIndex: "portfolio.name",
        sorter: true,
        filterColumn: true,
        pinnable: true,
      },
      {
        key: "investor",
        title: "Investor",
        dataIndex: "topLevelInvestor",
        sorter: true,
        filterColumn: true,
        pinnable: true,
      },
      {
        key: "settleDate",
        title: "Settle Date",
        dataIndex: "settleDateFormatted",
        sorter: "settleDate",
        filterColumn: { path: "settleDate", type: "date" },
        pinnable: true,
      },
      {
        key: "tradeDate",
        title: "Trade Date",
        dataIndex: "tradeDateFormatted",
        sorter: "tradeDate",
        filterColumn: { path: "tradeDate", type: "date" },
        pinnable: true,
      },
      {
        key: "shareClass",
        title: "Share Class",
        dataIndex: "shareClass",
        sorter: true,
        filterColumn: true,
        pinnable: true,
      },
      {
        key: "sector",
        title: "Sector",
        dataIndex: "company.sector",
        sorter: true,
        filterColumn: { path: true, type: "date" },
        pinnable: true,
      },
      {
        key: "dealStage",
        title: "Deal Stage",
        dataIndex: "dealStage",
        sorter: true,
        filterColumn: true,
        pinnable: true,
      },
      {
        key: "pricePerShare",
        title: "Purchase Price",
        numerical: { path: "subscription.pricePerShare", currency: true, minDecimalPlaces: 2, maxDecimalPlaces: 10 },
        sorter: "pricePerShare",
        filterColumn: { path: "pricePerShare", type: "number" },
        pinnable: true,
      },
      {
        key: "currentPricePerShare",
        title: "Current Price",
        numerical: { path: `currentPricePerShare`, currency: true, minDecimalPlaces: 2, maxDecimalPlaces: 10 },
        sorter: "pricePerShare",
        filterColumn: { path: "currentPricePerShare", type: "number" },
        pinnable: true,
      },
      {
        key: "noOfShares",
        title: "Number of Shares",
        dataIndex: "subscription.noOfShares",
        sorter: true,
        filterColumn: { path: true, type: "number" },
        pinnable: true,
      },
      {
        key: "cost",
        title: "Cost",
        numerical: "value",
        sorter: true,
        filterColumn: { path: "value", type: "number" },
        pinnable: true,
      },
      {
        key: "value",
        title: "Value",
        numerical: "currentValue",
        sorter: true,
        filterColumn: { path: "currentValue", type: "number" },
        pinnable: true,
      },
      {
        key: "changeValue",
        title: "Change (£)",
        numerical: "changeValue",
        sorter: true,
        filterColumn: { path: "changeValue", type: "number" },
        pinnable: true,
      },
      {
        key: "changePercent",
        title: "Change (%)",
        align: "right",
        render: (record) => {
          const roundedChangePercentage: number = Math.round(_.get(record, "changePercent") * 100) / 100;
          return `${roundedChangePercentage >= 0 ? "+" : "-"} ${Math.abs(roundedChangePercentage)}%`;
        },
        sorter: true,
        filterColumn: { path: "changePercent", type: "number" },
        pinnable: true,
      },
      {
        key: "status",
        title: "Status",
        dataIndex: "status",
        sorter: true,
        filterColumn: true,
        pinnable: true,
      },
      {
        key: "underlyingInvestor",
        title: "Underlying Investor",
        colGroup: [
          {
            key: "underlyingInvestorName",
            title: "Full Name",
            dataIndex: "subscription.investor.fullName",
            sorter: true,
            filterColumn: true,
            pinnable: true,
          },
          {
            key: "underlyingInvestorEmail",
            title: "Email",
            dataIndex: "subscription.investor.email",
            sorter: true,
            filterColumn: true,
            pinnable: true,
          },
        ],
      },
      {
        key: "applicationId",
        title: "Application ID",
        dataIndex: "subscription.application",
        sorter: true,
        filterColumn: true,
        pinnable: true,
      },
      {
        key: "transactionId",
        title: "Transaction ID",
        dataIndex: "shareTransactionId",
        sorter: true,
        filterColumn: true,
      },
      {
        key: "contractNote",
        title: "Contract Note",
        pinnable: true,
        sorter: "subscription.contractNote",
        render: (record) => {
          if (record.subscription && record.subscription.contractNote) {
            return (
              <Button
                variant="text"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  downloadContractNote(record);
                }}
              >
                Download
              </Button>
            );
          }
          return "N/A";
        },
      },
      {
        key: "uir",
        title: "Unique Investment Reference (UIR)",
        dataIndex: "uir",
        sorter: true,
        filterColumn: true,
        pinnable: true,
      },
      {
        key: "subscription.seis3",
        title: "(S)EIS3",
        colGroup: [
          {
            key: "subscription.seis3.status",
            title: "Status",
            dataIndex: "subscription.seis3.status",
            filterColumn: true,
            pinnable: true,
          },
          {
            key: "subscription.seis3.download",
            title: "Download",
            render: (record) =>
              record.subscription?.seis3?.key && (
                <DropDownButton
                  variant="text"
                  size="small"
                  primaryClick={(e) => {
                    e.stopPropagation();
                    downloadTaxCertificate(record?.subscriptionId);
                  }}
                  dropDownItems={[
                    {
                      text: "Regenerate",
                      onClick: async () => {
                        const res = await regenerateTaxCertificate(record.shareTransactionId, record.subscriptionId);
                        if (res) dispatch({ type: ADMIN_TYPES.BULK_UPDATE_HOLDINGS, payload: res });
                      },
                    },
                  ]}
                >
                  View
                </DropDownButton>
              ),
          },
          {
            key: "subscription.seis3.generated",
            title: "Generated Date",
            dataIndex: "subscription.seis3.generatedFormatted",
            sorter: "subscription.seis3.generated",
            filterColumn: { path: "subscription.seis3.generated", type: "date" },
          },
          {
            key: "subscription.seis3.signed",
            title: "Signed Date",
            dataIndex: "subscription.seis3.signedFormatted",
            sorter: "subscription.seis3.signed",
            filterColumn: { path: "subscription.seis3.signed", type: "date" },
          },
        ],
      },
    ],
    [dispatch],
  );

  const setHoldings = useCallback(
    async (onChangeObject: OnChangeObject, isExport: boolean) => {
      const res = await getAllHoldings(onChangeObject);
      if (!res) return [];
      if (isExport) return res.data;
      dispatch({ type: ADMIN_TYPES.SET_HOLDINGS, payload: res.data });
      setCount(parseInt(res.count));
    },
    [dispatch],
  );

  const handleUnderlyingDataExport = useCallback(async () => {
    const XLSX = await import("xlsx");
    const workbook = XLSX.utils.book_new();
    const groupedRows = selectedRows.reduce<GenericObject<AdminHolding[]>>(
      (rows, currRow) => ({
        ...rows,
        [currRow.company._id]: [...(rows[currRow.company._id] || []), currRow],
      }),
      {},
    );

    const data = Object.values(groupedRows).map((rows) => {
      const sheetRows = rows.map(({ company, subscription, ...row }) => {
        const { investor } = subscription;
        const advisor = investor.advisor;
        const address = investor.personalDetails?.addressHistory[0];
        return {
          "Settle Date": { t: "d", v: row.settleDate, z: "yyyy-mm-dd" },
          "Company Name": company.companyName,
          "Company number": company.companyNumber,
          "Deal Stage": row.dealStage,
          Portfolio: row.portfolio?.name,
          Nominee: row.nominee,
          "Investment Manager": row.investmentManager,
          Name: `${investor.forenames} ${investor.surname}`,
          Email: investor.email,
          "Investor's Qualification": investor.qualification?.qualification,
          "Adviser Name": advisor?.user ? `${advisor.user.forenames} ${advisor.user.surname}` : "N/A",
          "Adviser Email": advisor?.user ? advisor.user.email : "N/A",
          Address: address
            ? `${address.addressLineOne}, ${address.addressLineTwo ? `${address.addressLineTwo}, ` : ""}${address.city}, ${address.country}, ${
                address.postcode
              }`
            : "N/A",
          "Amount Invested": { t: "n", v: row.value, z: "£#,##0.00" },
          "Number of Shares": { t: "n", v: subscription.noOfShares, z: "#,##0" },
        };
      });
      const sheet = XLSX.utils.json_to_sheet(sheetRows);
      return { sheetRows, sheet, sheetTitle: sheetRows[0]["Company Name"].trim().slice(0, 30) };
    });
    const allDataSheet = XLSX.utils.json_to_sheet(data.flatMap(({ sheetRows }) => sheetRows));
    XLSX.utils.book_append_sheet(workbook, allDataSheet, "All Data");
    data.forEach(({ sheet, sheetTitle }) => XLSX.utils.book_append_sheet(workbook, sheet, sheetTitle));
    XLSX.writeFile(workbook, `Underlying_Investor_Data-${moment().toISOString()}.xlsx`);
  }, [selectedRows]);

  const handleGoogleSheetLink = useCallback(async () => {
    const data = await getSheetsExportLink();
    setSheetsExportLink(data);
  }, []);

  return (
    <>
      <AdminTable
        tableStructure={subscriptionsTable}
        tableData={holdings}
        onChange={setHoldings}
        count={count}
        exportToCSVOption
        csvFilename="Subscriptions"
        rowsSelectable
        onSelectedRowsChange={setSelectedRows}
        defaultSort={{ key: "settleDate", direction: "desc" }}
      />
      <div className={classes.tableFooter}>
        <DropDownButton
          primaryText="Bulk Actions"
          primaryButtonProps={{ disabled: !selectedRows.length }}
          dropDownItems={[{ text: "Export Underlying Investor Data", onClick: handleUnderlyingDataExport }]}
        />
        <Button onClick={handleGoogleSheetLink}>Google Sheets Link</Button>

        <DisplayLink
          fullWidth
          open={Boolean(sheetsExportLink)}
          link={sheetsExportLink}
          onClose={() => {
            setSheetsExportLink(null);
          }}
        />
      </div>
    </>
  );
};

export default SubscriptionsTab;
