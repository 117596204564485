import { makeStyles, Typography } from "@material-ui/core";
import { ConfigContext } from "client/context/config.context";
import { fundVariableReplacer } from "client/utils/helpers";
import React, { useContext } from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
  },
  row: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  key: {
    position: "relative",
    width: "40%",
    display: "inline-block",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  value: {
    position: "relative",
    width: "60%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      borderLeft: `5px solid ${theme.palette.primary.dark}`,
      borderRadius: 5,
      paddingLeft: theme.spacing(1),
    },
  },
}));

const Glossary = ({ dictionary, ...props }) => {
  const classes = useStyles(props);
  const { fund } = useContext(ConfigContext);

  return (
    <div className={classes.container}>
      <br />
      {Object.entries(dictionary).map(([term, definition], index) => (
        <div key={index} className={classes.row}>
          <Typography variant="body2" paragraph className={classes.key}>
            {term}
          </Typography>
          <Typography variant="body2" paragraph className={classes.value}>
            {fundVariableReplacer(definition, fund)}
          </Typography>
        </div>
      ))}
      <br />
    </div>
  );
};

export default Glossary;
