import { Container, makeStyles, Theme, useMediaQuery } from "@material-ui/core";
import InfoBanner from "client/components/InfoBanner.component";
import { ConfigContext } from "client/context/config.context";
import { FC, useContext, useEffect, useMemo } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import clientsAdvisorApi from "../../api/advisor/clients.advisorApi";
import ProgressBar from "../../components/UIKit/ProgressBar.component";
import UserContext, { UserContextTypes } from "../../context/user.context";
import FinancialSituation from "./FinancialSituation.view";
import IdVerification from "./IdVerification.view";
import Assessment from "./Assessment.view";
import InvestmentDetails from "./InvestmentDetails.view";
import InvestmentObjectives from "./InvestmentObjectives.view";
import KnowledgeExperience from "./KnowledgeExperience.view";
import PersonalDetails from "./PersonalDetails.view";

const PAGE_NAMES = {
  ID_VERIFICATION: "id-verification",
  ASSESSMENT: "assessment",
  PERSONAL_DETAILS: "personalDetails",
  OBJECTIVES: "objectives",
  FINANCES: "finances",
  EXPERIENCE: "experience",
  INVESTMENT: "investment",
} as const;

const useStyles = makeStyles(
  () => ({
    progressBarContainer: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  }),
  { name: "ApplicationFormView" },
);

const ApplicationForm: FC = (props) => {
  const classes = useStyles(props);
  const history = useHistory();
  const { clientID, applicationId } = useParams<{ clientID?: string; applicationId?: string }>();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const { dispatch } = useContext(UserContext);
  const { fund } = useContext(ConfigContext);

  const match = useRouteMatch<{ pageName?: string }>();
  const pageName = useMemo(() => match.params.pageName, [match.params.pageName]);
  const isAdmin = match.path === `/admin/application/:applicationId/apply/:pageName`;

  useEffect(() => {
    if (clientID) {
      (async () => {
        const tempClient = await clientsAdvisorApi.getClient(clientID);
        if (tempClient) dispatch({ type: UserContextTypes.SET_CLIENT, payload: tempClient, clientId: clientID });
      })();
    }
  }, [clientID, dispatch]);

  const formMap = useMemo(
    () => ({
      [PAGE_NAMES.ID_VERIFICATION]: () => (fund.aml.enabled ? <IdVerification /> : history.replace("./assessment")),
      [PAGE_NAMES.ASSESSMENT]: () => <Assessment isAdmin={isAdmin} />,
      [PAGE_NAMES.PERSONAL_DETAILS]: () => <PersonalDetails isAdmin={isAdmin} />,
      [PAGE_NAMES.OBJECTIVES]: () => <InvestmentObjectives isAdmin={isAdmin} />,
      [PAGE_NAMES.FINANCES]: () => <FinancialSituation isAdmin={isAdmin} />,
      [PAGE_NAMES.EXPERIENCE]: () => <KnowledgeExperience isAdmin={isAdmin} />,
      [PAGE_NAMES.INVESTMENT]: () => <InvestmentDetails isAdmin={isAdmin} />,
    }),
    [fund.aml.enabled, history, isAdmin],
  );

  const activeLine = useMemo(() => {
    switch (pageName) {
      case PAGE_NAMES.ID_VERIFICATION:
      case PAGE_NAMES.ASSESSMENT:
      case PAGE_NAMES.PERSONAL_DETAILS:
        return 1;
      case PAGE_NAMES.OBJECTIVES:
        return 2;
      case PAGE_NAMES.FINANCES:
        return 3;
      case PAGE_NAMES.EXPERIENCE:
        return 4;
      case PAGE_NAMES.INVESTMENT:
        return 5;
      default:
        return 0;
    }
  }, [pageName]);

  useEffect(() => {
    if (!pageName || !formMap[pageName]) history.replace(fund.aml.enabled ? "./id-verification" : "./personalDetails");
  }, [formMap, fund.aml.enabled, history, pageName]);

  return (
    <>
      {isAdmin && (
        <InfoBanner>
          You are editing this application as an admin.
          <br />
          Warning: Validation is ignored on form submission. Please make sure fields are correct manually!
        </InfoBanner>
      )}
      {!isMobile && (
        <Container className={classes.progressBarContainer}>
          <ProgressBar activeBarLine={activeLine} />
        </Container>
      )}
      {pageName && formMap[pageName]?.()}
    </>
  );
};

export default ApplicationForm;
