import { Container, makeStyles, Typography } from "@material-ui/core";
import ApplicationApi from "client/api/application.api";
import type HelloSignEmbedded from "hellosign-embedded";
import _ from "lodash";
import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ClientApplicationApi from "../../api/advisor/client/application.clientApi";
import ApplicationsApi from "../../api/applications.api";
import QualificationApi from "../../api/qualification.api";
import HelloSignLogo from "../../assets/HelloSign_logo.png";
import { ApplicationContext, APPLICATION_TYPES } from "../../context/application.context";
import { AuthContext, AUTH_TYPES } from "../../context/auth.context";
import { ConfigContext } from "../../context/config.context";
import useQuery from "../../hooks/useQuery.hook";
import { ROUTES, APPLICATION_STATES } from "../../utils/constants";
import notifications from "../../utils/notifications";

interface Props {}

let HelloSign: typeof HelloSignEmbedded;
if (typeof window !== "undefined") {
  import("hellosign-embedded").then((module) => (HelloSign = module.default));
}

const useStyles = makeStyles(
  (theme) => ({
    body: {
      minHeight: "70vh",
      width: "100%",
      padding: "0 20vw",
      marginTop: "50px",

      [theme.breakpoints.down("sm")]: {
        padding: "0 30px",
      },
    },
    hellosignLoading: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      flex: 1,
      justifyContent: "center",
    },
    hellosignLogo: {
      width: 180,
      marginLeft: -20,
      marginBottom: -40,
      [theme.breakpoints.down("sm")]: {
        width: 155,
        marginBottom: -34,
      },
    },
    imageContainer: {
      display: "flex",
      justifyContent: "center",
    },
    iAContainer: {
      width: "100%",
      border: `1px solid ${theme.palette.grey.darker}`,
      height: "calc(100vh - 100px)",
      position: "relative",
      display: "flex",
      flexDirection: "column",
    },
  }),
  { name: "SignAgreementView" },
);

const SignAgreement: FC<Props> = (props) => {
  const classes = useStyles(props);
  const config = useContext(ConfigContext);
  const {
    state: { user },
    dispatch: userDispatch,
  } = useContext(AuthContext);
  const iFrameRef = useRef<HTMLIFrameElement>(null);
  const history = useHistory();
  const sigId = useQuery("sigId");
  const applicationId = useQuery("applicationId");
  const params = useParams<{ clientID?: string }>();
  const [signatureUrl, setSignatureUrl] = React.useState<string | null>(null);
  const [hellosignLoading, setHellosignLoading] = useState(true);
  const { application, dispatch: applicationDispatch } = useContext(ApplicationContext);

  const { HELLOSIGN_CLIENT_ID, IS_DEVELOPMENT, IS_TEST, IS_FEATURE, logo, fund } = useMemo(() => config, [config]);

  const clientId = useMemo<boolean | string | undefined>(() => user!.isAdvisor && params.clientID, [params.clientID, user]);

  useEffect(() => {
    (async () => {
      if (application) return;
      const app = clientId ? await ClientApplicationApi.getApplication(clientId) : await ApplicationsApi.getUsersApplication(applicationId);
      applicationDispatch({ type: APPLICATION_TYPES.SET_APPLICATION, payload: app });
    })();
  }, [application, applicationDispatch, applicationId, clientId]);

  useEffect(() => {
    (async () => {
      if (!application?._id) return;
      const res = await ApplicationApi.requestSignatureUrl(application._id, params.clientID, sigId);
      if (res.success) setSignatureUrl(res.data);
    })();
  }, [application, params.clientID, sigId]);

  const confirmInvestor = useCallback(async () => {
    const res = await QualificationApi.confirmInvestor();
    if (res.success)
      userDispatch({
        type: AUTH_TYPES.SET_USER,
        payload: { ...user, ...res.data },
      });
  }, [userDispatch, user]);

  const goForward = useCallback(() => {
    history.push(params.clientID ? `/advisor/client/${params.clientID}${ROUTES.APPLICATION_COMPLETE}` : ROUTES.APPLICATION_COMPLETE);
  }, [history, params.clientID]);

  useEffect(() => {
    if (application?._id && HelloSign) {
      const { SIGN, ERROR, CANCEL } = HelloSign.events;
      const client = new HelloSign({ clientId: HELLOSIGN_CLIENT_ID });

      if (iFrameRef?.current && signatureUrl) {
        client.open(signatureUrl, {
          skipDomainVerification: IS_DEVELOPMENT || IS_TEST || IS_FEATURE,
          allowCancel: false,
          container: iFrameRef.current,
        });
        setHellosignLoading(false);
      }
      client.on(SIGN, () => {
        (async () => {
          if (!params.clientID && !sigId) {
            await confirmInvestor();
            applicationDispatch({ type: APPLICATION_TYPES.SET_CLIENT, payload: { ...application, status: APPLICATION_STATES.AWAITING_FUNDS } });
          }
          goForward();
        })();
      });
      client.on(ERROR, (data) => notifications.genericError(new Error(JSON.stringify(data))));
      client.on(CANCEL, () => notifications.warning("The document must be signed to complete your application."));

      return () => client.close();
    }
  }, [
    HELLOSIGN_CLIENT_ID,
    IS_DEVELOPMENT,
    IS_TEST,
    application,
    confirmInvestor,
    userDispatch,
    goForward,
    params.clientID,
    signatureUrl,
    user,
    sigId,
    IS_FEATURE,
    applicationDispatch,
  ]);

  return (
    <Container maxWidth="md">
      <Typography variant="h1" align="center" paragraph>
        {user!.isAdvisor ? "Your client’s application" : "Your investment agreement"}
      </Typography>
      {user!.isAdvisor ? (
        <Typography variant="body1" align="center" paragraph>
          Your client’s application has been generated based on the answers provided. Please review this document and, when you are happy with it,
          scroll down to the adviser section to sign. Your client will then be informed of the application and instructed to sign the investor
          section.
        </Typography>
      ) : (
        <Typography variant="body1" align="center" paragraph>
          This investment agreement has been generated based on the answers provided to us. Please review this document and, when you are happy with
          it, scroll down to the bottom to sign. A copy of the document will then be emailed to you.
        </Typography>
      )}
      <Typography component="div" variant="body2" paragraph>
        eSignature powered by <img src={HelloSignLogo} alt="HelloSign Logo" className={classes.hellosignLogo} />
      </Typography>

      <div className={classes.iAContainer} ref={iFrameRef}>
        {hellosignLoading && (
          <div data-testid="signatureLoading" className={classes.hellosignLoading}>
            <div className={classes.imageContainer}>
              <img src={fund.meta.darkLogo ? _.get(config, fund.meta.darkLogo) : logo} width="80%" height="100%" alt="Agreement loading" />
            </div>
            <br />
            <Typography variant="body1">Generating agreement...</Typography>
            (This can take up to 1 minute)
          </div>
        )}
      </div>
    </Container>
  );
};

export default SignAgreement;
