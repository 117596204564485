import { DialogContent, DialogContentText, DialogTitle, IconButton, Typography, createStyles, makeStyles } from "@material-ui/core";
import ResponsiveDialog from "./ResponsiveDialog.component";
import React from "react";
import CloseIcon from "client/components/Icons/Close.icon";

interface Props {
  onClose: () => void;
  companyName: string;
}
const useStyles = makeStyles(
  (theme) =>
    createStyles({
      closeButton: {
        alignSelf: "flex-end",
        padding: 0,
        color: theme.palette.grey.darker,
      },
      title: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 0,
      },
      titleText: {
        paddingTop: theme.spacing(3.82),
      },
      content: {
        paddingTop: theme.spacing(9.38),
        paddingLeft: theme.spacing(12),
        paddingRight: theme.spacing(12),
        paddingBottom: theme.spacing(8),
      },
      bold: {
        fontWeight: "bold",
      },
    }),
  { name: "DissolvedWarningPopup" },
);

const DissolvedWarningPopup: React.FC<Props> = ({ onClose, companyName, ...props }) => {
  const classes = useStyles(props);
  return (
    <ResponsiveDialog open={true} onClose={onClose} fullWidth={true} maxWidth="md" data-testid="dissolved-warning-popup">
      <DialogContentText component="div" align="justify" onClick={(event) => event.stopPropagation()}>
        <DialogTitle disableTypography className={classes.title}>
          <IconButton onClick={onClose} color="secondary" className={classes.closeButton} data-testid="dissolved-warning-closed-button">
            <CloseIcon fontSize="small" />
          </IconButton>
          <Typography variant="h2" align="center" className={classes.titleText}>
            DISSOLVED
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.content} data-testid="dissolved-warning-content">
          <Typography paragraph variant="body2">
            <Typography variant="body2" display="inline" className={classes.bold}>
              {companyName}
            </Typography>{" "}
            has been dissolved. You may be eligible to claim loss relief for your investment. Please contact your financial or tax advisor for advice
            on how to proceed.
          </Typography>
        </DialogContent>
      </DialogContentText>
    </ResponsiveDialog>
  );
};

export default DissolvedWarningPopup;
