import { FC, useCallback, useContext, useMemo } from "react";
import { Button, Dialog, DialogContent, DialogTitle, FormGroup, FormHelperText, IconButton, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Field, FieldArray, Form, Formik } from "formik";
import { LeanDoc } from "server/services/document/document.types";
import * as Yup from "yup";
import { removePublicDocument, uploadPublicDocument } from "../../../api/admin/documents.adminApi";
import CrudFormButtons from "../../../components/FormControls/CrudFormButtons.component";
import DatePickerField from "../../../components/FormControls/DatePickerField.component";
import SelectField from "../../../components/FormControls/SelectField.component";
import TextQuestion from "../../../components/FormControls/TextQuestion.component";
import { AdminContext, ADMIN_TYPES } from "../../../context/admin.context";

const SCHEMA = Yup.object().shape({
  title: Yup.string().required("Please enter a document title"),
  documentInitials: Yup.string().required("Please add the initial key for document"),
  accessRole: Yup.string().required("Please select who can access this document"),
  file: Yup.mixed().required("Please upload a file"),
  versionNumber: Yup.string(),
  date: Yup.date().required("Please enter a date").typeError("Invalid date"),
  coverImage: Yup.mixed().when("tags", {
    is: (tags: string[]) => tags?.includes("reviews"),
    then: Yup.mixed().required("Please upload a cover image. It is required for documents with 'reviews' tag"),
    otherwise: Yup.mixed(),
  }),
  tags: Yup.array(Yup.string().required("Please select a tag for this file")),
});

const INITIAL_VALUES = {
  title: "",
  documentInitials: "",
  accessRole: "",
  file: "",
  versionNumber: "",
  date: "",
  coverImage: "",
  tags: [],
};

interface Props {
  onClose: () => void;
  document: LeanDoc;
}

const PublicDocumentsForm: FC<Props> = ({ onClose, document, ...props }) => {
  const { dispatch } = useContext(AdminContext);

  const initialValues = useMemo(
    () =>
      document
        ? {
            ...INITIAL_VALUES,
            title: document ? document.title : "",
            documentInitials: document ? document.documentInitials : "",
            accessRole: document ? document.accessRole : "",
            date: document ? document.date : "",
          }
        : INITIAL_VALUES,
    [document],
  );

  const handleDelete = useCallback(async () => {
    if (document) {
      const documentId = await removePublicDocument(document._id);
      if (documentId) {
        dispatch({ type: ADMIN_TYPES.DELETE_DOCUMENT, payload: documentId });
        onClose();
      }
    }
  }, [dispatch, document, onClose]);

  const handleSubmit = useCallback(
    async (values: GenericObject<any>) => {
      const doc = await (document ? uploadPublicDocument(values, document._id) : uploadPublicDocument(values));
      if (doc) {
        dispatch({ type: ADMIN_TYPES.UPDATE_DOCUMENT, payload: { ...doc, fileName: `${doc.title}_v${doc.versionNumber}.pdf` } });
        onClose();
      }
    },
    [dispatch, document, onClose],
  );

  return (
    <Dialog open={Boolean(document)} onClose={onClose} {...props}>
      <DialogTitle>{document ? "Edit Public Document" : "Add Public Document"}</DialogTitle>
      <DialogContent>
        <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={SCHEMA} enableReinitialize={true}>
          {({ setFieldValue, values }) => (
            <Form data-testid="publicDocumentsForm">
              <FormGroup>
                <Field component={TextQuestion} name="title" label="Document Title" />
              </FormGroup>
              <FormGroup>
                <Field component={TextQuestion} name="documentInitials" label="Document Initials Key" />
              </FormGroup>
              <FormHelperText>For example KID for Key Information Document.</FormHelperText>
              <FormGroup>
                <Field
                  component={SelectField}
                  name="accessRole"
                  label="Who can view this document"
                  options={[
                    { label: "Public User (Unauthed user, not logged in)", value: "publicUser" },
                    { label: "Read-Only Investor (Advised Client)", value: "readOnlyInvestor" },
                    { label: "Investor", value: "investor" },
                    { label: "Adviser", value: "advisor" },
                    { label: "Read-Only Admin", value: "readOnlyAdmin" },
                    { label: "Admin", value: "admin" },
                    { label: "Manager", value: "manager" },
                  ]}
                />
              </FormGroup>
              <FormGroup>
                <Field component={DatePickerField} name="date" label="Date" />
              </FormGroup>
              <FormGroup>
                <Field
                  component={TextQuestion}
                  name="file"
                  type="file"
                  placeholder="Upload Document"
                  label="Upload Document"
                  onChange={(e: any) => setFieldValue(e.target.name, e.target.files[0])}
                  value={undefined}
                  InputProps={{
                    inputProps: {
                      accept: "application/pdf",
                    },
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Field component={TextQuestion} name="versionNumber" label="Version Number (optional)" placeholder="Version Number" />
              </FormGroup>
              <FormGroup>
                <Field
                  component={TextQuestion}
                  name="coverImage"
                  type="file"
                  placeholder="Upload Cover Image"
                  label="Upload Cover Image"
                  onChange={(e: any) => setFieldValue(e.target.name, e.target.files[0])}
                  value={undefined}
                  InputProps={{
                    inputProps: {
                      accept: "image/x-png,image/gif,image/jpeg",
                    },
                  }}
                />
              </FormGroup>

              <br />
              <hr />
              <Typography variant="body2">
                For the document to show in the Documents tab of the Information Memorandum section please add the “IM” tag.
              </Typography>

              <FieldArray name="tags">
                {({ push, remove }) => (
                  <>
                    {values.tags.map((tag, index) => (
                      <FormGroup key={index}>
                        {index > 0 && (
                          <FormGroup style={{ alignItems: "flex-end", margin: 0 }}>
                            <hr />
                            <IconButton size="small" edge="end" style={{ flex: 0 }} onClick={() => remove(index)}>
                              <CloseIcon />
                            </IconButton>
                          </FormGroup>
                        )}
                        <FormGroup>
                          <Field
                            component={SelectField}
                            name={`tags[${index}]`}
                            label="Document Tag"
                            options={[
                              {
                                label: "IM",
                                value: "im",
                              },
                              {
                                label: "Reviews",
                                value: "reviews",
                              },
                              {
                                label: "Current IM",
                                value: "currentIM",
                              },
                              {
                                label: "Investor Update Document",
                                value: "investorUpdateDocument",
                              },
                            ]}
                          />
                        </FormGroup>
                      </FormGroup>
                    ))}
                    <FormGroup>
                      <Button onClick={() => push("")}>Add Tag</Button>
                    </FormGroup>
                    <hr />
                  </>
                )}
              </FieldArray>

              <CrudFormButtons onClose={onClose} isEdit={Boolean(document)} handleDelete={handleDelete} />
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default PublicDocumentsForm;
