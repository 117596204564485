import { Container, createStyles, FormGroup, makeStyles, Typography } from "@material-ui/core";
import ApplicationApi from "client/api/application.api";
import FormikErrorsTouched from "client/components/FormikErrorsTouched.component";
import { InitialValues } from "client/utils/helpers";
import { Field, Form, Formik } from "formik";
import React, { FC, useCallback, useContext, useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { LeanApplication, Objectives } from "server/services/application/application.types";
import * as Yup from "yup";
import * as AdminApplicationApi from "../../api/admin/application.adminApi";
import ClientApplicationApi from "../../api/advisor/client/application.clientApi";
import ErrorsList from "../../components/ErrorsList.component";
import CheckboxQuestion from "../../components/FormControls/CheckboxQuestion.component";
import RadioQuestion from "../../components/FormControls/RadioQuestion.component";
import TextQuestion from "../../components/FormControls/TextQuestion.component";
import { APPLICATION_TYPES } from "../../context/application.context";
import { AuthContext } from "../../context/auth.context";
import useAnalytics from "../../hooks/useAnalytics";
import { FORM_FIELDS_DATA, ROUTES, TRACKED_EVENTS } from "../../utils/constants";
import StageButtonGroup from "./StageButtonGroup.component";
import useApplication from "./useApplication";

interface Props {
  isAdmin?: boolean;
}
const { INVESTMENT_OBJECTIVES_FORM } = FORM_FIELDS_DATA;

const {
  INPUT: {
    CATEGORIES: { INVESTMENT_OBJECTIVES },
  },
  PAGE_VIEWS,
  PAGE_ACTIONS,
} = TRACKED_EVENTS;

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      understandRisk: {
        marginBottom: theme.spacing(12.5),
      },
      container: {
        backgroundColor: "#F8F8F8",
        borderTop: "solid 1px",
        borderBottom: "solid 1px",
      },
      formGroup: {
        paddingBottom: theme.spacing(5),
        [theme.breakpoints.down("md")]: {
          paddingBottom: theme.spacing(3),
        },
      },
      hr: {
        borderTop: "1px solid",
      },
      subTitle: {
        fontWeight: "bold",
        fontSize: "24px",
      },
      secondTitle: {
        fontSize: "16px",
        fontWeight: "bold",
        paddingTop: "10px",
      },
      sectionTitle: {
        fontWeight: 600,
      },
    }),
  { name: "InvestmentObjectivesView" },
);

const SCHEMA = Yup.object().shape({
  [INVESTMENT_OBJECTIVES_FORM.accessToCapital.id]: Yup.boolean().required("Please select whether you require access to capital"),
  [INVESTMENT_OBJECTIVES_FORM.eisRelief.id]: Yup.boolean().required("Please select whether you wish take advantage of EIS taxation reliefs"),
  [INVESTMENT_OBJECTIVES_FORM.underPressure.id]: Yup.boolean().required("Please select whether you are under any pressure to invest"),
  [INVESTMENT_OBJECTIVES_FORM.willingToLoseInvestment.id]: Yup.boolean().required(
    "Please select whether you are willing to lose all of your investment",
  ),
  [INVESTMENT_OBJECTIVES_FORM.healthIssues.id]: Yup.boolean().required("Please select whether you are aware of any health issues"),
  [INVESTMENT_OBJECTIVES_FORM.deferGains.id]: Yup.object().shape({
    [INVESTMENT_OBJECTIVES_FORM.deferGains.isDeferGains.id]: Yup.boolean().required("Please select if you are seeking to defer capital gain"),
    [INVESTMENT_OBJECTIVES_FORM.deferGains.deferredGains.id]: Yup.string(),
  }),
  [INVESTMENT_OBJECTIVES_FORM.inheritanceTaxRelief.id]: Yup.boolean().required(
    "Please select whether you wish to benefit from inheritance tax relief",
  ),
  [INVESTMENT_OBJECTIVES_FORM.incomeTaxRelief.id]: Yup.boolean().required(
    "Please select whether you wish take advantage of claiming full income tax relief",
  ),
  [INVESTMENT_OBJECTIVES_FORM.capitalCommitments.id]: Yup.object().shape({
    [INVESTMENT_OBJECTIVES_FORM.capitalCommitments.isCapitalCommitment.id]: Yup.boolean().required(
      "Please select whether you have any significant capital commitments",
    ),
    [INVESTMENT_OBJECTIVES_FORM.capitalCommitments.confirmation.id]: Yup.string(),
  }),
  [INVESTMENT_OBJECTIVES_FORM.understandRisk.id]: Yup.boolean()
    .oneOf([true], "Please check to agree that you understand the risk")
    .required("Please check to agree that you understand the risk"),
});

const DEFAULT_VALUES = {
  [INVESTMENT_OBJECTIVES_FORM.understandRisk.id]: null,
  [INVESTMENT_OBJECTIVES_FORM.accessToCapital.id]: null,
  [INVESTMENT_OBJECTIVES_FORM.eisRelief.id]: null,
  [INVESTMENT_OBJECTIVES_FORM.deferGains.id]: {
    [INVESTMENT_OBJECTIVES_FORM.deferGains.isDeferGains.id]: null,
    [INVESTMENT_OBJECTIVES_FORM.deferGains.deferredGains.id]: "",
  },
  [INVESTMENT_OBJECTIVES_FORM.incomeTaxRelief.id]: null,
  [INVESTMENT_OBJECTIVES_FORM.underPressure.id]: null,
  [INVESTMENT_OBJECTIVES_FORM.willingToLoseInvestment.id]: null,
  [INVESTMENT_OBJECTIVES_FORM.healthIssues.id]: null,
  [INVESTMENT_OBJECTIVES_FORM.inheritanceTaxRelief.id]: null,
  [INVESTMENT_OBJECTIVES_FORM.capitalCommitments.id]: {
    [INVESTMENT_OBJECTIVES_FORM.capitalCommitments.isCapitalCommitment.id]: null,
    [INVESTMENT_OBJECTIVES_FORM.capitalCommitments.confirmation.id]: "",
  },
};

const InvestmentObjectives: FC<Props> = ({ isAdmin, ...props }) => {
  const {
    state: { user },
  } = useContext(AuthContext);
  const history = useHistory();
  const params = useParams<{ applicationId?: string; clientID?: string }>();
  const applicationId = useMemo(() => params.applicationId, [params.applicationId]);
  const clientId = useMemo(() => user?.isAdvisor && params.clientID, [params.clientID, user?.isAdvisor]);
  const classes = useStyles(props);
  const { application, dispatch } = useApplication(isAdmin);

  useAnalytics();

  useEffect(() => {
    globalThis.dataLayer.push({
      event: clientId ? PAGE_VIEWS.CLIENT_APPLICATION_FORM_OBJECTIVES : PAGE_VIEWS.INVESTOR_APPLICATION_OBJECTIVES,
    });
  }, [clientId]);

  const goForward = useCallback(() => {
    history.push(`..${ROUTES.APPLICATION_FINANCES}`);
  }, [history]);

  const handleFormSubmit = useCallback(
    async (values) => {
      let submittedApplication: LeanApplication | null = null;
      if (clientId) {
        const res = await ClientApplicationApi.postObjectives(clientId, application!._id, values);
        if (res) {
          submittedApplication = res;
          globalThis.dataLayer.push({ event: PAGE_ACTIONS.CLIENT_APPLICATION_ABOUT_FORM_SUBMITTED_SUCCESSFULLY });
        } else {
          globalThis.dataLayer.push({ event: PAGE_ACTIONS.CLIENT_APPLICATION_ABOUT_FORM_SUBMITTED_UNSUCCESSFULLY });
        }
      } else if (isAdmin) {
        const res = await AdminApplicationApi.postObjectives(applicationId!, values);
        if (res) submittedApplication = res;
      } else {
        const res = await ApplicationApi.postObjectives(application!._id, values);
        if (res) {
          submittedApplication = res;
          globalThis.dataLayer.push({ event: PAGE_ACTIONS.INVESTOR_APPLICATION_FORM_ABOUT_SUBMITTED_SUCCESSFULLY });
        } else {
          globalThis.dataLayer.push({
            event: PAGE_ACTIONS.INVESTOR_APPLICATION_FORM_ABOUT_SUBMITTED_UNSUCCESSFULLY,
          });
        }
      }
      if (submittedApplication) {
        dispatch({ type: APPLICATION_TYPES.SET_APPLICATION, payload: submittedApplication });
        goForward();
      }
    },
    [application, applicationId, clientId, dispatch, goForward, isAdmin],
  );

  const initialValues = useMemo<InitialValues<Objectives>>(
    () => (application?.data ? { ...DEFAULT_VALUES, ...application.data.objectives } : { ...DEFAULT_VALUES }),
    [application],
  );

  return (
    <>
      <Container>
        <FormGroup>
          <Typography variant="h2" align="center">
            Appropriateness questionnaire
          </Typography>
        </FormGroup>
        <FormGroup>
          <Typography align="center">Please note that we cannot accept an application if this information is not complete.</Typography>
        </FormGroup>
      </Container>
      <Formik initialValues={initialValues} validationSchema={SCHEMA} onSubmit={handleFormSubmit} enableReinitialize>
        {({ values, errors, submitCount }) => (
          <Form autoComplete="off" noValidate>
            <FormikErrorsTouched />
            <div className={classes.container}>
              <Container maxWidth="md">
                <FormGroup>
                  <Typography variant="h4" className={classes.sectionTitle}>
                    Section 2:
                  </Typography>
                  <Typography variant="h4" paragraph>
                    Investment objectives
                  </Typography>
                  <Typography paragraph>
                    The investment is designed to be held for the medium to long term, because investments in EIS companies have to be held for at
                    least three years in order to benefit from the initial income tax relief. Please note that investments in unquoted companies are
                    higher risk than shares quoted on the main market of the London Stock Exchange. The service is designed to provide investors with
                    access to a portfolio of investments in small unquoted companies which qualify for EIS tax benefits and have the potential for
                    capital appreciation.
                  </Typography>
                </FormGroup>
                <FormGroup>
                  <Field
                    component={CheckboxQuestion}
                    name={INVESTMENT_OBJECTIVES_FORM.understandRisk.name}
                    label={INVESTMENT_OBJECTIVES_FORM.understandRisk.label}
                    trackedCategory={INVESTMENT_OBJECTIVES}
                  />
                </FormGroup>
              </Container>
            </div>
            <Container maxWidth="md">
              <FormGroup className={classes.formGroup}>
                <Field
                  component={RadioQuestion}
                  name={INVESTMENT_OBJECTIVES_FORM.accessToCapital.name}
                  label={INVESTMENT_OBJECTIVES_FORM.accessToCapital.label}
                  trackedCategory={INVESTMENT_OBJECTIVES}
                />
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <Field
                  component={RadioQuestion}
                  name={INVESTMENT_OBJECTIVES_FORM.eisRelief.name}
                  label={INVESTMENT_OBJECTIVES_FORM.eisRelief.label}
                  trackedCategory={INVESTMENT_OBJECTIVES}
                />
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <Field
                  component={RadioQuestion}
                  name={INVESTMENT_OBJECTIVES_FORM.underPressure.name}
                  label={INVESTMENT_OBJECTIVES_FORM.underPressure.label}
                  trackedCategory={INVESTMENT_OBJECTIVES}
                />
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <Field
                  component={RadioQuestion}
                  name={INVESTMENT_OBJECTIVES_FORM.willingToLoseInvestment.name}
                  label={INVESTMENT_OBJECTIVES_FORM.willingToLoseInvestment.label}
                  trackedCategory={INVESTMENT_OBJECTIVES}
                />
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <Field
                  component={RadioQuestion}
                  name={INVESTMENT_OBJECTIVES_FORM.healthIssues.name}
                  label={INVESTMENT_OBJECTIVES_FORM.healthIssues.label}
                  trackedCategory={INVESTMENT_OBJECTIVES}
                />
              </FormGroup>
            </Container>
            <hr className={classes.hr} />
            <Container maxWidth="md">
              <FormGroup className={classes.formGroup}>
                <Typography className={classes.subTitle}>Specific questions relating to EIS taxation reliefs</Typography>
                <Typography className={classes.secondTitle}>
                  Please note the service may not be an appropriate investment if you cannot take advantage of some of the EIS tax reliefs (for each
                  question, tick one box as appropriate).
                </Typography>
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <Field
                  component={RadioQuestion}
                  name={INVESTMENT_OBJECTIVES_FORM.deferGains.isDeferGains.name}
                  label={INVESTMENT_OBJECTIVES_FORM.deferGains.isDeferGains.label}
                  trackedCategory={INVESTMENT_OBJECTIVES}
                />
                {values.deferGains?.isDeferGains && (
                  <Field
                    component={TextQuestion}
                    name={INVESTMENT_OBJECTIVES_FORM.deferGains.deferredGains.name}
                    label={INVESTMENT_OBJECTIVES_FORM.deferGains.deferredGains.label}
                    multiline
                  />
                )}
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <Field
                  component={RadioQuestion}
                  name={INVESTMENT_OBJECTIVES_FORM.inheritanceTaxRelief.name}
                  label={INVESTMENT_OBJECTIVES_FORM.inheritanceTaxRelief.label}
                  trackedCategory={INVESTMENT_OBJECTIVES}
                />
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <Field
                  component={RadioQuestion}
                  name={INVESTMENT_OBJECTIVES_FORM.incomeTaxRelief.name}
                  label={INVESTMENT_OBJECTIVES_FORM.incomeTaxRelief.label}
                  trackedCategory={INVESTMENT_OBJECTIVES}
                />
              </FormGroup>
              <FormGroup className={classes.formGroup}>
                <Field
                  component={RadioQuestion}
                  name={INVESTMENT_OBJECTIVES_FORM.capitalCommitments.isCapitalCommitment.name}
                  label={INVESTMENT_OBJECTIVES_FORM.capitalCommitments.isCapitalCommitment.label}
                  trackedCategory={INVESTMENT_OBJECTIVES}
                />
                {values.capitalCommitments?.isCapitalCommitment && (
                  <Field
                    component={TextQuestion}
                    name={INVESTMENT_OBJECTIVES_FORM.capitalCommitments.confirmation.name}
                    label={INVESTMENT_OBJECTIVES_FORM.capitalCommitments.confirmation.label}
                    trackedCategory={INVESTMENT_OBJECTIVES}
                  />
                )}
              </FormGroup>
              {submitCount > 0 && Object.keys(errors).length !== 0 && errors.constructor === Object && (
                <FormGroup>
                  <Typography variant="body2" color="error" paragraph>
                    Please complete the following fields:
                  </Typography>
                  <ErrorsList errors={errors} constant={INVESTMENT_OBJECTIVES_FORM} />
                </FormGroup>
              )}
              <FormGroup>
                <StageButtonGroup handleFormSubmit={handleFormSubmit} isAdmin={isAdmin} />
              </FormGroup>
            </Container>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default InvestmentObjectives;
