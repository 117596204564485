import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  (theme) => ({
    container: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",

      "@media print": {
        padding: 0,
      },
    },

    bold: {
      fontWeight: theme.typography.fontWeightBold,
    },

    fullWidthImage: {
      maxWidth: "100%",
      width: "auto",
      maxHeight: 500,
      marginBottom: 50,
    },

    signatureImage: {
      "@media print": {
        maxWidth: "100mm",
      },
      maxHeight: 120,

      [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "100%",
      },
    },

    titleContainer: {
      paddingLeft: "3vw",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 30,
        marginBottom: 20,
      },
    },

    stepContentContainer: {
      display: "flex",
      padding: theme.spacing(8, 0),
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(4, 0),
        flexDirection: "column",
        alignItems: "center",
      },
    },

    stepParagraphContainer: {
      flex: 6,

      [theme.breakpoints.down("sm")]: {
        flex: 8,
      },
    },

    stepImageContainer: {
      display: "flex",
      flex: 4,
      alignItems: "center",
      justifyContent: "center",
    },

    paragraph: {
      margin: theme.margin,
      color: theme.palette.text.primary,
    },

    documentContainer: {
      marginTop: 60,
      paddingTop: 20,
      display: "flex",
      justifyContent: "center",
      "& div": {
        flexDirection: "column",
      },
    },

    documentButton: {
      padding: 10,
      borderColor: theme.palette.text.primary,
      borderRadius: 7,
      backgroundColor: "white",
      fontWeight: theme.typography.fontWeightBold,
      height: 50,
      width: 50,
      fontSize: 18,
      boxSizing: "border-box",
      cursor: "pointer",
      color: "black",
      zIndex: 1,
      "&:focus": {
        outline: 0,
      },
    },

    documentButtonDock: {
      height: 75,
      padding: "0 5px",
      width: 200,
      boxSizing: "border-box",
      backgroundColor: "white",
      borderRadius: 7,
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
      alignItems: "center",
      zIndex: 1,
      position: "absolute",
      top: 60,
      right: "45%",
    },

    subtitle: {
      textAlign: theme.textAlign,
      color: theme.palette.text.primary,
      margin: theme.margin || "0 0 4vh 0",
      fontSize: theme.fontSize || "60px",
      display: "flex",
      alignSelf: "center",
      [theme.breakpoints.down("sm")]: {
        fontSize: 40,
      },
    },

    documentWrapper: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "center",
      alignItems: "center",
    },
    messageContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },

    returnsIframe: {
      position: "absolute",
      clip: "rect(150px, 2000px, 1500px, 100px)",
      top: "-150px",
      borderRadius: 5,
      overflow: "visible",
    },

    sectionWrapper: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      padding: `${theme.spacing(10)}px 0`,
    },

    graph: {
      marginBottom: "3vh",
      width: "100%",
    },

    trackRecordContainer: {
      display: "flex",

      [theme.breakpoints.down("md")]: {
        flexDirection: "column-reverse",
        alignItems: "center",
      },
    },

    iFrameWrapper: {
      flex: 1,
      paddingLeft: "2%",

      [theme.breakpoints.down("sm")]: {
        width: "99vw",
        height: 220,
        padding: 0,
        marginLeft: 0,
        marginBottom: "4vh",

        "& > iframe": {
          width: "100%",
          height: "100%",
        },
      },
    },

    returnsCalcIframeWrapper: {
      position: "relative",
      width: "100%",
      paddingBottom: 1350,
      border: "2px solid gray",
      borderRadius: 5,
      outlineOffset: "-20px",

      [theme.breakpoints.down("sm")]: {
        opacity: 0,
        display: "none",
      },
    },

    thirdPartyReviewsContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      alignItems: "center",
      backgroundColor: theme.palette.primary.light,
      padding: "50px 20vw 50px 20vw",
      marginTop: 100,
      boxSizing: "border-box",
      width: "100%",

      [theme.breakpoints.down("md")]: {
        marginTop: 60,
        padding: 25,
      },

      [theme.breakpoints.down("sm")]: {
        height: "auto",
        marginTop: 60,
        padding: 25,
        display: "flex",

        "& > p": {
          textAlign: "left",
          fontSize: 16,
          lineHeight: 24,
        },

        "& > h1": {
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
    },

    thankYouPhotoWrapper: {
      marginLeft: 60,

      [theme.breakpoints.down("sm")]: {
        margin: 0,
      },
    },

    taxContainer: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "space-around",
      boxSizing: "border-box",

      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
      },
    },

    taxBox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "35vw",
      padding: 40,
      borderColor: theme.borderColor,
      borderWidth: 2,
      borderStyle: "solid",
      boxSizing: "border-box",

      "&:not(:last-child)": {
        marginRight: 40,
      },

      [theme.breakpoints.down("md")]: {
        width: "92%",

        "&:not(:last-child)": {
          marginBottom: 20,
        },
      },

      [theme.breakpoints.down("sm")]: {
        padding: 20,
      },
    },

    bulletBorderColor: {
      borderColor: "#83DFC8",
    },

    primaryBorderColor: {
      borderColor: "#709BFF",
    },

    productsWrapper: {
      display: "flex",
      justifyContent: "center",

      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },

    footnotesContainer: {
      display: "flex",
    },
  }),
  { name: "UseStylesHook" },
);
