import { Container, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(
  (theme) => ({
    container: {
      border: `2px solid ${theme.palette.grey.border}`,
      padding: `${theme.spacing(6.25)}px ${theme.spacing(10)}px`,
      overflowY: "auto",
      scrollbarWidth: "thin",
      scrollbarColor: theme.palette.primary.main,
      "&::-webkit-scrollbar": {
        width: 10,
      },
      "&::-webkit-scrollbar-track": {
        background: "transparent",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: theme.palette.primary.main,
      },
      [theme.breakpoints.down("md")]: {
        padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
      },
    },
    bold: {
      fontWeight: theme.typography.fontWeightBold,
    },
  }),
  { name: "statementComponent" },
);

const Statement = ({ title, ...props }) => {
  const classes = useStyles(props);

  return (
    <>
      <Container maxWidth="sm">
        <Typography variant="body1" className={classes.bold}>
          Please confirm that the following statement is true by ticking the box below
        </Typography>
      </Container>
      <div className={classes.container}>
        <Typography variant="body1" gutterBottom className={classes.bold}>
          {title}
        </Typography>
        <Typography component="div" variant="body2">
          {props.children}
        </Typography>
      </div>
    </>
  );
};

export default Statement;
