import { Button, makeStyles } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../utils/constants';

const useStyles = makeStyles(theme => ({
  button: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

const AdvisorDashboard = props => {
  const classes = useStyles(props);
  const history = useHistory();

  return (
    <Button
      className={classes.button}
      onClick={() => {
        history.push(ROUTES.ADVISOR.DASHBOARD);
      }}
    >
      CONTINUE TO DASHBOARD
    </Button>
  );
};

export default AdvisorDashboard;
