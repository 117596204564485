import fileDownload from "js-file-download";
import { CompanyWithHoldings } from "server/services/company/company.types";
import { HistoricValue } from "server/services/shareTransaction/shareTransaction.types";
import notifications from "../../../utils/notifications";
import { ipAxios } from "../../ipAxios";

const getClientStatement = async (clientId: string) => {
  try {
    const res = await ipAxios.get(`/advisor/clients/${clientId}/statement`);
    return res.data;
  } catch (error) {
    notifications.genericError(error);
    return null;
  }
};

const getClientBalance = async (clientId: string) => {
  try {
    const res = await ipAxios.get(`/advisor/clients/${clientId}/statement/balance`);
    return res.data;
  } catch (error) {
    notifications.genericError(error);
    return null;
  }
};

const getClientPortfolio = async (clientId: string) => {
  try {
    const res = await ipAxios.get<{ historicValues: HistoricValue[]; portfolio: CompanyWithHoldings[] }>(
      `/advisor/clients/${clientId}/statement/portfolio`,
    );
    return res.data;
  } catch (error) {
    notifications.genericError(error);
    return null;
  }
};

const downloadClientCertificate = async (clientId: string, subscriptionId: string) => {
  try {
    const res = await ipAxios({
      url: `/advisor/clients/${clientId}/statement/portfolio/download-certificate/${subscriptionId}`,
      method: "GET",
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const blob = new Blob([res.data]);
    fileDownload(blob, `${clientId}-${subscriptionId}-certificate.pdf`);
  } catch (e) {
    notifications.genericError(e);
  }
};

const downloadClientEvidence = async (clientId: string, subscriptionId: string) => {
  try {
    const res = await ipAxios({
      url: `/advisor/clients/${clientId}/statement/portfolio/download-evidence/${subscriptionId}`,
      method: "GET",
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const blob = new Blob([res.data]);
    fileDownload(blob, `${clientId}-${subscriptionId}-evidence.pdf`);
  } catch (e) {
    notifications.genericError(e);
  }
};

export default {
  getClientStatement,
  getClientBalance,
  getClientPortfolio,
  downloadClientCertificate,
  downloadClientEvidence,
};
