import { Button, DialogContentText, Typography } from "@material-ui/core";
import { ColumnDefinition, OnChangeObject, TableCellEditHandler } from "@wearenova/mui-data-table";
import _ from "lodash";
import React, { Fragment, useCallback, useContext, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { AdminUser } from "server/services/user/user.types";
import { getUserPortfolio, getUsers, inviteUser, loginAsUser, resetPasswordLink, updateUser } from "../../../api/admin/users.adminApi";
import { AdminContext, ADMIN_TYPES } from "../../../context/admin.context";
import { AuthContext, AUTH_TYPES } from "../../../context/auth.context";
import { APPLICATION_STATES, ROUTES, USER_ROLE } from "../../../utils/constants";
import { formatThousands } from "../../../utils/helpers";
import notifications from "../../../utils/notifications";
import AdminTable from "../AdminTable.view";
import { useStyles } from "../useStyles.hook";
import DisplayToken from "./DisplayToken.view";
import UserForm from "./UserForm.view";
import VerifyAdvisor from "./VerifyAdvisor.view";
import { exportUserPortfolio } from "../../../utils/csvExport";
import ResponsiveDialog from "client/components/ResponsiveDialog.component";
import useToggle from "client/hooks/useToggle.hook";

interface Props {
  readOnly?: boolean;
}

const UsersTab: React.FC<Props> = ({ readOnly, ...props }) => {
  const classes = useStyles(props);
  const history = useHistory();
  const [addUser, setAddUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState<AdminUser | null>(null);
  const [advisorVerifyUser, setAdvisorVerifyUser] = useState<AdminUser | null>(null);
  const [count, setCount] = useState(-1);
  const [userToken, setUserToken] = useState(null);
  const [userName, setUserName] = useState("");
  const { users, dispatch: adminDispatch } = useContext(AdminContext);
  const {
    state: { user },
    dispatch: authDispatch,
  } = useContext(AuthContext);
  const [resetPassword, setResetPassword] = useState<any>("");
  const [open, , toggleOn, toggleOff] = useToggle(false);
  //object return array of roles
  const ROLES = Object.values(USER_ROLE);
  const QUALIFICATIONS = ["ADVISED_INVESTOR", "PROFESSIONAL_INVESTOR", "SELF_CERTIFIED_SOPHISTICATED_INVESTOR", "HIGH_NET_WORTH", "RESTRICTED"];

  const setUsers = useCallback(
    async (onChangeObject: OnChangeObject, isExport: boolean) => {
      const res = await getUsers(onChangeObject);
      if (!res) return [];
      if (isExport) return res.data;
      adminDispatch({ type: ADMIN_TYPES.SET_USERS, payload: res.data });
      setCount(res.count);
      return res.data;
    },
    [adminDispatch],
  );
  const usersTable = useMemo<ColumnDefinition<AdminUser>[]>(
    () => [
      {
        key: "fullName",
        title: "Full Name",
        dataIndex: "fullName",
        sorter: true,
        filterColumn: true,
        pinnable: true,
        hidden: true,
      },
      {
        key: "forenames",
        title: "Forenames",
        dataIndex: "forenames",
        sorter: true,
        filterColumn: true,
        pinnable: true,
        editable: true,
      },
      {
        key: "surname",
        title: "Surname",
        dataIndex: "surname",
        sorter: true,
        filterColumn: true,
        pinnable: true,
        editable: true,
      },
      {
        key: "email",
        title: "Email",
        dataIndex: "email",
        sorter: true,
        filterColumn: true,
        pinnable: true,
        editable: true,
      },
      {
        key: "isConfirmed",
        title: "Confirmed?",
        dataIndex: "isConfirmed",
        dataType: "boolean",
        sorter: true,
        filterColumn: true,
        editable: true,
      },
      {
        key: "contactNumber",
        title: "Contact Number",
        dataIndex: "personalDetails.contactNumber",
        sorter: true,
        filterColumn: true,
        pinnable: true,
        editable: true,
      },
      {
        key: "advisorFullName",
        title: "Adviser Full Name",
        dataIndex: "advisor.advisorUser.fullName",
        sorter: true,
        filterColumn: true,
        pinnable: true,
      },
      {
        key: "advisorEmail",
        title: "Adviser Email",
        dataIndex: "advisor.advisorUser.email",
        sorter: true,
        filterColumn: true,
        pinnable: true,
      },
      {
        key: "address",
        title: "Address",
        render: (record, isCSVExport) => {
          if (!record.personalDetails?.addressHistory?.[0]) return null;

          // if we're exporting to CSV, only export the user's latest address
          if (isCSVExport) {
            const { addressLineOne, addressLineTwo, city, country, postcode } = record.personalDetails.addressHistory[0];
            return `${addressLineOne} ${addressLineTwo || ""} ${city} ${country} ${postcode}`;
          }
          // display all addresses
          return record.personalDetails.addressHistory.map(({ addressLineOne, addressLineTwo, city, country, postcode }, index) => (
            <Fragment key={index}>
              {index > 0 && <hr />}
              <address>
                {[addressLineOne, addressLineTwo, city, country, postcode].filter(Boolean).map((line) => (
                  <Fragment key={line}>
                    {line}
                    <br />
                  </Fragment>
                ))}
              </address>
            </Fragment>
          ));
        },
        sorter: "personalDetails.addressHistory.addressLineOne",
        filterColumn: "personalDetails.addressHistory.addressLineOne",
      },
      {
        key: "role",
        title: "Role",
        dataIndex: "role",
        sorter: true,
        filterColumn: true,
        editable: {
          path: "role",
          type: "select",
          selectOptions: ROLES,
        },
      },
      {
        key: "qualification",
        title: "Qualification",
        dataIndex: "qualification.qualification",
        sorter: "qualification.qualification",
        filterColumn: "qualification.qualification",
        editable: {
          path: "qualification.qualification",
          type: "select",
          selectOptions: QUALIFICATIONS,
        },
      },
      {
        key: "applicationStatus",
        title: "Status",
        dataIndex: "applicationStatus",
        sorter: true,
        filterColumn: true,
        pinnable: true,
        editable: {
          path: "applicationStatus",
          type: "select",
          selectOptions: Object.values(APPLICATION_STATES),
        },
      },
      {
        key: "registrationDate",
        title: "Registration Date",
        dataIndex: "registrationDateFormatted",
        sorter: "registrationDate",
        dataType: "date",
        filterColumn: { path: "registrationDate", type: "date" },
        pinnable: true,
        editable: "registrationDate",
      },
      {
        key: "cashBalanceTotal",
        title: "Total Balance",
        sorter: "cashBalance.total",
        filterColumn: { path: "cashBalance.total", type: "number" },
        render: (record) => formatThousands(record.cashBalance.total, 2),
      },
      {
        key: "cashBalanceSeis",
        title: "SEIS Balance",
        sorter: "cashBalance.seis",
        filterColumn: { path: "cashBalance.seis", type: "number" },
        render: (record) => formatThousands(record.cashBalance.seis, 2),
      },
      {
        key: "cashBalanceEis",
        title: "EIS Balance",
        sorter: "cashBalance.eis",
        filterColumn: { path: "cashBalance.eis", type: "number" },
        render: (record) => formatThousands(record.cashBalance.eis, 2),
      },
      {
        key: "inviteRequired",
        title: "Invite user",
        sorter: "inviteRequired",
        filterColumn: { path: "inviteRequired", type: "boolean" },
        render: (record) =>
          record.inviteRequired && (
            <Button
              disabled={readOnly}
              variant="text"
              size="small"
              color="primary"
              onClick={async (e) => {
                e.stopPropagation();
                const user = await inviteUser(record._id);

                if (user) {
                  notifications.success("Email sent!");
                  adminDispatch({ type: ADMIN_TYPES.UPDATE_USER, payload: user });
                }
              }}
            >
              Invite
            </Button>
          ),
      },
      {
        title: "Min Investment",
        key: "minInvestment",
        render: (record) => (record.minInvestment ? formatThousands(record.minInvestment, 2) : "Min Investment Not Available"),
        dataIndex: "minInvestment",
        numerical: { path: true, currency: true },
        sorter: true,
        filterColumn: { path: "minInvestment", type: "number" },
        pinnable: true,
        editable: true,
      },
      {
        title: "Max Investment",
        key: "maxInvestment",
        render: (record) => (record.maxInvestment ? formatThousands(record.maxInvestment, 2) : record.maxInvestment),
        dataIndex: "maxInvestment",
        numerical: { path: true, currency: true },
        sorter: true,
        filterColumn: { path: "maxInvestment", type: "number" },
        pinnable: true,
        editable: true,
      },
      {
        key: "advisorVerified",
        title: "Verified Adviser?",
        sorter: "advisorDetails.isVerified",
        filterColumn: { path: "advisorDetails.isVerified", type: "boolean" },
        render: (record) => (typeof record.advisorDetails?.isVerified === "undefined" ? null : String(record.advisorDetails?.isVerified)),
      },
      {
        key: "Investor Report",
        title: "Investor Report",
        render: (record) => (
          <Button
            variant="text"
            size="small"
            onClick={async (e) => {
              e.stopPropagation();
              const data = await getUserPortfolio(record._id);
              if (!data) {
                notifications.error("Couldn't retrieve portfolio for this user.");
                return;
              }
              exportUserPortfolio(record._id, data);
              notifications.success("Portfolio exported!");
            }}
          >
            Download
          </Button>
        ),
      },
      {
        key: "login",
        title: "Login as User",
        render: (record) => (
          <Button
            variant="text"
            size="small"
            color="primary"
            disabled={user?.role === USER_ROLE.ADMIN || user?.role === USER_ROLE.MANAGER ? false : readOnly}
            onClick={async (e) => {
              e.stopPropagation();
              const user = await loginAsUser(record._id);
              if (user) {
                authDispatch({ type: AUTH_TYPES.ADMIN_SET_USER, payload: user });
                history.push(ROUTES.DASHBOARD);
              }
            }}
          >
            Login
          </Button>
        ),
      },
      {
        key: "getToken",
        title: "Get Token",
        render: (record) =>
          record.role === "apiUser" ? (
            <Button
              variant="text"
              size="small"
              color="primary"
              disabled={readOnly || user?.role === USER_ROLE.MANAGER}
              onClick={async (e) => {
                e.stopPropagation();
                const token = await loginAsUser(record._id, true);
                setUserToken(token);
                setUserName(`${record.forenames} ${record.surname}`);
              }}
            >
              Get Token
            </Button>
          ) : (
            ""
          ),
      },
      {
        key: "resetPassword",
        title: "Reset Password",
        render: (record) => (
          <Button
            variant="text"
            size="small"
            color="default"
            disabled={readOnly || user?.role === USER_ROLE.MANAGER}
            onClick={async (e) => {
              e.stopPropagation();
              toggleOn();
              await resetPasswordLink(record._id)
                .then((res) => {
                  res && setResetPassword(res?.data);
                })
                .catch((err) => notifications.genericError(err));
            }}
          >
            Reset
          </Button>
        ),
      },
    ],
    [QUALIFICATIONS, ROLES, adminDispatch, authDispatch, history, readOnly],
  );

  const handleTableEdit = useCallback<TableCellEditHandler<AdminUser>>(
    async ({ path, value }, record) => {
      const res = await updateUser(_.set({}, path, value === "null" ? null : value), record._id);
      if (res) adminDispatch({ type: ADMIN_TYPES.UPDATE_USER, payload: res });
    },
    [adminDispatch],
  );
  const handleCopy = async () => {
    await navigator.clipboard.writeText(resetPassword);
    notifications.success("Copied");
    toggleOff();
  };
  return (
    <>
      <AdminTable
        onChange={setUsers}
        count={count}
        tableData={users}
        tableStructure={usersTable}
        rowClick={(user) => setSelectedUser(user)}
        tableProps={{ size: "small" }}
        exportToCSVOption
        csvFilename="Users"
        defaultSort={{ key: "registrationDate", direction: "desc" }}
        onEdit={handleTableEdit}
      />
      <div className={classes.tableFooter}>
        <Button disabled={readOnly} onClick={() => setAddUser(true)} data-testid="addUserButton">
          Add User
        </Button>
        <UserForm
          fullWidth
          open={Boolean(addUser || selectedUser)}
          user={selectedUser || undefined}
          onClose={() => {
            setAddUser(false);
            setSelectedUser(null);
          }}
        />
        <VerifyAdvisor
          open={Boolean(advisorVerifyUser)}
          advisor={advisorVerifyUser}
          onClose={() => {
            setAdvisorVerifyUser(null);
          }}
        />
        <DisplayToken
          fullWidth
          open={Boolean(userToken)}
          token={userToken}
          userName={userName}
          onClose={() => {
            setUserToken(null);
          }}
        />
        <ResponsiveDialog
          open={open}
          closeButton={{ onClick: toggleOff }}
          title="Reset Password Link"
          actionButtons={[{ label: "Copy Link", onClick: handleCopy, attributes: { "data-testid": "copy-reset-password-url" } }]}
        >
          <DialogContentText component="div" align="justify" style={{ color: "inherit", wordBreak: "break-all" }}>
            <Typography paragraph>{resetPassword}</Typography>
          </DialogContentText>
        </ResponsiveDialog>
      </div>
    </>
  );
};

export default UsersTab;
