import { Container, createStyles, Link, makeStyles, Typography } from "@material-ui/core";
import { ConfigContext } from "client/context/config.context";
import { ROUTES } from "client/utils/constants";
import React, { useCallback, useContext, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Markdown from "./Markdown.component";

interface Props {}

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      riskWarningContainer: {
        borderTop: "solid 1px",
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        "& *": {
          fontSize: theme.typography.body2.fontSize,
          letterSpacing: theme.typography.caption.letterSpacing,
          lineHeight: theme.typography.caption.lineHeight,
        },
        "& a": {
          color: theme.palette.primary.main,
        },
        "@media print": {
          display: "none",
        },
      },
      riskLink: {
        overflowWrap: "anywhere",
        fallbacks: {
          overflowWrap: "break-word",
        },
      },
    }),
  { name: "RiskWarningComponent" },
);

const RiskWarning: React.FC<Props> = (props) => {
  const classes = useStyles(props);
  const { fund } = useContext(ConfigContext);
  const history = useHistory();
  const location = useLocation();

  const isRegister = useMemo(() => location.pathname === ROUTES.REGISTER || /\/registration.*/.test(location.pathname), [location.pathname]);

  const handleRiskWarningClick = useCallback(
    () => history.push("/risk", { fromRegister: isRegister, referrer: location.pathname + location.search }),
    [history, isRegister, location.pathname, location.search],
  );

  return (
    <div className={classes.riskWarningContainer}>
      <Container>
        <Typography color="inherit" paragraph>
          <strong>
            RISK WARNING: &nbsp;
            <Link onClick={handleRiskWarningClick} color="primary" paragraph className={classes.riskLink}>
              Please read our full risk warning and disclaimer: {fund.origin}/risk
            </Link>
          </strong>
        </Typography>
        <Markdown>{fund.footer}</Markdown>
      </Container>
    </div>
  );
};

export default RiskWarning;
