import { Button, Collapse, createStyles, FormGroup, makeStyles, MenuItem, Select, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { Chart, ChartDataset, ScatterDataPoint } from "chart.js";
import "chart.js/auto";
import { ConfigContext } from "client/context/config.context";
import clsx from "clsx";
import { Field, Form, Formik } from "formik";
import _ from "lodash";
import moment from "moment";
import React, { FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { ReturnsCalculatorResult } from "server/services/accounting/accounting.types";
import * as Yup from "yup";
import PublicReturnsCalculatorApi from "../api/public/returnsCalculator.publicApi";
import { TRACKED_EVENTS } from "../utils/constants";
import { formatThousands } from "../utils/helpers";
import NumberQuestion from "./FormControls/NumberQuestion.component";
import RotateDevice from "./RotateDevice.component";

interface Props {}

const OPTIONS = {
  investButton: { label: "Investment Made", backgroundColorPath: "text.usedLink", textColor: "secondary" },
  taxButton: { label: "Income Tax Relief", backgroundColorPath: "emerald.main", textColor: "secondary" },
  lossesButton: { label: "Losses", backgroundColorPath: "pk.main", textColor: "secondary" },
  lossReliefButton: { label: "Loss Relief", backgroundColorPath: "primary.main", textColor: "primary" },
  exitCashButton: { label: "Cash From Exits", backgroundColorPath: "stone.main", textColor: "secondary" },
  outflowButton: { label: "Cash Outflow", backgroundColorPath: "bullet.main", textColor: "primary" },
  cumulativeButton: { label: "Cumulative Cash Outflow", backgroundColorPath: "primary.dark", textColor: "secondary" },
  portfolioButton: { label: "Portfolio Value", backgroundColorPath: "violet.main", textColor: "secondary" },
  totalButton: { label: "Total Return", backgroundColorPath: "error.main", textColor: "secondary" },
} as const;

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      buttonsContainer: {
        marginBottom: theme.spacing(2),
      },
      calculationContainer: {
        border: `solid 2px ${theme.palette.primary.main}`,
        marginTop: theme.spacing(4),
        padding: theme.spacing(4),
      },
      dataButton: {
        margin: theme.spacing(1),
      },
      formBorder: {
        paddingRight: 20,
        borderRight: `solid 2px ${theme.palette.divider}`,

        [theme.breakpoints.down("sm")]: {
          paddingRight: 0,
          borderRight: 0,
          paddingBottom: 10,
          borderBottom: `solid 2px ${theme.palette.divider}`,
        },
      },
      hiddenReturns: {
        overflow: "scroll",
        scrollbarWidth: "thin",
        scrollbarColor: theme.palette.primary.main,
        "&::-webkit-scrollbar": {
          width: 4,
        },
        "&::-webkit-scrollbar-track": {
          background: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: theme.palette.primary.main,
        },
      },
      mobileWarning: {
        display: "none",
        [theme.breakpoints.down("sm")]: {
          display: "flex",
          padding: theme.spacing(2),
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          marginBottom: theme.spacing(2),
        },
      },
      returnsDetails: {
        display: "flex",
        justifyContent: "space-between",
        margin: theme.spacing(2, 0),
        [theme.breakpoints.down("sm")]: {
          flexDirection: "column",
        },
      },
      selectInput: {
        width: "100%",
      },
      visibleReturns: {
        marginTop: theme.spacing(4),
        padding: theme.spacing(4),
        border: `solid 2px ${theme.palette.primary.main}`,
        [theme.breakpoints.down("sm")]: {
          padding: theme.spacing(2),
        },
      },
      ...Object.entries(OPTIONS).reduce((classes, [key, option]) => {
        const style = {
          backgroundColor: _.get(theme.palette, option.backgroundColorPath),
          color: _.get(theme.palette, `text.${option.textColor}`),
        };
        return {
          ...classes,
          [key]: { ...style, "&.Mui-selected, &.Mui-selected:hover": style },
        };
      }, {} as GenericObject),
    }),
  { name: "ReturnsCalculator" },
);

const { PAGE_VIEWS, PAGE_ACTIONS } = TRACKED_EVENTS;

const getMinMaxMessage = (minMax: "min" | "max", { prefix = "", suffix = "" }: { prefix?: string; suffix?: string } = {}) =>
  `Must be at ${minMax === "min" ? "least" : "most"} ${prefix}\${${minMax}}${suffix}`;

const MESSAGES = {
  minYear: getMinMaxMessage("min", { suffix: " year" }),
  maxYear: getMinMaxMessage("max", { suffix: " years" }),
  minPound: getMinMaxMessage("min", { prefix: "£" }),
  maxPound: getMinMaxMessage("max", { prefix: "£" }),
  minPercent: getMinMaxMessage("min", { suffix: "%" }),
  maxPercent: getMinMaxMessage("max", { suffix: "%" }),
};

const SCHEMA = Yup.object({
  investmentPerYear: Yup.number()
    .min(10000, MESSAGES.minPound)
    .max(2100000, MESSAGES.maxPound)
    .required("Please enter the amount you would want to invest per year"),
  noYears: Yup.number()
    .min(1, MESSAGES.minYear)
    .max(5, MESSAGES.maxYear)
    .required("Please indicate the number of years you want to invest the amount above"),
  incomeTax: Yup.number().min(0, MESSAGES.minPercent).max(100, MESSAGES.maxPercent).required("Please indicate the marginal income tax"),
});

const addDataset = (dataset: Pick<ChartDataset<"line">, "label" | "borderColor" | "data" | "pointBackgroundColor">): ChartDataset<"line"> => ({
  backgroundColor: "rgba(0, 0, 0, 0)",
  pointHoverBorderWidth: 4,
  pointHoverRadius: 4,
  borderWidth: 2,
  pointRadius: 3,
  type: "line",
  ...dataset,
});

const INITIAL_STATE = {
  investButton: true,
  taxButton: false,
  lossesButton: false,
  lossReliefButton: false,
  exitCashButton: false,
  outflowButton: false,
  cumulativeButton: false,
  portfolioButton: false,
  totalButton: true,
};

type ChartValues = {
  [K in keyof ReturnsCalculatorResult]: Array<ReturnsCalculatorResult[keyof ReturnsCalculatorResult]>;
} & {
  totalTerm: number;
  irr?: number;
  co?: string;
  rp?: string;
  voc?: string;
};

const DEFAULT_CHART_VALUES: Omit<ChartValues, "cumulativeCashFromExits"> = {
  investment: [],
  taxRelief: [],
  losses: [],
  lossRelief: [],
  cashFromExits: [],
  cashOutflow: [],
  cumulativeOutflow: [],
  portfolioValue: [],
  newCompanies: [],
  cumulativeCompanies: [],
  totalReturn: [],
  totalTerm: 0,
};

const ReturnsCalculator: FC<Props> = (props) => {
  const classes = useStyles(props);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const { fund } = useContext(ConfigContext);
  const canvas = useRef<HTMLCanvasElement>(null);
  const [submitted, setSubmitted] = useState(false);
  const [buttonStates, setButtonStates] = useState(INITIAL_STATE);
  const [chart, setChart] = useState<Chart<"line", (number | ScatterDataPoint | null)[], string> | null>(null);
  const [chartValues, setChartValues] = useState({ ...DEFAULT_CHART_VALUES });
  const [selectValues, setSelectValues] = useState(["investButton", "totalButton"]);

  useEffect(() => {
    if (!fund.returnsCalculator.enabled) {
      history.replace("/dashboard/portfolio");
    }
  }, [fund.returnsCalculator.enabled, history]);

  useEffect(() => {
    Chart.defaults.font = {
      ...Chart.defaults.font,
      family: theme.typography.fontFamily!,
      size: 16,
      weight: theme.typography.fontWeightSemiBold! as any,
    };
  }, [theme.typography.fontFamily, theme.typography.fontWeightSemiBold]);

  useEffect(() => {
    globalThis.dataLayer.push({ event: PAGE_VIEWS.IM_RETURNS_CALCULATOR });
  }, []);

  const createChart = useCallback(
    (termLength: number) => {
      const ctx = canvas.current?.getContext("2d");
      if (!ctx) return;

      const years = [];
      const currentFinancialYearStart = moment().month("April").date(5);

      if (currentFinancialYearStart.isAfter()) currentFinancialYearStart.subtract(1, "year");

      for (let i = 0; i < termLength; i++) {
        years.push(
          `${moment(currentFinancialYearStart).add(i, "years").format("YYYY")}/${moment(currentFinancialYearStart)
            .add(i + 1, "years")
            .format("YY")}`,
        );
      }
      const chrt = new Chart(ctx, {
        type: "line",
        data: {
          labels: years,
          datasets: [],
        },
        options: {
          responsive: true,
          scales: {
            x: {
              grid: {
                display: false,
              },
            },
            y: {
              position: "left",
              display: true,
              title: {
                display: true,
                text: "Pounds \n (£1000s)",
                font: {
                  size: isMobile ? 10 : 24,
                  weight: theme.typography.fontWeightBold! as any,
                },
              },
              ticks: {
                callback: (value) => {
                  return (value as number) / 1000;
                },
              },
            },
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: (tooltipItems) => {
                  return formatThousands(tooltipItems.parsed.y);
                },
              },
            },
            legend: {
              display: false,
            },
          },
        },
      });
      setChart(chrt);
    },
    [canvas, isMobile, theme.typography.fontWeightBold],
  );

  useEffect(() => {
    if (chart) {
      const datasets = [];
      if (buttonStates.investButton) {
        const color = _.get(theme.palette, OPTIONS.investButton.backgroundColorPath);
        datasets.push(
          addDataset({
            label: OPTIONS.investButton.label,
            borderColor: color,
            pointBackgroundColor: color,
            data: chartValues.investment,
          }),
        );
      }
      if (buttonStates.taxButton) {
        const color = _.get(theme.palette, OPTIONS.taxButton.backgroundColorPath);
        datasets.push(
          addDataset({
            label: OPTIONS.taxButton.label,
            borderColor: color,
            pointBackgroundColor: color,
            data: chartValues.taxRelief,
          }),
        );
      }
      if (buttonStates.lossesButton) {
        const color = _.get(theme.palette, OPTIONS.lossesButton.backgroundColorPath);
        datasets.push(
          addDataset({
            label: OPTIONS.lossesButton.label,
            borderColor: color,
            pointBackgroundColor: color,
            data: chartValues.losses,
          }),
        );
      }
      if (buttonStates.lossReliefButton) {
        const color = _.get(theme.palette, OPTIONS.lossReliefButton.backgroundColorPath);
        datasets.push(
          addDataset({
            label: OPTIONS.lossReliefButton.label,
            borderColor: color,
            pointBackgroundColor: color,
            data: chartValues.lossRelief,
          }),
        );
      }
      if (buttonStates.exitCashButton) {
        const color = _.get(theme.palette, OPTIONS.exitCashButton.backgroundColorPath);
        datasets.push(
          addDataset({
            label: OPTIONS.exitCashButton.label,
            borderColor: color,
            pointBackgroundColor: color,
            data: chartValues.cashFromExits,
          }),
        );
      }
      if (buttonStates.outflowButton) {
        const color = _.get(theme.palette, OPTIONS.outflowButton.backgroundColorPath);
        datasets.push(
          addDataset({
            label: OPTIONS.outflowButton.label,
            borderColor: color,
            pointBackgroundColor: color,
            data: chartValues.cashOutflow,
          }),
        );
      }
      if (buttonStates.cumulativeButton) {
        const color = _.get(theme.palette, OPTIONS.cumulativeButton.backgroundColorPath);
        datasets.push(
          addDataset({
            label: OPTIONS.cumulativeButton.label,
            borderColor: color,
            pointBackgroundColor: color,
            data: chartValues.cumulativeOutflow,
          }),
        );
      }
      if (buttonStates.portfolioButton) {
        const color = _.get(theme.palette, OPTIONS.portfolioButton.backgroundColorPath);
        datasets.push(
          addDataset({
            label: OPTIONS.portfolioButton.label,
            borderColor: color,
            pointBackgroundColor: color,
            data: chartValues.portfolioValue,
          }),
        );
      }
      if (buttonStates.totalButton) {
        const color = _.get(theme.palette, OPTIONS.totalButton.backgroundColorPath);
        datasets.push(
          addDataset({
            label: OPTIONS.totalButton.label,
            borderColor: color,
            pointBackgroundColor: color,
            data: chartValues.totalReturn,
          }),
        );
      }

      chart.data.datasets = datasets;
      chart.update();
    }
  }, [buttonStates, chart, chartValues, theme.palette]);

  const getResults = useCallback(
    async (formData) => {
      try {
        const data = await PublicReturnsCalculatorApi.getReturns({
          initialInvestment: parseInt(formData.investmentPerYear, 10),
          noYears: parseInt(formData.noYears, 10),
          incomeTax: parseInt(formData.incomeTax, 10) / 100,
          yoyGrowth: parseInt(formData.yoyGrowth, 10) / 100,
          failureRate: parseInt(formData.failureRate, 10) / 100,
          term: parseInt(formData.investmentTerm, 10),
        });
        if (!data) return;
        const values = data.results.reduce(
          (obj, result) => ({
            ...obj,
            investment: [...obj.investment, result.investment],
            newCompanies: [...obj.newCompanies, result.newCompanies],
            cumulativeCompanies: [...obj.cumulativeCompanies, result.cumulativeCompanies],
            taxRelief: [...obj.taxRelief, result.taxRelief],
            losses: [...obj.losses, result.losses],
            lossRelief: [...obj.lossRelief, result.lossRelief],
            cashFromExits: [...obj.cashFromExits, result.cashFromExits],
            cashOutflow: [...obj.cashOutflow, result.cashOutflow],
            cumulativeOutflow: [...obj.cumulativeOutflow, result.cumulativeOutflow],
            portfolioValue: [...obj.portfolioValue, result.portfolioValue],
            totalReturn: [...obj.totalReturn, result.totalReturn],
          }),
          { ...DEFAULT_CHART_VALUES },
        );

        setChartValues({
          ...values,
          totalTerm: data.totalTerm,
          co: formatThousands(data.maxOutgoings, 2),
          irr: data.irr,
          rp: formatThousands((data.totalReturn / (formData.investmentPerYear * formData.noYears)).toFixed(2), 2),
          voc: formatThousands(data.totalReturn, 2),
        });

        if (!chart) createChart(data.totalTerm);
      } catch (e) {
        console.error(e);
      }
    },
    [chart, createChart],
  );

  const handleSubmit = useCallback(
    async (values) => {
      setSubmitted(true);
      await getResults(values);
      globalThis.dataLayer.push({ event: PAGE_ACTIONS.RETURNS_CALCULATOR_FORM_SUBMITTED_SUCCESSFULLY });
    },
    [getResults],
  );

  const handleMediaQueryChange = useCallback(
    (isSmallDisplay: boolean) => {
      if (!isSmallDisplay && submitted && canvas) createChart(chartValues.totalTerm);
    },
    [chartValues.totalTerm, createChart, submitted],
  );

  return !fund.returnsCalculator.enabled ? null : (
    <>
      <Typography className={classes.mobileWarning} variant="h3" align="center">
        For best experience, please use on a desktop/laptop computer.
      </Typography>
      <div>
        <Typography variant="h1" gutterBottom align="center">
          Returns Calculator
        </Typography>
        <Typography variant="h4" gutterBottom align="center">
          Use this calculator to understand the potential benefits of investing in the {fund.name}.
        </Typography>
        <Typography variant="h4" gutterBottom align="center">
          Please note that this is for illustrative purposes only. If you are in any doubt about the availability of tax reliefs, or the tax treatment
          of your investment, you should obtain independent tax advice before making your investment
        </Typography>
      </div>
      <div className={classes.calculationContainer}>
        <Typography variant="h1">Investment</Typography>
        <Formik
          validationSchema={SCHEMA}
          initialValues={{
            investmentPerYear: 100000,
            noYears: 2,
            incomeTax: 45,
            yoyGrowth: 20,
            failureRate: 34,
            investmentTerm: 7,
          }}
          onSubmit={handleSubmit}
        >
          <Form>
            <FormGroup row>
              <Typography variant="h3">Your personal circumstances</Typography>
              <Typography variant="h3">Fund performance parameters</Typography>
            </FormGroup>
            <FormGroup row>
              <FormGroup>
                <Field name="investmentPerYear" moneyFormat component={NumberQuestion} prefix="£" label="New investment per year" />
                <br />
                <Typography variant="body2" paragraph>
                  The minimum investment per year is £10,000 and the maximum is £2,100,000.
                </Typography>
              </FormGroup>
              <FormGroup>
                <Field name="yoyGrowth" component={NumberQuestion} suffix="%" label="Year on year growth in the Nova portfolio." />
                <br />
                <Typography variant="body2" paragraph>
                  Historically, the market performs at 34%, and Nova&apos;s portfolio has performed at 36.5%. Nova are conservatively targeting 20%
                  based on a 3 year failure rate of 34%
                </Typography>
              </FormGroup>
            </FormGroup>
            <FormGroup row>
              <FormGroup>
                <Field name="noYears" component={NumberQuestion} label="Number of years of new investment" />
                <br />
                <Typography variant="body2" paragraph>
                  Investing over a number of years gives Nova the opportunity to maximise the spread of your investment across their portfolio.
                </Typography>
              </FormGroup>
              <FormGroup>
                <Field name="investmentTerm" component={NumberQuestion} label="Average term of each portfolio investment." />
                <br />
                <Typography variant="body2" paragraph>
                  This determines the no. years the underlying investment assets will be returned as cash to you.
                </Typography>
              </FormGroup>
            </FormGroup>
            <FormGroup row>
              <FormGroup>
                <Field name="incomeTax" component={NumberQuestion} suffix="%" label="Marginal income tax" />
                <br />
                <Typography variant="body2" paragraph>
                  This impacts on loss relief you receive on companies that fail.
                </Typography>
              </FormGroup>
              <FormGroup>
                <Field name="failureRate" component={NumberQuestion} label="Failure rate within 3 years in the portfolio." suffix="%" />
                <br />
                <Typography variant="body2" paragraph>
                  Historically Nova’s 3 year failure rate is at 34%, the market is at 92%.
                </Typography>
              </FormGroup>
            </FormGroup>
            <FormGroup>
              <Button variant="contained" type="submit">
                Submit
              </Button>
            </FormGroup>
          </Form>
        </Formik>
      </div>

      <Collapse in={submitted}>
        <div className={clsx(classes.hiddenReturns, { [classes.visibleReturns]: submitted })}>
          <Typography variant="h1" gutterBottom>
            Potential Returns
          </Typography>
          <div className={classes.returnsDetails}>
            <div>
              <Typography variant="h3">Internal Rate of Return</Typography>
              <Typography variant="h4" gutterBottom>
                {chartValues.irr}%
              </Typography>
            </div>
            <div>
              <Typography variant="h3">Returns per £1</Typography>
              <Typography variant="h4" gutterBottom>
                {chartValues.rp}
              </Typography>
            </div>
            <div>
              <Typography variant="h3">Cash Outgoings</Typography>
              <Typography variant="h4" gutterBottom>
                {chartValues.co}
              </Typography>
            </div>
            <div>
              <Typography variant="h3">Cash Returns</Typography>
              <Typography variant="h4" gutterBottom>
                {chartValues.voc}
              </Typography>
            </div>
          </div>
          <RotateDevice helperText="Please rotate your device to view the graph" onMediaQueryChange={handleMediaQueryChange}>
            <Typography variant="h3">Show me data about:</Typography>
            <div className={classes.buttonsContainer}>
              {isMobile ? (
                <Select
                  value={selectValues}
                  onChange={(e: any) => {
                    setSelectValues(e.target.value);
                    setButtonStates(
                      Object.keys(INITIAL_STATE).reduce<any>(
                        (prev, key: any) => ({
                          ...prev,
                          [key]: Boolean(e.target.value && e.target.value.includes(key)),
                        }),
                        {},
                      ),
                    );
                  }}
                  multiple
                  className={classes.selectInput}
                  inputProps={{ placeholder: "Please select one or more", className: classes.selectInput }}
                >
                  {Object.entries(OPTIONS).map(([key, value]) => (
                    <MenuItem key={key} value={key} className={clsx({ [classes[key]]: selectValues.includes(key) })}>
                      {value.label}
                    </MenuItem>
                  ))}
                </Select>
              ) : (
                Object.entries(OPTIONS).map(([key, value]) => (
                  <Button
                    key={key}
                    variant="text"
                    className={clsx(classes.dataButton, {
                      [classes[key]]: buttonStates[key],
                    })}
                    onClick={() => setButtonStates((s) => ({ ...s, [key]: !s[key] }))}
                  >
                    {value.label}
                  </Button>
                ))
              )}
            </div>
            <canvas ref={canvas} />
          </RotateDevice>
        </div>
      </Collapse>
    </>
  );
};

export default ReturnsCalculator;
