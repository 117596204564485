import { useCallback, useState } from "react";

const useToggle = <T extends boolean | null | undefined>(
  defaultValue: T,
): [state: T extends boolean ? boolean : T, toggleState: () => void, toggleOn: () => void, toggleOff: () => void] => {
  const [state, setState] = useState<boolean | T>(defaultValue);

  const toggle = useCallback(() => setState((s) => !s), []);
  const toggleOn = useCallback(() => setState(true), []);
  const toggleOff = useCallback(() => setState(false), []);

  return [state as T extends boolean ? boolean : T, toggle, toggleOn, toggleOff];
};

export default useToggle;
