import { FormGroup, FormLabel, makeStyles } from "@material-ui/core";
import { Field } from "formik";
import React, { useMemo } from "react";
import { FORM_FIELDS_DATA } from "../../utils/constants";
import { subtractDecimals } from "../../utils/helpers";
import NumberQuestion from "./NumberQuestion.component";
import SliderField from "./SliderField.component";

const { INVESTMENT_DETAILS_FORM } = FORM_FIELDS_DATA;

const useStyles = makeStyles(
  (theme) => ({
    sliderContainer: {
      flex: 3,
    },
    split: {
      "& > *:not(:last-child)": {
        marginBottom: theme.spacing(2.5),
      },
      [theme.breakpoints.down("md")]: {
        maxWidth: "100%",
        flexDirection: "column",
        "& > *": {
          width: "100%",
        },
        "& > *:not(:last-child)": {
          marginRight: 0,
        },
      },
    },
  }),
  { name: "SeisEisSplitComponent" },
);

const SeisEisSplit = ({
  field,
  form: { setFieldValue },
  row = false,
  seisProps,
  eisProps,
  seisEisProps,
  sliderProps,
  namePrefix,
  trackedCategory,
  label,
  ...props
}) => {
  const classes = useStyles(props);
  const seisName = useMemo(() => (namePrefix ? `${namePrefix}.seis` : INVESTMENT_DETAILS_FORM.seis.name), [namePrefix]);
  const eisName = useMemo(() => (namePrefix ? `${namePrefix}.eis` : INVESTMENT_DETAILS_FORM.eis.name), [namePrefix]);

  return (
    <>
      {label && <FormLabel className="fs-exclude">{label}</FormLabel>}
      <FormGroup row={row} className={classes.split}>
        <Field
          component={NumberQuestion}
          name={seisName}
          placeholder={INVESTMENT_DETAILS_FORM.seis.placeholder}
          label={INVESTMENT_DETAILS_FORM.seis.label}
          trackedCategory={trackedCategory}
          suffix="%"
          min="0"
          max="100"
          step={1}
          onChange={(e) => {
            const value = parseFloat(e.target.value);
            setFieldValue(seisName, value);
            setFieldValue(eisName, subtractDecimals(e.target.value));
          }}
          {...seisEisProps}
          {...seisProps}
        />
        <Field
          name={eisName}
          component={SliderField}
          trackedCategory={trackedCategory}
          row={row}
          trackInputProps={{ className: classes.sliderContainer }}
          track={false}
          onChange={(_event, value) => {
            setFieldValue(eisName, Number(value));
            setFieldValue(seisName, Number(100 - value));
          }}
          {...sliderProps}
        />
        <Field
          component={NumberQuestion}
          name={eisName}
          placeholder={INVESTMENT_DETAILS_FORM.eis.placeholder}
          label={INVESTMENT_DETAILS_FORM.eis.label}
          trackedCategory={trackedCategory}
          suffix="%"
          min="0"
          max="100"
          step={1}
          onChange={(e) => {
            const value = parseFloat(e.target.value);
            setFieldValue(seisName, subtractDecimals(e.target.value));
            setFieldValue(eisName, value);
          }}
          {...seisEisProps}
          {...eisProps}
        />
      </FormGroup>
    </>
  );
};

export default SeisEisSplit;
