import { CircularProgress, Dialog, DialogContent, DialogProps, DialogTitle, Link, makeStyles, Typography } from "@material-ui/core";
import { bulkAddShareTransactions, createDeploymentDocs } from "client/api/admin/shareTransactions.adminApi";
import { AdminContext, ADMIN_TYPES } from "client/context/admin.context";
import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { AdminHolding, LeanShareTransaction } from "server/services/shareTransaction/shareTransaction.types";
import ShareTransactionForm from "../../ShareTransactions/ShareTransactionForm.view";

interface Props extends Omit<DialogProps, "onClose"> {
  onClose(): void;
  shareTransactions: LeanShareTransaction[] | null;
}

const useStyles = makeStyles(
  (theme) => ({
    tabButtonsContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      height: "50px",
    },
    loadingContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: theme.spacing(4),
    },
  }),
  { name: "BulkShareTransactionsFormView" },
);

const BulkShareTransactionsForm: React.FC<Props> = ({ onClose, shareTransactions: initialShareTransactions, ...props }) => {
  const classes = useStyles(props);
  const { dispatch } = useContext(AdminContext);
  const [shareTransactions, setShareTransactions] = useState<LeanShareTransaction[]>([]);
  const [shareTransactionIndex, setShareTransactionIndex] = useState(0);
  const [loaded, setLoaded] = useState<boolean | null>(null);
  const [status, setStatus] = useState<ReactNode | null>(null);
  const [updatedShareTransactions, setUpdatedValues] = useState<LeanShareTransaction[]>([]);
  const isFinalShareTransaction = useMemo(() => shareTransactionIndex === shareTransactions.length - 1, [shareTransactionIndex, shareTransactions]);
  const currentShareTransaction = useMemo(
    () => updatedShareTransactions[shareTransactionIndex] || shareTransactions[shareTransactionIndex],
    [shareTransactionIndex, shareTransactions, updatedShareTransactions],
  );

  const handleReset = useCallback(() => {
    setShareTransactions([]);
    setShareTransactionIndex(0);
    setLoaded(null);
    setStatus(null);
    setUpdatedValues([]);
  }, []);

  useEffect(() => {
    setShareTransactions(initialShareTransactions || []);
  }, [initialShareTransactions]);

  useEffect(() => handleReset, [handleReset]);

  const resetOnClose = useCallback(
    (_e?: GenericObject, reason?: "backdropClick" | "escapeKeyDown") => {
      if (loaded === false && (reason === "backdropClick" || reason === "escapeKeyDown")) return;
      handleReset();
      onClose();
    },
    [handleReset, loaded, onClose],
  );

  const handleSubmit = useCallback(
    async (newShareTransactions) => {
      setLoaded(false);
      setStatus("Creating Share Transactions....");
      const holdings: AdminHolding[] | null = await bulkAddShareTransactions(newShareTransactions);
      if (!holdings?.length) {
        setLoaded(true);
        return setStatus("Failed to create Share Transactions.");
      }
      dispatch({ type: ADMIN_TYPES.BULK_UPDATE_HOLDINGS, payload: holdings });
      setStatus("Creating Allocation Sheets....");
      const res = await createDeploymentDocs(holdings.map((h) => h.shareTransactionId));
      setLoaded(true);
      if (!res) return setStatus("Error during generation.");
      setStatus(
        typeof res === "string" ? (
          <>
            Deployment sheets and documents have been created and are available here:
            <br />
            <Link href={res} target="_blank" rel="noreferrer noopener" variant="caption" align="center">
              {res}
            </Link>
          </>
        ) : (
          "We were not able to produce the documents for this deployment, but were able to generate the Payment Instruction Form."
        ),
      );
    },
    [dispatch],
  );

  return (
    <Dialog onClose={resetOnClose} {...props}>
      <DialogTitle>Add Share Transactions</DialogTitle>
      {typeof loaded === "boolean" ? (
        <DialogContent className={classes.loadingContainer}>
          {!loaded && (
            <>
              <CircularProgress />
              <br />
            </>
          )}
          <Typography variant="body2">{status}</Typography>
        </DialogContent>
      ) : (
        <DialogContent>
          <Typography variant="body1" paragraph>
            Deployments will be added to the portal with a status of “Ready for deployment”, investors will not be able to see these and will not be
            emailed until the deployment is changed to “Funds deployed”. The payment allocation sheet for mainspring will also be exported when these
            deployments are added.
          </Typography>
          <Typography align="center">
            Share Transaction {shareTransactionIndex + 1} of {shareTransactions.length}
          </Typography>
          <ShareTransactionForm
            isBulkAdd
            shareTransaction={currentShareTransaction}
            isFinalShareTransaction={isFinalShareTransaction}
            onPrevious={() => setShareTransactionIndex(shareTransactionIndex ? shareTransactionIndex - 1 : shareTransactionIndex)}
            onSubmit={(values) => {
              const newShareTransactions = [...updatedShareTransactions];
              newShareTransactions[shareTransactionIndex] = { ...values };
              setUpdatedValues(newShareTransactions);
              if (isFinalShareTransaction) {
                return handleSubmit(newShareTransactions);
              }
              return setShareTransactionIndex(shareTransactionIndex + 1);
            }}
          />
        </DialogContent>
      )}
    </Dialog>
  );
};

export default BulkShareTransactionsForm;
