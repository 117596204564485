import { Dialog, DialogContent, DialogProps, DialogTitle, FormGroup, Typography } from "@material-ui/core";
import { createPortfolio, deletePortfolio, updatePortfolio } from "client/api/admin/portfolios.adminApi";
import CrudFormButtons from "client/components/FormControls/CrudFormButtons.component";
import TextQuestion from "client/components/FormControls/TextQuestion.component";
import { AdminContext } from "client/context/admin.context";
import { Field, Form, Formik } from "formik";
import React, { FC, useCallback, useContext, useMemo } from "react";
import { LeanPortfolio } from "server/services/portfolio/portfolio.types";
import * as Yup from "yup";

interface Props extends DialogProps {
  portfolio?: LeanPortfolio | null;
  onClose(): any;
}

const SCHEMA = Yup.object({
  name: Yup.string().required("Please provide a name for this portfolio"),
  nominee: Yup.string().required("Please indicate the nominee for this portfolio"),
});

const PortfolioForm: FC<Props> = ({ onClose, open, portfolio }) => {
  const { dispatch } = useContext(AdminContext);

  const initialValues = useMemo(
    () => ({
      name: "",
      nominee: "",
      ...(portfolio || {}),
    }),
    [portfolio],
  );

  const handleSubmit = useCallback(
    async (values: typeof initialValues) => {
      let updatedPortfolio: LeanPortfolio | null;
      if (!portfolio) {
        updatedPortfolio = await createPortfolio(values);
      } else {
        updatedPortfolio = await updatePortfolio(values, portfolio._id);
      }
      if (updatedPortfolio) {
        dispatch({ type: "UPDATE_PORTFOLIO", payload: updatedPortfolio });
        onClose();
      }
    },
    [dispatch, onClose, portfolio],
  );

  const handleDelete = useCallback(async () => {
    if (!portfolio) return;
    const deletedId = await deletePortfolio(portfolio._id);
    if (deletedId) dispatch({ type: "DELETE_PORTFOLIO", payload: deletedId });
    onClose();
  }, [dispatch, onClose, portfolio]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle disableTypography>
        <Typography variant="h3">{portfolio ? `Edit ${portfolio.name}` : "Create Portfolio"}</Typography>
      </DialogTitle>
      <DialogContent>
        <Formik initialValues={initialValues} validationSchema={SCHEMA} onSubmit={handleSubmit} enableReinitialize>
          {() => (
            <Form data-testid="portfolioForm">
              <FormGroup>
                <Field name="name" component={TextQuestion} label="Portfolio Name" placeholder="Name" />
              </FormGroup>
              <FormGroup>
                <Field name="nominee" component={TextQuestion} label="Portfolio Nominee" placeholder="Nominee" />
              </FormGroup>
              <CrudFormButtons isEdit={Boolean(portfolio)} handleDelete={handleDelete} onClose={() => onClose()} />
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default PortfolioForm;
