import { makeStyles, Tab, Tabs } from "@material-ui/core";
import InfoBanner from "client/components/InfoBanner.component";
import { ROUTES, USER_ROLE } from "client/utils/constants";
import React, { FC, ReactNode, useCallback, useContext, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { AuthContext } from "../../context/auth.context";
import { ConfigContext } from "../../context/config.context";
import ApplicationsTab from "./Applications/ApplicationsTab.view";
import CertificateSubmissionsTab from "./CertificateSubmissions/CertificateSubmissionsTab.view";
import CompanyTab from "./Companies/CompanyTab.view";
import DeploymentsTab from "./Deployments/DeploymentsTab.view";
import TransactionsPlannerTab from "./Deployments/InvestorAllocations";
import ExpectedPaymentsTab from "./ExpectedPayments/ExpectedPaymentsTab.view";
import FundsTab from "./Funds/FundsTab.view";
import PortfolioTab from "./Portfolios/PortfolioTab.view";
import ShareTransactionTab from "./ShareTransactions/ShareTransactionTab.view";
import StatisticsTab from "./Statistics/StatisticsTab.view";
import SubscriptionsTab from "./Subscriptions/SubscriptionsTab.view";
import UserDocumentsTab from "./UserDocuments/UserDocumentsTab.view";
import UsersTab from "./Users/UsersTab.view";

interface AdminPanelTab {
  label: string;
  value: string;
}

const useStyles = makeStyles(
  (theme) => ({
    contentContainer: {
      display: "flex",
      flexDirection: "column",
      maxHeight: "calc(100vh - (95px + 50px))", // (viewport height) - ((navbar height on desktop) + (tabs height on desktop and mobile))
      [theme.breakpoints.down("md")]: {
        maxHeight: "calc(100vh - (75px + 50px))", // (viewport height) - ((navbar height on mobile) + (tabs height on desktop and mobile))
      },
    },
  }),
  { name: "AdminPanelIndex" },
);

/**
 * An array of tabs the admin panel can contain.
 * @see {@link AdminPanelTab}
 */

const ADMIN_PANEL_TABS: AdminPanelTab[] = [
  {
    label: "Users",
    value: "users",
  },
  {
    label: "Companies",
    value: "companies",
  },
  {
    label: "Deployments",
    value: "deployments",
  },
  {
    label: "Share Transactions",
    value: "shareTransactions",
  },
  {
    label: "Certificate Submissions",
    value: "certificateSubmissions",
  },
  {
    label: "Subscriptions",
    value: "subscriptions",
  },
  {
    label: "Applications",
    value: "applications",
  },
  {
    label: "Portfolios",
    value: "portfolios",
  },
  {
    label: "Expected Payments",
    value: "expected-payments",
  },
  {
    label: "Statistics",
    value: "statistics",
  },
  {
    label: "Documents",
    value: "documents",
  },
  {
    label: "Funds",
    value: "funds",
  },
];

const MANAGER_PANEL_TABS: AdminPanelTab[] = [
  {
    label: "Users",
    value: "users",
  },

  {
    label: "Share Transactions",
    value: "shareTransactions",
  },
  {
    label: "Certificate Submissions",
    value: "certificateSubmissions",
  },
  {
    label: "Subscriptions",
    value: "subscriptions",
  },
  {
    label: "Applications",
    value: "applications",
  },
];

const DEFAULT_ROUTE = ROUTES.ADMIN_PANEL;

interface Props {}

const AdminPanel: FC<Props> = (props) => {
  const classes = useStyles(props);
  const history = useHistory();
  const { tabValue } = useParams<{ tabValue: string }>();

  const { fund } = useContext(ConfigContext);
  const {
    state: { user },
  } = useContext(AuthContext);

  const isReadOnly: boolean = useMemo(() => {
    return user?.role === USER_ROLE.READ_ONLY_ADMIN;
  }, [user]);

  const DISPLAY_PANEL_TABS = useMemo(() => {
    return user?.role === USER_ROLE.MANAGER ? MANAGER_PANEL_TABS : ADMIN_PANEL_TABS;
  }, [user]);

  const shouldBeDisabled = useMemo(() => {
    return !fund.adminPanel?.enabled || !fund.adminPanel?.enabledPanels?.length;
  }, [fund]);

  /**
   * Check whether or a not a tab should be enabled.

   * All tabs are disabled by default, and enabled tabs need to be directly
   * specified.
   *
   * @param {string} tabName Internal name of a tab (@see {@link ADMIN_PANEL_TABS})
   * @returns {boolean} *true* if panel should be enabled, *false* otherweise
   */
  const panelEnabled = useCallback(
    (tabName: string) => {
      if (tabName === "certificateSubmissions" && !fund.certificateSubmissions?.enabled) return false;
      if (!isReadOnly) return true;
      return fund.adminPanel.enabledPanels?.includes(tabName) as boolean;
    },
    [isReadOnly, fund],
  );

  /**
   * Render the admin panel tabs.
   *
   * This function also performs some checks to make sure the URL entered
   * actually specifies a *valid* tab (e.g. enabled, or actually exists) -
   * if not, then the user will be redirected.
   *
   * @returns Admin Panel tabs
   */
  const renderTabs = useMemo<ReactNode[] | null>(() => {
    const validTabs = DISPLAY_PANEL_TABS.flatMap((tab: AdminPanelTab) => tab.value);
    // redirects any invalid panel urls
    if (!validTabs.includes(tabValue)) {
      history.push(DEFAULT_ROUTE);
      return null;
    }

    return DISPLAY_PANEL_TABS.map<ReactNode>((tab: AdminPanelTab) => {
      if (!panelEnabled(tab.value)) return null;
      return <Tab key={`${tab.value}Tab`} label={tab.label} value={tab.value} />;
    });
  }, [panelEnabled, history, tabValue]);

  /**
   * React Element to conditionally render it's children if the
   * given tab (@param value) is both enabled and currently
   * selected.
   *
   * @param {React.ReactNode} children Children elements
   * @param {string} value Internal name of the tab (@see {@link ADMIN_PANEL_TABS})
   */
  const TabPanel = useCallback(
    ({ children, value }) => {
      const isCurrent: boolean = tabValue === value;

      // don't display any content & reroute if panel disabled
      if (isCurrent && !panelEnabled(value)) {
        history.push(DEFAULT_ROUTE);
        return null;
      }

      return isCurrent && children;
    },
    [tabValue, panelEnabled, history],
  );

  // called when a tab is clicked
  const onTabChange = useCallback(
    (_e: any, tabName: string) => {
      // safety check, shouldn't have to reroute in most cases
      panelEnabled(tabName) ? history.push(`${DEFAULT_ROUTE}/${tabName}`) : history.push(DEFAULT_ROUTE);
    },
    [history, panelEnabled],
  );

  // always reroute if the admin panel is disabled
  if (isReadOnly && shouldBeDisabled) {
    history.push(ROUTES.DASHBOARD);
    return null;
  }

  return (
    <>
      {isReadOnly && <InfoBanner>You are a read only admin user, some functionality may be disabled for you.</InfoBanner>}

      <Tabs onChange={onTabChange} value={tabValue}>
        {renderTabs}
      </Tabs>

      <div className={classes.contentContainer}>
        <TabPanel value="users">
          <UsersTab readOnly={isReadOnly} />
        </TabPanel>
        <TabPanel value="companies">
          <CompanyTab readOnly={isReadOnly} />
        </TabPanel>
        <TabPanel value="shareTransactions">
          <ShareTransactionTab readOnly={isReadOnly} />
        </TabPanel>
        <TabPanel value="certificateSubmissions">
          <CertificateSubmissionsTab readOnly={isReadOnly} />
        </TabPanel>
        <TabPanel value="deployments">
          <DeploymentsTab />
        </TabPanel>
        <TabPanel value="transactions-planner">
          <TransactionsPlannerTab readOnly={isReadOnly} />
        </TabPanel>
        <TabPanel value="subscriptions">
          <SubscriptionsTab />
        </TabPanel>
        <TabPanel value="applications">
          <ApplicationsTab readOnly={isReadOnly} />
        </TabPanel>
        <TabPanel value="portfolios">
          <PortfolioTab />
        </TabPanel>
        <TabPanel value="expected-payments">
          <ExpectedPaymentsTab />
        </TabPanel>
        <TabPanel value="statistics">
          <StatisticsTab />
        </TabPanel>
        <TabPanel value="documents" readOnly={isReadOnly}>
          <UserDocumentsTab />
        </TabPanel>
        <TabPanel value="funds">
          <FundsTab />
        </TabPanel>
      </div>
    </>
  );
};

export default AdminPanel;
