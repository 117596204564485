import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  (theme) =>
    createStyles({
      circularProgress: {
        position: "absolute",

        top: "25%",
        left: "44%",
      },
      tableFooter: {
        textAlign: "left",
        margin: theme.spacing(1.25),
        "& .MuiButton-root:not(:last-child)": {
          marginRight: 20,
        },
      },
    }),
  { name: "AdminPanelUseStylesHook" },
);
