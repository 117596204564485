import { Link, LinkProps, makeStyles } from "@material-ui/core";
import React, { FC, useMemo } from "react";
import { AdminCompany, CompanyLink, CompanyWithHoldings } from "server/services/company/company.types";
import { LeanDocument } from "mongoose";

interface Props extends LinkProps {
  company: CompanyWithHoldings | AdminCompany;
  exclude?: string[];
  renderWebsitesTruthfully?: boolean;
}

const useStyles = makeStyles(
  () => ({
    linkContainer: { display: "flex", flexDirection: "column" },
  }),
  { name: "CompanyLinkListComponent" },
);

/**
 * Component for rendering a list of links belonging to a company.
 *
 * @component
 * @property {AdminCompany} company The desired company
 * @property {string[]} exclude A list of link paths to exlcude from rendering
 * @property {boolean} renderWebsitesTruthfully Render website links as a 'cleaned' URL
 * rather than a named link
 *
 * @example
 * <CompanyLinkList company={company} exclude={["websiteLink", "video"]} />
 */

const CompanyLinkList: FC<Props> = ({ company, exclude, renderWebsitesTruthfully, ...props }) => {
  const classes = useStyles(props);

  const commonLinkProps = useMemo(
    () => ({
      target: "_blank",
      rel: "noopener noreferrer",
      onClick: () =>
        globalThis.dataLayer.push({
          event: `company link clicked`,
          eventProps: { companyName: company.companyName },
        }),
    }),
    [company.companyName],
  );

  /**
   * Render company links
   *
   * Handling multiple links (Liam):
   * - If there's only 1 link (for a type, e.g. websiteLink), then show the "default" name
   * (e.g. "Website").
   * - If there's multiple links (for a type, e.g. websiteLink), then show the link
   * name (e.g. "Marketing Site", "Business Site").
   */
  const links = useMemo(() => {
    const linkEntries: React.ReactNode[][] = [];

    // website links
    if (typeof company.websiteLink !== "string" && company.websiteLink?.length && !exclude?.includes("websiteLink")) {
      const singleLink: boolean = company.websiteLink.length === 1;

      linkEntries.push(
        company.websiteLink.map((websiteLink) => {
          return (
            <Link key={websiteLink.name} href={websiteLink.url} {...commonLinkProps} {...props}>
              {renderWebsitesTruthfully
                ? websiteLink.url.replace(/^https:\/\/(?:www\.)?(.+?)\/?$/g, "www.$1")
                : singleLink || !websiteLink.name
                ? "Website"
                : websiteLink.name}
            </Link>
          );
        }),
      );
    }

    // video links
    const shouldRenderVideoLinks = (): boolean | undefined =>
      typeof company.video !== "string" && company.video && company.video!.length > 0 && !exclude?.includes("video");

    if (shouldRenderVideoLinks()) {
      const singleLink: boolean = company.video!.length === 1;

      linkEntries.push(
        company.video!.map((videoLink) => (
          <Link key={videoLink.name} href={videoLink.url} {...commonLinkProps} {...props}>
            {singleLink || !videoLink.name ? "Video" : videoLink.name}
          </Link>
        )),
      );
    }

    // social links
    const shouldRenderSocialLinks = (): boolean => company.socials && !exclude?.includes("socials");

    const areLinksInvalid = (links: CompanyLink[] | string | LeanDocument<CompanyLink>[]): boolean =>
      !Array.isArray(links) || links.length === 0 || typeof links[0] === "string";

    const renderLinkContent = (link: CompanyLink | LeanDocument<CompanyLink>, category: string, singleLink: boolean): string =>
      singleLink || !link.name ? getCategoryName(category) : link.name;

    const getCategoryName = (category: string): string => {
      switch (category) {
        case "facebook":
          return "Facebook";
        case "twitter":
          return "Twitter";
        case "instagram":
          return "Instagram";
        case "linkedIn":
          return "LinkedIn";
        default:
          return "Social Link";
      }
    };

    if (shouldRenderSocialLinks()) {
      linkEntries.push(
        Object.entries(company.socials).map(([category, links]: [string, LeanDocument<CompanyLink>[]]) => {
          if (areLinksInvalid(links)) {
            return null;
          }
          const singleLink: boolean = links.length === 1;

          return links.map((link: CompanyLink | LeanDocument<CompanyLink>) => (
            <Link key={link.name} href={link.url} {...commonLinkProps} {...props}>
              {renderLinkContent(link, category, singleLink)}
            </Link>
          ));
        }),
      );
    }

    return linkEntries;
  }, [commonLinkProps, company.socials, company.video, company.websiteLink, exclude, props, renderWebsitesTruthfully]);

  // render the link entries as a vertically aligned list/column
  return (
    <div className={classes.linkContainer}>
      {links.map((group) => {
        if (group.length) return group;
      })}
    </div>
  );
};

export default CompanyLinkList;
