import { FC, useContext, useEffect, useMemo, useState } from "react";
import { Button, Container, makeStyles, Typography } from "@material-ui/core";
import { useHistory, useRouteMatch } from "react-router-dom";
import * as AdminApplicationApi from "../../api/admin/application.adminApi";
import ngcLogo from "../../assets/ngc_logo_blk.svg";
import { ApplicationContext, APPLICATION_TYPES } from "../../context/application.context";
import AdminConfirm from "./AdminApplicationConfirm.view";

const useStyles = makeStyles(
  (theme) => ({
    body: {
      minHeight: "70vh",
      width: "100%",
      padding: "0 20vw",
      marginTop: "50px",

      [theme.breakpoints.down("sm")]: {
        padding: "0 30px",
      },
    },
    hellosignLogo: {
      width: 180,
      marginLeft: -20,
      marginBottom: -40,
      [theme.breakpoints.down("sm")]: {
        marginBottom: "unset",
      },
    },
    imageContainer: {
      display: "flex",
      justifyContent: "center",
    },
    iAContainer: {
      width: "100%",
      height: "70vh",
      margin: `${theme.spacing(4)}px 0`,
      position: "relative",
      display: "flex",
      flexDirection: "column",
    },
    previewRoot: {
      display: "flex",
      flexDirection: "column",
      height: "70vh",
      alignItems: "center",
      margin: `${theme.spacing(4)}px 0`,
      flex: 1,
      justifyContent: "center",
    },
    submitButton: {
      width: "100%",
    },
  }),
  { name: "AdminPreviewView" },
);

const AdminPreview: FC = (props) => {
  const classes = useStyles(props);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [preview, setPreview] = useState<string | null>(null);
  const { application, dispatch } = useContext(ApplicationContext);

  const match = useRouteMatch<{ applicationId: string }>();
  const applicationId = useMemo(() => match.params.applicationId, [match.params.applicationId]);
  const history = useHistory();

  useEffect(() => {
    if (!application) {
      (async () => {
        const adminApplication = await AdminApplicationApi.getApplication(applicationId);
        dispatch({ type: APPLICATION_TYPES.SET_APPLICATION, payload: adminApplication });
      })();
    }
  }, [application, dispatch, applicationId]);

  useEffect(() => {
    if (application && application._id) {
      (async () => {
        const previewPdf = await AdminApplicationApi.getPreview(application._id);
        setPreview(previewPdf);
      })();
    }
  }, [application]);

  return (
    <Container maxWidth="md">
      <Typography variant="h1" align="center">
        Application Preview
      </Typography>

      {!preview ? (
        <div data-testid="previewLoading" className={classes.previewRoot}>
          <div className={classes.imageContainer}>
            <img src={ngcLogo} width="80%" height="100%" alt="Nova loading" />
          </div>
          <br />
          <Typography variant="body1">Generating preview...</Typography>
        </div>
      ) : (
        <iframe title="application-preview" src={preview} className={classes.iAContainer} />
      )}
      <Button className={classes.submitButton} onClick={() => setOpenConfirm(true)}>
        Submit
      </Button>
      <AdminConfirm
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        submit={async () => {
          const success = await AdminApplicationApi.submitApplication(applicationId);
          if (success) {
            setOpenConfirm(false);
            history.push("/admin/panel/applications");
          }
        }}
      />
    </Container>
  );
};

export default AdminPreview;
