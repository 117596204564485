import axios from 'axios';
import { API_KEYS } from '../utils/constants';

const CRAFTY_CLICKS_URL = 'https://pcls1.craftyclicks.co.uk/json/rapidaddress';

export const getAddressFromPostcode = async postcode => {
  try {
    const parameters = {
      postcode,
      key: API_KEYS.CRAFTY_CLICK,
      response: 'data_formatted',
    };

    const resp = await axios.post(CRAFTY_CLICKS_URL, parameters);
    if (resp.status === 200) {
      return {
        success: true,
        data: resp.data,
      };
    }
    // if craftyclicks api is down
    return {
      success: false,
    };
  } catch (e) {
    return {
      success: false,
    };
  }
};
