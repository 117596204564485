import { makeStyles, Paper, PaperProps, Typography } from "@material-ui/core";
import Divider from "client/components/Divider.component";
import clsx from "clsx";
import React, { FC, ReactNode } from "react";

interface Props {
  title: ReactNode;
  hideTitle?: boolean;
  titleAction?: ReactNode;
  paperProps?: PaperProps;
  className?: string;
}

const useStyles = makeStyles(
  (theme) => ({
    dividerHide: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    summaryItem: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    summaryItemContent: {
      display: "flex",
      flexGrow: 1,
      justifyContent: "center",
      overflow: "hidden",
      padding: theme.spacing(4, 2.25),
      backgroundColor: theme.palette.grey.main,
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(2, 1.125),
        borderBottom: `${theme.spacing(0.125)}px solid ${theme.palette.common.black}`,
      },
    },
    summaryItemTitle: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingBottom: theme.spacing(1.5),
      backgroundColor: theme.palette.common.white,
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(0, 1.125, 1.5),
      },
    },
    summaryItemTitleHide: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  }),
  { name: "SummaryItemComponent" },
);

const SummaryItem: FC<Props> = ({ title, titleAction, className, hideTitle = false, paperProps = {}, ...props }) => {
  const classes = useStyles(props);

  return (
    <Paper elevation={0} {...paperProps} className={clsx(classes.summaryItem, paperProps.className, className)}>
      <div className={clsx(classes.summaryItemTitle, { [classes.summaryItemTitleHide]: hideTitle })}>
        <Typography variant="h3">{title}</Typography>
        {titleAction}
      </div>
      <Divider size="medium" className={clsx({ [classes.dividerHide]: hideTitle })} />
      <div className={classes.summaryItemContent}>{props.children}</div>
    </Paper>
  );
};

export default SummaryItem;
