import { Button, Container, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    whiteSpace: "pre-wrap",
  },
}));

const BasicPageTemplate = ({ title, body, onClick, buttonText, isBtnDisabled, className, ...props }) => {
  const classes = useStyles(props);
  return (
    <Container maxWidth="md" className={clsx([classes.container, className, "fs-exclude"])} {...props}>
      <Typography variant="h1" align="center" gutterBottom>
        {title}
      </Typography>
      <Typography variant="body1" align="center" paragraph>
        {body}
      </Typography>
      <br />
      <Button disabled={isBtnDisabled} onClick={onClick} size="large" data-testid="basicPageTemplateButton">
        {buttonText}
      </Button>
    </Container>
  );
};

BasicPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  isBtnDisabled: PropTypes.bool,
  className: PropTypes.string,
};

export default BasicPageTemplate;
