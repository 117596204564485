import fileDownload from "js-file-download";
import { BaseDeploymentDeal, CompanyValuation } from "server/services/company/company.types";
import notifications from "../../utils/notifications";
import { ipAxios } from "../ipAxios";

export const getCompanies = async (onChangeObject?: GenericObject) => {
  try {
    const res = await ipAxios.get(`/admin/companies/`, { params: onChangeObject });
    return { data: res.data, count: res.headers["x-count"] };
  } catch (error: any) {
    if (error.message !== "duplicate request cancelled") {
      notifications.error(error.message, error);
    }
  }
};

export const downloadCompanyData = async () => {
  try {
    const res = await ipAxios.get<Blob>("/admin/companies/download", {
      responseType: "blob",
    });
    if (res.status === 200) {
      fileDownload(new Blob([res.data]), `company-data-${Date.now()}.csv`);
      return { success: true, data: res.data };
    }
    return { success: false };
  } catch (error: any) {
    notifications.genericError(error);
    return { success: false };
  }
};

export const saveCompany = async (values: GenericObject, companyId: string = "") => {
  try {
    const data = new FormData();
    data.append("body", JSON.stringify(values));

    if (values.companyLogo && values.companyLogo.constructor === File) {
      data.append("companyLogo", values.companyLogo, values.companyLogo.name);
    }

    if (values.advancedAssurance && values.advancedAssurance.seis.key?.constructor === File) {
      data.append("advancedAssurance.seis.key", values.advancedAssurance.seis.key, values.advancedAssurance.seis.key.name);
    }

    if (values.advancedAssurance && values.advancedAssurance.eis.key?.constructor === File) {
      data.append("advancedAssurance.eis.key", values.advancedAssurance.eis.key, values.advancedAssurance.eis.key.name);
    }

    if (values.team) {
      values.team.forEach((teamMember: GenericObject, index: number) => {
        if (teamMember.image?.constructor === File) {
          data.append(`team[${index}].image`, teamMember.image, teamMember.image.name);
        }
      });
    }

    const res = await ipAxios.post(`/admin/companies/${companyId}`, data);
    return res.data;
  } catch (error: any) {
    notifications.error(error.message, error);
    return false;
  }
};

export const removeCompany = async (companyId: string) => {
  try {
    const res = await ipAxios.delete(`/admin/companies/${companyId}`);

    return res.data;
  } catch (error: any) {
    notifications.genericError(error);
    return;
  }
};

export const getIcRounds = async (companyId: string) => {
  try {
    const res = await ipAxios.get(`/admin/companies/${companyId}/icRounds`);
    return res.data;
  } catch (error: any) {
    notifications.error(error.message, error);
    return [];
  }
};

export const getCompanyById = async (companyId: string) => {
  try {
    const res = await ipAxios.get(`/admin/companies/${companyId}`);
    return res.data;
  } catch (error: any) {
    notifications.error(error.message, error);
    return null;
  }
};

export const getTransactionPlannerCompanies = async () => {
  try {
    const res = await ipAxios.get("/admin/companies/transactionPlanner");
    return res.data;
  } catch (error: any) {
    notifications.error(error.message, error);
    return null;
  }
};

/**
 * @param {string} companyId
 * @param {string} seisEIS
 */
export const downloadAdvancedAssurance = async (companyId: string, seisEIS: string) => {
  try {
    const res = await ipAxios.get(`/admin/companies/${companyId}/advancedAssurance/download`, { params: { seisEIS }, responseType: "blob" });
    fileDownload(res.data, `advanced-assurance-${seisEIS}-${companyId}.pdf`);
    return true;
  } catch (error: any) {
    notifications.error(error.message, error);
    return null;
  }
};

/**
 * @param {string} companyId
 * @param {string} date
 */
export const getCompanyValuationAtDate = async (companyId: string, date: string) => {
  try {
    const res = await ipAxios.get<CompanyValuation[]>(`/admin/companies/${companyId}/valuation`, {
      params: { date },
      headers: { "X-Disable-Duplicate": 1 },
    });
    return res.data;
  } catch (error: any) {
    notifications.error(error.message, error);
    return null;
  }
};

export const getDeals = async () => {
  try {
    const res = await ipAxios.get<BaseDeploymentDeal[]>("/admin/companies/deals");
    return { deals: res.data, count: res.headers["x-count"] };
  } catch (error: any) {
    notifications.error(error.message, error);
    return null;
  }
};
