import { Tab, Tabs } from "@material-ui/core";
import { getTransactionPlannerCompanies } from "client/api/admin/companies.adminApi";
import { getFunds } from "client/api/admin/funds.adminApi";
import { AdminContext, ADMIN_TYPES } from "client/context/admin.context";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { DeploymentProvider } from "./deployment.context";
import TransactionsPlannerTab from "./InvestorAllocations";
import OpenDealsTab from "./OpenDeals";

const CURRENT_TAB_REGEX = /\/(?<currentTab>[^/]+)$/;

const VALID_TABS = ["deals", "allocations"] as const;

const DeploymentsTab: React.FC = () => {
  const { url } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const { dispatch: adminDispatch } = useContext(AdminContext);

  const currentTab = useMemo(() => CURRENT_TAB_REGEX.exec(location.pathname)!.groups!.currentTab, [location.pathname]);

  useEffect(() => {
    if (!VALID_TABS.includes(currentTab as any)) {
      history.push(`${url}/deals`);
    }
  }, [currentTab, history, url]);

  useEffect(() => {
    (async () => {
      const res = await getFunds();
      if (res) adminDispatch({ type: ADMIN_TYPES.SET_FUNDS, payload: res });
    })();
    (async () => {
      const companies = await getTransactionPlannerCompanies();
      adminDispatch({ type: ADMIN_TYPES.SET_ALL_COMPANIES, payload: companies });
    })();
  }, [adminDispatch]);

  const handleTabChange = useCallback((_e: React.ChangeEvent<{}>, value: string) => history.push(`${url}/${value}`), [history, url]);

  return (
    <>
      <Tabs value={currentTab} onChange={handleTabChange} variant="standard" centered>
        <Tab value="deals" label="Open Deals" />
        <Tab value="allocations" label="Investor Allocations" />
      </Tabs>
      <Switch>
        <Route path={`${url}/deals`} component={OpenDealsTab} />
        <Route path={`${url}/allocations`} component={TransactionsPlannerTab} />
      </Switch>
    </>
  );
};

export default () => (
  <DeploymentProvider>
    <DeploymentsTab />
  </DeploymentProvider>
);
