import notifications from "../../utils/notifications";
import { ipAxios } from "../ipAxios";

const onboard = async (values) => {
  try {
    const { status, data } = await ipAxios.post(`/advisor/onboarding`, values);
    if (status === 200) {
      return {
        success: true,
        data,
      };
    }
    return {
      success: false,
    };
  } catch (error) {
    notifications.genericError(error);
    return { success: false };
  }
};

const getAdvisorsPersonalDetails = async () => {
  try {
    const res = await ipAxios.get(`/advisor/onboarding/personalDetails`);
    return res.data;
  } catch (error) {
    notifications.genericError(error);
    return false;
  }
};

export default {
  onboard,
  getAdvisorsPersonalDetails,
};
