import { FC, useEffect, useState } from "react";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import { Button, Container, Grid, Link, makeStyles, Typography, Avatar } from "@material-ui/core";
import { AuthContextUser } from "server/services/user/user.types";
import { getAllDocuments, downloadDocument } from "client/api/document.api";
import { formatDateString } from "client/utils/helpers";
import _ from "lodash";

interface Props {
  user?: AuthContextUser;
}

interface Document {
  _id: string;
  title: string;
  accessRole: string;
  file: string;
  versionNumber?: string;
  coverImage: string;
  date: Date;
  tags?: string[];
  downloadedBy?: string[];
}

interface DocumentOldCardProps {
  documents: Document[];
  classes: Record<string, string>;
  documentDownload: (documentId: string, filename: string) => Promise<void>;
}

interface DocumentCardProps {
  documents: Document[];
  checkDownloaded: boolean;
  classes: Record<string, string>;
  documentDownload: (documentId: string, filename: string) => Promise<void>;
}

const useStyles = makeStyles((theme) => ({
  updateContainer: {
    backgroundColor: theme.palette.grey.dark,
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  documentGridContainer: {
    padding: theme.spacing(3),
  },
  documentCard: {
    backgroundColor: "#190A00",
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: `6px 6px 0px ${theme.palette.primary.main}`,
  },
  documentCardDownloaded: {
    backgroundColor: theme.palette.white.main,
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0px 3px 12px #00000014",
  },
  documentCardOld: {
    backgroundColor: "transparent",
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "2px solid #190A01",
  },
  grouped: {
    display: "flex",
    justifyCotent: "center",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: "#190A00",
  },
  avatarDownloaded: {
    backgroundColor: theme.palette.white.main,
    color: theme.palette.secondary.dark,
  },
  avatarOld: {
    backgroundColor: "transparent",
    color: theme.palette.secondary.dark,
  },
  fontNew: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
  },
  cardText: {
    color: theme.palette.white.main,
    fontWeight: "normal",
    marginLeft: theme.spacing(1),
  },
  cardTextDate: {
    color: theme.palette.white.main,
    marginLeft: theme.spacing(1),
  },
  cardTextDownloaded: {
    color: theme.palette.secondary.dark,
    fontWeight: "normal",
    marginLeft: theme.spacing(1),
  },
  cardTextDateDownloaded: {
    color: theme.palette.secondary.dark,
    marginLeft: theme.spacing(1),
  },
  buttonLink: {
    backgroundColor: "#190A00",
    color: `${theme.palette.primary.main} !important`,
  },
  buttonLinkDownloaded: {
    backgroundColor: theme.palette.white.main,
  },
  buttonLinkOld: {
    backgroundColor: "transparent",
  },
}));

const sortByDateDescending = (data: any) => _.orderBy(data, [(item) => new Date(item.date)], ["desc"]);

const DocumentCardNew: FC<DocumentCardProps> = ({ documents, checkDownloaded, classes, documentDownload }) => {
  return (
    <Grid item md={6} xs={12}>
      <Grid container direction="row" className={checkDownloaded ? classes.documentCardDownloaded : classes.documentCard}>
        <Grid md={9} container className={classes.grouped}>
          <Grid item>
            <Avatar className={checkDownloaded ? classes.avatarDownloaded : classes.avatar}>
              <DescriptionOutlinedIcon fontSize="large" />
            </Avatar>
          </Grid>
          <Grid item>
            <Grid>
              {!checkDownloaded && (
                <Typography variant="h6" className={classes.fontNew}>
                  {" "}
                  NEW{" "}
                </Typography>
              )}
              <Grid container alignItems="baseline">
                <Typography variant="h5" className={checkDownloaded ? classes.cardTextDownloaded : classes.cardText}>
                  {documents[0].title} -
                </Typography>
                <Typography variant="h5" className={checkDownloaded ? classes.cardTextDateDownloaded : classes.cardTextDate}>
                  {formatDateString(String(documents[0].date), "MMMM YYYY")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={3}>
          <Link
            component={Button}
            className={checkDownloaded ? classes.buttonLinkDownloaded : classes.buttonLink}
            underline="always"
            onClick={() => documentDownload(documents[0]._id, documents[0].title + ".pdf")}
          >
            Download
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

const DocumentCardOld: FC<DocumentOldCardProps> = ({ documents, classes, documentDownload }) => {
  return (
    <Grid item md={6} xs={12}>
      {documents.length > 1 ? (
        <Grid container direction="row" className={classes.documentCardOld}>
          <Grid md={9} container className={classes.grouped}>
            <Grid item>
              <Avatar className={classes.avatarOld}>
                <DescriptionOutlinedIcon fontSize="large" />
              </Avatar>
            </Grid>
            <Grid item>
              <Grid>
                <Grid container alignItems="baseline">
                  <Typography variant="h5" className={classes.cardTextDownloaded}>
                    {documents[1].title} -
                  </Typography>
                  <Typography variant="h5" className={classes.cardTextDateDownloaded}>
                    {formatDateString(String(documents[0].date), "MMMM YYYY")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={3}>
            <Link
              component={Button}
              className={classes.buttonLinkOld}
              underline="always"
              onClick={() => documentDownload(documents[1]._id, documents[1].title + ".pdf")}
            >
              Download
            </Link>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  );
};

const InvestorUpdates: FC<Props> = ({ user }) => {
  const classes = useStyles();
  const [documents, setDocuments] = useState<any>([]);

  const getUpdatedDocuments = async () => {
    const response = await getAllDocuments("investorUpdateDocument");
    if (response) {
      setDocuments(sortByDateDescending(response.data));
    }
  };

  const documentDownload = async (documentId: string, filename: string) => {
    const response = await downloadDocument(documentId, filename);
    if (response) {
      await getUpdatedDocuments();
    }
  };

  useEffect(() => {
    getUpdatedDocuments();
  }, []);

  const checkDownloaded = documents?.[0]?.downloadedBy.includes(user?.userId) ?? false;

  return documents.length ? (
    <Container className={classes.updateContainer}>
      <Typography variant="h4" style={{ fontWeight: "bold" }}>
        INVESTOR UPDATES
      </Typography>
      <br />
      <Grid container justify="flex-start" spacing={2}>
        <DocumentCardNew checkDownloaded={checkDownloaded} documents={documents} classes={classes} documentDownload={documentDownload} />
        <DocumentCardOld documents={documents} classes={classes} documentDownload={documentDownload} />
      </Grid>
    </Container>
  ) : null;
};

export default InvestorUpdates;
