import { Checkbox, FormControlLabel, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import { ErrorMessage } from "formik";
import React from "react";
import { fromCamelCase } from "../../utils/helpers";
import TrackInput from "../TrackInput.component";
import ErrorText from "../UIKit/ErrorText.component";

const useStyles = makeStyles(
  (theme) => ({
    label: { ...theme.typography.body3 },
  }),
  { name: "CheckboxQuestionComponent" },
);

interface CheckboxQuestionProps {
  field: {
    name: string;
    value: boolean;
  };
  label?: string;
  trackedCategory: string;
  className?: string;
}

const CheckboxQuestion: React.FC<CheckboxQuestionProps> = ({ field, label, trackedCategory, ...props }) => {
  const classes = useStyles(props);

  return (
    <TrackInput category={trackedCategory} label={fromCamelCase(field.name)} value={field.value}>
      <FormControlLabel
        control={
          <Checkbox color="primary" {...field} value={true} checked={field.value} {...props} className={clsx(props.className, "fs-exclude")} />
        }
        label={
          label && (
            <Typography variant="body2" className={classes.label}>
              {label}
            </Typography>
          )
        }
      />
      <ErrorMessage name={field.name} component={ErrorText} />
    </TrackInput>
  );
};

export default CheckboxQuestion;
