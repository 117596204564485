import { FormControl, FormControlLabel, makeStyles, Radio, RadioGroup } from "@material-ui/core";
import clsx from "clsx";
import { ErrorMessage } from "formik";
import React from "react";
import { fromCamelCase } from "../../utils/helpers";
import QuestionLabel from "../QuestionLabel.component";
import TrackInput from "../TrackInput.component";
import ErrorText from "../UIKit/ErrorText.component";

const useStyles = makeStyles(
  (theme) => ({
    formControlLabel: {
      "& > .MuiFormControlLabel-label": {
        padding: theme.spacing(1.5, 0),
        fontWeight: theme.typography.fontWeightBold,
      },
    },
    textAndRadioContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "space-between",
    },
    questionLabel: {
      marginBottom: theme.spacing(1.25),
      "&.Mui-focused": {
        color: theme.palette.text.primary,
      },
    },
  }),
  { name: "RadioQuestionComponent" },
);

const RadioQuestion = ({ field, form, label, trackedCategory, disabled, radioStyle, radioValues, ...props }) => {
  const classes = useStyles(props);
  return (
    <FormControl>
      {radioValues ? (
        <TrackInput category={trackedCategory} label={fromCamelCase(field.name)} value={field.value}>
          <RadioGroup
            {...field}
            onChange={(e) => {
              const selectedValue = radioValues
                .map((option) => (typeof option === "object" ? option.value : option))
                .find((option) => String(option) === e.target.value);
              form.setFieldValue(field.name, selectedValue);
            }}
            {...props}
          >
            <QuestionLabel className={classes.questionLabel}>{label}</QuestionLabel>
            {radioValues.map((option) => {
              const optionValue = typeof option === "object" ? option.value : option;
              const optionLabel = typeof option === "object" ? option.label : option;
              return (
                <FormControlLabel
                  key={optionValue}
                  value={optionValue}
                  label={optionLabel}
                  className={clsx(classes.formControlLabel, "fs-exclude")}
                  style={radioStyle}
                  disabled={disabled}
                  control={<Radio color="primary" className="fs-exclude" />}
                />
              );
            })}
          </RadioGroup>
          <ErrorMessage name={field.name} component={ErrorText} />
        </TrackInput>
      ) : (
        <div className={classes.textAndRadioContainer}>
          <TrackInput category={trackedCategory} label={fromCamelCase(field.name)} value={field.value}>
            <RadioGroup size="large" {...field} onChange={(e) => form.setFieldValue(field.name, e.target.value === "true")} {...props}>
              <QuestionLabel className={classes.questionLabel}>{label}</QuestionLabel>
              <FormControlLabel
                value={false}
                label="No"
                className={clsx(classes.formControlLabel, "fs-exclude")}
                disabled={disabled}
                control={<Radio color="primary" className="fs-exclude" />}
              />
              <FormControlLabel
                value={true}
                label="Yes"
                className={clsx(classes.formControlLabel, "fs-exclude")}
                disabled={disabled}
                control={<Radio color="primary" className="fs-exclude" />}
              />
            </RadioGroup>
            <ErrorMessage name={field.name} component={ErrorText} />
          </TrackInput>
        </div>
      )}
    </FormControl>
  );
};

export default RadioQuestion;
