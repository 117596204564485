import notifications from "client/utils/notifications";
import { LeanPortfolio } from "server/services/portfolio/portfolio.types";
import { ipAxios } from "../ipAxios";
import { UpdateQuery } from "mongoose";

export const getPortfolios = async (query?: GenericObject) => {
  try {
    const res = await ipAxios.get("/admin/portfolios", { params: query });
    return res.data;
  } catch (error) {
    notifications.error(error.message, error);
    return null;
  }
};

export const deletePortfolio = async (id: string) => {
  try {
    const res = await ipAxios.delete<string>(`/admin/portfolios/${id}`);
    return res.data;
  } catch (error) {
    notifications.error(error.message, error);
    return null;
  }
};

export const createPortfolio = async (values: LeanPortfolio) => {
  try {
    const res = await ipAxios.post<LeanPortfolio>("/admin/portfolios", values);
    return res.data;
  } catch (error) {
    notifications.error(error.message);
    return null;
  }
};

export const updatePortfolio = async (values: UpdateQuery<LeanPortfolio>, id: string | null) => {
  try {
    const res = await ipAxios.put<LeanPortfolio>(`/admin/portfolios/${id}`, values);
    return res.data;
  } catch (error) {
    notifications.error(error.message);
    return null;
  }
};
