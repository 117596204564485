import { CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Link, makeStyles, Typography } from "@material-ui/core";
import CloseIcon from "client/components/Icons/Close.icon";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment";
import React, { FC, Fragment, useCallback, useEffect, useState } from "react";
import { CompanyWithHoldings } from "server/services/company/company.types";
import ClientStatementApi from "../../../../api/advisor/client/statement.clientApi";
import StatementApi from "../../../../api/statement.api";
import DocumentIcon from "../../../../assets/document.svg";
import { TRACKED_EVENTS } from "../../../../utils/constants";

interface Props {
  company: CompanyWithHoldings | null;
  onClose: () => any;
  clientId?: string;
}

const { PAGE_ACTIONS } = TRACKED_EVENTS;

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    padding: "0 50px 16px 50px",
    [theme.breakpoints.down("sm")]: {
      padding: 24,
    },
  },
  closeButton: {
    alignSelf: "flex-start",
  },
  dialog: {
    margin: theme.spacing(1),
  },
  documentIcon: {
    width: theme.spacing(4),
    height: "auto",
    marginRight: 20,
  },
  documentsContainer: {
    display: "flex",
    flexDirection: "column",
  },
  documentsRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: theme.spacing(2.5, 0),
    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  dateAndName: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  documentName: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  linkDownloadAgainText: {
    color: `${theme.palette.text.usedLink}!important`,
  },
  loadingSpinner: {
    position: "absolute",
  },
  title: {
    display: "flex",
    alignItems: "center",
    "& > *:first-child": {
      flex: 1,
    },
  },
}));

const DocumentsForDownload: FC<Props> = ({ company, onClose, clientId, ...props }) => {
  const classes = useStyles(props);
  const [isOpen, setIsOpen] = useState(false);
  const [isDownloading, setIsDownloading] = useState(null);

  useEffect(() => {
    setIsOpen(Boolean(company));
  }, [company]);

  const handleDownloadClick = useCallback(
    async (holding: CompanyWithHoldings["holdings"][0], doc) => {
      doc.key === "seis3.key" ? (doc.downloadedKey = "seis3.downloaded") : (doc.downloadedKey = "seis2Downloaded");
      setIsDownloading(doc);
      await doc.download(clientId, holding._id);
      setIsDownloading(null);
      globalThis.dataLayer.push({ event: PAGE_ACTIONS.PORTFOLIO_DOCUMENT_DOWNLOAD_CLICKED });
    },
    [clientId],
  );

  const getDocuments = useCallback(
    (holding: CompanyWithHoldings["holdings"][0]) =>
      [
        {
          name: holding.dealStage === "SEIS" ? "SEIS3 Qualifying Certificate" : "EIS3 Qualifying Certificate",
          key: "seis3.key",
          downloadedKey: "seis3.downloaded",
          download: async (userId: string, subscriptionId: string) => {
            if (userId) {
              await ClientStatementApi.downloadClientCertificate(userId, subscriptionId);
            } else {
              await StatementApi.downloadCertificate(subscriptionId);
            }
            globalThis.dataLayer.push({
              event: PAGE_ACTIONS.QUALIFYING_CERTIFICATE_FILE_DOWNLOADED_SUCCESSFULLY,
              eventProps: { holdingID: subscriptionId },
            });
          },
        },
        // {
        //   name: holding.dealStage === "SEIS" ? "SEIS2 Qualifying Certificate" : "EIS2 Qualifying Certificate",
        //   key: "seis2.key",
        //   downloadedKey: "seis2Downloaded",
        //   download: async (userId: string, subscriptionId: string) => {
        //     if (userId) {
        //       await ClientStatementApi.downloadClientEvidence(userId, subscriptionId);
        //     } else {
        //       await StatementApi.downloadEvidence(subscriptionId);
        //     }
        //     globalThis.dataLayer.push({
        //       event: PAGE_ACTIONS.QUALIFYING_EVIDENCE_FILE_DOWNLOADED_SUCCESSFULLY,
        //       eventProps: { holdingID: subscriptionId },
        //     });
        //   },
        // },
        {
          name: "Contract Note",
          key: "contractNote",
          caption: (
            <>
              If you would like a hard copy posted to you, please request this by emailing us at{" "}
              <Link variant="inherit" href="mailto:fund@builtventures.uk">
                fund@builtventures.uk
              </Link>{" "}
              with the Company Name and Date of Investment.
            </>
          ),
          download: async (_clientId: string, subscriptionId: string) => {
            const file = await StatementApi.downloadContractNote(subscriptionId);
            if (file) {
              globalThis.dataLayer.push({
                event: PAGE_ACTIONS.CONTRACT_NOTE_DOWNLOADED_SUCCESSFULLY,
                eventProps: { holdingID: subscriptionId },
              });
            }
          },
        },
      ].filter((document) => Boolean(_.get(holding, document.key, null))),
    [],
  );

  return !company ? null : (
    <>
      {/* {documents.length > 0 ? (
        <Link className={classes.valueTypography} variant="body2" onClick={() => setIsOpen(true)}>
          {documents.length} {documents.length === 1 ? "document" : "documents"}
        </Link>
      ) : (
        <Typography variant="body2">Unavailable</Typography>
      )} */}
      <Dialog maxWidth="md" fullWidth open={isOpen} onClose={onClose} className={classes.dialog}>
        <DialogTitle disableTypography className={classes.title}>
          <Typography variant="h2" align="center">
            {company.companyName}
          </Typography>
          <IconButton onClick={onClose} color="secondary" className={classes.closeButton}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {company.holdings.map((holding) => {
            const documents = getDocuments(holding);
            return documents.map((document) => {
              const isDownloaded = Boolean(document.downloadedKey) && _.get(holding, document.downloadedKey!, false);
              return (
                <Fragment key={document.key}>
                  <div className={classes.documentsRow}>
                    <img className={classes.documentIcon} src={DocumentIcon} alt="document icon" />
                    <div className={classes.dateAndName}>
                      <Typography variant="body1" component="div" className={classes.documentName}>
                        <strong>
                          {document.name}
                          {!isDownloaded && (
                            <Typography variant="inherit" color="primary" component="span">
                              &nbsp;NEW!
                            </Typography>
                          )}
                        </strong>
                      </Typography>
                      <Typography variant="body2">{moment(holding.settleDate).format("L")}</Typography>
                    </div>
                    <Link
                      onClick={() => handleDownloadClick(holding, document)}
                      variant="body2"
                      component="button"
                      className={clsx({ [classes.linkDownloadAgainText]: isDownloaded })}
                    >
                      {isDownloaded ? "Download again" : "Download"}
                      {isDownloading === document && <CircularProgress size="2em" className={classes.loadingSpinner} />}
                    </Link>
                  </div>
                  {Boolean(document.caption) && (
                    <Typography color="textPrimary" component="div" variant="caption" gutterBottom>
                      {document.caption}
                    </Typography>
                  )}
                </Fragment>
              );
            });
          })}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DocumentsForDownload;
