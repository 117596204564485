import { Button, Container, Link, makeStyles, Tooltip, Typography } from "@material-ui/core";
import DataTable, { ColumnDefinition } from "@wearenova/mui-data-table";
import clsx from "clsx";
import moment from "moment";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { UserStateClient } from "server/services/user/user.types";
import ClientApplicationApi from "../../api/advisor/client/application.clientApi";
import AdvisorClientsApi from "../../api/advisor/clients.advisorApi";
import rocketDeploymentIcon from "../../assets/rocket_deployment.svg";
import { ApplicationContext, APPLICATION_TYPES } from "../../context/application.context";
import { AuthContext } from "../../context/auth.context";
import { ConfigContext } from "../../context/config.context";
import UserContext, { UserContextTypes } from "../../context/user.context";
import { ADVISOR_TYPES, APPLICATION_STATES, ROUTES, TRACKED_EVENTS } from "../../utils/constants";
import { formatThousands } from "../../utils/helpers";
import notifications from "../../utils/notifications";

const { PAGE_VIEWS } = TRACKED_EVENTS;

const EDIT_FEE_DISABLED_STATES = [APPLICATION_STATES.AWAITING_SIGNATURE];

const useStyles = makeStyles(
  (theme) => ({
    bannerWrapper: {
      borderTop: `3px solid ${theme.palette.secondary.main}`,
      borderBottom: `3px solid ${theme.palette.secondary.main}`,
    },
    bannerContentContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
    dashboardWrapper: {
      display: "flex",
      flexDirection: "column",
    },
    buttonWrapper: {
      display: "inline-block",
      padding: "2% 0",
      textAlign: "right",
    },
    disabledLink: {
      color: theme.palette.action.disabled,
      cursor: "unset",
    },
    row: {
      width: "100%",
      height: "auto",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      paddingBottom: 20,
      position: "relative",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    icon: {
      margin: "auto 20px auto 0",
      width: 63,
      height: 63,
      [theme.breakpoints.down("lg")]: {
        width: 53,
        height: 53,
      },
      [theme.breakpoints.down("md")]: {
        width: 63,
        height: 63,
      },
    },
    infoContainer: {
      maxWidth: "100%",
      display: "flex",
      margin: "auto 0",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
    },
    nextDeployment: {
      padding: 0,
      display: "flex",
    },
  }),
  { name: "AdvisorDashboardView" },
);

const AdvisorDashboard: React.FC = (props) => {
  const classes = useStyles(props);
  const history = useHistory();
  const [disabled, setDisabled] = useState(false);
  const { dispatch: applicationDispatch } = useContext(ApplicationContext);
  const { NEXT_DEPLOYMENT } = useContext(ConfigContext);
  const { clients, dispatch: userDispatch } = useContext(UserContext);
  const {
    state: { user },
  } = useContext(AuthContext);

  const tableStructure = useMemo<ColumnDefinition<UserStateClient>[]>(
    () => [
      {
        title: "Client Name",
        dataIndex: "clientName",
        key: "clientName",
      },
      {
        title: "Application Status",
        dataIndex: "applicationStatus",
        key: "applicationStatus",
      },
      {
        title: "Cash Balance",
        key: "cashBalance",
        render: (record) => (
          <Tooltip
            title={
              <>
                <p>SEIS Balance: {formatThousands(record.balances.fundsRemaining.seis, 2)}</p>
                <p>EIS Balance: {formatThousands(record.balances.fundsRemaining.eis, 2)}</p>
              </>
            }
            arrow
            interactive
          >
            <Typography variant="body2">{formatThousands(record.balances.fundsRemaining.total, 2)}</Typography>
          </Tooltip>
        ),
      },
      {
        title: "Advice Type",
        dataIndex: "adviceType",
        key: "adviceType",
      },
      {
        title: "Fee",
        dataIndex: "fee",
        key: "fee",
      },
      ...(user?.isReadOnly
        ? []
        : ([
            {
              title: <>Edit&nbsp;Fee</>,
              key: "editFee",
              render: (record) => {
                const isButtonDisabled = EDIT_FEE_DISABLED_STATES.some(
                  (state) => record.applicationStatus && record.applicationStatus.toLowerCase() === state.toLowerCase(),
                );
                return (
                  <Tooltip
                    title={
                      isButtonDisabled ? (
                        <>
                          This client has an application that is awaiting their signature, the application has been signed by you and includes the
                          current fee. If you have any issues please email{" "}
                          <Link color="inherit" variant="inherit" href="mailto:fund@builtventures.uk">
                            fund@builtventures.uk
                          </Link>
                        </>
                      ) : (
                        ""
                      )
                    }
                  >
                    <span>
                      <Button
                        data-client-id={record.userId}
                        variant="outlined"
                        data-testid="editClient"
                        onClick={() => record.onEdit(record)}
                        disabled={isButtonDisabled}
                      >
                        Edit&nbsp;Fee
                      </Button>
                    </span>
                  </Tooltip>
                );
              },
            },
            {
              title: "Apply",
              key: "apply",
              render: (record) =>
                record.adviceType === ADVISOR_TYPES.REFERRED ? null : (
                  <Link
                    onClick={async () => {
                      if (disabled) return;
                      setDisabled(true);
                      await record.onInvite(record);
                      setDisabled(false);
                    }}
                    data-client-id={record.userId}
                    data-testid="applyNow"
                    color="textPrimary"
                    className={clsx({ [classes.disabledLink]: disabled })}
                  >
                    Apply now
                  </Link>
                ),
            },
          ] as ColumnDefinition<UserStateClient>[])),
      {
        title: "Dashboard",
        key: "clientDashboard",
        render: (record) => (
          <Button
            data-client-id={record.userId}
            data-testid="viewDashboard"
            variant="outlined"
            onClick={() => history.push(`/advisor/client/${record.userId}/dashboard/portfolio`)}
          >
            View&nbsp;Dashboard
          </Button>
        ),
      },
    ],
    [classes.disabledLink, disabled, history, user?.isReadOnly],
  );

  useEffect(() => {
    globalThis.dataLayer.push({ event: PAGE_VIEWS.ADVISOR_DASHBOARD });
  }, []);

  useEffect(() => {
    if (user?.isVerified) {
      (async () => {
        const { success, data }: { success: boolean; data?: undefined | any[] } = await AdvisorClientsApi.getAllClients();
        if (success) {
          const tableData = data!.map<UserStateClient>((client) => ({
            userId: client._id,
            clientName: `${client.forenames} ${client.surname}`,
            email: client.email,
            applicationStatus: client.applicationStatus,
            balances: { fundsRemaining: client.cashBalance },
            adviceType: client.advisor.adviceType,
            fee: `${client.advisor.feePercentage.toFixed(2)}%`,
            onInvite: async (selectedClient) => {
              try {
                const clientApplication = await ClientApplicationApi.getApplication(selectedClient.userId);
                applicationDispatch({ type: APPLICATION_TYPES.SET_CLIENT, payload: clientApplication });
                history.push(`/advisor/client/${selectedClient.userId}/apply`);
              } catch (error) {
                notifications.genericError(error);
              }
            },
            onEdit: (selectedClient) => history.push(`/advisor/client/${selectedClient.userId}/edit`),
          }));
          userDispatch({ type: UserContextTypes.SET_CLIENTS, payload: tableData });
        }
      })();
    }
  }, [applicationDispatch, history, user?.isVerified, userDispatch]);

  const handleAddClientClick = () => {
    history.push(ROUTES.ADVISOR.ADD_CLIENT);
  };

  return (
    <>
      {!user?.isOnboardedAdvisor || !user?.isVerified ? (
        <div className={classes.bannerWrapper}>
          <Container className={classes.bannerContentContainer}>
            {!user?.isOnboardedAdvisor ? (
              <>
                <Typography variant="h3" paragraph>
                  Please verify your IFA status before you are able to add clients.
                </Typography>
                <Button data-testid="goToVerification" onClick={() => history.push(ROUTES.ADVISOR.ONBOARDING)}>
                  Go to verification
                </Button>
              </>
            ) : (
              <Typography variant="h3">One of our team will contact you shortly to verify your account.</Typography>
            )}
          </Container>
        </div>
      ) : null}
      <Container>
        <div className={classes.dashboardWrapper}>
          <div className={classes.row}>
            <Typography variant="h1" gutterBottom>
              Welcome to your dashboard
            </Typography>
            <div className={classes.nextDeployment}>
              <img className={classes.icon} src={rocketDeploymentIcon} alt="Calender" />
              <div className={classes.infoContainer}>
                Next deployment planned
                <Typography variant="h2" data-testid="deploymentDate">
                  Next Deployment <strong>{NEXT_DEPLOYMENT === "TBD" ? "TBD" : moment(NEXT_DEPLOYMENT).format("DD/MM/YY")}</strong>
                </Typography>
              </div>
            </div>
          </div>

          <DataTable tableData={Object.values(clients)} tableStructure={tableStructure} />
          {user?.isReadOnly ? null : (
            <div className={classes.buttonWrapper}>
              <Button onClick={handleAddClientClick} disabled={!user?.isVerified} data-testid="addAClient">
                Add a client
              </Button>
            </div>
          )}
        </div>
      </Container>
    </>
  );
};

export default AdvisorDashboard;
