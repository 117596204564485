import { USER_ROLE } from "client/utils/constants";
import { useMemo } from "react";
import { AuthContextUser } from "server/services/user/user.types";

function useIsAdmin(user: AuthContextUser | undefined) {
  const isAuthed = useMemo(() => Boolean(user), [user]);
  const isAdmin = useMemo(
    () => Boolean(isAuthed && (user!.role === USER_ROLE.ADMIN || user!.role === USER_ROLE.READ_ONLY_ADMIN || user!.role === USER_ROLE.MANAGER)),
    [isAuthed, user],
  );
  return isAdmin;
}

export default useIsAdmin;
