import { Dialog, DialogContent, DialogProps, DialogTitle, FormGroup, Typography } from "@material-ui/core";
import { deleteFund, updateFund } from "client/api/admin/funds.adminApi";
import CrudFormButtons from "client/components/FormControls/CrudFormButtons.component";
import DatePickerField from "client/components/FormControls/DatePickerField.component";
import TextQuestion from "client/components/FormControls/TextQuestion.component";
import { AdminContext, ADMIN_TYPES } from "client/context/admin.context";
import { Field, Form, Formik } from "formik";
import { FC, useCallback, useContext, useMemo } from "react";
import * as Yup from "yup";

interface Props extends DialogProps {
  fund?: any; // todo: change to (FundType | null) union type?
  onClose(): any;
}

// yup validation schema
const SCHEMA = Yup.object({
  name: Yup.string().required("Please provide a name for this fund"),
  meta: Yup.object({
    deploymentDate: Yup.date().typeError("Invalid date").nullable(),
  }),
});

/**
 * Fund form functional component.
 *
 * @param props
 * @returns Form component
 */

const FundForm: FC<Props> = ({ onClose, open, fund }) => {
  const { dispatch } = useContext(AdminContext);

  // handle initial values
  const initialValues = useMemo(
    () => ({
      name: "",
      meta: {
        deploymentDate: null,
      },
      ...(fund || {}),
    }),
    [fund],
  );

  // handle form submission (update the fund)
  const handleSubmit = useCallback(
    async (values: typeof initialValues) => {
      if (fund) {
        // if a fund is passed to this component, we are
        // trying to update it
        const updatedFund = await updateFund(values, fund._id);
        if (updatedFund) {
          dispatch({ type: ADMIN_TYPES.UPDATE_FUND, payload: updatedFund });
          onClose();
        }
      }
    },
    [dispatch, onClose, fund],
  );

  // handle fund deletion
  const handleDelete = useCallback(async () => {
    if (!fund) return;
    const deletedId = await deleteFund(fund._id);
    if (deletedId) dispatch({ type: "DELETE_FUND", payload: deletedId });
    onClose();
  }, [dispatch, onClose, fund]);

  // render form
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle disableTypography>
        <Typography variant="h3">{fund ? `Edit ${fund.name}` : "Create Fund"}</Typography>
      </DialogTitle>
      <DialogContent>
        <Formik initialValues={initialValues} validationSchema={SCHEMA} onSubmit={handleSubmit} enableReinitialize>
          {() => (
            <Form data-testid="fundForm">
              <FormGroup>
                <Field name="name" component={TextQuestion} label="Fund Name" placeholder="Name" />
              </FormGroup>
              <FormGroup>
                <Field
                  name="meta.deploymentDate"
                  component={DatePickerField}
                  label="Deployment Date"
                  views={["year", "month"]}
                  openTo="year"
                  format="MM/YYYY"
                  placeholder="MM/YYYY"
                  autoComplete="off"
                  nullable
                />
              </FormGroup>
              <CrudFormButtons isEdit={Boolean(fund)} handleDelete={handleDelete} onClose={() => onClose()} />
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default FundForm;
