import { ApplicationContext, APPLICATION_TYPES } from "client/context/application.context";
import { AuthContext } from "client/context/auth.context";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import * as AdminApplicationApi from "../../api/admin/application.adminApi";
import ClientApplicationApi, { getAdvisedAssessment } from "../../api/advisor/client/application.clientApi";
import ApplicationsApi from "../../api/applications.api";
import { ROUTES } from "../../utils/constants";
import { AssessmentStatus } from "server/services/assessment/assessment.consts";

const useApplication = (isAdmin: boolean = false) => {
  const params = useParams<{ applicationId?: string; clientID?: string }>();
  const navigate = useHistory();
  const context = useContext(ApplicationContext);
  const {
    state: { user },
  } = useContext(AuthContext);
  const clientId = useMemo(() => user?.isAdvisor && params.clientID, [params.clientID, user?.isAdvisor]);
  const isAdvisor = useMemo(() => Boolean(clientId), [clientId]);

  const getApplication = useCallback(() => {
    if (params.applicationId && clientId) return ClientApplicationApi.getApplicationById(clientId, params.applicationId);
    if (clientId) return ClientApplicationApi.getApplication(clientId);
    if (isAdmin) return AdminApplicationApi.getApplication(params.applicationId!);
    return ApplicationsApi.getUsersApplication(params.applicationId);
  }, [params.applicationId, clientId, isAdmin]);

  const investorAssessmentCheck = useCallback(
    (isPath: boolean) => {
      if (!isAdvisor && user?.assessmentStatus != AssessmentStatus.CONTINUE_TO_THE_APPLICATION && user?.assessmentStatus != undefined && isPath) {
        navigate.push(`..${ROUTES.APPLICATION_ASSESSMENT}`, { replace: true });
      }
    },
    [isAdvisor, navigate, user?.assessmentStatus],
  );

  const advisorAssessmentCheck = useCallback(
    async (isPath: boolean) => {
      if (isAdvisor && isPath && clientId != null) {
        const advisedAssessmentRes = await getAdvisedAssessment(clientId as string);
        if (advisedAssessmentRes) {
          if (advisedAssessmentRes.status != AssessmentStatus.CONTINUE_TO_THE_APPLICATION)
            navigate.push(`..${ROUTES.APPLICATION_ASSESSMENT}`, { replace: true });
        }
      }
    },
    [isAdvisor, clientId],
  );

  useEffect(() => {
    if (!context.application) {
      (async () => {
        const app = await getApplication();
        context.dispatch({ type: APPLICATION_TYPES.SET_APPLICATION, payload: app });
      })();
    }
  }, [context, getApplication]);

  useEffect(() => {
    (async () => {
      if (isAdmin) return;
      const isPath =
        location.pathname.includes(`${ROUTES.APPLICATION_DETAILS}`) ||
        location.pathname.includes(`${ROUTES.APPLICATION_OBJECTIVES}`) ||
        location.pathname.includes(`${ROUTES.APPLICATION_FINANCES}`) ||
        location.pathname.includes(`${ROUTES.APPLICATION_EXPERIENCE}`) ||
        location.pathname.includes(`${ROUTES.APPLICATION_INVESTMENT}`)
          ? true
          : false;
      investorAssessmentCheck(isPath);
      await advisorAssessmentCheck(isPath);
    })();
  }, [clientId, user?.assessmentStatus, isAdvisor]);
  return context;
};

export default useApplication;
