import { Button } from "@material-ui/core";
import { ColumnDefinition, OnChangeObject, TableCellEditHandler } from "@wearenova/mui-data-table";
import { getPortfolios, updatePortfolio } from "client/api/admin/portfolios.adminApi";
import { AdminContext, ADMIN_TYPES } from "client/context/admin.context";
import React, { FC, useCallback, useContext, useMemo, useState } from "react";
import { LeanPortfolio } from "server/services/portfolio/portfolio.types";
import AdminTable from "../AdminTable.view";
import PortfolioForm from "./PortfolioForm.view";
import _ from "lodash";

const PortfolioTab: FC = () => {
  const { portfolios, dispatch } = useContext(AdminContext);
  const [selectedPortfolio, setSelectedPortfolio] = useState<LeanPortfolio | null>(null);
  const [addNew, setAddNew] = useState<boolean>(false);

  const handleChange = useCallback(
    async (changeObject: OnChangeObject, isExport: boolean) => {
      const data = await getPortfolios(changeObject);
      if (isExport) return data;
      dispatch({ type: "SET_PORTFOLIOS", payload: data || [] });
    },
    [dispatch],
  );

  const handleRowClick = useCallback((portfolio: LeanPortfolio) => setSelectedPortfolio(portfolio), []);

  const handleAddNew = useCallback(() => setAddNew(true), []);

  const handleClose = useCallback(() => {
    setSelectedPortfolio(null);
    setAddNew(false);
  }, []);

  const handleTableEdit = useCallback<TableCellEditHandler<LeanPortfolio>>(
    async ({ path, value }, record) => {
      const res = await updatePortfolio(_.set({}, path, value), record._id);
      if (res) dispatch({ type: ADMIN_TYPES.UPDATE_PORTFOLIO, payload: res });
    },
    [dispatch],
  );

  const tableStructure = useMemo<ColumnDefinition<LeanPortfolio>[]>(
    () => [
      {
        key: "name",
        dataIndex: "name",
        title: "Name",
        filterColumn: true,
        pinnable: true,
        editable: true,
      },
      {
        key: "nominee",
        dataIndex: "nominee",
        title: "Nominee",
        filterColumn: true,
        pinnable: true,
        editable: true,
      },
    ],
    [],
  );

  return (
    <>
      <AdminTable tableStructure={tableStructure} tableData={portfolios} onChange={handleChange} rowClick={handleRowClick} onEdit={handleTableEdit} />
      <div>
        <Button onClick={handleAddNew}>Create a Portfolio</Button>
      </div>
      <PortfolioForm open={Boolean(selectedPortfolio || addNew)} onClose={handleClose} portfolio={selectedPortfolio} />
    </>
  );
};

export default PortfolioTab;
