import { Button, ButtonProps, Link, LinkProps, makeStyles, Tooltip, Typography, useMediaQuery } from "@material-ui/core";
import ApplicationClientApi from "client/api/advisor/client/application.clientApi";
import { ConfigContext } from "client/context/config.context";
import clsx from "clsx";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { UserStateClient } from "server/services/user/user.types";
import ApplicationApi from "../../api/applications.api";
import userApi from "../../api/user.api";
import { ApplicationContext, APPLICATION_TYPES } from "../../context/application.context";
import { AuthContext } from "../../context/auth.context";
import UserContext, { UserContextTypes } from "../../context/user.context";
import { ROUTES } from "../../utils/constants";
import notifications from "../../utils/notifications";
import useApplication from "client/views/ApplicationForm/useApplication";
import { getLinkTextByApplicationStatus } from "client/utils/helpers";

type ButtonLinkProps<T extends boolean | undefined> = T extends true ? LinkProps : ButtonProps;

export type Props<T extends boolean | undefined> = ButtonLinkProps<T> & {
  className?: string;
  link?: T;
  client?: UserStateClient;
};

const useStyles = makeStyles(
  (theme) => ({
    button: {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    disabled: {
      color: theme.palette.text.disabled,
      cursor: "default",
    },
  }),
  { name: "InvestFundsComponent" },
);

const InvestFunds = <T extends boolean | undefined>({ className = "", link = false, client, ...props }: Props<T>) => {
  const classes = useStyles(props);
  const history = useHistory();
  const isDesktop = useMediaQuery("(pointer: fine)");
  const { dispatch: applicationDispatch } = useContext(ApplicationContext);
  const { advisor, dispatch: userDispatch } = useContext(UserContext);
  const {
    state: { user },
  } = useContext(AuthContext);
  const { application } = useApplication(false);

  const { fund } = useContext(ConfigContext);
  const [disabled, setDisabled] = useState(false);

  const { isAdvised, isInvestor } = useMemo(() => user!, [user]);

  useEffect(() => {
    if (isAdvised) {
      (async () => {
        const data = await userApi.getAdvisorDetails();
        if (!data) {
          return;
        }
        userDispatch({ type: UserContextTypes.SET_ADVISOR, payload: data });
      })();
    }
  }, [isAdvised, userDispatch]);

  const getTooltipTitle = useCallback(() => {
    if (client) return `Apply on behalf of ${client.clientName}`;
    if (isDesktop && isAdvised)
      return (
        <>
          To invest funds please speak to your financial adviser: <strong>{advisor.fullName}</strong> (
          <Link variant="inherit" color="inherit" href={`mailto:${advisor.email}`}>
            {advisor.email}
          </Link>
          )
        </>
      );
    return "";
  }, [advisor.email, advisor.fullName, client, isAdvised, isDesktop]);

  const investFundsProps = useMemo<T extends true ? LinkProps : ButtonProps>(
    () => ({
      disabled: disabled || isAdvised, // MUI Buttons support disabled, MUI Links do not
      className: clsx(classes.button, className),
      "data-testid": "invest-funds",
      onClick: async () => {
        if (client) {
          const clientApplication = await ApplicationClientApi.getApplication(client.userId);
          applicationDispatch({ type: APPLICATION_TYPES.SET_CLIENT, payload: clientApplication });
          return history.push(`/advisor/client/${client.userId}/apply`);
        } else if (!isAdvised) {
          // button should do nothing when clicked if the user is advised
          setDisabled(true);
          try {
            const application = await ApplicationApi.getApplication();
            applicationDispatch({ type: APPLICATION_TYPES.SET_APPLICATION, payload: application });
            if (isInvestor) {
              history.push(fund.aml.enabled ? ROUTES.APPLICATION_ID_VERIFICATION : ROUTES.APPLICATION_DETAILS);
            } else {
              history.push(ROUTES.APPLICATION_BEGIN);
            }
          } catch (error) {
            notifications.genericError(error);
          }
          setDisabled(false);
        }
      },
      ...props,
    }),
    [applicationDispatch, className, classes.button, client, disabled, fund.aml.enabled, history, isAdvised, isInvestor, props],
  );

  /**
   * Function to render the 'Invest funds' link, disabling it
   * if the user is an advised client.
   *
   * This had to be seperated into a function as the 'disabled'
   * prop does not work for MUI Links and adding a disabled class
   * to the className (in 'investFundsProps') conflicts with
   * the Button link.
   *
   * (INV-1367)
   *
   * @returns 'Invest funds' Link JSX
   */
  const renderInvestLink = useCallback(() => {
    const LINK_TEXT = getLinkTextByApplicationStatus(application);

    if (!isAdvised) return <Link {...(investFundsProps as LinkProps)}>{props.children || LINK_TEXT}</Link>;

    return (
      <Typography variant="body2" className={classes.disabled}>
        {LINK_TEXT}
      </Typography>
    );
  }, [isAdvised, classes.disabled, investFundsProps, props.children]);

  return (
    <>
      <Tooltip interactive title={getTooltipTitle()}>
        <span>
          {link ? (
            renderInvestLink()
          ) : (
            <Button {...(investFundsProps as ButtonProps)}>{props.children || getLinkTextByApplicationStatus(application, false)}</Button>
          )}
        </span>
      </Tooltip>
      {!isDesktop && isAdvised && (
        <Typography variant="caption" align="center">
          {getTooltipTitle()}
        </Typography>
      )}
    </>
  );
};

export default InvestFunds;
