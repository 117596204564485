import { TextField, Typography } from "@material-ui/core";
import { Settings } from "@material-ui/icons";
import { ColGroupDefinition, ColumnDefinition, EditableCell, numberFormatter, TableCellEditHandler } from "@wearenova/mui-data-table";
import { useUpdateDealConfigs } from "client/api/gql/deployment.gqlApi";
import notifications from "client/utils/notifications";
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { AllDeploymentDeals, BaseDeploymentDeal } from "server/services/company/company.types";
import { DealConfigUpdate, FundConfig } from "server/services/deployment/deployment.types";
import * as Yup from "yup";
import AdminTable from "../../AdminTable.view";
import { useStyles } from "../../useStyles.hook";
import DeploymentContext, { DEALS } from "../deployment.context";
import ConfigField from "./ConfigField.component";
import OpenDealsTableOptions from "./OpenDealsTableOptions";
import { OPEN_DEALS_OPTIONS } from "client/utils/constants";

interface Props {}

export type OptionKey = keyof typeof OPEN_DEALS_OPTIONS;
export type OptionsState = {
  [key in OptionKey]: boolean;
};

type RemainingAllowance = { [companyName: string]: { SEIS: number; EIS: number } };

const OPEN_DEALS_OPTIONS_STORAGE_KEY = "OPEN_DEALS_OPTIONS";

const OpenDealsTab: React.FC<Props> = ({ ...props }) => {
  useStyles(props);
  const [openDealsOptions, setOpenDealsOptions] = useState<boolean>(false);
  const { deals, applications, funds } = useContext(DeploymentContext);
  const [filteredDeals, setFilteredDeals] = useState<AllDeploymentDeals>(DEALS);
  const [updateFundConfigs] = useUpdateDealConfigs();
  const getEditableAmountConfig = useCallback(
    (fundId: string, dealStage: string): EditableCell<BaseDeploymentDeal, BaseDeploymentDeal[]> => ({
      path: true,
      component({ onChange, ...props }, record) {
        return (
          <TextField
            variant="standard"
            {...props}
            onChange={(e) => onChange(e.target.value)}
            type="number"
            inputProps={{ step: 0.01, min: 0 }}
            disabled={record.config[fundId][dealStage].weighting > 0}
          />
        );
      },
      validate: (value) => Yup.number().min(0, "must be at least 0").required("Required").validate(value),
    }),
    [],
  );
  const [options, setOptions] = useState<OptionsState>(() => {
    const storedOptions = typeof window != "undefined" && localStorage.getItem(OPEN_DEALS_OPTIONS_STORAGE_KEY);
    if (storedOptions) {
      return JSON.parse(storedOptions);
    } else {
      const initialOptions: OptionsState = {} as OptionsState;
      Object.keys(OPEN_DEALS_OPTIONS).forEach((key) => {
        initialOptions[key as OptionKey] = false;
      });
      return initialOptions;
    }
  });

  const [tempOptions, setTempOptions] = useState<OptionsState>(() => ({ ...options }));
  const handleOptionsSubmit = useCallback(async () => {
    setOptions(tempOptions);

    localStorage.setItem(OPEN_DEALS_OPTIONS_STORAGE_KEY, JSON.stringify(tempOptions));
    setOpenDealsOptions(false);
  }, [tempOptions]);

  const handleToggleOption = useCallback((option: OptionKey) => {
    setTempOptions((prevOptions) => ({
      ...prevOptions,
      [option]: !prevOptions[option],
    }));
  }, []);

  // Create a memoized function to check if at least one value is true
  const isAnyOptionTrue = useMemo(() => {
    return Object.values(options).some((value) => value === true);
  }, [options]);

  const tableStructure = useMemo<ColumnDefinition<BaseDeploymentDeal, AllDeploymentDeals>[]>(
    () =>
      (
        [
          {
            key: "config",
            title: "Config",
            align: "center",
            actionButtons: [
              {
                key: "changeOptions",
                icon: <Settings />,
                onClick: () => setOpenDealsOptions(true),
                color: isAnyOptionTrue ? "primary" : undefined,
              },
            ],
            render: () => {
              return <></>;
            },
          },
          {
            key: "stage",
            title: "Stage",
            dataIndex: "investmentStage",
          },
          {
            key: "status",
            title: "Status",
            dataIndex: "status",
          },
          {
            key: "investmentRequired",
            title: "Investment Required",
            dataIndex: "investmentRequired",
            dataType: "boolean",
          },
          {
            key: "investmentStatus",
            title: "Investment Status",
            dataIndex: "investmentStatus",
            dataType: "string",
          },
          {
            key: "type",
            title: "Investment Type",
            dataIndex: "type",
            dataType: "string",
          },
          {
            key: "startDate",
            title: "Work Start Date",
            dataType: "date",
            dataIndex: "startDate",
            render: (record) => record.startDate && moment(record.startDate).format("L"),
          },
          {
            key: "companyName",
            title: "Company",
            dataIndex: "company.companyName",
          },
          {
            key: "investmentAsk",
            title: "Investment Ask",
            dataType: "number",
            numerical: "investmentAsk",
          },
          {
            key: "cashComponent",
            title: "Cash Component",
            dataType: "number",
            numerical: "cashForBusiness",
          },
          {
            key: "servicesComponent",
            title: "Services Component",
            dataType: "number",
            numerical: "service.cashAmount",
          },
          {
            key: "externalFunding",
            title: "External Funding",
            dataType: "number",
            numerical: "externalFunding.total",
          },
          {
            key: "cashInBank",
            title: "Cash in Bank",
            dataType: "number",
            numerical: "cashInBank",
          },
          {
            key: "service",
            title: "Service",
            colGroup: [
              {
                key: "service.type",
                title: "Type",
                dataIndex: "service.type",
              },
              {
                key: "service.amount",
                title: "Amount",
                dataType: "number",
                dataIndex: "service.amount",
                render: (record) =>
                  typeof record.service.amount !== "number"
                    ? "N/A"
                    : numberFormatter(record.service.amount, { currency: record.service.type?.toLowerCase() === "cash" }),
              },
            ],
          },
          {
            key: "cashBackedRate",
            title: "Cash Backed",
            dataType: "number",
            dataIndex: "service.cashPercentage",
            numerical: { path: true, currency: false, style: "percent", minDecimalPlaces: 0 },
          },
          {
            key: "cashBackedServices",
            title: "Cash",
            dataType: "number",
            dataIndex: "service.cashAmount",
            numerical: true,
          },
          {
            key: "equityValue",
            title: "Equity Value",
            dataType: "number",
            dataIndex: "equityValue",
            numerical: true,
          },
          {
            key: "dayRate",
            title: "Day Rate",
            dataType: "number",
            dataIndex: "service.dayRate",
            numerical: true,
          },
          {
            key: "historicInvestment",
            title: "Historic Investment",
            colGroup: [
              {
                key: "historicInvestment.total",
                title: "Total",
                dataType: "number",
                dataIndex: "historicInvestment.total",
                numerical: true,
              },
              {
                key: "historicInvestment.seis",
                title: "SEIS",
                dataType: "number",
                dataIndex: "historicInvestment.seis",
                numerical: true,
              },

              {
                key: "historicInvestment.eis",
                title: "EIS",
                dataType: "number",
                dataIndex: "historicInvestment.eis",
                numerical: true,
              },
            ],
          },
          {
            key: "historicInvoice",
            title: "Historic Invoice",
            dataType: "number",
            dataIndex: "historicInvoice",
            numerical: true,
          },
          {
            key: "historicFees",
            title: "Historic Fees",
            colGroup: [
              {
                key: "historicFees.annualManagementCharge",
                title: "Annual Management Charge",
                dataType: "number",
                dataIndex: "historicFees.annualManagement",
                numerical: true,
              },
              {
                key: "historicFees.investmentFee",
                title: "Investment Fee",
                dataType: "number",
                dataIndex: "historicFees.investment",
                numerical: true,
              },
            ],
          },
          {
            // ! calculated
            key: "investmentRemaining",
            title: "Investment Remaining",
            colGroup: [
              {
                key: "investmentRemaining.total",
                title: "Total",
                dataType: "number",
                dataIndex: "investmentRemaining.total",
                numerical: true,
              },
              {
                key: "sumSEISRemaining",
                title: "SEIS Remaining",
                dataType: "number",
                dataIndex: "sumSEISRemaining",
                numerical: true,
              },
              // {
              //   key: "investmentRemaining.seis",
              //   title: "SEIS",
              //   dataType: "number",
              //   dataIndex: "investmentRemaining.seis",
              //   numerical: true,
              // },
              // {
              //   key: "investmentRemaining.eis",
              //   title: "EIS",
              //   dataType: "number",
              //   dataIndex: "investmentRemaining.eis",
              //   numerical: true,
              // },
            ],
          },
          {
            key: "shareClass",
            title: "Share Class Data",
            colGroup: [
              {
                key: "shareClass.name",
                title: "Name",
                dataIndex: "shareClassData.name",
              },
              {
                key: "shareClass.nominalValue",
                title: "Nominal Value",
                dataType: "number",
                dataIndex: "shareClassData.nominalValue",
                numerical: { path: true, maxDecimalPlaces: 7 },
                editable: true,
              },
              {
                key: "pricePerShare",
                title: "Price",
                dataType: "number",
                dataIndex: "shareClassData.pricePerShare",
                numerical: { path: true, maxDecimalPlaces: 7 },
                editable: true,
              },
            ],
          },
          ...funds.map<ColumnDefinition<BaseDeploymentDeal, AllDeploymentDeals>>((fund) => {
            const fundId = String(fund._id);
            return {
              key: fundId,
              title: fund.name,
              colGroup: (["seis", "eis"] as const).flatMap<ColGroupDefinition<BaseDeploymentDeal, AllDeploymentDeals>>((dealStage) => [
                {
                  key: `${fundId}.${dealStage}.weighting`,
                  title: <ConfigField fundId={fundId} dealStage={dealStage} path="weighting" />,
                  dataType: "number",
                  dataIndex: `config.${fundId}.${dealStage}.weighting`,
                  numerical: { path: true, currency: false, decimalPlaces: 0 },
                  filterColumn: { title: `${dealStage.toUpperCase()} weighting` },
                  editable: {
                    path: true,
                    component: ({ onChange, ...props }) => (
                      <TextField
                        variant="standard"
                        {...props}
                        onChange={(e) => onChange(e.target.value)}
                        type="number"
                        inputProps={{ step: 1, min: 0 }}
                      />
                    ),
                    validate: (value) => Yup.number().min(0, "must be at least 0").integer().required("Required").validate(value),
                  },
                  footer: (data) => (
                    <Typography variant="caption" align="right" component="div">
                      <strong>{data.totals.config[fundId]?.[dealStage]?.weighting ?? 0}</strong>
                    </Typography>
                  ),
                },
                {
                  key: `${fundId}.${dealStage}.amount`,
                  title: <ConfigField fundId={fundId} dealStage={dealStage} path="amount" />,
                  dataType: "number",
                  dataIndex: `config.${fundId}.${dealStage}.amount`,
                  numerical: true,
                  filterColumn: { title: `${dealStage.toUpperCase()} amount` },
                  editable: getEditableAmountConfig(fundId, dealStage),
                  footer: (data) => (
                    <Typography variant="caption" align="right" component="div">
                      <strong>{numberFormatter(data.totals.config[fundId]?.[dealStage]?.amount ?? 0)}</strong>
                    </Typography>
                  ),
                },
                {
                  key: `${fundId}.${dealStage}.deployed`,
                  title: `${dealStage.toUpperCase()}\nDeployed`,
                  dataType: "number",
                  render: (record) => {
                    const amountDeployed = applications.totals?.deals?.[record._id]?.[fundId]?.[dealStage]?.amount ?? 0;
                    const weighting = (record.config[fundId] as FundConfig | undefined)?.[dealStage]?.weighting ?? 0;
                    const diff = Math.round((amountDeployed - (record.config[fundId]?.[dealStage]?.amount ?? 0)) * 100) / 100;
                    const isError =
                      (weighting === 0 && diff !== 0) ||
                      (weighting > 0 && diff > 0) ||
                      (weighting > 0 && diff < -record.shareClassData.pricePerShare);
                    return (
                      <>
                        {numberFormatter(amountDeployed)}
                        <br />
                        <Typography variant="inherit" color={isError ? "error" : "inherit"}>
                          {numberFormatter(diff, { signDisplay: diff === 0 ? "never" : "always" })}
                        </Typography>
                      </>
                    );
                  },
                  footer: (data) => {
                    const totalDeployed = applications.totals?.investmentAmount?.fundTotals?.[fundId]?.[dealStage] ?? 0;
                    const diff = totalDeployed - data.totals.config[fundId]?.[dealStage]?.amount;
                    return (
                      <>
                        {numberFormatter(totalDeployed)}
                        <br />
                        <Typography variant="inherit" color={Math.round(diff * 100) / 100 !== 0 ? "error" : "inherit"}>
                          {numberFormatter(diff)}
                        </Typography>
                      </>
                    );
                  },
                },
              ]),
              footer: (data) => {
                const seisAmount = data.totals.config[fundId]?.seis?.available ?? 0;
                const eisAmount = data.totals.config[fundId]?.eis?.available ?? 0;
                return (
                  <>
                    Total Available: {numberFormatter(seisAmount + eisAmount)}
                    <br />
                    SEIS Total Available: {numberFormatter(seisAmount)}
                    <br />
                    EIS Total Available: {numberFormatter(eisAmount)}
                    <br />
                  </>
                );
              },
            };
          }),
        ] as ColumnDefinition<BaseDeploymentDeal, AllDeploymentDeals>[]
      ).map((c, index) => {
        if (index == 0) return c; //skipping the config column
        return {
          ...c,
          ...(!c.colGroup
            ? { pinnable: true, filterColumn: c.filterColumn ?? true, sorter: c.sorter ?? true }
            : {
                pinnable: true,
                colGroup: c.colGroup.map((cg) => ({ ...cg, pinnable: true, filterColumn: cg.filterColumn ?? true, sorter: cg.sorter ?? true })),
              }),
        } as ColumnDefinition<BaseDeploymentDeal, AllDeploymentDeals>;
      }),
    [applications.totals?.deals, applications.totals?.investmentAmount?.fundTotals, funds, getEditableAmountConfig, isAnyOptionTrue],
  );

  const handleTableEdit = useCallback<TableCellEditHandler<BaseDeploymentDeal>>(
    async (update, record) => {
      const parsed = typeof update.value === "string" && parseFloat(update.value);
      const res = await updateFundConfigs({
        variables: {
          updates: _.set<DealConfigUpdate>({}, update.path, parsed === false || isNaN(parsed) ? update.value : parsed),
          dealIds: [record._id],
        },
      });
      if (res.errors) notifications.gql(res.errors);
    },
    [updateFundConfigs],
  );

  const calculateRemainingAllowance: any = useCallback<any>((filteredDealsTemp: any) => {
    // Create an object to store remaining allowances
    const remainingAllowances: RemainingAllowance = {};

    // Calculate sum of SEIS and EIS while populating the remainingAllowances object
    filteredDealsTemp.forEach((deal: any) => {
      const companyName = deal.company.companyName;
      const seisRemaining = deal.historicInvestment.seis;
      const eisRemaining = deal.historicInvestment.eis;

      if (!remainingAllowances[companyName]) {
        remainingAllowances[companyName] = { SEIS: 0, EIS: 0 };
      }

      remainingAllowances[companyName].SEIS += seisRemaining;
      remainingAllowances[companyName].EIS += eisRemaining;
    });

    // Step 3: Update filteredDeals with the sum for each company
    filteredDealsTemp.forEach((deal: any) => {
      const companyName = deal.company.companyName;
      deal.sumSEISRemaining = 0;
      if (remainingAllowances[companyName].EIS < 1) deal.sumSEISRemaining = deal.company.seisAllowanceLimit - remainingAllowances[companyName].SEIS;
    });
    return filteredDealsTemp;
  }, []);

  useEffect(() => {
    let filteredDealsTemp: AllDeploymentDeals = deals.filter((deal) => {
      return (
        (options.openDeals ? deal.investmentRequired : true) &&
        (options.dealsMoreThanTenPounds ? deal.investmentRemaining.total > 10 : true) &&
        (options.activeDeals ? deal.investmentStatus === "Active" : true) &&
        (options.eisDeals ? deal.historicInvestment.seis >= deal.company.seisAllowanceLimit || deal.historicInvestment.eis > 0 : true) &&
        (options.seisDeals ? deal.historicInvestment.seis < deal.company.seisAllowanceLimit && deal.historicInvestment.eis === 0 : true)
      );
    }) as AllDeploymentDeals;

    filteredDealsTemp.totals = deals.totals;
    filteredDealsTemp = calculateRemainingAllowance(filteredDealsTemp);
    setFilteredDeals(filteredDealsTemp);
  }, [calculateRemainingAllowance, deals, options]);

  return (
    <>
      <AdminTable
        tableStructure={tableStructure}
        tableData={filteredDeals}
        onEdit={handleTableEdit}
        rowsPerPageDefault={50}
        rowOptions={{ rowDisabled: (record: BaseDeploymentDeal) => !record.shareClassData.pricePerShare }}
      />
      <OpenDealsTableOptions
        fullWidth
        open={openDealsOptions}
        onClose={() => setOpenDealsOptions(false)}
        options={tempOptions}
        handleToggleOption={handleToggleOption}
        handleSubmit={handleOptionsSubmit}
      />
    </>
  );
};

export default OpenDealsTab;
