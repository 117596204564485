import React, { createContext, Dispatch, FC, Provider, Reducer, useEffect, useMemo, useReducer } from "react";
import { AdminApplication, ExpectedPayment } from "server/services/application/application.types";
import { AdminCertSubmission } from "server/services/certificateSubmissions/certSubmission.types";
import { AdminCompany } from "server/services/company/company.types";
import { LeanFund } from "server/services/fund/fund.types";
import { LeanPortfolio } from "server/services/portfolio/portfolio.types";
import { AdminHolding, AdminShareTransaction } from "server/services/shareTransaction/shareTransaction.types";
import { AdminUser, LeanUser } from "server/services/user/user.types";
import { arrayToObject } from "../utils/helpers";

export interface FormattedAdminState {
  allUsers: LeanUser[];
  users: AdminUser[];
  allCompanies: AdminCompany[];
  companies: AdminCompany[];
  shareTransactions: AdminShareTransaction[];
  allApplications: AdminApplication[];
  applications: AdminApplication[];
  expectedPayments: ExpectedPayment[];
  statistics: GenericObject<any>[];
  investmentChecker: GenericObject<any>[];
  documents: GenericObject<any>[];
  portfolios: LeanPortfolio[];
  holdings: AdminHolding[];
  funds: LeanFund[];
  certificateSubmissions: AdminCertSubmission[];
}

type ArrayStates = "expectedPayments";

type AdminState = {
  [P in keyof Omit<FormattedAdminState, ArrayStates>]: Record<string, FormattedAdminState[P][number]>;
} & Pick<FormattedAdminState, ArrayStates>;

interface IDispatch {
  dispatch: Dispatch<{ type: keyof typeof ADMIN_TYPES; payload: any }>;
}

const DEFAULT_ADMIN_STATE: AdminState = {
  allUsers: {},
  users: {},
  allCompanies: {},
  companies: {},
  shareTransactions: {},
  allApplications: {},
  applications: {},
  expectedPayments: [],
  statistics: {},
  investmentChecker: {},
  documents: {},
  portfolios: {},
  holdings: {},
  funds: {},
  certificateSubmissions: {},
};

const AdminContext = createContext<FormattedAdminState & IDispatch>({
  ...(DEFAULT_ADMIN_STATE as unknown as FormattedAdminState),
  dispatch: () => null,
});
AdminContext.displayName = "AdminContext";

const ADMIN_TYPES = {
  SET_ALL: "SET_ALL",

  SET_ALL_USERS: "SET_ALL_USERS",
  SET_USERS: "SET_USERS",
  UPDATE_USER: "UPDATE_USER",
  DELETE_USER: "DELETE_USER",

  SET_ALL_COMPANIES: "SET_ALL_COMPANIES",
  SET_COMPANIES: "SET_COMPANIES",
  UPDATE_COMPANY: "UPDATE_COMPANY",
  DELETE_COMPANY: "DELETE_COMPANY",

  SET_HOLDINGS: "SET_HOLDINGS",
  UPDATE_HOLDING: "UPDATE_HOLDING",
  BULK_UPDATE_HOLDINGS: "BULK_UPDATE_HOLDINGS",
  DELETE_HOLDING: "DELETE_HOLDING",

  SET_ALL_APPLICATIONS: "SET_ALL_APPLICATIONS",
  SET_APPLICATIONS: "SET_APPLICATIONS",
  UPDATE_APPLICATION: "UPDATE_APPLICATION",
  DELETE_APPLICATION: "DELETE_APPLICATION",

  SET_EXPECTED_PAYMENTS: "SET_EXPECTED_PAYMENTS",

  SET_STATISTICS: "SET_STATISTICS",

  SET_INVESTMENT_CHECKER: "SET_INVESTMENT_CHECKER",

  SET_DOCUMENTS: "SET_DOCUMENTS",
  UPDATE_DOCUMENT: "UPDATE_DOCUMENT",
  DELETE_DOCUMENT: "DELETE_DOCUMENT",

  SET_PORTFOLIOS: "SET_PORTFOLIOS",
  UPDATE_PORTFOLIO: "UPDATE_PORTFOLIO",
  DELETE_PORTFOLIO: "DELETE_PORTFOLIO",

  SET_FUNDS: "SET_FUNDS",
  UPDATE_FUND: "UPDATE_FUND",
  DELETE_FUND: "DELETE_FUND",

  SET_SHARE_TRANSACTIONS: "SET_SHARE_TRANSACTIONS",
  BULK_UPDATE_SHARE_TRANSACTIONS: "BULK_UPDATE_SHARE_TRANSACTIONS",
  UPDATE_SHARE_TRANSACTIONS: "UPDATE_SHARE_TRANSACTIONS",
  DELETE_SHARE_TRANSACTIONS: "DELETE_SHARE_TRANSACTIONS",

  SET_CERTIFICATE_SUBMISSIONS: "SET_CERTIFICATE_SUBMISSIONS",
  UPDATE_CERTIFICATE_SUBMISSION: "UPDATE_CERTIFICATE_SUBMISSION",
  DELETE_CERTIFICATE_SUBMISSION: "DELETE_CERTIFICATE_SUBMISSION",
} as const;

const ACTION_TYPE_MAP = {
  [ADMIN_TYPES.SET_ALL]: (state: AdminState, payload: any) => ({ ...state, ...payload }),

  [ADMIN_TYPES.SET_ALL_USERS]: (state: AdminState, payload: any) => {
    const allUsers = arrayToObject(payload);
    return { ...state, allUsers };
  },
  [ADMIN_TYPES.SET_USERS]: (state: AdminState, payload: any) => {
    const users = arrayToObject(payload);
    return { ...state, users };
  },
  [ADMIN_TYPES.UPDATE_USER]: (state: AdminState, payload: any) => {
    const updatedUsers = arrayToObject(payload);
    return { ...state, users: { ...state.users, ...updatedUsers } };
  },
  [ADMIN_TYPES.DELETE_USER]: (state: AdminState, payload: any) => {
    const newUsers = state.users;
    delete newUsers[payload];
    return { ...state, users: newUsers };
  },

  [ADMIN_TYPES.SET_ALL_COMPANIES]: (state: AdminState, payload: any) => {
    const allCompanies = arrayToObject(payload);
    return { ...state, allCompanies };
  },
  [ADMIN_TYPES.SET_COMPANIES]: (state: AdminState, payload: any) => {
    const companies = arrayToObject(payload);
    return { ...state, companies };
  },
  [ADMIN_TYPES.UPDATE_COMPANY]: (state: AdminState, payload: any) => {
    const updatedCompanies = arrayToObject(payload);
    return { ...state, companies: { ...state.companies, ...updatedCompanies } };
  },
  [ADMIN_TYPES.DELETE_COMPANY]: (state: AdminState, payload: any) => {
    const newCompanies = state.companies;
    delete newCompanies[payload];
    return { ...state, companies: newCompanies };
  },

  [ADMIN_TYPES.SET_HOLDINGS]: (state: AdminState, payload: any) => {
    const holdings = arrayToObject(payload, "id");
    return { ...state, holdings };
  },
  [ADMIN_TYPES.BULK_UPDATE_HOLDINGS]: (state: AdminState, payload: any) => {
    const bulkedHoldings = arrayToObject(payload, "id");
    return { ...state, holdings: { ...state.holdings, ...bulkedHoldings } };
  },
  [ADMIN_TYPES.UPDATE_HOLDING]: (state: AdminState, payload: any) => {
    const updatedHoldings = arrayToObject(payload, "id");
    return { ...state, holdings: { ...state.holdings, ...updatedHoldings } };
  },
  [ADMIN_TYPES.DELETE_HOLDING]: (state: AdminState, payload: any) => {
    const newHoldings = Object.values(state.holdings).filter((holding) => holding.shareTransactionId !== payload);
    return { ...state, holdings: arrayToObject(newHoldings, "id") };
  },

  [ADMIN_TYPES.SET_ALL_APPLICATIONS]: (state: AdminState, payload: any) => {
    const allApplications = arrayToObject(payload);
    return { ...state, allApplications };
  },
  [ADMIN_TYPES.SET_APPLICATIONS]: (state: AdminState, payload: any) => {
    const applications = arrayToObject(payload);
    return { ...state, applications: applications };
  },
  [ADMIN_TYPES.UPDATE_APPLICATION]: (state: AdminState, payload: any) => {
    const updatedApplications = arrayToObject(payload);
    return { ...state, applications: { ...state.applications, ...updatedApplications } };
  },
  [ADMIN_TYPES.DELETE_APPLICATION]: (state: AdminState, payload: any) => {
    const newApplications = state.applications;
    delete newApplications[payload];
    return { ...state, applications: newApplications };
  },

  [ADMIN_TYPES.SET_EXPECTED_PAYMENTS]: (state: AdminState, payload: any) => ({ ...state, expectedPayments: payload }),

  [ADMIN_TYPES.SET_STATISTICS]: (state: AdminState, payload: any) => {
    const statistics = arrayToObject(payload, "weekStart");
    return { ...state, statistics: { ...state.statistics, ...statistics } };
  },

  [ADMIN_TYPES.SET_INVESTMENT_CHECKER]: (state: AdminState, payload: any) => {
    const investmentChecker = arrayToObject(payload, "investorId");
    return { ...state, investmentChecker: { ...state.investmentChecker, ...investmentChecker } };
  },

  [ADMIN_TYPES.SET_DOCUMENTS]: (state: AdminState, payload: any) => {
    const documents = arrayToObject(payload);
    return { ...state, documents };
  },
  [ADMIN_TYPES.UPDATE_DOCUMENT]: (state: AdminState, payload: any) => {
    if (payload.userId) {
      const prevDocuments = state.documents;
      if (payload.previousDocumentId) {
        delete prevDocuments[payload.previousDocumentId];
      }
      const updatedDocuments = { ...prevDocuments, [payload._id]: payload };
      return { ...state, documents: updatedDocuments };
    } else {
      return { ...state, documents: { ...state.documents, [payload._id]: payload } };
    }
  },
  [ADMIN_TYPES.DELETE_DOCUMENT]: (state: AdminState, payload: any) => {
    const newDocuments = state.documents;
    delete newDocuments[payload];
    return { ...state, documents: newDocuments };
  },

  [ADMIN_TYPES.SET_PORTFOLIOS]: (state: AdminState, payload: any) => ({ ...state, portfolios: arrayToObject(payload) }),
  [ADMIN_TYPES.UPDATE_PORTFOLIO]: (state: AdminState, payload: any) => ({ ...state, portfolios: { ...state.portfolios, [payload._id]: payload } }),
  [ADMIN_TYPES.DELETE_PORTFOLIO]: (state: AdminState, payload: any) => {
    const portfolios = state.portfolios;
    delete portfolios[payload];
    return { ...state, portfolios };
  },

  [ADMIN_TYPES.SET_FUNDS]: (state: AdminState, payload: any) => ({ ...state, funds: arrayToObject(payload) }),
  [ADMIN_TYPES.UPDATE_FUND]: (state: AdminState, payload: any) => ({ ...state, funds: { ...state.funds, [payload._id]: payload } }),
  [ADMIN_TYPES.DELETE_FUND]: (state: AdminState, payload: any) => {
    const funds = state.funds;
    delete funds[payload];
    return { ...state, funds };
  },

  [ADMIN_TYPES.SET_SHARE_TRANSACTIONS]: (state: AdminState, payload: any) => ({ ...state, shareTransactions: arrayToObject(payload) }),
  [ADMIN_TYPES.BULK_UPDATE_SHARE_TRANSACTIONS]: (state: AdminState, payload: any) => {
    const bulkedShareTransactions = arrayToObject(payload);
    return { ...state, shareTransactions: { ...state.shareTransactions, ...bulkedShareTransactions } };
  },
  [ADMIN_TYPES.UPDATE_SHARE_TRANSACTIONS]: (state: AdminState, payload: any) => {
    const updatedShareTransactions = arrayToObject(payload);
    return { ...state, shareTransactions: { ...state.shareTransactions, ...updatedShareTransactions } };
  },
  [ADMIN_TYPES.DELETE_SHARE_TRANSACTIONS]: (state: AdminState, payload: any) => {
    const newShareTransaction = Object.values(state.shareTransactions).filter((shareTransaction) => shareTransaction._id !== payload);
    return { ...state, shareTransactions: arrayToObject(newShareTransaction) };
  },

  [ADMIN_TYPES.SET_CERTIFICATE_SUBMISSIONS]: (state: AdminState, payload: any) => ({ ...state, certificateSubmissions: arrayToObject(payload) }),
  [ADMIN_TYPES.UPDATE_CERTIFICATE_SUBMISSION]: (state: AdminState, payload: any) => {
    const updatedCertificateSubmission = arrayToObject(payload);
    return { ...state, certificateSubmissions: { ...state.certificateSubmissions, ...updatedCertificateSubmission } };
  },
  [ADMIN_TYPES.DELETE_CERTIFICATE_SUBMISSION]: (state: AdminState, payload: any) => {
    const certificateSubmissions = state.certificateSubmissions;
    delete certificateSubmissions[payload];
    return { ...state, certificateSubmissions };
  },
};

const AdminReducer: Reducer<
  AdminState,
  {
    type: (typeof ADMIN_TYPES)[keyof typeof ADMIN_TYPES];
    payload: any;
  }
> = (state, action) => ACTION_TYPE_MAP[action.type]?.(state, action.payload) ?? state;

const AdminContextProvider = AdminContext.Provider as unknown as Provider<FormattedAdminState & IDispatch>;

const AdminProvider: FC<{ value: AdminState }> = ({ value, ...props }) => {
  const [state, dispatch] = useReducer(AdminReducer, value);

  useEffect(() => {
    dispatch({ type: "SET_ALL", payload: value });
  }, [value]);

  const formattedState: FormattedAdminState = useMemo(
    () =>
      Object.entries(state).reduce(
        (prev, [key, val]) => ({
          ...prev,
          [key]: Array.isArray(val) || val.disableFormatting ? val : Object.values(val),
        }),
        {} as FormattedAdminState,
      ),
    [state],
  );

  return <AdminContextProvider value={{ ...formattedState, dispatch }}>{props.children}</AdminContextProvider>;
};

const AdminConsumer = AdminContext.Consumer;

export { AdminContext, AdminConsumer, AdminProvider, ADMIN_TYPES, DEFAULT_ADMIN_STATE };
