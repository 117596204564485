import type { RegisterFormValues } from "client/components/RegisterForm.component";
import Cookies from "js-cookie";
import { STORAGE_KEYS } from "./constants";

export const setToken = (token: string) => Cookies.set(STORAGE_KEYS.AUTH_TOKEN, token);

export const getToken = () => Cookies.get(STORAGE_KEYS.AUTH_TOKEN);

export const clearToken = () => Cookies.remove(STORAGE_KEYS.AUTH_TOKEN);

export const setRegisterFormValues = (values: Partial<RegisterFormValues>) => window.sessionStorage.setItem("registerValues", JSON.stringify(values));

export const clearRegisterFormValues = () => window.sessionStorage.removeItem("registerValues");

export const getRegisterFormValues = (): Partial<RegisterFormValues> => {
  try {
    if (typeof window === "undefined") return {};
    const registerValuesString = window.sessionStorage.getItem("registerValues");
    if (!registerValuesString) return {};
    return JSON.parse(registerValuesString);
  } catch (error) {
    console.error(error);
    return {};
  }
};
