import { Dialog, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import { FC, Fragment, useCallback, useMemo } from "react";
import { APPLICATION_STATES } from "../../../utils/constants";
import { formatThousands } from "../../../utils/helpers";
import { DbUser } from "server/services/user/user.types";
import moment from "moment";

interface Props {
  data?: any;
  open: any;
}

const WeeklyDetailed: FC<Props> = ({ data, ...props }) => {
  const applicationStates = useMemo(() => Object.entries(APPLICATION_STATES), []);

  const FilteredList = useCallback(
    ({ value }) => {
      const filteredUsers =
        data.weeklyUsers &&
        data.weeklyUsers.filter(
          ({ applicationStatus }: { applicationStatus: string }) => applicationStatus && applicationStatus.toLowerCase() === value.toLowerCase(),
        );

      return filteredUsers.length ? (
        filteredUsers.map((user: DbUser) => (
          <Typography key={user._id} variant="body2" gutterBottom>
            {user.forenames} {user.surname} - {user.email}
          </Typography>
        ))
      ) : (
        <Typography variant="body2" gutterBottom>
          No users with this application state
        </Typography>
      );
    },
    [data.weeklyUsers],
  );

  return (
    <Dialog {...props}>
      <DialogTitle>Weekly user application statuses for week beginning {moment(data.weekStart).format("L")}</DialogTitle>
      <DialogContent>
        <Typography variant="h4" gutterBottom>
          Funds signed for and/or received
        </Typography>
        {data.weeklyUsers &&
          data.weeklyUsers.map((user: any) =>
            user.fundsReceived.length || user.fundsSigned.length ? (
              <Fragment key={user._id}>
                <Typography variant="body1" gutterBottom>
                  {user.forenames} {user.surname} - {user.email}
                </Typography>
                {user.fundsSigned.map(
                  (application: any) =>
                    application.amount && (
                      <Typography variant="body2" gutterBottom>
                        Signed for {formatThousands(application.amount, 2)} on {moment(application.completedTimestamp).format("L")}
                      </Typography>
                    ),
                )}
                {user.fundsReceived.map(
                  (investment: any) =>
                    investment.amount && (
                      <Typography variant="body2" gutterBottom color="primary">
                        Received {formatThousands(investment.amount, 2)} on {moment(investment.date).format("L")}
                      </Typography>
                    ),
                )}
              </Fragment>
            ) : null,
          )}
        <br />
        <Typography variant="h4" gutterBottom>
          Users statuses
        </Typography>
        {applicationStates.map(([key, value]) => (
          <Fragment key={key}>
            <Typography variant="body1" gutterBottom>
              {value}
            </Typography>
            <FilteredList value={value} />
          </Fragment>
        ))}
      </DialogContent>
    </Dialog>
  );
};

export default WeeklyDetailed;
