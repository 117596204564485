import { Button, Dialog, DialogContent, DialogProps, DialogTitle, FormGroup, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Field, FieldArray, Form, Formik } from "formik";
import { generate } from "generate-password";
import moment from "moment";
import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Address } from "server/services/application/application.types";
import { LeanUser } from "server/services/user/user.types";
import * as Yup from "yup";
import { addAdvisor, addClient, addUser, getUsers, removeUser, updateAdvisor, updateClient, updateUser } from "../../../api/admin/users.adminApi";
import CheckboxQuestion from "../../../components/FormControls/CheckboxQuestion.component";
import CrudFormButtons from "../../../components/FormControls/CrudFormButtons.component";
import DatePickerField from "../../../components/FormControls/DatePickerField.component";
import NumberQuestion from "../../../components/FormControls/NumberQuestion.component";
import PostcodeLookup from "../../../components/FormControls/PostcodeLookupField.component";
import RadioQuestion from "../../../components/FormControls/RadioQuestion.component";
import SelectField from "../../../components/FormControls/SelectField.component";
import TextQuestion from "../../../components/FormControls/TextQuestion.component";
import FormikErrorsTouched from "../../../components/FormikErrorsTouched.component";
import QuestionLabel from "../../../components/QuestionLabel.component";
import { AdminContext, ADMIN_TYPES } from "../../../context/admin.context";
import { APPLICATION_STATES, NATIONALITY_DATA, USER_ROLE, UsCitizen } from "../../../utils/constants";

interface Props extends DialogProps {
  onClose(): any;
  user?: LeanUser;
}
//object return array of roles
const ROLES = Object.values(USER_ROLE);
const QUALIFICATIONS = {
  ADVISED_INVESTOR: "ADVISED_INVESTOR",
  PROFESSIONAL_INVESTOR: "PROFESSIONAL_INVESTOR",
  SELF_CERTIFIED_SOPHISTICATED_INVESTOR: "SELF_CERTIFIED_SOPHISTICATED_INVESTOR",
  HIGH_NET_WORTH: "HIGH_NET_WORTH",
  RESTRICTED: "RESTRICTED",
};

const BLANK_ADDRESS = {
  addressLineOne: "",
  addressLineTwo: "",
  city: "",
  country: "",
  postcode: "",
  lengthYears: "",
  lengthMonths: "",
};

interface UserOption {
  value: string;
  label: string;
}

const UserForm: React.FC<Props> = ({ onClose, user, ...props }) => {
  const [investorOptions, setInvestorOptions] = useState<UserOption[]>([]);
  const [advisorOptions, setAdvisorOptions] = useState<UserOption[]>([]);
  const [associateOptions, setAssociateOptions] = useState<UserOption[]>([]);
  const { allUsers, dispatch } = useContext(AdminContext);

  useEffect(() => {
    (async () => {
      const res = await getUsers();
      if (res) {
        dispatch({ type: ADMIN_TYPES.SET_ALL_USERS, payload: res.data });
      }
    })();
  }, [dispatch]);

  useEffect(() => {
    const investors = allUsers
      .filter((u) => !u.advisorDetails || typeof u.advisorDetails.isVerified !== "boolean")
      .map((investor) => ({
        value: investor._id,
        label: `${investor.forenames} ${investor.surname} - ${investor.email}`,
      }));

    const advisors = allUsers
      .filter((u) => Boolean(u.advisorDetails && u.advisorDetails.isVerified))
      .map((advisorUser) => ({
        value: advisorUser._id,
        label: `${advisorUser.forenames} ${advisorUser.surname} - ${advisorUser.email}`,
      }));

    const associates = allUsers
      .filter((u) => u.role === "readOnlyAdvisor")
      .map((a) => ({
        value: a._id,
        label: `${a.forenames} ${a.surname} - ${a.email}`,
      }));

    setInvestorOptions(investors);
    setAdvisorOptions(advisors);
    setAssociateOptions(associates);
  }, [allUsers]);

  const initialValues = useMemo(
    () => ({
      title: user?.title ?? "",
      forenames: user?.forenames ?? "",
      surname: user?.surname ?? "",
      email: user?.email ?? "",
      isConfirmed: user?.isConfirmed ?? false,
      phoneNumber: user?.phoneNumber ?? "",
      registrationDate: user ? user.registrationDate || null : moment().format("YYYY-MM-DD"),
      role: user?.role ?? "",
      investorType: user?.investorType ?? "Direct",
      qualification: user?.qualification ?? { qualification: "" },
      minInvestment: user?.minInvestment ?? null,
      maxInvestment: user?.maxInvestment ?? null,
      applicationStatus: user ? user.applicationStatus : "",
      personalDetails: {
        contactNumber: user?.personalDetails?.contactNumber ?? "",
        dob: user?.personalDetails?.dob ?? "",
        addressHistory: user?.personalDetails?.addressHistory ?? [],
        placeOfBirth: user?.personalDetails?.placeOfBirth ?? "",
        nationality: user?.personalDetails?.nationality ?? "GB",
        taxResidency: user?.personalDetails?.taxResidency ?? "GB",
        tiNumber: user?.personalDetails?.tiNumber ?? "",
        usCitizen: user?.personalDetails?.usCitizen ?? "No",
        politicallyExposed: user?.personalDetails?.politicallyExposed ?? false,
        dualTaxResidency: user?.personalDetails?.dualTaxResidency ?? false,
        dualTaxResidencyNationality: user?.personalDetails?.dualTaxResidencyNationality ?? "GB",
        dualTaxResidencyTINumber: user?.personalDetails?.dualTaxResidencyTINumber ?? "",
        dualTaxResidencyAddressHistory: user?.personalDetails?.dualTaxResidencyAddressHistory ?? [],
      },
      password: !user && generate({ length: 10 }),
      usersAdvisor: {
        advisor: "",
        adviceType: "",
        feePercentage: 0,
        disableEmails: false,
        ...(user?.advisor || {}),
      },
      advisor:
        user && user.advisorDetails
          ? {
              clients: (user.advisorDetails as any).clients.map((cli: LeanUser) => ({
                clientId: cli._id,
                adviceType: cli.advisor!.adviceType,
                feePercentage: cli.advisor!.feePercentage,
                associates: cli.advisor!.associates || [],
              })),
              company: user.advisorDetails.company,
              contactNumber: user.advisorDetails.contactNumber,
              fcaNumber: user.advisorDetails.fcaNumber,
              isNetworkService: user.advisorDetails.isNetworkService,
              networkService: user.advisorDetails.networkService,
            }
          : {
              clients: [],
              company: "",
              contactNumber: "",
              fcaNumber: "",
              isNetworkService: "",
              networkService: "",
            },
    }),
    [user],
  );

  const handleSubmit = useCallback(
    async ({ password, advisor, ...values }) => {
      let newUser;
      if (initialValues.personalDetails.contactNumber !== values.personalDetails.contactNumber) {
        values.phoneNumber = values.personalDetails.contactNumber;
      }
      if (values.investorType === "Advisor") {
        newUser = await (user ? updateAdvisor({ advisor, ...values }, user._id) : addAdvisor({ advisor, password, ...values }));
      } else if (values.investorType === "Advised" || values.investorType === "Referred") {
        newUser = await (user ? updateClient(values, user._id) : addClient({ password, ...values }));
      } else {
        newUser = await (user ? updateUser(values, user._id) : addUser({ password, ...values }));
      }
      if (newUser) {
        dispatch({ type: ADMIN_TYPES.UPDATE_USER, payload: newUser });
        onClose();
      }
    },
    [dispatch, onClose, user, initialValues.personalDetails.contactNumber],
  );

  const handleDelete = useCallback(async () => {
    if (user) {
      const userId = await removeUser(user._id);

      if (userId) {
        dispatch({ type: ADMIN_TYPES.DELETE_USER, payload: userId });
        onClose();
      }
    }
  }, [dispatch, onClose, user]);

  const baseSchema = {
    advisor: Yup.string().required("Please select an adviser"),
    adviceType: Yup.string()
      .matches(/(REFERRED|ADVISED)/, "Must be one of REFERRED or ADVISED")
      .required("Please make sure the advice type is selected"),
    feePercentage: Yup.number()
      .min(0, "Fee percentage must be greater than 0")
      .max(3, "Fee percentage must be less than or equal to 3")
      .required("Please enter the investor's fee percentage"),
  };

  const SCHEMA = useMemo(
    () =>
      Yup.object({
        title: Yup.string().nullable(),
        forenames: Yup.string().required("Please enter the user's forenames"),
        surname: Yup.string().required("Please enter the user's surname"),
        email: Yup.string().email("Please enter a valid email").required("Please enter the user's email address"),
        phoneNumber: Yup.string().nullable(),
        isConfirmed: Yup.boolean(),
        registrationDate: Yup.date().nullable().default(null).notRequired(),
        password: !user ? Yup.string().min(6, "Please make sure the password is at least six characters long") : Yup.string().nullable(),
        role: Yup.string().oneOf(ROLES).required("Please select a role for the user's permissions"),
        qualification: Yup.object().when("investorType", {
          is: "Advised",
          then: Yup.object({
            qualification: Yup.string()
              .oneOf(
                [QUALIFICATIONS.ADVISED_INVESTOR],
                `Qualification must be ${QUALIFICATIONS.ADVISED_INVESTOR} as the investor is advised by a financial adviser`,
              )
              .required(`Investor is an advised investor and so their qualification must be set to ${QUALIFICATIONS.ADVISED_INVESTOR}`),
          }).required(`Please select the ${QUALIFICATIONS.ADVISED_INVESTOR} qualification as they are an Advised investor type`),
          otherwise: Yup.object({
            qualification: Yup.string()
              .oneOf(
                Object.values(QUALIFICATIONS).filter((qualification) => qualification !== QUALIFICATIONS.ADVISED_INVESTOR),
                `Qualification must not be ${QUALIFICATIONS.ADVISED_INVESTOR} when the investor is not advised by a financial adviser`,
              )
              .notRequired(),
          }).notRequired(),
        }),
        applicationStatus: Yup.string().oneOf(Object.values(APPLICATION_STATES)).required(),
        personalDetails: Yup.object({
          contactNumber: Yup.string().nullable(),
          addressHistory: Yup.array(
            Yup.object({
              addressLineOne: Yup.string().required("Please enter an address line"),
              addressLineTwo: Yup.string().notRequired(),
              city: Yup.string().required("Please enter a city"),
              country: Yup.string().required("Please enter a country"),
              postcode: Yup.string().required("Please enter a postcode"),
              lengthYears: Yup.number().min(0, "Invalid number of years").nullable(),
              lengthMonths: Yup.number().min(0, "Invalid number of months").max(11, "Invalid number of months").nullable(),
            }),
          )
            .min(0)
            .max(3),
          dob: Yup.date().nullable().default(null).notRequired(),
          placeOfBirth: Yup.string(),
          nationality: Yup.string(),
          taxResidency: Yup.string(),
          tiNumber: Yup.string(),
          dualTaxResidency: Yup.boolean(),
          usCitizen: Yup.string(),
          politicallyExposed: Yup.boolean(),
          dualTaxResidencyTINumber: Yup.string(),
          dualTaxResidencyAddressHistory: Yup.array().when("dualTaxResidency", {
            is: true,
            then: Yup.array(
              Yup.object({
                addressLineOne: Yup.string().required("Please enter an address line"),
                addressLineTwo: Yup.string().notRequired(),
                city: Yup.string().required("Please enter a city"),
                country: Yup.string().required("Please enter a country"),
                postcode: Yup.string().required("Please enter a postcode"),
                lengthYears: Yup.number()
                  .typeError("Please enter a number of years")
                  .min(0, "Invalid number of years")
                  .required("Please enter a number of years"),
                lengthMonths: Yup.number()
                  .typeError("Please enter a number of months")
                  .min(0, "Invalid number of months")
                  .max(11, "Invalid number of months")
                  .required("Please enter a number of months"),
              }),
            )
              .min(1)
              .max(3)
              .required("Please enter your secondary residence address"),
            otherwise: Yup.array(),
          }),
        }),
        investorType: !user
          ? Yup.string()
              .matches(/(Direct|Advisor|Referred|Advised)/, "Must be one of Direct or Advisor or Referred or Advised")
              .required("Please make sure the investor type is selected")
          : Yup.string().nullable(),
        usersAdvisor: Yup.object().when("investorType", {
          is: (investorType?: string) => investorType === "Advised" || investorType === "Referred",
          then: Yup.object().when("investorType", {
            is: (investorType?: string) => investorType === "Referred",
            then: Yup.object(baseSchema),
            otherwise: Yup.object({ ...baseSchema, disableEmails: Yup.boolean().required("Please indicate if the investor would like emails") }),
          }),
        }),
        minInvestment: Yup.number().nullable(),
        maxInvestment: Yup.number().nullable(),
        advisor: Yup.object().when("investorType", {
          is: "Advisor",
          then: Yup.object({
            company: Yup.string(),
            contactNumber: Yup.string(),
            fcaNumber: Yup.string(),
            isNetworkService: Yup.bool(),
            networkService: Yup.string().when("isNetworkService", {
              is: true,
              then: Yup.string().required("Please indicate the adviser's network service"),
            }),
            clients: Yup.array(
              Yup.object({
                clientId: Yup.string().required("Please indicate a client"),
                adviceType: Yup.string().required("Please enter the client's advice type"),
                feePercentage: Yup.number()
                  .min(0, "Fee percentage must be greater than 0")
                  .max(3, "Fee percentage must be less than or equal to 3")
                  .required("Please enter the client's fee percentage"),
                associates: Yup.array(Yup.string()),
              }),
            ),
          }).required(),
        }),
      }),
    [user, baseSchema],
  );

  return (
    <Dialog onClose={onClose} {...props}>
      <DialogTitle>Add User</DialogTitle>
      <DialogContent>
        <Formik onSubmit={handleSubmit} validationSchema={SCHEMA} initialValues={initialValues}>
          {({ values, setValues, setFieldValue }) => (
            <Form data-testid="userForm">
              <FormikErrorsTouched />
              <FormGroup>
                <Field component={TextQuestion} name="title" data-testid="title" placeholder="Title" label="Title" />
              </FormGroup>
              <FormGroup>
                <Field component={TextQuestion} name="forenames" data-testid="forename" placeholder="Forenames" label="Forenames" />
              </FormGroup>
              <FormGroup>
                <Field component={TextQuestion} name="surname" data-testid="surname" placeholder="Surname" label="Surname" />
              </FormGroup>
              <FormGroup>
                <Field component={DatePickerField} name="personalDetails.dob" data-testid="dob" label="Date of Birth" autoComplete="off" nullable />
              </FormGroup>
              <FormGroup>
                <hr />
                <Field component={TextQuestion} name="email" data-testid="email" placeholder="Email" label="Email" />
                <br />
                {values.investorType === "Advised" ? (
                  <Field component={CheckboxQuestion} name="usersAdvisor.disableEmails" label="Disable Emails for this investor?" />
                ) : null}
                <FormGroup row>
                  <Field component={CheckboxQuestion} name="isConfirmed" data-testid="isConfirmed" label="Email confirmed" />
                  <Button
                    variant="text"
                    size="small"
                    onClick={() =>
                      setFieldValue(
                        "email",
                        `placeholder+${generate({
                          length: 8,
                          lowercase: true,
                          numbers: true,
                          uppercase: false,
                          symbols: false,
                        })}@novagrowthcapital.co.uk`,
                      )
                    }
                  >
                    Use Placeholder Email
                  </Button>
                </FormGroup>
                <FormGroup>
                  <Field
                    component={TextQuestion}
                    name="personalDetails.contactNumber"
                    data-testid="phoneNumber"
                    label="Contact Number"
                    placeholder="Contact Number"
                  />
                </FormGroup>
                <hr />
              </FormGroup>
              <FormGroup>
                <Field component={TextQuestion} name="personalDetails.placeOfBirth" data-testid="placeOfBirth" label="Place of Birth" />
              </FormGroup>
              <FormGroup>
                <Field
                  component={SelectField}
                  name="personalDetails.nationality"
                  data-testid="nationality"
                  label="Nationality"
                  options={NATIONALITY_DATA}
                />
              </FormGroup>
              <FormGroup>
                <Field
                  component={SelectField}
                  name="personalDetails.taxResidency"
                  data-testid="taxResidency"
                  label="Tax Residency"
                  options={NATIONALITY_DATA}
                />
              </FormGroup>
              <FormGroup>
                <Field component={TextQuestion} name="personalDetails.tiNumber" data-testid="tiNumber" label="Tax Identification Number" />
              </FormGroup>
              <FormGroup>
                <Field
                  component={SelectField}
                  name="personalDetails.usCitizen"
                  placeholder="Are you a US citizen?"
                  label="Are you a US citizen?"
                  options={Object.values(UsCitizen)}
                />
              </FormGroup>
              <FormGroup>
                <Field
                  component={CheckboxQuestion}
                  name="personalDetails.politicallyExposed"
                  label="Are you a Politically Exposed Person or a close relative or associate of one?"
                />
              </FormGroup>
              {values.personalDetails.politicallyExposed && (
                <FormGroup>
                  <Field component={TextQuestion} name="personalDetails.politicallyExposedDetails" label="Please provide full details" />
                </FormGroup>
              )}
              <FormGroup>
                <Field component={CheckboxQuestion} name="personalDetails.dualTaxResidency" label="Are you a Dual Tax Resident?" />
              </FormGroup>
              {values.personalDetails.dualTaxResidency ? (
                <>
                  <FormGroup>
                    <Field
                      component={SelectField}
                      name="personalDetails.dualTaxResidencyNationality"
                      label="Second tax residency country"
                      options={NATIONALITY_DATA}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Field
                      component={TextQuestion}
                      name="personalDetails.dualTaxResidencyTINumber"
                      label="Tax identification number for second residency"
                    />
                  </FormGroup>
                </>
              ) : null}
              <hr />
              <FormGroup>
                <Field
                  component={DatePickerField}
                  name="registrationDate"
                  data-testid="registrationDate"
                  label="Registration Date"
                  autoComplete="off"
                  nullable
                />
              </FormGroup>
              <FormGroup>
                <Field component={SelectField} name="role" placeholder="Role" data-testid="role" label="Role" options={ROLES} />
              </FormGroup>
              <FormGroup>
                <Field
                  component={SelectField}
                  name="investorType"
                  label="Investor Type"
                  placeholder="Investor Type"
                  data-testid="investorType"
                  options={["Direct", "Advisor", "Referred", "Advised"]}
                  onChange={(_event: any, selectedOption: LeanUser["investorType"]) =>
                    setValues({
                      ...values,
                      ...(selectedOption === "Advised"
                        ? {
                            qualification: {
                              qualification: QUALIFICATIONS.ADVISED_INVESTOR,
                            },
                          }
                        : {}),
                      investorType: selectedOption,
                      usersAdvisor: {
                        ...values.usersAdvisor,
                        adviceType: selectedOption && selectedOption.toUpperCase(),
                      },
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Field
                  component={SelectField}
                  name="qualification.qualification"
                  label="Qualification"
                  placeholder="Qualification"
                  data-testid="qualification"
                  options={Object.values(QUALIFICATIONS)}
                />
              </FormGroup>
              <FormGroup>
                <Field
                  component={SelectField}
                  name="applicationStatus"
                  placeholder="Application Status"
                  data-testid="applicationStatus"
                  label="Application Status"
                  options={Object.values(APPLICATION_STATES)}
                />
              </FormGroup>
              <FormGroup>
                <Field
                  component={NumberQuestion}
                  moneyFormat
                  name="minInvestment"
                  label="Min Investment"
                  data-testid="minInvestment"
                  placeholder="Min Investment"
                  prefix="£"
                  min="5000"
                  max="10000"
                />
              </FormGroup>
              <FormGroup>
                <Field
                  component={NumberQuestion}
                  moneyFormat
                  name="maxInvestment"
                  label="Max Investment"
                  data-testid="maxInvestment"
                  placeholder="Max Investment"
                  prefix="£"
                  min="10000"
                />
              </FormGroup>
              <FormGroup>
                <FieldArray name="personalDetails.addressHistory">
                  {({ push, remove }) => (
                    <>
                      {values.personalDetails.addressHistory.map((address, index) => (
                        <FormGroup key={index}>
                          <FormGroup row>
                            <QuestionLabel>Address {index + 1}</QuestionLabel>
                            <IconButton size="small" edge="end" style={{ flex: 0 }} onClick={() => remove(index)}>
                              <CloseIcon />
                            </IconButton>
                          </FormGroup>
                          <FormGroup>
                            <Field
                              component={PostcodeLookup}
                              name={`personalDetails.addressHistory[${index}]`}
                              onChange={(selectedAddress: Address) =>
                                setFieldValue(`personalDetails.addressHistory[${index}]`, {
                                  ...address,
                                  ...selectedAddress,
                                })
                              }
                            />
                          </FormGroup>
                          <FormGroup row>
                            <Field
                              component={NumberQuestion}
                              name={`personalDetails.addressHistory[${index}].lengthYears`}
                              suffix="Years"
                              min="0"
                              data-testid="years"
                              max="99"
                              step="1"
                            />
                            <Field
                              component={NumberQuestion}
                              name={`personalDetails.addressHistory[${index}].lengthMonths`}
                              suffix="Months"
                              data-testid="month"
                              min="0"
                              max="11"
                              step="1"
                            />
                          </FormGroup>
                        </FormGroup>
                      ))}
                      <hr />
                      <Button onClick={() => push(BLANK_ADDRESS)} data-testid="addAddress">
                        Add address
                      </Button>
                    </>
                  )}
                </FieldArray>
              </FormGroup>
              {values.personalDetails.dualTaxResidency ? (
                <FormGroup>
                  <FieldArray name="personalDetails.dualTaxResidencyAddressHistory">
                    {({ push, remove }) => (
                      <>
                        {values.personalDetails.dualTaxResidencyAddressHistory.map((address, index) => (
                          <FormGroup key={index}>
                            <FormGroup row>
                              <QuestionLabel>Dual Tax Residence Address {index + 1}</QuestionLabel>
                              <IconButton size="small" edge="end" style={{ flex: 0 }} onClick={() => remove(index)}>
                                <CloseIcon />
                              </IconButton>
                            </FormGroup>
                            <FormGroup>
                              <Field
                                component={PostcodeLookup}
                                name={`personalDetails.dualTaxResidencyAddressHistory[${index}]`}
                                onChange={(selectedAddress: Address) =>
                                  setFieldValue(`personalDetails.dualTaxResidencyAddressHistory[${index}]`, {
                                    ...address,
                                    ...selectedAddress,
                                  })
                                }
                              />
                            </FormGroup>
                            <FormGroup row>
                              <Field
                                component={NumberQuestion}
                                name={`personalDetails.dualTaxResidencyAddressHistory[${index}].lengthYears`}
                                suffix="Years"
                                min="0"
                                max="99"
                                step="1"
                              />
                              <Field
                                component={NumberQuestion}
                                name={`personalDetails.dualTaxResidencyAddressHistory[${index}].lengthMonths`}
                                suffix="Months"
                                min="0"
                                max="11"
                                step="1"
                              />
                            </FormGroup>
                          </FormGroup>
                        ))}
                        <hr />
                        <Button onClick={() => push(BLANK_ADDRESS)}>Add dual Tax Residency Address</Button>
                      </>
                    )}
                  </FieldArray>
                </FormGroup>
              ) : null}
              {!user && (
                <FormGroup>
                  <Field
                    component={TextQuestion}
                    name="password"
                    data-testid="password"
                    placeholder="Temporary  Password"
                    label="Temporary Password"
                    disabled
                  />
                </FormGroup>
              )}
              {(values.investorType === "Advised" || values.investorType === "Referred") && (
                <>
                  <FormGroup>
                    <Field
                      component={SelectField}
                      name="usersAdvisor.advisor"
                      placeholder="Select an adviser"
                      label="Select an adviser"
                      options={advisorOptions}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Field component={SelectField} name="usersAdvisor.adviceType" label="Advice type" options={["ADVISED", "REFERRED"]} disabled />
                  </FormGroup>
                  <FormGroup>
                    <Field component={NumberQuestion} label="Fee percentage" placeholder="Fee percentage" name="usersAdvisor.feePercentage" />
                  </FormGroup>
                </>
              )}
              {values.investorType === "Advisor" && (
                <>
                  <FormGroup>
                    <Field name="advisor.company" component={TextQuestion} placeholder="Company name" label="Adviser's company" />
                  </FormGroup>
                  <FormGroup>
                    <Field name="advisor.contactNumber" component={TextQuestion} placeholder="Contact number" label="Adviser's number" />
                  </FormGroup>
                  <FormGroup>
                    <Field name="advisor.fcaNumber" component={TextQuestion} placeholder="FCA number" label="Adviser's FCA number" />
                  </FormGroup>
                  <FormGroup>
                    <Field name="advisor.isNetworkService" component={RadioQuestion} label="Network Service" />
                  </FormGroup>
                  {values.advisor.isNetworkService && (
                    <FormGroup>
                      <Field name="advisor.networkService" component={TextQuestion} placeholder="Network Service" label="Network Service" />
                    </FormGroup>
                  )}
                  <FormGroup>
                    <FieldArray name="advisor.clients">
                      {(clients) => (
                        <>
                          {values.advisor.clients.map((_client: LeanUser, index: number) => (
                            <Fragment key={index}>
                              <FormGroup row>
                                <QuestionLabel>Client {index + 1}</QuestionLabel>
                                <IconButton size="small" edge="end" style={{ flex: 0 }} onClick={() => clients.remove(index)}>
                                  <CloseIcon />
                                </IconButton>
                              </FormGroup>
                              <FormGroup>
                                <Field
                                  name={`advisor.clients[${index}].clientId`}
                                  component={SelectField}
                                  options={investorOptions}
                                  placeholder="Client"
                                />
                              </FormGroup>
                              <FormGroup>
                                <Field
                                  name={`advisor.clients[${index}].adviceType`}
                                  component={SelectField}
                                  options={["ADVISED", "REFERRED"]}
                                  label="Advice type"
                                  placeholder="Advice Type"
                                />
                              </FormGroup>
                              <FormGroup>
                                <Field name={`advisor.clients[${index}].feePercentage`} component={NumberQuestion} label="Fee percentage" />
                              </FormGroup>
                              <FormGroup>
                                <Field
                                  name={`advisor.clients[${index}].associates`}
                                  component={SelectField}
                                  label="Associate(s)"
                                  options={associateOptions.filter((option) => !user || option.value !== user._id)}
                                  onChange={(_e: any, selected: any[]) =>
                                    setFieldValue(
                                      `advisor.clients[${index}].associates`,
                                      selected.map((selectedOption) => selectedOption.value),
                                    )
                                  }
                                  multiple
                                  filterSelectedOptions
                                />
                              </FormGroup>
                            </Fragment>
                          ))}
                          <Button onClick={() => clients.push({ clientId: "", adviceType: "", feePercentage: 0 })}>Add Client</Button>
                        </>
                      )}
                    </FieldArray>
                  </FormGroup>
                </>
              )}
              <CrudFormButtons isEdit={Boolean(user)} handleDelete={handleDelete} onClose={onClose} />
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default UserForm;
