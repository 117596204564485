import { Typography, createStyles, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import InfoIcon from "@material-ui/icons/Info";
import DissolvedWarningPopup from "./DissolvedWarningPopup.component";

interface Props {
  companyName: string;
}
const useStyles = makeStyles(
  (theme) =>
    createStyles({
      dissolvedCompanyNotification: {
        display: "flex",
        alignItems: "center",
        marginRight: theme.spacing(3),
      },
      dissolvedCompanyNotificationLabel: {
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.error.main,
        cursor: "default",
      },
      infoIcon: {
        color: theme.palette.error.main,
        marginLeft: theme.spacing(1),
        fontSize: "1.2rem",
      },
    }),
  { name: "DissolvedWarning" },
);

const DissolvedWarning: React.FC<Props> = ({ companyName, ...props }) => {
  const classes = useStyles(props);
  const [showDissolvedPopup, setShowDissolvedPopup] = useState<boolean>(false);
  return (
    <div className={classes.dissolvedCompanyNotification} data-testid="dissolved-warning">
      <Typography
        className={classes.dissolvedCompanyNotificationLabel}
        variant="h5"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        DISSOLVED
      </Typography>
      <InfoIcon
        fontSize="small"
        className={classes.infoIcon}
        onClick={(e) => {
          e.stopPropagation();
          setShowDissolvedPopup(true);
        }}
        data-testid="dissolved-warning-icon"
      />
      {showDissolvedPopup && <DissolvedWarningPopup companyName={companyName} onClose={() => setShowDissolvedPopup(false)} />}
    </div>
  );
};

export default DissolvedWarning;
