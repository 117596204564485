import { createStyles, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React, { useCallback, useEffect, useRef, useState } from "react";

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  alt: string;
}

const useStyles = makeStyles(
  () =>
    createStyles({
      "@keyframes imageFadeIn": {
        from: { opacity: 0 },
      },
      image: {
        animation: "$imageFadeIn 2s",
      },
    }),
  { name: "ImageComponent" },
);

const Image: React.FC<Props> = ({ src, ...props }) => {
  const classes = useStyles(props);
  const imageRef = useRef<HTMLImageElement>(null);
  const [loaded, setLoaded] = useState(false);

  const handleLoaded = useCallback(() => setLoaded(true), []);

  useEffect(() => {
    if (imageRef.current?.complete) handleLoaded();
  }, [handleLoaded]);

  return (
    <img
      {...props}
      ref={imageRef}
      src={src}
      alt={props.alt}
      onLoad={handleLoaded}
      onError={handleLoaded}
      className={clsx(props.className, { [classes.image]: loaded })}
    />
  );
};

export default Image;
