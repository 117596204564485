import { Button, CircularProgress, Container, makeStyles, Typography } from "@material-ui/core";
import { ConfigContext } from "client/context/config.context";
import useToggle from "client/hooks/useToggle.hook";
import jwt from "jsonwebtoken";
import qs from "querystring";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import PublicAuthenticationApi from "../api/public/authentication.publicApi";
import BasicPageTemplate from "../components/BasicPageTemplate.component";
import { AuthContext, AUTH_TYPES } from "../context/auth.context";
import useAnalytics from "../hooks/useAnalytics";
import { ROUTES } from "../utils/constants";

const useStyles = makeStyles(
  () => ({
    spinner: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  }),
  { name: "ConfirmEmailView" },
);

const ConfirmEmail: React.FC = (props) => {
  const classes = useStyles(props);
  const history = useHistory();
  const location = useLocation();
  const {
    state: { user },
    dispatch,
  } = useContext(AuthContext);
  const { fund, imDefaultPath } = useContext(ConfigContext);

  const [resendClicked, setResendClicked] = useState(false);
  const [confirmed, setConfirmed] = useState<boolean | null>(null);
  const [loading, , , doneLoading] = useToggle(true);

  const token = useMemo(() => qs.parse(location.search.substr(1)).token, [location.search]);

  const userId = useMemo(() => {
    if (!token) return;
    const decodedToken = jwt.decode(token as string) as null | { data: string };
    return decodedToken?.data;
  }, [token]);
  useAnalytics();

  useEffect(() => {
    (async () => {
      if (typeof confirmed === "boolean") return doneLoading();
      const res = await PublicAuthenticationApi.confirmEmail({
        token,
      });
      if (typeof res === "boolean") {
        setConfirmed(res);
        if (res) dispatch({ type: AUTH_TYPES.SET_CONFIRMED, payload: true });
      }
      doneLoading();
    })();
  }, [confirmed, dispatch, doneLoading, token]);

  const handleClick = useCallback(() => (user ? history.push(imDefaultPath) : history.push(ROUTES.LOGIN)), [history, imDefaultPath, user]);

  const btnText = useMemo(() => (user ? "Continue" : "Login"), [user]);

  const handleResend = useCallback(async () => {
    setResendClicked(true);
    const res = await PublicAuthenticationApi.publicResend(userId);
    if (!res) {
      setResendClicked(false);
    }
  }, [userId]);

  return loading ? (
    <Container maxWidth="md" className={classes.spinner}>
      <CircularProgress size="3rem" />
      <br />
      <Typography>Please wait....</Typography>
    </Container>
  ) : typeof confirmed !== "boolean" ? (
    <Container maxWidth="md">
      <Typography variant="h1" align="center" gutterBottom>
        Your link has expired
      </Typography>
      <Typography variant="body1" align="center" gutterBottom>
        For security reasons our invitation links expire after 24 hours, please select the button below for a new link to be sent to your email.
      </Typography>
      <Button onClick={handleResend} disabled={resendClicked} fullWidth>
        Resend Link
      </Button>
    </Container>
  ) : confirmed ? (
    <BasicPageTemplate
      title="Email Confirmed"
      body={`Thank you for confirming your email. You are now able to continue with your application to invest in the ${fund.name}.`}
      buttonText={btnText}
      onClick={handleClick}
    />
  ) : (
    <BasicPageTemplate
      title="Email Previously Confirmed"
      body={`This email has already been confirmed, you are able to continue your application to invest in the ${fund.name}.`}
      buttonText={btnText}
      onClick={handleClick}
    />
  );
};

export default ConfirmEmail;
