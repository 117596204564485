import { Typography } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { getAllDocuments } from "../../../api/document.api";
import { TRACKED_EVENTS } from "../../../utils/constants";
import DocumentCard from "../components/DocumentCard.component";
import { useStyles as externalStyles } from "../useStyles";

const { PAGE_VIEWS } = TRACKED_EVENTS;

const Documents: React.FC = (props) => {
  const classes = externalStyles(props);
  const [documentsData, setDocumentsData] = useState<GenericObject[]>([]);

  const fetchDocuments = useCallback(async () => {
    const { data } = await getAllDocuments("im");
    setDocumentsData(data);
  }, [setDocumentsData]);

  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments]);

  useEffect(() => {
    globalThis.dataLayer.push({ event: PAGE_VIEWS.IM_DOCUMENTS });
  }, []);

  return (
    <>
      <Typography variant="h1" gutterBottom>
        Fund Documents
      </Typography>
      <div className={classes.documentWrapper}>
        {documentsData.map((doc) => (
          <DocumentCard
            key={doc.title}
            documentId={doc._id}
            documentInitialKey={doc.documentInitials}
            documentName={doc.title}
            documentVersion={doc.versionNumber}
            documentDate={doc.date}
          />
        ))}
      </div>
    </>
  );
};

export default Documents;
