import { Button, ButtonProps } from "@material-ui/core";
import React, { useCallback } from "react";
import AuthenticationApi from "../../api/authentication.api";
import { TRACKED_EVENTS } from "../../utils/constants";
import notifications from "../../utils/notifications";

const ResendConfirmationButton: React.FC<ButtonProps> = (props) => {
  const onClick = useCallback(async () => {
    globalThis.dataLayer.push({ event: TRACKED_EVENTS.PAGE_ACTIONS.RESEND_CONFIRMATION_EMAIL });
    const success = await AuthenticationApi.resendConfirmation();
    if (success) notifications.success("Email sent");
  }, []);

  return (
    <Button {...props} data-testid="confirmedEmailButton" onClick={onClick}>
      {props.children}
    </Button>
  );
};

export default ResendConfirmationButton;
