import { ColumnDefinition } from "@wearenova/mui-data-table";
import moment from "moment";
import { FC, useContext, useEffect, useMemo, useState } from "react";
import { getStats } from "../../../api/admin/statistics.adminApi";
import { AdminContext, ADMIN_TYPES } from "../../../context/admin.context";
import { formatThousands } from "../../../utils/helpers";
import AdminTable from "../AdminTable.view";
import WeeklyDetailed from "./WeeklyDetailed.view";

interface Props {}

const StatisticsTab: FC<Props> = () => {
  // set component states
  const [showDetailedWeekly, setShowWeeklyDetailed] = useState(false);
  const [weekData, setWeekData] = useState({});

  // set context
  const { statistics, dispatch } = useContext(AdminContext);

  // set the statistics state (retrieve stats)
  useEffect(() => {
    (async () => {
      const data = await getStats();
      dispatch({ type: ADMIN_TYPES.SET_STATISTICS, payload: data });
    })();
  }, [dispatch]);

  // define the table structure
  const weeklyStatsTable = useMemo<ColumnDefinition<any, any>[]>(
    () => [
      {
        key: "weekStart",
        title: "Week start",
        dataIndex: "weekStart",
        // https://stackoverflow.com/questions/36560806/the-left-hand-side-of-an-arithmetic-operation-must-be-of-type-any-number-or
        sorter: (a, b) => new Date(a.weekStart).valueOf() - new Date(b.weekStart).valueOf(),
        render: (record) => moment(record.weekStart).format("L"),
      },
      {
        key: "weeklyNotStarted",
        title: "Not Started",
        dataIndex: "weeklyNotStarted",
        sorter: (a, b) => a.weeklyNotStarted - b.weeklyNotStarted,
      },
      {
        key: "weeklyStarted",
        title: "Started Application",
        dataIndex: "startedApplication",
        sorter: (a, b) => a.weeklyNotStarted - b.weeklyNotStarted,
      },
      {
        key: "weeklyQualified",
        title: "Qualified",
        dataIndex: "weeklyInProgress.qualified",
        sorter: (a, b) => a.weeklyInProgress.qualified - b.weeklyInProgress.qualified,
      },
      {
        key: "weeklyInProgressPD",
        title: "In progress: completed personal details",
        dataIndex: "weeklyInProgress.personal",
        sorter: (a, b) => a.weeklyInProgress.personal - b.weeklyInProgress.personal,
      },
      {
        key: "weeklyInProgressO",
        title: "In progress: completed objectives",
        dataIndex: "weeklyInProgress.objectives",
        sorter: (a, b) => a.weeklyInProgress.objectives - b.weeklyInProgress.objectives,
      },
      {
        key: "weeklyInProgressF",
        title: "In progress: completed finances",
        dataIndex: "weeklyInProgress.financialSituation",
        sorter: (a, b) => a.weeklyInProgress.financialSituation - b.weeklyInProgress.financialSituation,
      },
      {
        key: "weeklyInProgressE",
        title: "In progress: completed experience",
        dataIndex: "weeklyInProgress.experience",
        sorter: (a, b) => a.weeklyInProgress.experience - b.weeklyInProgress.experience,
      },
      {
        key: "weeklyInProgressIF",
        title: "In progress: completed investment information",
        dataIndex: "weeklyInProgress.investmentInformation",
        sorter: (a, b) => a.weeklyInProgress.investmentInformation - b.weeklyInProgress.investmentInformation,
      },
      {
        key: "weeklyAwaitingSignature",
        title: "Awaiting signature",
        dataIndex: "weeklyAwaitingSignature",
        sorter: (a, b) => a.weeklyAwaitingSignature - b.weeklyAwaitingSignature,
      },
      {
        key: "weeklyAwaitingFunds",
        title: "Awaiting funds",
        dataIndex: "weeklyAwaitingFunds",
        sorter: (a, b) => a.weeklyAwaitingFunds - b.weeklyAwaitingFunds,
      },
      {
        key: "weeklyFundsSignedValue",
        title: "Value of funds signed for",
        sorter: (a, b) => a.weeklyFundsSignedValue - b.weeklyFundsSignedValue,
        render: (record) => formatThousands(record.weeklyFundsSignedValue, 2),
      },
      {
        key: "weeklyFundsReceived",
        title: "Funds received",
        dataIndex: "weeklyFundsReceived",
        sorter: (a, b) => a.weeklyFundsReceived - b.weeklyFundsReceived,
      },
      {
        key: "weeklyFundsReceivedValue",
        title: "Value of funds received",
        sorter: (a, b) => a.weeklyFundsReceivedValue - b.weeklyFundsReceivedValue,
        render: (record) => formatThousands(record.weeklyFundsReceivedValue, 2),
      },
      {
        key: "weeklyFundsDeployed",
        title: "Funds deployed",
        dataIndex: "weeklyFundsDeployed",
        sorter: (a, b) => a.weeklyFundsDeployed - b.weeklyFundsDeployed,
      },
    ],
    [],
  );

  // seperated props for the WeeklyDetailed component
  const weeklyDetailedProps = useMemo(
    () => ({
      fullWidth: true,
      data: weekData,
      open: showDetailedWeekly,
      onClose: () => setShowWeeklyDetailed(false),
    }),
    [weekData, showDetailedWeekly],
  );

  // render the table
  return (
    <>
      <AdminTable
        tableData={statistics}
        tableStructure={weeklyStatsTable}
        rowClick={(data) => {
          setWeekData(data);
          setShowWeeklyDetailed(true);
        }}
        exportToCSVOption
        csvFilename="Statistics"
      />
      <WeeklyDetailed {...weeklyDetailedProps} />
    </>
  );
};

export default StatisticsTab;
