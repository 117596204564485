import { createStyles, makeStyles, useTheme } from "@material-ui/core";
import { Chart } from "chart.js";
import React, { forwardRef, ForwardRefRenderFunction, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { formatThousands } from "server/utils/helpers";

interface Props {
  amounts: Array<{ x: string; y: number; type: "totalCost" }>;
  values: Array<{ x: string; y: number; type: "totalValue" }>;
  xLabels: string[];
}

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      chart: {
        maxHeight: 380,
      },
      key: {
        display: "flex",
        "& > div": {
          display: "flex",
          alignItems: "center",
          "&:first-child": {
            marginRight: theme.spacing(2),
          },
          "& > hr": {
            width: 20,
            marginRight: theme.spacing(1),
          },
        },
      },
      tooltipContainer: {
        ...theme.typography.caption,
        position: "absolute",
        opacity: 0,
        transition: theme.transitions.create("opacity", {
          duration: theme.transitions.duration.short,
          easing: theme.transitions.easing.easeInOut,
        }),
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: "50%",
        backgroundColor: theme.palette.background.default,
      },
      tooltipTextContainer: {
        width: "100%",
        paddingTop: "100%",
        position: "relative",
      },
      tooltipText: {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      },
    }),
  { name: "FullValueChartComponent" },
);

const FullValueChart: ForwardRefRenderFunction<number, Props> = ({ amounts, values, xLabels, ...props }, ref) => {
  const theme = useTheme();
  const classes = useStyles(props);
  const canvas = useRef<HTMLCanvasElement>(null);
  const [chartHeight, setChartHeight] = useState(0);

  useImperativeHandle(ref, () => chartHeight, [chartHeight]);

  const createCanvas = useCallback(() => {
    const ctx = canvas.current?.getContext("2d");
    if (!ctx) return;
    const chart = new Chart<"line", typeof amounts | typeof values>(ctx, {
      type: "line",
      data: {
        labels: xLabels,
        datasets: [
          {
            order: 1,
            data: values,
            label: "Total Value",
            borderColor: theme.palette.primary.main,
          },
          {
            order: 2,
            data: amounts,
            label: "Amount Invested",
            borderColor: theme.palette.secondary.main,
          },
        ],
      },
      options: {
        responsive: typeof ResizeObserver !== "undefined",
        scales: {
          x: {
            grid: {
              display: false,
            },
            ticks: {
              color: theme.palette.text.primary,
            },
          },
          y: {
            ticks: {
              color: theme.palette.text.primary,
              callback: (val) => formatThousands(val),
            },
          },
        },
        datasets: {
          line: {
            fill: false,
            spanGaps: true,
            order: 0,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
            callbacks: {
              title: () => "",
              label: (ctx) => formatThousands(ctx.parsed?.y),
              labelTextColor: (ctx) => ((ctx.raw as any)?.type === "totalCost" ? theme.palette.secondary.main : theme.palette.primary.main),
            },
            external: ({ tooltip, chart }) => {
              let tooltipEl = document.getElementById("chartjs-tooltip");
              if (!tooltipEl) {
                tooltipEl = document.createElement("div");
                tooltipEl.id = "chartjs-tooltip";
                document.body.appendChild(tooltipEl);
              }
              if (tooltip.opacity === 0) {
                tooltipEl.style.opacity = "0";
                return;
              }
              tooltipEl.classList.add(classes.tooltipContainer);

              const flattenedBody = tooltip.body.flatMap((item) => item.lines.map((line) => line));
              if (tooltip.body) {
                tooltipEl.innerHTML = `
                <div class="${classes.tooltipTextContainer}">
                  <div class="${classes.tooltipText}">
                  ${flattenedBody.map((line, idx) => `<div style="color: ${tooltip.labelTextColors[idx]}">${line}</div>`).join("")}
                  </div>
                </div>
                `;
              }

              tooltipEl.style.pointerEvents = "none";
              tooltipEl.style.opacity = "1";

              const maxLen = Math.max(...flattenedBody.map((line) => line.length)) - 1;
              tooltipEl.style.width = maxLen + "em";
              tooltipEl.style.height = maxLen + "em";

              const valueFound = tooltip.dataPoints.find((item) => (item.raw as any)?.type === "totalValue");
              tooltipEl.style.borderColor = valueFound ? theme.palette.primary.main : theme.palette.secondary.main;

              const position = chart.canvas.getBoundingClientRect();
              tooltipEl.style.top = `calc(${position.top + window.pageYOffset + tooltip.caretY}px - ${maxLen / 2}em)`;
              tooltipEl.style.left = `calc(${position.left + window.pageXOffset + tooltip.caretX}px - ${maxLen / 2}em)`;
            },
          },
        },
      },
    });
    if (chart.canvas) setChartHeight(chart.canvas.scrollHeight);
    return () => chart.destroy();
  }, [amounts, classes, theme, values, xLabels]);

  useEffect(() => {
    return createCanvas();
  }, [createCanvas]);

  useEffect(() => () => document.getElementById("chartjs-tooltip")?.remove(), []);

  return <canvas ref={canvas} className={classes.chart} />;
};

export default forwardRef(FullValueChart);
