import { ROUTES } from "client/utils/constants";
import React, { useMemo } from "react";
import { Config } from "server/config";

const ConfigContext = React.createContext<Config>({} as any);

const ConfigProvider: React.FC<{ config: Config }> = ({ config, children }) => {
  const value: Config = useMemo(
    () => ({
      ...config,
      imDefaultPath: `${ROUTES.IM.index}/${config.imDefaultPath}`,
    }),
    [config],
  );

  return <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>;
};

const ConfigConsumer = ConfigContext.Consumer;
export { ConfigContext, ConfigProvider, ConfigConsumer };
