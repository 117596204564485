export const numberFormatter = (
  value: number,
  { currency = true, decimalPlaces, ...options }: Omit<Intl.NumberFormatOptions, "currency"> & { currency?: boolean; decimalPlaces?: number },
) =>
  new Intl.NumberFormat("en-GB", {
    style: currency ? "currency" : undefined,
    currency: currency ? "GBP" : undefined,
    minimumFractionDigits: decimalPlaces ?? 2,
    maximumFractionDigits: decimalPlaces ?? 2,
    ...options,
  }).format(value);

export const formatThousands = (num: number, decimalPlaces = 0, currency = true, minDecimalPlaces = 0, maxDecimalPlaces = 0) =>
  new Intl.NumberFormat("en-GB", {
    style: currency ? "currency" : "decimal",
    currency: "GBP",
    minimumFractionDigits: minDecimalPlaces || decimalPlaces,
    maximumFractionDigits: maxDecimalPlaces || decimalPlaces,
  }).format(num);
export const reArrangeName = (inputString: string) => {
  if (inputString.includes(",")) {
    const fullName = inputString.split(",");
    const lastName = fullName[0];
    const firstName = fullName[1];
    // Concatenate the rearranged name
    return firstName + " " + lastName;
  }
  return inputString;
};
export const xlsxCurrencyFormat = (v: string | number) => ({ t: "n", v, z: "£#,##0.00" });

export const xlsxThousandsFormat = (v: string | number, skipDecimals?: boolean) => ({ t: "n", v, z: !skipDecimals ? "#,##0.00" : "#,##0" });

export const xlsxStringFormat = (v: string | number) => ({ t: "s", v });

export const xlsxDateFormat = (v: Date | string | number) => ({ t: "d", v, z: "d-mmm-yy" });

export const xlsxRowsToStringFormat = (rowsAoa: any[][]) =>
  rowsAoa.map((row) =>
    row.map((cell) => {
      if (typeof cell === "string") {
        return xlsxStringFormat(cell);
      }
      return cell;
    }),
  );

// Function to autofit columns when creating XLSX sheets with SheetJS (server)
// https://github.com/SheetJS/sheetjs/issues/1473#issuecomment-580648494
export const xlsxAutofitColumns = (sheetJsonData: any[], worksheet: any, padding?: number) => {
  const jsonKeys = Object.keys(sheetJsonData[0]);
  const objectMaxLength: Record<string, number> = {};

  sheetJsonData.forEach((value) => {
    jsonKeys.forEach((key) => {
      const length = typeof value[key] === "number" ? 10 : value[key]?.length || 0;
      objectMaxLength[key] = Math.max(objectMaxLength[key] || 0, length);
    });

    jsonKeys.forEach((key) => {
      objectMaxLength[key] = Math.max(objectMaxLength[key] || 0, key.length + (padding ?? 0));
    });
  });

  const wscols = jsonKeys.map((key) => ({ width: objectMaxLength[key] }));
  worksheet["!cols"] = wscols;

  return worksheet;
};

const PLACEHOLDER_EMAIL_REGEX = /^placeholder\+.+@(novagrowthcapital|wearenova)\.co\.uk$/i;

const NOVA_EMAIL_REGEX = /^.+@(novagrowthcapital|wearenova)\.co\.uk$/i;

export const isPlaceholderEmail = (email = "") => PLACEHOLDER_EMAIL_REGEX.test(email);

export const isNovaEmail = (email = "") => NOVA_EMAIL_REGEX.test(email);
