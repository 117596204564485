import { createStyles, Link, makeStyles, Paper, Typography } from "@material-ui/core";
import { ConfigContext } from "client/context/config.context";
import React, { useContext, useRef } from "react";
import RiskWarningPopup from "./RiskWarningPopup.component";

interface Props {}

const RiskBanner: React.FC<Props> = (props) => {
  const { fund } = useContext(ConfigContext);

  const useStyles = makeStyles(
    (theme) =>
      createStyles({
        bannerBox: {
          backgroundColor: !fund._id ? theme.palette.error.main : theme.palette.secondary.main,
          color: !fund._id ? theme.palette.text.secondary : theme.palette.primary.main,
          position: "fixed",
          width: "100%",
          zIndex: 1101,
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
        },
      }),
    { name: "RiskBannerComponent" },
  );

  const classes = useStyles(props);
  const riskWarningRef = useRef<() => void>(null);

  return (
    <Paper className={classes.bannerBox} square elevation={0}>
      <RiskWarningPopup ref={riskWarningRef}>
        <Typography align="center" color="inherit">
          Don&apos;t invest unless you&apos;re prepared to lose all your money.
          <br />
          This is a high-risk investment and you are unlikely to be protected if something goes wrong.
          <br />
          <Link onClick={() => riskWarningRef.current?.()} color="inherit">
            Take 2 mins to learn more
          </Link>
        </Typography>
      </RiskWarningPopup>
    </Paper>
  );
};
// pt: 2, pb: 2
export default RiskBanner;
