import React, { FC, useState } from "react";
import { Button, Container, FormGroup, Typography } from "@material-ui/core";
import { ConfigContext } from "client/context/config.context";
import useQuery from "client/hooks/useQuery.hook";
import { Field, Form, Formik } from "formik";
import { useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";
import ApplicationApi from "../../api/applications.api";
import PublicUserApi from "../../api/public/user.publicApi";
import CheckboxQuestion from "../../components/FormControls/CheckboxQuestion.component";
import { ApplicationContext, APPLICATION_TYPES } from "../../context/application.context";
import { AuthContext } from "../../context/auth.context";
import { ROUTES } from "../../utils/constants";

const SCHEMA = Yup.object({
  agreeWithCharge: Yup.boolean().oneOf([true], "Please accept the charge").required("Please accept the charge"),
  agreeWithPayment: Yup.boolean().oneOf([true], "Please accept the payment statement").required("Please accept the payment statement"),
});

const INITIAL_VALUES = {
  agreeWithCharge: false,
  agreeWithPayment: false,
};

const AgreeFee: FC = () => {
  const history = useHistory();
  const location = useLocation();

  const [feePercentage, setFeePercentage] = useState(0);
  const [feeAmount, setFeeAmount] = useState(0);
  const [loading, setLoading] = useState(true);

  const aId = useQuery("applicationId");

  const { application, dispatch } = React.useContext(ApplicationContext);
  const { fund } = React.useContext(ConfigContext);
  const {
    state: { user },
  } = React.useContext(AuthContext);

  const goBack = () => {
    history.push(ROUTES.APPLICATION_INVESTMENT);
  };

  React.useEffect(() => {
    if (!application) {
      (async () => {
        const app = await ApplicationApi.getUsersApplication(aId);
        dispatch({ type: APPLICATION_TYPES.SET_APPLICATION, payload: app });
      })();
    }
  }, [aId, application, dispatch]);

  const onFinish = React.useCallback(async () => {
    const { success } = await PublicUserApi.clientAgreeToCharges({
      userId: user!.userId,
    });
    if (success) {
      history.push({
        pathname: ROUTES.APPLICATION_AGREEMENT,
        search: location.search,
      });
    }
  }, [user, history, location]);

  React.useEffect(() => {
    if (aId || (application && application._id))
      (async () => {
        const applicationId = aId || application!._id;

        const res = await ApplicationApi.getInvestmentFee(applicationId);
        if (res?.success && res?.data.feePercentage === 0) {
          onFinish();
        } else if (res?.success) {
          setLoading(false);
          setFeePercentage(res.data.feePercentage);
          setFeeAmount(res.data.feeAmount);
        }
      })();
  }, [application, onFinish, location.search, setFeeAmount, setFeePercentage, user, aId]);

  return (
    <Container maxWidth="md">
      {!loading && feePercentage && feeAmount && (
        <>
          <Typography variant="h1">Financial Adviser / Intermediary Facilitation Charge</Typography>
          <Formik validationSchema={SCHEMA} initialValues={INITIAL_VALUES} onSubmit={onFinish}>
            {() => (
              <Form>
                <FormGroup>
                  <Field
                    component={CheckboxQuestion}
                    id="agreeWithCharge"
                    name="agreeWithCharge"
                    label={`Please tick this to confirm that you agree with the ${feePercentage}% (£${feeAmount}) facilitation charge as set by your adviser.`}
                  />
                </FormGroup>
                <FormGroup>
                  <Field
                    component={CheckboxQuestion}
                    id="agreeWithPayment"
                    name="agreeWithPayment"
                    label={`Please check this box to confirm that you are OK with ${fund.whoPaysFee} paying this fee on your behalf directly to your adviser.`}
                  />
                </FormGroup>
                <FormGroup row>
                  {user!.advisor?.adviceType !== "ADVISED" && <Button onClick={goBack}>Back</Button>}
                  <Button data-testid="finish" type="submit">
                    Finish
                  </Button>
                </FormGroup>
              </Form>
            )}
          </Formik>
        </>
      )}
    </Container>
  );
};

export default AgreeFee;
