import { Container, makeStyles, Typography } from "@material-ui/core";
import { ConfigContext } from "client/context/config.context";
import React, { useContext, useMemo } from "react";
import { AuthContextUser } from "server/services/user/user.types";
import { AuthContext } from "../../context/auth.context";
import AdvisorDashboardCTA from "./AdvisorDashboard.component";
import AdvisorOnboardCTA from "./AdvisorOnboard.component";
import InvestFundsCTA from "./InvestFunds.component";
import QualifyCTA from "./QualifyToInvest.component";

const useStyles = makeStyles(
  (theme) => ({
    bannerContentContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
    bannerWrapper: {
      borderTop: `2px solid ${theme.palette.secondary.main}`,
      borderBottom: `3px solid ${theme.palette.secondary.main}`,
    },
    buttonsWrapper: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "space-between",

      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
  }),
  { name: "InfoBannerComponent" },
);

const BannerContent: React.FC = () => {
  const {
    state: { user },
  } = useContext(AuthContext);
  const { fund } = useContext(ConfigContext);

  const { isAdvisor, isOnboardedAdvisor, isVerified, isInvestor, isQualified } = useMemo<Partial<AuthContextUser>>(() => user || {}, [user]);

  const advisorText = useMemo(() => {
    if (!isAdvisor) return null;
    if (!isOnboardedAdvisor)
      return "Before you can start sharing our investment opportunities with your clients, we need to verify your IFA status. Please do so by filling in the following questions.";
    return "One of our team will contact you shortly to verify your account.";
  }, [isAdvisor, isOnboardedAdvisor]);

  if (isAdvisor) {
    return (
      <>
        {!isOnboardedAdvisor || !isVerified ? (
          <>
            <Typography variant="h3" align="center" paragraph>
              Verify your IFA status
            </Typography>
            <Typography variant="body2" align="center" paragraph>
              {advisorText}
            </Typography>
            {!isOnboardedAdvisor && <AdvisorOnboardCTA />}
          </>
        ) : (
          <>
            <Typography variant="h3" align="center" paragraph>
              Add your clients
            </Typography>
            <Typography variant="body2" align="center" paragraph>
              Continue to your dashboard to add your clients and begin their onboarding process
            </Typography>
            <AdvisorDashboardCTA />
          </>
        )}
      </>
    );
  }

  if (!isInvestor) {
    return !isQualified ? (
      <>
        <Typography variant="h3" align="center" paragraph>
          QUALIFY TO INVEST
        </Typography>
        <Typography variant="body2" align="center" paragraph>
          Thank you for registering with {fund.name}. Below you can read more about our business and investment fund. Before you can access our fund
          documentation and further information, you must complete our qualification process.
        </Typography>
        <QualifyCTA />
      </>
    ) : (
      <>
        <Typography variant="h3" align="center" paragraph>
          APPLICATION FORM
        </Typography>
        <Typography variant="body2" align="center" paragraph>
          To invest in the fund, we will ask you questions to create a form that is tailored specifically to you that you can electronically sign. We
          will then email you a copy of this form for your records.
        </Typography>
        <InvestFundsCTA />
      </>
    );
  }

  return (
    <>
      <Typography variant="h3" align="center" paragraph>
        YOUR APPLICATION
      </Typography>
      <Typography variant="body2" align="center" paragraph>
        Thank you for applying to the {fund.name}. Click the &ldquo;START&rdquo; button below to start another application
      </Typography>
      <InvestFundsCTA />
    </>
  );
};

const InfoBanner: React.FC = (props) => {
  const classes = useStyles(props);

  return (
    <div className={classes.bannerWrapper}>
      <Container className={classes.bannerContentContainer}>
        <BannerContent />
      </Container>
    </div>
  );
};
export default InfoBanner;
