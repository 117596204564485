import { Dialog, DialogContent, DialogTitle, FormGroup } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import { addDocument, getUsers, removeDocument } from "../../../api/admin/users.adminApi";
import CrudFormButtons from "../../../components/FormControls/CrudFormButtons.component";
import DatePickerField from "../../../components/FormControls/DatePickerField.component";
import SelectField from "../../../components/FormControls/SelectField.component";
import TextQuestion from "../../../components/FormControls/TextQuestion.component";
import FormikErrorsTouched from "../../../components/FormikErrorsTouched.component";
import { AdminContext, ADMIN_TYPES } from "../../../context/admin.context";

const SCHEMA = Yup.object().shape({
  investor: Yup.string().required("Please select an investor"),
  documents: Yup.object({
    date: Yup.date().required("Please select a date"),
    userSpecifiedFileName: Yup.string().required("Please enter a file name"),
    fileName: Yup.mixed().required("Please provide a file"),
  }).required("Please enter document details"),
});

const INITIAL_VALUES = {
  investor: "",
  documents: {
    date: "",
    userSpecifiedFileName: "",
    fileName: null,
  },
};

interface Props {
  onClose: () => void;
  document: {
    _id: string;
    userId: string;
    document: {
      date: string;
      fileName: string;
      userSpecifiedFileName: string;
      _id: string;
    };
  };
}

const UserDocumentsForm: React.FC<Props> = ({ onClose, document, ...props }) => {
  const [investorOptions, setInvestorOptions] = useState<any>([]);
  const { allUsers, dispatch } = useContext(AdminContext);

  useEffect(() => {
    (async () => {
      const res = await getUsers();
      if (res) {
        dispatch({ type: ADMIN_TYPES.SET_ALL_USERS, payload: res.data });
      }
    })();
  }, [dispatch]);

  useEffect(() => {
    const investors = allUsers.map((user) => ({
      value: user._id,
      label: `${user.forenames} ${user.surname} - ${user.email}`,
    }));
    setInvestorOptions(investors);
  }, [allUsers]);

  const handleDelete = useCallback(async () => {
    if (document) {
      const { _id: userId } = await removeDocument(document.userId, document.document._id);
      if (userId) {
        dispatch({ type: ADMIN_TYPES.DELETE_DOCUMENT, payload: document._id });
        onClose();
      }
    }
  }, [dispatch, document, onClose]);

  const handleSubmit = useCallback(
    async (values) => {
      const userDocuments = await (document ? addDocument(values, document.document._id) : addDocument(values)); // return user details and his/her documents
      if (userDocuments) {
        // Extract only the recent added document of the user and pass desire data require for state
        const userDocument = {
          previousDocumentId: document ? document._id : "", // pass the previous document id in case of updating user document
          fullName: `${userDocuments.forenames} ${userDocuments.surname}`,
          fileName: `${userDocuments.documents[userDocuments.documents.length - 1].userSpecifiedFileName}.pdf`,
          date: userDocuments.documents[userDocuments.documents.length - 1].date,
          forenames: userDocuments.forenames,
          surname: userDocuments.surname,
          document: { ...userDocuments.documents[userDocuments.documents.length - 1] },
          _id: userDocuments.documents[userDocuments.documents.length - 1]._id,
          userId: userDocuments._id,
        };
        dispatch({
          type: ADMIN_TYPES.UPDATE_DOCUMENT,
          payload: userDocument,
        });
        onClose();
      }
    },
    [dispatch, document, onClose],
  );

  const initialValues = useMemo(
    () =>
      document
        ? {
            ...INITIAL_VALUES,
            investor: document ? document.userId : "",
            documents: {
              ...INITIAL_VALUES.documents,
              date: document ? document.document.date : "",
              userSpecifiedFileName: document ? document.document.userSpecifiedFileName : "",
            },
          }
        : INITIAL_VALUES,
    [document],
  );

  return (
    <Dialog onClose={onClose} {...props}>
      <DialogTitle>{document ? "Edit User Document" : "Add User Document"}</DialogTitle>
      <DialogContent>
        <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={SCHEMA} enableReinitialize={true}>
          {({ setFieldValue }) => (
            <Form data-testid="userDocumentsForm">
              <FormikErrorsTouched />
              <FormGroup>
                <Field component={SelectField} name="investor" label="Investor" options={investorOptions} />
              </FormGroup>
              <FormGroup>
                <Field component={DatePickerField} name="documents.date" label="Date" />
              </FormGroup>
              <FormGroup>
                <Field component={TextQuestion} name="documents.userSpecifiedFileName" label="File Name" />
              </FormGroup>
              <FormGroup>
                <Field
                  component={TextQuestion}
                  name="documents.fileName"
                  type="file"
                  placeholder="Upload Document"
                  label="Upload Document"
                  onChange={(e) => setFieldValue(e.target.name, e.target.files[0])}
                  value={undefined}
                  InputProps={{
                    inputProps: {
                      accept: "application/pdf",
                    },
                  }}
                />
              </FormGroup>

              <CrudFormButtons isEdit={Boolean(document)} handleDelete={handleDelete} onClose={onClose} />
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default UserDocumentsForm;
