import { getFunds } from "client/api/admin/funds.adminApi";
import { getPortfolios } from "client/api/admin/portfolios.adminApi";
import { AdminContext } from "client/context/admin.context";
import { useContext, useEffect } from "react";

export const useAdminContext = () => {
  return useContext(AdminContext);
};

export const useAllFunds = () => {
  const { funds, dispatch } = useAdminContext();

  useEffect(() => {
    (async () => {
      const data = await getFunds();
      if (data) {
        dispatch({ type: "SET_FUNDS", payload: data });
      }
    })();
  }, [dispatch]);

  return funds;
};

export const useAllPortfolios = () => {
  const { portfolios, dispatch } = useAdminContext();

  useEffect(() => {
    (async () => {
      const data = await getPortfolios();
      if (data) {
        dispatch({ type: "SET_PORTFOLIOS", payload: data });
      }
    })();
  }, [dispatch]);

  return portfolios;
};
