import { gql, useMutation, useQuery } from "@apollo/client";
import { PLANNER_OPTIONS } from "client/utils/constants";
import { useEffect } from "react";
import {
  AllocationsOptions,
  AppConfigUpdate,
  DealConfig,
  DealConfigUpdate,
  DeploymentOptions,
  LeanApplicationConfig,
} from "server/services/deployment/deployment.types";

const ALLOCATIONS_OPTIONS = Object.keys(PLANNER_OPTIONS).join("\n");

const DeploymentOptionsQueries = {
  query: gql`
    query GetDeploymentOptions {
      options {
        allocations {
          ${ALLOCATIONS_OPTIONS}
        }
      }
    }
  `,
  subscribe: gql`
    subscription OnDeploymentOptions {
      options {
        allocations {
          ${ALLOCATIONS_OPTIONS}
        }
      }
    }
  `,
  mutate: {
    allocations: gql`
      mutation UpdateDeploymentOptions($options: IAllocationsOptions!) {
        updateAllocationOptions(opts: $options) {
          allocations {
            ${ALLOCATIONS_OPTIONS}
          }
        }
      }
    `,
  },
};

export const useDeploymentOptions = () => {
  const { subscribeToMore, ...query } = useQuery<{ options: DeploymentOptions }>(DeploymentOptionsQueries.query);

  useEffect(() => {
    subscribeToMore<{ options: DeploymentOptions }>({
      document: DeploymentOptionsQueries.subscribe,
      updateQuery(_prev, { subscriptionData }) {
        return { options: subscriptionData.data.options };
      },
    });
  }, [subscribeToMore]);

  return query;
};

export const useUpdateAllocationOptions = () => {
  return useMutation<AllocationsOptions, { options: Partial<AllocationsOptions> }>(DeploymentOptionsQueries.mutate.allocations);
};

export const DealConfigsQueries = {
  subscribe: gql`
    subscription OnDealConfigs {
      dealConfigs {
        deal
        config
        shareClassData {
          nominalValue
          pricePerShare
        }
      }
    }
  `,
  mutate: gql`
    mutation UpdateDealConfigs($updates: DealConfigUpdate!, $dealIds: [String!]!) {
      updateDealConfigs(updates: $updates, dealIds: $dealIds) {
        deal
      }
    }
  `,
};

export const useUpdateDealConfigs = () => {
  return useMutation<{ updateFundConfigs: DealConfig[] }, { updates: DealConfigUpdate; dealIds: string[] }>(DealConfigsQueries.mutate);
};

export const AppConfigsQueries = {
  subscribe: gql`
    subscription OnAppConfigs {
      appConfigs {
        application
        spread {
          seis
          eis
        }
        percentToUse {
          seis
          eis
        }
        excludedCompanies
      }
    }
  `,
  mutate: gql`
    mutation UpdateAppConfigs($updates: AppConfigUpdate!, $appIds: [String!]!) {
      updateAppConfigs(updates: $updates, appIds: $appIds) {
        application
      }
    }
  `,
};

export const useUpdateAppConfigs = () => {
  return useMutation<{ updateAppConfigs: Pick<LeanApplicationConfig, "application">[] }, { updates: AppConfigUpdate; appIds: string[] }>(
    AppConfigsQueries.mutate,
  );
};
