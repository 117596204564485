import MomentUtils from "@date-io/moment";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import * as Sentry from "@sentry/react";
import moment from "moment";
import "moment/locale/en-gb";
import React, { useEffect } from "react";
import { browserVersion, isIE } from "react-device-detect";
import { Config } from "server/config";
import { AuthContextUser } from "server/services/user/user.types";
import PublicApi from "./api/public/authentication.publicApi";
import "./App.css";
import { AdminProvider, DEFAULT_ADMIN_STATE } from "./context/admin.context";
import { ApplicationProvider } from "./context/application.context";
import { AuthProvider } from "./context/auth.context";
import { ConfigProvider } from "./context/config.context";
import { DEFAULT_USER_DATA, UserProvider } from "./context/user.context";
import "./cookieBar.css";
import useDescription from "./hooks/useDescription.hook";
import useTitle from "./hooks/useTitle.hook";
import Router from "./Router";
import { clearToken, getToken, setToken } from "./utils/auth";
import { STORAGE_KEYS } from "./utils/constants";
import { clearLocalStorage } from "./utils/helpers";
import notifications from "./utils/notifications";

interface Props {
  config: Config;
  user: AuthContextUser | undefined;
}

moment.updateLocale("en-GB", null);

/* eslint-disable */
const tagManager = (TAG_MANAGER_CONTAINER_ID: string) => {
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
    const f = d.getElementsByTagName(s)[0];
    const j = d.createElement(s) as HTMLScriptElement;
    const dl = l != "dataLayer" ? `&l=${l}` : "";
    j.async = true;
    j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
    f.parentNode!.insertBefore(j, f);
  })(window, document, "script", "dataLayer", TAG_MANAGER_CONTAINER_ID);
};
/* eslint-enable */

const verifyUser = async () => {
  const token = getToken();
  if (!token) return null;

  const { success, data } = await PublicApi.verifyToken();
  if (success) {
    setToken(data.refreshedToken);
    return data;
  } else if (data === "invalid token") {
    clearToken();
    clearLocalStorage();
    notifications.error("Your session has expired. Please log in again.");
    window.location.href = `${window.location.protocol}//${window.location.host}/login`;
  }
  Object.values(STORAGE_KEYS).forEach((i) => window.localStorage.removeItem(i));
  return null;
};

const App: React.FC<Props> = ({ config, user }) => {
  useTitle(config.fund.meta.title);
  useDescription(config.fund.meta.description);

  useEffect(() => {
    window.onfocus = async function () {
      await verifyUser();
    };

    return () => {
      window.onfocus = null;
    };
  }, []);

  useEffect(() => {
    if (config.TAG_MANAGER_CONTAINER_ID) {
      tagManager(config.TAG_MANAGER_CONTAINER_ID);
    }

    if (!config.IS_DEVELOPMENT) {
      Sentry.init({
        dsn: "https://feb6b8cf7bf8444f9bd329d2779cc851@sentry.io/1853799",
        release: config.RELEASE,
        environment: config.ENVIRONMENT,
      });
    }
  }, [config]);

  return (
    <AuthProvider user={user}>
      <AdminProvider value={DEFAULT_ADMIN_STATE}>
        <ConfigProvider config={config}>
          <UserProvider userData={DEFAULT_USER_DATA}>
            <ApplicationProvider>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <>
                  <CssBaseline />
                  {isIE && parseInt(browserVersion) < 11
                    ? notifications.warning("Sorry, your current web browser is not supported. Please use another web browser, such as Google Chrome")
                    : null}
                  <Router />
                </>
              </MuiPickersUtilsProvider>
            </ApplicationProvider>
          </UserProvider>
        </ConfigProvider>
      </AdminProvider>
    </AuthProvider>
  );
};

export default App;
