import fileDownload from "js-file-download";
import { AdminUser } from "server/services/user/user.types";
import { setToken } from "../../utils/auth";
import notifications from "../../utils/notifications";
import { ipAxios } from "../ipAxios";

export const getUsers = async (onChangeObject?: GenericObject) => {
  try {
    const res = await ipAxios.get<AdminUser[]>(`/admin/users/`, { params: onChangeObject });
    return { data: res.data, count: res.headers["x-count"] };
  } catch (error: any) {
    if (error.message !== "duplicate request cancelled") {
      notifications.error(error.message, error);
    }
    return null;
  }
};

export const downloadUserData = async () => {
  try {
    const res = await ipAxios.get<Blob>("/admin/users/download", {
      responseType: "blob",
    });
    if (res.status === 200) {
      fileDownload(new Blob([res.data]), `user-data-${Date.now()}.csv`);
      return { success: true, data: res.data };
    }
    return { success: false };
  } catch (error: any) {
    notifications.error(error.message, error);
    return { success: false };
  }
};

export const addUser = async (values: any) => {
  try {
    const res = await ipAxios.post("/admin/users", values);
    return res.data;
  } catch (error: any) {
    if (error.response.status === 400) {
      notifications.error(error.response.data);
      return null;
    }
    notifications.error(error.message, error);
    return null;
  }
};

export const addAdvisor = async (values: any) => {
  try {
    const res = await ipAxios.post("/admin/users/advisor", values);
    return res.data;
  } catch (error: any) {
    notifications.error(error.message, error);
    return null;
  }
};

export const addClient = async (values: any) => {
  try {
    const res = await ipAxios.post("/admin/users/client", values);
    return res.data;
  } catch (error: any) {
    notifications.error(error.message, error);
    return null;
  }
};

export const updateClient = async (values: any, userId: string) => {
  try {
    const res = await ipAxios.put(`/admin/users/client/${userId}`, values);
    return res.data;
  } catch (error: any) {
    notifications.error(error.message, error);
    return null;
  }
};

export const updateAdvisor = async (values: any, advisorId: string) => {
  try {
    const res = await ipAxios.put(`/admin/users/advisor/${advisorId}`, values);
    return res.data;
  } catch (error: any) {
    notifications.error(error.message, error);
    return null;
  }
};

export const updateUser = async (values: any, userId: string) => {
  try {
    const res = await ipAxios.post(`/admin/users/${userId}`, values);
    return res.data;
  } catch (error: any) {
    notifications.error(error.message, error);
    return null;
  }
};

export const removeUser = async (userId: string) => {
  try {
    const res = await ipAxios.delete(`/admin/users/${userId}`);
    return res.data;
  } catch (error: any) {
    notifications.error(error.message, error);
    return null;
  }
};

export const inviteUser = async (userId: string) => {
  try {
    const res = await ipAxios.post(`/admin/users/${userId}/invite`, null);
    return res.data;
  } catch (error: any) {
    notifications.error(error.message, error);
    return null;
  }
};

export const verifyAdvisor = async (advisorId: string, values: any) => {
  try {
    const res = await ipAxios.post(`/admin/users/advisor/verify/${advisorId}`, values);
    return res.data;
  } catch (error: any) {
    notifications.error(error.message, error);
    return null;
  }
};

export const addDocument = async (values: GenericObject, documentId: string = "") => {
  try {
    const formData = new FormData();
    formData.append("body", JSON.stringify(values));
    if (values.documents.fileName && values.documents.fileName.constructor === File) {
      formData.append("documents.fileName", values.documents.fileName, values.documents.fileName.name);
    }
    const res = await ipAxios.post(`/admin/users/document/${values.investor}/${documentId}`, formData);
    return res.data;
  } catch (error: any) {
    notifications.error(error.message, error);
    return null;
  }
};

export const getAllUserDocuments = async (onChangeObject: GenericObject) => {
  try {
    const res = await ipAxios.get("/admin/users/documents", { params: onChangeObject });
    return { data: res.data, count: res.headers["x-count"] };
  } catch (error: any) {
    if (error.message !== "duplicate request cancelled") {
      notifications.error(error.message, error);
    }
    return null;
  }
};

export const downloadDocument = async (documentId: string, fileName: string, userId: string) => {
  try {
    const res = await ipAxios.get(`/admin/users/documents/${userId}/${documentId}`, {
      responseType: "blob",
    });

    if (res.status === 200) {
      const blob = new Blob([res.data]);
      fileDownload(blob, fileName);
      return { data: res.data, success: true };
    }
    return { success: false };
  } catch (error: any) {
    notifications.genericError(error);
    return { success: false };
  }
};

export const removeDocument = async (userId: string, documentId: string) => {
  try {
    const res = await ipAxios.delete(`/admin/users/documents/${userId}/${documentId}`);
    return res.data;
  } catch (error: any) {
    notifications.error(error.message, error);
    return null;
  }
};

export const loginAsUser = async (userId: string, apiUser?: boolean) => {
  try {
    const { data } = await ipAxios.get(`/admin/users/${userId}/token`, { params: apiUser && { apiUser } });
    if (apiUser) {
      return data.token;
    } else {
      setToken(data.token);
      return data.user;
    }
  } catch (error: any) {
    notifications.error(error.message, error);
    return null;
  }
};

export const getUserPortfolio = async (userId: string) => {
  try {
    const res = await ipAxios.get(`/admin/users/portfolio/${userId}`);
    return res.data;
  } catch (error: any) {
    notifications.error(error.message, error);
    return null;
  }
};
export const resetPasswordLink = async (userId: string) => {
  try {
    const res = await ipAxios.get(`/admin/users/${userId}/reset-password-link`);
    return res;
  } catch (e: any) {
    notifications.genericError(e);
    return false;
  }
};
