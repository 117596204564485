import Axios, { Canceler } from "axios";
import qs from "qs";
import { getToken } from "../utils/auth";
import { BASE_API } from "../utils/constants";

const requests: Array<{ url?: string; cancel: Canceler }> = [];
const { CancelToken } = Axios;

export const ipAxios = Axios.create({
  baseURL: BASE_API,
  paramsSerializer: (params) => qs.stringify(params),
});

ipAxios.interceptors.request.use((config) => {
  let cancelSource;
  if (!config.headers["X-Disable-Duplicate"] && config.url?.includes("/admin") && config.params && config.method?.toUpperCase() === "GET") {
    const requestIndex = requests.findIndex((req) => req.url === config.url);
    const foundRequest = requestIndex >= 0 && requests.splice(requestIndex, 1)[0];
    if (foundRequest) {
      foundRequest.cancel("duplicate request cancelled");
    }

    cancelSource = CancelToken.source();
    requests.push({ url: config.url, cancel: cancelSource.cancel });
  }

  return {
    ...config,
    cancelToken: cancelSource && cancelSource.token,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${getToken()}`,
    },
  };
});
ipAxios.interceptors.response.use((res) => {
  if (res.config.url?.includes("/admin") && res.config.method?.toUpperCase() === "GET") {
    const requestIndex = requests.findIndex((req) => {
      return req.url === res.config.url?.replace("/api", "");
    });
    if (requestIndex >= 0) {
      requests.splice(requestIndex, 1);
    }
  }
  return res;
});
