import { AppBar, Container, Link, makeStyles, Slide, Toolbar, useScrollTrigger } from "@material-ui/core";
import { ConfigContext } from "client/context/config.context";
import clsx from "clsx";
import React, { ReactElement, useCallback, useContext, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AuthContext, AUTH_TYPES } from "../../context/auth.context";
import { clearToken, setToken } from "../../utils/auth";
import { ROUTES, USER_ROLE } from "../../utils/constants";
import { clearLocalStorage } from "../../utils/helpers";
import TouchNavbar from "./TouchNavbar.component";
import useIsAdmin from "client/hooks/useIsAdmin";

interface Props { }

const useStyles = makeStyles(
  (theme) => ({
    normalContainer: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    normalLogo: {
      width: 146,
      height: "auto",
    },
    smallContainer: {
      height: "100%",
      display: "none",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        display: "flex",
      },
    },
    smallLogo: {
      height: "auto",
      width: 45,
      marginRight: 10,
    },
    navBarItem: {
      padding: theme.spacing(0, 2.5),
      textTransform: "uppercase",
      fontWeight: theme.typography.fontWeightSemiBold,
      "&:hover": {
        opacity: 0.8,
      },
      "&:last-child": {
        paddingRight: 0,
      },
      [theme.breakpoints.down("md")]: {
        margin: theme.spacing(6.25),
        "&:last-child": {
          paddingRight: theme.spacing(2.5),
        },
      },
    },
    navBarItemBlack: {
      fontWeight: theme.typography.fontWeightBlack,
    },
    mobileBackButton: {
      display: "none",
      [theme.breakpoints.down("md")]: {
        fontSize: 16,
        display: "flex",
        color: "black",

        "& img": {
          width: "12%",
          margin: "auto 0",
        },
        "& div": {
          paddingLeft: 10,
          width: "30%",
          margin: "auto 0",
        },
      },
    },
    appBar: {
      paddingTop: theme.spacing(15),
      [theme.breakpoints.down("md")]: {
        paddingTop: theme.spacing(11),
      },
      [theme.breakpoints.down("sm")]: {
        paddingTop: theme.spacing(15),
      },
      [theme.breakpoints.down("xs")]: {
        paddingTop: theme.spacing(19),
      },
    },
    navHeight: {
      height: 95,
      [theme.breakpoints.down("md")]: {
        height: 75,
      },
      "@media print": {
        display: "none",
      },
    },
    navContent: {
      height: "100%",
      display: "flex",
      alignItems: "center",
      paddingBottom: 0,
      paddingTop: 0,
    },
    normalNavItemsContainer: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      width: "100%",
      height: "100%",
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
  }),
  { name: "InvestorNavbarComponent" },
);

const HideOnScroll: React.FC<{ children: ReactElement<any, any> | undefined }> = (props) => {
  const trigger = useScrollTrigger();
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {props.children}
    </Slide>
  );
};

const InvestorNavBar: React.FC<Props> = (props) => {
  const {
    state: { user },
    dispatch,
  } = React.useContext(AuthContext);
  const isAdmin = useIsAdmin(user);

  const { fund, logo, imDefaultPath } = useContext(ConfigContext);
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles(props);

  const disabledForReadOnly = useMemo(() => {
    return !fund.adminPanel?.enabled || !fund.adminPanel?.enabledPanels?.length;
  }, [fund]);

  const showAdminLink = useMemo(() => {
    return user?.role === USER_ROLE.ADMIN || user?.role === USER_ROLE.MANAGER || (user?.role === USER_ROLE.READ_ONLY_ADMIN && !disabledForReadOnly);
  }, [disabledForReadOnly, user]);

  const showApiLink = useMemo(() => {
    return user?.role === USER_ROLE.ADMIN || user?.role === USER_ROLE.MANAGER || user?.role === USER_ROLE.READ_ONLY_ADMIN;
  }, [user]);

  const navItems = useMemo(
    () => (
      <>
        {showAdminLink && (
          <Link
            align="center"
            variant="h5"
            data-testid="adminPanel"
            color="inherit"
            className={clsx(classes.navBarItem, { [classes.navBarItemBlack]: location.pathname.includes(ROUTES.ADMIN_PANEL) })}
            underline="none"
            onClick={() => history.push(ROUTES.ADMIN_PANEL)}
          >
            Admin Panel
          </Link>
        )}
        {showApiLink && (
          <Link
            align="center"
            variant="h5"
            color="inherit"
            underline="none"
            className={clsx(classes.navBarItem, { [classes.navBarItemBlack]: location.pathname.includes("/docs") })}
            onClick={() => history.push("/docs")}
          >
            API Docs
          </Link>
        )}
        {user && user.isAdvisor && (
          <Link
            align="center"
            variant="h5"
            color="inherit"
            className={clsx(classes.navBarItem, { [classes.navBarItemBlack]: location.pathname.includes(ROUTES.ADVISOR.DASHBOARD) })}
            underline="none"
            onClick={() => history.push(ROUTES.ADVISOR.DASHBOARD)}
          >
            Adviser Dashboard
          </Link>
        )}
        {user && !user.isAdvisor && (
          <Link
            align="center"
            variant="h5"
            color="inherit"
            className={clsx(classes.navBarItem, {
              [classes.navBarItemBlack]: location.pathname.includes(ROUTES.DASHBOARD) && !location.pathname.includes(ROUTES.ADVISOR.index),
            })}
            underline="none"
            onClick={() => history.push("/dashboard/portfolio")}
            data-testid="investor-dashboard"
          >
            Investor Dashboard
          </Link>
        )}
        <Link
          align="center"
          variant="h5"
          color="inherit"
          className={clsx(classes.navBarItem, { [classes.navBarItemBlack]: location.pathname.includes(ROUTES.IM.index) })}
          underline="none"
          onClick={() => history.push(imDefaultPath)}
          data-testid="information-memorandum"
        >
          Information Memorandum
        </Link>
        {user && (
          <Link
            align="center"
            variant="h5"
            color="inherit"
            className={classes.navBarItem}
            data-testid="investorLogout"
            underline="none"
            onClick={() => {
              if (user.adminUser) {
                const { token, ...adminUser } = user.adminUser;
                setToken(token);
                clearLocalStorage();
                dispatch({ type: AUTH_TYPES.SET_USER, payload: adminUser });
                return history.push("/admin/panel/users");
              }
              clearToken();
              clearLocalStorage();
              dispatch({ type: AUTH_TYPES.CLEAR_USER, payload: undefined });
              history.push("/login");
            }}
          >
            {user.adminUser ? "Back" : "Log out"}
          </Link>
        )}
      </>
    ),
    [classes.navBarItem, classes.navBarItemBlack, dispatch, history, location.pathname, user, showAdminLink, showApiLink, imDefaultPath],
  );

  const handleLogoClick = useCallback(
    () => history.push(user?.isAdvisor ? ROUTES.ADVISOR.DASHBOARD : ROUTES.DASHBOARD_OVERVIEW),
    [history, user?.isAdvisor],
  );

  return (
    <>
      <HideOnScroll>
        <AppBar variant="elevation" color="primary" className={isAdmin ? "" : classes.appBar}>
          <Toolbar className={classes.navHeight}>
            <Container className={classes.navContent}>
              <Link className={classes.normalContainer} underline="none" onClick={handleLogoClick}>
                <img className={classes.normalLogo} alt="Investor Portal Logo" src={logo} />
              </Link>
              <div className={classes.normalNavItemsContainer}>{navItems}</div>
              <TouchNavbar>{navItems}</TouchNavbar>
            </Container>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar className={classes.navHeight} />
    </>
  );
};

export default InvestorNavBar;
