import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { FC, useState } from "react";

interface Props {
  open: boolean;
  submit: () => void;
  onClose: () => void;
}

const AdminApplicationConfirm: FC<Props> = ({ open, submit, onClose }) => {
  const [disableButtons, setDisableButtons] = useState(false);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Are you sure you want to submit this application?</DialogTitle>
      <DialogContent>
        <DialogContentText color="textPrimary">
          Submitting this application on behalf of an investor will send the investor an email and initiate the signing process for them.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" disabled={disableButtons} onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={disableButtons}
          onClick={async () => {
            setDisableButtons(true);
            await submit();
            setDisableButtons(true);
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AdminApplicationConfirm;
