import { Button, Container, createStyles, FormGroup, LinearProgress, makeStyles, Typography } from "@material-ui/core";
import { ErrorBoundary } from "@sentry/react";
import ApplicationApi from "client/api/application.api";
import ApplicationsApi from "client/api/applications.api";
import IdentityCheck from "client/components/FormControls/IdentityCheck.component";
import { ApplicationContext, APPLICATION_TYPES } from "client/context/application.context";
import { ROUTES } from "client/utils/constants";
import notifications from "client/utils/notifications";
import { Field, Form, Formik } from "formik";
import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { LeanApplication } from "server/services/application/application.types";
import * as Yup from "yup";

const useStyles = makeStyles(
  () =>
    createStyles({
      container: {
        minHeight: "70vh",
        display: "flex",
        overflowY: "auto",
      },
      form: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      },
    }),
  { name: "IdVerificationView" },
);

const SCHEMA = Yup.object({
  completeNow: Yup.boolean(),
  completed: Yup.boolean().when("completeNow", {
    is: (value: any) => Boolean(value),
    then: Yup.boolean().oneOf([true], "Please complete the ID Verification").required("Please complete the ID Verification"),
    otherwise: Yup.boolean().nullable(),
  }),
});

const INITIAL_VALUES = {
  completeNow: false,
  completed: null,
};

const IdVerification: FC = (props) => {
  const classes = useStyles(props);
  const { application, dispatch } = useContext(ApplicationContext);
  const history = useHistory();
  const [updatedApplication, setUpdatedApplication] = useState<LeanApplication>();
  const [docInterval, setDocInterval] = useState<ReturnType<typeof setInterval>>();
  const [loading, setLoading] = useState(false);
  const [yotiURL, setYotiURL] = useState<string | undefined>();

  useEffect(() => {
    if (!application) {
      (async () => {
        const app = await ApplicationsApi.getUsersApplication();
        dispatch({ type: APPLICATION_TYPES.SET_APPLICATION, payload: app });
      })();
    }
  }, [application, dispatch]);

  useEffect(() => {
    if (application) {
      (async () => {
        const data = await ApplicationApi.getAMLChecksURL(application._id);
        if (data) setYotiURL(data);
      })();
    }
  }, [application]);

  const goForward = useCallback(() => history.push(ROUTES.APPLICATION_ASSESSMENT), [history]);
  const handleBack = useCallback(() => history.push(ROUTES.DASHBOARD), [history]);

  const handleVerificationComplete = useCallback(async () => {
    await ApplicationApi.setFlowCompleted(application!._id);
    setLoading(true);
    setDocInterval(
      setInterval(async () => {
        const details = await ApplicationApi.getInvestorDetails(application!._id);
        if (details) setUpdatedApplication(details);
      }, 1000),
    );
  }, [application]);

  const handleClearInterval = useCallback(() => {
    if (docInterval) {
      clearInterval(docInterval);
      setDocInterval(undefined);
    }
  }, [docInterval]);

  useEffect(() => {
    if (loading) {
      const timeout = setTimeout(() => {
        setLoading(false);
        notifications.info(
          "Thank you for uploading your documentation. This will now be reviewed by our team. Please continue completing the application form.",
          10,
        );
        handleClearInterval();
        goForward();
      }, 10000);
      return () => clearTimeout(timeout);
    }
  }, [handleClearInterval, loading, goForward]);

  useEffect(() => {
    if (updatedApplication) {
      notifications.success(
        "Thank you for uploading your documentation. This will now be reviewed by our team. We have automatically extracted your information and included it in the application form, please continue completing the application form.",
        20,
      );
    }
  }, [updatedApplication]);

  useEffect(() => {
    if (updatedApplication) {
      handleClearInterval();
      dispatch({ type: APPLICATION_TYPES.SET_APPLICATION, payload: updatedApplication });
      goForward();
    }
  }, [handleClearInterval, updatedApplication, goForward, dispatch]);

  return (
    <ErrorBoundary onError={goForward}>
      <Container maxWidth="md" className={classes.container}>
        <Formik initialValues={INITIAL_VALUES} validationSchema={SCHEMA} onSubmit={goForward}>
          {() => (
            <Form className={classes.form}>
              <Field
                component={IdentityCheck}
                amlChecksURL={yotiURL}
                onComplete={handleVerificationComplete}
                onSkip={goForward}
                skipDisabled={loading}
              />
              {!loading ? null : (
                <FormGroup>
                  <LinearProgress color="primary" />
                  <Typography variant="body1" align="center">
                    Fetching data....
                  </Typography>
                </FormGroup>
              )}
              <FormGroup>
                <Button onClick={handleBack} variant="outlined" size="small" disabled={loading}>
                  Previous
                </Button>
              </FormGroup>
            </Form>
          )}
        </Formik>
      </Container>
    </ErrorBoundary>
  );
};

export default IdVerification;
