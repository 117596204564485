import { SvgIcon, SvgIconProps } from "@material-ui/core";
import { ReactComponent as MenuIcon } from "client/assets/menu.svg";
import React, { FC } from "react";

interface Props extends SvgIconProps {}

const Menu: FC<Props> = ({ ...props }) => {
  return <SvgIcon {...props} component={MenuIcon} viewBox="0 0 344.339 344.339" />;
};

export default Menu;
