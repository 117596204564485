import { LeanApplication } from "server/services/application/application.types";
import { LeanAssessment } from "server/services/assessment";
import notifications from "../../../utils/notifications";
import { ipAxios } from "../../ipAxios";

const getApplicationById = async (clientId: string, applicationId: string) => {
  try {
    const res = await ipAxios.get(`/advisor/clients/${clientId}/applications/${applicationId}/applicationContext`);
    return res.data;
  } catch (error: any) {
    notifications.genericError(error);
    return null;
  }
};

const getApplication = async (clientId: string) => {
  try {
    const res = await ipAxios.get(`/advisor/clients/${clientId}/applications/application`);
    return res.data;
  } catch (error: any) {
    notifications.genericError(error);
    return null;
  }
};

const postPersonalDetails = async (clientID: string, applicationId: string, values: any) => {
  try {
    const res = await ipAxios.post<LeanApplication>(`/advisor/clients/${clientID}/applications/${applicationId}/personalDetails`, values);
    return res.data;
  } catch (error: any) {
    notifications.genericError(error);
    return null;
  }
};

const postObjectives = async (clientID: string, applicationId: string, values: any) => {
  try {
    const res = await ipAxios.post<LeanApplication>(`/advisor/clients/${clientID}/applications/${applicationId}/objectives`, values);
    return res.data;
  } catch (error: any) {
    notifications.genericError(error);
    return null;
  }
};

const postFinances = async (clientID: string, applicationId: string, values: any) => {
  try {
    const res = await ipAxios.post<LeanApplication>(`/advisor/clients/${clientID}/applications/${applicationId}/finances/`, values);
    return res.data;
  } catch (error: any) {
    notifications.genericError(error);
    return null;
  }
};

const postExperience = async (clientID: string, applicationId: string, values: any) => {
  try {
    const res = await ipAxios.post<LeanApplication>(`/advisor/clients/${clientID}/applications/${applicationId}/experience/`, values);
    return res.data;
  } catch (error: any) {
    notifications.genericError(error);
    return null;
  }
};

const postInvestment = async (clientID: string, applicationId: string, values: any) => {
  try {
    const res = await ipAxios.post<LeanApplication>(`/advisor/clients/${clientID}/applications/${applicationId}/investment/`, values);
    return res.data;
  } catch (error: any) {
    if (error.response.status >= 500) {
      notifications.genericError(error);
    } else {
      notifications.error(error.response.data, error);
    }
    return null;
  }
};

const getClientApplications = async (clientId: string) => {
  try {
    const response = await ipAxios.get(`/advisor/clients/${clientId}/applications`);
    return response.data;
  } catch (error: any) {
    notifications.genericError(error);
    return null;
  }
};

export const getAdvisedAssessment = async (clientId: string) => {
  try {
    const res = await ipAxios.get<LeanAssessment>(`/advisor/clients/${clientId}/assessment`);
    return res.data;
  } catch (error: any) {
    notifications.genericError(error);
    return null;
  }
};

export async function completeAdvisedAssessment(clientId: string) {
  try {
    const res = await ipAxios.put<LeanAssessment>(`/advisor/clients/${clientId}/assessment/continue`);
    return res.data;
  } catch (error: any) {
    notifications.genericError(error);
    return null;
  }
}

export async function skipCooldownAndCompleteAdvisedAssessment(clientId: string) {
  try {
    const res = await ipAxios.put<LeanAssessment>(`/advisor/clients/${clientId}/assessment/cooldown/skip`);
    return res.data;
  } catch (error: any) {
    notifications.genericError(error);
    return null;
  }
}

export default {
  getApplication,
  postPersonalDetails,
  postObjectives,
  postExperience,
  postFinances,
  postInvestment,
  getClientApplications,
  getApplicationById,
};
