import { createStyles, makeStyles, Theme, Typography, TypographyProps, useMediaQuery, useTheme } from "@material-ui/core";
import clsx from "clsx";
import React, { FC, HTMLAttributes, ReactNode, useEffect, useState } from "react";

interface Props {
  deviceProps?: HTMLAttributes<HTMLDivElement>;
  mediaQuery?: string | ((theme: Theme) => string);
  helperText?: ReactNode;
  helperTextProps?: TypographyProps;
  onMediaQueryChange?: (mediaQueryRes: boolean) => any;
}

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      "@keyframes rotate": {
        "0%": {
          transform: "rotate(0deg)",
        },
        "50%": {
          transform: "rotate(90deg)",
        },
        "100%": {
          transform: "rotate(90deg)",
        },
      },
      device: {
        height: 100,
        width: 50,
        background: theme.palette.common.white,
        border: `3px solid ${theme.palette.secondary.main}`,
        borderRadius: 10,
        animation: "$rotate 1.5s ease-in-out infinite alternate",
      },
      rotateContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
    }),
  { name: "RotateDeviceComponent" },
);

const RotateDevice: FC<Props> = ({
  mediaQuery,
  onMediaQueryChange,
  helperText = "Please rotate your device",
  deviceProps = {},
  helperTextProps = {},
  ...props
}) => {
  const classes = useStyles(props);
  const theme = useTheme();
  const displayHelper = useMediaQuery(mediaQuery ?? `${theme.breakpoints.down(500)} and (orientation: portrait)`);
  const [prevState, setPrevState] = useState<boolean | null>(null);

  useEffect(() => {
    if (displayHelper !== prevState) {
      if (onMediaQueryChange) onMediaQueryChange(displayHelper);
      setPrevState(displayHelper);
    }
  }, [displayHelper, onMediaQueryChange, prevState]);

  return (
    <>
      {displayHelper ? (
        <div className={classes.rotateContainer}>
          <div {...deviceProps} className={clsx(classes.device, deviceProps.className)} />
          <Typography variant="body1" align="center" {...helperTextProps}>
            {helperText}
          </Typography>
        </div>
      ) : (
        props.children
      )}
    </>
  );
};

export default RotateDevice;
