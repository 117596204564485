import { AuthContext } from "client/context/auth.context";
import { ConfigContext } from "client/context/config.context";
import React, { useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { ContentsEntry } from "server/services/fund/fund.types";
import IMPage from "./IMPage.view";

interface Props {
  config: ContentsEntry;
}

const TabPanel: React.FC<Props> = ({ config }) => {
  const history = useHistory();
  const { tabName } = useParams<{ tabName: string }>();
  const { fund } = useContext(ConfigContext);
  const {
    state: { user },
  } = useContext(AuthContext);

  useEffect(() => {
    if (tabName === config.tabValue && !user?.isVerified && !user?.isQualified && !config.unrestricted) {
      const howToInvestPath = fund.informationMemorandum.contents.find((content) => Boolean(content.howToInvest))!.tabValue;
      history.replace(howToInvestPath);
    }
  }, [
    config.tabLabel,
    config.tabValue,
    config.unrestricted,
    fund.informationMemorandum.contents,
    history,
    tabName,
    user?.isQualified,
    user?.isVerified,
  ]);

  if (tabName !== config.tabValue || (!user?.isVerified && !user?.isQualified && !config.unrestricted)) return null;
  return <IMPage config={config} />;
};

export default TabPanel;
