import { Button, Dialog, DialogContent, DialogTitle, FormGroup, Typography } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import React, { useCallback, useContext } from "react";
import * as Yup from "yup";
import { verifyAdvisor } from "../../../api/admin/users.adminApi";
import CheckboxQuestion from "../../../components/FormControls/CheckboxQuestion.component";
import { AdminContext, ADMIN_TYPES } from "../../../context/admin.context";

const SCHEMA = Yup.object({
  doNotSendEmail: Yup.boolean().oneOf([true, false]),
});

const INITIAL_VALUES = {
  doNotSendEmail: false,
};

const VerifyAdvisor = ({ advisor, onClose, ...props }) => {
  const { dispatch } = useContext(AdminContext);

  const handleFormSubmit = useCallback(
    async (values) => {
      const user = await verifyAdvisor(advisor._id, values);

      if (user) {
        dispatch({ type: ADMIN_TYPES.UPDATE_USER, payload: user });
        onClose();
      }
    },
    [advisor, dispatch, onClose],
  );
  return (
    <>
      <Dialog onClose={onClose} {...props}>
        <DialogTitle>Verify: {advisor ? advisor.email : ""}</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to verify this adviser?</p>
          <Formik validationSchema={SCHEMA} initialValues={INITIAL_VALUES} onSubmit={handleFormSubmit}>
            {() => (
              <Form data-testid="verifyAdvisorForm">
                <FormGroup>
                  <Field label="Do not send email notification" name="doNotSendEmail" component={CheckboxQuestion} />
                </FormGroup>
                <FormGroup row>
                  <Button variant="text" onClick={() => onClose()}>
                    <Typography color="textPrimary">Cancel</Typography>
                  </Button>
                  <Button data-testid="modalOk" type="submit">
                    Yes
                  </Button>
                </FormGroup>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default VerifyAdvisor;
