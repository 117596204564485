import notifications from "client/utils/notifications";
import { ipAxios } from "./ipAxios";

const resendConfirmation = async () => {
  try {
    const res = await ipAxios.post(`/authentication/resendConfirmation`);
    return res.status;
  } catch (error) {
    notifications.genericError(error);
    return null;
  }
};

export default { resendConfirmation };
