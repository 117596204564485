import notifications from "client/utils/notifications";
import { UpdateQuery } from "mongoose";
import { AdminCertSubmission, DbCertSubmission, LeanCertSubmission } from "server/services/certificateSubmissions/certSubmission.types";
import { ipAxios } from "../ipAxios";
import fileDownload from "js-file-download";

/**
 * Retrieve all certificate submission entries
 * from the database.
 *
 * @param query
 * @returns AxiosResponse data
 */

export const getCertificateSubmissions = async (query?: GenericObject) => {
  try {
    const res = await ipAxios.get<AdminCertSubmission[]>("/admin/certificateSubmissions/", { params: query });
    return { data: res.data, count: parseInt(res.headers["x-count"]) };
  } catch (error: any) {
    notifications.error(error.message, error);
    return null;
  }
};

/**
 * Update the specified submission
 *
 * @param values
 * @param submissionId
 * @returns AxiosResponse data
 */

export const saveCertificateSubmission = async (values: UpdateQuery<DbCertSubmission>, submissionId?: string) => {
  try {
    const data = new FormData();
    data.append("body", JSON.stringify(values));
    const res = await ipAxios.post<LeanCertSubmission>(`/admin/certificateSubmissions/${submissionId}`, data);
    return res.data;
  } catch (error: any) {
    notifications.error(error.message, error);
    return false;
  }
};

/**
 * Delete the specified submission
 *
 * @param submissionId
 * @returns AxiosResponse data
 */

export const deleteSubmission = async (submissionId: string) => {
  try {
    const res = await ipAxios.delete<LeanCertSubmission>(`/admin/certificateSubmissions/${submissionId}`);
    return res.data;
  } catch (error: any) {
    notifications.error(error.message);
    return null;
  }
};

export const getUserCertificateStatuses = async () => {
  try {
    const res = await ipAxios({
      url: "/admin/certificateSubmissions/statuses/",
      method: "GET",
      responseType: "blob",
    });
    fileDownload(new Blob([res.data]), `certificate-submission-statuses.csv`);
    return res.data;
  } catch (error: any) {
    notifications.error(error.message);
    return null;
  }
};
