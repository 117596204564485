import { ApolloProvider } from "@apollo/client";
import { ThemeProvider } from "@material-ui/core";
import React, { useEffect, useMemo } from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import mergeThemes from "./theme";
import createApolloClient from "./utils/createApolloClient";
import "./wdyr";

globalThis.dataLayer = [];

const apolloClient = createApolloClient();

const Main: React.FC = () => {
  useEffect(() => {
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles?.parentElement) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  const mergedTheme = useMemo(() => mergeThemes(__CONFIG__.theme), []);

  return (
    <BrowserRouter>
      <ApolloProvider client={apolloClient}>
        <ThemeProvider theme={mergedTheme}>
          <App config={__CONFIG__} user={__CONFIG__.user} />
        </ThemeProvider>
      </ApolloProvider>
    </BrowserRouter>
  );
};

ReactDOM.hydrate(<Main />, document.getElementById("root"));

if (module.hot) {
  module.hot.accept();
}
