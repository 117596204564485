import { roundTo2DP } from "client/views/AdminPanel/Deployments/utils";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { LeanShareTransaction, ShareTransactionAggregates } from "server/services/shareTransaction/shareTransaction.types";

interface Props {
  shareTransaction: LeanShareTransaction;
  handleAdjustmentFactor: (subscriptionIndex: number, adjustmentFactor: number) => void;
  resetAdjustments: () => void;
  roundingErrorHandler: (roundingError: boolean) => void;
}

const calculateShareTransactionAggregates = (shareTransaction: LeanShareTransaction): ShareTransactionAggregates => {
  const noOfShares = _.sumBy<(typeof shareTransaction.subscriptions)[number]>(shareTransaction.subscriptions, "noOfShares");
  const pricePerShare = shareTransaction?.pricePerShare ?? 0;
  const investmentCost = pricePerShare * (noOfShares ?? 0);
  const investmentCost2DP = roundTo2DP(investmentCost);

  const investmentCostFromSubscriptions = _.sumBy(
    shareTransaction.subscriptions.map((subscription) => roundTo2DP((subscription.noOfShares ?? 0) * pricePerShare)),
  );
  const investmentCostFromSubscriptions2DP = roundTo2DP(investmentCostFromSubscriptions);
  const investmentCostDifference = roundTo2DP(investmentCost2DP - investmentCostFromSubscriptions2DP);
  const adjustmentFactor = _.sumBy<(typeof shareTransaction.subscriptions)[number]>(shareTransaction.subscriptions, "adjustmentFactor");
  const investmentCostFromSubscriptionsAfterAdjustment2DP = investmentCostFromSubscriptions2DP + adjustmentFactor;

  return {
    noOfShares,
    pricePerShare,
    investmentCostFromSubscriptions,
    investmentCost,
    investmentCost2DP,
    investmentCostFromSubscriptions2DP,
    investmentCostDifference,
    investmentCostFromSubscriptionsAfterAdjustment2DP,
    adjustmentFactor,
  };
};

const useRoundingError = ({ shareTransaction, handleAdjustmentFactor, resetAdjustments, roundingErrorHandler }: Props) => {
  const [changesConfirmed, setChangesConfirmed] = useState<boolean>(false);

  const shareTransactionAggregates = useMemo<ShareTransactionAggregates>(
    () => calculateShareTransactionAggregates(shareTransaction),
    [shareTransaction],
  );

  const hasRoundingError = useMemo(
    //this will check if the transaction has rounding error or not with original values without adjustment factor
    () => shareTransactionAggregates && shareTransactionAggregates?.investmentCostDifference < 0,
    [shareTransactionAggregates],
  );

  const hasRoundingErrorResolved = useMemo(
    //property is used to highligh the admin if error has resolved and he can safely confirm the changes
    () =>
      hasRoundingError &&
      shareTransactionAggregates &&
      shareTransactionAggregates.investmentCostDifference + shareTransactionAggregates.adjustmentFactor * -1 == 0,
    [hasRoundingError, shareTransactionAggregates],
  );

  useEffect(() => {
    if (!hasRoundingErrorResolved) {
      setChangesConfirmed(false);
    }
  }, [hasRoundingErrorResolved]);

  useEffect(() => {
    //share transaction from will know that error is resolved and confirmed so that it can make decision to allow the user to submit the transaction or stop from submission.
    if (hasRoundingError && hasRoundingErrorResolved && changesConfirmed) {
      roundingErrorHandler(false);
    } else {
      roundingErrorHandler(hasRoundingError);
    }

    //formik handlers are exluded from dependencies because they create infinite looping
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changesConfirmed, hasRoundingErrorResolved, hasRoundingError]);

  useEffect(() => {
    //whenever price per share or total shares changed in share transaction form, subscriptions adjstment factor should be reset
    setChangesConfirmed(false);
    resetAdjustments();

    //excluding dependencies because of formik, it always return new instance of callback function, and we would face infinite loop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shareTransaction.pricePerShare, shareTransaction.totalShares]);

  const autoFixRoundingError = useCallback(() => {
    if (shareTransactionAggregates.investmentCostDifference < 0) {
      //reset previous adjustments
      if (shareTransactionAggregates.adjustmentFactor) {
        resetAdjustments();
      }

      //fix the difference automatically
      const topInvestorSubscription = _.maxBy([...shareTransaction.subscriptions], "noOfShares");
      const topInvestorSubscriptionIndex = shareTransaction.subscriptions.findIndex((subscription) => subscription === topInvestorSubscription);

      handleAdjustmentFactor(topInvestorSubscriptionIndex, shareTransactionAggregates.investmentCostDifference);
    }
  }, [
    handleAdjustmentFactor,
    resetAdjustments,
    shareTransaction.subscriptions,
    shareTransactionAggregates.adjustmentFactor,
    shareTransactionAggregates.investmentCostDifference,
  ]);
  return {
    autoFixRoundingError,
    shareTransactionAggregates,
    hasRoundingError,
    changesConfirmed,
    setChangesConfirmed,
    hasRoundingErrorResolved,
  };
};
export default useRoundingError;
