import { Container, makeStyles, Typography } from "@material-ui/core";
import { ConfigContext } from "client/context/config.context";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { LeanUser } from "server/services/user/user.types";
import AdvisorClientsApi from "../../api/advisor/clients.advisorApi";
import BasicPageTemplate from "../../components/BasicPageTemplate.component";
import DashboardButton from "../../components/CTAs/DashboardButton.component";
import useAnalytics from "../../hooks/useAnalytics";
import { ROUTES, TRACKED_EVENTS } from "../../utils/constants";
import { clearApplicationData } from "../../utils/helpers";

const { PAGE_VIEWS } = TRACKED_EVENTS;

const useStyles = makeStyles(
  (theme) => ({
    container: {
      display: "flex",
      flexDirection: "column",
    },
    reviewRequired: {
      marginBottom: theme.spacing(4),
    },
    sectionContainer: {
      backgroundColor: "#F8F8F8",
      borderTop: "solid 1px",
      borderBottom: "solid 1px",
    },
    paymentContainer: {
      paddingBottom: 0,
      paddingTop: 0,
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "center",
    },
    titleContainer: {
      paddingBottom: 0,
    },
  }),
  { name: "ApplicationCompleteView" },
);

const ApplicationComplete: React.FC = (props) => {
  const classes = useStyles(props);
  const history = useHistory();
  const match = useRouteMatch<{ clientID?: string }>();
  const { fund } = useContext(ConfigContext);
  const [client, setClient] = useState<Partial<LeanUser> | null>({});

  const clientId = useMemo(() => match.params.clientID, [match.params.clientID]);

  useAnalytics();

  useEffect(() => {
    if (clientId) {
      (async () => {
        const tempClient = await AdvisorClientsApi.getClient(clientId);
        if (tempClient) setClient(tempClient);
      })();
    } else {
      setClient(null);
    }
  }, [clientId]);

  useEffect(() => {
    clearApplicationData();
    globalThis.dataLayer.push({
      event: clientId ? PAGE_VIEWS.CLIENT_APPLICATION_FORM_COMPLETE : PAGE_VIEWS.INVESTOR_APPLICATION_COMPLETE,
    });
  }, [clientId]);

  return clientId ? (
    <BasicPageTemplate
      title="Application Submitted"
      body={`
        You have submitted the application for ${client.forenames} ${client.surname}.
        We will now send the signed document to your client to electronically execute.
      `}
      onClick={() => history.push(ROUTES.ADVISOR.DASHBOARD)}
      buttonText="Continue to dashboard"
    />
  ) : (
    <>
      <Container maxWidth="md" className={classes.titleContainer}>
        <Typography variant="h1" align="center" paragraph>
          Application complete!
        </Typography>
        <Typography variant="body1" align="center" paragraph>
          {`Thank you for your application to invest in the ${
            fund.name
          }! You will receive an email shortly containing a PDF document of your signed application. We will review your application and if we have any questions or concerns regarding your suitability to invest${
            fund.aml.amlDocumentsUploadable ? " or your AML documents" : ""
          }, we will come back to you.`}
        </Typography>
      </Container>
      <Container maxWidth="md" className={classes.buttonContainer}>
        <DashboardButton size="large" />
      </Container>
    </>
  );
};

export default ApplicationComplete;
