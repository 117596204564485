import { Collapse, Container, Fade, Link, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import LoginForm from "./Forms/LoginForm.component";
import RegisterForm from "./Forms/RegisterForm.component";
import { ConfigContext } from "client/context/config.context";

const useStyles = makeStyles(
  () => ({
    root: {
      position: "sticky",
      top: 0,
    },
    cardHeader: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    hide: {
      display: "none",
    },
  }),
  { name: "EntryFormPane" },
);

interface Props {
  register?: boolean;
}

const FormPane: React.FC<Props> = ({ register = false, ...props }) => {
  const classes = useStyles(props);
  const history = useHistory();
  const { IS_PRODUCTION } = useContext(ConfigContext);

  return (
    <Container className={classes.root} maxWidth="xs">
      <div className={classes.cardHeader}>
        <Fade in={!register}>
          <div className={clsx({ [classes.hide]: register })}>
            <Typography variant="h3" align="center" paragraph>
              Welcome back
            </Typography>
            <Typography variant="body2" align="center" paragraph style={{ display: `${IS_PRODUCTION && "none"}` }}>
              Haven&apos;t signed up?{" "}
              <Link onClick={() => history.push({ pathname: "/register", search: history.location.search })} data-testid="registerLink">
                Register
              </Link>
            </Typography>
          </div>
        </Fade>
        <Fade in={register}>
          <div className={clsx({ [classes.hide]: !register })}>
            <Typography variant="h3" align="center" paragraph>
              Register & certify in under 5 minutes
            </Typography>
            <Typography variant="body2" align="center" paragraph>
              Already have an account?{" "}
              <Link onClick={() => history.push({ pathname: "/login", search: history.location.search })} data-testid="loginLink">
                Login
              </Link>
            </Typography>
            {IS_PRODUCTION && (
              <Typography variant="body2" align="center" paragraph>
                New applications are now closed for 2023/24
              </Typography>
            )}
          </div>
        </Fade>
      </div>
      <Collapse in={register}>
        <RegisterForm />
      </Collapse>
      <Collapse in={!register}>
        <LoginForm />
      </Collapse>
    </Container>
  );
};
FormPane.whyDidYouRender = true;

export default FormPane;
