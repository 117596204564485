import { Button, FormGroup, Link, makeStyles, Typography } from "@material-ui/core";
import PasswordQuestion from "client/components/FormControls/PasswordQuestion.component";
import FormikErrorsTouched from "client/components/FormikErrorsTouched.component";
import { ConfigContext } from "client/context/config.context";
import { Field, Form, Formik } from "formik";
import React, { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { LeanUser } from "server/services/user/user.types";
import * as Yup from "yup";
import PublicAuthenticationApi from "../../../api/public/authentication.publicApi";
import PublicUserApi from "../../../api/public/user.publicApi";
import ButtonSelector from "../../../components/FormControls/ButtonSelector.component";
import CheckboxQuestion from "../../../components/FormControls/CheckboxQuestion.component";
import TextQuestion from "../../../components/FormControls/TextQuestion.component";
import { AuthContext, AUTH_TYPES } from "../../../context/auth.context";
import useQuery from "../../../hooks/useQuery.hook";
import { clearRegisterFormValues, getRegisterFormValues, setRegisterFormValues } from "../../../utils/auth";
import { ROUTES, TRACKED_EVENTS, USER_TYPES } from "../../../utils/constants";

export interface RegisterFormValues extends Partial<Pick<LeanUser, "forenames" | "surname" | "email" | "phoneNumber">> {
  personalDetails: { contactNumber?: string };
  investorAdvisor: string;
  understandRisk: boolean;
}

interface Props extends Pick<RegisterFormValues, "email" | "forenames" | "surname" | "phoneNumber"> {
  forgottenPassword?: boolean;
  advisedClient?: string | null;
  applicationId?: string | null;
  userId?: string;
  userType?: (typeof USER_TYPES)[keyof typeof USER_TYPES];
}

const { PAGE_ACTIONS } = TRACKED_EVENTS;

const useStyles = makeStyles(
  (theme) => ({
    formContainer: {
      display: "flex",
      flexDirection: "column",
      "& > form > .MuiFormGroup-root": {
        marginTop: theme.spacing(2),
      },
    },
    header: {
      textAlign: "center",
    },
    formWrapper: {
      display: "flex",
      alignItems: "center",
    },
    inputName: {
      marginTop: 0,
    },
    formikInputContainer: {
      width: "100%",
    },
    radioContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      margin: "5px 0px",
      justifyContent: "space-around",
    },
    checkboxWrapper: {
      display: "flex",
      justifyContent: "flex-start",
    },
  }),
  { name: "RegisterFormComponent" },
);

const getSchema = (forgottenPassword?: boolean, prevRegistered?: Props["userType"]) =>
  Yup.object().shape({
    forenames: Yup.string().required("").max(50),
    surname: Yup.string().required("").max(50),
    email: Yup.string().email("Not a valid email").required("").max(100),
    phoneNumber: Yup.string().nullable(),
    password: Yup.string().required("").min(6).max(20),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Your passwords don't match")
      .required(""),
    personalDetails: Yup.object({
      contactNumber: Yup.string().nullable(),
    }),
    ...(prevRegistered || forgottenPassword
      ? {}
      : {
          investorAdvisor: Yup.string()
            .oneOf([USER_TYPES.DIRECT_INVESTOR, USER_TYPES.ADVISOR], "Please choose one of the options")
            .required("Please choose one of the options"),
        }),
    ...(forgottenPassword
      ? {}
      : {
          understandRisk: Yup.boolean().typeError("").oneOf([true], "").required(""),
        }),
  });

const linkedInPixel = () => {
  const imgtag = document.createElement("img");
  imgtag.height = 1;
  imgtag.width = 1;
  imgtag.style.display = "none";
  imgtag.alt = "";
  imgtag.src = `https://px.ads.linkedin.com/collect/?pid=1798948&conversionId=1929884&fmt=gif&time=${Date.now()}`;
  document.body.append(imgtag);
};

const RegisterForm: React.FC<Props> = (props) => {
  const { dispatch } = React.useContext(AuthContext);
  const { fund, IS_PRODUCTION } = React.useContext(ConfigContext);
  const { userType, forgottenPassword } = props;
  const disableFields = useMemo(
    () => Boolean(props.forenames || props.advisedClient || forgottenPassword),
    [forgottenPassword, props.advisedClient, props.forenames],
  );
  const classes = useStyles(props);
  const history = useHistory();
  const [queryType, queryUid, isApplicationUpdate] = useQuery(["type", "uid", "update"]);

  const handleRegisterSuccess = useCallback(
    (data, investorAdvisor) => {
      clearRegisterFormValues();
      if (userType !== USER_TYPES.ADVISOR) {
        linkedInPixel();
      }

      globalThis.dataLayer.push({ event: PAGE_ACTIONS.REGISTER_FORM_SUBMITTED_SUCCESSFULLY });
      dispatch({ type: AUTH_TYPES.SET_USER, payload: data });

      if (isApplicationUpdate) {
        const clientLinkPart = props.advisedClient ? `/advisor/client/${props.advisedClient}` : "";
        return history.push(`${clientLinkPart}/applications/${props.applicationId}/apply/personalDetails`);
      }

      if (investorAdvisor === USER_TYPES.ADVISOR) {
        return history.push(ROUTES.ADVISOR.DASHBOARD);
      }

      if (props.advisedClient) {
        return history.push(`${ROUTES.ADVISOR.FEE_PAGE}?sigId=${props.advisedClient}&applicationId=${props.applicationId}`);
      }

      history.push(ROUTES.QUALIFY);
    },
    [dispatch, history, props.advisedClient, props.applicationId, userType, isApplicationUpdate],
  );

  const handleFormSubmit = useCallback(
    async (values) => {
      const { confirmPassword, understandRisk, investorAdvisor, ...dto } = values;
      if (!dto.personalDetails.contactNumber) dto.personalDetails.contactNumber = null;
      dto.phoneNumber = dto.personalDetails.contactNumber;

      if (forgottenPassword || Boolean(queryUid)) {
        const { success, data } = await PublicUserApi.updateUserPassword({
          uidToken: queryUid,
          password: values.password,
        });
        if (success) handleRegisterSuccess(data, investorAdvisor);
        return;
      }
      if (investorAdvisor === USER_TYPES.ADVISOR) {
        const { success, data } = await PublicAuthenticationApi.registerAdvisor({
          ...dto,
        });
        if (success) {
          clearRegisterFormValues();
          dispatch({ type: AUTH_TYPES.SET_USER, payload: data });
          history.push(ROUTES.ADVISOR.DASHBOARD);
        }
        return;
      }
      const { success, data } = await PublicAuthenticationApi.register({
        ...dto,
        investorType: userType || USER_TYPES.DIRECT_INVESTOR,
      });
      if (success) handleRegisterSuccess(data, investorAdvisor);
    },
    [dispatch, forgottenPassword, handleRegisterSuccess, history, queryUid, userType],
  );

  const handleRiskClick = useCallback(
    ({ confirmPassword, password, ...values }) => {
      setRegisterFormValues(values);
      history.push(ROUTES.RISK_WARNING, { fromRegister: true, referrer: location.pathname + location.search });
    },
    [history],
  );

  const schema = useMemo(() => getSchema(forgottenPassword, userType), [forgottenPassword, userType]);

  const initialValues = useMemo(() => {
    const registerFormValues = getRegisterFormValues();
    return {
      email: props.email || "",
      phoneNumber: props.phoneNumber || "",
      forenames: props.forenames || "",
      surname: props.surname || "",
      password: "",
      confirmPassword: "",
      investorAdvisor: queryType || fund.defaultUserType || USER_TYPES.DIRECT_INVESTOR,
      understandRisk: false,
      ...registerFormValues,
      personalDetails: registerFormValues.personalDetails || {
        contactNumber: props.phoneNumber || "",
      },
    };
  }, [props.email, props.phoneNumber, props.forenames, props.surname, queryType]);

  const userTypeOptions = useMemo(
    () => [
      { label: <>Direct&nbsp;Investor</>, value: USER_TYPES.DIRECT_INVESTOR },
      { label: <>Financial&nbsp;Adviser</>, value: USER_TYPES.ADVISOR },
    ],
    [],
  );

  return (
    <div className={classes.formContainer}>
      {forgottenPassword ? (
        <Typography variant="h2" className={classes.header}>
          Reset Password
        </Typography>
      ) : userType === USER_TYPES.ADVISOR ? (
        <Typography variant="h2" className={classes.header}>
          Advisor Signup
        </Typography>
      ) : userType === USER_TYPES.ADVISED_INVESTOR ? (
        <Typography variant="h2" className={classes.header}>
          Account Details
        </Typography>
      ) : null}
      <Formik validationSchema={schema} onSubmit={handleFormSubmit} initialValues={initialValues}>
        {({ values, isSubmitting }) => (
          <Form id="registration-form">
            <FormikErrorsTouched />
            <FormGroup className={classes.inputName} row>
              <Field
                component={TextQuestion}
                name="forenames"
                size="small"
                autoComplete={props.forenames && "off"}
                data-testid="forenames"
                placeholder="Forename(s)*"
                disabled={disableFields}
                required
              />
              <Field
                component={TextQuestion}
                name="surname"
                size="small"
                autoComplete={props.forenames && "off"}
                data-testid="surnames"
                placeholder="Surname*"
                disabled={disableFields}
                required
              />
            </FormGroup>
            <FormGroup>
              <Field
                component={TextQuestion}
                name="email"
                size="small"
                data-testid="email"
                disabled={disableFields}
                placeholder="Email*"
                autoComplete="email"
                required
              />
            </FormGroup>
            <FormGroup>
              <Field
                component={TextQuestion}
                name="personalDetails.contactNumber"
                size="small"
                data-testid="phoneNumber"
                placeholder="Contact Number"
                autoComplete="off"
              />
            </FormGroup>
            <FormGroup>
              <Field
                component={PasswordQuestion}
                showControls
                name="password"
                size="small"
                data-testid="password"
                placeholder={forgottenPassword ? "New Password*" : "Password*"}
                autoComplete="password"
                required
              />
            </FormGroup>
            <FormGroup>
              <Field
                component={PasswordQuestion}
                showControls
                name="confirmPassword"
                size="small"
                data-testid="confirmPassword"
                placeholder={forgottenPassword ? "Confirm your new Password*" : "Confirm your password*"}
                autoComplete="confirm-password"
                required
              />
            </FormGroup>
            {!props.userId && (
              <Field component={ButtonSelector} name="investorAdvisor" size="small" row label="I am a..." options={userTypeOptions} />
            )}
            {!forgottenPassword && (
              <FormGroup>
                <Field
                  component={CheckboxQuestion}
                  name="understandRisk"
                  size="small"
                  data-testid="understandRisk"
                  label={
                    <>
                      I have read and understood the&nbsp;<Link onClick={() => handleRiskClick(values)}>risk warning</Link>
                    </>
                  }
                />
              </FormGroup>
            )}
            <FormGroup>
              <Button
                size="small"
                fullWidth
                data-testid="regButton"
                type="submit"
                color="primary"
                disabled={(IS_PRODUCTION && !props.email) || isSubmitting}
              >
                {props.email ? "Set Password" : "Register"}
              </Button>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </div>
  );
};
RegisterForm.whyDidYouRender = true;

export default RegisterForm;
