import { Accordion, AccordionDetails, AccordionSummary, createStyles, Grid, makeStyles, Typography, useTheme } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import Markdown from "client/components/Markdown.component";
import Glossary from "client/components/UIKit/Glossary.component";
import NumberedSubTitle from "client/components/UIKit/NumberedSubTitle.component";
import { ConfigContext } from "client/context/config.context";
import { fundVariableReplacer } from "client/utils/helpers";
import Color from "color";
import { get } from "lodash";
import React, { FC, useCallback, useContext, useMemo } from "react";
import { Content, NestedSection as INestedSection } from "server/services/fund/fund.types";

interface Props {
  content: Content | Content[];
}

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      accordion: {
        backgroundColor: theme.palette.primary.main,
        "&:not(.Mui-expanded) > div.MuiAccordionSummary-root": {
          paddingTop: theme.spacing(7.5),
          paddingBottom: theme.spacing(7.5),
        },
        "&:not(:last-child)": {
          marginBottom: theme.spacing(4),
        },
      },
      accordionDetails: {
        flexDirection: "column",
      },
      accordionSummary: {
        transitionProperty: "min-height, background-color, padding",
      },
      imageGridItem: {
        flexDirection: "column",
        alignItems: "center",
        "& > *": {
          flex: 1,
        },
        "& > img": {
          width: "auto",
          height: "auto",
          paddingBottom: theme.spacing(2),
          [theme.breakpoints.down("md")]: {
            height: "4rem",
          },
        },
      },
    }),
  { name: "SectionContentComponent" },
);

interface NestedSectionProps extends Omit<INestedSection, "content"> {
  children: INestedSection["content"];
}

export const NestedSection: React.FC<NestedSectionProps> = ({ prefix, title, ...props }) => {
  const { fund } = useContext(ConfigContext);
  const replacedTitle = useMemo(() => fundVariableReplacer(title, fund), [fund, title]);
  return (
    <>
      <NumberedSubTitle number={prefix} title={replacedTitle} />
      <SectionContent content={props.children} />
    </>
  );
};

const SectionContent: FC<Props> = ({ content, ...props }) => {
  const classes = useStyles(props);
  const theme = useTheme();
  const { fund } = useContext(ConfigContext);

  const getValue = useCallback((text: string) => fundVariableReplacer(text, fund), [fund]);

  if (typeof content === "string") return <Markdown>{content}</Markdown>;
  if (Array.isArray(content)) {
    return (
      <>
        {content.map((val, index) => (
          <SectionContent key={index} content={val} />
        ))}
      </>
    );
  }
  if (content.glossary) return <Glossary dictionary={content.glossary} />;
  if (content.accordion) {
    return (
      <Accordion
        elevation={0}
        className={classes.accordion}
        style={{
          backgroundColor:
            content.accordion.color && Color(get(theme.palette, content.accordion.color, content.accordion.color)).fade(0.8).toString(),
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore color="secondary" />} className={classes.accordionSummary}>
          <Typography variant="h3">{getValue(content.accordion.title)}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Markdown>{content.accordion.content}</Markdown>
        </AccordionDetails>
      </Accordion>
    );
  }
  if (content.sections) {
    return (
      <>
        {content.sections.map((section, index) => (
          <NestedSection key={index} prefix={section.prefix ?? index + 1} title={section.title}>
            {section.content}
          </NestedSection>
        ))}
      </>
    );
  }
  if (content.imageGrid) {
    return (
      <Grid container justify="space-between" alignItems="baseline" spacing={4}>
        {content.imageGrid.map((image, index) => (
          <Grid key={index} item xs={6} md={2} className={classes.imageGridItem}>
            <img src={image.src} alt={image.title} />
            {image.title && (
              <Typography variant="body2" align="center">
                <strong>{getValue(image.title)}</strong>
              </Typography>
            )}
            {image.subtitle && (
              <Typography variant="body2" align="center">
                {getValue(image.subtitle)}
              </Typography>
            )}
          </Grid>
        ))}
      </Grid>
    );
  }
  return null;
};

export default SectionContent;
