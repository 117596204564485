import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import ClientStatementApi from "../../../../api/advisor/client/statement.clientApi";
import StatementApi from "../../../../api/statement.api";
import Filter from "../../../../components/Filter.component";
import UserContext, { UserContextTypes } from "../../../../context/user.context";
import { TRACKED_EVENTS } from "../../../../utils/constants";
import { formatThousands, toStringDate } from "../../../../utils/helpers";

const { PAGE_VIEWS, PAGE_ACTIONS } = TRACKED_EVENTS;

const useStyles = makeStyles(
  (theme) => ({
    container: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    accountBalance: {
      border: `1px solid ${theme.palette.primary.main}`,
      padding: theme.spacing(1.25, 2.5),
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("sm")]: {
        marginBottom: theme.spacing(4),
      },
    },
    emptyInfo: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",

      height: "10vh",
    },
    row: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column-reverse",
        alignItems: "unset",
      },
    },
    transactionAmount: {
      flex: 1,
      marginRight: 10,
    },
    transactionBody: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      padding: "20px",
    },
    transactionHeader: {
      width: "100%",
      padding: "10px 15px",
      display: "flex",
      alignItems: "center",
      fontWeight: theme.typography.fontWeightMedium,
      backgroundColor: "rgba(131,223,200,0.2)",
    },
    transactionInvestment: {
      backgroundColor: "rgba(112,155,255,0.2)",
    },
    transactionRow: {
      width: "100%",
    },
    transactionType: {
      flex: 1,
    },
    widget: {
      backgroundColor: theme.palette.grey.main,
      padding: 20,
      width: "100%",
      flex: 2,
      margin: "0 10px",
      [theme.breakpoints.down("xl")]: {
        padding: "10px 15px",
      },
      [theme.breakpoints.down("md")]: {
        padding: "20px 30px",
      },
      [theme.breakpoints.down("sm")]: {
        order: 1,
        margin: "10px 0",
      },
    },
  }),
  { name: "StatementComponent" },
);

interface Props {
  clientId: string;
}

const Statement: FC<Props> = ({ clientId, ...props }) => {
  const { statement, balances, clients, dispatch } = useContext(UserContext);
  const [filter, setFilter] = useState("");
  const classes = useStyles(props);
  const statementData = useMemo(() => (clients[clientId] ? clients[clientId].statement : statement), [clientId, clients, statement]);
  const balancesData = useMemo(() => (clients[clientId] ? clients[clientId].balances : balances), [balances, clientId, clients]);

  useEffect(() => {
    (async () => {
      const data = await (clientId ? ClientStatementApi.getClientStatement(clientId) : StatementApi.getStatement());
      if (data) {
        dispatch({ type: UserContextTypes.SET_STATEMENT, payload: data, clientId });
      }
    })();
  }, [clientId, dispatch]);

  useEffect(() => {
    setFilter("");
  }, [statementData]);

  useEffect(() => {
    globalThis.dataLayer.push({ event: PAGE_VIEWS.INVESTOR_DASHBOARD_STATEMENT });
  }, []);

  const filterOptions = useMemo(() => {
    return [...new Set(statementData.map((item) => item.type))].map((val) => ({
      value: val,
      label: val.charAt(0).toUpperCase() + val.slice(1),
    }));
  }, [statementData]);

  const filteredTransactions = useMemo(() => {
    return statementData.filter((transaction) => !filter || transaction.type === filter);
  }, [statementData, filter]);

  return (
    <>
      <Typography variant="h3" paragraph>
        Account activity
      </Typography>
      <div className={classes.row}>
        {statementData.length > 0 && (
          <Filter
            filterOptions={filterOptions}
            filter={filter}
            filterLabel="Filter Transactions"
            onChange={(f) => {
              globalThis.dataLayer.push({ event: PAGE_ACTIONS.PORTFOLIO_FILTER_CLICKED });
              setFilter(f);
            }}
          />
        )}
        <div className={classes.accountBalance}>
          <Typography variant="h6">Account balance</Typography>
          <Typography variant="h1" data-testid="accountBalance">
            {formatThousands(balancesData.fundsRemaining.total, 2)}
          </Typography>
        </div>
      </div>
      <br /> <br />
      {statementData.length > 0 ? (
        filteredTransactions.map((transaction, index) => (
          <div className={classes.transactionRow} key={index}>
            <Typography
              variant="body1"
              className={clsx(classes.transactionHeader, {
                [classes.transactionInvestment]: transaction.type === "investment",
              })}
            >
              {toStringDate(transaction.date)}
            </Typography>
            <div className={classes.transactionBody}>
              <Typography className={classes.transactionAmount} variant="h3" data-testid="transactionBody">
                {`${transaction.type === "investment" ? "+" : "-"} ${formatThousands(transaction.amount, 2)}`}
              </Typography>
              <Typography className={classes.transactionType} variant="body2">
                {transaction.type.toUpperCase()}
              </Typography>
            </div>
          </div>
        ))
      ) : (
        <div className={classes.widget} data-testid="transactionsEmptyState">
          <div className={classes.emptyInfo}>You currently have no transactions</div>
        </div>
      )}
    </>
  );
};

export default Statement;
