import { FormControl, FormGroup, IconButton, Link, makeStyles, MenuItem, Select, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import notifications from "client/utils/notifications";
import clsx from "clsx";
import { ErrorMessage, Field } from "formik";
import _ from "lodash";
import React, { useCallback, useEffect, useState, useContext, useMemo } from "react";
import { getAddressFromPostcode } from "../../api/thirdPartiesApi";
import { FORM_FIELDS_DATA, USER_ROLE } from "../../utils/constants";
import { fromCamelCase } from "../../utils/helpers";
import QuestionLabel from "../QuestionLabel.component";
import TrackInput from "../TrackInput.component";
import ErrorText from "../UIKit/ErrorText.component";
import TextQuestion from "./TextQuestion.component";
import { AuthContext } from "../../context/auth.context";

const { ADDRESS_FORM } = FORM_FIELDS_DATA;

const useStyles = makeStyles((theme) => ({
  styledField: {
    height: 60,
    fontSize: 16,
    "&:focus, &:hover, &:active": {
      boxShadow: "none",
      borderRightWidth: "2px !important",
    },
  },
  addressSpan: {
    marginTop: 10,

    fontWeight: theme.typography.fontWeightMedium,
    cursor: "pointer",
  },
  dropDown: {
    width: "100%",
  },
  label: {
    backgroundColor: theme.palette.grey.light,
  },
  manualLookupPostcodeLabel: {
    marginBottom: 0,
    marginTop: 20,
  },
  marginBottom: {
    marginBottom: 15,
  },
  menu: {
    maxHeight: 200,
  },
  noBottom: {
    marginBottom: 0,
  },
}));

interface PostcodeLookupFieldProps {
  field: {
    name: string;
    value: {
      addressLineOne: string;
      addressLineTwo: string;
      city: string;
      country: string;
      postcode: string;
    };
  };
  form: {
    setFieldValue: (field: string, value: any) => void;
    setFieldError: (field: string, value: any) => void;
    touched: Record<string, boolean>;
    errors: Record<string, string>;
  };
  label: string;
  trackedCategory: string;
  initialIsManual?: boolean;
  className?: string;
}

const PostcodeLookupField: React.FC<PostcodeLookupFieldProps> = ({
  field: { name, value },
  form: { setFieldValue, setFieldError, touched, errors },
  label,
  trackedCategory,
  initialIsManual = false,
  ...props
}) => {
  const [searchPostcode, setSearchPostcode] = useState("");
  const [dropdownItems, setDropdownItems] = useState(null);
  const [selectedItem, setSelectedItem] = useState(0);
  const [isManual, setIsManual] = useState(initialIsManual);
  const [loading, setLoading] = useState(false);
  const [openSelect, setOpenSelect] = useState(false);
  const classes = useStyles(props);
  const {
    state: { user },
  } = useContext(AuthContext);
  const isAdmin = useMemo(
    () => Boolean(user.role === USER_ROLE.ADMIN || user.role === USER_ROLE.READ_ONLY_ADMIN || user.role === USER_ROLE.MANAGER),
    [user],
  );

  const lookupPostcode = useCallback(
    async (postcode) => {
      setLoading(true);
      const res = await getAddressFromPostcode(postcode);
      if (res.success && !res.data.error_code) {
        const items = res.data.delivery_points.map((item, index) => ({
          label: [item.line_1, item.line_2, res.data.town, res.data.postcode].filter(Boolean).join(", "),
          value: index,
          address: {
            addressLineOne: item.line_1,
            addressLineTwo: item.line_2,
            city: res.data.town,
            country: "United Kingdom",
            postcode: res.data.postcode,
          },
        }));
        setSelectedItem(0);
        setDropdownItems(items);
        setLoading(false);
      } else {
        if (res.data.error_code === "0001") {
          // postcode is valid but non existing
          setFieldError(name, "Unknown postcode");
        } else {
          notifications.error(
            "Unable to look for postcode at the moment. Please try entering an address manually.",
            new Error(`Error looking up postcode: ${res.data.error_code}`),
          );
          setFieldError(name, "Unable to look for postcode at the moment. Please try entering an address manually.");
        }
        setSelectedItem(null);
        setDropdownItems(null);
        setLoading(false);
      }
    },
    [name, setFieldError],
  );

  useEffect(() => {
    if (dropdownItems?.[selectedItem]) {
      const { address } = dropdownItems[selectedItem];
      Object.entries(address).forEach(([key, addressLine]) => {
        setFieldValue(`${name}.${key}`, addressLine);
      });
    }
  }, [dropdownItems, name, selectedItem, setFieldValue]);

  useEffect(() => {
    if (value?.postcode) {
      setSearchPostcode(value.postcode);
    }
  }, [value]);

  return (
    <TrackInput category={trackedCategory} label={fromCamelCase(name)} value={value}>
      <QuestionLabel className={isManual ? classes.noBottom : classes.marginBottom}>{label}</QuestionLabel>
      {!isManual && (
        <TextField
          fullWidth
          variant="outlined"
          className={clsx(classes.styledField, "fs-exclude")}
          value={searchPostcode}
          placeholder={ADDRESS_FORM.searchPostcode.placeholder}
          error={Boolean(_.get(touched, name) && _.get(errors, name))}
          onChange={(e) => setSearchPostcode(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              lookupPostcode(searchPostcode);
              e.preventDefault();
            }
          }}
          InputProps={{
            endAdornment: (
              <IconButton onClick={() => lookupPostcode(searchPostcode)}>
                <SearchIcon />
              </IconButton>
            ),
          }}
        />
      )}
      {!isAdmin && !isManual && (
        <ErrorMessage name={name}>
          {() => (
            <ErrorText>
              No address selected, please make sure to search for an address by selecting the magnifying glass in the search field
            </ErrorText>
          )}
        </ErrorMessage>
      )}
      {!isManual && dropdownItems && (
        <FormGroup>
          <FormControl variant="outlined" className={classes.dropDown}>
            <QuestionLabel>Select Address</QuestionLabel>
            <Select
              open={openSelect}
              onOpen={() => setOpenSelect(true)}
              onClose={() => setOpenSelect(false)}
              value={selectedItem}
              MenuProps={{ PaperProps: { className: classes.menu } }}
              inputProps={{ onClick: () => setOpenSelect(true) }}
              disabled={loading}
              onChange={(e) => setSelectedItem(e.target.value)}
              className="fs-exclude"
            >
              {dropdownItems.map((option: any, index: number) => (
                <MenuItem key={`${option.label}-${index}`} value={option.value} className="fs-exclude">
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </FormGroup>
      )}
      {(isManual || dropdownItems?.[selectedItem]) && (
        <>
          <FormGroup>
            <Field
              component={TextQuestion}
              name={`${name}.addressLineOne`}
              disabled={!isManual}
              label={ADDRESS_FORM.addressLineOne.label}
              placeholder={ADDRESS_FORM.addressLineOne.placeholder}
              trackedCategory={trackedCategory}
            />
          </FormGroup>
          <FormGroup>
            <Field
              component={TextQuestion}
              name={`${name}.addressLineTwo`}
              disabled={!isManual}
              label={ADDRESS_FORM.addressLineTwo.label}
              placeholder={ADDRESS_FORM.addressLineTwo.placeholder}
              trackedCategory={trackedCategory}
            />
          </FormGroup>
          <FormGroup>
            <Field
              component={TextQuestion}
              name={`${name}.city`}
              disabled={!isManual}
              label={ADDRESS_FORM.city.label}
              placeholder={ADDRESS_FORM.city.placeholder}
              trackedCategory={trackedCategory}
            />
          </FormGroup>
          <FormGroup>
            <Field
              component={TextQuestion}
              name={`${name}.country`}
              disabled={!isManual}
              label={ADDRESS_FORM.country.label}
              placeholder={ADDRESS_FORM.country.placeholder}
              trackedCategory={trackedCategory}
            />
          </FormGroup>
          <FormGroup>
            <Field
              component={TextQuestion}
              name={`${name}.postcode`}
              disabled={!isManual}
              label={ADDRESS_FORM.postcode.label}
              placeholder={ADDRESS_FORM.postcode.placeholder}
              trackedCategory={trackedCategory}
            />
          </FormGroup>
        </>
      )}
      <FormGroup className={classes.manualLookupPostcodeLabel} style={{ marginBottom: 0, marginTop: "20px" }}>
        {!isManual ? (
          <Link data-testid="enterManualAddress" onClick={() => setIsManual(true)}>
            Enter Address Manually
          </Link>
        ) : (
          <Link
            onClick={() => {
              setIsManual(false);
              lookupPostcode(searchPostcode);
            }}
          >
            Lookup Postcode
          </Link>
        )}
      </FormGroup>
    </TrackInput>
  );
};

export default PostcodeLookupField;
