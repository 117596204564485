import fileDownload from "js-file-download";
import { CompanyWithHoldings } from "server/services/company/company.types";
import { HistoricValue } from "server/services/shareTransaction/shareTransaction.types";
import notifications from "../utils/notifications";
import { ipAxios } from "./ipAxios";

const getStatement = async () => {
  try {
    const res = await ipAxios.get(`/statement`);
    return res.data;
  } catch (error) {
    notifications.genericError(error);
    return null;
  }
};

const getBalance = async () => {
  try {
    const res = await ipAxios.get(`/statement/balance`);
    return res.data;
  } catch (error) {
    notifications.genericError(error);
    return null;
  }
};

const getPortfolio = async () => {
  try {
    const res = await ipAxios.get<{ historicValues: HistoricValue[]; portfolio: CompanyWithHoldings[] }>("/statement/portfolio");
    return res.data;
  } catch (error) {
    notifications.genericError(error);
    return null;
  }
};

const downloadCertificate = async (subscriptionId: string) => {
  try {
    const res = await ipAxios({
      url: `/statement/portfolio/download-certificate/${subscriptionId}`,
      method: "GET",
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (res.status === 200) {
      const filename = res.headers["content-disposition"].split("filename=")[1];

      const blob = new Blob([res.data]);
      fileDownload(blob, `${filename}`);
      return { success: true, data: res.data };
    }
    return { success: false };
  } catch (error) {
    notifications.genericError(error);
    return { success: false };
  }
};

const downloadEvidence = async (subscriptionId: string) => {
  try {
    const res = await ipAxios({
      url: `/statement/portfolio/download-evidence/${subscriptionId}`,
      method: "GET",
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (res.status === 200) {
      const blob = new Blob([res.data]);
      fileDownload(blob, `${subscriptionId}-evidence.pdf`);
      return { success: true, data: res.data };
    }
    return { success: false };
  } catch (error) {
    notifications.genericError(error);
    return { success: false };
  }
};

const downloadContractNote = async (subscriptionId: string) => {
  try {
    const res = await ipAxios.get(`/statement/portfolio/${subscriptionId}/contractNote`, { responseType: "blob" });
    fileDownload(res.data, `contract-note-${subscriptionId}.pdf`);
    return res.data;
  } catch (error) {
    notifications.genericError(error);
    return null;
  }
};

export default {
  getBalance,
  getPortfolio,
  downloadCertificate,
  downloadEvidence,
  getStatement,
  downloadContractNote,
};
