import { makeStyles, Slider } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import TrackInput from "../TrackInput.component";

const useStyles = makeStyles(
  (theme) => ({
    slider: {
      padding: theme.spacing(0),
    },
    row: {
      [theme.breakpoints.up("lg")]: {
        flex: 2,
        padding: theme.spacing(7.5, 0, 0),
      },
    },
  }),
  { name: "SliderFieldComponent" },
);

const SliderField = ({ field, form, trackedCategory, row, className, trackInputProps = {}, ...props }) => {
  const classes = useStyles(props);
  return (
    <TrackInput category={trackedCategory} label={field.name} value={field.value} {...trackInputProps}>
      <Slider
        min={0}
        max={100}
        defaultValue={50}
        {...field}
        className={clsx(classes.slider, { [classes.row]: row }, className, "fs-exclude")}
        onChange={(_event, value) => form.setFieldValue(field.name, value)}
        {...props}
      />
    </TrackInput>
  );
};

export default SliderField;
