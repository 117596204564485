import { FormGroup, Typography } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import React, { useCallback, useMemo } from "react";
import { LeanUser } from "server/services/user/user.types";
import * as Yup from "yup";
import ClientsApi from "../api/advisor/clients.advisorApi";
import { TRACKED_EVENTS } from "../utils/constants";
import notifications from "../utils/notifications";
import CrudFormButtons from "./FormControls/CrudFormButtons.component";
import NumberQuestion from "./FormControls/NumberQuestion.component";
import RadioQuestion from "./FormControls/RadioQuestion.component";
import TextQuestion from "./FormControls/TextQuestion.component";

interface Props {
  goForward(): void;
  goBack(): void;
  client?: LeanUser;
}

const {
  ADVISOR: {
    INPUT: {
      CATEGORIES: { ADD_CLIENT },
    },
  },
  PAGE_ACTIONS,
} = TRACKED_EVENTS;

const SCHEMA = Yup.lazy((value) =>
  Yup.object().shape({
    email: Yup.string().email("Please enter a valid email address").required("This field is required"),
    forenames: Yup.string().required("This field is required"),
    surname: Yup.string().required("This field is required"),
    adviceType: Yup.string().required("This field is required"),
    feePercentage:
      value.adviceType && value.adviceType.toLocaleUpperCase() === "ADVISED"
        ? Yup.number().min(0).max(3, "Your fee can be up to 3%").required("A facilitation charge is required")
        : Yup.number().min(0).max(2, "Your fee can be up to 2%").required("A facilitation charge is required"),
  }),
);

const AddClientForm: React.FC<Props> = ({ goForward, goBack, client }) => {
  const initialValues = useMemo(
    () => ({
      email: client?.email ?? "",
      forenames: client?.forenames ?? "",
      surname: client?.surname ?? "",
      adviceType: client?.advisor?.adviceType ?? "",
      feePercentage: client?.advisor?.feePercentage ?? "",
    }),
    [client],
  );

  const handleFormSubmit = useCallback(
    async (values) => {
      const res = await ClientsApi.addClient(values);
      if (res === null) {
        globalThis.dataLayer.push({ event: PAGE_ACTIONS.ADD_CLIENT_FORM_SUBMITTED_UNSUCCESSFULLY });
        return;
      }
      if (res) {
        globalThis.dataLayer.push({ event: PAGE_ACTIONS.ADD_CLIENT_FORM_SUBMITTED_SUCCESSFULLY });
        goForward();
        return;
      }
      notifications.error(
        <span>
          Sorry that email address is already linked with an account. Please try another email or get in touch to let us know of any issue at{" "}
          <a href="mailto:fund@builtventures.uk?"> fund@builtventures.uk</a>
        </span>,
        undefined,
        10,
      );
    },
    [goForward],
  );

  const updateFee = useCallback(
    async (values) => {
      const { feePercentage } = values;
      const { success } = await ClientsApi.editFee({
        clientId: client?._id,
        feePercentage,
      });
      if (!success) {
        globalThis.dataLayer.push({ event: PAGE_ACTIONS.EDIT_CLIENT_FEE_FORM_SUBMITTED_UNSUCCESSFULLY });
        return;
      }
      globalThis.dataLayer.push({ event: PAGE_ACTIONS.EDIT_CLIENT_FEE_FORM_SUBMITTED_SUCCESSFULLY });
      notifications.success("Client fee updated successfully.");
      goForward();
    },
    [client?._id, goForward],
  );

  return (
    <Formik validationSchema={SCHEMA} initialValues={initialValues} enableReinitialize onSubmit={client ? updateFee : handleFormSubmit}>
      {() => (
        <Form>
          <FormGroup>
            <Field
              component={TextQuestion}
              name="forenames"
              label="Forenames"
              placeholder="Forenames"
              trackedCategory={ADD_CLIENT}
              disabled={Boolean(client)}
            />
          </FormGroup>
          <FormGroup>
            <Field
              component={TextQuestion}
              name="surname"
              label="Surname"
              placeholder="Surname"
              trackedCategory={ADD_CLIENT}
              disabled={Boolean(client)}
            />
          </FormGroup>
          <FormGroup>
            <Field
              component={TextQuestion}
              name="email"
              label="Email"
              placeholder="Email"
              id="email"
              trackedCategory={ADD_CLIENT}
              disabled={Boolean(client)}
            />
          </FormGroup>
          <FormGroup>
            <Field
              component={RadioQuestion}
              name="adviceType"
              label={client ? "Client advice type:" : "Please select the type of advice you are providing to this client:"}
              trackedCategory={ADD_CLIENT}
              radioValues={[
                { label: "Advised", value: "ADVISED" },
                { label: "Referred", value: "REFERRED" },
              ]}
              disabled={Boolean(client)}
            />
          </FormGroup>
          {!client && (
            <FormGroup>
              <Typography variant="body2">
                <strong>An Advised client</strong> is someone that has received professional financial advice about this investment opportunity and is
                having their application managed on their behalf.
                <br />
                <br />
                <strong>A Referred client</strong> is someone that may or may not have received professional financial advice about this investment
                opportunity and will manage their application on their own.
              </Typography>
            </FormGroup>
          )}
          <FormGroup>
            <Field
              component={NumberQuestion}
              name="feePercentage"
              trackedCategory={ADD_CLIENT}
              label="Financial Adviser/Intermediary Facilitation Charge (%)"
              placeholder="Percentage"
              suffix="%"
              min="0"
              max="3"
              step="0.01"
            />
            <br />
            <Typography variant="body2" paragraph>
              Please note that Nova will pay the fee on behalf of your client so long as the application is completed online. The fee is charged for
              the net amount to be subscribed to the Fund. Fees are capped at 3% for Advised Clients and 2% for Referred Clients.
            </Typography>
          </FormGroup>
          <FormGroup>
            <CrudFormButtons submitText={client ? "Update fee" : "Add client"} cancelText="Back" onClose={goBack} />
          </FormGroup>
        </Form>
      )}
    </Formik>
  );
};

export default AddClientForm;
