import { useDeploymentApplications } from "client/api/gql/application.gqlApi";
import { useDeploymentDeals } from "client/api/gql/company.gqlApi";
import { useDeploymentOptions } from "client/api/gql/deployment.gqlApi";
import { FilteredFund, useGetFilteredFunds } from "client/api/gql/fund.gqlApi";
import { useAdminContext } from "client/hooks/adminContext.hooks";
import { arrayToObject } from "client/utils/helpers";
import React, { createContext, Reducer, useEffect, useMemo, useReducer } from "react";
import { AllDeploymentApplications, BaseDeploymentApplication } from "server/services/application/application.types";
import { AllDeploymentDeals, BaseDeploymentDeal } from "server/services/company/company.types";
import { AllocationsOptions, DeploymentOptions } from "server/services/deployment/deployment.types";
import useDealData from "./hooks/useDealData.hook";
import usePlannerData from "./hooks/usePlannerData.hook";

interface DeploymentContextState {
  baseApplications: BaseDeploymentApplication[];
  applications: AllDeploymentApplications;

  deals: BaseDeploymentDeal[];

  funds: FilteredFund[];

  options: DeploymentOptions;
}

type Action<T extends keyof ActionTypeMap = keyof ActionTypeMap, V extends ActionTypeMap[T] = ActionTypeMap[T]> = {
  type: T;
  payload: V | ((state: DeploymentContextState) => V);
};

interface DeploymentContextProviderValue extends DeploymentContextState {
  deals: AllDeploymentDeals;
  funds: FilteredFund[];
  dispatch: <K extends keyof ActionTypeMap>(action: Action<K>) => void;
}

export const DEALS: AllDeploymentDeals = [] as unknown as AllDeploymentDeals;
DEALS.totals = { config: {} };

const DEFAULT_VALUE: DeploymentContextProviderValue = {
  deals: DEALS,
  baseApplications: [],
  applications: [] as AllDeploymentApplications,
  options: { allocations: {} as AllocationsOptions },
  funds: [],
  dispatch: () => {},
};

const DeploymentContext = createContext<DeploymentContextProviderValue>(DEFAULT_VALUE);
DeploymentContext.displayName = "DeploymentContext";

interface ActionTypeMap {
  SET_APPLICATION_DATA: DeploymentContextState["applications"];

  SET_BASE_APPLICATIONS: DeploymentContextState["baseApplications"];

  SET_DEAL_DATA: DeploymentContextState["deals"];
  UPDATE_DEAL: DeploymentContextState["deals"][number];

  SET_FUNDS: DeploymentContextState["funds"];

  SET_OPTIONS: DeploymentContextState["options"];
}

const DEPLOYMENT_ACTION_MAP: {
  [Key in keyof ActionTypeMap]: (state: DeploymentContextState, payload: Action<Key>["payload"]) => DeploymentContextState;
} = {
  SET_APPLICATION_DATA: (state, payload) => {
    const applications = typeof payload === "function" ? payload(state) : payload;
    return state.applications === applications ? state : { ...state, applications };
  },

  SET_BASE_APPLICATIONS: (state, payload) => {
    const baseApplications = typeof payload === "function" ? payload(state) : payload;
    return state.baseApplications === baseApplications ? state : { ...state, baseApplications };
  },

  SET_DEAL_DATA: (state, payload) => {
    const deals = typeof payload === "function" ? payload(state) : payload;
    return state.deals === deals ? state : { ...state, deals };
  },
  UPDATE_DEAL: (state, payload) => {
    const res = typeof payload === "function" ? payload(state) : payload;
    const idx = state.deals.findIndex((deal) => deal._id === res._id);
    const deals = [...state.deals];
    if (idx !== -1) {
      deals[idx] = res;
    } else {
      deals.push(res);
    }
    return { ...state, deals };
  },

  SET_FUNDS: (state, payload) => {
    const funds = typeof payload === "function" ? payload(state) : payload;
    return state.funds === funds ? state : { ...state, funds };
  },

  SET_OPTIONS: (state, payload) => {
    const options = typeof payload === "function" ? payload(state) : payload;
    return state.options === options ? state : { ...state, options };
  },
};

const deploymentReducer: Reducer<DeploymentContextState, Action> = (state, action) => {
  if (!DEPLOYMENT_ACTION_MAP[action.type]) return state;
  return DEPLOYMENT_ACTION_MAP[action.type](state, action.payload as any);
};

export const DeploymentProvider: React.FC<{ value?: DeploymentContextState }> = ({ value = DEFAULT_VALUE, ...props }) => {
  const deploymentOptions = useDeploymentOptions();
  const deploymentDeals = useDeploymentDeals();
  const filteredFundsQuery = useGetFilteredFunds();
  const applicationsQuery = useDeploymentApplications();
  const { allCompanies } = useAdminContext();
  const [state, dispatch] = useReducer(deploymentReducer, {
    ...value,
    funds: filteredFundsQuery.data?.filteredFunds || value.funds,
    deals: deploymentDeals.data?.deals || value.deals,
    baseApplications: applicationsQuery?.data?.deploymentApplications || value.baseApplications,
    options: deploymentOptions.data?.options || value.options,
  });

  useEffect(() => {
    if (deploymentDeals.data?.deals) dispatch({ type: "SET_DEAL_DATA", payload: deploymentDeals.data.deals });
  }, [deploymentDeals.data?.deals]);
  useEffect(() => {
    if (deploymentOptions.data?.options) dispatch({ type: "SET_OPTIONS", payload: deploymentOptions.data.options });
  }, [deploymentOptions.data?.options]);
  useEffect(() => {
    if (filteredFundsQuery.data?.filteredFunds) dispatch({ type: "SET_FUNDS", payload: filteredFundsQuery.data.filteredFunds });
  }, [filteredFundsQuery.data?.filteredFunds]);
  useEffect(() => {
    if (applicationsQuery?.data?.deploymentApplications)
      dispatch({ type: "SET_BASE_APPLICATIONS", payload: applicationsQuery.data.deploymentApplications });
  }, [applicationsQuery.data?.deploymentApplications]);

  const companiesObject = useMemo(() => arrayToObject(allCompanies), [allCompanies]);
  const commonArgs = useMemo(
    () => ({
      applications: state.baseApplications,
      options: state.options,
      funds: state.funds,
      companiesObject,
    }),
    [state.baseApplications, state.options, state.funds, companiesObject],
  );

  const deals = useDealData({ ...commonArgs, deals: state.deals });
  const applications = usePlannerData({ ...commonArgs, deals });

  return <DeploymentContext.Provider value={{ ...state, deals, applications, dispatch }}>{props.children}</DeploymentContext.Provider>;
};

export default DeploymentContext;
