import { FormGroup, IconButton, IconButtonProps, makeStyles, Typography, TypographyProps } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import React from "react";

interface Props {
  label?: string;
  labelTypographyProps?: TypographyProps;
  handleClick(): any;
  iconButtonProps?: IconButtonProps;
  hideIconButton?: boolean;
  disableMargin?: boolean;
}

const useStyles = makeStyles(
  () => ({
    formGroup: {
      alignItems: "flex-end",
    },
    formGroupDisabledMargin: {
      margin: 0,
    },
    iconButton: {
      flex: 0,
    },
  }),
  { name: "FieldArrayRemoveButtonComponent" },
);

const FieldArrayRemoveButton: React.FC<Props> = ({
  label,
  labelTypographyProps = {},
  handleClick,
  iconButtonProps = {},
  hideIconButton = false,
  disableMargin = false,
  ...props
}) => {
  const classes = useStyles(props);
  return (
    <FormGroup row={Boolean(label)} className={clsx(classes.formGroup, { [classes.formGroupDisabledMargin]: disableMargin })}>
      {label && (
        <Typography variant="body1" {...labelTypographyProps}>
          {label}
        </Typography>
      )}
      {!hideIconButton && (
        <IconButton size="small" edge="end" className={classes.iconButton} {...iconButtonProps} onClick={handleClick}>
          <CloseIcon />
        </IconButton>
      )}
    </FormGroup>
  );
};

export default FieldArrayRemoveButton;
