import { TypographyProps } from "@material-ui/core";
import { ErrorMessage as FormikErrorMessage } from "formik";
import type React from "react";
import ErrorText, { ErrorTextComponentProps } from "./UIKit/ErrorText.component";

interface Props extends TypographyProps, ErrorTextComponentProps {
  name: string;
}

const ErrorMessage: React.FC<Props> = ({ name, ...typographyProps }) => {
  return (
    <FormikErrorMessage name={name}>
      {(error) => typeof error !== "undefined" && <ErrorText {...typographyProps}>{error}</ErrorText>}
    </FormikErrorMessage>
  );
};

export default ErrorMessage;
