import { CircularProgress, Container, Grid } from "@material-ui/core";
import { getToken } from "client/utils/auth";
import React, { Suspense, useCallback, useMemo } from "react";
import "swagger-ui-react/swagger-ui.css";

const SwaggerUI = React.lazy(() => import("swagger-ui-react"));

const APIDocumentation: React.FC = () => {
  const fallback = useMemo(
    () => (
      <Container>
        <Grid container justify="center">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      </Container>
    ),
    [],
  );

  const requestInterceptor = useCallback((req) => {
    req.headers.Authorization = `Bearer ${getToken()}`;
    return req;
  }, []);

  return typeof window === "undefined" ? (
    fallback
  ) : (
    <Suspense fallback={fallback}>
      <SwaggerUI url="/api/admin/docs" requestInterceptor={requestInterceptor} />
    </Suspense>
  );
};

export default APIDocumentation;
