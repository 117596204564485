import { FC } from "react";
import { Button, Grid, Typography, makeStyles } from "@material-ui/core";

interface Props {
  onClick: () => void;
}

const useStyles = makeStyles(
  (theme) => ({
    container: {
      background: `${theme.palette.error.main}`,
      padding: theme.spacing(1),
      marginTop: theme.spacing(2),
    },
  }),
  { name: "RoundingErrorWarning" },
);

export const RoundingErrorWarning: FC<Props> = ({ onClick, ...props }) => {
  const classes = useStyles(props);

  return (
    <Grid container spacing={2} className={classes.container}>
      <Typography variant="h4" color="textPrimary">
        Rounding Error Detected
      </Typography>
      <Typography variant="body2">
        The transaction amount does not add up correctly. Please review and correct the amounts before submitting.
      </Typography>
      <Button color="secondary" data-testid="fixRoundingErrorButton" onClick={onClick}>
        Fix Rounding Error
      </Button>
    </Grid>
  );
};
