import { CircularProgress, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React, { useCallback, useEffect, useState } from "react";
import { downloadDocument, getCoverImage } from "../../../api/document.api";

const useStyles = makeStyles(
  () => ({
    documentContainer: {
      cursor: "pointer",
      width: 265,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "left",
      margin: "1vw",
      boxSizing: "content-box",
    },
    documentImg: {
      width: 265,
      height: "auto",
    },
  }),
  { name: "DocumentComponent" },
);

const Document = ({ documentId, fileName, ...props }) => {
  const [coverImageURL, setCoverImageURL] = useState("");
  const [imageLoading, setImageLoading] = useState(true);
  const fetchCoverImages = useCallback(async () => {
    const res = await getCoverImage(documentId);
    setCoverImageURL(res);
    setImageLoading(false);
  }, [documentId]);
  useEffect(() => {
    fetchCoverImages();
  }, [fetchCoverImages]);

  const classes = useStyles(props);
  return (
    <div className={clsx(classes.documentContainer)} {...props}>
      {imageLoading ? (
        <CircularProgress size={24} />
      ) : (
        /* eslint-disable */
        <button
          type="button"
          style={{ border: "none", padding: 0, margin: 0, backgroundColor: "transparent" }}
          onClick={() => downloadDocument(documentId, fileName)}
          tabIndex={0}
          onKeyDown={(event) => event.key === "Enter" && downloadDocument(documentId, fileName)}
        >
          <img alt={fileName} className={classes.documentImg} src={coverImageURL} />
        </button>
      )}
    </div>
  );
};

export default Document;
