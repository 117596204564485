import fileDownload from "js-file-download";
import { parse } from "json2csv";
import _ from "lodash";
import moment from "moment";
import { CompanyWithHoldings } from "server/services/company/company.types";
import { formatThousands } from "./helpers";

export const exportUserPortfolio = (clientId?: string | null, holdings?: CompanyWithHoldings[] | null) => {
  if (!holdings) return;
  const csvFilename = `generated-portfolio-${clientId ? clientId + "-" : ""}${Date.now()}.csv`;

  const subscriptions = _.orderBy(
    holdings.flatMap((company) =>
      company.holdings.map((holding) => ({
        projectName: company.projectName,
        companyName: company.companyName,
        sector: company.sector,
        settleDate: moment(holding.settleDate).format("DD/MM/YYYY"),
        currentPricePerShare: formatThousands(company.holdings[0].currentPricePerShare, 2),
        purchasePricePerShare: holding.pricePerShare,
        totalShares: holding.noOfShares,
        totalCost: holding.cost,
        totalValue: holding.noOfShares ? holding.noOfShares * company.holdings[0].currentPricePerShare : holding.value,
        growth: formatThousands(
          holding.noOfShares ? holding.noOfShares * company.holdings[0].currentPricePerShare - holding.cost : holding.value - holding.cost,
          2,
        ),
        growthPercentage:
          Math.round(
            ((holding.noOfShares ? holding.noOfShares * company.holdings[0].currentPricePerShare - holding.cost : holding.value - holding.cost) /
              holding.cost) *
              100 *
              100,
          ) / 100,
        uir: holding.uir,
        dealStage: holding.dealStage,
      })),
    ),
    ["projectName", (subscription) => moment(subscription.settleDate, "DD/MM/YYYY")],
    ["asc", "asc"],
  );

  const csvString = parse(subscriptions, {
    defaultValue: "N/A",
    includeEmptyRows: true,
    fields: [
      { label: "Brand Name", value: "projectName" },
      { label: "Company", value: "companyName" },
      { label: "Date", value: "settleDate" },
      { label: "Sector", value: "sector" },
      { label: "Relief", value: "dealStage" },
      { label: "Shares", value: "totalShares" },
      { label: "UIR", value: "uir" },
      { label: "Purchase Price", value: "purchasePricePerShare" },
      { label: "Current Price", value: "currentPricePerShare" },
      { label: "Cost (£)", value: "totalCost" },
      { label: "Value (£)", value: "totalValue" },
      { label: "Change (£)", value: "growth" },
      { label: "Change (%)", value: "growthPercentage" },
    ],
  });
  fileDownload(new Blob([csvString]), csvFilename, "text/csv;charset=utf-16;");
};
