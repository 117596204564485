import deepdash from 'deepdash';
import { useFormikContext } from 'formik';
import lodash from 'lodash';
import { useEffect } from 'react';

const _ = deepdash(lodash);

const FormikErrorsTouched = () => {
  const { errors, isSubmitting, isValidating, setFieldTouched } = useFormikContext();

  useEffect(() => {
    if (isSubmitting && !isValidating) {
      _.keysDeep(errors || {}).forEach(key => {
        setFieldTouched(key, true, false);
      });
    }
  }, [errors, isSubmitting, isValidating, setFieldTouched]);

  return null;
};

export default FormikErrorsTouched;
