import { useFormikContext } from "formik";
import React, { FC, useCallback } from "react";
import { useHistory } from "react-router-dom";
import ButtonGroup from "../../components/UIKit/ButtonGroup.component";

interface Props {
  handleFormSubmit: (values: any) => void;
  isAdmin?: boolean;
  backLink?: string;
}

/**
 * Component for use on individual stages of the Application
 * Form.
 *
 * Allows the user to skip client-side validation on a form
 * when they press the 'Next'/'Finish'button.
 *
 * @component
 * @property {() => void)} handleFormSubmit Form submission method (after validation)
 * @property {boolean} isAdmin User's admin status
 * @property {string} backLink back button link
 */

const StageButtonGroup: FC<Props> = ({ handleFormSubmit, isAdmin, backLink }) => {
  const history = useHistory();
  const { values } = useFormikContext();

  const goBack = useCallback(() => {
    if (backLink) {
      history.push(backLink);
    } else {
      history.goBack();
    }
  }, [history, backLink]);

  return (
    <ButtonGroup
      goBack={goBack}
      onClick={(e: any) => {
        if (!isAdmin) return;
        e.stopPropagation();
        e.preventDefault();
        handleFormSubmit(values);
      }}
    />
  );
};

export default StageButtonGroup;
