import React, { Dispatch, FC, Reducer, useReducer } from "react";
import { LeanApplication } from "server/services/application/application.types";

interface ApplicationAction {
  type: typeof APPLICATION_TYPES[keyof typeof APPLICATION_TYPES];
  payload: LeanApplication;
}

interface IApplicationContext {
  application: LeanApplication | null;
  dispatch: Dispatch<ApplicationAction>;
}

const ApplicationContext = React.createContext<IApplicationContext>({ application: null, dispatch: () => null });
ApplicationContext.displayName = "ApplicationContext";

export const APPLICATION_TYPES = {
  SET_APPLICATION: "SET_APPLICATION",
  SET_CLIENT: "SET_CLIENT",
} as const;

const ApplicationReducer: Reducer<LeanApplication | null, ApplicationAction> = (_state, action) => {
  switch (action.type) {
    case APPLICATION_TYPES.SET_APPLICATION:
    case APPLICATION_TYPES.SET_CLIENT:
      return action.payload;
    default: {
      throw new Error(`Unhandled action: ${action.type}`);
    }
  }
};

const ApplicationProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(ApplicationReducer, null);

  return <ApplicationContext.Provider value={{ application: state, dispatch }}>{children}</ApplicationContext.Provider>;
};

const ApplicationConsumer = ApplicationContext.Consumer;
export { ApplicationContext, ApplicationProvider, ApplicationConsumer };
