import { Button, Grid, Container, Typography, Checkbox, FormGroup, FormControlLabel, makeStyles, createStyles } from "@material-ui/core";
import { getRemainingTimeUntilMsTimestamp } from "client/utils/CountdownTimerUtils";
import React, { useContext, useCallback, useMemo, useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { PersonalDetails } from "server/services/application/application.types";
import { AssessmentStatus } from "server/services/assessment/assessment.consts";
import { completeUserAssessment } from "../../../api/application.api";
import { completeAdvisedAssessment, skipCooldownAndCompleteAdvisedAssessment } from "../../../api/advisor/client/application.clientApi";
import { ConfigContext } from "client/context/config.context";
import { ROUTES } from "client/utils/constants";

const useStyles = makeStyles(
  () =>
    createStyles({
      paraGraph: {
        fontSize: "16px !important",
        lineHeight: "2em",
      },
      fontBold: {
        fontWeight: "bold",
      },
      countDownDigits: {
        fontSize: "80px",
      },
      countDownSeparator: {
        fontSize: "80px",
      },
    }),
  { name: "CoolDownComponent" },
);

interface CoolDownProps {
  investorDetail: PersonalDetails | undefined;
  assessmentStatus: AssessmentStatus;
  coolDownTime: Date | null;
  assessmentId: string;
  clientId: string | false | undefined;
  isAdvisor: boolean;
}

const CoolDown: React.FC<CoolDownProps> = (props) => {
  const classes = useStyles(props);
  const navigate = useHistory();
  const { isAdvisor, clientId } = props;
  const { fund: currentFund } = useContext(ConfigContext);
  const { assessmentStatus, investorDetail, coolDownTime, assessmentId } = props;
  const investorName =
    investorDetail?.forenames && investorDetail?.surname ? `${investorDetail?.forenames} ${investorDetail?.surname}` : `${investorDetail?.forenames}`;
  const topReference = useRef<HTMLDivElement>(null);
  const [showAdvisorContinue, setShowAdvisorContinue] = useState<boolean>(false);
  const goMain = useCallback(
    () => navigate.push(isAdvisor ? `../../../..${ROUTES.ADVISOR.DASHBOARD}` : `..${ROUTES.DASHBOARD}`),
    [isAdvisor, navigate],
  );

  const handleSubmit = useCallback(async () => {
    if (isAdvisor && clientId != null) {
      const res = await completeAdvisedAssessment(clientId as string);
      if (res && res.status == AssessmentStatus.CONTINUE_TO_THE_APPLICATION) navigate.go(0);
    } else {
      const res = await completeUserAssessment(assessmentId);
      if (res && res.status == AssessmentStatus.CONTINUE_TO_THE_APPLICATION) navigate.go(0);
    }
  }, [assessmentId, clientId, isAdvisor, navigate]);

  const advisorSkipCooldown = useCallback(async () => {
    if (isAdvisor && clientId != null) {
      const res = await skipCooldownAndCompleteAdvisedAssessment(clientId as string);
      if (res && res.status == AssessmentStatus.CONTINUE_TO_THE_APPLICATION) navigate.go(0);
    }
  }, [clientId, isAdvisor, navigate]);

  const countdownTimestampMs = useMemo(() => coolDownTime, [coolDownTime]);

  const defaultRemainingTime = {
    seconds: "00",
    minutes: "00",
    hours: "00",
    days: "00",
  };

  const [remainingTime, setRemainingTime] = useState(defaultRemainingTime);

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateRemainingTime(countdownTimestampMs);
    }, 1000);
    topReference.current?.focus();
    return () => clearInterval(intervalId);
  }, [countdownTimestampMs]);

  function updateRemainingTime(countdown: any) {
    setRemainingTime(getRemainingTimeUntilMsTimestamp(countdown));
  }

  const timerView = useCallback(
    (isSuccessCoolDown: boolean, message: string) => {
      return (
        <>
          <Typography gutterBottom={true} variant="h2" align="center">
            {message}
          </Typography>
          <Container>
            <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
              <Grid item md={12}>
                <span className={classes.countDownDigits}>{isSuccessCoolDown ? "00" : remainingTime.hours}</span>
                <span className={classes.countDownSeparator}>:</span>
                <span className={classes.countDownDigits}>{isSuccessCoolDown ? "00" : remainingTime.minutes}</span>
                <span className={classes.countDownSeparator}>:</span>
                <span className={classes.countDownDigits}>{isSuccessCoolDown ? "00" : remainingTime.seconds}</span>
              </Grid>
            </Grid>
          </Container>
        </>
      );
    },
    [classes.countDownDigits, classes.countDownSeparator, remainingTime.hours, remainingTime.minutes, remainingTime.seconds],
  );

  const unsuccessfullyCoolDown = useCallback(() => {
    return (
      <Container>
        {timerView(false, "Application unsuccessful")}
        <Typography className={classes.paraGraph} paragraph={true}>
          Dear {investorName},
        </Typography>
        <Typography className={classes.paraGraph} paragraph={true}>
          Unfortunately, based on the results of your appropriateness test assessment, it appears that investing in early stage companies may not be
          the best fit for you at this time. It&apos;s important to remember that investing in early stage companies carries a higher level of risk
          and uncertainty than investing in more established companies. We strongly advise that you consider your personal financial situation and
          risk tolerance before making any investment decisions.
        </Typography>
        <Typography className={classes.paraGraph} paragraph={true}>
          Investing in early stage companies can be a highly speculative and risky endeavor. These companies are typically in the early stages of
          development and may not have a proven track record of success. They may also be in the process of raising capital, or have a limited history
          of revenue and earnings. As a result, the value of your investment may be highly volatile and may be subject to significant fluctuations.
          Additionally, there is a risk that the company may not achieve its goals or may fail entirely, resulting in a total loss of your investment.
        </Typography>
        <Typography className={classes.paraGraph} paragraph={true}>
          Given these risks, it is important that you carefully consider your risk tolerance and financial situation before making any investment
          decisions. We understand that the allure of high returns can be tempting, but it is crucial that you only invest what you can afford to
          lose. We invite you to retake the appropriateness test in 24 hours, or to consult with a financial advisor to determine if this type of
          investment is suitable for you.
        </Typography>
        <Typography className={classes.paraGraph} paragraph={true}>
          It&apos;s important to note that investing in early stage companies can also be rewarding, as they have the potential to generate
          significant returns if they are successful. However, it&apos;s important to approach these investments with caution and to fully understand
          the risks involved. We hope that you will take the time to reassess your risk tolerance and financial situation before making any investment
          decisions. Please do not hesitate to reach out to us if you have any questions or need further information.
        </Typography>
        {currentFund._id == null && (
          <>
            <Typography className={classes.paraGraph} paragraph={true}>
              Sapphire Capital Partners LLP
            </Typography>
            <Typography className={classes.paraGraph} paragraph={true}>
              {currentFund?.name} Manager
            </Typography>
          </>
        )}
        <Typography className={classes.paraGraph} paragraph={true} style={{ fontWeight: "bold" }}>
          When your countdown has finished, please refresh this page to continue
        </Typography>
      </Container>
    );
  }, [classes.paraGraph, currentFund?.name, currentFund?.id, investorName, timerView]);

  const advisorContinueButton = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setShowAdvisorContinue(e.target.checked);
  }, []);
  const advisorSuccessfullyCoolDown = useCallback(() => {
    return (
      <>
        <Typography className={classes.paraGraph} paragraph={true}>
          As an authorized alternative investment manager under the Financial Conduct Authority (FCA), we are committed to ensuring that all of our
          investment offerings comply with the FCA policy statement on consumer duty (PS22/10) as issued in November 2022.
        </Typography>

        <Typography className={classes.paraGraph} paragraph={true}>
          In accordance with this policy, we are writing to confirm that you have fulfilled the following requirements prior to the investment of your
          client:
          <ol type="1">
            <li>You have shown the appropriate risk warning.</li>
            <li>You haveassessed the appropriateness of the investor to this type of investment.</li>
            <li>
              You have applied a 24-hour cooling off period, during which you have asked the investor to confirm their intention to proceed with the
              investment.
            </li>
          </ol>
        </Typography>
        <Typography className={classes.paraGraph} paragraph={true}>
          Your confirmation serves as a legal and formal acknowledgement that you have acted in accordance with the FCA&apos;s policy statement on
          consumer duty (PS22/10) and that the investment in question meets the necessary standards for consumer protection.
        </Typography>
        <Typography className={classes.paraGraph} paragraph={true}>
          We thank you for your cooperation and for helping to ensure that our investments are conducted in a responsible and compliant manner.
        </Typography>
        <Typography className={classes.paraGraph} paragraph={true}>
          Sincerely,
        </Typography>
        {currentFund._id == null && (
          <Typography className={classes.paraGraph} paragraph={true}>
            Sapphire Capital Partners LLP,
          </Typography>
        )}
        <Typography className={classes.paraGraph} paragraph={true}>
          Authorised Alternative Investment Manager
        </Typography>
        <Typography className={classes.paraGraph} paragraph={true}>
          <FormGroup>
            <FormControlLabel control={<Checkbox onChange={advisorContinueButton} />} label="I Confirm" />
          </FormGroup>
        </Typography>

        <Grid container spacing={2}>
          <Grid item md={3}>
            <Button
              disabled={!showAdvisorContinue}
              variant="contained"
              style={{ display: "block", paddingLeft: 6, paddingRight: 6 }}
              onClick={advisorSkipCooldown}
            >
              <Typography variant="h4" style={{ color: "white" }}>
                Continue
              </Typography>
            </Button>
          </Grid>
        </Grid>
        <br />
      </>
    );
  }, [currentFund?.name, showAdvisorContinue, currentFund._id, advisorContinueButton, advisorSkipCooldown, classes.paraGraph]);

  const investorSuccessfullyCoolDown = useCallback(() => {
    return (
      <>
        <Typography className={classes.paraGraph} paragraph={true}>
          We are writing to inform you that before proceeding with your investment in the {currentFund?.name}, you are required to wait a 24-hour
          cooling-off period. This period is intended to give you an opportunity to reflect on the risks associated with investing in early-stage and
          high-risk investments, and to ensure that you fully understand the potential consequences of your investment decision.
        </Typography>

        <Typography className={classes.paraGraph} paragraph={true}>
          Early-stage and high-risk investments carry a higher level of risk compared to more established and lower-risk investments. This means that
          there is a higher possibility of experiencing significant losses on the investment. It is important for investors to carefully consider the
          risks and potential returns of any investment before making a decision, and to ensure that they are suitable for the level of risk involved.
        </Typography>
        <Typography className={classes.paraGraph} paragraph={true}>
          We encourage you to use this cooling-off period to carefully review the information provided to you about the {currentFund?.name}, including
          the risks and potential returns. We also recommend that you seek independent financial advice if you have any concerns about your ability to
          handle the risks associated with this type of investment.
        </Typography>
        <Typography className={classes.paraGraph} paragraph={true}>
          Thank you for your attention to this matter.
        </Typography>
        <Typography className={classes.paraGraph} paragraph={true}>
          Sincerely,
        </Typography>
        {currentFund._id == null && (
          <Typography className={classes.paraGraph} paragraph={true}>
            Sapphire Capital Partners LLP Investment manager of the {currentFund?.name}
          </Typography>
        )}
      </>
    );
  }, [currentFund?.name, currentFund._id, classes.paraGraph]);

  const successfullyCoolDown = useCallback(() => {
    return (
      <Container>
        {timerView(false, "Success!")}
        <Typography className={classes.paraGraph} paragraph={true}>
          Dear {investorName},
        </Typography>
        {isAdvisor ? advisorSuccessfullyCoolDown() : investorSuccessfullyCoolDown()}
        <Typography className={classes.paraGraph} paragraph={true} style={{ fontWeight: "bold" }}>
          When your countdown has finished, please refresh this page to continue
        </Typography>
      </Container>
    );
  }, [
    classes.paraGraph,
    currentFund?.name,
    investorName,
    timerView,
    isAdvisor,
    showAdvisorContinue,
    advisorSuccessfullyCoolDown,
    investorSuccessfullyCoolDown,
  ]);
  const completedCoolDown = useCallback(() => {
    return (
      <>
        <Container>
          {timerView(true, "Do you wish to proceed?")}
          <Typography className={classes.paraGraph} paragraph={true}>
            Dear {investorName},
          </Typography>
          <Typography className={classes.paraGraph} paragraph={true}>
            We are writing to inform you that the 24-hour cooling-off period has now passed, and you are now able to proceed with your investment in
            the {currentFund?.name} if you choose to do so.
          </Typography>
          <Typography className={classes.paraGraph} paragraph={true}>
            Before making a decision, we encourage you to consider the following:
            <ul>
              <li>Do you wish to proceed with the investment in the {currentFund?.name}?</li>
              <li>
                Do you understand the risks associated with investing in early-stage and high- risk investments, and are you comfortable with the
                potential consequences of your investment decision?
              </li>
            </ul>
          </Typography>
          <Typography className={classes.paraGraph} paragraph={true}>
            Please let us know if you wish to proceed with the investment or if you do not wish to proceed. If you choose to proceed, we will need
            your confirmation that you understand the risks involved and are comfortable with the potential consequences of your investment decision.
          </Typography>

          <Typography className={classes.paraGraph} paragraph={true}>
            Thank you for your attention to this matter.
          </Typography>
          <Typography className={classes.paraGraph} paragraph={true}>
            Sincerely,
          </Typography>
          {currentFund._id == null && (
            <Typography className={classes.paraGraph} paragraph={true}>
              Sapphire Capital Partners LLP Investment manager of the {currentFund?.name}
            </Typography>
          )}
        </Container>

        <Container>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Button
                variant="contained"
                style={{ display: "block", paddingLeft: 6, paddingRight: 6 }}
                onClick={handleSubmit}
                data-testid="continue-to-the-application"
              >
                <Typography variant="h4" style={{ color: "white" }}>
                  YES
                </Typography>
                <Typography variant="body2" style={{ textTransform: "none", color: "white" }}>
                  I wish to proceed with my initial investment
                </Typography>
              </Button>
            </Grid>

            <Grid item md={6}>
              <Button variant="contained" style={{ display: "block", paddingLeft: 6, paddingRight: 6 }} onClick={goMain}>
                <Typography variant="h4" style={{ color: "white" }}>
                  NO
                </Typography>
                <Typography variant="body2" style={{ textTransform: "none", color: "white" }}>
                  This investment is not suitable for me
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Container>
      </>
    );
  }, [classes.paraGraph, currentFund?.name, goMain, handleSubmit, investorName, timerView, currentFund._id]);
  const showCoolDown = useMemo(() => {
    if (assessmentStatus == AssessmentStatus.COOLDOWN_SUCCESS) return successfullyCoolDown();
    if (assessmentStatus == AssessmentStatus.COOLDOWN_FAILED) return unsuccessfullyCoolDown();
    if (assessmentStatus == AssessmentStatus.COMPLETED_SUCCESSFULLY) return completedCoolDown();
  }, [assessmentStatus, completedCoolDown, successfullyCoolDown, unsuccessfullyCoolDown]);

  return (
    <>
      <div ref={topReference} tabIndex={-1} />
      {showCoolDown}
    </>
  );
};

export default CoolDown;
