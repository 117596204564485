import { Button, Dialog, DialogContent, DialogProps, DialogTitle, FormControlLabel, FormGroup, makeStyles, Switch } from "@material-ui/core";
import { OPEN_DEALS_OPTIONS } from "client/utils/constants";
import React from "react";
import { OptionKey, OptionsState } from ".";

interface Props extends DialogProps {
  onClose: () => void;
  options: OptionsState;
  handleToggleOption: (option: OptionKey) => void;
  handleSubmit: () => Promise<void>;
}

const useStyles = makeStyles(
  () => ({
    filterBox: {
      marginTop: 10,
      marginBottom: 10,
      marginLeft: 10,
    },
    toggleSwitch: {
      "& .Mui-checked .MuiIconButton-label": {
        background: "unset",
      },
    },
  }),
  { name: "OpenDealsOptionsForm" },
);

const OpenDealsTableOptions: React.FC<Props> = ({ onClose, handleToggleOption, handleSubmit, options, ...props }) => {
  const classes = useStyles(props);

  return (
    <Dialog onClose={onClose} {...props}>
      <DialogTitle>Open Deals Options</DialogTitle>
      <DialogContent id="dialog">
        {Object.entries(OPEN_DEALS_OPTIONS).map(([option, label]) => (
          <FormGroup className={classes.filterBox} key={option}>
            <FormControlLabel
              label={label}
              labelPlacement="end"
              control={
                <Switch
                  color="primary"
                  className={classes.toggleSwitch}
                  checked={options[option]}
                  onChange={() => handleToggleOption(option as OptionKey)}
                />
              }
            />
          </FormGroup>
        ))}
        <FormGroup row>
          <Button
            variant="outlined"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmit}>
            Submit
          </Button>
        </FormGroup>
      </DialogContent>
    </Dialog>
  );
};

export default OpenDealsTableOptions;
