import { Button, FormGroup, makeStyles, TypographyProps } from "@material-ui/core";
import { FieldProps } from "formik";
import React from "react";
import { fromCamelCase } from "../../utils/helpers";
import ErrorMessage from "../ErrorMessage.component";
import QuestionLabel from "../QuestionLabel.component";
import TrackInput from "../TrackInput.component";

interface Option {
  value: any;
  label: string;
}

interface Props extends FieldProps {
  options: Option[];
  label?: string;
  row?: boolean;
  trackedCategory?: string;
  errorMessageProps?: TypographyProps;
  className?: string;
}

const useStyles = makeStyles(
  (theme) => ({
    selectorContainer: {
      margin: theme.spacing(0.625, 0),
      "& .MuiButton-label": {
        ...theme.typography.caption,
      },
    },
    active: {
      pointerEvents: "none", // disables hover effect
    },
    inactive: {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.action.disabledBackground,
      "&:hover": {
        backgroundColor: theme.palette.action.disabled,
      },
    },
  }),
  { name: "ButtonSelectorComponent" },
);

const ButtonSelector: React.FC<Props> = ({ field, form, options, label, row, trackedCategory, errorMessageProps = {}, className, ...props }) => {
  const classes = useStyles(props);

  return (
    <>
      <br />
      <TrackInput category={trackedCategory} label={fromCamelCase(field.name)} value={field.value}>
        {label && <QuestionLabel>{label}</QuestionLabel>}
        <FormGroup row={row} className={classes.selectorContainer}>
          {options.map((option) => (
            <Button
              key={option.value}
              {...props}
              className={`${field.value === option.value ? classes.active : classes.inactive} ${className || " "}`}
              onClick={() => form.setFieldValue(field.name, option.value)}
              data-testid={option.value}
            >
              {option.label}
            </Button>
          ))}
        </FormGroup>
        <ErrorMessage name={field.name} {...errorMessageProps} />
      </TrackInput>
    </>
  );
};

export default ButtonSelector;
