import React from "react";
import { Button, FormGroup, IconButton } from "@material-ui/core";
import { ErrorMessage, Field, FieldArray, FieldProps } from "formik";
import TextQuestion from "./TextQuestion.component";
import CloseIcon from "../Icons/Close.icon";
import ErrorText from "../UIKit/ErrorText.component";
import { CompanyLink } from "server/services/company/company.types";

interface Props extends FieldProps {
  label: string;
}

/**
 * Formik FieldArray component which lets you create, read,
 * update and delete links for a company.
 */

const CrudLinkArray: React.FC<Props> = ({ label, field }) => {
  return (
    <FieldArray name={field.name}>
      {({ push, remove }) => (
        <>
          {field.value &&
            field.value.map((_link: CompanyLink, index: number) => (
              <FormGroup key={index}>
                <FormGroup style={{ alignItems: "flex-end", margin: 0 }}>
                  <hr />
                  <IconButton size="small" edge="end" style={{ flex: 0 }} onClick={() => remove(index)}>
                    <CloseIcon />
                  </IconButton>
                </FormGroup>
                <FormGroup row>
                  <Field component={TextQuestion} name={`${field.name}[${index}].name`} placeholder={label} label="Link Name" />
                  <Field component={TextQuestion} name={`${field.name}[${index}].category`} placeholder="Marketing" label="Link Category" />
                </FormGroup>
                <FormGroup>
                  <Field component={TextQuestion} name={`${field.name}[${index}].url`} placeholder="Link URL" label="Link URL" />
                </FormGroup>
              </FormGroup>
            ))}
          <FormGroup row>
            <Button size="small" onClick={() => push({ url: "", name: "", category: "" })}>
              Add {label} link
            </Button>
          </FormGroup>
          <ErrorMessage name={field.name} component={ErrorText} />
        </>
      )}
    </FieldArray>
  );
};

export default CrudLinkArray;
