import { StandardTextFieldProps, TextField, TypographyProps } from "@material-ui/core";
import clsx from "clsx";
import { FieldProps } from "formik";
import _ from "lodash";
import React, { ReactNode } from "react";
import { fromCamelCase } from "../../utils/helpers";
import ErrorMessage from "../ErrorMessage.component";
import QuestionLabel from "../QuestionLabel.component";
import TrackInput from "../TrackInput.component";

interface Props extends FieldProps, StandardTextFieldProps {
  inputLabel?: ReactNode;
  trackedCategory?: string;
  accept?: string | string[];
  errorMessageProps?: TypographyProps;
}

const TextQuestion: React.FC<Props> = ({
  field,
  form: { touched, errors },
  label,
  inputLabel,
  trackedCategory,
  className,
  accept,
  errorMessageProps = {},
  ...props
}) => (
  <TrackInput category={trackedCategory} label={fromCamelCase(field.name)} value={field.value}>
    {label && <QuestionLabel required={Boolean(props.required)}>{label}</QuestionLabel>}
    <TextField
      fullWidth
      variant="outlined"
      error={typeof (_.get(touched, field.name) && _.get(errors, field.name)) === "string"}
      label={inputLabel}
      className={clsx(className, "fs-exclude")}
      {...field}
      {...props}
      inputProps={{
        ...props.inputProps,
        accept: props.type === "file" ? [...(Array.isArray(accept) ? accept : [accept])].join(",") : undefined,
      }}
    />
    <ErrorMessage name={field.name} {...errorMessageProps} />
  </TrackInput>
);

export default TextQuestion;
