import { Link, Button } from "@material-ui/core";
import { ColumnDefinition, OnChangeObject, TableCellEditHandler } from "@wearenova/mui-data-table";
import { getCertificateSubmissions, saveCertificateSubmission, getUserCertificateStatuses } from "client/api/admin/certificateSubmissions.adminApi";
import { AdminContext, ADMIN_TYPES } from "client/context/admin.context";
import { ConfigContext } from "client/context/config.context";
import { useAllFunds } from "client/hooks/adminContext.hooks";
import _ from "lodash";
import moment from "moment";
import { FC, Fragment, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { AdminCertSubmission } from "server/services/certificateSubmissions/certSubmission.types";
import AdminTable from "../AdminTable.view";
import CertificateSubmissionForm from "./CertificateSubmissionForm";
import { AuthContext } from "../../../context/auth.context";
import { USER_ROLE } from "client/utils/constants";

interface Props {
  readOnly: boolean;
}

const ALWAYS_ENABLED_COLS = ["company.companyName", "settleDate", "dealStage", "totals"] as string[];
const MANAGER_USER_ENABLED_COLS = ["totals.fundIds", "company.companyName", "nominee", "settleDate", "dealStage", "totals"] as string[];

const STAGE_COL_PROPS = {
  pinnable: true,
  sorter: true,
  filterColumn: true,
  editable: true,
} as const;

/**
 * Certificate Submissions Tab functional component.
 *
 * @returns FundsTab component
 */

const CertificateSubmissionsTab: FC<Props> = ({ readOnly }) => {
  const { certificateSubmissions, dispatch } = useContext(AdminContext);
  const { fund } = useContext(ConfigContext);
  const {
    state: { user },
  } = useContext(AuthContext);

  const allFunds = useAllFunds();
  const [count, setCount] = useState<number>(-1);
  const [enabledColumns, setEnabledColumns] = useState<string[] | null>(null);
  const [selectedSubmission, setSelectedSubmission] = useState<AdminCertSubmission | null>(null);

  const allFundsObject = useMemo(() => _.keyBy(allFunds, (f) => String(f._id)), [allFunds]);

  const canDelete = fund.certificateSubmissions.enableAllFunds && !readOnly;

  useEffect(() => {
    setEnabledColumns(fund.certificateSubmissions?.enabledColumns.length ? fund.certificateSubmissions?.enabledColumns : null);
  }, [fund]);

  const handleRowClick = useCallback((submission: AdminCertSubmission) => setSelectedSubmission(submission), []);

  const handleClose = useCallback(() => {
    setSelectedSubmission(null);
  }, []);

  const handleChange = useCallback(
    async (changeObject: OnChangeObject) => {
      const res = await getCertificateSubmissions(changeObject);
      if (!res) return [];
      setCount(res.count);
      dispatch({ type: ADMIN_TYPES.SET_CERTIFICATE_SUBMISSIONS, payload: res.data });
      return res.data;
    },
    [dispatch],
  );

  const handleTableEdit = useCallback<TableCellEditHandler<AdminCertSubmission>>(
    async ({ path, value }, record) => {
      const res = await saveCertificateSubmission({ [path]: value === "null" ? null : value }, record._id);
      if (!res) return;
      dispatch({ type: ADMIN_TYPES.UPDATE_CERTIFICATE_SUBMISSION, payload: res });
    },
    [dispatch],
  );

  const fullTableStructure = useMemo<ColumnDefinition<AdminCertSubmission>[]>(
    () => [
      {
        key: "totals.fundIds",
        title: "Fund(s)",
        render: (record) => {
          const submissionFunds: string[] = _.uniq(record.totals.fundIds).reverse() as string[];
          return submissionFunds.map((currFundId, index) => {
            const fundName = allFundsObject[currFundId]?.name; // should always exist
            return (
              <Fragment key={`${fundName}${index}`}>
                {Boolean(index) && " & "}
                {`${fundName}`}
                <br />
              </Fragment>
            );
          });
        },
        pinnable: true,
      },
      {
        key: "company.companyName",
        title: "Company Name",
        dataIndex: "company.companyName",
        pinnable: true,
        sorter: true,
        filterColumn: true,
      },
      {
        key: "nominee",
        title: "Nominee(s)",
        render: (record) => {
          const nominees = _.uniq(record.totals.nominees).reverse();
          return nominees.map((nominee: string, index: number) => (
            <div key={nominee} title={nominee}>
              {Boolean(index) && " & "}
              {nominee ?? ""}
              <br />
            </div>
          ));
        },
        pinnable: true,
      },
      {
        key: "totals",
        title: "Underlying Data",
        colGroup: [
          {
            key: "totals.shares",
            title: "Shares",
            dataIndex: "totals.shares",
            dataType: "number",
            numerical: { path: true, currency: false, minDecimalPlaces: 0, maxDecimalPlaces: 0 },
            sorter: true,
            filterColumn: { path: true, type: "number" },
          },
          {
            key: "totals.numberOfInvestors",
            title: "No. Investors",
            dataIndex: "totals.numberOfInvestors",
            sorter: true,
            filterColumn: { path: true, type: "number" },
          },
          {
            key: "totals.investmentAmount",
            title: "Total Investment",
            dataIndex: "totals.investmentAmount",
            dataType: "number",
            numerical: { path: true, currency: true },
            sorter: true,
            filterColumn: true,
          },
        ],
      },
      {
        key: "settleDate",
        title: "Date Monies Come In",
        dataIndex: "settleDate",
        render: (record) => (record.settleDate ? moment(record.settleDate).format("DD/MM/YYYY") : null),
        pinnable: true,
        sorter: true,
        filterColumn: true,
      },
      {
        key: "dealStage",
        title: "SEIS/EIS",
        dataIndex: "dealStage",
        pinnable: true,
        sorter: true,
        filterColumn: true,
      },
      {
        key: "gDriveLink",
        title: "GDrive Folder",
        render: (record) =>
          record.gDriveLink ? (
            <Link key={record.gDriveLink} href={record.gDriveLink} onClick={(e) => e.stopPropagation()} target="_blank" rel="noopener noreferrer">
              Folder
            </Link>
          ) : null,
        pinnable: true,
      },
      {
        key: "stages.listOfInvestors",
        title: "List of Investors \nin GDrive",
        dataIndex: "stages.listOfInvestors",
        ...STAGE_COL_PROPS,
      },
      {
        key: "stages.agentAuthorisation",
        title: "Agent Authorisation \n(within 3 months) in GDrive",
        dataIndex: "stages.agentAuthorisation",
        ...STAGE_COL_PROPS,
      },
      {
        key: "stages.advancedAssurance",
        title: "Advanced Assurance \nEvidence in GDrive",
        dataIndex: "stages.advancedAssurance",
        ...STAGE_COL_PROPS,
      },
      {
        key: "stages.bankStatement",
        title: "Bank Statement \nfrom Date of Investment \nto date in GDrive",
        dataIndex: "stages.bankStatement",
        ...STAGE_COL_PROPS,
      },
      {
        key: "stages.serviceInvoice",
        title: "Service Invoice \nin GDrive",
        dataIndex: "stages.serviceInvoice",
        ...STAGE_COL_PROPS,
      },
      {
        key: "stages.shareholderRegister",
        title: "Shareholder Register \nin GDrive",
        dataIndex: "stages.shareholderRegister",
        ...STAGE_COL_PROPS,
      },
      {
        key: "stages.allotmentRegister",
        title: "Allotment Register \nin GDrive",
        dataIndex: "stages.allotmentRegister",
        ...STAGE_COL_PROPS,
      },
      {
        key: "stages.boardMinutesSigned",
        title: "Board Minutes Signed?",
        dataIndex: "stages.boardMinutesSigned",
        ...STAGE_COL_PROPS,
      },
      {
        key: "stages.percentSpend",
        title: "70% Spend?",
        dataIndex: "stages.percentSpend",
        ...STAGE_COL_PROPS,
      },
      {
        key: "stages.sharesAlloted",
        title: "Shares allotted?",
        dataIndex: "stages.sharesAlloted",
        ...STAGE_COL_PROPS,
      },
      {
        key: "stages.filedCompaniesHouse",
        title: "Filed with \nCompanies House?",
        dataIndex: "stages.filedCompaniesHouse",
        ...STAGE_COL_PROPS,
      },
      {
        key: "stages.issuedAndApproved",
        title: "Share Certificates \nIssued and Approved?",
        dataIndex: "stages.issuedAndApproved",
        ...STAGE_COL_PROPS,
      },
      {
        key: "stages.submittedToNominee",
        title: "Share Certificates \nSubmitted to Nominee?",
        dataIndex: "stages.submittedToNominee",
        ...STAGE_COL_PROPS,
      },
      {
        key: "applicationSent",
        title: "Date Application \nForm Sent",
        dataIndex: "applicationSent",
        dataType: "date",
        render: (record) => (record.applicationSent ? moment(record.applicationSent).format("DD/MM/YYYY") : null),
        pinnable: true,
        sorter: true,
        filterColumn: true,
        editable: true,
      },
      {
        key: "seis2.dueDate",
        title: "SEIS2 \nDue Date",
        dataIndex: "seis2.dueDate",
        dataType: "date",
        render: (record) => (record.seis2?.dueDate ? moment(record.seis2.dueDate).format("DD/MM/YYYY") : null),
        pinnable: true,
        sorter: true,
        filterColumn: true,
        editable: true,
      },
      {
        key: "seis2.received",
        title: "SEIS2 \nReceived Date",
        dataIndex: "seis2.received",
        dataType: "date",
        render: (record) => (record.seis2?.received ? moment(record.seis2.received).format("DD/MM/YYYY") : null),
        pinnable: true,
        sorter: true,
        filterColumn: true,
        editable: true,
      },
      {
        key: "comments",
        title: "Comments",
        dataIndex: "comments",
        pinnable: true,
        filterColumn: true,
        editable: true,
      },
    ],
    [allFundsObject],
  );

  /**
   * Filters columns to display, can be different per fund.
   * Certain columns will always be enabled as they contain important
   * information.
   *
   * @see {@link enabledColumns}
   * @see {@link ALWAYS_ENABLED_COLS}
   */
  const tableStructure = useMemo(() => {
    if (!enabledColumns && user?.role !== USER_ROLE.MANAGER) return fullTableStructure;
    return fullTableStructure.filter((col) => {
      if (user?.role === USER_ROLE.MANAGER && MANAGER_USER_ENABLED_COLS.includes(col.key)) return true;
      if (ALWAYS_ENABLED_COLS.includes(col.key)) return true;
      return enabledColumns?.includes(col.key);
    });
  }, [enabledColumns, fullTableStructure]);

  return (
    <>
      <AdminTable
        tableStructure={tableStructure}
        tableData={certificateSubmissions}
        count={count}
        onChange={handleChange}
        rowClick={canDelete ? handleRowClick : undefined}
        defaultSort={{ key: "settleDate", direction: "desc" }}
        onEdit={handleTableEdit}
        resizeable={true}
      />
      <div>
        <Button onClick={() => getUserCertificateStatuses()} disabled={user?.role === USER_ROLE.MANAGER}>
          Download User Certificate Statuses
        </Button>
      </div>
      {canDelete && (
        <CertificateSubmissionForm
          open={Boolean(selectedSubmission) && user?.role === USER_ROLE.ADMIN}
          onClose={handleClose}
          submission={selectedSubmission}
        />
      )}
    </>
  );
};

export default CertificateSubmissionsTab;
