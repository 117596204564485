import { LeanUser } from "server/services/user/user.types";
import notifications from "../../utils/notifications";
import { ipAxios } from "../ipAxios";

const editFee = async (values: GenericObject) => {
  try {
    const { status } = await ipAxios.post(`/advisor/clients/editFee`, values);
    if (status === 200) {
      return {
        success: true,
      };
    }
    return {
      success: false,
    };
  } catch (error: any) {
    notifications.genericError(error);
    return { success: false };
  }
};

const addClient = async (values: GenericObject) => {
  try {
    const res = await ipAxios.post<LeanUser>(`/advisor/clients`, values);
    return res.data;
  } catch (error: any) {
    if (error.response.status === 409) {
      return false;
    }
    notifications.genericError(error);
    return null;
  }
};

const getAllClients = async () => {
  try {
    const { status, data } = await ipAxios.get(`/advisor/clients/`);
    if (status === 200) {
      return { success: true, data };
    }
    return { success: false };
  } catch (error: any) {
    notifications.genericError(error);
    return { success: false };
  }
};

const getClient = async (clientId: string) => {
  try {
    const res = await ipAxios.get(`/advisor/clients/${clientId}`);
    return res.data;
  } catch (error: any) {
    notifications.genericError(error);
    return null;
  }
};

export default {
  editFee,
  addClient,
  getAllClients,
  getClient,
};
