import { Button, Divider } from "@material-ui/core";
import ImageIcon from "@material-ui/icons/ImageSharp";
import { ColumnDefinition, OnChangeObject, TableCellEditHandler } from "@wearenova/mui-data-table";
import CompanyLinkList from "client/components/Lists/CompanyLinkList.component";
import _ from "lodash";
import React, { FC, Fragment, useCallback, useContext, useMemo, useState } from "react";
import { AdminCompany, AdminShareClass } from "server/services/company/company.types";
import { LiteralUnion } from "type-fest";
import { downloadAdvancedAssurance, getCompanies, getCompanyById, saveCompany } from "../../../api/admin/companies.adminApi";
import { AdminContext, ADMIN_TYPES } from "../../../context/admin.context";
import { formatThousands } from "../../../utils/helpers";
import AdminTable from "../AdminTable.view";
import { useStyles } from "../useStyles.hook";
import CompanyForm from "./CompanyForm.view";

interface Props {
  readOnly: boolean;
}

const getShareClassValues = (
  path: LiteralUnion<keyof AdminShareClass, string>,
  company: AdminCompany,
  formatting?: { monetary: boolean; decimalPlaces: number; minDecimalPlaces?: number },
) =>
  company.shareClasses.map((sc, index) => {
    const val = _.get(sc, path);
    return (
      <Fragment key={sc._id}>
        {Boolean(index) && <Divider />}
        <br />
        {typeof val === "undefined"
          ? "Unavailable"
          : !formatting
          ? val
          : formatThousands(val, formatting.decimalPlaces, formatting.monetary, formatting.minDecimalPlaces)}
        <br />
        <br />
      </Fragment>
    );
  });

const CompanyTab: FC<Props> = ({ readOnly, ...props }) => {
  const [addCompany, setAddCompany] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<null | AdminCompany>(null);
  const [count, setCount] = useState(-1);
  const classes = useStyles(props);
  const { companies, dispatch } = useContext(AdminContext);

  const setCompanies = useCallback(
    async (onChangeObject: OnChangeObject, isExport: boolean) => {
      const res = await getCompanies(onChangeObject);
      if (!res) return [];
      if (isExport) return res.data;
      dispatch({ type: ADMIN_TYPES.SET_COMPANIES, payload: res.data });
      setCount(res.count);
    },
    [dispatch],
  );

  const setCompany = useCallback(async (company) => {
    const comp = await getCompanyById(company.companyId);
    setSelectedCompany(comp);
  }, []);

  const companyTable = useMemo<ColumnDefinition<AdminCompany>[]>(
    () => [
      {
        key: "companyLogo",
        groupBy: "companyId",
        title: "Logo",
        render: (company) =>
          company.companyLogo ? (
            <img style={{ maxWidth: "90%", width: "auto" }} src={company.companyLogo} alt={`${company.companyName} logo`} />
          ) : (
            <ImageIcon fontSize="large" />
          ),
        pinnable: true,
      },
      {
        key: "companyName",
        groupBy: "companyId",
        title: "Company Name",
        dataIndex: "companyName",
        sorter: true,
        filterColumn: true,
        pinnable: true,
        editable: true,
      },
      {
        key: "companyNumber",
        groupBy: "companyNumber",
        title: "Company Number",
        dataIndex: "companyNumber",
        sorter: true,
        filterColumn: true,
        pinnable: true,
      },
      {
        key: "projectName",
        groupBy: "companyId",
        title: "Project Name",
        dataIndex: "projectName",
        sorter: true,
        filterColumn: true,
        pinnable: true,
        editable: true,
      },
      {
        key: "isDissolved",
        title: "Dissolved?",
        dataIndex: "isDissolved",
        sorter: true,
        filterColumn: true,
        pinnable: true,
        editable: true,
      },
      {
        key: "advancedAssurance.seis",
        title: "SEIS Advanced Assurance",
        groupBy: "companyId",
        colGroup: [
          {
            key: "advancedAssurance.seis.date",
            title: "Date",
            groupBy: "companyId",
            dataIndex: "advancedAssurance.seis.dateFormatted",
            sorter: "advancedAssurance.seis.date",
            filterColumn: { path: "advancedAssurance.seis.date", type: "date" },
          },
          {
            key: "advancedAssurance.seis.download",
            title: "Download",
            groupBy: "companyId",
            render: (record, isCSVExport) => {
              if (isCSVExport || !record.advancedAssurance.seis.key) return;
              return (
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    downloadAdvancedAssurance(record.companyId, "seis");
                  }}
                  variant="text"
                >
                  Download
                </Button>
              );
            },
          },
        ],
      },
      {
        key: "advancedAssurance.eis",
        title: "EIS Advanced Assurance",
        groupBy: "companyId",
        colGroup: [
          {
            key: "advancedAssurance.eis.date",
            title: "Date",
            groupBy: "companyId",
            dataIndex: "advancedAssurance.eis.dateFormatted",
            sorter: "advancedAssurance.eis.date",
            filterColumn: { path: "advancedAssurance.eis.date", type: "date" },
          },
          {
            key: "advancedAssurance.eis.download",
            title: "Download",
            groupBy: "companyId",
            render: (record, isCSVExport) => {
              if (isCSVExport || !record.advancedAssurance.eis.key) return;
              return (
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    downloadAdvancedAssurance(record.companyId, "eis");
                  }}
                  variant="text"
                >
                  Download
                </Button>
              );
            },
          },
        ],
      },
      {
        key: "links",
        groupBy: "companyId",
        title: "Links",
        render: (company) => <CompanyLinkList company={company} />,
      },
      {
        key: "headline",
        groupBy: "companyId",
        title: "Headline",
        render: (company) => {
          if (company.headline && company.headline.length > 100) {
            return `${company.headline.substring(0, 100)}...`;
          }
          return company.headline;
        },
        editable: "headline",
      },
      {
        key: "detailedInfo",
        groupBy: "companyId",
        title: "Info",
        render: (company) => {
          if (company.detailedInfo && company.detailedInfo.length > 100) {
            return `${company.detailedInfo.substring(0, 100)}...`;
          }
          return company.detailedInfo;
        },
      },
      {
        key: "sector",
        groupBy: "companyId",
        title: "Sector",
        dataIndex: "sector",
        filterColumn: true,
        editable: true,
      },
      {
        key: "companyValuation",
        groupBy: "companyId",
        title: "Valuation",
        render: (company) => {
          return company.shareClasses && company.shareClasses[0]?.pricePerShare != null
            ? formatThousands(company.companyValuation, 0, true, 2, 10)
            : "Not Available";
        },
        pinnable: true,
        sorter: "companyValuation",
        filterColumn: { path: "companyValuation", type: "number" },
      },
      {
        key: "shareClasses",
        groupBy: "companyId",
        title: "Share Classes",
        pinnable: true,
        colGroup: [
          {
            key: "shareClassName",
            title: "Name",
            groupBy: "companyId",
            render: (company, isCSVExport) =>
              isCSVExport ? company.shareClasses.map(({ name }) => `${name}`).join("\n") : getShareClassValues("name", company),
          },
          {
            key: "shareClassNoShares",
            title: "No. Shares",
            groupBy: "companyId",
            render: (company, isCSVExport) =>
              isCSVExport
                ? company.shareClasses
                    .map((shareClass) => {
                      const value = _.get(shareClass, "deployed.total.shares");
                      return `${value}` || "Data unavailable";
                    })
                    .join("\n")
                : getShareClassValues("deployed.total.shares", company, { monetary: false, decimalPlaces: 0 }),
          },
          {
            key: "shareClassPrice",
            title: "Price",
            groupBy: "companyId",
            render: (company, isCSVExport) =>
              isCSVExport
                ? company.shareClasses
                    .map(({ pricePerShare }) => {
                      return pricePerShare ? `${pricePerShare}` : "Data unavailable";
                    })
                    .join("\n")
                : getShareClassValues("pricePerShare", company, { monetary: true, decimalPlaces: 10, minDecimalPlaces: 2 }),
          },
        ],
      },
      {
        key: "resourceBalance",
        title: "Resource Balance",
        numerical: { path: "resourceBalance", currency: true },
        pinnable: true,
        editable: "resourceBalance",
      },
      {
        key: "seisAllowanceLimit",
        title: "SEIS Allowance Limit",
        numerical: { path: "seisAllowanceLimit", currency: true },
        pinnable: true,
        sorter: "seisAllowanceLimit",
      },
    ],
    [],
  );

  const handleTableEdit = useCallback<TableCellEditHandler<AdminCompany>>(
    async ({ path, value }, record) => {
      const res = await saveCompany(_.set({}, path, value === "null" ? null : value), record._id);
      if (res) dispatch({ type: ADMIN_TYPES.UPDATE_COMPANY, payload: res });
    },
    [dispatch],
  );

  return (
    <>
      <AdminTable
        tableData={companies}
        onChange={setCompanies}
        count={count}
        tableStructure={companyTable}
        rowClick={setCompany}
        tableProps={{ size: "small" }}
        exportToCSVOption
        csvFilename="Companies"
        defaultSort={{ key: "companyName", direction: "asc" }}
        onEdit={handleTableEdit}
      />
      <div className={classes.tableFooter}>
        <Button disabled={readOnly} onClick={() => setAddCompany(true)}>
          Add company
        </Button>
        <CompanyForm
          fullWidth
          open={Boolean(addCompany || selectedCompany)}
          company={selectedCompany}
          onClose={() => {
            setAddCompany(false);
            setSelectedCompany(null);
          }}
        />
      </div>
    </>
  );
};

export default CompanyTab;
