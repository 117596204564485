import { Button } from "@material-ui/core";
import React, { useCallback, useContext, useMemo, useState } from "react";
import moment from "moment";
import { downloadPublicDocument, getAllDocuments } from "../../../api/admin/documents.adminApi";
import { downloadDocument } from "../../../api/admin/users.adminApi";
import { AdminContext, ADMIN_TYPES } from "../../../context/admin.context";
import AdminTable from "../AdminTable.view";
import { useStyles } from "../useStyles.hook";
import PublicDocumentsForm from "./PublicDocumentsForm.view";
import UserDocumentsForm from "./UserDocumentsForm.view";

const UserDocumentsTab = (props) => {
  const classes = useStyles(props);
  const [addDocument, setAddDocument] = useState(false);
  const [addPublicDocument, setAddPublicDocument] = useState(false);
  const [selectedUserDocument, setSelectedUserDocument] = useState(null);
  const [selectedPublicDocument, setSelectedPublicDocument] = useState(null);
  const { documents, dispatch } = useContext(AdminContext);

  const fetchDocuments = useCallback(
    async (onChangeObject) => {
      const allDocuments = await getAllDocuments(onChangeObject);

      if (allDocuments) {
        dispatch({ type: ADMIN_TYPES.SET_DOCUMENTS, payload: allDocuments.data });
        return allDocuments;
      }
    },
    [dispatch],
  );

  const documentsTable = useMemo(
    () => [
      {
        key: "investorName",
        title: "Investor Name",
        render: (record) => (record.fullName ? record.fullName : "N/A"),
        sorter: "fullName",
        filterColumn: "fullName",
      },
      {
        key: "date",
        title: "Upload Date",
        dataIndex: "date",
        render: (record) => (record.date ? moment(record.date).format("DD/MM/YYYY") : null),
        sorter: true,
        filterColumn: { path: true, type: "date" },
      },
      {
        key: "fileName",
        title: "File Name",
        dataIndex: "fileName",
        filterColumn: true,
      },

      {
        key: "Download",
        title: "Download",
        render: (record) => (
          <Button
            variant="text"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              if (record.title) {
                return downloadPublicDocument(record._id, record.fileName);
              } else {
                return downloadDocument(record.document._id, record.fileName, record._id);
              }
            }}
          >
            Download
          </Button>
        ),
      },
    ],
    [],
  );

  return (
    <>
      <AdminTable
        tableStructure={documentsTable}
        tableData={documents}
        count={documents.length}
        onChange={fetchDocuments}
        rowClick={(record) => (record.title ? setSelectedPublicDocument(record) : setSelectedUserDocument(record))}
      />
      <div className={classes.tableFooter}>
        <Button onClick={() => setAddDocument(true)}>Add User Document</Button>
        <Button onClick={() => setAddPublicDocument(true)}>Add Public Document</Button>
      </div>
      <UserDocumentsForm
        fullWidth
        open={Boolean(addDocument || selectedUserDocument)}
        document={selectedUserDocument}
        onClose={() => {
          setAddDocument(false);
          setSelectedUserDocument(null);
        }}
      />
      <PublicDocumentsForm
        fullWidth
        open={Boolean(addPublicDocument || selectedPublicDocument)}
        document={selectedPublicDocument}
        onClose={() => {
          setAddPublicDocument(false);
          setSelectedPublicDocument(null);
        }}
      />
    </>
  );
};
export default UserDocumentsTab;
