import {
  Card,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";

export interface Item {
  key: string;
  label: string;
  value: string;
  disabled?: boolean;
  render?: (item: Omit<Item, "render">) => React.ReactNode;
}

interface Props {
  items: Item[];
  value: string[];
  onChange: (rightList: string[], leftList: string[]) => void;
  sortKey?: string;
  searchTerm?: string | null;
}

const useStyles = makeStyles(
  (theme) => ({
    card: {
      padding: theme.spacing(2),
      maxHeight: "50%",
      width: "100%",
    },
    list: {
      overflowY: "auto",
      height: 400,
    },
  }),
  { name: "TransferListComponent" },
);

const TransferList: React.FC<Props> = ({ items: listItems = [], value = [], onChange, sortKey = "label", searchTerm, ...props }) => {
  const classes = useStyles(props);
  const [checkedItems, setCheckedItems] = useState<Item[]>([]);

  useEffect(() => {
    const hasKeys = listItems.every((item) => Boolean(item.key));
    if (!hasKeys) {
      throw new Error("Each transfer list item must have a key.");
    }
  }, [listItems]);

  const selectedItems = useMemo(() => value.map((v) => listItems.find((item) => item.value === v)), [listItems, value]);
  const sortedItems = useMemo(() => _.sortBy(listItems, sortKey), [sortKey, listItems]);
  const leftList = useMemo(() => _.differenceBy(sortedItems, selectedItems, "key"), [sortedItems, selectedItems]);
  const rightList = useMemo(() => _.intersectionBy(sortedItems, selectedItems, "key"), [sortedItems, selectedItems]);
  const leftListFiltered = useMemo(
    () => (searchTerm ? leftList.filter((item) => item?.label?.toUpperCase()?.includes(searchTerm.toUpperCase())) : leftList),
    [searchTerm, leftList],
  );
  const rightListFiltered = useMemo(
    () => (searchTerm ? rightList.filter((item) => item?.label?.toUpperCase()?.includes(searchTerm.toUpperCase())) : rightList),
    [searchTerm, rightList],
  );

  const leftChecked = useMemo(() => _.intersectionBy(leftList, checkedItems, "key"), [checkedItems, leftList]);
  const rightChecked = useMemo(() => _.intersectionBy(rightList, checkedItems, "key"), [checkedItems, rightList]);

  const handleCheckAll = useCallback(
    (items, sideChecked) => {
      if (sideChecked.length === items.length) {
        return setCheckedItems(_.xorBy(checkedItems, items, "key"));
      }
      return setCheckedItems(_.unionBy(checkedItems, items, "key"));
    },
    [checkedItems],
  );

  const handleCheck = useCallback(
    (item) => {
      const valueIndex = checkedItems.findIndex((val) => val.key === item.key);
      const newChecked = [...checkedItems];
      if (valueIndex === -1) {
        return setCheckedItems([...newChecked, item]);
      }
      newChecked.splice(valueIndex, 1);
      return setCheckedItems(newChecked);
    },
    [checkedItems],
  );

  const handleMoveLeft = useCallback(() => {
    const newRightItems = _.sortBy(_.differenceBy(rightList, rightChecked, "key"), sortKey);
    const newLeftItems = _.sortBy([...leftList, ...rightChecked], sortKey);
    if (onChange)
      onChange(
        newRightItems.map((item) => item.value),
        newLeftItems.map((item) => item.value),
      );
    setCheckedItems(_.differenceBy(checkedItems, rightChecked, "key"));
  }, [checkedItems, leftList, onChange, rightChecked, rightList, sortKey]);

  const handleMoveRight = useCallback(() => {
    const newRightItems = _.sortBy([...rightList, ...leftChecked], sortKey);
    const newLeftItems = _.sortBy(_.differenceBy(leftList, leftChecked, "key"), sortKey);
    if (onChange)
      onChange(
        newRightItems.map((item) => item.value),
        newLeftItems.map((item) => item.value),
      );
    setCheckedItems(_.differenceBy(checkedItems, leftChecked, "key"));
  }, [checkedItems, leftChecked, leftList, onChange, rightList, sortKey]);

  const cardList = useCallback(
    ({ title, items, checked, count }: { title: string; items: Item[]; checked: Item[]; count: number }) => {
      const notDisabledItems = items.filter((item) => !item.disabled);
      return (
        <Card className={classes.card}>
          <CardHeader
            title={title}
            subheader={
              <Typography variant="caption" color="textPrimary">
                {checked.length}/{count} selected
              </Typography>
            }
            avatar={
              <Checkbox
                onClick={() => handleCheckAll(notDisabledItems, checked)}
                checked={notDisabledItems.length > 0 && checked.length === notDisabledItems.length}
                disabled={notDisabledItems.length === 0}
              />
            }
          />
          <Divider />
          <List dense className={classes.list}>
            {items.map(({ render, ...item }) => (
              <ListItem key={item.key} button onClick={() => handleCheck(item)} disabled={item.disabled}>
                <ListItemIcon>
                  <Checkbox size="small" disableRipple checked={checked.findIndex((val) => val.key === item.key) !== -1} />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="caption">{render ? render(item) : item.label}</Typography>
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </Card>
      );
    },
    [classes.card, classes.list, handleCheck, handleCheckAll],
  );

  return (
    <Grid container spacing={1} justify="center" alignItems="center">
      <Grid item sm={5}>
        {cardList({ title: "unselected", items: leftListFiltered, checked: leftChecked, count: leftList.length })}
      </Grid>
      <Grid item sm={1}>
        <Grid container direction="column" alignItems="center">
          <IconButton size="small" onClick={handleMoveRight} disabled={leftChecked.length === 0}>
            <ChevronRight />
          </IconButton>
          <IconButton size="small" onClick={handleMoveLeft} disabled={rightChecked.length === 0}>
            <ChevronLeft />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item sm={5}>
        {cardList({ title: "selected", items: rightListFiltered, checked: rightChecked, count: rightList.length })}
      </Grid>
    </Grid>
  );
};

export default TransferList;
