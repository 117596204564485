import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Grid,
  LinearProgress,
  Link,
  makeStyles,
  Tooltip,
  TooltipProps,
  Typography,
} from "@material-ui/core";
import ClientStatementApi from "client/api/advisor/client/statement.clientApi";
import StatementApi from "client/api/statement.api";
import type { Props as InvestQualifyProps } from "client/components/CTAs/InvestFunds.component";
import InvestFunds from "client/components/CTAs/InvestFunds.component";
import QualifyToInvest from "client/components/CTAs/QualifyToInvest.component";
import Divider from "client/components/Divider.component";
import { AuthContext } from "client/context/auth.context";
import { ConfigContext } from "client/context/config.context";
import UserContext, { UserContextTypes } from "client/context/user.context";
import useQuery from "client/hooks/useQuery.hook";
import { TRACKED_EVENTS, USER_ROLE } from "client/utils/constants";

import { formatThousands } from "client/utils/helpers";
import clsx from "clsx";
import moment from "moment";
import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { animateScroll } from "react-scroll";
import { CompanyWithHoldings } from "server/services/company/company.types";
import { exportUserPortfolio } from "../../../../utils/csvExport";
import ChangeHistoryIcon from "./ChangeHistoryIcon.component";
import CompanyHoldingCard from "./CompanyHoldingCard.component";
import CompanyInfo from "./CompanyInfo.view";
import DocumentsForDownload from "./DocumentsForDownload.view";
import PortfolioSummaryItem from "./PortfolioSummaryItem.component";
import SummaryItem from "./SummaryItem.component";
import InvestorUpdates from "./InvestorUpdates.component";
import { Holding } from "server/services/shareTransaction/shareTransaction.types";

interface Props {
  clientId?: string;
}

const { PAGE_VIEWS /*, PAGE_ACTIONS*/ } = TRACKED_EVENTS;

const useStyles = makeStyles(
  (theme) => ({
    wrapper: {
      overflowX: "auto",
    },
    companyContainer: {
      overflowY: "auto",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "row",
      alignContent: "flex-start",
      flexWrap: "wrap",
      width: "40%",
      height: "100%",
      [theme.breakpoints.down("md")]: {
        width: "100%",
        flexDirection: "row",
        alignContent: "center",
      },
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    closeButton: {
      position: "absolute",
      right: 5,
      top: 5,
    },
    deployContent: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      "& > p": {
        display: "flex",
        justifyContent: "space-between",
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    detailContainer: {
      boxSizing: "border-box",
      display: "flex",
      width: "60%",
      height: "100%",
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    dialog: {
      borderRadius: 0,
    },
    dialogContent: {
      padding: `${theme.spacing(4)}px ${theme.spacing(6.25)}px`,
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(3),
      },
    },
    downloadContainer: {
      display: "flex",
      justifyContent: "flex-end",
    },
    growthIndicator: {
      position: "absolute",
      left: "-1.4em",
    },
    growthPercentage: {
      fontWeight: theme.typography.fontWeightSemiBold,
    },
    growthPercentageNegative: {
      color: theme.palette.error.main,
    },
    growthPercentagePositive: {
      color: theme.palette.emerald.main,
    },
    growthValue: {
      display: "flex",
      alignItems: "center",
      position: "relative",
    },
    hoverText: {
      opacity: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      color: theme.palette.primary.main,
      fontWeight: "bold",
      textDecoration: "underline",
      cursor: "pointer",
      textAlign: "center",
      transition: "opacity 0.2s ease-in-out",
      "&:hover": {
        opacity: 1,
        background: "rgba(240, 240, 240, 0.9)",
      },
    },
    linkDownloadAgainText: {
      color: theme.palette.text.usedLink,
      "&:hover": {
        color: theme.palette.text.usedLink,
      },
    },
    loading: {
      width: "100%",
    },
    logoWrapper: {
      position: "relative",
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    logo: {
      width: "auto",
      alignSelf: "center",
      maxWidth: "60%",
      [theme.breakpoints.down("md")]: {
        maxWidth: "100%",
      },
    },
    portfolioSummaryItem: {
      flex: 2,
    },
    sortIcon: {
      color: theme.palette.text.primary,
    },
    startupsContainer: {
      background: theme.palette.grey.main,
    },
    startupsHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    summaryContainer: {
      display: "flex",
      flexWrap: "wrap",
      "& > *": {
        marginRight: theme.spacing(3),
      },
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        paddingLeft: 0,
        paddingRight: 0,
        "& > *": {
          overflow: "hidden",
          marginRight: 0,
          "&:last-child": {
            marginTop: theme.spacing(3),
          },
        },
      },
    },
    summaryIcon: {
      paddingRight: theme.spacing(5),
      height: 70,
      width: "auto",
    },
    summaryItem: {
      flex: 1,
      alignItems: "center",
    },
    valueTypography: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    updateContainer: {
      backgroundColor: theme.palette.grey.dark,
      paddingTop: theme.spacing(20),
      paddingBottom: theme.spacing(20),
    },
  }),
  { name: "InvestmentPortfolioView" },
);

const BalanceTooltip: FC<Omit<TooltipProps, "title"> & { amountHeldElsewhere: number }> = ({ amountHeldElsewhere, ...props }) => (
  <Tooltip
    interactive
    {...props}
    title={
      <>
        {amountHeldElsewhere ? (
          <>
            <strong>{formatThousands(amountHeldElsewhere, 2)} is currently held with Reyker</strong>
            <br />
            <br />
          </>
        ) : null}
        Please note that your cash balance can take up to a week to be reflected on your dashboard.
        <br />
        <br />
        If you have any questions please let us know on our chat or email us at
        <br />
        <Link variant="inherit" color="inherit" href="mailto:fund@builtventures.uk">
          fund@builtventures.uk
        </Link>
      </>
    }
  >
    {props.children}
  </Tooltip>
);

const InvestmentPortfolio: FC<Props> = ({ clientId, ...props }) => {
  const classes = useStyles(props);
  const history = useHistory();
  const location = useLocation();
  const { NEXT_DEPLOYMENT } = useContext(ConfigContext as any);
  const { balances, clients, holdings: userHoldings, historicValues: usersHistoricValues, dispatch } = useContext(UserContext);
  const {
    state: { user },
  } = useContext(AuthContext);
  const selectedCompanyId = useQuery("selectedCompanyId");
  const [loading, setLoading] = useState(false);
  const [selectedCompanyHoldings, setSelectedCompanyHoldings] = useState<CompanyWithHoldings | null>(null);
  const [scrollY, setScrollY] = useState(0);

  const client = useMemo(() => (clientId ? clients[clientId] : null), [clientId, clients]);
  const balancesData = useMemo(() => (client ? client.balances : balances), [balances, client]);
  const holdings = useMemo(() => (client ? client.holdings : userHoldings), [client, userHoldings]);
  const historicValues = useMemo(() => (client ? client.historicValues : usersHistoricValues), [client, usersHistoricValues]);
  const totalValue = useMemo(() => holdings?.reduce((total, holding) => total + holding.totalValue, 0) ?? 0, [holdings]);
  const totalCost = useMemo(() => holdings?.reduce((total, holding) => total + holding.totalCost, 0) ?? 0, [holdings]);
  const growth = useMemo(() => totalValue - totalCost, [totalCost, totalValue]);
  const growthPercentage = useMemo(() => {
    if (!totalCost) return 0;
    return Math.round((growth / totalCost) * 100 * 100) / 100;
  }, [growth, totalCost]);

  const selectedCompany = useMemo(() => {
    if (!selectedCompanyId) return;
    return holdings?.find((c) => c._id === selectedCompanyId);
  }, [selectedCompanyId, holdings]);

  const getPortfolio = useCallback(async () => {
    setLoading(true);
    const data = clientId ? await ClientStatementApi.getClientPortfolio(clientId) : await StatementApi.getPortfolio();
    if (data) {
      dispatch({ type: UserContextTypes.SET_HOLDINGS, payload: data.portfolio, clientId });
      dispatch({ type: UserContextTypes.SET_HISTORIC_VALUES, payload: data.historicValues, clientId });
    }
    setLoading(false);
  }, [clientId, dispatch]);

  useEffect(() => {
    if (!selectedCompanyId) {
      animateScroll.scrollTo(scrollY, { duration: 0 });
    }
  }, [scrollY, selectedCompanyId]);

  useEffect(() => {
    globalThis.dataLayer.push({
      event: clientId ? PAGE_VIEWS.CLIENT_INVESTMENTS : PAGE_VIEWS.INVESTOR_DASHBOARD_PORTFOLIO_INVESTMENTS,
    });
  }, [clientId]);

  useEffect(() => {
    getPortfolio();
  }, [getPortfolio]);

  const getInvestQualifyComponent = useCallback(
    <T extends boolean | undefined>(ps: InvestQualifyProps<T>) => {
      if (client?.adviceType === "ADVISED") return <InvestFunds {...ps} client={client} />;
      if (client?.adviceType === "REFERRED") return null;
      return user!.isQualified ? <InvestFunds {...ps} /> : <QualifyToInvest {...ps} />;
    },
    [client, user],
  );

  const summaryBannerItems = useMemo(
    () => [
      {
        title: "GROWTH",
        hideTitle: true,
        content: (
          <div>
            <Typography variant="h3" align="center" component="div" paragraph className={classes.growthValue}>
              {holdings?.length ? <ChangeHistoryIcon growth={growth} className={classes.growthIndicator} /> : null}
              {formatThousands(growth, 2)}
            </Typography>
            <Typography
              variant="body2"
              align="center"
              className={clsx(classes.growthPercentage, {
                [classes.growthPercentageNegative]: growth < 0,
                [classes.growthPercentagePositive]: growth > 0,
              })}
            >
              ({growthPercentage}%)
            </Typography>
          </div>
        ),
      },
      {
        title: "DEPLOY",
        action: getInvestQualifyComponent({ link: true }),
        content: (
          <div className={classes.deployContent}>
            <BalanceTooltip amountHeldElsewhere={balancesData.fundsRemaining.amountHeldElsewhere}>
              <Typography variant="body2" paragraph>
                Balance <strong data-testid="CASH BALANCE">{formatThousands(balancesData.fundsRemaining.total, 2)}</strong>
              </Typography>
            </BalanceTooltip>
            <Typography variant="body2">
              Next Deployment <strong>{NEXT_DEPLOYMENT === "TBD" ? "TBD" : moment(NEXT_DEPLOYMENT).format("DD/MM/YY")}</strong>
            </Typography>
          </div>
        ),
      },
    ],
    [
      NEXT_DEPLOYMENT,
      balancesData.fundsRemaining.amountHeldElsewhere,
      balancesData.fundsRemaining.total,
      classes,
      holdings,
      getInvestQualifyComponent,
      growth,
      growthPercentage,
    ],
  );

  const isClientDashboard = useMemo(() => {
    return user?.role === USER_ROLE.ADVISOR && client;
  }, [client, user]);

  const helperText = useMemo(() => {
    if (holdings?.length) return null;
    if (!holdings && loading) return "Please wait while we finish setting up your dashboard.";

    const applicationStatus: string | null = isClientDashboard ? client!.applicationStatus : user!.applicationStatus;

    switch (applicationStatus) {
      case "Signed and awaiting funds":
        return (
          <>
            <BalanceTooltip amountHeldElsewhere={balancesData.fundsRemaining.amountHeldElsewhere}>
              <div>We’ve received your application and your cash balance will be updated as soon as we receive confirmation from our custodian.</div>
            </BalanceTooltip>
            <br />
            Select the button below to start a new application.
          </>
        );
      case "Funds Received, Awaiting Deployment":
        return (
          <>
            Your companies will show here after the next deployment.
            <br />
            <br />
            Select the button below to start a new application.
          </>
        );
      default:
        return !isClientDashboard
          ? `Select the button below to ${user!.isQualified ? "start an investment application." : "qualify to invest."}`
          : client?.adviceType === "REFERRED"
          ? "For your client to get started they can select to invest funds from their dashboard."
          : `Select the button below to start an investment application for your client.`;
    }
  }, [balancesData.fundsRemaining.amountHeldElsewhere, holdings, loading, user, client, isClientDashboard]);

  const handleCompanyCardClick = useCallback(
    (company: CompanyWithHoldings) => {
      setScrollY(window.scrollY);
      history.push(`${location.pathname}?selectedCompanyId=${company._id}`);
    },

    [history, location.pathname],
  );

  const filterUniqueDocuments = (holdings: Omit<Holding, "company">[]) => {
    return holdings.reduce((uniqueHoldings: Omit<Holding, "company">[], currentHolding: Omit<Holding, "company">) => {
      const existingItem = uniqueHoldings.find(
        (holding: Omit<Holding, "company">) => holding.seis3 && currentHolding.seis3 && holding.seis3.key === currentHolding.seis3.key,
      );

      if (!existingItem) {
        uniqueHoldings.push(currentHolding);
      }

      return uniqueHoldings;
    }, []);
  };

  const duplicateDocsCount = (holdings: Omit<Holding, "company">[]): number => {
    const duplicateCounts: Record<string, number> = {};

    holdings.forEach((currentHolding: Omit<Holding, "company">) => {
      const seis3Key = currentHolding.seis3?.key;

      if (seis3Key !== null && seis3Key !== undefined && seis3Key !== "") {
        duplicateCounts[seis3Key] = (duplicateCounts[seis3Key] || 0) + 1;
      }
    });

    // Count the occurrences where the count is greater than 1
    const totalCount = Object.values(duplicateCounts).filter((count) => count > 1).length;

    return totalCount;
  };

  const handleCompanyDocumentsClick = useCallback(
    (company: CompanyWithHoldings) => setSelectedCompanyHoldings({ ...company, holdings: filterUniqueDocuments(company.holdings) }),
    [],
  );

  const handleDocumentsForDownloadClose = useCallback(() => setSelectedCompanyHoldings(null), []);

  const exportPortfolio = useCallback(() => exportUserPortfolio(client?.userId, holdings), [client?.userId, holdings]);

  return selectedCompany ? (
    <CompanyInfo company={selectedCompany} onBackClick={() => history.push(location.pathname)} />
  ) : (
    <>
      <Container className={classes.summaryContainer}>
        <PortfolioSummaryItem historicValues={historicValues} cost={totalCost} value={totalValue} />
        {summaryBannerItems.map((item, index) => (
          <SummaryItem key={index} title={item.title} titleAction={item.action} hideTitle={item.hideTitle}>
            {item.content}
          </SummaryItem>
        ))}
      </Container>
      <div className={classes.startupsContainer}>
        <Container>
          <Typography variant="h3" gutterBottom className={classes.startupsHeader}>
            STARTUPS
            <Typography variant="body2">
              {holdings?.length ?? 0} {holdings?.length === 1 ? "company" : "companies"}
            </Typography>
          </Typography>
          <Divider size="medium" color="secondary" />
          <br />
          {user?.applicationStatus === "Funds Deployed" ? <InvestorUpdates user={user} /> : null}
          <br />
          <Grid container spacing={3} justify={!holdings?.length ? "center" : "space-between"}>
            {!holdings?.length ? (
              <Grid item xs={12} md={6}>
                <Card>
                  <CardHeader
                    title={
                      <Typography variant="h4">
                        <strong>
                          {!holdings && loading
                            ? "SETTING UP YOUR DASHBOARD...."
                            : isClientDashboard
                            ? "YOUR CLIENT HAS NO HOLDINGS"
                            : "YOU HAVE NO HOLDINGS"}
                        </strong>
                      </Typography>
                    }
                  />
                  <CardContent>
                    <Typography variant="body2">{helperText}</Typography>
                  </CardContent>
                  <CardActions>
                    {!holdings && loading ? (
                      <LinearProgress color="primary" className={classes.loading} />
                    ) : (
                      getInvestQualifyComponent({ variant: "contained" })
                    )}
                  </CardActions>
                </Card>
              </Grid>
            ) : (
              holdings.map((company) => {
                return (
                  <Grid key={company._id} data-testid={company.companyName} item xs={12} md={6}>
                    <CompanyHoldingCard
                      data={{ ...company, noDocuments: company.noDocuments - duplicateDocsCount(company.holdings) }}
                      onClick={handleCompanyCardClick}
                      onDocumentsClick={handleCompanyDocumentsClick}
                    />
                  </Grid>
                );
              })
            )}
          </Grid>
        </Container>
      </div>
      {!holdings?.length ? null : (
        <Container className={classes.downloadContainer}>
          <Button onClick={exportPortfolio} size="small" data-testid="export-dropdown-button">
            Export As CSV
          </Button>
        </Container>
      )}
      <DocumentsForDownload company={selectedCompanyHoldings} onClose={handleDocumentsForDownloadClose} clientId={clientId} />
    </>
  );
};
export default InvestmentPortfolio;
