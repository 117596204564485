import { makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles(theme => ({
  fullstop: {
    color: theme.palette.bullet.main,
  },
  orangeFullstop: {
    color: theme.palette.primary.main,
  },
}));

const SectionLetterTypography = ({ children, letter, orangeFullstop = false, ...props }) => {
  const classes = useStyles(props);

  return (
    <Typography variant="h1" gutterBottom {...props}>
      {letter}
      <Typography variant="inherit" className={clsx({ [classes.fullstop]: !orangeFullstop, [classes.orangeFullstop]: orangeFullstop })}>
        .
      </Typography>
      &nbsp;{children}
    </Typography>
  );
};

export default SectionLetterTypography;
