import { MutableRefObject, useEffect, useRef } from "react";

const descriptions: MutableRefObject<string | undefined>[] = [];

const getDescriptionMetaTag = () => document.querySelector<HTMLMetaElement>('meta[name="description"]');

/**
 * This hook will set the window description, when a component gets mounted.
 * When the component gets unmounted, the previously used description will be restored.
 * @param {string} description
 */
export const useDescription = (description: string) => {
  const ref = useRef<string>();

  if (ref.current === undefined) {
    descriptions.push(ref);
  }

  ref.current = description;

  useEffect(
    () => () => {
      descriptions.splice(descriptions.indexOf(ref), 1);
      const descriptionElement = getDescriptionMetaTag();
      descriptionElement!.content = getDescription();
    },
    [],
  );

  useEffect(() => {
    const descriptionElement = getDescriptionMetaTag();
    descriptionElement!.content = getDescription();
  }, [description]);
};

/**
 * Returns the current window description to be used in a SSR context
 * @returns {string}
 */
export const getDescription = (): string => descriptions[descriptions.length - 1]?.current ?? "";

export default useDescription;
