import { Button, Dialog, DialogContent, DialogProps, DialogTitle, Typography } from "@material-ui/core";
import { numberFormatter } from "@wearenova/mui-data-table";
import { deleteSubmission } from "client/api/admin/certificateSubmissions.adminApi";
import ConfirmationDialog from "client/components/ConfirmationDialog.component";
import { AdminContext, ADMIN_TYPES } from "client/context/admin.context";
import useToggle from "client/hooks/useToggle.hook";
import moment from "moment";
import { FC, useCallback, useContext } from "react";
import { AdminCertSubmission } from "server/services/certificateSubmissions/certSubmission.types";

interface Props extends DialogProps {
  submission: AdminCertSubmission | null;
  onClose: () => void;
}

/**
 * Certificate Submission form functional component.
 *
 * @param props
 * @returns Form component
 */

const CertificateSubmissionForm: FC<Props> = ({ onClose, open, submission }) => {
  const { dispatch } = useContext(AdminContext);
  const [confirmationOpen, , toggleOn, toggleOff] = useToggle(false);

  const handleDelete = useCallback(async () => {
    if (!submission) return;
    const deleted = await deleteSubmission(submission._id);
    if (deleted) dispatch({ type: ADMIN_TYPES.DELETE_CERTIFICATE_SUBMISSION, payload: deleted });
    toggleOff();
    onClose();
  }, [submission, dispatch, toggleOff, onClose]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle disableTypography>
        <Typography variant="h3">{`Submission entry for ${submission?.company.companyName}`}</Typography>
      </DialogTitle>
      <DialogContent>
        <div>
          <Typography variant="body2">
            This submission entry is for:
            <br />
            <ul>
              <li>
                <strong>Company:</strong> {submission?.company.companyName}
              </li>
              <li>
                <strong>Deal Stage:</strong> {submission?.dealStage}
              </li>
              <li>
                <strong>Settle Date:</strong> {submission?.settleDate && moment(submission.settleDate).format("DD/MM/YYYY")}
              </li>
              <li>
                <strong>Underlying Data:</strong>
                <ul>
                  <li>
                    <strong>Shares:</strong> {submission && numberFormatter(submission?.totals.shares, { decimalPlaces: 0, currency: false })}
                  </li>
                  <li>
                    <strong>No. Investors:</strong> {submission?.totals.numberOfInvestors}
                  </li>
                  <li>
                    <strong>Total Investment:</strong> {submission && numberFormatter(submission?.totals.investmentAmount)}
                  </li>
                </ul>
              </li>
            </ul>
          </Typography>
          <Button onClick={toggleOn} variant="outlined" size="small" fullWidth>
            Delete Submission
          </Button>
        </div>
        <ConfirmationDialog open={confirmationOpen} onClose={toggleOff} onPositiveButtonClick={handleDelete}>
          Are you sure you want to do this?
          <br />
          This will delete the selected submission entry!
        </ConfirmationDialog>
      </DialogContent>
    </Dialog>
  );
};

export default CertificateSubmissionForm;
