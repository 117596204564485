import { FilteredFund } from "client/api/gql/fund.gqlApi";
import _, { isNull } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { AllDeploymentApplications, BaseDeploymentApplication, FullDeploymentApplication } from "server/services/application/application.types";
import { AdminCompany, BaseDeploymentDeal } from "server/services/company/company.types";
import { DeploymentOptions } from "server/services/deployment/deployment.types";
import { getAmountLeftToDeploy, getFundConfig, getRelevantApps, getRelevantDeals, roundTo2DP } from "../utils";

const usePlannerData = ({
  applications = [],
  deals = [],
  companiesObject,
  options,
  funds,
}: {
  applications: BaseDeploymentApplication[];
  companiesObject: GenericObject<AdminCompany>;
  deals: BaseDeploymentDeal[];
  options: DeploymentOptions;
  funds: FilteredFund[];
}) => {
  const [result, setResult] = useState<AllDeploymentApplications>([]);

  // initialise objects for row data
  const rowData: AllDeploymentApplications = useMemo(() => {
    const data: AllDeploymentApplications = applications.map<FullDeploymentApplication>((row) => {
      const companiesAfter = [...row.balances.fundsDeployed.companies];
      const seisToDeploy = (row.balances.fundsRemaining.seis * (row.config.percentToUse.seis ?? 100)) / 100;
      const eisToDeploy = (row.balances.fundsRemaining.eis * (row.config.percentToUse.eis ?? 100)) / 100;
      const investorToDeploy = {
        seis: roundTo2DP(seisToDeploy),
        eis: roundTo2DP(eisToDeploy),
        total: 0,
      };
      investorToDeploy.total = investorToDeploy.seis + investorToDeploy.eis;

      const balanceRemaining = {
        seis: row.balances.fundsRemaining.seis,
        eis: row.balances.fundsRemaining.eis,
        total: row.balances.fundsRemaining.total,
      };
      const totalInvested = { eis: 0, seis: 0, total: 0 };

      if (options.allocations?.includeExpectedPayments && (!balanceRemaining.total || balanceRemaining.total < 10)) {
        balanceRemaining.seis = row.balances.fundsSignedFor.seis;
        balanceRemaining.eis = row.balances.fundsSignedFor.eis;
        balanceRemaining.total = row.balances.fundsSignedFor.total;
      }

      return {
        ...row,
        excluded: investorToDeploy.total === 0,
        toDeploy: investorToDeploy,
        balances: {
          ...row.balances,
          afterShareTransaction: {
            companies: companiesAfter,
            ...balanceRemaining,
          },
          totalInvested,
        } as FullDeploymentApplication["balances"],
        deals: deals.reduce(
          (obj, deal) => ({
            ...obj,
            [deal._id]: funds.reduce(
              (obj, fund) => ({
                ...obj,
                [String(fund._id)]: {
                  seis: { shares: 0, amount: 0 },
                  eis: { shares: 0, amount: 0 },
                },
              }),
              {},
            ),
          }),
          {},
        ),
      } as FullDeploymentApplication;
    });

    const getFundsTotals = () =>
      funds.reduce(
        (prev, fund) => ({
          ...prev,
          [String(fund._id)]: { eis: 0, seis: 0, total: 0 },
        }),
        {},
      );

    // initialise object to count totals for each column
    data.totals = deals.reduce(
      (prev, deal) => ({
        ...prev,
        deals: {
          ...prev.deals,
          [deal._id]: funds.reduce(
            (prev, fund) => ({
              ...prev,
              [String(fund._id)]: {
                seis: { shares: 0, amount: 0, weighting: 0, toDeploy: 0 },
                eis: { shares: 0, amount: 0, weighting: 0, toDeploy: 0 },
              },
            }),
            {},
          ),
        },
      }),
      {
        carryBack: { seis: 0, eis: 0 },
        fundsReceived: { seis: 0, eis: 0, total: 0, fundTotals: getFundsTotals() },
        fundsDeployed: { seis: 0, eis: 0, total: 0, fundTotals: getFundsTotals() },
        availableAmount: { seis: 0, eis: 0, total: 0, fundTotals: getFundsTotals() },
        investmentAmount: { seis: 0, eis: 0, total: 0, fundTotals: getFundsTotals() },
        remainingAmount: { seis: 0, eis: 0, total: 0, fundTotals: getFundsTotals() },
        toDeploy: { seis: 0, eis: 0, total: 0, fundTotals: getFundsTotals() },
        deals: {},
      } as NonNullable<AllDeploymentApplications["totals"]>,
    );

    return data;
  }, [applications, deals, options.allocations?.includeExpectedPayments, funds]);

  // helper function to update all relevant fields when shares change
  const allocateShares = useCallback(
    ({
      row,
      deal,
      dealStage,
      shares,
      fundId,
    }: {
      row: FullDeploymentApplication;
      deal: BaseDeploymentDeal;
      dealStage: "seis" | "eis";
      shares: number;
      fundId: string;
    }) => {
      const amount = shares * deal.shareClassData.pricePerShare;
      row.deals[deal._id][fundId][dealStage].shares += shares;
      row.deals[deal._id][fundId][dealStage].amount += amount;
      row.balances.totalInvested[dealStage] += amount;
      row.balances.totalInvested.total += amount;
      row.balances.afterShareTransaction[dealStage] -= amount;
      row.balances.afterShareTransaction.total -= amount;
      rowData.totals!.deals[deal._id][fundId][dealStage].shares += shares;
      rowData.totals!.deals[deal._id][fundId][dealStage].amount += amount;
      rowData.totals!.investmentAmount[dealStage] += amount;
      rowData.totals!.investmentAmount.total += amount;
      rowData.totals!.investmentAmount.fundTotals[fundId][dealStage] += amount;
      rowData.totals!.investmentAmount.fundTotals[fundId].total += amount;
      rowData.totals!.remainingAmount[dealStage] -= amount;
      rowData.totals!.remainingAmount.total -= amount;
      rowData.totals!.remainingAmount.fundTotals[fundId][dealStage] -= amount;
      rowData.totals!.remainingAmount.fundTotals[fundId].total -= amount;

      if (shares > 0 && !row.balances.afterShareTransaction.companies.includes(deal.company._id)) {
        row.balances.afterShareTransaction.companies.push(deal.company._id);
      }
    },
    [rowData],
  );

  const getDealWeightings = useCallback(
    (dealsToUse: BaseDeploymentDeal[], fundId: string) => {
      const totals = dealsToUse.reduce(
        (prev, deal) => {
          const currSEIS = rowData.totals!.deals[deal._id][fundId].seis.toDeploy - rowData.totals!.deals[deal._id][fundId].seis.amount,
            isSEISWeighted = rowData.totals!.deals[deal._id][fundId].seis.weighting > 0;
          const currEIS = rowData.totals!.deals[deal._id][fundId].eis.toDeploy - rowData.totals!.deals[deal._id][fundId].eis.amount,
            isEISWeighted = rowData.totals!.deals[deal._id][fundId].eis.weighting > 0;
          return {
            seis: prev.seis + currSEIS,
            eis: prev.eis + currEIS,
            weighted: { seis: prev.weighted.seis + (isSEISWeighted ? currSEIS : 0), eis: prev.weighted.eis + (isEISWeighted ? currEIS : 0) },
            unweighted: { seis: prev.unweighted.seis + (!isSEISWeighted ? currSEIS : 0), eis: prev.unweighted.eis + (!isEISWeighted ? currEIS : 0) },
          };
        },
        { seis: 0, eis: 0, weighted: { seis: 0, eis: 0 }, unweighted: { seis: 0, eis: 0 } },
      );

      return dealsToUse.reduce(
        (obj, deal) => ({
          ...obj,
          [deal._id]: {
            seis: (rowData.totals!.deals[deal._id][fundId].seis.toDeploy - rowData.totals!.deals[deal._id][fundId].seis.amount) / totals.seis,
            eis: (rowData.totals!.deals[deal._id][fundId].eis.toDeploy - rowData.totals!.deals[deal._id][fundId].eis.amount) / totals.eis,
          },
        }),
        {
          weighted: totals.weighted,
          unweighted: totals.unweighted,
        } as { weighted: { seis: number; eis: number }; unweighted: { seis: number; eis: number }; [dealId: string]: { seis: number; eis: number } },
      );
    },
    [rowData],
  );

  const calculateShares = useCallback(
    ({
      dealStage,
      rows,
      fund,
      getDeals,
    }: {
      dealStage: "seis" | "eis";
      rows: AllDeploymentApplications;
      fund: FilteredFund;
      getDeals(row: FullDeploymentApplication): BaseDeploymentDeal[];
    }) => {
      const fundId = fund._id ?? "null";
      // calculate shares for given rows and comps based on compWeightings
      rows.forEach((row) => {
        const dealsToUse = getDeals(row);
        if (!dealsToUse.length) return;

        const amountLeftToDeploy = getAmountLeftToDeploy(row, dealStage);
        const dealWeightings = getDealWeightings(dealsToUse, fundId);

        dealsToUse.forEach((deal) => {
          const dealTotals = rowData.totals!.deals[deal._id][fundId][dealStage];
          const leftToDeploy = Math.min(dealTotals.toDeploy - dealTotals.amount, amountLeftToDeploy * dealWeightings[deal._id][dealStage]);
          const shares = Math.floor(leftToDeploy / deal.shareClassData.pricePerShare);
          allocateShares({ row, deal, dealStage, shares, fundId });
        });
      });

      let madeUnweightedChanges = true;
      while (madeUnweightedChanges) {
        madeUnweightedChanges = false;
        rows.forEach((row) => {
          getDeals(row).forEach((deal) => {
            if (rowData.totals!.deals[deal._id][fundId][dealStage].weighting) return;
            const amountLeftToDeploy = getAmountLeftToDeploy(row, dealStage);
            const balanceRemaining = roundTo2DP(row.balances.afterShareTransaction[dealStage]);
            const prevAmount = roundTo2DP(rowData.totals!.deals[deal._id][fundId][dealStage].amount);
            const maxToDeploy = roundTo2DP(rowData.totals!.deals[deal._id][fundId][dealStage].toDeploy);
            const remainingAmount = roundTo2DP(maxToDeploy - prevAmount);

            if (
              deal.type !== "ASA" &&
              deal.shareClassData.pricePerShare <= amountLeftToDeploy &&
              deal.shareClassData.pricePerShare <= balanceRemaining &&
              deal.shareClassData.pricePerShare + prevAmount <= maxToDeploy
            ) {
              allocateShares({
                row,
                deal,
                dealStage,
                shares: 1,
                fundId,
              });
              madeUnweightedChanges = true;
            } else if (deal.type === "ASA" && amountLeftToDeploy > 0 && balanceRemaining > 0 && remainingAmount > 0) {
              allocateShares({
                row,
                deal,
                dealStage,
                shares: remainingAmount / deal.shareClassData.pricePerShare,
                fundId,
              });
              madeUnweightedChanges = true;
            }
          });
        });
      }

      rows.forEach((row) => {
        // pass over all rows and columns using up any left over funds, by row
        let madeChanges = true;
        while (madeChanges) {
          madeChanges = false;
          getDeals(row).forEach((deal) => {
            const shares = row.deals[deal._id][fundId][dealStage].shares;
            const amountLeftToDeploy = getAmountLeftToDeploy(row, dealStage);
            const balanceRemaining = roundTo2DP(row.balances.afterShareTransaction[dealStage]);
            const prevAmount = roundTo2DP(rowData.totals!.deals[deal._id][fundId][dealStage].amount);
            const maxToDeploy = roundTo2DP(rowData.totals!.deals[deal._id][fundId][dealStage].toDeploy);
            const remainingAmount = roundTo2DP(maxToDeploy - prevAmount);

            if (
              shares > 0 &&
              deal.type !== "ASA" &&
              deal.shareClassData.pricePerShare <= amountLeftToDeploy &&
              deal.shareClassData.pricePerShare <= balanceRemaining &&
              deal.shareClassData.pricePerShare + prevAmount <= maxToDeploy
            ) {
              allocateShares({
                row,
                deal,
                dealStage,
                shares: 1,
                fundId,
              });
              madeChanges = true;
            } else if (shares > 0 && deal.type === "ASA" && amountLeftToDeploy > 0 && balanceRemaining > 0 && remainingAmount > 0) {
              allocateShares({
                row,
                deal,
                dealStage,
                shares: remainingAmount / deal.shareClassData.pricePerShare,
                fundId,
              });
              madeChanges = true;
            }
          });
        }
      });
    },
    [allocateShares, getDealWeightings, rowData],
  );

  useEffect(() => {
    funds.forEach((fund) => {
      const fundId = fund._id ?? "null";
      (["seis", "eis"] as const).forEach((dealStage) => {
        const appsToDeploy = getRelevantApps({ applications: rowData, fundId: fund._id, dealStage });
        if (!appsToDeploy.length) return;

        // calculate values for footer totals
        appsToDeploy.forEach((row) => {
          rowData.totals!.carryBack[dealStage] += row?.data?.investmentInformation?.carryBack?.[dealStage] || 0;
          rowData.totals!.fundsReceived[dealStage] += row?.balances?.fundsReceived?.[dealStage] ?? 0;
          rowData.totals!.fundsReceived.total += row?.balances?.fundsReceived?.[dealStage] ?? 0;
          rowData.totals!.fundsDeployed[dealStage] += row?.balances?.fundsDeployed?.[dealStage] ?? 0;
          rowData.totals!.fundsDeployed.total += row?.balances?.fundsDeployed?.[dealStage] ?? 0;
          rowData.totals!.availableAmount[dealStage] += row?.balances?.fundsRemaining?.[dealStage] ?? 0;
          rowData.totals!.availableAmount.total += row?.balances?.fundsRemaining?.[dealStage] ?? 0;
          rowData.totals!.remainingAmount[dealStage] += row?.balances?.fundsRemaining?.[dealStage] ?? 0;
          rowData.totals!.remainingAmount.total += row?.balances?.fundsRemaining?.[dealStage] ?? 0;
          rowData.totals!.toDeploy[dealStage] += row?.toDeploy?.[dealStage] ?? 0;
          rowData.totals!.toDeploy.total += row?.toDeploy?.[dealStage] ?? 0;
          // funds
          rowData.totals!.fundsReceived.fundTotals[fundId][dealStage] += row?.balances?.fundsReceived?.[dealStage] ?? 0;
          rowData.totals!.fundsReceived.fundTotals[fundId].total += row?.balances?.fundsReceived?.[dealStage] ?? 0;
          rowData.totals!.fundsDeployed.fundTotals[fundId][dealStage] += row?.balances?.fundsDeployed?.[dealStage] ?? 0;
          rowData.totals!.fundsDeployed.fundTotals[fundId].total += row?.balances?.fundsDeployed?.[dealStage] ?? 0;
          rowData.totals!.availableAmount.fundTotals[fundId][dealStage] += row?.balances?.fundsRemaining?.[dealStage] ?? 0;
          rowData.totals!.availableAmount.fundTotals[fundId].total += row?.balances?.fundsRemaining?.[dealStage] ?? 0;
          rowData.totals!.remainingAmount.fundTotals[fundId][dealStage] += row?.balances?.fundsRemaining?.[dealStage] ?? 0;
          rowData.totals!.remainingAmount.fundTotals[fundId].total += row?.balances?.fundsRemaining?.[dealStage] ?? 0;
          rowData.totals!.toDeploy.fundTotals[fundId][dealStage] += row?.toDeploy?.[dealStage] ?? 0;
          rowData.totals!.toDeploy.fundTotals[fundId].total += row?.toDeploy?.[dealStage] ?? 0;
        });

        const dealsToDeploy = getRelevantDeals(deals, fund._id, dealStage);
        if (!dealsToDeploy.length) return;

        dealsToDeploy.forEach((deal) => {
          const fundConfig = getFundConfig(deal, fundId, dealStage);
          rowData.totals!.deals[deal._id][fundId][dealStage].weighting = fundConfig.weighting;
          rowData.totals!.deals[deal._id][fundId][dealStage].toDeploy = fundConfig.amount!;
        });

        const sortedRows = _.sortBy(
          appsToDeploy,
          (row) => -row.config.excludedCompanies.length,
          (row) => (isNull(row.config.spread[dealStage]) ? Infinity : row.config.spread[dealStage]),
          (row) => row.toDeploy[dealStage] - row.balances.totalInvested[dealStage],
        );
        const sortedDeals = _.sortBy(
          dealsToDeploy,
          (deal) => -deal.shareClassData.pricePerShare,
          (deal) => rowData.totals!.deals[deal._id][fundId][dealStage].toDeploy,
        );

        calculateShares({
          dealStage,
          rows: sortedRows,
          fund,
          getDeals: (row) => {
            const eligibleDeals = sortedDeals.filter((deal) => {
              if (row.config.excludedCompanies.includes(deal.company._id)) return false;
              const compLeftToDeploy =
                rowData.totals!.deals[deal._id][fundId][dealStage].toDeploy - rowData.totals!.deals[deal._id][fundId][dealStage].amount;
              return roundTo2DP(compLeftToDeploy) >= deal.shareClassData.pricePerShare || (deal.type === "ASA" && compLeftToDeploy > 0);
            });

            const newCompanies = _.differenceBy(eligibleDeals, row.balances.afterShareTransaction.companies, (cd) => {
              return typeof cd === "string" ? cd : cd.company._id;
            });
            const totalSpread =
              row.balances.afterShareTransaction.companies.length - row.balances.fundsDeployed.companies.length + newCompanies.length;

            if (!isNull(row.config.spread[dealStage]) && totalSpread < row.config.spread[dealStage]!) {
              const capsDealStage = dealStage.toUpperCase();
              if (!row.noSpreadReasons) row.noSpreadReasons = {};
              row.noSpreadReasons[
                dealStage
              ] = `${capsDealStage} Companies Desired: ${row.config.spread[dealStage]}\n${capsDealStage} Companies Available: ${eligibleDeals.length}`;
              return [];
            }
            const sorted = _.orderBy(
              eligibleDeals,
              [
                (deal) => Number(getFundConfig(deal, fund._id, dealStage).weighting === 0),
                (deal) => rowData.totals!.deals[deal._id][fundId][dealStage].toDeploy - rowData.totals!.deals[deal._id][fundId][dealStage].amount,
              ],
              ["desc", "desc"],
            );
            if (isNull(row.config.spread[dealStage])) return sorted;
            const uniqSliced = _.uniqBy(sorted, "company._id")
              .slice(0, row.config.spread[dealStage]!)
              .map((d) => d.company._id);
            return sorted.filter((d) => uniqSliced.includes(d.company._id));
          },
        });
      });
    });

    setResult(rowData);
  }, [applications, calculateShares, companiesObject, deals, funds, options, rowData]);
  return result;
};

export default usePlannerData;
