import { Button, Dialog, DialogContent, DialogProps, DialogTitle, FormControlLabel, FormGroup, makeStyles, Switch } from "@material-ui/core";
import { useUpdateAllocationOptions } from "client/api/gql/deployment.gqlApi";
import { PLANNER_OPTIONS } from "client/utils/constants";
import notifications from "client/utils/notifications";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { AllocationsOptions } from "server/services/deployment/deployment.types";
import DeploymentContext from "../deployment.context";

interface Props extends DialogProps {
  onClose: () => void;
}

const useStyles = makeStyles(
  () => ({
    filterBox: {
      marginTop: 10,
      marginBottom: 10,
      marginLeft: 10,
    },
    toggleSwitch: {
      "& .Mui-checked .MuiIconButton-label": {
        background: "unset",
      },
    },
  }),
  { name: "TransactionsPlannerOptionsForm" },
);

const AllocationsTableOptions: React.FC<Props> = ({ onClose, ...props }) => {
  const classes = useStyles(props);
  const { options } = useContext(DeploymentContext);
  const [updateAllocationOptions] = useUpdateAllocationOptions();
  const [selectedOptions, setSelectedOptions] = useState({} as Partial<AllocationsOptions>);
  const initialValues = useMemo(() => ({ ...options.allocations }), [options.allocations]);

  const handleSubmit = useCallback(async () => {
    const res = await updateAllocationOptions({ variables: { options: selectedOptions } });
    if (res.errors) return notifications.gql(res.errors, "Failed to update allocations options");
    onClose();
  }, [onClose, selectedOptions, updateAllocationOptions]);

  useEffect(() => () => setSelectedOptions({}), []);

  return (
    <Dialog onClose={onClose} {...props}>
      <DialogTitle>Investor Allocations Options</DialogTitle>
      <DialogContent id="dialog">
        {Object.entries(PLANNER_OPTIONS).map(([key, label]) => (
          <FormGroup className={classes.filterBox} key={key}>
            <FormControlLabel
              label={label}
              labelPlacement="end"
              control={
                <Switch
                  color="primary"
                  className={classes.toggleSwitch}
                  checked={selectedOptions[key] ?? initialValues[key] ?? false}
                  onChange={(e) => setSelectedOptions({ ...selectedOptions, [key]: e.target.checked })}
                />
              }
            />
          </FormGroup>
        ))}
        <FormGroup row>
          <Button
            variant="outlined"
            onClick={() => {
              setSelectedOptions(options.allocations || {});
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmit}>
            Submit
          </Button>
        </FormGroup>
      </DialogContent>
    </Dialog>
  );
};

export default AllocationsTableOptions;
