import {
  Button,
  ButtonGroup,
  ButtonGroupProps,
  ButtonProps,
  ClickAwayListener,
  Grow,
  Input,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import clsx from "clsx";
import React, { FC, MouseEventHandler, useCallback, useMemo, useRef, useState } from "react";

export interface DropDownItem {
  text: string;
  onClick(): any;
}

interface Props extends Pick<ButtonGroupProps, "variant" | "size" | "color"> {
  primaryText?: string;
  primaryClick?: MouseEventHandler<HTMLButtonElement>;
  dropDownItems: DropDownItem[];
  primaryButtonProps?: Partial<ButtonProps<"button", GenericObject>>;
  searchable?: boolean;
}

const useStyles = makeStyles(
  (theme) => ({
    button: {
      flex: "unset",
      // marginBottom: theme.spacing(2),
    },
    buttonIcon: {
      transition: "transform 0.15s ease-in-out",
    },
    dropUp: {
      transform: "rotate(180deg)",
    },
    filterInput: {
      padding: theme.spacing(1),
      width: "100%",
      "& > input": {
        ...theme.typography.body2,
        "&::placeholder": {
          ...theme.typography.body2,
          opacity: 1,
          color: theme.palette.text.hint,
        },
      },
    },
    paperBackground: {
      background: theme.palette.grey.main,
    },
  }),
  { name: "DropDownButtonComponent" },
);

const DropDownButton: FC<Props> = ({
  primaryText,
  primaryClick,
  dropDownItems = [],
  primaryButtonProps = {},
  searchable = false,
  variant,
  size,
  color,
  ...props
}) => {
  const classes = useStyles(props);
  const anchorRef = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [filter, setFilter] = useState("");

  const filteredItems = useMemo(() => {
    if (!searchable || !filter) return dropDownItems;
    return dropDownItems.filter((item) => new RegExp(filter, "i").test(item.text));
  }, [dropDownItems, filter, searchable]);

  const commonButtonProps = useMemo(
    () => ({
      variant: variant || "contained",
      size: size || "small",
      color: color || "secondary",
    }),
    [color, size, variant],
  );

  const handleDropdownClick = useCallback((e) => {
    e.stopPropagation();
    setOpen((s) => !s);
  }, []);

  return (
    <>
      {primaryClick ? (
        <ButtonGroup {...commonButtonProps} ref={anchorRef}>
          <Button onClick={primaryClick} {...primaryButtonProps}>
            {primaryText ?? props.children}
          </Button>
          <Button onClick={handleDropdownClick}>{<ArrowDropDownIcon className={clsx(classes.buttonIcon, { [classes.dropUp]: open })} />}</Button>
        </ButtonGroup>
      ) : (
        <Button
          onClick={handleDropdownClick}
          endIcon={<ArrowDropDownIcon className={clsx(classes.buttonIcon, { [classes.dropUp]: open })} />}
          className={classes.button}
          ref={anchorRef}
          {...primaryButtonProps}
        >
          {primaryText ?? props.children}
        </Button>
      )}
      <Popper open={open} anchorEl={anchorRef.current} transition>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper className={classes.paperBackground} elevation={4}>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <div>
                  {!searchable ? null : (
                    <Input
                      onChange={(e) => {
                        setFilter(e.target.value);
                      }}
                      placeholder="Filter actions"
                      className={classes.filterInput}
                      autoFocus
                    />
                  )}
                  <MenuList>
                    {filteredItems.map(({ onClick, text }, index) => (
                      <MenuItem
                        key={text}
                        disabled={selectedIndex === index}
                        onClick={async (e) => {
                          try {
                            e.stopPropagation();
                            setSelectedIndex(index);
                            await onClick();
                          } finally {
                            setSelectedIndex(-1);
                          }
                        }}
                        data-testid={text}
                      >
                        <Typography variant="body2">{text}</Typography>
                      </MenuItem>
                    ))}
                  </MenuList>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default DropDownButton;
