import { Container } from "@material-ui/core";
import { ConfigContext } from "client/context/config.context";
import { fundVariableReplacer } from "client/utils/helpers";
import React, { useContext, useMemo } from "react";
import { SectionType } from "server/services/fund/fund.types";
import SectionContent, { NestedSection } from "./SectionContent.component";
import SectionLetterTypography from "./SectionLetterTypography.component";

interface Props {
  prefix: string;
  section: SectionType;
}

const MainSection: React.FC<Props> = ({ prefix, section }) => {
  const { fund } = useContext(ConfigContext);
  const replacedTitle = useMemo(() => fundVariableReplacer(section.title, fund), [fund, section.title]);

  return (
    <Container>
      <SectionLetterTypography letter={prefix}>{replacedTitle}</SectionLetterTypography>
      {!section.content ? null : <SectionContent content={section.content} />}
      {!section.sections?.length
        ? null
        : section.sections.map((nestedSection, index) => (
            <NestedSection key={index} prefix={nestedSection.prefix ?? index + 1} title={nestedSection.title}>
              {nestedSection.content}
            </NestedSection>
          ))}
    </Container>
  );
};

export default MainSection;
