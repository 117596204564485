import { makeStyles } from "@material-ui/core";
import DataTable from "@wearenova/mui-data-table";
import { AuthContext } from "client/context/auth.context";
import clsx from "clsx";
import React, { useContext } from "react";

const useStyles = makeStyles(
  (theme) => ({
    table: {
      "& *.MuiTableCell-root": {
        ...theme.typography.caption,
        whiteSpace: "pre",
        "& *:not(svg)": {
          fontSize: theme.typography.caption.fontSize,
        },
      },
      "& .MuiTableFooter-root .MuiTableCell-root": {
        fontWeight: theme.typography.fontWeightBold,
      },
      [theme.breakpoints.up("lg")]: {
        tableLayout: "auto",
      },
    },
  }),
  { name: "AdminTableView" },
);

const AdminTable: typeof DataTable = ({ tableProps, onChange, ...props }) => {
  const {
    state: { user },
  } = useContext(AuthContext);
  const classes = useStyles(props);

  return (
    <DataTable
      tableProps={{ size: "small", ...tableProps, className: clsx([classes.table, tableProps && tableProps.className]) }}
      onChange={onChange}
      rowsPerPageOptions={[5, 10, 25, 50, 100, 500, 1000, 2000, { label: "All", value: props.count! }]}
      enableHiddenColumns
      {...props}
      editable={!user || user.isReadOnly ? false : true}
    />
  );
};

export default AdminTable;
